<template>	 

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getStati()"> Destinazioni </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Nazioni </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;" v-on:click="nuovoStato()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Stato</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche()" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help()" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
                </div>
            </div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#stati-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate> Stati
							</div>
						</a>
					</li>
				</ul>
				
				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getStati()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-code-merge fa-fw me-2"></i><span class="text-muted me-1">|</span>Merge Stati</a>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Stati</a>
								</div>
							</div>
						</li>
						<li class="nav-item ms-4">
							<SelectLingue class="form-select form-select-sm" @change="getStati()" v-model="currentLang">
							</SelectLingue>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="stati-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="stato-a">
									<div class="row">           
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Nazione" v-model="search.ID_STATO.VALUE" :tabindex="2" />
										</div>   
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice Nazione" v-model="search.COUNTRY_CODE.VALUE" :tabindex="3" />
										</div>  
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Nazione" v-model="search.TITOLO.VALUE" :tabindex="4" />
										</div>          
										<div class="col-12 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" placeholder="Seleziona Continente" v-model="search.FK_ID_CONTINENTE.VALUE" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona Continente</option>
												<option v-for="continente in arrayContinenti" v-bind:value="continente.ID_CONTINENTE" v-bind:key="continente.ID_CONTINENTE"> {{ continente.DESCRIZIONE }} </option>
											</select> 
										</div>          
										<div class="col-12 col-md-4 col-lg-4 mbx-1">

										</div>
										<div class="col-6 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="6">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivati</option>
												<option value="0"> &nbsp; Disattivati</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
			                                    + FILTRI
											</a>
										</div>
								 	</div>
								</form>  
							</div>
						</div>
		            </div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllComuni" v-on:click="filters.selectAll('chkAllComuni',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
										<th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
										<th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm">Nome / SEO /<br />Impostazioni </th>
										<th class="table-head-sm" style="width:6%;">Cod. Paese</th>
										<th class="table-head-sm" style="width:15%;">Continente /<br /></th>
										<th class="table-head-sm text-center" style="width:15%;">Pref. Tel. /<br />Valuta</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
									<tr v-for="stato in paginatedListStati.arr" v-bind:key="stato.ID_STATO">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="stato.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center">
											<img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
										<td class="td-class fs-sm">
											<span>{{stato.ID_STATO}}</span><br />
											<small class="badge bg-light text-primary float-end">{{stato.ORDINE}}</small>
										</td>
										<td class="td-class text-center"> 
											<img v-if="stato.ID_STATO > 0 && stato.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',stato.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="stato.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneStato(stato)">{{stato.TITOLO}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="fal fa-tag fa-fw"></i> {{stato.FK_ID_ENTE}}</span>
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="fal fa-thumbs-up fa-fw"></i> Metti in Evidenza</span>
											<span class="float-end">
												<a class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"> Lat: {{stato.LATITUDINE}}</a>
												<a class="badge bg-light badge-xs text-dark rounded-0" style="border: 1px #dddddd solid;"> Lng: {{stato.LONGITUDINE}}</a>
											</span>
										</td>
										<td class="td-class fs-sm">
											<div class="badge bg-light">
												<Flag :country="stato.ID_STATO" class="me-1"></Flag> - <span class="font-size-base text-dark" style="top: 2px; position: relative;">{{stato.COUNTRY_CODE}}</span>
											</div>
										</td>
										<td class="td-class fs-sm">
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info">{{getLabelContinente(stato)}}</span><br />
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-phone fs-base lh-base align-top text-success me-1"></i> Prefizzo: <a href="#">{{stato.COUNTRY_PREFIX}}</a></div>
											<div><i class="ph-coins fs-base lh-base align-top text-danger me-1"></i> Valuta: <a href="#">{{stato.CURRENCY}}</a></div>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateFlgVisibile(stato)" v-show="stato.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(stato)" v-show="stato.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>											
										</td>
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoStato(stato)" v-model="stato.FLG_ATTIVO" :size="'sm'" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end"> 
													<a href="#" class="dropdown-item" v-on:click="modificaStato(stato)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Nazione</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="openContenuti(stato)"><i class="fad fa-text fa-fw me-2"></i><span class="text-muted me-1">|</span>Contenuti Stato</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteStato(stato)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Stato</a>
												</div>
											</div>
										</td>
									</tr>
								</draggable>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">STATI: {{ paginatedListStati.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class=" float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListStati.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div> 
						</div> 
					</div>
				</div>
			</div>

		</div>
	</div>	


	<!-- START MODALI -->
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" aria-hidden="true" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" :tabindex="2">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneGeo @modificaStato="modificaStato($event)" :ref="'schedaVisualizzazioneStato'"></SchedaVisualizzazioneGeo>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>	 

	<div class="modal fade" id="popUpStato" aria-modal="true" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-xl"> 
            <div class="modal-content" >
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> SCHEDA {{statoSelezionato.ID_STATO == 0 ? 'NUOVO' : 'MODIFICA'}} STATO </h6>                                                        
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body" style="height: 32rem;">

					<div class="d-block d-sm-flex">
                        <div class="profile-tab-nav border-right">
                            <div class="p-3">
                                <div class="img-circle text-center cursor-pointer mb-0" v-on:click="uploadImmagineStato()" :tabindex="3">
									<img v-if="statoSelezionato.ID_STATO > 0 && statoSelezionato.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',statoSelezionato.URL_AVATAR)" class="rounded shadow" alt="" />
									<span v-if="statoSelezionato.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 2rem 4rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</span>
                                </div>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="padding-right: 5px !important; min-width: 13rem;">
                                <a class="nav-link strech-nav-pill active" id="geodata-tab" data-bs-toggle="pill" href="#geo-data" role="tab" aria-controls="geo-data" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-globe fa-fw"></i> Dati Geografici
                                </a>
								<a class="nav-link strech-nav-pill" id="geo-map-tab" data-bs-toggle="pill" href="#geo-map" role="tab" aria-controls="geo-map" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-map fa-fw"></i> Geo Localizzazione
                                </a>
                            </div>
                        </div>
                        <div class="tab-content p-md-2" style="padding-right: 0px !important;" id="v-pills-tabContent">

                            <div class="tab-pane fade show active" id="geo-data" role="tabpanel" aria-labelledby="geo-data-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-globe fa-fw me-1"></i> Dati Geografici</h3>
                                <div class="box_26">   

									<div class="row">
										<div class="col-12 col-md-4 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Continente:</label>
											<select class="form-select form-select-sm select-icons" v-model="statoSelezionato.FK_ID_CONTINENTE" :tabindex="3"> 
												<option v-for="continente in arrayContinenti" v-bind:value="continente.ID_CONTINENTE" v-bind:key="continente.ID_CONTINENTE"> {{ continente.DESCRIZIONE }} </option>
											</select> 
										</div>	
										<div class="col-12 col-md-8 form-group-1"></div>
		
										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Nome Nazione:</label>
											<input autocomplete="off" type="text" class="form-select form-select-sm" id="autocomplete"  v-model="statoSelezionato.NOME" :tabindex="10">
										</div>

										<div class="col-12 col-md-6 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Slogan Nazione:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-select form-select-sm" v-model="statoSelezionato.lang.TITOLO" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-tag fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-2 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Iso:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-select form-select-sm" v-model="statoSelezionato.COUNTRY_CODE" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-stamp fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-2 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Codice Valuta:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-select form-select-sm" v-model="statoSelezionato.CURRENCY" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-balance-scale-right fa-fw"></i></div>
											</div>
										</div>
										<div class="col-5 col-md-2 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Prefisso Tel.:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-select form-select-sm" v-model="statoSelezionato.COUNTRY_PREFIX" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-phone fa-fw"></i></div>
											</div>
										</div>

										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Fatturazione:</label>
											<select class="form-select form-select-sm select-icons" v-model="statoSelezionato.COUNTRY_VAT" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="1"> - Cee</option>
												<option value="2"> - Fuori Cee</option>
											</select> 
										</div>
										<div class="col-12 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Documenti:</label>
											<div class="form-group-feedback form-group-feedback-left">
												<input autocomplete="off" type="text" class="form-select form-select-sm" v-model="statoSelezionato.COUNTRY_DOC" :tabindex="4">
												<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-text fa-fw"></i></div>
											</div>
										</div>
										
										<div class="col-12 form-group-1">
											<label class="text-danger custom-control custom-control-right">Categorie associate:</label>
											<Select2 :multiple="true" v-bind:options="arrayCategorie" v-model="statoSelezionato.categorie" :tabindex="17"></Select2>
										</div>

										<div class="col-12 col-md-12 form-group-1">
											<label class="text-danger custom-control custom-control-right">Pubblica sul Sito:</label>
											<div class="row">
												<div class="col-lg-1"><span class="btn btn-light btn-sm" v-on:click="statoSelezionato.enti = filters.select2AddAll(statoSelezionato.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span></div>
												<div class="col-lg-11">
													<Select2 :multiple="true" v-bind:options="arrayEntiSelect2" v-model="statoSelezionato.enti" :tabindex="17"></Select2>  
												</div>
											</div>
										</div>

										<div class="col-5 col-md-3 form-group-1"></div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">In Evidenza.</label>
											<select class="form-select form-select-sm select-icons" v-model="statoSelezionato.FLG_INHOME" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - No</option>
												<option value="1"> - Si</option>
											</select> 
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Visibile:</label>
											<select class="form-select form-select-sm select-icons" v-model="statoSelezionato.FLG_VISIBILE" :tabindex="3"> 
												<option value="-1" data-icon="chrome" selected>Seleziona</option>
												<option value="0"> - Solo Admin</option>
												<option value="1"> - Pubblico</option>
											</select>
										</div>
										<div class="col-5 col-md-3 form-group-1"> 
											<label class="text-danger custom-control custom-control-right">Stato:</label>
											<select class="form-select form-select-sm select-icons" v-model="statoSelezionato.lang.FLG_ATTIVO" :tabindex="3"> 
												<option value="" data-icon="chrome" selected>Seleziona</option>
												<option value="1"> - Attivo</option>
												<option value="2"> - Disattivo</option>
											</select> 
										</div>
									</div>
								</div>
							</div> 

                            <div class="tab-pane fade" id="geo-map" role="tabpanel" aria-labelledby="geo-map-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-map fa-fw me-1"></i> Geo Localizzazione</h3>
                                <div class="box_26">

                                    <div class="card card-nobottom">
                                        <div class="card-body card-body-2 bg-light">
                                            <h5 class="card-title">Geolocalizzazione Automatica tramite Google Maps
                                                <code class="d-block font-size-base">(permette di individuare la struttura tramite Google)</code>
                                            </h5>
                                            <div class="row">
												<div class="col-md-6 form-group-1">
													<label class="text-muted custom-control custom-control-right">Inserire nome della Nazione.</label>
													<AddressField @selezionato="indirizzoSelezionato($event)" v-model="statoSelezionato.NOME" :placeholder="statoSelezionato.NOME"></AddressField>
												</div>
												<div class="col-6 col-md-3 form-group-1">
													<label class="text-muted custom-control custom-control-right">Latitudine</label>
													<input type="text" class="form-select form-select-sm" v-model="statoSelezionato.LATITUDINE">
												</div>
												<div class="col-6 col-md-3 form-group-1">
													<label class="text-muted custom-control custom-control-right">Longitudine </label>
													<input type="text" class="form-select form-select-sm" v-model="statoSelezionato.LONGITUDINE">
												</div>
                                            </div>
                                        </div>
                                    </div>                                    
                                
                                </div>
                            </div>

						</div>
					</div>

                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaStato()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>                
            </div>
        </div>
	</div>	 


    <div class="modal fade" :id="'popUpUploadImgStato'+rs" role="dialog" aria-modal="true" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Nazione</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsStato" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>

 
</template>



<script> 

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import Flag from '@/components/utils/Flag.vue'
import Multiselect from 'vue-multiselect'
import AddressField from '@/components/utils/AddressField.vue'	
import SchedaVisualizzazioneGeo from '@/components/SchedaVisualizzazioneGeo.vue'


export default {
    name:'GeoStati',	   
	props:['modulo'],

    components:{
        Pagine,
        ISwitch,
		Select2,
        draggable: VueDraggableNext,
		SelectLingue,
        Upload,
		Flag,
        AddressField,
        //GoogleMaps
		SchedaVisualizzazioneGeo,

    }, 

    data: function () {
		return {
			apiStartup:'travel/geo/stati',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			rs : utils.generateRandomString(30),
        	arrayStati:new Array(),
        	statoSelezionato:{lang:{}},
            arrayFormElementsStato:new Array(),
			arrayContinenti : new Array(),
	        search:{
	          	PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_STATO:{TYPE:'TEXT',VALUE:''},
	          	TITOLO:{TYPE:'TEXT',VALUE:''},
	          	COUNTRY_CODE:{TYPE:'TEXT',VALUE:''},
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},	
				FK_ID_CONTINENTE:{TYPE:'COMBO',VALUE:''},	
			},
			currentTab:1,
			ordineHasChanged: false,
			arrayEntiSelect2:new Array(),
        	selectedMenuItem:{},
			currentLang:0,
			contenutoLangSelezionato:{},
			arrayCategorie:new Array(),
            argsStato:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:1
			},

      	}      
    },

    methods: {  
      	getStati : function() {
	     	utils.ajax(this.apiStartup,{LANG:this.currentLang}, (response) => {
				this.ordineHasChanged = false;
	          	this.arrayStati = response.data;
	        });
 		},

        nuovoStato : function(){
			this.statoSelezionato = {ID_STATO:0,lang:{}};
			this.filters.openPopUp("popUpStato");
        },

        modificaStato : function(t){
			utils.ajax('travel/geo/stato',{ID_STATO:t.ID_STATO,ID_GEO_LANG:t.ID_GEO_LANG}, (response) => {
				if (response.esito == 0){
					this.statoSelezionato = utils.clone(response.data);
					this.argsStato.ID_STATO = t.ID_STATO;
					this.filters.openPopUp("popUpStato");
				}
			});
        },

        salvaStato : function(){
			utils.salvaElemento("travel/geo/stato/",this.statoSelezionato,"ID_STATO","popUpStato",(response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
					this.getStati();
				}
			});				
        },	

        updateStatoStato : function(stato){
            setTimeout(() => {
                utils.ajax("travel/geo/gen/stato/update",stato, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },
        
        deleteStato : function(t){
            utils.alert.confirm("Sicuro di voler eliminare lo Stato " + t.NOME + "?",()=>{
                utils.ajax('core/basic/delete',{className:'Stato',id:t.ID_STATO}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getStati();
                    }
                });
            });
        },
        
		updateFlgVisibile : function(stato){
			stato.FLG_VISIBILE = stato.FLG_VISIBILE == 1 ? 0 : 1;
			comune.FK_ID_TIPO = 1;
			utils.ajax("travel/geo/visibilita/update",stato, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					stato.FLG_VISIBILE = stato.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},

		updateFlgInHome : function(stato){
			stato.FLG_INHOME = stato.FLG_INHOME == 1 ? 0 : 1;
			utils.ajax("travel/geo/update",stato, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					stato.FLG_INHOME = stato.FLG_INHOME == 1 ? 0 : 1;
				}
			});                
        },	
        
 		openSchedaStato : function(stato){
			this.$root.geoLangSelezionato = utils.clone(stato);
			global.router.push("GeoContenutoLang");
		},	

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaGeoStati',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayStati = this.filters.draggableChangeHandler(event,this.arrayStati,this.search,"ID_STATO");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayStati.length ; i++){
					arr.push({"ID": this.arrayStati[i].ID_STATO,"O": this.arrayStati[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Stato',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getStati();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

        uploadImmagineStato : function(){
            this.filters.openPopUp("popUpUploadImgStato"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgStato"+this.rs);
            utils.ajax("travel/geo/stato",{ID_STATO:this.statoSelezionato.ID_STATO}, (response) => {
                this.statoSelezionato = response.data;
				this.getStati();
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgStato"+this.rs);
        },

        indirizzoSelezionato : function(place){
            utils.google.importaPlace(place,this.stato);
            this.$refs.mappa.startup(this.stato.LATITUDINE,this.stato.LONGITUDINE);        
        },

		getLabelContinente : function(stato){
			var c = utils.array.select(utils.arrayContinenti,"ID_CONTINENTE",stato.FK_ID_CONTINENTE,1)[0];
			return c != undefined ? c.DESCRIZIONE : "-";
		},
        
		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneGeo : function(stato,tab = ""){
			this.statoSelezionato = utils.clone(stato);	
			this.$refs.schedaVisualizzazioneStato.openSchedaVisualizzazioneGeo(stato,tab);
		},
	 
    },

    computed: {
      	paginatedListStati(){
        	var arr = utils.inGrid(this.arrayStati,this.search);
        	return arr;
      	},

		getTotali(){
			var attive = 0;
            var inattive = 0; 
			for (var i = 0; i < this.arrayStati.length; i++) {
				if (this.arrayStati[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
            return {
                COUNT:this.arrayStati.length,
				ADVOK:attive,
				ADVKO:inattive
            }
        },


    },

    created:function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }
		this.arrayContinenti = utils.clone(utils.arrayContinenti);
		this.currentLang = this.$root.currentLang;
		utils.ajax("travel/geo/stati/startup",{LANG:this.currentLang}, (response) => {
			this.ordineHasChanged = false;
			this.arrayStati = response.data.STATI;
			this.arrayCategorie = this.filters.toSelect2(response.data.CATEGORIE,"ID_CATEGORIA","DESCRIZIONE");
			this.getStati();
		});
    }

}
</script>