<template>

    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
					<a href="#" class="d-inline-block text-dark"> Moduli </a> \ 
                    <span class="text-muted">Elenco Routers</span>
					<code> - Lingua italiano</code> 
                </h4>
                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Routers </h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoRouter()"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="$root.confTravelTipologiche()"><i class="icon-power2"></i><span>OPZIONI</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-center d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Menu Routers
				</button>
    		</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#router-a" class="navbar-nav-link active" data-bs-toggle="tab">
							<i class="icon-menu7 me-2"></i> Elenco Routers
						</a>
					</li>
				</ul>

				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item">
						<div class="btn-group btn-group-sm justify-content-center">
							<a href="#" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-cog5 me-1"></i> Azioni Multiple</a>
							<div class="dropdown-menu">
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="icon-trash"></i> Elimina</a>
							</div>
						</div>
					</li>
					<li class="nav-item ms-4">
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="router-a">
        		<div class="card shadow mb-0">
		            <div class="card-body card-body-2">
	                    <div class="alert alert-2 alert-secondary alert2 border-0">
							<form action="#" class="">
		                    	<div class="row ">
			                  		<div class="col-5 col-md-1 form-group-filter">
			                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Router" :tabindex="1" />
			                    	</div>	
		                            <div class="col-7 col-md-1 form-group-filter">
		                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" />
		                            </div>                
		                            <div class="col-12 col-md-2 form-group-filter">
		                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome" :tabindex="3" />
		                            </div>
		                            <div class="col-md-2 form-group-filter">
		                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Email" :tabindex="4" />
		                            </div>
									<div class="col-12 col-md-6">
										<div class="row">
											<div class="col-12 col-md-4 form-group-filter">
		
											</div>
											<div class="col-12 col-md-6 form-group-filter">
	
											</div>
											<div class="col-12 col-md-2 form-group-filter">
												<select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="4" v-model="search.FLG_ATTIVO.VALUE">
				                                    <option value="">Tutti </option>   
				                                    <option value="1"> &nbsp; Attivati</option>
				                                    <option value="0"> &nbsp; Disattivati</option>
				                                </select>
				                            </div>
								        </div>
					          		</div> 
				          		</div> 
							</form>  
		            	</div>
					</div>
				
			        <div class="card-body card-body-2 card-body-nobottom card-body-notop">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead>
			                        <tr>
		                                <th class="table-head-sm" style="width:2%;"> <input id="chkAllRouters" v-on:click="filters.selectAll('chkAllRouters',paginatedList.arr)" type="checkbox" class=""> </th>
		                                <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
		                                <th class="table-head-sm" style="width:6%;"> # </th>
		                                <th class="table-head-sm" style="width:4%;"> Img </th>
		                                <th class="table-head-sm">Denominazione</th>
		                                <th class="table-head-sm" style="width:10%"><span>Contratto</span></th>
		                                <th class="table-head-sm" style="width:10%"><span>CF/PI</span></th>
		                                <th class="table-head-sm" style="width:15%;"><span>EMAIL/<br />TEL</span></th>
		                                <th class="table-head-sm" style="width:5%;">Hits</th>
		                                <th class="table-head-sm" style="width:5%;">Stato</th>
		                            	<th class="table-head-sm" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
								<tbody>
		                            <tr v-for="router in paginatedList.arr" v-bind:key="router.ID_ROUTER">
		                                <td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="router.SELEZIONATO" class=""> </td>
		                                <td class="text-center"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="fs-12">
											{{router.ID_ROUTER}}<br />
										</td>
		                                <td class="text-center">
											<img v-if="router.ID_ROUTER != 'placeholder.jpg'" :src="filters.getImgPath('ROUTERS',router.ID_ROUTER)" class="rounded" width="50" height="35" alt="" />
											<div v-if="router.ID_ROUTER == 'placeholder.jpg'" class="btn-nophoto">No<br />Photo</div>
		                                </td>
		                                <td class="">
											<span v-on:click="modificaRouter(router)" class="text-primary fw-bold cursor-pointer">titolo</span>
		                                    <span class="badge badge-flat border-secondary text-secondary font-size-xs text-muted float-end"><i class="fal fa-calendar-alt fa-fw"></i> data</span><br />
		                                    <span class="badge badge-flat border-secondary text-secondary font-size-xs text-muted"> </span>
										</td>
		                                <td class=""></td>
		                                <td class=""></td>
		                                <td class="fs-12">
		                                    <i class="fal fa-envelope fa-fw"></i> 
		                                    <br />
		                                    <button class="badge badge-flat border-secondary text-secondary font-size-xs text-muted"><i class="far fa-sign-in-alt fa-fw fa-lg"></i> Accedi ora</button>
										</td>
		                                <td class="fs-12">1</td>
		                                <td class="text-center">
		                                    <ISwitch @toggle="updateStatoRouter(router)" :size="'sm'" v-model="router.STATO" style="z-index: 0;"></ISwitch>
		                                </td>
		                                <td class="text-center">
											<div class="list-icons" :tabindex="26">
		                                        <div class="dropdown">
		                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
		                                            <div class="dropdown-menu dropdown-menu-right">
		                                                <a href="#" class="dropdown-item" v-on:click="modificaRouter(router)"><i class="fal fa-pencil fa-fw"></i> Modifica Agenzia</a>
		                                                <div class="dropdown-divider"></div>
		                                                <a href="#" class="dropdown-item" v-on:click="eliminaRouter(router)"><i class="fal fa-ban fa-fw"></i> Sospendi Agenzia</a>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </td>
		                            </tr>
								</tbody>
		                    </table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                </select>
                            </div> 
                            </div>  
		
                        </div>
					</div>  
                </div>

			</div>   
		</div>


        <!-- Modal -->
        <div class="modal fade" id="popUpRouter"  role="dialog"  aria-modal="true" >
            <div class="modal-dialog modal-lg"  >
                <div class="modal-content" >
                	<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title"> SCHEDA  LOCANDINA </h6>                                                        
                    <div class="btn-group float-end" :tabindex="2">
                        <button type="button" class="btn btn-yellow btn-yellow-100 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> AZIONI
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
	
                    <div class="d-block d-sm-flex">
                        <div class="profile-tab-nav border-right">
                            <div class="p-3">
                                <div class="img-circle text-center cursor-pointer mb-0" v-on:click="uploadImmagineContenuto()">
                                    <img src="" class="rounded shadow" alt="" />
									<span class="btn-nophoto" style="border-radius: 1rem; padding: 2rem 3.5rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</span>
                                </div>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="padding-right: 5px !important; min-width: 13rem;">
                                <a class="nav-link strech-nav-pill " id="cont-main-tab" data-bs-toggle="pill" href="#cont-main" role="tab" aria-controls="cont-main" aria-selected="true">
                                    <i class="fal fa-info-square fa-fw"></i> Info Generali
                                </a>
                                <a  class="nav-link strech-nav-pill" id="cont-testi-tab" data-bs-toggle="pill" href="#cont-testi" role="tab" aria-controls="cont-testi" aria-selected="false">
                                    <i class="fal fa-text fa-fw"></i> Gestione Contenuti 
                                </a>
                            </div>
                        </div>
                        <div class="tab-content p-md-2" style="padding: 0px 0px 0px 10px !important;" id="v-pills-tabContent">

                            <div class="tab-pane fade" id="cont-main" role="tabpanel" aria-labelledby="cont-main-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-info-square fa-fw me-1"></i> Info Generali</h3>
			                    <div class="box_26"> 

                                    <div class="row mt-1">
                                        <div class="col-6 col-md-3 form-group-1">
                                            <label class="text-danger custom-control custom-control-right">Modello Contenuto		
                                                <span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Modello Contenuto" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
											</label>
                                            <select  class="form-control form-control-sm">
                                                <option v-bind:value="0">Tutti</option>
                                                <option></option>
                                            </select>  
                                        </div>
										<div class="col-6 col-md-3 form-group-1">
										</div>
                                        <div class="col-12 col-md-6 form-group-1"> 
                                            <label class="text-danger custom-control custom-control-right">Titolo:</label>
                                            <div class="input-group input-group-sm" :tabindex="3">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-pennant fa-fw"></i></span></span>
                                                <input autocomplete="off" type="text" class="form-control form-control-sm valid" placeholder="Descrizione" :tabindex="4">
                                                <div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
                                            </div>
                                        </div>
									</div>

                                    <hr class="hr-1" />

                                    <div class="row mt-2">	
                                        <div class="col-12 col-md-6">

                                            <div class="row">
		                                        <div class="col-12 col-md-12 form-group-1"> 
		                                            <label class="text-danger custom-control custom-control-right">Sottotitolo:</label>
		                                            <div class="form-group-feedback form-group-feedback-left">
		                                                <input autocomplete="off" type="text" class="form-control form-control-sm" :tabindex="4">
		                                                <div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
		                                            </div>
		                                        </div>

                                                <div class="col-md-12 form-group-1">
                                                    <label class="text-danger custom-control custom-control-right">Visibile nel Modulo
		                                                <span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tematiche associate" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
		                                            </label>
		                                            <Select2 ></Select2>
		                                        </div>	 

                                             	<div class="col-md-12 form-group-1">
                                                    <label class="text-danger custom-control custom-control-right">Pubblica sul Sito
                                                        <span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tematiche associate" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
                                                    </label>
                                                    <div class="input-group input-group-sm" :tabindex="15">
                                                    <span class="btn btn-light btn-sm" v-on:click="prodotto.enti = filters.select2AddAll(router.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span>
                                                        <Select2></Select2>
                                                    </div>
                                                </div>  
                                    		</div>

                                		</div>
                                        <div class="col-12 col-md-6" style="border-left: 1px solid #ddd;">
                                            
                                            <div class="row">
                                                <div class="col-12 col-md-12 form-group-1">
                                                    <label class="text-danger custom-control custom-control-right">Custom 1
                                                        <span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tematiche associate" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 1">
                                                </div>
                                                <div class="col-12 col-md-12 form-group-1">
                                                    <label class="text-danger custom-control custom-control-right">Custom 2
                                                        <span class="pop_over float-end" data-popup="popover-custom" data-placement="left" data-trigger="hover" data-html="true" title="" data-original-title="Tematiche associate" data-content="assss"><i class="far fa-info-circle text-primary"></i></span>
													</label>
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 2" >
                                                </div>

                                                <div class="col-12 col-md-6 form-group-1"></div>
                                                <div class="col-12 col-md-6 form-group-1">
                                                    <label class="text-danger custom-control custom-control-right">Stato</label>
                                                    <select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="13" >  
                                                        <option value="1"> &nbsp; Attivata</option>
                                                        <option value="0"> &nbsp; Disattivata</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

			                <div class="tab-pane fade" id="cont-testi" role="tabpanel" aria-labelledby="cont-testi-tab">  
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-text fa-fw"></i> Gestione Contenuti</h3>      
                                <ul class="nav nav-tabs nav-tabs-highlight mb-0">
                                    <li class="nav-item"><a href="#bordered-TESTO_HOME" class="nav-link active" data-bs-toggle="tab">Intro</a></li>
			                    </ul>
                                <div class="tab-content border-top-0 rounded-top-0 mb-0">

                                    <div class="tab-pane fade show active me-2" id="bordered-TESTO_HOME">
                                        <div class="alert alert-info alert-styled-left alert-dismissible mt-1 mb-1">
                                            <button type="button" class="close" data-dismiss="alert"><span>X</span></button>
                                            lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                        </div>
                                        <ckeditor :editor="editor" v-model="routerSelezionato.TESTO_NOTE" :config="editorConfig"></ckeditor>

                                    </div>  
                                    
			                    </div> 
					   	    </div>

					    </div>	 
                    </div>                    
	                     
                       
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="salvaRouter()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
	</div>	 
 

    <div class="modal fade" :id="'popUpUploadImgRouter'+rs" role="dialog" aria-modal="true" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Router</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsRouter" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button type="button" class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div> 
	</div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Upload from '@/components/utils/Upload.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
    name:"Routers",

    components:{
        Pagine,
        ISwitch,
		Select2,
        Upload,
    },

    data: function (){
      	return{
            apiStartup:'travel/routers',
	        filters:global.filters,	
            GLOBAL_IMAGES_PATH:'',
	        arrayRouters: new Array(),
            routerSelezionato:{enti:new Array()},
            routerCorrente:{},
            arrayCampiPopUpRouters : new Array(),
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_ROUTER:{TYPE:'TEXT',VALUE:''},	
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
			rs : utils.generateRandomString(30),
            argsRouter:{
                modalita:'MOONLIGHT_uploadImgRouter',
				tipologia:1
			}, 
			currentLang:0,
			editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },

      	}
    },

    methods: {
        getRouters: function () {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayRouters = response.data;
            }); 
        }, 

        nuovoRouter : function(){ 
			this.routerSelezionato = {ID_ROUTER:0};
            this.filters.openPopUp("popUpRouter");
            $('#cont-main-tab').click();
        },	

        modificaRouter : function(t){
			utils.ajax(this.apiStartup,{ID_ROUTER:t.ID_ROUTER}, (response) => {
				if (response.esito == 0){
					this.routerSelezionato = utils.clone(response.data);
					this.argsRouter.popUpRouter = t.ID_ROUTER;
					this.filters.openPopUp("popUpRouter");
				}
			});
        },

        salvaRouter : function(){
            var api = this.routerSelezionato.ID_ROUTER > 0 ? "travel/router/update" : "travel/router/insert";
            utils.ajax(api,this.routerSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (this.routerSelezionato.ID_ROUTER == 0){
                        this.routerSelezionato.ID_ROUTER = response.id_inserito;
                    }
                    utils.ajax('travel/router',this.routerSelezionato, (response) => {                        
                        //this.filters.closePopUp("popUpRouter");
                        utils.ajax('travel/routera',{}, (response) => {
                            this.arrayRouters = response.data;
                        });
                    }); 
                }
            });
        },  

		updateFlgVisibile : function(router){
			stato.FLG_VISIBILE = stato.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/router/visibilita/update",stato, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					stato.FLG_VISIBILE = stato.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},

        updateStatoRouter : function(router){
            setTimeout(() => {
                utils.ajax('core/router/stato/update',router, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.filters.closePopUp("popUpRouter");  
                    }  
                }); 
            },250);
        }, 

         deleteCategoria : function(t){
            utils.alert.confirm("Sicuro di voler eliminare questo Router " + t.NOME + "?",()=>{
                utils.ajax('travel/router/delete',{className:'Router',id:t.ID_ROUTER}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getRouters();
                    }
                });
            });
        },

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaRouters',formato:formato});
      	},

        uploadImmagineRouter : function(){
            this.filters.openPopUp("popUpUploadImgRouter"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgRouter"+this.rs);
            utils.ajax("travel/routers/detail",{ID_ROUTER:this.routerSelezionato.ID_ROUTER}, (response) => {
                this.routerSelezionato = response.data;
				this.getRouters();
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgRouter"+this.rs);
        },
	         
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayRouters,this.search);
            return arr;
        },
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }
        this.routerCorrente = utils.clone(this.$root.Router);
		utils.ajax(this.apiStartup,{}, (response) => {
           this.arrayRouters = response.data;
        });
    }

}
</script>