<template>
    <img :src="getFlagUrl()" alt="Flag" style="width:20px;"/>
</template>

<script>
export default {
    name:"Flag",
    props:['country'],
    data:function(){
        return{
            filters:global.filters
        }
    },
    methods:{
        getFlagUrl : function() {
            var country = utils.array.select(this.$root.arrayGeoStati,"ID_STATO",this.country,1)[0];
            if (country != undefined) {
                const url = process.env.VUE_APP_GLOBAL_IMAGES_PATH + "/FLAGS/"+country.COUNTRY_CODE.toString().toLowerCase() + ".svg";
                return url;
            }            
        }
    }
}
</script>