<template>
    <div>

        <!-- Page header -->
        <div class="page-header page-header-light">
            <div class="page-header-content header-elements-lg-inline">
                <div class="page-title d-flex">
                    <h4 class="d-none d-sm-block">
                        <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
						<a href="#" class="d-inline-block text-dark"><span class="font-weight-semibold"> Tessere </span></a> \ 
						<router-link href="#" class="d-inline-block text-dark" :to="'ListCards'"><span class="font-weight-semibold"> Elenco Stagioni </span></router-link> \ 
						<router-link href="#" class="d-inline-block text-dark" :to="'Range'"><span class="font-weight-semibold"> Elenco Range </span></router-link> \ 
                        <span class="text-muted">Gestione Tessere</span>
						<code> - Lingua italiano</code> 
                    </h4>
                    <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Gestione Tessere </h4>
                    <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
                </div>
                <div class="header-elements d-none">
                    <div class="d-flex justify-content-center">
                        <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovaAssicurazione()"><i class="icon-plus-circle2"></i><span>ASSICURAZIONI</span></a>
                        <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovaStagione()"><i class="icon-bars-alt"></i><span>STAGIONI</span></a>
	                    <div class="btn-group ms-1 position-static">
							<button type="button" class="btn btn-link btn-float text-body dropdown-toggle dropdown-toggle-2" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="icon-power2"></i> <span>OPZIONI</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right" style="">
								<a href="#" class="dropdown-item"><i class="icon-cube4"></i> Configurazione Modulo</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item" v-on:click="$root.confTravelTipologiche()"><i class="icon-grid"></i> Configurazione Plug-In</a>
							</div>
						</div>	                    
                        <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <!-- Content area -->
		<div class="content content-2">

            <div class="card shadow mb-0">
                <div class="card-header card-header-2 bg-transparent header-elements-sm-inline py-sm-0">
                    <h6 class="card-title py-sm-2 d-none d-sm-block">
						<div class="btn-group position-static">
							<button type="button" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle btn-sm me-4" data-bs-toggle="dropdown" aria-expanded="false" :tabindex="1"><b><i class="icon-cog5 "></i></b> Azioni Multiple</button>
							<div class="dropdown-menu dropdown-menu-left dropdown-menu-sm" style="">
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="icon-trash"></i> Elimina</a>
							</div>
						</div>
                    </h6>
					<div class="header-elements">
						<form action="#" class="me-3 cell-150" :tabindex="1">
							<select class="form-control form-control-sm" data-placeholder="Seleziona Anno">
								<option>Anno</option>
							</select>
						</form>
						<div class="list-icons">
							<a class="list-icons-item" data-action="collapse"></a>
						</div>
					</div>
				</div>
				<div class="collapse show">
					<div class="card-body card-body-2">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							<form action="#" class="">
								<div class="row">
									<div class="col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" name="userdata_surname" placeholder="Cognome">
									</div>
									<div class="col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" name="userdata_name" placeholder="Nome">
									</div>
									<div class="col-md-1 form-group-filter">
										<input autocomplete="off" value="" class="form-control form-control-sm" type="text" name="client_id" placeholder="N° transazione">
									</div>
									<div class="col-md-1 form-group-filter">
										<input autocomplete="off" value="" class="form-control form-control-sm" type="text" name="client_pnr" placeholder="Ordine n°">
									</div>
									<div class="col-12 col-md-3">
										<div class="row ">
											<div class="col-6 col-md-6 form-group-filter">
												<div class="input-group">
													<span class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar22"></i></span></span>
													<DatePicker class="form-control form-control-sm"></DatePicker> 
												</div>
											</div>
											<div class="col-6 col-md-6 form-group-filter">
												<div class="input-group">
													<DatePicker class="form-control form-control-sm"></DatePicker> 
													<span class="input-group-append"><span class="input-group-text"><i class="icon-calendar22"></i></span></span>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-3">
										<div class="row">
											<div class="col-md-5 form-group-filter">
												<input autocomplete="off" value="" class="form-control form-control-sm" type="text" name="card_client_number_from" placeholder="N° tessera Raspa Dal">
											</div>
											<div class="col-md-5 form-group-filter">
												<input autocomplete="off" value="" class="form-control form-control-sm" type="text" name="card_client_number_to" placeholder="N° tessera Raspa Al">
											</div>
											<div class="col-6 col-md-2 form-group-filter">
												<a href="#collapse-icon" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="14">
													<span style="font-weight: 500;">S</span>
												</a>
											</div>  
										</div>
									</div>
								</div>
								<div class="collapse show" id="collapse-icon" style="">
									<div class="row">
										<div class="col-12 col-md-7">
											<div class="row">
												<div class="col-md-3 form-group-filter">
													<select class="form-control form-control-sm" name="card_client_status" data-placeholder="Seleziona Stato RASPA">
														<option value="">Seleziona Stato Raspa</option>
														<option value="pending">pending</option>
														<option value="active">active</option>
														<option value="deactive">deactive</option>
														<option value="canceled">canceled</option>
													</select>   
												</div>
												<div class="col-md-3 form-group-filter">
													<select class="form-control form-control-sm" name="card_status_print" data-placeholder="Seleziona Stampa RASPA">
														<option value="">Seleziona Stampa Raspa</option>
														<option value="1">STAMPATE</option>
														<option value="2">DA STAMPARE</option>
													</select>    
												</div>	
												<div class="col-md-3 form-group-filter">
													<select class="form-control form-control-sm" name="card_status_ship" data-placeholder="Seleziona Invio RASPA">
														<option value="">Seleziona Invio Raspa</option>
														<option value="3">SPEDITE</option>
														<option value="4">DA SPEDIRE</option>
													</select>   
												</div>
												<div class="col-md-3 form-group-filter">
													<select class="form-control form-control-sm" name="card_status_email" data-placeholder="Seleziona Email RASPA">
														<option value="">Seleziona Email Raspa</option>
														<option value="5">INVIATE</option>
														<option value="6">DA INVIARE</option>
													</select>   
												</div>
											</div>
										</div>	
										<div class="col-12 col-md-5">
											<div class="row">
												<div class="col-md-4 form-group-filter">
													<select class="form-control form-control-sm" name="card_generic_type" data-placeholder="Seleziona Tipo Tessera">
														<option value="">Seleziona Tipo Tessera</option>
														<option value="wtp">WTP</option>
														<option value="goskipass">GOSKIPASS</option>
													</select>
												</div>    
												<div class="col-md-4 form-group-filter">
													<input value="" class="form-control form-control-sm" type="text" name="card_number" placeholder="N° Tessera Generica">
												</div>
												<div class="col-md-4 form-group-filter">
													<select class="form-control form-control-sm" name="card_generic_status" data-placeholder="Seleziona Stato Generica">
														<option value="">Seleziona Stato Generica</option>
														<option value="active">ATTIVE</option>
														<option value="deactive">DA ASSEGNARE</option>
														<option value="pending-0">DA RICHIEDERE</option>
														<option value="pending-1">DA ATTIVARE</option>
													</select>
												</div>
											</div>	
										</div>	
									</div>
								</div>	
							</form>
						</div> 
					</div>
				</div>
				<div class="card-body card-body-2 card-body-nobottom card-body-notop">
					<div class="tableFixHead">
						<table class="table table-bordered table-hover table-striped table-togglable"> 
							<thead>
								<tr>
									<th class="table-head-sm" rowspan="2" style="width:2%;"> <input id="chkAllCategorie" v-on:click="filters.selectAll('chkAllCategorie',paginatedList.arr)" type="checkbox" class=""> </th>
									<th class="table-head-sm" rowspan="2" style="width:6%;"> # </th>
									<th class="table-head-sm" rowspan="2" style="">Ente / Ragione Sociale</th>
									<th class="table-head-sm text-center" colspan="5" style="">RASPA</th>
									<th class="table-head-sm text-center" colspan="4" style="">TESSERE GENERICHE</th>
								</tr>
								<tr>
									<th class="table-head-sm text-center" style="width:7%;">N° Raspa</th>
									<th class="table-head-sm text-center" style="width:7%;">Prezzo</th>
									<th class="table-head-sm text-center" style="width:7%;">Stato</th>
									<th class="table-head-sm text-center" style="width:7%;">Stampa</th>
									<th class="table-head-sm text-center" style="width:10%;">Spedizioni</th>
									<th class="table-head-sm text-center" style="width:15%;">N° Tessera</th>
									<th class="table-head-sm text-center" style="width:7%;">Stato</th>
									<th class="table-head-sm text-center" style="width:10%;">Spedizioni</th>
									<th class="table-head-sm text-center" style="width:2%;">X</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
									<td class=""></td>
									<td class="">
										<span class="text-primary fw-bold cursor-pointer">Raspagliesi Marco <small class="float-end">(1985)</small></span><br />	                                   
										<span class="text-muted">Raspagliesi Marco</span> 
									</td>
									<td class="text-center"><span class="badge bg-light">230000000</span></td>
									<td class="text-center"><span class="badge bg-light">&euro; 24</span></td>
									<td class="text-center"><span class="badge badge-success">attiva</span></td>
									<td class="text-center"><span class="badge badge-flat border-secondary text-secondary">stampata</span></td>
									<td class="text-center"><span class="badge badge-flat border-danger text-danger">da spedire</span></td>
									<td class="text-center"><span class="badge bg-light text-primary"></span></td>
									<td class="text-center"><span class="badge badge-success"></span></td>
									<td class="text-center"><span class="badge badge-flat border-danger text-danger"></span></td>
									<td class="text-center"><span class="badge badge-flat border-light text-dark cursor-pointer"></span></td>
								</tr>
								<tr>
									<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
									<td class=""></td>
									<td class="">
										<span class="text-primary fw-bold cursor-pointer"></span><br />	                                   
										<span class="text-muted">&nbsp;</span> 
									</td>
									<td class="text-center"><span class="badge bg-light"></span></td>
									<td class="text-center"><span class="badge bg-light"></span></td>
									<td class="text-center"><span class="badge badge-success"></span></td>
									<td class="text-center"><span class="badge badge-flat border-secondary text-secondary"></span></td>
									<td class="text-center"><span class="badge badge-flat border-danger text-danger"></span></td>
									<td class="text-center"><span class="badge bg-light text-primary">WTP: N9811007-58A-Y4Q</span></td>
									<td class="text-center"><span class="badge badge-success">attiva</span></td>
									<td class="text-center"><span class="badge badge-flat border-danger text-danger">da ritirare</span></td>
									<td class="text-center"><span class="badge badge-flat border-light text-dark cursor-pointer">X</span></td>
								</tr>
								<tr>
									<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
									<td class=""></td>
									<td class="">
										<span class="text-primary fw-bold cursor-pointer"></span><br />	                                   
										<span class="text-muted">&nbsp;</span> 
									</td>
									<td class="text-center"><span class="badge bg-light"></span></td>
									<td class="text-center"><span class="badge bg-light"></span></td>
									<td class="text-center"><span class="badge badge-success"></span></td>
									<td class="text-center"><span class="badge badge-flat border-secondary text-secondary"></span></td>
									<td class="text-center"><span class="badge badge-flat border-danger text-danger"></span></td>
									<td class="text-center"><span class="badge bg-light text-primary">GOSKIPASS: 0551121166-5</span></td>
									<td class="text-center"><span class="badge badge-success">attiva</span></td>
									<td class="text-center"><span class="badge badge-flat border-danger text-danger">da ritirare</span></td>
									<td class="text-center"><span class="badge badge-flat border-light text-dark cursor-pointer">X</span></td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="row">
						<div class="col-md-11" :tabindex="27">
							<div class="float-end">
								<Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
							</div>
						</div>
						<div class="col-md-1" :tabindex="28">
							<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="75">75</option>
								<option value="100">100</option>
								<option value="250">250</option>
								<option value="500">500</option>
							</select>
						</div>
					</div>	

				</div>	
			</div>

		</div>
	
		<!-- Modal -->

        
    </div>
</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'
//import ISwitch from '@/components/utils/ISwitch.vue'

export default {
    name:"Cards",
    components:{
        Pagine,
        //ISwitch,
    },
    data: function (){
      	return{
            apiStartup:'travel/categorie',
	        filters:global.filters,	
	        arrayCategorie:new Array(),
            arrayCampiPopUpCategorie : new Array(),
            categoriaSelezionata:{},
            categoriaCorrente:{},
            GLOBAL_IMAGES_PATH:'',
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},

	        },
      	}
    },

    methods: {
        getCategorie: function () {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayCategorie = response.data;
            }); 
        },

        nuovaCategoria : function(){ 
			this.categoriaSelezionata = {ID_CATEGORIA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
			this.filters.openPopUp("popUpCategoria");
        },

        modificaCategoria : function(categoria){
            this.categoriaSelezionata = utils.clone(categoria);
            this.filters.openPopUp("popUpCategoria"); 
        },

        salvaCategoria : function(){
            var api = this.categoriaSelezionata.ID_CATEGORIA > 0 ? "travel/categoria/update" : "travel/categoria/insert";
            utils.ajax(api,this.categoriaSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpCategoria");  
                    utils.ajax(this.apiStartup,{}, (response) => {
                        this.arrayCategorie = response.data;
                    });
                }
            });
        }, 

        updateStatoCategoria : function(categoria){
            setTimeout(() => {
                utils.ajax('travel/categoria/stato/update',categoria, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.filters.closePopUp("popUpCategoria"); 
                    }  
                }); 
            },250);
        }
        
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayCategorie,this.search);
            return arr;
        },
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.categoriaCorrente = utils.clone(this.$root.categoria);
        utils.ajax(this.apiStartup,{}, (response) => {
           this.arrayCategorie = response.data;
        });
    },

}
</script>