<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer"> Preferenze </a> \
                    <span class="text-muted">Configurazione Aziende </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;">
                        <select class="form-select form-select-sm" data-placeholder="Ente" v-model="idEnteCorrente" @change="getConfigurazioniEnte()">
                            <option v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE" v-bind:value="ente.ID_ENTE"> {{ ente.LABEL }}</option>
                        </select>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(livello == 2 ? 21 : 22)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(livello == 2 ? 21 : 22)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#webconf-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>Info
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#webconf-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-pencil7 me-2"></i>Testi
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#webconf-d" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-images2 me-2"></i>Template
							</div>
						</a>
					</li>
				</ul>

 
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">

						</li>
						<li class="nav-item">
	                        
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
    <!-- /page header -->
    
    <!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="webconf-a">
        		<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI INFO
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
                            <div class="row">
                                <div class="col-12 col-md-10 col-lg-10 mbx-1">
                                    <div class="alert alert-2 alert-secondary border-0">
                                        <p style="padding: 2px; position:relative; top:5px;">
											I campi contrassegnati in <span class="text-danger">rosso</span> sono <strong>Obbligatori</strong>. 
				                            Inserire dati corretti e veritieri, la maggior parte sono pubblicati su documenti fiscali.
                                        </p>	  
                                    </div>                              
                                </div>
                                <div class="col-12 col-md-2 col-lg-2 mbx-1">
                                    <button type="submit" class="btn btn-primary float-end" v-on:click="salvaOpzioni()"><i class="icon-floppy-disk me-2"></i>Salva Modifiche</button>
                                </div>
                            </div>
						</div>	
					</div>
		
                    <div class="card-body box-card mt-0 mb-0" id="box-card">
                        <div class="box-config" id="tableFixHead">
			          		<div class="row ">
		                        <div class="col-lg-8">
		                            <fieldset v-if="arrayOpzioniConfigurazione.length > 0">
		                                <legend class="font-weight-semibold p-2 mb-2"><i class="icon-wrench2 me-2"></i> Dati principali</legend>
		                                <div class="row">
		
		                                    <div v-for="opzione in arrayOpzioniConfigurazione[0].opzioni" v-bind:key="opzione.LABEL" class="mb-2" v-bind:class="opzione.COL">
		                                        <label class="form-label text-danger mb-1" v-html="opzione.ETICHETTA"></label>
		                                        <IControl :campo="opzione" v-model="opzione.VALORE" :inputgroup="opzione.GROUP" :icon="opzione.ICON"></IControl>
		                                    </div>
		                                </div>
		                            </fieldset>
		                        </div>
                                <div class="col-lg-4" style="border-left: 1px solid rgba(0,0,0,.125);">
                                    <fieldset class="mb-2">
		                                <legend class="font-weight-semibold p-2 mb-2"><i class="icon-cog3 me-2"></i> Dati Configurazione</legend>
		                                <div class="box_600" style="min-height: 34rem; max-height: 34rem;">
		                                    <div class="row mt-2">                         
		                                        <div class="col-md-12">
		                                            <div class="card-group-control card-group-control-right" id="accordion-control-right">
		                                                <div class="card" v-for="gruppo in arrayOpzioniConfigurazione" v-bind:key="gruppo.id">
		                                                    <div class="card-header card-header-1" :id="'heading_'+gruppo.id" v-if="gruppo.id > 1">
		                                                        <h6 class="card-title">
		                                                            <a class="collapsed text-body" data-bs-toggle="collapse" :href="'#accordion-control-'+gruppo.id"><i class="fa-fw" v-bind:class="gruppo.icona"></i> 
		                                                                {{gruppo.titolo}}
		                                                            </a>
		                                                        </h6>
		                                                    </div>
		                                                    <div :id="'accordion-control-'+ gruppo.id" class="collapse" data-parent="#accordion-control-right" :aria-labelledby="'heading_'+ gruppo.id">
		                                                        <div class="card-body">
		                                                            <div class="row">
		                                                                <div v-for="opzione in gruppo.opzioni" v-bind:key="opzione.LABEL" class="form-group-1" v-bind:class="opzione.COL">
		                                                                    <label>{{opzione.ETICHETTA}}</label>
		                                                                    <IControl :campo="opzione" v-model="opzione.VALORE" :inputgroup="opzione.GROUP" :icon="opzione.ICON"></IControl>
		                                                                </div>
		                                                            </div>                    
		                                                        </div>
		                                                    </div>
		                                                </div>
		                                            </div>  
		                                        </div>   
		                                    </div>
		                                </div>
		                            </fieldset>
		                        </div>                        
                            </div>                    
						</div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="webconf-b">
			</div>

            <div class="tab-pane fade" id="webconf-c">
			</div>
            
       </div>
    </div>

</template>



<script>
import IControl from '@/components/utils/IControl.vue'

export default {
    name:"ConfOwner",
    components:{
        IControl,

    },

    data: function () {
        return {   
            filters:global.filters,
            arrayFunzionalita: new Array(),
            searchFunzionalita:{

            },

            idEnteCorrente:0,
            arrayOpzioniConfigurazione:new Array(),
            arrayOpzioniConfigurazioneDefault : new Array(
                {
                    titolo:"Azienda",
                    icona:'',
                    visible:false,
                    id:1,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:200, ETICHETTA:'Alias Azienda',                            LABEL:'RAG_NAME',                       TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-industry'},
                        {ID_OPZIONE_BASE:201, ETICHETTA:'Ragione Sociale',                          LABEL:'RAG_SOC',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-industry-alt'},
                        {ID_OPZIONE_BASE:202, ETICHETTA:"Nazionalità",                              LABEL:'NATION',                         TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-flag'},                       
                        {ID_OPZIONE_BASE:203, ETICHETTA:'Indirizzo',                                LABEL:'ADDRESS',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-map'},
                        {ID_OPZIONE_BASE:204, ETICHETTA:'Civico',                                   LABEL:'CIVIC',                          TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-align-center'},                        
                        {ID_OPZIONE_BASE:205, ETICHETTA:'Cap',                                      LABEL:'CAP',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-link'},
                        {ID_OPZIONE_BASE:206, ETICHETTA:'Città',                                    LABEL:'CITY',                           TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-city'},
                        {ID_OPZIONE_BASE:207, ETICHETTA:'Provincia',                                LABEL:'PROV',                           TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-circle'},
                        {ID_OPZIONE_BASE:208, ETICHETTA:'Email',                                    LABEL:'EMAIL',                          TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6 ',             CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:209, ETICHETTA:'Email certificata',                        LABEL:'PEC',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-certificate'},                        
                        {ID_OPZIONE_BASE:210, ETICHETTA:'Telefono Ufficio',                         LABEL:'OFFICE_PHONE',                   TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-phone'},
                        {ID_OPZIONE_BASE:211, ETICHETTA:'Codice SDI',                               LABEL:'SDI',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-code'},                     
                        {ID_OPZIONE_BASE:212, ETICHETTA:'Partita IVA',                              LABEL:'IVA',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-balance-scale'},                                            
                        {ID_OPZIONE_BASE:213, ETICHETTA:'Codice fiscale',                           LABEL:'CF',                             TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-barcode'},                                            
                    
                    
                    )
                },
                {
                    titolo:"Societario",
                    icona:'far fa-globe',
					visible:false,
                    id:2,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:220, ETICHETTA:'Amministratore Societario',                LABEL:'CEO',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-user-cog'},
                        {ID_OPZIONE_BASE:221, ETICHETTA:'Licenza Turistica',                        LABEL:'LICENCE',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-file-alt'},
                        {ID_OPZIONE_BASE:222, ETICHETTA:'REA',                                      LABEL:'REA',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-brackets-curly'},
                        {ID_OPZIONE_BASE:223, ETICHETTA:'Capitale Sociale',                         LABEL:'CAPITAL',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-money'},
                        {ID_OPZIONE_BASE:224, ETICHETTA:'Fondo di garanzia',                        LABEL:'GARANTY_FOUND',                  TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-project-diagram'},
                        {ID_OPZIONE_BASE:225, ETICHETTA:'Assicurazione',                            LABEL:'INSURANCE',                      TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-shield-check'},
                    )
                },
                {
                    titolo:"Email",
                    icona:'far fa-paper-plane',
					visible:false,
                    id:3,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:230, ETICHETTA:'Email Amministrazione',                    LABEL:'EMAIL_ADMIN',                    TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:231, ETICHETTA:'Email Documenti',                          LABEL:'EMAIL_DOCS',                     TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:232, ETICHETTA:'Email Gruppi',                             LABEL:'EMAIL_GROUP',                    TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:233, ETICHETTA:'Email Commerciale',                        LABEL:'EMAIL_COMMERCIAL',               TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:234, ETICHETTA:'Email Booking',                            LABEL:'EMAIL_BOOKING',                  TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:235, ETICHETTA:'Email Booking 2',                          LABEL:'EMAIL_BOOKING2',                 TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:236, ETICHETTA:'Email Registrazione Clienti',              LABEL:'EMAIL_CLIENT',                   TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:237, ETICHETTA:'Email Registrazione Agenzie',              LABEL:'EMAIL_ADV',                      TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:238, ETICHETTA:'Email Preventivi Clienti',                 LABEL:'EMAIL_REQUEST_CLIENT',           TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:239, ETICHETTA:'Email Preventivi Agenzie',                 LABEL:'EMAIL_REQUEST_ADV',              TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                    )
                },
                {
                    titolo:"Assistenza",
                    icona:'far fa-life-ring',
					visible:false,
                    id:4,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:240, ETICHETTA:'Titolo',                                   LABEL:'ASSISTANCE_TITLE',               TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',    GROUP:1,    ICON: 'far fa-ballot-check'},
                        {ID_OPZIONE_BASE:241, ETICHETTA:'Data prossima scadenza',                   LABEL:'ASSISTANCE_DATA',                TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',    GROUP:1,    ICON: 'far fa-calendar'},
                        {ID_OPZIONE_BASE:242, ETICHETTA:'Messaggio canone assistenza',              LABEL:'ASSISTANCE_MESSAGE',             TYPE:"TEXTAREA",VALORE:'-', COL:'col-12'},
                    )
                },

            ),
        }
    },

    methods: {
        getOpzioni : function(){
            utils.ajax('core/admin/opzioni',{ID_ENTE:this.idEnteCorrente}, (response) => {
                var arrayOpzioni = response.data;
                this.arrayOpzioniConfigurazione = utils.clone(this.arrayOpzioniConfigurazioneDefault);
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                        this.arrayOpzioniConfigurazione[i].opzioni[j].CLASSE_CTRL = 'input-sm';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].VMODEL = 'VALORE';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = 0;
                        for (var k = arrayOpzioni.length-1 ; k > -1  ; --k){
                            if (this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE_BASE == arrayOpzioni[k].ID_OPZIONE_BASE){
                                this.arrayOpzioniConfigurazione[i].opzioni[j].VALORE = arrayOpzioni[k].VALORE;
                                this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = arrayOpzioni[k].ID_OPZIONE;
                                arrayOpzioni.splice(k,1);
                                break;
                            }
                        }
                    }
                }
                //AGGIUNGO LE OPZIONI CUSTOM DEL SITO
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    if (this.arrayOpzioniConfigurazione[i].id == 100){
                        for (var j = 0 ; j < arrayOpzioni.length ; j++){
                            this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:arrayOpzioni[j].LABEL,VALORE:arrayOpzioni[j].VALORE,TYPE:'TEXTAREA',ID_OPZIONE:arrayOpzioni[j].ID_OPZIONE});
                        }
                        this.$forceUpdate();
                        break;
                    }
                }
            });
        },        

        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                    arr.push(this.arrayOpzioniConfigurazione[i].opzioni[j]);
                }
            }
            utils.ajax('core/admin/opzioni/update',{opzioni:arr,ID_ENTE:this.idEnteCorrente}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getOpzioni();
                }
            });
        },

        getConfigurazioniEnte : function(){
            this.getOpzioni();
        }

    },

    computed: {
        uploadDisabled() {
            return this.files.length === 0;
          }
    },

    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },

    created: function () {
        this.idEnteCorrente = this.$root.idEnteCorrente;
       this.getOpzioni();
    },
}
</script>