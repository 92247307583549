<template>
  	<div>

    	<multiselect 
					v-model="OPZIONE_SELEZIONATA" 
					:track-by="'id'" 
					:value="'Integer'"
					:placeholder="placeholder" 
					open-direction="bottom" 
					:options="options" 					
					:multiple="true" 
					:searchable="true" 
					:loading="isLoading" 
					:internal-search="false" 
					label="text" 
					:clear-on-select="false" 
					:close-on-select="true" 
					:options-limit="300" 
					:limit="10" 
					:max-height="600" 
					:show-no-results="false" 
					:hide-selected="true" 					
					:selectLabel="''"
					>
					<!-- 
					<div class="multiselect__clear" v-if="selectedCountries.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
					-->
					<template v-slot:clear >
						<div class="multiselect__clear" v-if=" this.currentId > 0" @mousedown.prevent.stop="clearAll()"></div>
					</template>
					<template v-slot:noOptions >Scrivi min. 3 caratteri</template>

					
		</multiselect>
  	</div>
</template>


<script>
import { computed,ref } from 'vue'
import Multiselect from 'vue-multiselect'
import { utils } from '../../assets/js/utils';


export default {
	name: 'Multiple',

	components:{
		Multiselect
	},

	data() {
		return {
			isLoading:false,
			currentId:0,
			isWriting : false,
			currentQuery:""
		};
	},

  	emits: ['update:modelValue','selectedItem'],

  	setup(props, { emit,ref,getCurrentInstance,context }) {
	
		const CURRENT_TIME = new Date().getTime();
		const OPZIONE_SELEZIONATA = computed({          	
			get: () => {
				var t = props.modelValue;
				if (t == undefined || t == null){
					return [{id: 0,text:'Selezionare un elemento'}];
				}
				
				if (t.length == 0){
					return [{id: 0,text:'Selezionare un elemento'}];
				}		
				else{
					//var arr = utils.appoggio['select2_'+CURRENT_TIME];
					var arrayReturn = new Array();
					for (var i = 0; i < t.length; i++){
						var opz = utils.array.select(props.options,"id",t[i],1);
						if  (opz != undefined){
							var item = opz[0];
							arrayReturn.push(item);
						}						
					}
					return arrayReturn;
					
				}	
			},
			set : (newVal,oldVal) => {
				console.log("setter newval => " + newVal);
				if (newVal != undefined && newVal != oldVal){
					var arr = new Array();
					for (var i = 0 ; i < newVal.length ; i++){
						if (newVal[i].id > 0){
							arr.push(newVal[i].id);
						}						
					}
					console.log("setterarr => " + arr);

					emit('update:modelValue',arr);
					return arr;				
				}
				return newVal;
			}
		});
		return { OPZIONE_SELEZIONATA, CURRENT_TIME };
  	},

	props: {
		modelValue: [String, Array, Number, Object], // previously was `value: String`
		placeholder: {
			type: String,
			default: ''
		},
		api : {
			type : String,
			default:null
		},
		apiselected:{
			type : String,
			default : null
		},   
		options:{
			type:Object,
			default: () =>{}
		}, 
	},

	watch: {    
		
	},

  	methods: {
		
	
		clearAll:function(){
			//this.formattaInUscita(new Array());
		},
		
	

    

		onSelect : function(){
			console.log("alerting");
			//this.changeHandler();
		}

	},

  	mounted() {
		this.GET_OPZIONI = () => {
			return this.options;
		}
    	return;
	
	},

	beforeUnmount() {
		return;
		this.select2.select2('destroy');
	},
};
</script>