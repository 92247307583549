<template>
    <div>

        <!-- Page header -->
        <div class="page-header page-header-light">
            <div class="page-header-content header-elements-lg-inline">
                <div class="page-title d-flex">
                    <h4 class="d-none d-sm-block">
                        <i class="icon-arrow-left52 me-2"></i> 
                        <a href="#" style="color:#000;"><span class="font-weight-semibold"> Benvenuto {{utente.USERNAME}} </span></a> 
                    </h4>
                    <h4 class="d-block d-xl-none"> Dashboard </h4>
                    <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
                </div>
                <div class="header-elements d-none">
                    <div class="d-flex justify-content-center">
                        <a href="#" class="btn btn-link btn-float text-body"><i class="icon-bars-alt text-primary"></i><span>STATISTICHE</span></a>
                        <a href="#" class="btn btn-link btn-float text-body"><i class="icon-calculator text-primary"></i> <span>CONTABILITA'</span></a>
                        <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-calendar5 text-primary"></i> <span>DA FARE</span></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <!-- Content area -->
        <div class="content content-2">

            <div class="row">
                <div class="col-xl-9">

                    <div class="card card-body shadow mb-0">
                        <div class="row text-center">
                            <div class="col-2 cursor-pointer" v-on:click="filters.openRoute('/Ordini')">
                                <p><i class="icon-cash3 icon-2x d-inline-block text-success"></i></p>
                                <h5 class="font-weight-semibold mb-0">$9,693</h5>
                                <span class="text-muted fs-12">Oggi</span>
                            </div>
                            <div class="col-2 cursor-pointer" v-on:click="filters.openRoute('/Ordini')">
                                <p><i class="icon-bag icon-2x d-inline-block text-warning"></i></p>
                                <h5 class="font-weight-semibold mb-0">3,568</h5>
                                <span class="text-muted fs-12">Pratiche</span>
                            </div>
                            <div class="col-2 cursor-pointer" v-on:click="filters.openRoute('/Preventivi')">
                                <p><i class="icon-stack-text icon-2x d-inline-block text-info"></i></p>
                                <h5 class="font-weight-semibold mb-0">2,345</h5>
                                <span class="text-muted fs-12">Preventivi</span>
                            </div>
                            <div class="col-2 cursor-pointer" v-on:click="filters.openRoute('/ListCards')">
                                <p><i class="icon-credit-card icon-2x d-inline-block text-indigo"></i></p>
                                <h5 class="font-weight-semibold mb-0">2,345</h5>
                                <span class="text-muted fs-12">Tessere</span>
                            </div>
                            <div class="col-2 cursor-pointer" v-on:click="filters.openRoute('/UtentiClienti')">
                                <p><i class="icon-users2 icon-2x d-inline-block text-primary"></i></p>
                                <h5 class="font-weight-semibold mb-0">2,345</h5>
                                <span class="text-muted fs-12">Utenti</span>
                            </div>
                            <div class="col-2 cursor-pointer" v-on:click="filters.openRoute('/Agenzie')">
                                <p><i class="icon-store icon-2x d-inline-block text-secondary"></i></p>
                                <h5 class="font-weight-semibold mb-0">150</h5>
                                <span class="text-muted fs-12">Agenzie</span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-8">
                            
                        </div>
                        <div class="col-lg-4">
                           
                        </div>
                    </div>                                      

                </div>
                <div class="col-xl-3">
                    <div class="card shadow mb-0">
                        <div class="card-header header-elements-inline">
                            <h6 class="card-title">COSE DA FARE</h6>
                        </div>
                        <div class="card-body">
                            <div class="tableFixHead" style="min-height: 40rem; max-height: 40rem;">
                                <table class="table table-bordered table-hover table-striped table-togglable"> 
                                    <thead>
                                        <tr> 
                                            <th style="width: 15%;">Data</th>
                                            <th>Descrizione</th>
                                            <th style="width: 17%;">Azione</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5 class="text-center text-success mb-0">02</h5>
                                                <div class="text-center fs-12 text-muted line-height-1">Nov</div>
                                            </td>
                                            <td>
                                                <a href="#" class="text-body font-weight-semibold letter-icon-title">Sigma application</a>
                                                <div class="fs-12"><span class="badge badge-danger badge-xs">Danger</span></div>
                                            </td>
                                            <td>
                                                <div class="ms-3 align-self-center">
                                                    <div class="dropdown">
                                                        <a href="#" class="text-body" data-bs-toggle="dropdown"><i class="icon-more2"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a href="#" class="dropdown-item"><i class="icon-comment-discussion"></i> Leggi</a>
                                                            <a href="#" class="dropdown-item"><i class="icon-phone2"></i> Rispondi</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a href="#" class="dropdown-item"><i class="icon-stars"></i> Contrassegna</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- /content area -->
        
    </div>
</template>


<script>
export default {
    name:'Dashboard3',
        data:function(){
        return{
            filters:global.filters,
            t:global.t,
            utente:{},
            today: new Date(),
            startInterval:{},
            arrayRisultatiRicerca:new Array(),
        }
    },

    props: ['todo'],

    methods:{
        goToRoute: function(route){
            global.router.push(route);
        },
        varianti : function(){
            this.$root.appoggio.prodotto = {ID_PRODOTTO:1};
            this.$root.changeRoute('varianti');
        },
        ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data.RISULTATI;
        },
        testAlert : function(){   
            utils.alert.success("ciaone");
            utils.alert.warning("ciaone");
            utils.alert.info("ciaone");
            utils.alert.primary("ciaone");
            utils.alert.danger("ciaone");
        }
      
    },

    created: function () {
        this.utente = this.$root.utente;

    },
}
</script>