<template>
  	<div>
	
	    <!-- Page header -->
	    <div class="page-header page-header-light">
	        <div class="page-header-content header-elements-lg-inline">
	            <div class="page-title d-flex">
	                <h4 class="d-none d-sm-block">
	                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
						<router-link href="#" class="d-inline-block text-dark" :to="'GeoStati'"><span class="font-weight-semibold"> Geo </span></router-link> \
	                    <a v-on:click="back()" class="d-inline-block text-dark cursor-pointer"> {{geoLang.TITOLO}} </a> \
	                    <span class="text-muted">Elenco Contenuti</span><code> - Lingua italiano</code>
	                </h4>
	                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Contenuti </h4>
	                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
	            </div>
	            <div class="header-elements d-none">
	                <div class="d-flex justify-content-center">
	                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="salvaContenuto()"><i class="icon-googleplus5 text-success"></i><span>SALVA</span></a>
	                    <a href="#" class="btn btn-link btn-float text-body"><i class="icon-air"></i><span>LISTINI</span></a>
	                    <div class="btn-group ms-1 position-static">
							<button type="button" class="btn btn-link btn-float text-body dropdown-toggle dropdown-toggle-2" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="icon-power2"></i> <span>CONFIG</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right" style="">
								<a href="#" class="dropdown-item"><i class="icon-cube4"></i> Configurazione Modulo</a>
								<div class="dropdown-divider"></div>
	                    		<a href="#" class="dropdown-item" v-on:click="$root.confTravelTipologiche()"><i class="icon-grid"></i> Configurazione Plug-In</a> 
							</div>
						</div>	                    
	                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
	                </div>
	            </div>
	        </div>

			<!-- Profile navigation -->
			<div class="navbar navbar-expand-lg navbar-light border-top">
				<div class="text-center d-lg-none w-100">
					<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
						<i class="icon-menu7 me-2"></i> Lingua 
					</button>
	    		</div>
				<div class="navbar-collapse collapse" id="navbar-second">
					<ul class="nav navbar-nav">
						<li class="nav-item" v-for="lang in $root.arrayLingue" v-bind:key="lang.ID_GEO_STATO">
							<a :href="'#tab_lingua'+lang.ID_GEO_STATO" class="navbar-nav-link" :data-tab="lang.ID_GEO_STATO" data-bs-toggle="tab" :id="'btnLingua'+lang.ID_GEO_STATO" v-on:click="linguaSelezionata = lang.ID_GEO_STATO">
								<Flag :country="lang.ID_GEO_STATO" class="me-1"></Flag> {{ lang.NOME }}
							</a>
						</li>
					</ul>

					<ul class="navbar-nav ms-lg-auto">
						<li class="nav-item">
							<SelectStagioni @change="changeComboStagioneLingua()"  v-model="currentStagione"></SelectStagioni>
						</li>
						<li class="nav-item">
						</li>
					</ul>
				</div>
			</div>
			<!-- /profile navigation -->		
				
	    </div>
	    <!-- /page header -->	
	
	    <!-- Content area -->
	    <div class="content content-2">
	
	        <div class="card shadow mb-0">
				<div class="card-body card-body-2">
					<!--
					<div class="alert alert-2 alert-secondary alert2 border-0">
						<div class="row ">
							<div class="d-none d-sm-block col-md-9 form-group-1">
								
							</div> 
							<div class="col-6 col-md-2 form-group-1">
								
							</div> 
							<div class="col-6 col-md-1 form-group-1">
								<SelectLingue @change="changeComboStagioneLingua()"  v-model="currentLang"></SelectLingue>
							</div> 
						</div>
					</div>
					-->
				</div>	          
		        <div class="card-body card-body-2 card-body-nobottom card-body-notop">
	                <div class="row">
                    	<div class="col-lg-4 form-group-1">
							<label>Titolo Prodotto</label>
							<input class="form-control" type="text" v-model="geoLang.TITOLO"  />							
						</div>
                    	<div class="col-lg-4 form-group-1">
							<label>Slogan Prodotto</label>
							<input class="form-control" type="text" v-model="geoContenutoLang.SOTTOTITOLO" />							
						</div>
                    	<div class="col-lg-2 form-group-1">
							<label>Stagione Prodotto</label>
							<SelectStagioni  v-model="geoContenutoLang.FK_ID_STAGIONE"></SelectStagioni>				
						</div>
                    	<div class="col-6 col-lg-1 form-group-1">
							<label>Mese DA</label>
							<input class="form-control" type="text" v-bind:value="getMeseStagione(1)" disabled />							
						</div>
                    	<div class="col-6 col-lg-1 form-group-1">
							<label>Mese A</label>
							<input class="form-control" type="text" v-bind:value="getMeseStagione(2)" disabled />							
						</div>
					</div> 
	                <div class="row">
						<div class="alert alert-danger alert-styled-left alert-dismissible" v-show="geoContenutoLang.ID_GEO_CONTENUTO_LANG == 0">
							<span class="font-weight-semibold">Attenzione:</span><br />
							contunto mai salvato per questa lingua / stagione
						</div>
                        <div class="col-lg-12">                        
                            <ul class="nav nav-tabs mb-2 nav-tabs-highlight" id="myTab" role="tablist">
		                        <li class="nav-item">
		                            <a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                                        <i class="far fa-info-square fa-fw"></i> <span class="d-none d-sm-inline"><strong>Intro </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="body-tab" data-bs-toggle="tab" href="#body" role="tab" aria-controls="body" aria-selected="false">
                                        <i class="far fa-h-square fa-fw"></i> <span class="d-none d-sm-inline"><strong>Desc. </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="include-tab" data-bs-toggle="tab" href="#include" role="tab" aria-controls="include" aria-selected="false">
                                        <i class="far fa-thumbs-up fa-fw"></i> <span class="d-none d-sm-inline"><strong>Q. Inc. </strong></span>
		                            </a>
		                        </li>
		                        <li class="nav-item">
		                            <a class="nav-link" id="exclude-tab" data-bs-toggle="tab" href="#exclude" role="tab" aria-controls="exclude" aria-selected="false">
                                        <i class="far fa-thumbs-down fa-fw"></i> <span class="d-none d-sm-inline"><strong>Q. Esc. </strong></span>
		                            </a>
		                        </li>
		                        <li class="nav-item">
		                            <a class="nav-link" id="inloco-tab" data-bs-toggle="tab" href="#inloco" role="tab" aria-controls="inloco" aria-selected="false">
                                        <i class="far fa-map-marker-check fa-fw"></i> <span class="d-none d-sm-inline"><strong>In Loco </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="orari-tab" data-bs-toggle="tab" href="#orari" role="tab" aria-controls="orari" aria-selected="false">
                                        <i class="far fa-clock fa-fw"></i> <span class="d-none d-sm-inline"><strong>Orari </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="extra-tab" data-bs-toggle="tab" href="#extra" role="tab" aria-controls="extra" aria-selected="false">
                                        <i class="far fa-layer-plus fa-fw"></i> <span class="d-none d-sm-inline"><strong>Suppl. </strong></span>
		                            </a>
		                        </li> 

   								<li class="nav-item">
		                            <a class="nav-link" id="policy-tab" data-bs-toggle="tab" href="#policy" role="tab" aria-controls="policy" aria-selected="false">
                                        <i class="far fa-user-secret fa-fw"></i> <span class="d-none d-sm-inline"><strong>Policy </strong></span>
		                            </a>
		                        </li> 

		                        <li class="nav-item">
		                            <a class="nav-link" id="voucher-tab" data-bs-toggle="tab" href="#voucher" role="tab" aria-controls="voucher" aria-selected="false">
                                        <i class="far fa-file-invoice fa-fw"></i> <span class="d-none d-sm-inline"><strong>Vouch. </strong></span>
		                            </a>
		                        </li> 
		                     
		                        
		                        <li class="nav-item">
		                            <a class="nav-link" id="note-tab" data-bs-toggle="tab" href="#note" role="tab" aria-controls="note" aria-selected="false">
                                        <i class="far fa-clipboard fa-fw"></i> <span class="d-none d-sm-inline"><strong>Note </strong></span>
		                            </a>
		                        </li>
		                        <li class="nav-item">
		                            <a class="nav-link" id="notes-tab" data-bs-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">
                                        <i class="far fa-user-shield fa-fw"></i> <span class="d-none d-sm-inline"><strong>Admin </strong></span>
		                            </a>
		                        </li>    
		                    </ul>
                    		<div class="tab-content profile-tab" id="myTabContent">

		                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
									<div class="box_450"> 
										<div class="alert alert-info alert-styled-left alert-dismissible">
											<span class="font-weight-semibold">Testo introduttivo:</span><br />
											lunghezza consigliata non più di tre righe.
											Questo testo viene posizionato nei box dei risultati della ricerca e nel dettaglio del prodotto, 
											sotto la galleria fotografica.
										</div>
										<ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_HOME" :config="editorConfig"></ckeditor>
									</div>
                                </div>

                                <div class="tab-pane fade" id="body" role="tabpanel" aria-labelledby="body-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Testo descrittivo:</span><br />
											lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info"..
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_BODY" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

								<div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
									<div class="box_450">
										<div class="alert alert-info alert-styled-left alert-dismissible">
											<span class="font-weight-semibold">Testo Informativo:</span><br />
											lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info"..
										</div>
										<ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_INFO" :config="editorConfig"></ckeditor>
									</div>
								</div>

								<div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="body-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Testo info:</span><br />
											info
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_INFO" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="include" role="tabpanel" aria-labelledby="include-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Cosa TESTO_DOCUMENTI:</span> <br />
											lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato nel dettaglio del prodotto nel 
											Tab nominato "Inc. / Esc." lato sinistro.
                                        </div>
										<ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_DOCUMENTI" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="exclude" role="tabpanel" aria-labelledby="exclude-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Cosa TESTO_VOUCHER</span> <br />
											lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato nel dettaglio del prodotto nel 
											Tab nominato "Inc. / Esc." lato destro.
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_VOUCHER" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="inloco" role="tabpanel" aria-labelledby="inloco-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Testo In TESTO_POLICY</span><br />
											 lunghezza consigliata nessun limite. Questo testo viene posizionato nella scheda del prodotto verso la fine.
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_POLICY" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

								<div class="tab-pane fade" id="incontro" role="tabpanel" aria-labelledby="inloco-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Testo TESTO_RECENSIONE</span><br />
											 lunghezza consigliata nessun limite. Questo testo viene posizionato nella scheda del prodotto verso la fine.
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_RECENSIONE" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="extra" role="tabpanel" aria-labelledby="extra-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Testo NOTE</span><br /> 
											lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato solo ed esclusivamente nei Voucher.
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_NOTE" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="policy" role="tabpanel" aria-labelledby="policy-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <span class="font-weight-semibold">Testo ADMIN</span> <br />
											lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel 
											Tab nominato "Policy"
                                        </div>
                                        <ckeditor :editor="editor" v-model="geoContenutoLang.TESTO_ADMIN" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>


							</div>
							<button type="submit" class="btn btn-primary float-end mt-3 mb-2 me-2" v-on:click="salvaContenuto()"> SALVA </button>
						</div>
					</div>
		        </div>
			</div>	    
	    </div>

	</div>
</template>



<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import SelectLingue  from '@/components/utils/SelectLingue.vue'
import SelectStagioni  from '@/components/utils/SelectStagioni.vue'
import Flag from '@/components/utils/Flag.vue'



export default {
    name:"geoContenutoLang",
    components:{
		//SelectLingue,
		SelectStagioni,
		Flag
    },
    props:["modulo"],
    data:function(){
		return{
	        filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
	        arrayGeoContenutiLang:new Array(),
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_LANG:{TYPE:'TEXT',VALUE:''},
                FK_FK_ID_PRODOTTO:{TYPE:'COMBO',VALUE:''}, 
	            TITOLO:{TYPE:'TEXT',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
			currentLang:0,
			currentStagione:0,
	        geoContenutoLang:{},
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
			geoLang:{}
      	}
    },

    methods:{
       salvaContenuto : function(){
			var api = this.geoContenutoLang.ID_GEO_CONTENUTO_LANG > 0 ? "travel/geoContenutoLang/update" : "travel/geoContenutoLang/insert"; 
            utils.ajax(api,this.geoContenutoLang, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
					if (this.geoContenutoLang.ID_GEO_CONTENUTO_LANG == 0){
						this.geoContenutoLang.ID_GEO_CONTENUTO_LANG = response.id_inserito;
					}
                }
            });
        },
		changeComboStagioneLingua : function(){
			utils.ajax("travel/geo/contenutolang",{FK_ID_GEO_LANG:this.geoLang.ID_GEO_LANG,FK_ID_LINGUA:this.currentLang,FK_ID_STAGIONE:this.currentStagione},(response)=>{
				this.geoContenutoLang = response.data;
				if (!this.geoContenutoLang.ID_GEO_CONTENUTO_LANG > 0){
					this.geoContenutoLang.ID_GEO_CONTENUTO_LANG = 0;
					this.geoContenutoLang.FK_ID_GEO_LANG = this.geoLang.ID_GEO_LANG;
					this.geoContenutoLang.FK_ID_LINGUA = this.currentLang;
					this.geoContenutoLang.FK_ID_STAGIONE = this.currentStagione;
				}
			});
		},
		getMeseStagione : function(custom){
			var a = utils.array.select(this.$root.arrayStagioni,"ID_OPZIONE_TIPOLOGICA",this.geoContenutoLang.FK_ID_STAGIONE,1)[0];
			if (a != undefined){
				return a['CUSTOM_'+custom];
			}
			return "";
		}
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayGeoContenutiLang,this.search);
            return arr;
        },

    }, 

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		//utils.ajax("travel/prodotto",{ID_PRODOTTO:this.$root.prodotto.ID_PRODOTTO},(response)=>{
		this.geoLang = this.$root.geoLangSelezionato;
		this.currentLang = this.$root.currentLang;
		this.currentStagione = this.$root.arrayStagioni[0].ID_OPZIONE_TIPOLOGICA;
		this.changeComboStagioneLingua();
        //});
		setTimeout(() => {
            $('a[data-bs-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
				this.currentLang = tab;
				this.changeComboStagioneLingua();
            });            
		},300);
    },
}
</script>


