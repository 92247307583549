<template>
        
    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
                    <a href="#" class="d-inline-block text-dark"> Contabilità </a> \
                    <span class="text-muted">Gestione Pagamenti</span>
					<code> - <Translate :path="'dizionario.base.LINGUA'"></Translate> italiano</code>
                </h4>
                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Gestione Pagamenti</h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body text-uppercase" v-on:click="nuovoPayment(1)"><i class="icon-googleplus5 text-success"></i><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="statistichePayments()"><i class="icon-bars-alt"></i><span>STATISTICHE</span></a>
					<div class="btn-group ms-1 position-static">
						<button type="button" class="btn btn-link btn-float text-body dropdown-toggle dropdown-toggle-2 text-uppercase" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="icon-power2"></i><Translate :path="'dizionario.base.BTN_OPZIONI'"></Translate>
						</button>
						<div class="dropdown-menu dropdown-menu-right" style="">
							<a href="#" class="dropdown-item" v-on:click="$root.confTravelTipologiche(livello == 2 ? 21 : 22)"><i class="icon-cube4"></i> Configurazioni</a>
							<div class="dropdown-divider"></div>
							<a href="#" class="dropdown-item" v-on:click="$root.confTravelLingue(this.modulo)"><i class="icon-flag4"></i><Translate :path="'dizionario.base.LINGUA'"></Translate></a>
						</div>
					</div>
					<a href="#" class="btn btn-link btn-float text-pink" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-center d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Menu Pagamenti
				</button>
			</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#payment-a" class="navbar-nav-link active" data-bs-toggle="tab">
							<i class="icon-calendar52 me-2"></i> Scadenzario
						</a>
					</li>
					<li class="nav-item">
						<a href="#payment-b" class="navbar-nav-link" data-bs-toggle="tab">
							<i class="icon-menu7 me-2"></i> <Translate :path="'dizionario.base.ELENCO'"></Translate> Pagamenti
						</a>
					</li>
					<li class="nav-item">
						<a href="#payment-c" class="navbar-nav-link" data-bs-toggle="tab">
							<i class="icon-bars-alt me-2"></i> <Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate>
						</a>
					</li>
				</ul>

				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item dropdown">
						<a href="#" class="navbar-nav-link dropdown-toggle" data-bs-toggle="dropdown">
							<i class="icon-list"></i><span class="ms-2"><strong>Azioni Multiple</strong></span>
						</a>
						<div class="dropdown-menu dropdown-menu-right">
							<div class="dropdown-submenu dropdown-submenu-left">
								<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
								<div class="dropdown-menu">
									<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
									<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
								</div>
							</div>
							<div class="dropdown-divider"></div>
							<div class="dropdown-submenu dropdown-submenu-left">
								<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
								<div class="dropdown-menu">
									<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
									<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
									<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
								</div>
							</div>
							<div class="dropdown-divider"></div>
							<div class="dropdown-submenu dropdown-submenu-left">
								<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
								<div class="dropdown-menu">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
									<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
								</div>
							</div>
							<div class="dropdown-divider"></div>
							<a href="#" class="dropdown-item"><i class="icon-trash text-danger"></i> Elimina</a>
						</div>
					</li>
					<li class="nav-item ms-4">
						<select class="form-control form-control-sm changeyear mt-1" id="comboAnno" @change="getPayments()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
                        </select>
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="payment-a">
				<div class="card shadow mb-0">
					<div class="card-body card-body-2">
					
					
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="payment-b">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body card-body-2">
		                    <div class="alert alert-2 alert-secondary alert2 border-0">
								<form action="#" id="payment">
		                            <div class="row ">	
		                                <div class="col-6 col-md-1 form-group-filter">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id pratica" :tabindex="2" v-model="search.ID_ORDINE.VALUE" />
		                                </div> 
		                                <div class="col-6 col-md-1 form-group-filter">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Pnr pratica" :tabindex="2" v-model="search.PNR.VALUE" />
		                                </div>  
		                                <div class="col-12 col-md-2 form-group-filter">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Ragione Sociale" :tabindex="3" v-model="search.INTESTATARIO.VALUE" />
		                                </div>	 
		                                <div class="col-12 col-md-2 form-group-filter">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Titolo pratica" :tabindex="3" v-model="search.TITOLO_PRATICA.VALUE" />
		                                </div> 
										<div class="col-12 col-md-6">
		                                    <div class="row">
		                                        <div class="col-6 col-md-3 form-group-filter">
		                                            <div class="input-group">
		                                                <span class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar22"></i></span></span>
														<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />
													</div> 
		                                        </div>
		                                        <div class="col-6 col-md-3 form-group-filter">
		                                            <div class="input-group">
		                                                <span class="input-group-append"><span class="input-group-text"><i class="icon-calendar22"></i></span></span>
		                                            </div>
		                                        </div> 
		                                        <div class="col-12 col-md-2 form-group-filter">
		                                            <select class="form-control form-control-sm" data-placeholder="Saldo" tabindex="4"> 
		                                                <option>Movimento</option> 
		                                                <option v-bind:value="0"> - Banca</option> 
		                                                <option v-bind:value="1"> - Contanti</option>
		                                            </select>
		                                        </div>
						                        <div class="col-12 col-md-3 form-group-filter">
						                            <select class="form-control form-control-sm" data-placeholder="Stato Pagamento">
					                                    <option>Stato Pagamento</option> 
					                                    <option v-bind:value="0"> - Da pagare</option> 
					                                    <option v-bind:value="1"> - Pagata</option>                                                                    
						                            </select>
						                        </div>
												<div class="col-md-1 form-group-filter">
													<a href="#collapse-icon" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="12">
														<span style="font-weight: 500;"><i class="far fa-plus fa-fw"></i></span>
													</a>
												</div>  
		                                    </div>
		                                </div>  
		                            </div>
		                            <div class="collapse show" id="collapse-icon" style="">
		                                <div class="row">
		                                    <div class="col-md-2 form-group-filter">
		                                        <select class="form-control form-control-sm" data-placeholder="Tipologia booking" tabindex="4" v-model="search.FK_ID_TIPO.VALUE">
		                                            <option v-bind:value="0">Booking</option> 
		                                            <option v-bind:value="1"> - Booking x Agenzia</option> 
		                                            <option v-bind:value="2"> - Booking x Cliente</option> 
		                                            <option v-bind:value="3"> - Agenzia Diretta</option> 
		                                            <option v-bind:value="4"> - Cliente Diretto</option>
		                                        </select>
		                                    </div>
		                                    <div class="col-6 col-md-2 form-group-filter">
		                                        <select class="form-control form-control-sm" data-placeholder="Promotore" :tabindex="7">
		                                            <option value="">Promotore </option>   
		                                        </select>
		                                    </div>
		                                    <div class="col-6 col-md-2 form-group-filter">
		                                        <select class="form-control form-control-sm" data-placeholder="Agente" :tabindex="5">
		                                            <option>Agente</option>
		                                        </select>
		                                    </div>
		                                    <div class="col-6 col-md-2 form-group-filter">
		                                        <select class="form-control form-control-sm" data-placeholder="Sito Web" :tabindex="5">
		                                            <option>Sito</option>
		                                        </select>
		                                    </div>
		                                    <div class="col-6 col-md-4 form-group-filter">
		                                    </div>
		                                </div>
		                            </div>
		                    	</form>
		                    </div>
		                </div>
		         	</div>
		
		            <div class="card-body card-body-2 card-body-nobottom card-body-notop">
		                <div class="tableFixHead" style="min-height: 33rem !important; max-height: 33rem !important;">
		                    <table class="table table-bordered table-hover table-striped table-togglable"> 
		                        <thead>
		                            <tr>
		                                <th class="table-head-sm" style="width: 2%;"> <input id="chkAllProducts" v-on:click="filters.selectAll('chkAllProducts',paginatedList.arr)" type="checkbox" class=""> </th>
		                                <th class="table-head-sm text-center" style="width: 6%;"> # </th>
		                                <th class="table-head-sm text-center" style="width: 7%;">Data pagamento</th>
		                                <th class="table-head-sm" style="width: 19%;"> Ragione Sociale </th>
		                                <th class="table-head-sm text-center"> Descrizione<br />Dati pratica </th>
		                                <th class="table-head-sm text-center" style="width: 8%;"> Importo </th>
										<th class="table-head-sm text-center" style="width: 8%;">Movimento<br />Tipo</th>
										<th class="table-head-sm text-center" style="width: 10%;">Utente</th>
		                                <th class="table-head-sm text-center" style="width: 10%;">Stato</th>
										<th class="table-head-sm text-center" style="width: 6%;">Fatturata</th>
		                                <th class="table-head-sm text-center" style="width: 5.4%;"> Azioni </th>
		                            </tr>
		                        </thead>
		                        <tbody>
		                            <tr v-for="ordine in paginatedList.arr" v-bind:key="ordine.ID_ORDINE">
		                                <td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="ordine.SELEZIONATO" class=""> </td>
		                                <td class="text-center f-08">
											<span class="">{{ordine.ID_ORDINE}}</span>
										</td>
		                                <td class="text-center f-08">{{filters.formatDate(ordine.DATA_CREAZIONE)}}</td>
		                                <td class="f-08">
		                                    <span class="" v-show="ordine.FK_ID_AGENCY > 0">{{ ordine.AGENZIA }}</span>
		                                    <span class="" v-show="ordine.FK_ID_AGENCY == 0 && ordine.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">{{ ordine.INTESTATARIO_PERSONA_FISICA }}</span>
		                                    <span class="" v-show="ordine.FK_ID_AGENCY == 0 && ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">{{ ordine.INTESTATARIO_PERSONA_GIURIDICA }}</span>
		                                </td>
		                                <td>
											<span class="text-secondary fw-bold">Acconto</span><br />
											<a href="#" class="badge bg-light text-primary" title="Apri dettaglio Pratica">{{filters.getPnr(ordine)}}</a> - <span class="f-08">{{ordine.TITOLO_PRATICA}}</span>
										</td>
		                                <td class="text-center"><strong>&euro; {{ordine.COSTO_TOTALE}} </strong></td>
										<td class="text-center"> Bonifico </td>
										<td class="text-center"> Banca </td>
		                                <td class="text-center">
		                                    <span class="badge badge-danger cursor-pointer">Da pagare</span>
		                                </td>        
		                                <td class="text-center">
		                                    <span class="badge badge-warning cursor-pointer" v-on:click="openCatturaOrdine()"><i class="far fa-thumbs-down"></i></span>
		                                </td>
		                                <td class="text-center">
		                                    <div class="list-icons">
		                                        <div class="dropdown">
		                                            <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
		                                            <div class="dropdown-menu dropdown-menu-right">
		                                                <a href="#" class="dropdown-item" v-on:click="openPayment(ordine)"><i class="far fa-pencil fa-fw"></i> Modifica incasso</a>
		                                                <div class="dropdown-divider"></div>
		                                                <a href="#" class="dropdown-item" v-on:click="deletePayment(ordine)"><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina incasso</a>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </td>
		                            </tr>
		                        </tbody>
		                    </table>
		                </div>
		
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
		                        </div>
		                    </div>
		                    <div class="col-4 col-md-1" :tabindex="28">
		                        <select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
		                    </div>
		                </div>
		
		            </div>
        		</div>
			</div>		

			<div class="tab-pane fade" id="payment-c">
				<div class="card shadow mb-0">
					<div class="card-body card-body-2">
						<div class="row">
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="me-3 align-self-center">
											<i class="icon-file-stats icon-3x text-success"></i>
										</div>
										<div class="media-body text-right">
											<h3 class="font-weight-semibold mb-0">&euro; 652,549</h3>
											<span class="text-uppercase fs-12 text-muted">Pagamenti totale</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="me-3 align-self-center">
											<i class="icon-coin-euro icon-3x text-indigo"></i>
										</div>
										<div class="media-body text-right">
											<h3 class="font-weight-semibold mb-0">&euro; 245,382</h3>
											<span class="text-uppercase fs-12 text-muted">Pagamenti versati</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="media-body">
											<h3 class="font-weight-semibold mb-0">&euro; 54,390</h3>
											<span class="text-uppercase fs-12 text-muted">Pagamenti in attesa</span>
										</div>
				
										<div class="ms-3 align-self-center">
											<i class="icon-calendar52 icon-3x text-primary"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="media-body">
											<h3 class="font-weight-semibold mb-0">&euro; 389,438</h3>
											<span class="text-uppercase fs-12 text-muted">Pagamenti stornati</span>
										</div>
			
										<div class="ms-3 align-self-center">
											<i class="icon-blocked icon-3x text-danger"></i>
										</div>

									</div>
								</div>
							</div>
						</div>					
					

					</div>
				</div>
			</div>

        </div>
	</div>


	<!-- Modal -->
    <div class="modal fade" id="popUpCatturaOrdine" role="dialog"  aria-modal="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> IMPORTA DATI NEL GESTIONALE </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">  

                    <p class="text-center" style="font-size: 14px;">
                        <span id="modal_status_subtitle">sei sicuro di voler importare questa Pratica nel tuo Gestionale?</span> <br> 
                        <span id="modal_status_accept">Proseguendo, la Pratica e le relative Quote, saranno importate nel tuo Gestionale.</span>
                    </p> 
                    <hr> 
                    <div class="row">
                        <div class="col-md-6">
                            <h6><strong>1.</strong> Seleziona Gestionale</h6> 
                            <div class="form-group">
                                <select name="order_status" class="form-control form-control-sm" disabled="disabled">
                                    <option value="easy">Cattura Easy</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <h6><strong>2.</strong> Scegli Operazione</h6>
                            <div class="form-group">
                                <select class="form-control form-control-sm" disabled="disabled">
                                    <option value="Set_Pratica">Importa pratica</option> 
                                    <option value="Set_Quota">Importa quota</option> 
                                    <option value="Set_Payments">Importa incasso</option> 
                                    <option value="anagrafica">Importa anagrafica</option> 
                                    <option value="voucher">Importa voucher</option>
                                </select>
                            </div>
                        </div> 
                    </div>

                </div>
                <div class="modal-footer bg-light">
                    Vuoi Procedere&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">NO ESCI</button>&nbsp;
                    <button type="button" class="btn btn-success btn-sm">SI IMPORTA</button><!--  v-on:click="sendStatus()" -->
                </div>
            </div>
        </div>
    </div>  
		

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'

export default {
    name:"Ordini",		 

    components:{
        Pagine,
        Datepicker,
    },		   

    data:function(){
        return {
            apiStartup:'travel/ordini',
            filters:global.filters,
            arrayOrdini:new Array(),
            ordineSelezionato:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_ORDINE:{TYPE:'TEXT',VALUE:''},
                PNR:{TYPE:'TEXT',VALUE:''},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
                CODICE_ORDINE:{TYPE:'TEXT',VALUE:''},
                FK_ID_STATO:{TYPE:'COMBO',VALUE: 0},
	            INTESTATARIO:{TYPE:'TEXT',VALUE:''}, 
	            TITOLO_PRATICA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''},
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
        }
    },

    methods:{
        getOrdini:function(){
            utils.ajax(this.apiStartup,{ANNO_ORDINE:this.search.ANNO_ORDINE.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayOrdini = response.data;
                }
            });  
        },

        openOrdine:function(ordine){
            this.$root.ordineSelezionato = utils.clone(ordine);
            global.router.push("Ordine");
        },

        nuovaQuota : function(id){
            this.$root.ordineSelezionato = utils.clone(this.ordine);
            global.router.push("Quota");
        },
		
        getBtnStatusClass : function(ordine){
            switch (ordine.FK_ID_STATO){
                case 1:
                    return 'badge-success'
                case 2:
                    return 'badge-primary'
                case 3:
                    return 'badge-info'
				case 4:
                    return 'badge-warning'
                case 5:
                    return 'badge-yellow'
                case 6:                
                    return "badge-secondary";
                case 7:
                case 8:
                    return 'badge-danger'
                default :
                    return 'badge-light'
            }
        },

        getTipoBookClass : function(ordine){
            switch (ordine.FK_ID_TIPO){
                case 1:
                    return "badge-danger";
                case 2:
                    return "badge-info";                   
                case 3:
                    return "badge-warning";
                case 4:
                    return "badge-primary";                   
            }
            return "";
        },

        getTipoBook : function(ordine){
            switch (ordine.FK_ID_TIPO){
                case 1:
                    return "BA";
                case 2:
                    return "BC";
                case 3:
                    return "A";
                case 4:
                    return "C";                   
            }
            return "";
    	},

        openStatoOrdine : function(){
			this.ordineSelezionato = {ID_ORDINE:0};
            this.filters.openPopUp("popUpStatoOrdine");
        },

        openCatturaOrdine : function(){
            this.ordineSelezionato = {ID_ORDINE:0};
            this.filters.openPopUp("popUpCatturaOrdine");
        },

        getDateRange : function(ordine){
          
        },

        openSchedaOrdine : function(ordine){
            this.$refs.schedaOrdine.openSchedaOrdine(ordine);
        }

    },

    computed: {
        paginatedList(){
            if (this.search.FK_ID_STATO.VALUE == 0){
                var arr = utils.clone(this.arrayOrdini);
                for (var i = arr.length -1 ; i > -1 ; --i){
                    if (arr[i].FK_ID_STATO > 5){
                        arr.splice(i,1);
                    }
                }
                return utils.inGrid(arr,this.search);
            }
            else{
	            var arr = utils.inGrid(this.arrayOrdini,this.search);
	            return arr;
            }            
        },
    }, 

    created : function(){
        this.getOrdini();
    }
}
</script>