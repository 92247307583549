<template>

    <nav style="margin-top:10px;" v-if="$root.isMobile == false && modalita != 2"> 
        <Pag 
            v-model="localValue"
            :page-count="numpagine"
            :container-class="'pagination'"
            :first-last-button="true"
            :page-link-class="'page-link'"
            :next-link-class="'page-link'"
            :prev-link-class="'page-link'"
            :active-class="'active'"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
            :next-text="'>'"
            :prev-text="'<'"
            :numrighe="numrighe"
            :pdf="pdf"
            :xlsx="xlsx"
            :csv="csv"
            @esportazione="esportazione($event)">
        </Pag>       
    </nav>
    <nav v-if="$root.isMobile == true || modalita == 2">
        <div class="pagination d-flex align-items-center" style="justify-content: center;">
            <div class="page-item">
                <a v-on:click="decrementa()" href="#" class="page-link rounded-pill px-0">
                    <i class="ph-caret-left"></i>
                </a>
            </div>
            <div class="px-3">
                {{ localValue }} of <a href="#">{{ numeroPagine }}</a>
            </div>
            <div class="page-item">
                <a v-on:click="incrementa()" href="#" class="page-link rounded-pill px-0">
                    <i class="ph-caret-right"></i>
                </a>
            </div>
        </div>
    </nav>

</template>


<script>
import Pag from '@/components/utils/Pag.vue'


export default {
    name: 'Pagine',
    components: {
        Pag
    },
    props: {
        options: {
          
        },
        modelValue: Number,
        numpagine: {
          type: Number,
        },
        numrighe: {
            type: Number,
        },
        pdf:{
            type:Boolean,
            default : false
        },
        xlsx:{
            type:Boolean,
            default : false
        },
        csv:{
            type:Boolean,
            default : false
        },
        modalita:{
            type: Number,
            default : 1
        }
    },
    emits: ['update:modelValue'],

    data:function(){
        return{
            //CREO UN VALORE COPIA DEL VALORE IN INPUT PERCHE' VUE NON PUO' GESTIRE DIRETTAMENTE IL V-MODEL ALL'ITERNO DEL CHILD COMPONENT
            localValue:global.utils.clone(this.modelValue)
        }
    },

    methods: {
        esportazione:function(formato){
            this.$emit('esportazione',formato);
        },
        incrementa(){
            if (this.localValue < this.numeroPagine){
                this.localValue++;
            }
        },
        decrementa(){
            if (this.localValue > 1){
                --this.localValue;
            }
        }
    },

    computed: {
        numeroPagine : function(){
            return Math.ceil(this.numrighe / this.numpagine);
        }
    },
    
    watch: {
        localValue:function(){
            this.$emit('update:modelValue', this.localValue)
        }
    },
}

</script>