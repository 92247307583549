import { createRouter, createWebHistory } from 'vue-router'

import Install from '../views/Install.vue'
import Start from '../views/Start.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Inbox from '../views/Inbox.vue'
import Chat from '../views/Chat.vue'
import Tickets from '../views/Tickets.vue'
import Test from '../views/Test.vue'


import Incassi from '../views/account/Incassi.vue'
import Invoice from '../views/account/Invoice.vue'
import Payments from '../views/account/Payments.vue'
import Statistiche from '../views/account/Statistiche.vue'


import ConfAccessi from '../views/config/ConfAccessi.vue'
import ConfWebsite from '../views/config/ConfWebsite.vue'
import ConfOwner from '../views/config/ConfOwner.vue'
import ConfEmail from '../views/config/ConfEmail.vue'
import ConfNavs from '../views/config/ConfNavs.vue'
import ConfSliders from '../views/config/ConfSliders.vue'
import ConfGoogle from '../views/config/ConfGoogle.vue'
import ConfTravelTipologiche from '../views/config/ConfTravelTipologiche.vue'
import Configurazione from '../views/config/Configurazione.vue'
import Lingua from '../views/Lingua.vue'


import UtentiCms from '../views/utenti/UtentiCms.vue'
import Fornitori from '../views/utenti/Fornitori.vue'
import Agenzie from '../views/utenti/Agenzie.vue'
import UtentiClienti from '../views/utenti/UtentiClienti.vue'


import CardSeason from '../views/cards/CardSeason.vue'
import Range from '../views/cards/Range.vue'
import Cards from '../views/cards/Cards.vue'


import Activities from '../views/product/Activities.vue'
import Hotels from '../views/product/Hotels.vue'
import Camere from '../views/product/Camere.vue'
import Flights from '../views/product/Flights.vue'
import Packages from '../views/product/Packages.vue'
import Calendario from '../views/product/Calendario.vue'
import Varianti from '../views/product/Varianti.vue'
import Stagioni from '../views/product/Stagioni.vue'
import ProdottoLang from '../views/product/ProdottoLang.vue'
import Preventivi from '../views/product/Preventivi.vue'
import Preventivo from '../views/product/Preventivo.vue'
import Ordini from '../views/product/Ordini.vue'
import Ordine from '../views/product/Ordine.vue'
import Quota from '../views/product/Quota.vue'
import PreQuota from '../views/product/PreQuota.vue'
import Extra from '../views/product/Extra.vue'
import Shops from '../views/product/Shops.vue'


import Accessi from '../components/common/Accessi.vue'
import Errori from '../components/common/Errori.vue'
import GeoStati from '../components/common/GeoStati.vue'
import GeoRegioni from '../components/common/GeoRegioni.vue'
import GeoProvince from '../components/common/GeoProvince.vue'
import GeoComuni from '../components/common/GeoComuni.vue'
import GeoAree from '../components/common/GeoAree.vue'
import GeoContenutoLang from '../components/common/GeoContenutoLang.vue'
import GeoAeroporti from '../components/common/GeoAeroporti.vue'
import Airlines from '../components/common/Airlines.vue'


import Coupons from '../views/moduli/Coupons.vue'
import Flyers from '../views/moduli/Flyers.vue'
import Routers from '../views/moduli/Routers.vue'
import Notifiche from '../views/moduli/Notifiche.vue'
import Banners from '../views/moduli/Banners.vue'


import Categorie from '../views/content/Categorie.vue'
import Blogs from '../views/content/Blogs.vue'
import News from '../views/content/News.vue'
import Pages from '../views/content/Pages.vue'
import Manuals from '../views/content/Manuals.vue'
import Guida from '../views/content/Guida.vue'
import Faqs from '../views/content/Faqs.vue'








const routes = [
  {    path: '/Install',                          name: 'Install',                      component: Install  },
  {    path: '/Start',                            name: 'Start',                        component: Start  },
  {    path: '/',                                 name: 'Home',                         component: Home  },
  {    path: '/Login',                            name: 'Login',                        component: Login  },
  {    path: '/Dashboard',                        name: 'Dashboard',                    component: Dashboard  },
  {    path: '/Inbox',                            name: 'Inbox',                        component: Inbox  },
  {    path: '/Chat',                             name: 'Chat',                         component: Chat  },
  {    path: '/Tickets',                          name: 'Tickets',                      component: Tickets  },
  {    path: '/Test',                             name: 'Test',                         component: Test  },

  {    path: '/Incassi',                          name: 'Incassi',                      component: Incassi  },
  {    path: '/Invoice',                          name: 'Invoice',                      component: Invoice  },
  {    path: '/Payments',                         name: 'Payments',                     component: Payments  },
  {    path: '/Statistiche',                      name: 'Statistiche',                  component: Statistiche  },

  {    path: '/ConfAccessi',                      name: 'ConfAccessi',                  component: ConfAccessi  },
  {    path: '/ConfWebsite',                      name: 'ConfWebsite',                  component: ConfWebsite  },
  {    path: '/ConfOwner',                        name: 'ConfOwner',                    component: ConfOwner  },
  {    path: '/ConfEmail',                        name: 'ConfEmail',                    component: ConfEmail  },
  {    path: '/ConfNavs',                         name: 'ConfNavs',                     component: ConfNavs  },
  {    path: '/ConfSliders',                      name: 'ConfSliders',                  component: ConfSliders  },
  {    path: '/ConfGoogle',                       name: 'ConfGoogle',                   component: ConfGoogle  },
  {    path: '/Configurazione',                   name: 'Configurazione',               component: Configurazione  },
  {    path: '/ConfTravelTipologiche',            name: 'ConfTravelTipologiche',        component: ConfTravelTipologiche  },
  {    path: '/Lingua',                           name: 'Lingua',                       component: Lingua  },
  
  {    path: '/UtentiCms',                        name: 'UtentiCms',                    component: UtentiCms  },
  {    path: '/Fornitori',                        name: 'Fornitori',                    component: Fornitori  },
  {    path: '/Agenzie',                          name: 'Agenzie',                      component: Agenzie  },
  {    path: '/UtentiClienti',                    name: 'UtentiClienti',                component: UtentiClienti  },

  {    path: '/CardSeason',                       name: 'CardSeason',                   component: CardSeason  },
  {    path: '/Range',                            name: 'Range',                        component: Range  },
  {    path: '/Cards',                            name: 'Cards',                        component: Cards  },

  {    path: '/Activities',                       name: 'Activities',                   component: Activities  },
  {    path: '/Hotels',                           name: 'Hotels',                       component: Hotels  },
  {    path: '/Camere',                           name: 'Camere',                       component: Camere  },
  {    path: '/Flights',                          name: 'Flights',                      component: Flights  },
  {    path: '/Packages',                         name: 'Packages',                     component: Packages  },
  {    path: '/Calendario',                       name: 'Calendario',                   component: Calendario  },
  {    path: '/Varianti',                         name: 'Varianti',                     component: Varianti  },
  {    path: '/Stagioni',                         name: 'Stagioni',                     component: Stagioni  },
  {    path: '/ProdottoLang',                     name: 'ProdottoLang',                 component: ProdottoLang  },
  {    path: '/Preventivi',                       name: 'Preventivi',                   component: Preventivi  },
  {    path: '/Preventivo',                       name: 'Preventivo',                   component: Preventivo  },
  {    path: '/Ordini',                           name: 'Ordini',                       component: Ordini  },
  {    path: '/Ordine',                           name: 'Ordine',                       component: Ordine  },
  {    path: '/Quota',                            name: 'Quota',                        component: Quota  },
  {    path: '/PreQuota',                         name: 'PreQuota',                     component: PreQuota  },
  {    path: '/Extra',                            name: 'Extra',                        component: Extra  },
  {    path: '/Shops',                            name: 'Shops',                        component: Shops  },

  {    path: '/Accessi',                          name: 'Accessi',                      component: Accessi  },
  {    path: '/Errori',                           name: 'Errori',                       component: Errori  },
  {    path: '/GeoStati',                         name: 'GeoStati',                     component: GeoStati  },
  {    path: '/GeoRegioni',                       name: 'GeoRegioni',                   component: GeoRegioni  },
  {    path: '/GeoProvince',                      name: 'GeoProvince',                  component: GeoProvince  },
  {    path: '/GeoComuni',                        name: 'GeoComuni',                    component: GeoComuni  },
  {    path: '/GeoAree',                          name: 'GeoAree',                      component: GeoAree  },
  {    path: '/GeoContenutoLang',                 name: 'GeoContenutoLang',             component: GeoContenutoLang  },

  {    path: '/GeoAeroporti',                     name: 'GeoAeroporti',                 component: GeoAeroporti  },
  {    path: '/Airlines',                         name: 'Airlines',                     component: Airlines  },

  {    path: '/Categorie',                        name: 'Categorie',                    component: Categorie  },
  {    path: '/Coupons',                          name: 'Coupons',                      component: Coupons  },
  {    path: '/Flyers',                           name: 'Flyers',                       component: Flyers  },
  {    path: '/Routers',                          name: 'Routers',                      component: Routers  },
  {    path: '/Notifiche',                        name: 'Notifiche',                    component: Notifiche  },
  {    path: '/Banners',                          name: 'Banners',                      component: Banners  },
  
  {    path: '/Blogs',                            name: 'Blogs',                        component: Blogs  },
  {    path: '/News',                             name: 'News',                         component: News  },
  {    path: '/Pages',                            name: 'Pages',                        component: Pages  },
  {    path: '/Manuals',                          name: 'Manuals',                      component: Manuals  },
  {    path: '/Guida',                            name: 'Guida',                        component: Guida  },
  {    path: '/Faqs',                             name: 'Faqs',                         component: Faqs  },

  




]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
