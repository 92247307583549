<template>
    <div>

        <div class="row mt-1">
            <div class="col-6 col-md-2 mb-2">
                <label class="col-lg-3 col-form-label">Alias:</label> 
            </div>
            <div class="col-6 col-md-10 mb-2">
                <input type="text" class="form-control form-control-sm" v-model="seo.ALIAS">
            </div>
        </div>
        <br />

        <ul class="nav nav-tabs mt-0 mb-1" id="tabs" role="tablist" style="margin-top:14px">
            <li class="nav-item">
                <a class="nav-link isprint-tab active" data-bs-toggle="tab" data-tab="seotile" href="#seotile" role="tab" aria-controls="seotile" aria-selected="true">
                    <i class="far fa-ethernet fa-fw me-2"></i> TITOLO SEO
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="seodesc" href="#seodesc" role="tab" aria-controls="seodesc" aria-selected="true">
                    <i class="far fa-brackets-curly fa-fw me-2"></i> META DESCRIPTION
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="seokey" href="#seokey" role="tab" aria-controls="seokey" aria-selected="true">
                    <i class="far fa-keyboard fa-fw me-2"></i> META KEYWORDS
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="seomicro" href="#seomicro" role="tab" aria-controls="seomicro" aria-selected="true">
                    <i class="far fa-hdd fa-fw me-2"></i> MICRODATI
                </a>
            </li>
        </ul>
        <div class="tab-content">

            <div class="tab-pane active" id="seotile" role="tabpanel" style="padding: 0.5rem;">
                <div class="row">
                    <div class="col-md-7"> 
                        <textarea class="form-control" rows="10" cols="1" v-model="seo.METATITLE"></textarea>                                                     
                    </div>
                    <div class="col-md-5"> 
                        <div class="alert alert-info">
                            <label><i class="far fa-lightbulb fa-fw me-1"></i><strong>Titolo SEO</strong></label><br />
                            <span>Scegli un titolo per la tua pagina che abbia tra i 50 e i 70 caratteri e che contenga un minimo di cinque e un massimo di dieci parole.</span>
                        </div>                                                    
                    </div>
                </div>
            </div>

            <div class="tab-pane" id="seodesc" role="tabpanel" style="padding: 0.5rem;">
                <div class="row">
                    <div class="col-md-7"> 
                        <textarea class="form-control" rows="10" cols="1" v-model="seo.METADESCRIPTION"></textarea>                                                     
                    </div>
                    <div class="col-md-5"> 
                        <div class="alert alert-info">
                            <label><i class="far fa-lightbulb fa-fw me-1"></i><strong>Meta Description</strong></label><br />
                            <span>Completa la descrizione della pagina. La descrizione ideale e completa dovrebbe essere composta da 140 a 180 caratteri.</span>
                        </div>                                                   
                    </div>
                </div>
            </div>
            
            <div class="tab-pane" id="seokey" role="tabpanel" style="padding: 0.5rem;">
                <div class="row">
                    <div class="col-md-7"> 
                        <textarea class="form-control" rows="10" cols="1" v-model="seo.METAKEY"></textarea>                                                    
                    </div>
                    <div class="col-md-5"> 
                        <div class="alert alert-info">
                            <label><i class="far fa-lightbulb fa-fw me-1"></i><strong>Meta Keywords</strong></label><br />
                            <span>Completa la pagina con le parole chiavi. Le parole chiavi ideali dovono essere non più di 10 e possono essere parole composte.</span>
                        </div>                                                   
                    </div>
                </div>
            </div>
            
            <div class="tab-pane" id="seomicro" role="tabpanel" style="padding: 0.5rem;">
                <div class="row">
                    <div class="col-md-7"> 
                        <textarea class="form-control" rows="10" cols="1" v-model="seo.METAMICRODATA"></textarea>                                                    
                    </div>
                    <div class="col-md-5"> 
                        <div class="alert alert-info">
                            <label><i class="far fa-lightbulb fa-fw me-1"></i><strong>Microdati</strong></label><br />
                            <span>Affinché le pagine del sito siano idonee per i risultati multimediali, dovresti eseguirne il markup con uno dei tre formati supportati: JSON-LD (consigliato),Microdati,RDFa</span>
                        </div>                                                     
                    </div>
                </div>
            </div>
        </div>

    </div> 
</template>


<script>

import { computed } from 'vue'

export default {
    name:'Seo',
    props: {
      modelValue: {
          type: Object,
          default: () => ({}),
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const seo = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });
        return { seo };
    },

    data:function(){
        return{}
    },

    methods:{
           
    },

    computed: {
    },

    watch: {

    },
}
</script>        
