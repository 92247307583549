<template>
  	<div>

	    <!-- Page header -->
	    <div class="page-header page-header-light">
	        <div class="page-header-content header-elements-lg-inline">
	            <div class="page-title d-flex">
	                <h4 class="d-none d-sm-block">
	                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
						<a href="#" style="color:#000;"><span class="font-weight-semibold"> Preferenze </span></a> \
	                    <span class="text-muted">Configurazione Azienda </span><code> - Lingua italiano</code>
	                </h4>
	                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Configurazione Azienda </h4>
	                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
	            </div>
	            <div class="header-elements d-none">
	                <div class="d-flex justify-content-center">
	                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
	                </div>
	            </div>
	        </div>
	    </div>
	    <!-- /page header -->
	    
	    <!-- Content area -->
	    <div class="content content-2">
	
	       <div class="card shadow mb-0">
	            <div class="card-header card-header-2 header-elements-inline">
	                <h5 class="card-title"><i class="icon-warning me-2"></i> ATTENZIONE </h5>
	                <div class="header-elements">
	                    <div class="list-icons">
	                        <a class="list-icons-item" data-action="collapse"></a>
	                    </div>
	           		</div>
	        	</div>
	            <div class="collapse show">
		            <div class="card-body card-body-2">
                        <p class="bg-light py-1 px-2 rounded mb-3 text-dark">I campi contrassegnati in <span class="text-danger">rosso</span> sono <strong>Obbligatori</strong>. 
                        Inserire dati corretti e veritieri, la maggior parte sono pubblicati su documenti fiscali.</p>    
                        <div class="row">
                            <div class="col-lg-8">
                                <fieldset>
                                    <legend class="font-weight-semibold"><i class="icon-wrench2 me-2"></i> Dati principali</legend>
                                    <div class="row">
                                        <div class="col-lg-6 form-group-1">
                                            <label>Nome Azienda:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-qrcode fa-fw"></i></span></span>
                                                <input type="text" class="form-control" placeholder="Left addon" tabindex="4">
                                            </div>
                                        </div> 
                                        <div class="col-lg-6 form-group-1">
                                            <label>Ragione Sociale:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-tag fa-fw"></i></span></span>
                                                <input type="text" class="form-control" placeholder="Left addon" tabindex="1">
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 form-group-1">
                                            <label>Inserire indirizzo completo:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-location fa-fw"></i></span></span>
                                                <input type="text" class="form-control" placeholder="Left addon" tabindex="4">
                                            </div>
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                            <label>Latitudine:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-map-pin fa-fw"></i></span></span>
                                                <input type="text" class="form-control" placeholder="Left addon" tabindex="5">
                                            </div>
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                            <label>Longitudine:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-map-pin fa-fw"></i></span></span>
                                                <input type="text" class="form-control" placeholder="Left addon" tabindex="5">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 form-group-1">
                                            <label>Stato</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-flag fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 form-group-1">
                                            <label>Regione</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-map fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 form-group-1">
                                            <label>Città</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-city fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>                         
                                    </div>
                                    <br />                                        
                                    <div class="row">
                                        <div class="col-lg-6 form-group-1">
                                            <label>Email societaria:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-at fa-fw"></i></span></span>
                                                <input type="text" placeholder="Ring street 12" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 form-group-1">
                                            <label>E-mail Certificata: <code>(PEC)</code></label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-certificate fa-fw"></i></span></span>
                                                <input type="text" placeholder="Ring street 12" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-3 form-group-1">
                                            <label>Fax:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-fax fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                            <label>Lingua Documenti:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-language fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                            <label>Telefono primario:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-phone fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                            <label>Telefono secondario:</label>
                                            <div class="input-group">
                                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-phone-office fa-fw"></i></span></span>
                                                <select class="form-control">
                                                    <option value="">Seleziona</option>
                                                    <option value="0"> - Si</option>
                                                    <option value="1"> - No</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-3 form-group-1">
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                        </div>
                                        <div class="col-lg-3 form-group-1">
                                        </div>
                                    </div>   
                                </fieldset>
                            </div>
                            <div class="col-lg-4">
                                <fieldset>
                                    <legend class="font-weight-semibold"><i class="icon-cog3 me-2"></i> Dati Configurazione</legend>
                                    <div class="box_600">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="card-group-control card-group-control-right" id="accordion-control-right">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h6 class="card-title">
                                                                <a class="collapsed text-body" data-bs-toggle="collapse" href="#accordion-control-right-group1"><i class="far fa-abacus fa-fw"></i> Amministrativo</a>
                                                            </h6>
                                                        </div>
                                                        <div id="accordion-control-right-group1" class="collapse" data-parent="#accordion-control-right">
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Amministratore Societario</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-user-cog fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" placeholder="Left addon" value="Barbara Marangi" tabindex="5">
                                                                        </div>                                            
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-md-6 form-group-1">
                                                                        <label>Partita IVA</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-key fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xp_iva" value="05649530721">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 form-group-1">
                                                                        <label>Codice SDI</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-code fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xcode_sdi" value="USAL8PV">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-8 form-group-1">
                                                                        <label>Codice IBAN</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-barcode fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xiban" value="IT 71 Z 0542404013000001024937">
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <div class="col-lg-4 form-group-1">
                                                                        <label>Codice SWIF</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-percentage fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h6 class="card-title">
                                                                <a class="collapsed text-body" data-bs-toggle="collapse" href="#accordion-control-right-group2"><i class="far fa-layer-group fa-fw"></i> Societario</a>
                                                            </h6>
                                                        </div>
                                                        <div id="accordion-control-right-group2" class="collapse" data-parent="#accordion-control-right">
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-lg-6 form-group-1">
                                                                        <label>Licenza Turistica:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-file-alt fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xiban" value="IT 71 Z 0542404013000001024937">
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <div class="col-lg-6 form-group-1">
                                                                        <label>REA:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-brackets-curly fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div> 
                                                                <div class="row">
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Fondo di garanzia:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-project-diagram fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xiban" value="IT 71 Z 0542404013000001024937">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div> 
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-shield-check fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h6 class="card-title">
                                                                <a class="collapsed text-body" data-bs-toggle="collapse" href="#accordion-control-right-group3"><i class="far fa-paper-plane fa-fw"></i> Mail</a>
                                                            </h6>
                                                        </div>
                                                        <div id="accordion-control-right-group3" class="collapse" data-parent="#accordion-control-right">
                                                            <div class="card-body">
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>       
                                                                <div class="row">    
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Assicurazione:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xswifbic" value="">
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>                                                                        
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h6 class="card-title">
                                                                <a class="collapsed text-body" data-bs-toggle="collapse" href="#accordion-control-right-group4"><i class="far fa-life-ring fa-fw"></i> Assistenza</a>
                                                            </h6>
                                                        </div>
                                                        <div id="accordion-control-right-group4" class="collapse" data-parent="#accordion-control-right">
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-lg-6 form-group-1">
                                                                        <label>Titolo:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-ballot-check fa-fw"></i></span></span>
                                                                            <select name="xoffline" class="form-control">
                                                                                <option value="0"> - No</option>
                                                                                <option value="1"> - Si</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 form-group-1">
                                                                        <label>Data prossima scadenza:</label>
                                                                        <div class="input-group">
                                                                            <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-calendar fa-fw"></i></span></span>
                                                                            <input type="text" class="form-control" name="xopendate" value="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-lg-12 form-group-1">
                                                                        <label>Messaggio canone assistenza:</label>
                                                                        <textarea class="form-control" rows="4" cols="1"  name="xoffline_message"></textarea>  
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                        
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        
                        </div>
                        <hr />
                        <div class="row mb-2">
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary"><i class="icon-floppy-disk me-2"></i> Salva Modifiche </button>
                                </div>
                            </div>
                        </div>
	                </div>
	            </div>
	       </div>
	    </div>

	</div>
</template>



<script>

export default {
    name:"ConfOwner",
    components:{
    },
    data: function () {
        return {   
             searchFunzionalita:{
            },
            arrayOpzioniConfigurazione : new Array(
                {
                    titolo:"Generali",
                    id:1,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:1,LABEL:'NOME_SITO', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:35,LABEL:'URL_SITO', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:26,LABEL:'TITLE', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:29,LABEL:'MASCHERA_LOGIN_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:42,LABEL:'EMAIL_ADMIN', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:44,LABEL:'TIMEOUT_SESSIONE', TYPE:"TEXT",VALORE:3600},
                        {ID_OPZIONE_BASE:45,LABEL:'RELOAD_SESSIONE_SCADUTA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:46,LABEL:'FLG_UNA_SESSIONE_PER_UTENTE', TYPE:"SELECT",VALORE:0,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:47,LABEL:'SOGLIA_MULTIPLE_INSERT', TYPE:"TEXT",VALORE:3000},
                        {ID_OPZIONE_BASE:48,LABEL:'DROPBOX_CLIENT_ID', TYPE:"TEXT",VALORE:''},
                    )
                },
                {
                    titolo:"Stile",
                    id:4,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:2,LABEL:'COLORE_HEAD', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:3,LABEL:'LOGO_HEAD', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:4,LABEL:'LOGO_MAIN', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:36,LABEL:'BACKGROUND', TYPE:"TEXT",VALORE:'#f8f9fc'},
                        {ID_OPZIONE_BASE:21,LABEL:'MOSTRA_MENU_LATERALE', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:22,LABEL:'MOSTRA_MENU_TOP', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:23,LABEL:'COLORE_TESTO_HEAD', TYPE:"TEXT", TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:24,LABEL:'ALTEZZA_HEAD', TYPE:"TEXT", TYPE:"TEXT",VALORE:46},
                        {ID_OPZIONE_BASE:25,LABEL:'MOSTRA_BREADCRUMB', TYPE:"SELECT", TYPE:"TEXT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:39,LABEL:'DELTA_MARGIN_TOP', TYPE:"TEXT", TYPE:"TEXT",VALORE:'0'},
                        {ID_OPZIONE_BASE:40,LABEL:'BG_LOGIN', TYPE:"TEXT", TYPE:"TEXT",VALORE:'#FFF'},

                    )
                },
                {
                    titolo:"Email",
                    id:2,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:5,LABEL:'USERNAME', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:6,LABEL:'PASSWORD', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:7,LABEL:'FROM', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:8,LABEL:'REPLY_TO', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:9,LABEL:'SERVER_SMTP', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:10,LABEL:'PORTA', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:11,LABEL:'SICUREZZA', TYPE:"SELECT",VALORE:'0',OPTIONS:new Array({ID:0,DESCRIZIONE:'NESSUNA'},{ID:'ssl',DESCRIZIONE:'SSL'},{ID:'tls',DESCRIZIONE:'TLS'})},
                        {ID_OPZIONE_BASE:12,LABEL:'DKIM_DOMAIN', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:13,LABEL:'DKIM_KEY', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:14,LABEL:'DKIM_SELECTOR', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:41,LABEL:'TESTATA_EMAIL', TYPE:"TEXT",VALORE:'-'},
                    )
                },
                {
                    titolo:"Registrazione",
                    id:3,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:15,LABEL:'REGISTRAZIONE_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:16,LABEL:'REGISTRAZIONE_CON_FACEBOOK_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:17,LABEL:'ID_APP_FACEBOOK', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:18,LABEL:'FACEBOOK_APP_SECRET', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:19,LABEL:'REGISTRAZIONE_CON_GOOGLE_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:20,LABEL:'ID_APP_GOOGLE', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:27,LABEL:'FK_ID_LIVELLO_DEFAULT', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:28,LABEL:'FK_ID_GRUPPO_DEFAULT', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:43,LABEL:'NOTIFICA_REGISTRAZIONE_ADMIN', TYPE:"TEXT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:49,LABEL:'LIVELLO_PASSWORD',TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'LIBERA'},{ID:1,DESCRIZIONE:'LUNGHEZZA MINIMA 8'},{ID:2,DESCRIZIONE:'ALMENO UN CARATTERE ALFABETICO'},{ID:3,DESCRIZIONE:'ALMENO UN NUMERO'},{ID:4,DESCRIZIONE:'ALMENO UN CARATTERE SPECIALE'})},
                        {ID_OPZIONE_BASE:50,LABEL:'MESSAGGIO_REGISTRAZIONE', TYPE:"TEXT",VALORE:''},
                    )
                },
                {
                    titolo:"Stripe",
                    id:5,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:32,LABEL:'MODALITA_STRIPE', TYPE:"SELECT",VALORE:0,OPTIONS:new Array({ID:0,DESCRIZIONE:'TEST'},{ID:1,DESCRIZIONE:'LIVE'})},
                        {ID_OPZIONE_BASE:30,LABEL:'TEST_STRIPE_PUBLIC_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:31,LABEL:'TEST_STRIPE_SECRET_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:37,LABEL:'TEST_STRIPE_CLIENT_ID', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:33,LABEL:'LIVE_STRIPE_PUBLIC_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:34,LABEL:'LIVE_STRIPE_SECRET_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:38,LABEL:'LIVE_STRIPE_CLIENT_ID', TYPE:"TEXT",VALORE:''},
                    )
                },
                {
                    titolo:"Altre",
                    id:100,
                    opzioni: new Array(
                        
                    )
                },
            ),
        }
    },
    methods: {
        
        add: function() {
            this.list.push({ name: "Juan" });
          },
          replace: function() {
            this.list = [{ name: "Edgard" }];
          },
          clone: function(el) {
            return {
              name: el.name + " cloned"
            };
          },
          log: function(evt) {
            window.console.log(evt);
        },
        customFormElementOrdineHandler: function(evt) {
            window.console.log(evt);
            for (var i = 0 ; i < this.arrayCustomFormElement.length ; i++){
                this.arrayCustomFormElement[i].ORDINE = (i+1);
            }
        },
        salvaOrdineCustomFormElements : function(){
            utils.ajax("core/customformelement/ordine",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM,arrayCustomFormElement:this.arrayCustomFormElement}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        },
        getFunzionalita: function () {
            utils.ajax("core/funzionalita",{}, (response) => {
                this.arrayFunzionalita = response.data;
            });
        },
        nuovaFunzionalita:function(){
            this.funzionalitaSelezionata = utils.nuovoElemento(this.funzionalitaSelezionata,{ID_FUNZIONALITA:0,FLG_CORE:0,DESCRIZIONE:"",FK_ID_ITEM:0},"popUpFunzionalita");
        },
        modificaFunzionalita:function(funzionalita){
            this.funzionalitaSelezionata = utils.modificaElemento(this.funzionalitaSelezionata,funzionalita,"popUpFunzionalita");
        },
        salvaFunzionalita:function(){
            utils.salvaElemento("core/funzionalita/",this.funzionalitaSelezionata,"ID_FUNZIONALITA","popUpFunzionalita",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getFunzionalita();
                }
            });
        },
        getGruppi: function () {
            utils.ajax("core/gruppi",{}, (response) => {
                this.arrayGruppi = response.data;
            });
        },
        funzionalitaGruppo : function(gruppo){
            this.arrayFunzionalitaGruppo = new Array();
            utils.ajax("core/gruppo/funzionalita",gruppo, (response) => {
                this.gruppoSelezionato = response.data.gruppo;
                for (var i = 0 ; i < response.data.funzionalita.length ; i++){
                    var selezionato = utils.array.select(this.gruppoSelezionato.funzionalita,"ID_FUNZIONALITA",response.data.funzionalita[i]['ID_FUNZIONALITA'],1).length == 1 ? 1 : 0;
                    var item = utils.clone(response.data.funzionalita[i]);
                    item.SELEZIONATO = selezionato;
                    this.arrayFunzionalitaGruppo.push(item);
                }
                this.filters.openPopUp("popUpFunzionalitaGruppo");
            });
        },
        nuovoGruppo:function(){
            this.gruppoSelezionato = utils.nuovoElemento(this.gruppoSelezionato,{ID_GRUPPO:0,FLG_CORE:0,DESCRIZIONE:""},"popUpGruppo");
        },
        modificaGruppo:function(gruppo){
            this.gruppoSelezionato = utils.modificaElemento(this.gruppoSelezionato,gruppo,"popUpGruppo");
        },
        salvaGruppo:function(){
            utils.salvaElemento("core/gruppi/",this.gruppoSelezionato,"ID_GRUPPO","popUpGruppo",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getGruppi();
                }
            });
        },
        getLivelli: function () {
            utils.ajax("core/livelli",{}, (response) => {
                this.arrayLivelli = response.data;
            });
        },
        nuovoLivello:function(){
            this.livelloSelezionato = utils.nuovoElemento(this.livelloSelezionato,{ID_LIVELLO:0,FLG_CORE:0,DESCRIZIONE:""},"popUpLivello");
        },
        modificaLivello:function(funzionalita){
            this.livelloSelezionato = utils.modificaElemento(this.livelloSelezionato,funzionalita,"popUpLivello");
        },
        salvaLivello:function(){
            utils.salvaElemento("core/livelli/",this.livelloSelezionato,"ID_LIVELLO","popUpLivello",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getLivelli();
                }
            });
        },
        updateFunzionalitaGruppo:function(selezionato,funzionalita){
            utils.ajax("core/gruppi/funzionalita/update",{ID_FUNZIONALITA:funzionalita.ID_FUNZIONALITA,ID_GRUPPO:this.gruppoSelezionato.ID_GRUPPO,SELEZIONATO:funzionalita.SELEZIONATO}, (response) => {
                
            });
        },

        // TESTI EMAIL
        getTestiEmail: function () {
            utils.ajax("core/testiemail",{}, (response) => {
                this.arrayTestiEmail = response.data;
            });
        },
        nuovoTestoEmail:function(){
            this.testoEmailSelezionato = utils.nuovoElemento(this.testoEmailSelezionato,{ID_TESTO_EMAIL:0,className:"TestoEmail"},"popUpTestoEmail");
        },
        modificaTestoEmail:function(testo){
            testo.className = "TestoEmail";
            this.testoEmailSelezionato = utils.modificaElemento(this.testoEmailSelezionato,testo,"popUpTestoEmail");
        },
        salvaTestoEmail:function(){
            utils.salvaElemento("core/basic/",this.testoEmailSelezionato,"ID_TESTO_EMAIL","popUpTestoEmail",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getTestiEmail();
                }
            });
        },
        testEmail : function(testo){
            this.testoEmailSelezionato = testo;
            this.filters.openPopUp("popUpEmail");
        },
        doTestEmail : function(){
            this.filters.closePopUp("popUpEmail");
            utils.ajax("core/testiemail/test",{ID_TESTO_EMAIL:this.testoEmailSelezionato.ID_TESTO_EMAIL,EMAIL:this.emailTest}, (response) => {
                
            });
        },

        /// MENU

        getMenu: function () {
            utils.ajax("core/menu",{}, (response) => {
                this.arrayMenu = response.data;
            });
        },
        nuovoMenu:function(){
            this.menuSelezionato = utils.nuovoElemento(this.menuSelezionato,{ID_MENU:0,FLG_CORE:0,DESCRIZIONE:""},"popUpMenu");
        },
        nuovoMenuChildren:function(menu){
            this.menuSelezionato = utils.nuovoElemento(this.menuSelezionato,{FK_ID_MENU:menu.hasOwnProperty("ID_MENU") ? menu.ID_MENU : menu.FK_ID_MENU,
                                                                                DESCRIZIONE:"",
                                                                                ID_ITEM:0,
                                                                                ID_ITEM_PADRE:menu.hasOwnProperty("ID_MENU") ? 0 : menu.ID_ITEM},"popUpMenuChildren");
        },
        modificaMenu:function(menu){
            this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenu");
        },
        modificaMenuChildren:function(menu){
            if (menu.hasOwnProperty("ID_MENU")){
                this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenu");
            }
            else{
                this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenuChildren");
            }
        },
        salvaMenu:function(){
            utils.salvaElemento("core/menu/",this.menuSelezionato,"ID_MENU","popUpMenu",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getMenu();
                }
            });
        },
        salvaMenuChildren:function(){
            this.menuSelezionato.className = "MenuItem";
            utils.salvaElemento("core/basic/",this.menuSelezionato,"ID_ITEM","popUpMenuChildren",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    //this.getMenu();
                }
            });
        },
        deleteMenuChildren : function (menu){
            utils.alert.confirm("Sicuro di voler eliminare il menu e tutti i suoi figli?",() => {
                utils.ajax('core/basic/delete',{className:"MenuItem","id" : menu.ID_ITEM}, (response) => {
                    this.getMenu();
                });
            });
        },
        childrenMenu : function(menu){
            utils.ajax("core/menu/children",{ID_MENU:menu.ID_MENU}, (response) => {
                var arr = utils.clone(this.arrayMenu);
                for (var i = 0 ; i < arr.length ; i++){
                    if (arr[i].ID_MENU == menu.ID_MENU){
                        var item = utils.clone(arr[i]);
                        item.open = true;
                        item.children = response.data.items;
                        for (var j = 0 ; j < item.children.length ; j++){
                            item.children[j].children = item.children[j].items;
                            item.open = true;
                        }
                        arr[i] = item;
                        break;
                    }
                }
                this.arrayMenu = arr;
                this.$forceUpdate();
            });
        },
        

        //// GRUPPI TIPOLOGICHE

        getGruppiTipologiche: function () {
            utils.ajax("core/tipologiche/gruppi",{}, (response) => {
                this.arrayGruppiTipologiche = response.data;
            });
        },
        nuovoGruppoTipologiche:function(){
            this.gruppoTipologicaSelezionato = utils.nuovoElemento(this.gruppoTipologicaSelezionato,{ID_GRUPPO_TIPOLOGICA:0,DESCRIZIONE:"",FK_ID_LIVELLO:0,FK_ID_ENTE:1},"popUpGruppoTipologiche");
        },
        modificaGruppoTipologiche:function(gr){
            this.gruppoTipologicaSelezionato = utils.modificaElemento(this.gruppoTipologicaSelezionato,gr,"popUpGruppoTipologiche");
        },
        salvaGruppoTipologiche:function(){
            utils.salvaElemento("core/tipologiche/gruppi/",this.gruppoTipologicaSelezionato,"ID_GRUPPO_TIPOLOGICA","popUpGruppoTipologiche",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getGruppiTipologiche();
                }
            });
        },

        tipologicheGruppo : function(gruppo){
            this.gruppoTipologicaSelezionato = utils.clone(gruppo);
            utils.ajax("core/tipologiche",{FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA}, (response) => {
                this.arrayTipologiche = response.data;
                this.filters.openPopUp("popUpTipologicheGruppo");
            });
        },


        ///// TIPOLOGICHE

        nuovaTipologica:function(){
            this.tipologicaSelezionata = utils.nuovoElemento(this.tipologicaSelezionata,{ID_TIPOLOGICA:0,DESCRIZIONE:"",VALORE:"",FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA},"popUpTipologica");
        },
        modificaTipologica:function(t){
            this.tipologicaSelezionata = utils.modificaElemento(this.tipologicaSelezionata,t,"popUpTipologica");
        },
        updateTipologica : function(t){
            this.tipologicaSelezionata = utils.clone(t);
            this.salvaTipologica('popUpTipologica');
        },
        deleteTipologica : function(t){
            utils.alert.confirm("Sei sicuro di voler eliminare la tipologica selezionata?",() => {
                utils.ajax('/core/tipologiche/delete',{ID_TIPOLOGICA:t.ID_TIPOLOGICA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax("core/tipologiche",{FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA}, (response) => {
                            this.arrayTipologiche = response.data;
                        });                        
                    }
                });
            });
        },
        salvaTipologica:function(popup = "popUpTipologica"){
            utils.salvaElemento("core/tipologiche/",this.tipologicaSelezionata,"ID_TIPOLOGICA",popup,(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax("core/tipologiche",{FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA}, (response) => {
                        this.arrayTipologiche = response.data;
                        console.log(this.arrayTipologiche);
                    });
                }
            });
        },


        //CUSTOM FORM
        getCustomForm: function () {
            utils.ajax("core/customform",{}, (response) => {
                this.arrayCustomForm = response.data;
            });
        },
        nuovoCustomForm:function(){
            this.customFormSelezionato = utils.nuovoElemento(this.customFormSelezionato,{ID_CUSTOM_FORM:0,DESCRIZIONE:""},"popUpCustomForm");
        },
        modificaCustomForm:function(menu){
            this.customFormSelezionato = utils.modificaElemento(this.customFormSelezionato,menu,"popUpCustomForm");
        },
        salvaCustomForm:function(){
            utils.salvaElemento("core/customform/",this.customFormSelezionato,"ID_CUSTOM_FORM","popUpCustomForm",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getCustomForm();
                }
            });
        },

        controlliForm : function(_form){
            this.customFormSelezionato = utils.clone(_form);
            this.filters.openPopUp("popUpCustomFormElements");
            utils.ajax("core/customformelement",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM}, (response) => {
                this.arrayCustomFormElement = response.data;
            });
        },
        
        nuovoCustomFormElement:function(){
            this.customFormElementSelezionato = utils.nuovoElemento(this.customFormElementSelezionato,{ID_ELEMENT:0,CLASSE_CTRL:'input-sm',CLASSE:'col-md-',FK_ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM},"popUpCustomFormElement");
        },
        modificaCustomFormElement:function(t){
            this.customFormElementSelezionato = utils.modificaElemento(this.customFormElementSelezionato,t,"popUpCustomFormElement");
        },
        updateCustomFormElement : function(t){
            this.customFormElementSelezionato = utils.clone(t);
            this.salvaCustomFormElement('');
        },
        deleteCustomFormElement : function(t){
            utils.alert.confirm("Sei sicuro di voler eliminare l'elemento selezionato?",() => {
                utils.ajax('/core/customformelement/delete',{ID_ELEMENT:t.ID_ELEMENT}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax("core/customformelement",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM}, (response) => {
                            this.arrayCustomFormElement = response.data;
                        });                       
                    }
                });
            });
        },
        salvaCustomFormElement:function(popup = "popUpCustomFormElement"){
            utils.salvaElemento("core/customformelement/",this.customFormElementSelezionato,"ID_ELEMENT",popup,(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax("core/customformelement",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM}, (response) => {
                        this.arrayCustomFormElement = response.data;
                    });
                }
            });
        },
        esportaSelezionati : function(){
            var arr = utils.array.select(this.arrayCustomForm,"SELEZIONATO",1);
            utils.ajax("core/customform/esporta",{arr:arr}, (response) => {
                $key = response.data.key;
                window.open("CORE/SERVER/API/core/report?ID_REPORT=1&KEY="+$key);
            });
        },
        installaCustomForm : function(){
            this.filters.openPopUp("popInstallazioneCustomForm");
        },
        chiudiPopUpInstallazioneCustomForm : function(){
            this.filters.openPopUp("popInstallazioneCustomForm");
        },
        getOpzioni : function(){
            utils.ajax('core/admin/opzioni',{}, (response) => {
                var arrayOpzioni = response.data;
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                        this.arrayOpzioniConfigurazione[i].opzioni[j].CLASSE_CTRL = 'input-sm';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].VMODEL = 'VALORE';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = 0;
                        for (var k = arrayOpzioni.length-1 ; k > -1  ; --k){
                            if (this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE_BASE == arrayOpzioni[k].ID_OPZIONE_BASE){
                                this.arrayOpzioniConfigurazione[i].opzioni[j].VALORE = arrayOpzioni[k].VALORE;
                                this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = arrayOpzioni[k].ID_OPZIONE;
                                arrayOpzioni.splice(k,1);
                                break;
                            }
                        }
                    }
                }
                //AGGIUNGO LE OPZIONI CUSTOM DEL SITO
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    if (this.arrayOpzioniConfigurazione[i].id == 100){
                        for (var j = 0 ; j < arrayOpzioni.length ; j++){
                            this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:arrayOpzioni[j].LABEL,VALORE:arrayOpzioni[j].VALORE,TYPE:'TEXTAREA',ID_OPZIONE:arrayOpzioni[j].ID_OPZIONE});
                        }
                        this.$forceUpdate();
                        break;
                    }
                }
            });
        },
        nuovaOpzione : function(){
            this.opzioneSelezionata = {};
            this.filters.openPopUp("popUpNuovaOpzione");
        },
        salvaOpzioneSito : function(){
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                if (this.arrayOpzioniConfigurazione[i].id == 100){
                    var opzione = utils.clone(this.opzioneSelezionata);
                    this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:opzione.LABEL,VALORE:opzione.VALORE,TYPE:'TEXT'});
                    this.$forceUpdate();
                    this.filters.closePopUp("popUpNuovaOpzione");
                    break;
                }
            }
        },
        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                    arr.push(this.arrayOpzioniConfigurazione[i].opzioni[j]);
                }
            }
            utils.ajax('core/admin/opzioni/update',{opzioni:arr}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        },
        test:function(){
            utils.ajax('core/test',{DESCRIZIONE:"PROVA"}, (response) => {
                
            });
        },
        test2:function(){
            utils.report.token({modalita:'esportaUtenti',formato:1});
        },
        backup : function(){
            utils.ajax('core/admin/backup',{}, (response) => {
                
            });
            
        }

    },
    computed: {
        uploadDisabled() {
            return this.files.length === 0;
          },
        paginatedListFunzionalita(){
            var arr = utils.inGrid(this.arrayFunzionalita,this.searchFunzionalita);
            return arr;
        },
        paginatedListGruppi(){
            var arr = utils.inGrid(this.arrayGruppi,this.searchGruppi);
            return arr;
        },
        paginatedListLivelli(){
            var arr = utils.inGrid(this.arrayLivelli,this.searchLivelli);
            return arr;
        },
        paginatedLisFunzGruppo(){
            var arr = utils.inGrid(this.arrayFunzionalitaGruppo,this.searchFunzGruppo);
            return arr;
        },
        paginatedListGruppiTipologiche(){
            var arr = utils.inGrid(this.arrayGruppiTipologiche,this.searchGruppiTipologiche);
            return arr;
        },
        paginatedListTipologiche(){
            var arr = utils.inGrid(this.arrayTipologiche,this.searchTipologiche);
            return arr;
        },
        paginatedListMenu(){
            var arr = utils.inGrid(this.arrayMenu,this.searchMenu);
            return arr;
        },
        paginatedListMenuChildren (){
            var arr = utils.inGrid(this.arrayMenuChildren,this.searchMenuChidren);
            return arr;
        },
        paginatedListCustomForm(){
            var arr = utils.inGrid(this.arrayCustomForm,this.searchCustomForm);
            return arr;
        },
        paginatedListTestiEmail(){
            var arr = utils.inGrid(this.arrayTestiEmail,this.searchEmail);
            return arr;
        }
    },
    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    created: function () {
        this.selectedMenuItem = this.$root.selectedMenuItem;
        setTimeout(() => {
            $('a[data-bs-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
                switch(tab){
                    case 'funzionalita':
                        this.getFunzionalita();
                        break;
                    case 'gruppi':
                        this.getGruppi();
                        break;
                    case 'livelli':
                        this.getLivelli();
                        break;
                    case 'menu':
                        this.getMenu();
                        break;
                    case 'gruppiTipologiche':
                        this.getGruppiTipologiche();
                        break;
                    case 'tipologiche':
                        this.getTipologiche();
                        break;
                    case 'customForm':
                        this.getCustomForm();
                        break;
                    case 'configurazione':
                        this.getOpzioni();
                        break;
                    case 'email':
                        this.getTestiEmail();
                        break;
                }
            });            
        },300);
        //
        utils.ajax('core/basic/element',{"className":"CustomForm","id":1}, (response) => {
            this.arrayCampiMenuChildren = response.data.elements;
            utils.ajax('core/menu/children/linear',{}, (response) => {
                this.linearMenuItems = response.data;
                this.getFunzionalita(); 
            }); 
        });
    },
}
</script>