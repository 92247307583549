<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getContenuti()"> Contenuti </a> \ 
					<span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> {{moduleName}}</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;" v-on:click="nuovoContenuto()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Contenuto</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(35)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(35)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(35)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#contenuti-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Contenuti
							</div>
						</a>
					</li>
				</ul>
				
				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getContenuti()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>	   
						<li class="nav-item ms-4">
							<SelectLingue class="form-select form-select-sm" @change="getContenuti()" v-model="currentLang">
							</SelectLingue>						
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="contenuti-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="contenuto-a">
									<div class="row ">
										<div class="col-5 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" v-model="search.ID_CONTENUTO.VALUE" placeholder="Id Contenuto" :tabindex="1" />
										</div>	
			                            <div class="col-7 col-md-2 col-lg-2 mbx-1">
		                                    <select class="form-select form-select-sm" v-model="search.FK_ID_CATEGORIA.VALUE" placeholder="Seleziona Categoria">
		                                        <option v-bind:value="''">Seleziona Categoria</option>
		                                        <option v-for="categoria in arrayCategorie" v-bind:key="categoria.ID_CATEGORIA"  v-bind:value="categoria.ID_CATEGORIA" > &nbsp; {{categoria.DESCRIZIONE}}</option>
		                                    </select>
			                            </div> 
				                  		<div class="col-5 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" :tabindex="1" v-model="search.DESCRIZIONE.VALUE" />
										</div>                
			                            <div class="col-12 col-md-5 col-lg-5 mbx-1">
		
										</div>
										<div class="col-12 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="search.FLG_ATTIVO.VALUE">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivati</option>
												<option value="0"> &nbsp; Disattivati</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
			                                    + FILTRI
											</a>
										</div>
									</div>
								</form>
							</div>
						</div> 
			        </div>
						
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllContenuti" v-on:click="filters.selectAll('chkAllContenuti',search.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm">Nome Contenuto /<br />Sito / SEO</th>
										<th class="table-head-sm" style="width:15%;">Categoria /<br />Modello</th>
										<th class="table-head-sm" style="width:20%;">Visibile in</th>
										<th class="table-head-sm" style="width:5%;">Hits</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
									<tr v-for="contenuto in paginatedList.arr" v-bind:key="contenuto.ID_CONTENUTO">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="contenuto.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center">
											<img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
										<td class="td-class fs-sm">
											<span>{{contenuto.ID_CONTENUTO}}</span><br /> 
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer">{{ contenuto.ORDINE}}</small>
										</td>
										<td class="td-class text-center">
											<img v-if="contenuto.ID_CONTENUTO > 0 && contenuto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('CONTENUTO',contenuto.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="contenuto.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="width: 40px; height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneContenuto(contenuto)">{{contenuto.TITOLO}}</span><br />	                                   
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="Ente"><i class="fal fa-tag fa-fw"></i> {{contenuto.FK_ID_ENTE}}</span> 
											<span v-on:click="updateContenutoFlgInHome(contenuto)" v-show="contenuto.FLG_INHOME == 1" class="badge bg-info badge-xs text-white cursor-pointer"><i class="fal fa-home fa-fw"></i> In Home</span>
											<span v-on:click="updateContenutoFlgInHome(contenuto)" v-show="contenuto.FLG_INHOME == 0" class="badge bg-light badge-xs text-dark cursor-pointer" style="border: 1px #dddddd solid;"><i class="fal fa-thumbs-up fa-fw"></i> Metti in Home</span>
										</td>
										<td class="td-class fs-sm">
                                            <span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;">{{contenuto.CATEGORIA}}</span><br />
                                            <span class="text-muted">{{contenuto.MODEL}}</span>
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Ultimo accesso: <a href="#"></a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Livello: <a href="#">Admin</a></div>
										</td>
		                                <td class="td-class fs-sm text-center"><span class="badge badge-flat badge-pill border-secondary text-secondary">{{contenuto.COUNTER}}</span></td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateContenutoFlgVisibile(contenuto)" v-show="contenuto.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateContenutoFlgVisibile(contenuto)" v-show="contenuto.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>		                                
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoContenuto(contenuto)" :size="'sm'" v-model="contenuto.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaContenuto(contenuto)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Contenuto</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteContenuto(contenuto)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Contenuto</a>
												</div>
											</div>
										</td>
									</tr>
								</draggable>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">CATEGORIE: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

		
	<!-- START MODALI -->
    <div class="modal fade" id="popUpcontenuto" role="dialog" aria-modal="true">
		<div class="modal-dialog modal-lg"  >
			<div class="modal-content" >
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
					<h6 class="modal-title">{{contenutoSelezionato.ID_CONTENUTO == 0 ? 'Nuovo' : 'Modifica'}} contenuto</h6>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">  

					<div class="row" style="margin-top:10px">
						<div class="col-md-12 form-group">
							<label>Username</label>
							<input v-model="contenutoSelezionato.DENOMINAZIONE" class="input-sm form-control input-sm" placeholder="Username" type="text">
						</div>
						<div class="col-md-6 form-group" v-show="contenutoSelezionato.ID_CONTENUTO == 0">
							<label>Password</label>
							<input v-model="contenutoSelezionato.PASSWORD" class="input-sm form-control input-sm" placeholder="Password" type="password">
						</div>
						<div class="col-md-6 form-group" v-show="contenutoSelezionato.ID_CONTENUTO == 0">
							<label>Conferma password</label>
							<input v-model="contenutoSelezionato.CONFERMA_PASSWORD" class="input-sm form-control input-sm" placeholder="Password" type="password">
						</div>
						<div class="col-md-6 form-group">
							<label>Email</label>
							<input v-model="contenutoSelezionato.EMAIL" class="input-sm form-control input-sm" placeholder="Email" type="text">

						</div>
						<div class="col-md-6 form-group" v-show="contenutoSelezionato.ID_CONTENUTO == 0">
							<label>Conferma email</label>
							<input v-model="contenutoSelezionato.CONFERMA_EMAIL" class="input-sm form-control input-sm" placeholder="Conferma Email" type="text">
						</div>
						<div class="col-md-6 form-group" v-show="contenutoSelezionato.ID_CONTENUTO == 0">
							<label>Nome</label>
							<input v-model="contenutoSelezionato.NOME" class="input-sm form-control input-sm" placeholder="Nome" type="text">
						</div>
						<div class="col-md-6 form-group" v-show="contenutoSelezionato.ID_CONTENUTO == 0">
							<label>Cognome</label>
							<input v-model="contenutoSelezionato.COGNOME" class="input-sm form-control input-sm" placeholder="Cognome" type="text">
						</div>
					</div>                       

				</div>
				<div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="salvaContenuto()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
				</div>
			</div>
		</div>
	</div>
		    
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" aria-hidden="true" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" :tabindex="2">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneContenuto @modificaContenuto="openSchedaContenuto($event)" :ref="'schedaVisualizzazioneContenuto'"></SchedaVisualizzazioneContenuto>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>

	<SchedaContenuto :ref="'schedaContenuto'" @updateGriglia="getContenuti()" :modulo="modulo" :modalita="1"></SchedaContenuto>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import SchedaContenuto from '@/components/SchedaContenuto.vue'	  
import { VueDraggableNext } from 'vue-draggable-next'  
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import SchedaVisualizzazioneContenuto from '@/components/SchedaVisualizzazioneContenuto.vue'


export default {
    name:"Contenuti",	  
	props:['modulo'],

    components:{
        Pagine,
        ISwitch,
		SchedaContenuto,
        draggable: VueDraggableNext,
		SelectLingue,
		SchedaVisualizzazioneContenuto

    },

    data: function (){
      	return{
            apiStartup:'travel/contenuti',
	        filters:global.filters,	
            GLOBAL_IMAGES_PATH:'',
	        arrayContenuti:new Array(),
            contenutoSelezionato:{},
            contenutoCorrente:{},
			contenutoLangSelezionato:{},
            arrayCampiPopUpcontenuto : new Array(),
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_CONTENUTO:{TYPE:'TEXT',VALUE:''},	
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},	
	            STATO:{TYPE:'COMBO',VALUE:'1'}, 
				FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				FK_ID_MODULO:{TYPE:'COMBO',VALUE:''}, 
				FK_ID_CATEGORIA:{TYPE:'COMBO_IN_ARRAY',VALUE:'',ARRAY:"CATEGORIE"}
	        },
			rs : utils.generateRandomString(30),
            arrayCategorie : new Array(),
			ordineHasChanged: false,
			selectedMenuItem:{},
			currentLang:0,
      	}
		
    },

    methods: {
        getContenuti: function () {
            utils.ajax(this.apiStartup,{ID_MODULO:this.modulo}, (response) => {
				this.ordineHasChanged = false;
				this.arrayContenuti = response.data; 
				for (let i = 0 ; i < this.arrayContenuti.length ; i++){
                    this.arrayContenuti[i].CATEGORIE = new Array();
					this.arrayContenuti[i].FK_ID_CATEGORIA = 0;
					var arrayCategorie = this.arrayContenuti[i].CATEGORIA.split(",");
					var arrayId = this.arrayContenuti[i].ID_CATEGORIE.split(",");
					var arrayFinale = new Array();
					for (var j = 0 ; j < arrayCategorie.length ; j++){
						arrayFinale.push({FK_ID_CATEGORIA:arrayId[j],DESCRIZIONE:arrayCategorie[j]});
					}
					this.arrayContenuti[i].CATEGORIE = arrayFinale;
                }				
			});
        },	 

        nuovoContenuto : function(){ 
            this.$refs.schedaContenuto.startup({ID_CONTENUTO:0,FK_ID_MODULO:this.modulo,FLG_ATTIVO:1,lang:{}});
        }, 

        modificaContenuto : function(contenuto){
            this.contenutoSelezionato = utils.clone(contenuto);
            this.$refs.schedaContenuto.startup(contenuto);
        }, 

		salvaContenuto:function(){
            utils.salvaElemento("travel/contenuti/",this.contenutoSelezionato,"ID_CONTENUTO","popUpContenuto",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getContenuti();
                }
            });
        },

        updateStatoContenuto : function(contenuto){
            setTimeout(() => {
                utils.ajax('travel/contenuti/stato/update',contenuto, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.filters.closePopUp("popUpContenuto");
                    }  
                }); 
            },250);
        },
        
        deleteContenuto : function(t){
            utils.alert.confirm("Sicuro di voler eliminare questo Contenuto " + t.NOME + "?",()=>{
                utils.ajax('travel/contenuti/delete',{className:'Contenuto',id:t.ID_CONTENUTO}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getContenuti();
                    }
                });
            });
        }, 
	
		updateContenutoFlgVisibile : function(contenuto){
			contenuto.FLG_VISIBILE = contenuto.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/contenuto/update",contenuto, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					contenuto.FLG_VISIBILE = contenuto.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
        }, 
	
		updateContenutoFlgInHome : function(contenuto){
			contenuto.FLG_INHOME = contenuto.FLG_INHOME == 1 ? 0 : 1;
			utils.ajax("travel/contenuti/update",contenuto, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					contenuto.FLG_INHOME = contenuto.FLG_INHOME == 1 ? 0 : 1;
				}
			});                
        },	
        
        openSchedaContenuto: function(contenuto){
            this.$refs.schedacontenuto.openSchedaContenuto(contenuto);
        }, 

        esportazione : function(formato){
            utils.report.token({modalita:'esportaContenuti',formato:formato});
        }, 

		ordineChangeHandler : function(event){
			this.arrayContenuti = this.filters.draggableChangeHandler(event,this.arrayContenuti,this.search,"ID_CONTENUTO");
			this.ordineHasChanged = true;		
		},		

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayContenuti.length ; i++){
					arr.push({"ID": this.arrayContenuti[i].ID_CONTENUTO,"O": this.arrayContenuti[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Contenuto',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getContenuti();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneContenuto : function(contenuto){
			this.contenutoSelezionato = utils.clone(contenuto);	
			this.$refs.schedaVisualizzazioneContenuto.openSchedaVisualizzazioneContenuto(contenuto,2);
		},

    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayContenuti,this.search);
            return arr;
        },
/*
		getTotali(){
            var filtrato = global.utils.filter(this.arrayUtenti,this.search);
            var totale = 0;
            var fatturato = 0;
            for (var i = 0 ; i < filtrato.length ; i++){
                totale += parseFloat(filtrato[i]);
                fatturato += parseFloat(filtrato[i]);
            }
            return {
                LORDO:lordo,
                COMM:comm,
                NETTO:lordo-comm,
                COUNT:filtrato.length
            }
        }
*/

        moduleName(){
           return this.filters.moduleName(this.modulo);
        }
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.arrayEntiSelect2 = new Array();
        for (var i = 0 ; i < this.$root.arrayEnti.length ; i++){
            this.arrayEntiSelect2.push({id: this.$root.arrayEnti[i].ID_ENTE,text: this.$root.arrayEnti[i].LABEL,})
        }
        this.contenutoCorrente = utils.clone(this.$root.contenuto);
		this.currentLang = this.$root.currentLang;
		utils.ajax('travel/categorie/modulo',{FK_ID_MODULO:this.modulo}, (response) => {
			this.arrayCategorie = response.data;
			this.getContenuti();
		});        
    }

}
</script>