<template>

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">

		<div class="row">
			<div class="col-sm-6 col-xl-2">
				<div class="card card-body mb-2">
					<div class="d-flex align-items-center">
						<i class="ph-house-line ph-2x text-primary me-3"></i>
						<div class="flex-fill text-end">
							<h4 class="mb-0">{{ getTotali.COUNT }}</h4>
							<span class="text-muted">Totale {{tipoName}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-2">
				<div class="card card-body mb-2">
					<div class="d-flex align-items-center">
						<i class="ph-thumbs-up ph-2x text-success me-3"></i>

						<div class="flex-fill text-end">
							<h4 class="mb-0">{{ stats.TOT_ENTITA_CON_ORDINI }}</h4>
							<span class="text-muted">Utenti attivi</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-2">
				<div class="card card-body mb-2">
					<div class="d-flex align-items-center">
						<i class="ph-thumbs-down ph-2x text-secondary me-3"></i>
						<div class="flex-fill text-end">
							<h4 class="mb-0">{{  getTotali.COUNT - stats.TOT_ENTITA_CON_ORDINI }}</h4>
							<span class="text-muted">Utenti 0 pratiche</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-2">
				<div class="card card-body mb-2">
					<div class="d-flex align-items-center">
						<div class="flex-fill">
							<h4 class="mb-0"></h4>
							<span class="text-muted">Utenti bannati</span>
						</div>
						<i class="ph-house-simple ph-2x text-danger ms-3"></i>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-2">
				<div class="card card-body mb-2">
					<div class="d-flex align-items-center">
						<div class="flex-fill">
							<h4 class="mb-0">2365</h4>
							<span class="text-muted">Utenti </span>
						</div>
						<i class="ph-sketch-logo ph-2x text-indigo ms-3"></i>
					</div>
				</div>
			</div>
			<div class="col-sm-6 col-xl-2">
				<div class="card card-body mb-2">
					<div class="d-flex align-items-center">
						<div class="flex-fill">
							<h4 class="mb-0">389,438</h4>
							<span class="text-muted">Utenti </span>
						</div>
						<i class="ph-globe-hemisphere-east ph-2x text-purple ms-3"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="card shadow">
			<div class="card-body mt-0 mb-0">
				<nav class="tabbable">
					<ul class="nav nav-tabs nav-tabs-underline">
						<li class="nav-item" role="presentation">
							<a href="#statics-1" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab" v-on:click="getStatisticheOrdiniChart()">VENDITE</a>
						</li>
						<li class="nav-item" role="presentation">
							<a href="#statics-2" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="getStatisticheOrdiniFatt()">FATT. VENDUTO</a>
						</li>
						<li class="nav-item" role="presentation">
							<a href="#statics-3" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">FATT: PARTITO</a>
						</li>
						<li class="nav-item" role="presentation">
							<a href="#statics-4" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">GEO</a>
						</li>
					</ul>
				</nav>
				<div class="tab-content">

					<div class="tab-pane fade mt-1 active show" id="statics-1" role="tabpanel" style="padding: 0px;">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							
							<div class="row">
								<div class="col-12 col-md-2 mbx-1">
									<Select2 :api="'core/geo/regioni/select2'" :placeholder="'Seleziona Regione'" v-model="searchStatisticheOrdini.FK_ID_REGIONE.VALUE" :tabindex="6"></Select2>
								</div>
								<div class="col-12 col-md-2 mbx-1">
									<Select2 :api="'core/geo/province/select2'" :placeholder="'Seleziona Provincia'" v-model="searchStatisticheOrdini.FK_ID_PROVINCIA.VALUE" :tabindex="7"></Select2>
								</div>
								<div class="col-12 col-md-2 mbx-1">
									<Select2 :w="250" :api="'core/geo/comuni/select2'" placeholder="Seleziona Citt&agrave;" v-model="searchStatisticheOrdini.FK_ID_COMUNE.VALUE" :tabindex="19"></Select2>
								</div> 
								<div class="col-12 col-md-5">
									<div class="row">
										<div class="col-12 col-md-4 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FLG_GRUPPI.VALUE" :tabindex="13">
												<option value="">Tutte i Promotori </option>   
												<option v-for="opzione in $root.arrayPromotori" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-4 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FK_ID_NETWORK.VALUE" :tabindex="13">
												<option value="">Tutte i Network </option>   
												<option v-for="opzione in $root.arrayNetwork" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-4 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FLG_GRUPPI.VALUE" :tabindex="13">
												<option value="">Tutte le tipologie </option>   
												<option v-for="opzione in $root.arrayPraticaTipo" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-1 mbx-1">
									<button class="btn btn-flat-info btn-sm float-end" v-on:click="getStatisticheOrdiniChart()">
										AGGIORNA
									</button>
								</div>
							</div>
							
						</div>
						<div class="box-advstats mt-2">
							<div class="grafico" id="chart1">
			
							</div>
						</div>
					</div>
	
					<div class="tab-pane fade mt-1" id="statics-2" role="tabpanel">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							
							<div class="row">
								<div class="col-12 col-md-2 mbx-1">
									<Datepicker autocomplete="off" class="bg-light" style="width:253px" v-model="dataCreazioneRangeFatt" @update:model-value="dataCreazioneRangeChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" placeholder="Seleziona Range Date" />
								</div>
								<div class="col-12 col-md-9 mbx-1">

									<div class="row">
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :api="'core/geo/regioni/select2'" :placeholder="'Seleziona Regione'" v-model="searchStatisticheOrdini.FK_ID_REGIONE.VALUE" :tabindex="6"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :api="'core/geo/province/select2'" :placeholder="'Seleziona Provincia'" v-model="searchStatisticheOrdini.FK_ID_PROVINCIA.VALUE" :tabindex="7"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :w="250" :api="'core/geo/comuni/select2'" placeholder="Seleziona Citt&agrave;" v-model="searchStatisticheOrdini.FK_ID_COMUNE.VALUE" :tabindex="19"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FLG_GRUPPI.VALUE" :tabindex="13">
												<option value="">Tutte i Promotori </option>   
												<option v-for="opzione in $root.arrayPromotori" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FK_ID_NETWORK.VALUE" :tabindex="13">
												<option value="">Tutte i Network </option>   
												<option v-for="opzione in $root.arrayNetwork" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FLG_GRUPPI.VALUE" :tabindex="13">
												<option value="">Tutte le tipologie </option>   
												<option v-for="opzione in $root.arrayPraticaTipo" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
									</div>	

								</div>
								<div class="col-12 col-md-1 mbx-1">
									<button class="btn btn-flat-info btn-sm" v-on:click="getStatisticheOrdiniFatt()">
										+ AGGIORNA
									</button>
								</div>
							</div>

						</div>
						<div class="box-27 mt-1">
							
							<div class="box-23">
								<table class="table table-bordered table-hover table-striped table-togglable table-cms"> 
									<thead class="sticky-top">
										<tr class="bg-secondary text-white">
											<th class="table-head-sm" style="width:6%;"> # </th>
											<th class="table-head-sm" style="">Nome {{tipoName}}</th>
											<th v-if="modalita == 2" class="table-head-sm" style="width:13%;"><span>Promotore</span></th>
											<th v-if="modalita == 2" class="table-head-sm" style="width:10%;"><span>Gruppo</span></th>
											<th v-if="modalita == 2" class="table-head-sm" style="width:13%;"><span>Network</span></th>
											<th class="table-head-sm" style="width:13%;">Citt&agrave; / Regione</th>
											<th class="table-head-sm" style="width:17%;">Fatturato</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="entita in paginatedListFatt.arr" v-bind:key="entita.ID_PERSONA_GIURIDICA">
											<td class="td-class fs-sm">
												<span>{{entita.ID_AZIENDA}}</span>
											</td>
											<td class="td-class fs-sm">
												<span v-show="modalita == 1" class="text-primary text-uppercase fw-bold cursor-pointer">{{entita.USERNAME}}</span>
												<span v-show="modalita == 2 || modalita == 3" class="text-primary text-uppercase fw-bold cursor-pointer">{{entita.ALIAS}}</span>
											</td>
												<td v-show="modalita == 2" class="td-class fs-sm">
												<span class="text-dark">{{entita.PROMOTORI}}</span>
											</td>
												<td v-show="modalita == 2" class="td-class fs-sm">
												<span class="text-muted">Gruppo</span>
											</td>
												<td v-show="modalita == 2" class="td-class fs-sm">
												<span class="text-muted">Network</span>
											</td>
												
											<td class="td-class fs-sm">	
												<span class="text-muted me-1" v-show="entita.FK_ID_NAZIONALITA == 118">{{ entita.GEO_REGIONE }} - {{ entita.GEO_COMUNE }}</span>
												<span class="text-muted me-1" v-show="entita.FK_ID_NAZIONALITA != 118">{{ entita.COMUNE }} - {{ entita.GEO_STATO }} </span>
											</td>
											<td class="td-class fs-sm">
												<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Fatturato: <a href="#">{{ filters.formattaImporto(entita.IMPORTO) }} </a></div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="row page-bottom">
								<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
									<div style="position: relative; top: 10px;">
										<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
										<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">AGENZIE: </span> 
										<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: </span>
									</div>
								</div>
								<div class="col-9 col-md-6" :tabindex="27">
									<div class="float-end">
										<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListFatt.count" :numpagine="searchStatisticheOrdiniFatt.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
									</div>
								</div>
								<div class="col-3 col-md-1" :tabindex="28">
									<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchStatisticheOrdiniFatt.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="75">75</option>
										<option value="100">100</option>
										<option value="250">250</option>
										<option value="500">500</option>
									</select>
								</div> 
							</div>	
						</div>
					</div>

					<div class="tab-pane fade mt-1" id="statics-3" role="tabpanel">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							
							<div class="row">
								<div class="col-12 col-md-2 mbx-1">
									<Datepicker autocomplete="off" class="bg-light" style="width:253px" v-model="dataCreazioneRangeFatt" @update:model-value="dataCreazioneRangeChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" placeholder="Seleziona Range Date" />
								</div>
								<div class="col-12 col-md-9 mbx-1">

									<div class="row">
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :api="'core/geo/regioni/select2'" :placeholder="'Seleziona Regione'" v-model="searchStatisticheOrdini.FK_ID_REGIONE.VALUE" :tabindex="6"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :api="'core/geo/province/select2'" :placeholder="'Seleziona Provincia'" v-model="searchStatisticheOrdini.FK_ID_PROVINCIA.VALUE" :tabindex="7"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :w="250" :api="'core/geo/comuni/select2'" placeholder="Seleziona Citt&agrave;" v-model="searchStatisticheOrdini.FK_ID_COMUNE.VALUE" :tabindex="19"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FLG_GRUPPI.VALUE" :tabindex="13">
												<option value="">Tutte i Promotori </option>   
												<option v-for="opzione in $root.arrayPromotori" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FK_ID_NETWORK.VALUE" :tabindex="13">
												<option value="">Tutte i Network </option>   
												<option v-for="opzione in $root.arrayNetwork" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<select class="form-select form-select-sm" v-model="searchStatisticheOrdini.FLG_GRUPPI.VALUE" :tabindex="13">
												<option value="">Tutte le tipologie </option>   
												<option v-for="opzione in $root.arrayPraticaTipo" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
									</div>	

								</div>
								<div class="col-12 col-md-1 mbx-1">
									<button class="btn btn-flat-info btn-sm" v-on:click="getStatisticheOrdiniFatt()">
										+ AGGIORNA
									</button>
								</div>
							</div>
							
						</div>
						<div class="box-27 mt-1">
							
							<div class="box-23">
								<table class="table table-bordered table-hover table-striped table-togglable table-cms"> 
									<thead class="sticky-top">
										<tr class="bg-secondary text-white">
											<th class="table-head-sm" style="width:6%;"> # </th>
											<th class="table-head-sm" style="">Nome {{tipoName}}</th>
											<th v-if="modalita == 2" class="table-head-sm" style="width:13%;"><span>Promotore</span></th>
											<th v-if="modalita == 2" class="table-head-sm" style="width:10%;"><span>Gruppo</span></th>
											<th v-if="modalita == 2" class="table-head-sm" style="width:13%;"><span>Network</span></th>
											<th class="table-head-sm" style="width:13%;">Citt&agrave; / Regione</th>
											<th class="table-head-sm" style="width:17%;">Fatturato</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="entita in paginatedListFatt.arr" v-bind:key="entita.ID_PERSONA_GIURIDICA">
											<td class="td-class fs-sm">
												<span>{{entita.ID_AZIENDA}}</span>
											</td>
											<td class="td-class fs-sm">
												<span class="text-primary text-uppercase fw-bold cursor-pointer">{{entita.ALIAS}}</span>
											</td>
											<td v-show="modalita == 2" class="td-class fs-sm">
												<span class="text-dark">{{entita.PROMOTORI}}</span>
											</td>
											<td v-show="modalita == 2" class="td-class fs-sm">
												<span class="text-muted">Gruppo</span>
											</td>
											<td v-show="modalita == 2" class="td-class fs-sm">
												<span class="text-muted">Network</span>
											</td>
												
											<td class="td-class fs-sm">	
												<span class="text-muted me-1" v-show="entita.FK_ID_NAZIONALITA == 118">{{ entita.GEO_REGIONE }} - {{ entita.GEO_COMUNE }}</span>
												<span class="text-muted me-1" v-show="entita.FK_ID_NAZIONALITA != 118">{{ entita.COMUNE }} - {{ entita.GEO_STATO }} </span>
											</td>
											<td class="td-class fs-sm">
												<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Fatturato: <a href="#">{{ filters.formattaImporto(entita.IMPORTO) }} </a></div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="row page-bottom">
								<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
									<div style="position: relative; top: 10px;">
										<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
										<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">AGENZIE: </span> 
										<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: </span>
									</div>
								</div>
								<div class="col-9 col-md-6" :tabindex="27">
									<div class="float-end">
										<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListFatt.count" :numpagine="searchStatisticheOrdiniFatt.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
									</div>
								</div>
								<div class="col-3 col-md-1" :tabindex="28">
									<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchStatisticheOrdiniFatt.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
										<option value="5">5</option>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="75">75</option>
										<option value="100">100</option>
										<option value="250">250</option>
										<option value="500">500</option>
									</select>
								</div> 
							</div>	
						</div>
					</div>

					<div class="tab-pane fade mt-1" id="statics-4" role="tabpanel">





					</div>
						
				</div>
			</div>
		</div>

	</div>	


	<!-- START MODALI -->    


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
//import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker' 

export default {
    name:"Statistiche",

    components:{
        Pagine,
        //ISwitch,
        Select2,
		Datepicker,
    },	 

    props:['livello','modalita'],	

    data: function () {
        return {   
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            livelloMaxFiltroSocieta: 2,
            arrayUtenti: new Array(),
            utenteSelezionato:{personaFisica:{}},
            utenteCorrente:{},
			modulo:0,
			selectedMenuItem:{},
			dataCreazioneRange:{},
			dataCreazioneRangeFatt:{},
			azioneSelezionata : {},
			arrayCampiPopUpUtente : new Array(),
			arrayAzioniUtente : new Array(),
			arrayOpzioniAll : new Array(),
			arrayPosizioni: new Array(),
			arrayTipologie : new Array(),
			arrayRuoli : new Array(),
			stats:{},
			
			search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},	
				ANNO_ORDINE:{TYPE:'COMBO',VALUE:''},	
			
            },
			searchStatisticheOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},	
				FK_ID_NETWORK:{TYPE:'COMBO',VALUE:''},	
				FK_ID_PROMOTORI:{TYPE:'COMBO',VALUE:''},	
				FLG_GRUPPI:{TYPE:'COMBO',VALUE:''},			
            },
			arrayStatisticheOrdiniFatt : new Array(),
			searchStatisticheOrdiniFatt:{
				PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},	
			}
			
        }
    },

    methods: {

		datePickerChangeHandler : function(){
			this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
				this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
			}
		},

		dataCreazioneRangeChangeHandler : function(){
            this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
                this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
            }
        },

		getTipologia: function(utente){
			if(this.ID_UTENTE = 0){
				return 'Privato';
			} 
			else {
				return 'Aziendale';
			} 
		},

		getStileColore : function(utente){
			return {color:utente.COLORE};
		},	

		checkShowRuolo : function(ruolo){			
			if (this.livello != 5){
				if (ruolo.CUSTOM_1 == "" || ruolo.CUSTOM_1 == this.livello){
					return true;
				}
			}
			else{
				if (ruolo.CUSTOM_1 == this.livello){
					return true;
				}
			}
			return false;
		}, 

		getStatisticheOrdiniFatt(){
			var api = "";
			switch (this.modalita){
				case 1:
					api = "travel/statistiche/ordini/utenti";
					break;
				case 2:
					api = "travel/statistiche/ordini/agenzie";
					break;
				case 3:
					api = "travel/statistiche/ordini/fornitori";
					break;
				
			}
			utils.ajax(api,this.searchStatisticheOrdiniFatt, (response) => {
				this.arrayStatisticheOrdiniFatt = response.data;
			});			
		},
		
        getStatisticheOrdiniChart : function(){
            //$('.grafico').removeAttr("_echarts_instance_");
            var arr = document.getElementsByClassName("grafico");//.removeAttribute("disabled");
            for (var i = 0; i < arr.length; i++){
                arr[i].removeAttribute("_echarts_instance_");
                arr[i].innerHTML = "";
            }
			
			var params = utils.clone(this.searchStatisticheOrdini);
			//IMPOSTARE UNA CASISTICA PER CUI VENGONO RIMOSSE ALCUNE PROPRIETA' IN BASE ALLA MODALITA DI ESECUZIONE
			//UTENTI
			if (this.modalita == 1){
				delete params.FK_ID_AZIENDA;
			}
			//AGENZIE
			else if (this.modalita == 2){
				delete params.FK_ID_UTENTE;
			}
			//FORNITORI
			else if (this.modalita == 3){
				delete params.FK_ID_UTENTE;
			}
            utils.ajax('travel/statistiche/ordini/importo', params , (response) => {
                var option= {};
                //var chart1 = echarts.init(document.getElementById('chart1'));
                let chart1 = echarts.getInstanceByDom (document.getElementById ('chart1')); // get some words DOM node has echarts instance.
                if (chart1 == null) {// If not, it is initialized.
                    chart1 = echarts.init(document.getElementById('chart1'));
                }

                var arrayDistinctMesi = utils.array.distinct(response.data,"MESE","MESE");
                var arrayDistinctAnni = utils.array.distinct(response.data,"ANNO","ANNO");
                var series = new Array();
                var arrayAnni = [];
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    arrayAnni.push(arrayDistinctAnni[i].ANNO.toString());
                }
                var arrayMesi = [];
                for (var i = 0 ; i < arrayDistinctMesi.length ; i++){
                    arrayMesi.push(utils.array.select(utils.arrayMesi,"ID_MESE",arrayDistinctMesi[i].MESE,1)[0].DESCRIZIONE);
                }
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    var serie = {
                        name: arrayDistinctAnni[i].ANNO.toString(),
                        type: 'bar',
                        barGap: 0.2,
                        data: []
                    };
                    for (var j = 0 ; j < arrayDistinctMesi.length ; j++){
                        var trovato = 0;
                        for (var k = 0 ; k < response.data.length ; k++ ){
                            if (response.data[k].MESE == arrayDistinctMesi[j].MESE && response.data[k].ANNO == arrayDistinctAnni[i].ANNO){
                            trovato = 1;
                            serie.data.push(parseFloat(response.data[k].IMPORTO));
                            break;
                            }
                        }
                        if (trovato == 0){
                            serie.data.push(0);
                        }
                    }
                    series.push(serie);
                }
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter : (params) => {
                            return "<strong>" + params.name + " " +  params.seriesName + "</strong><br/>€ "+ utils.formatNumber(params.data) ;
                        }
                    },
                    legend: {
                        data: arrayAnni
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {show: true},
                            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: arrayMesi
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel : {
                            formatter : (param) => {
                                return utils.formatNumberInt(param);//"<strong>" + params.name + " " +  params.seriesName + "</strong><br/>"+ params.data ;
                            }
                            },
                        }
                    ],
                    series: series
                };    
                chart1.setOption(option);
                         
            });
        },

		statisticheStartup : function(){
			utils.ajax('travel/statistiche/utenti/startup',{}, (response) => {
				this.stats = response.data;
				this.getStatisticheOrdiniChart();
			});
		},
        
    },	

    computed: {
		paginatedListFatt(){
            var arr = utils.inGrid(this.arrayStatisticheOrdiniFatt,this.searchStatisticheOrdiniFatt);
            return arr;
        },
		
        levelName(){
            switch (this.livello){
                case 1:		return 'Autori';
                case 2:		return 'Amministratori';
                case 3:		return 'Fornitori';
                case 4:		return 'Agenzie';
                default:	return 'Clienti';
            }
			return "";
        },	  

        iconLevel(){
            switch (this.livello){
                case 1:		return 'fa-key';
                case 2:		return 'fa-user';
                case 4:		return 'fa-plane';
                default:	return 'fal fa-box';
            }
			return "";
        }, 

		getTotali(){
			var attive = 0;
            var inattive = 0; 
			for (var i = 0; i < this.arrayUtenti.length; i++) {
				if (this.arrayUtenti[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
            return {
                COUNT:this.arrayUtenti.length,
				ADVOK:attive,
				ADVKO:inattive
            }
        },

		annoCorrente : function(){
			return new Date().getFullYear();
		},

		annoPrecedente : function(){
			return new Date().getFullYear() - 1;
		}

    },

    created: function () {
		return;
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.utenteCorrente = utils.clone(this.$root.utente);
		utils.ajax("travel/utenti/startup",{ID_LIVELLO:this.livello}, (response) => {
			this.arrayTipologie = utils.array.select(response.data,"FK_ID_TIPOLOGICA",this.$root.COSTANTI.TIPOLOGICA_CLIENT_TIPOLOGIE);
			this.arrayPosizioni = utils.array.select(response.data,"FK_ID_TIPOLOGICA",this.$root.COSTANTI.TIPOLOGICA_CLIENT_POSIZIONI);
			this.getUtenti();
		}); 
		switch (this.livello){
			case 2:
				this.modulo = 21;
				break;
			case 5:
				this.modulo = 22;
				break;
		}		        
        
    },

}
</script>

<style scoped>
	.grafico { min-height: 451px; }
</style>