<template>
    <div class="form-switch" @click="toggle($event)">
        <input class="form-check-input cursor-pointer" type="checkbox" role="switch" :checked="modelValue == 1 ? true : false" >
    </div>
</template>



<script>

export default {
    name: 'ISwitch',
    emits: ['update:modelValue',"toggle"],
    props: {
        modelValue: {
            type: Number
        },
        color: {
            type: String,
            required: false,
            default: "#28a745"
        },
        size: {
            type: String,
            required: false,
            default: "normal"
        },
    },
    methods: {
        toggle: function(event) {
            this.$emit("toggle", this.modelValue == 1 ? 0 : 1);
            this.$emit('update:modelValue', this.modelValue == 1 ? 0 : 1);
            this.$forceUpdate();
        },
    },
    computed:{
    
    }
}
</script>