<template>

    <img :src="src">

</template>



<script>


export default {
    name:"Img",
    props: {
        modelValue: String,
        folder: String,
        chiave: String
    },
    data: function () {
        return {   
            filters:global.filters,
            src:"",
        }
    },
    methods:{
        checkImage:function (imageSrc, good, bad) {
            /*
            console.log('checkImage');
            var img = new Image();
            img.onload = good; 
            img.onerror = bad;
            img.src = imageSrc;*/
            utils.download("/travel/img",{CHIAVE:this.chiave,FOLDER:this.FOLDER},(response) => {
                //console.log(response.length);
                //'data:image/jpeg;base64,' + btoa('your-binary-data');
                //console.log(response);
                this.src = 'data:image/jpeg;base64,'+response;
            },false);

            

        },

        hexToBase64(str) {
            return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
        },

        refresh : function(){
            var url = this.filters.getImgPath(this.folder,this.chiave);
            this.checkImage();
            /*
            url,()=>{
                this.src = url;
            },()=>{
                this.src = "https://www.daviderattacaso.com/wp-content/uploads/2019/02/google-1762248_1280.png"
            });*/
        }
    },  

    created : function(){
        this.refresh();
    },

    onUpdated:function()  {    
        this.refresh();
    },
    
    watch:{
        chiave:function(){
            this.refresh();
        },
        folder:function(){
            this.refresh();
        },
    }
};
</script>
