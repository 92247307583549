<template>
        
	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="text-dark" v-on:click="openRoute('/Ordini')"> Pratiche </a> \
					<span class="text-muted">Disponibilit&agrave; prodotto</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
			  
	</div>
	<!-- /page header -->

	<!-- Content area -->
	<div class="content content-1">
		<div class="row">
			<div class="col-md-2">

				<Ricerca :modalita="1" @onresult="ricercaResultHandler($event)" @changeModulo="changeModuloHandler($event)" :ref="'ricerca'" class="mb-3"></Ricerca>

				<div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none order-1 order-lg-2 mb-3" style="width: 15.3rem;">
					<div class="card">
						<div class="sidebar-section-header bg-light border-bottom">
							<h3 class="float-start mb-0">Filtri per la ricerca</h3>
						</div>

						<div class="sidebar-section-body">
							<form action="#">
								<div class="mb-3">
									<div class="fw-semibold mb-3">Stato pratica:</div>
									<label class="form-check mb-2">
										<input type="radio" class="form-check-input" name="disponibilita" v-on:click="search.STATO_DISPONIBILITA.VALUE = 0" checked>
										<span class="form-check-label">Tutti</span>
									</label>

									<label class="form-check mb-2">
										<input type="radio" class="form-check-input" name="disponibilita" v-on:click="search.STATO_DISPONIBILITA.VALUE = 1">
										<span class="form-check-label">Disponibile</span>
									</label>

									<label class="form-check mb-2">
										<input type="radio" class="form-check-input" name="disponibilita" v-on:click="search.STATO_DISPONIBILITA.VALUE = 2">
										<span class="form-check-label">In Richiesta</span>
									</label>
								</div>
							</form>
						</div>
					</div>  

				</div>

			</div>
			<div class="col-md-7">
				
				<!-- INIZIO RISULTATO RICERCA PRODOTTO -->
				<div class="card mb-2">

					<div class="card-header bg-light pb-1 pt-0">
						<h3 class="float-start mb-0 mt-2">
							{{ currentTitle() }} 
							<span v-show="arrayRisultatiRicerca.length > 0" class="badge bg-primary rounded-pill align-top ms-1">{{arrayRisultatiRicerca.length}}</span>
						</h3>


						<div class="navbar navbar-expand-lg shadow rounded py-1 float-end ordering">
							<div class="container-fluid">
								<div class="text-center d-lg-none">
									<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#navbar-filter">
										<i class="icon-unfold me-2"></i>
										Ordinamento
									</button>
								</div>

								<div class="navbar-collapse collapse order-2 order-lg-1" id="navbar-filter">
									<ul class="navbar-nav flex-wrap mt-2 mt-lg-0">
										<li class="nav-item dropdown">
											<a href="#" class="navbar-nav-link dropdown-toggle rounded" data-bs-toggle="dropdown">
												Prezzo
											</a>
											<div class="dropdown-menu">
												<a href="#" class="dropdown-item" v-on:click="search.ORDINAMENTO.VALUE = 1"><i class="icon-sort-numeric-asc me-1"></i>più economico</a>
												<a href="#" class="dropdown-item" v-on:click="search.ORDINAMENTO.VALUE = 2"><i class="icon-sort-numberic-desc me-1"></i>più caro</a>
											</div>
										</li>
										<li class="nav-item dropdown ms-lg-1">
											<a href="#" class="navbar-nav-link dropdown-toggle rounded" data-bs-toggle="dropdown">
												Nome
											</a>
											<div class="dropdown-menu">
												<a href="#" class="dropdown-item" v-on:click="search.ORDINAMENTO.VALUE = 3"><i class="icon-sort-alpha-asc me-1"></i>crescente</a>
												<a href="#" class="dropdown-item" v-on:click="search.ORDINAMENTO.VALUE = 4"><i class="icon-sort-alpha-desc me-1"></i>decrescente</a>
											</div>
										</li>
									</ul>
								</div>

								<div class="d-flex order-1 order-lg-2 ms-auto">
									<ul class="navbar-nav flex-row">
										<li class="nav-item btn-checkbox-all">
											<input type="radio" class="btn-check" v-model="modalitaVistaRisultati" value="1" autocomplete="off" checked>
											<label class="navbar-nav-link navbar-nav-link-icon active rounded cursor-pointer" for="btn-check-1"><i class="fas fa-th-large"></i></label>
										</li>

										<li class="nav-item btn-checkbox-all ms-1">
											<input type="radio" class="btn-check" v-model="modalitaVistaRisultati" value="2" autocomplete="off" checked>
											<label class="navbar-nav-link navbar-nav-link-icon rounded cursor-pointer" for="btn-check-2"><i class="fas fa-list"></i></label>
										</li>
									</ul>
								</div>
							</div>
						</div>

					</div>
					<div class="card-body" style="padding: 0 0 0 5px;">

						<div class="tableFixHead" style="min-height: 41rem !important;max-height: 41rem !important; overflow-x: hidden; padding: 5px;">
							<div class="row mt-1" v-if="arrayRisultatiRicerca.length !== 0 && currentModulo() != 4">
								<div v-for="risultato in getRisultatiRicerca" v-bind:key="risultato" v-bind:class="{'col-xl-4':modalitaVistaRisultati == 1, 'col-12':modalitaVistaRisultati==2}">
									<CardRisultatoRicerca :preventivo="ordine.isPreventivo == 1 ? 1 : 0" :modalitaVistaRisultati="modalitaVistaRisultati"  :selezionabile="true" :risultato="risultato" @selezionato="quotaSelezionataHandler(risultato)"></CardRisultatoRicerca>
								</div>	
							</div>
							<div class="row mt-1" v-if="arrayRisultatiRicerca.length !== 0 && currentModulo() == 4">
								<h4 v-show="arrayRisultatiRicerca.length > 0" class="col-12">Voli di andata</h4>
								<div v-for="risultato in getRisultatiRicerca" v-bind:key="risultato" class="col-xl-3">
									<CardRisultatoRicerca :preventivo="ordine.isPreventivo == 1 ? 1 : 0" :modalitaVistaRisultati="modalitaVistaRisultati" :risultato="risultato" @selezionato="quotaSelezionataHandler(risultato)"></CardRisultatoRicerca>
								</div>										
							</div> 
							<div class="row mt-1" v-if="arrayRisultatiRicerca.length !== 0 && divVoliRitornoVisibile() && currentModulo() == 4">
								<h4 v-show="arrayRisultatiRicerca.length > 0" class="col-12">Voli di ritorno</h4>
								<div v-for="risultato in getRisultatiRicercaRitorno" v-bind:key="risultato" class="col-xl-3">
									<CardRisultatoRicerca :preventivo="ordine.isPreventivo == 1 ? 1 : 0" :modalitaVistaRisultati="modalitaVistaRisultati" :risultato="risultato" @selezionato="quotaSelezionataHandler(risultato)"></CardRisultatoRicerca>
								</div>
							</div>									
						</div>

					</div>

				</div>			

			</div>
			<div class="col-md-3">

				<div class="card" v-show="ordine.ID_ORDINE > 0">
					<div class="card-header bg-light p-2">
						<div class="d-flex">
							<div class="flex-grow-1">
								
								<h3 class="float-start mb-0">Riepilogo {{ ordine.isPreventivo == 1 ? 'Preventivo' : 'Ordine' }}
									<!-- 
									<div class="btn-group float-end">
										<button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
											<i class="fa fa-bolt fa-fw"></i> AZIONI
										</button>
										<div class="dropdown-menu dropdown-menu-right">
											<a href="#" v-on:click="openOrdine(ordine)" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-save fa-fw"></i> Torna all'ordine </a>
											<a href="#" v-on:click="openPopUpInviaEmailOrdine()" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope fa-fw"></i> Invia email </a> 
											<a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane fa-fw"></i> Invia sms </a> 
										</div>
									</div>
									-->
								</h3>
								
							</div>
						</div>
					</div>
					<div class="card-body card-body-2">
						<div class="table-responsive table-card">
							<table class="table table-borderless align-middle mb-1">
								<tbody>
									<template v-for="gr in getGruppi" v-bind:key="gr">
										<tr v-show="(ordine.isPreventivo == 1 || ordine.FLG_PREVENTIVO == 1) && getCountQuoteGruppo(gr) > 0">
											<td colspan="2"><h5 class="mb-0"><strong>Proposta {{ gr }}</strong></h5></td>
										</tr>
										<tr v-for="q in getQuoteOrdinate"  v-bind:key="q.ID_QUOTA" v-show="gr == q.FK_ID_GRUPPO" style="border-bottom: 1px #ccc solid;padding-left:20px" >
											<td scope="col" style="width: 70px;">
												<div class="avatar-md bg-light rounded p-1 mb-3" style=" vertical-align: top;">
													<img v-if="q.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',q.URL_AVATAR)" class="img-fluid d-block" style="width: 55px;" alt="" />
													<img v-if="q.URL_AVATAR == 'placeholder.jpg'" :src="filters.getImgPath('NOPHOTO/placeholder.jpg')" class="img-fluid card-img" alt="">
												</div>
											</td>
											<td scope="col" colspan="2">
												<p style="margin-bottom: 0.1rem !important;">
													<span class="text-primary fs-12" v-on:click="impostaDatiRicerca(q,$event)" style="cursor:pointer">[{{ q.ID_QUOTA }}] {{ q.FK_ID_MODULO == 2 ? q.DESCRIZIONE_PADRE : q.DESCRIZIONE }}</span>
													<select class="badge bg-light badge-xs border-primary float-end" v-show="(ordine.isPreventivo == 1 || ordine.FLG_PREVENTIVO == 1)" @change="updateGruppoQuota(q)" v-model="q.FK_ID_GRUPPO" style="width: 50px; padding: 0px 0px !important;">
														<option v-for="gruppo in getGruppi" v-bind:value="gruppo" v-bind:key="gruppo" >{{ gruppo }}</option>	
													</select>
													<br/>
													<span class="fs-12" v-show="q.FK_ID_MODULO == 2">{{ q.DESCRIZIONE }}</span>
												</p>
												<!--- <p class="text-muted fs-12 mb-0">Sistemazione: </p>-->
												<p class="text-muted fs-12 mb-0">Occupazione: {{q.TOT_ADU}} Adu - {{q.TOT_CHD}} Chd - {{q.TOT_INF}} Inf</p>
												<p class="text-muted fs-12 mb-0">Subtotale: &euro; {{ filters.formattaImporto(q.PREZZO_TOTALE) }}
													<button class="badge bg-danger border-danger float-end" v-on:click="eliminaQuota(q)" style="padding: 0rem 0.3rem !important;"><i class="fal fa-trash fa-fw"></i></button>
												</p>
											</td>
										</tr>
									</template>
									<tr class="bg-light">
										<td class="text-dark p-2"><strong>TOTALE:</strong></td>
										<td colspan="2" class="float-end p-2">
											<span><strong>&euro; {{ filters.formattaImporto(ordine.PREZZO_TOTALE) }}</strong></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>												

					</div>
					<div class="card-footer text-center">
						<button v-on:click="openOrdine(ordine)" class="btn btn-primary me-3"><i class="fal fa-save fa-fw"></i> Torna all'ordine</button>
						<button v-on:click="openPopUpInviaEmailOrdine()" class="btn btn-primary"><i class="fal fa-envelope fa-fw"></i> Invia Email</button>
					</div>
				</div>  

			</div>
		</div>
		
	</div>    

	<SchedaQuota :idOrdine="ordine.ID_ORDINE" :ref="'schedaQuota'" @salva="handlerSalvataggio($event)"></SchedaQuota>

	
	<!-- Modal -->
	<div class="modal fade" id="popUpGenerazioneOrdine" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header bg-secondary text-white border-0">
					<div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Nuova Pratica</h6>  
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">

					<div class="row">
						<div class="col-lg-3">
						</div>
						<div class="col-lg-3">
							<div class="card" style="border:1px solid #dedede" v-on:click="ordine.STEP = 2;this.ordine.FK_ID_TIPO=this.$root.COSTANTI.ORDINE_FK_ID_TIPO_B2C">
								<div class="card-body text-center">
									<i class="icon-user icon-2x text-warning border-warning border-3 rounded-pill"></i>
									<h5 class="mb-1"><a href="#">Pratica Cliente</a></h5>								
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card" style="border:1px solid #dedede" v-on:click="modificaAgenzia();this.ordine.FK_ID_TIPO=this.$root.COSTANTI.ORDINE_FK_ID_TIPO_B2B">
								<div class="card-body text-center">
									<i class="icon-headset icon-2x text-secondary border-secondary border-3 rounded-pill"></i>
									<h5 class="mb-1" v-if="this.ordine.FK_ID_AZIENDA_AGENZIA > 0"><a href="#" class="" >{{ this.ordine.agency.personaGiuridica.DENOMINAZIONE}}</a></h5>
									<h5 class="mb-1" v-else><a href="#">Pratica Agenzia</a></h5>									
								</div>
							</div>
						</div>
						<div class="col-lg-3">
						</div>	
					</div>
					

					<div class="row" v-show="ordine.STEP >= 2">
						<hr />
						<div class="col-lg-3">
							<div class="card" style="border:1px solid #dedede" v-on:click="this.ordine.FLG_PREVENTIVO = 0;this.ordine.FLG_GRUPPI = this.$root.COSTANTI.ORDINE_FLG_GRUPPI_INDIVIDUALI;this.ordine.STEP = 3">
								<div class="card-body text-center" v-bind:class="{'bg-light':this.ordine.FLG_PREVENTIVO == 0}">
									<i class="icon-bag icon-2x text-success border-success border-3 rounded-pill p-1 mb-1 mt-1"></i>
									<h5><a href="#" class="btn btn-success btn-block" >ORDINE</a></h5>
									<p class="mb-3">Clicca qui per creare un Ordine</p>
									
								</div>
							</div>
						</div>

						<div class="col-lg-3">
							<div class="card" style="border:1px solid #dedede" v-on:click="this.ordine.FLG_PREVENTIVO = 1;this.ordine.FLG_GRUPPI = this.$root.COSTANTI.ORDINE_FLG_GRUPPI_INDIVIDUALI;this.ordine.STEP = 3">
								<div class="card-body text-center" v-bind:class="{'bg-light':this.ordine.FLG_PREVENTIVO == 1}">
									<i class="icon-balance icon-2x text-warning border-warning border-3 rounded-pill p-1 mb-1 mt-1"></i>
									<h5><a href="#" class="btn btn-warning btn-block" >PREVENTIVO</a></h5>
									<p class="mb-3">Clicca qui per creare un Preventivo</p>									
								</div>
							</div>
						</div>

						<!--
						<div class="col-lg-3">
							<div class="card"  style="border:1px solid #dedede" v-on:click="this.ordine.FLG_PREVENTIVO = 0;this.ordine.FLG_GRUPPI = this.$root.COSTANTI.ORDINE_FLG_GRUPPI_GRUPPI;this.ordine.STEP = 3">
								<div class="card-body text-center" v-bind:class="{'bg-light':this.ordine.FLG_PREVENTIVO == 1}">
									<i class="icon-users icon-2x text-secondary border-secondary border-3 rounded-pill p-3 mb-3 mt-1"></i>
									<h5><a href="#" class="btn btn-secondary btn-block" >GRUPPO</a></h5>
									<p class="mb-3">Clicca qui per creare una pratica Gruppi</p>									
								</div>
							</div>
						</div>

						<div class="col-lg-3">
							<div class="card"  style="border:1px solid #dedede" v-on:click="this.ordine.FLG_PREVENTIVO = 1">
								<div class="card-body text-center" v-bind:class="{'bg-light':this.ordine.FLG_PREVENTIVO == 1}">
									<i class="icon-heart6 icon-2x text-pink border-pink border-3 rounded-pill p-3 mb-3 mt-1"></i>
									<h5><a href="#" class="btn btn-pink btn-block" >LISTA NOZZE</a></h5>
									<p class="mb-3">Clicca qui per creare una pratica Lista Nozze</p>									
								</div>
							</div>
						</div>
						-->
					</div>


					<div class="row" v-show="ordine.STEP >= 3">
						<hr />
						<div class="col-lg-3">
						</div>
						<div class="col-lg-3">
							<div class="card" style="border:1px solid #dedede"  v-on:click="modificaIntestatario(1)">
								<div class="card-body text-center">
									<i class="icon-user-tie icon-2x text-primary border-primary border-3 rounded-pill p-1 mb-1 mt-1"></i>
									<h5 class="fs-lg mb-1"><a href="#">Intestatario Privato</a></h5>								
								</div>
							</div>
						</div>
						<div class="col-lg-3">
							<div class="card" style="border:1px solid #dedede"  v-on:click="modificaIntestatario(2)">
								<div class="card-body text-center">
									<i class="icon-profile icon-2x text-secondary border-secondary border-3 rounded-pill p-1 mb-1 mt-1"></i>
									<h5 class="fs-lg mb-1"><a href="#">Intestatario Aziendale</a></h5>									
								</div>
							</div>
						</div>
						<div class="col-lg-3">
						</div>	
					</div>
					
					<!--
					<h3 class="border-bottom-2 border-bottom-light mb-2 mt-2" v-show="ordine.FLG_PREVENTIVO > -1" style="line-height: 2rem;">
						<strong>Imposta dati intestatario</strong>
						<div class="btn-group float-end" style="position: relative; top: -4px;">
							<a href="#" class="btn btn-warning	 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fal fa-user fa-fw me-2"></i> CLIENTE</a>
							<div class="dropdown-menu" style="">
								<a href="#" class="dropdown-item pt-0 pb-0"><i class="fa-fw fal fa-search me-2"></i> </a>
								<a href="#" class="dropdown-item pt-0 pb-0" v-on:click="modificaIntestatario(2)"><i class="fa-fw fal fa-search me-2"></i> </a>
							</div>
						</div>
						<button class="btn btn-warning btn-sm me-3 float-end" style="position: relative; top: -4px;" v-on:click="modificaAgenzia()">
							<i class="fal fa-store-alt fa-fw me-2"></i> AGENZIA
						</button>
					</h3>	
				-->
				</div>
			
			</div>
		</div>
	</div>



    <RicercaPersonaGiuridica :tipo="1" :ref="'ricercaPg'" @selezionato="doModificaAgenzia($event)"></RicercaPersonaGiuridica>
    <RicercaPersonaGiuridica :tipo="3" :ref="'ricercaPgIntestatario'" @selezionato="doModificaIntestatario($event,2)"></RicercaPersonaGiuridica>
    <RicercaPersonaFisica :tipo="1" :ref="'ricercaPfIntestario'" @selezionato="doModificaIntestatario($event,1)"></RicercaPersonaFisica>
	<SchedaEmailOrdine :tipo="1" :ref="'schedaEmail'"></SchedaEmailOrdine>

</template>



<script>

import Ricerca from '@/components/Ricerca.vue'
//import Pax from '@/components/common/Pax.vue'
import CardRisultatoRicerca from '@/components/CardRisultatoRicerca.vue'
import SchedaQuota from '@/components/SchedaQuota.vue'
import RicercaPersonaGiuridica from '@/components/RicercaPersonaGiuridica.vue'
import RicercaPersonaFisica from '@/components/RicercaPersonaFisica.vue'
import SchedaEmailOrdine from '@/components/SchedaEmailOrdine.vue'


export default {
    name:'Quota',

    components: {
        Ricerca,
        CardRisultatoRicerca,
		SchedaQuota,
		RicercaPersonaGiuridica,
		RicercaPersonaFisica,
		SchedaEmailOrdine
    },

    data:function(){
		return{
			filters:global.filters,
			utente:{},
			startInterval:{},
			arrayRisultatiRicerca:new Array(),
			ordine : {},
			quotaSelezionata : {},
			quota:{prodotto:{},price:{}},
			FK_ID_MODULO:0,
			FLG_VOLO_ANDATA_SELEZIONATO:0,
			modalitaVistaRisultati:1,
			search:{
					PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000},	          	
					STATO_DISPONIBILITA:{TYPE:'COMBO',VALUE:''},
					ORDINAMENTO:{TYPE:'COMBO',VALUE:''},
			},
		}
    },

    methods:{
		startup : function(){
			this.ordine = utils.clone(this.$root.ordineSelezionato);
			utils.wait(()=>{this.$refs.ricerca.reset();});			
			this.arrayRisultatiRicerca = new Array();
			if (this.ordine.ID_ORDINE > 0){
				utils.ajax("travel/ordine",{ID_ORDINE:this.ordine.ID_ORDINE}, (response) => {
					if (response.esito == 0){
						this.ordine = response.data;
						if (this.ordine.isPreventivo == 1){
							if (this.ordine.quote.length > 0){
								this.impostaDatiRicerca(this.ordine.quote[0]);
							}
						}
					}
				});  
			}
			else{
				this.ordine = {ID_ORDINE:0,INTESTATARIO_FK_ID_PERSONA_FISICA:0,INTESTATARIO_FK_ID_PERSONA_GIURIDICA:0};
			}
		},
		handlerSalvataggio : function(idOrdine){
			//VOLI
			console.log("HANDLER_SALVATAGGIO");
			if (this.FK_ID_MODULO == 4){
				if (this.ordine.ID_ORDINE == 0){
					this.ordine.ID_ORDINE = idOrdine;
				}	
				utils.ajax("travel/ordine",{ID_ORDINE:this.ordine.ID_ORDINE}, (response) => {
					if (response.esito == 0){
						this.ordine = response.data;
					}
				}); 
				this.FLG_VOLO_ANDATA_SELEZIONATO = 1;		
			}
			else{
				this.startup();
			}
		},
        varianti : function(){
            this.$root.appoggio.prodotto = {ID_PRODOTTO:1};
            this.$root.changeRoute('varianti');
        },
        ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data;
			this.FLG_VOLO_ANDATA_SELEZIONATO = 0;		
			//$('#linkRisultati'+this.FK_ID_MODULO).click();
        },
		
        gestioneSelezioneQuota:function(quota){
            //console.log(quota);
            this.quota = utils.clone(quota);
        },
        openOrdine:function(ordine){
            this.$root.ordineSelezionato = utils.clone(ordine);
            global.router.push("Ordine");
        },
        opzioneSelezionata : function(opzioniSelezionate){
            this.$refs.ricerca.ricerca(new Array({CHIAVE:"opzioniSelezionate",VALORE:opzioniSelezionate}));
        },
        salvaQuota : function(){
            if (this.quota.IS_TEMP == true){
                utils.ajax("travel/quota/insert",{ID_ORDINE:this.ordine.ID_ORDINE/*,ID_QUOTA:this.quota.ID_QUOTA*/}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
						global.router.push("Ordine");
                    }
                });  
            }
            else{
                utils.ajax("travel/quota/update",this.quota, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }
                }); 
            }            
        },
        paxChangeHandler : function(pax){
            pax.SALVATO = 0;
            console.log(pax);
        },

		
		tabIsVisible(idModulo){
			if (this.FK_ID_MODULO == idModulo){
				return true;
			}			
			return false;			 
		},
		changeModuloHandler : function(idModulo){
			this.FK_ID_MODULO = idModulo;
			this.arrayRisultatiRicerca = new Array();
		},
		quotaSelezionataHandler : function(quota){
			if (quota != null){
				this.quotaSelezionata = utils.clone(quota);
			}
			else{
				this.quotaSelezionata = null;
			}
			//SE ESISTE GIA' UN ORDINE CREATO => APRO LA SCHEDA DELLA QUOTA OPPURE LA GESTISCO COME LA QUOTA DI UN PREVENTIVO (IN BASE ALLA TIPOLOGICA DI ORDINE)
			if (this.ordine.ID_ORDINE > 0){
				this.openSchedaQuota(quota);
			}
			//MOSTRO UNA POPUP CHE MI CHIEDE COSA VOGLIO FARE: 1 - CREARE UN ORDINE / 2 - CREARE UN PREVENTIVO
			else{
				this.ordine = {ID_ORDINE:0,FLG_PREVENTIVO:-1,STEP:1};
				this.filters.openPopUp("popUpGenerazioneOrdine");
			}
		},
		openSchedaQuota : function(quota){
			if (this.ordine.isPreventivo == 1){
				//AGGIUNGO LA QUOTA DIRETTAMENTE AL PREVENTIVO
				quota.DATI_RICERCA = utils.clone(this.$refs.ricerca.datiRicercaCompleti);
				utils.ajax("travel/ricerca/salva",{QUOTA:quota}, (response) => {
					var arrayCamere = new Array();
					for (var i = 0 ; i < quota.CAMERE.length ; i++){
						var item = quota.CAMERE[i].RISULTATI[quota.CAMERE[i].INDICE_RISULTATO_SELEZIONATO].pax;
						item.ID_CAMERA = quota.CAMERE[i].CAMERA.ID_CAMERA;
						arrayCamere.push(item);
					}
					utils.ajax("travel/quota/insert",{ID_ORDINE:this.ordine.ID_ORDINE,ID_QUOTA:quota.ID_QUOTA,ID_QUOTA_DELETE:0,pax:arrayCamere,INTESTATARIO_FK_ID_PERSONA_FISICA:this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA}, (response) => {
						if (response.esito == 0){
							utils.alert.success(response.messaggio);
							utils.ajax("travel/ordine",{ID_ORDINE:this.ordine.ID_ORDINE}, (response) => {
								if (response.esito == 0){
									this.ordine = response.data;
								}
							});                  
						}
					});
				});
			}
			else{
				this.$refs.schedaQuota.openSchedaQuota(quota,this.$refs.ricerca.datiRicercaCompleti,this.ordine);
			}			
		},
		divVoliRitornoVisibile : function(){
			if (this.$refs.ricerca != undefined && this.$refs.ricerca.dati != undefined){
				return this.$refs.ricerca.dati.FLG_SOLA_ANDATA == 0;
			}
			return false;			
		},
		eliminaQuota : function(quota){
            utils.alert.confirm("Sei sicuro di voler eliminare la quota <strong>" + quota.DESCRIZIONE + "</strong>?",()=>{
                utils.ajax("travel/ordine/quota/delete",{ID_QUOTA:quota.ID_QUOTA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax("travel/ordine",{ID_ORDINE:this.ordine.ID_ORDINE}, (response) => {
							if (response.esito == 0){
								this.ordine = response.data;
								this.FLG_VOLO_ANDATA_SELEZIONATO = 0;
								this.$refs.ricerca.ricerca();
							}
						});                         
                    }
                });
            },this.$root)
        },
		currentTitle : function(){
			if (this.$refs.ricerca == undefined || this.$refs.ricerca.dati == undefined || this.$refs.ricerca.dati.FK_ID_MODULO == 0){
				return "Seleziona tipologia";
			}
			var res = utils.array.select(this.filters.arrayModuli(),"ID_MODULO",this.$refs.ricerca.dati.FK_ID_MODULO,1);
			if (res.length == 0){
				return "";
			}
			return res[0]['DESCRIZIONE'];
		},
		currentModulo : function(){
			if (this.$refs.ricerca == undefined || this.$refs.ricerca.dati == undefined || this.$refs.ricerca.dati.FK_ID_MODULO == 0){
				return 0;
			}
			return this.$refs.ricerca.dati.FK_ID_MODULO;
		},
		ordinamentoRisultati : function(arr){
			if (this.search.ORDINAMENTO.VALUE == 1 || this.search.ORDINAMENTO.VALUE == 2){
				arr = utils.array.ordinaArray(arr,"PREZZO_TOTALE");
				if (this.search.ORDINAMENTO.VALUE == 2){
					arr = arr.reverse();
				}
			}
			else{
				arr = utils.array.ordinaArray(arr,"DESCRIZIONE");
				if (this.search.ORDINAMENTO.VALUE == 4){
					arr = arr.reverse();
				}
			}			
			return arr;
		},
		impostaDatiRicerca : function(quota,event = null){
			console.log("ciaone");
			if (event != null){
				if (event.target.nodename =="button" || event.target.nodename == "i"){
					return;
				}
			}
			this.$refs.ricerca.reset(JSON.parse(quota.DATI_RICERCA));
		},
		updateGruppoQuota : function(quota){
			utils.ajax("travel/ordine/quote/gruppo",{ID_QUOTA:quota.ID_QUOTA,FK_ID_GRUPPO:quota.FK_ID_GRUPPO}, (response) => {
			},false);
		},
		nuovoPreventivo : function(){ 
			this.preventivoSelezionato = {ID_CATEGORIA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
            //this.$refs.schedaContenuto.reset();
            this.filters.openPopUp("popUpPreventivo");
        },
        openPopUpInviaEmailOrdine : function(){
			this.$refs.schedaEmail.startup(this.ordine);
        },
		openRoute : function(route){
			global.router.push(route);
		},
		modificaAgenzia : function(){
            this.$refs.ricercaPg.openRicerca();
        },

        modificaIntestatario : function(tipo){
            if (tipo == 1){
                this.$refs.ricercaPfIntestario.openRicerca();
            }
            else{
                this.$refs.ricercaPgIntestatario.openRicerca();
            }
        },

		doModificaAgenzia : function(pg){
            this.ordine.FK_ID_AZIENDA_AGENZIA = pg.ID_AZIENDA;
            this.ordine.agency = {ID_AZIENDA:pg.ID_AZIENDA,personaGiuridica:utils.clone(pg)};
            //if (this.ordine.ID_PREVENTIVO > 0){
			//this.salvaOrdine();
			this.ordine.STEP = 2;
            //}
        },
        doModificaIntestatario : function(p,tipo){
            if (tipo == 1){
                this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA = p.ID_PERSONA_FISICA;
                this.ordine.intestatarioPersonaFisica = utils.clone(p);
                this.ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA = 0;
                this.ordine.intestatarioPersonaGiuridica = {};
            }
            else{
                this.ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA = p.ID_PERSONA_GIURIDICA;
                this.ordine.intestatarioPersonaGiuridica = utils.clone(p);
                this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA = 0;
                this.ordine.intestatarioPersonaFisica = {};
            }   
			this.salvaOrdine();         
        },
		
        salvaOrdine : function(){
			this.ordine.FK_ID_TIPO_FISCALE = this.$root.COSTANTI.ORDINE_DEFAULT_TIPO_FISCALE;
            utils.ajax("travel/ordine/genera",this.ordine, (response) => {
                if (response.esito == 0){
					this.filters.closePopUp("popUpGenerazioneOrdine");
                    utils.alert.success(response.messaggio);
					utils.ajax("travel/ordine",{ID_ORDINE:response.id_inserito}, (response) => {
						if (response.esito == 0){
							this.ordine = response.data;
							this.$root.ordineSelezionato = utils.clone(this.ordine)	;
							console.log(this.quotaSelezionata);
							if (this.quotaSelezionata != null){
								this.quotaSelezionataHandler(this.quotaSelezionata);
							}
						}
					}); 
                }
            });
        },
		getCountQuoteGruppo(idGruppo){
			return utils.array.select(this.ordine.quote,"FK_ID_GRUPPO",idGruppo).length;
		}
    },

	computed : {
		getQuoteOrdinate : function(){
			if (this.ordine.quote != undefined){
				return utils.array.ordinaArray(this.ordine.quote,"FK_ID_GRUPPO");
			}
			return new Array();			
		},
		getGruppi : function(){
			var gruppi = [];
			if (this.ordine.quote == undefined){
				return new Array();
			}
			var max = 0;
			for (var i = 0; i < this.ordine.quote.length ; i++){
				var check = 0;
				for (var j = 0; j < gruppi.length ; j++ ){
					if (this.ordine.quote[i].FK_ID_GRUPPO == gruppi[j]){
						check = 1;
						break;
					}
				}
				if (check == 0){
					gruppi.push(this.ordine.quote[i].FK_ID_GRUPPO);
					if (this.ordine.quote[i].FK_ID_GRUPPO > max){
						max = this.ordine.quote[i].FK_ID_GRUPPO;
					}
				}
			}
			gruppi.push(max+1);
			return gruppi;			
		},
		getRisultatiRicerca(){	
			var arr =  utils.inGrid(this.arrayRisultatiRicerca,this.search).arr; 
			if (this.ordine.ID_ORDINE == 0){
				return this.ordinamentoRisultati(arr);
			}
			//console.log(this.$refs.ricerca.dati);
			var arr2 = new Array();
			for (var i = 0 ; i < arr.length ; i++){
				var check = 0;
				for (var j = 0 ; j < this.ordine.quote.length ; j++){
					var quota = this.ordine.quote[j];
					//CAMERE
					if (quota.prodotto.FK_ID_MODULO == 5){
						if (quota.prodotto.FK_ID_PRODOTTO == arr[i].PRODOTTO.ID_PRODOTTO){
							if (quota.DATA_START == this.$refs.ricerca.dati.DATA_START){
								if (quota.DATA_END == this.$refs.ricerca.dati.DATA_END){
									check = 1;
									break;	
								}	
							}
						}
					}
					//TUTTO IL RESTO
					else{
						if (quota.prodotto.ID_PRODOTTO == arr[i].PRODOTTO.ID_PRODOTTO){
							if (quota.DATA_START == this.$refs.ricerca.dati.DATA_START){
								if (quota.DATA_END == this.$refs.ricerca.dati.DATA_END){
									check = 1;
									break;	
								}	
							}
						}
					}
				}
				if (check == 0){
					arr2.push(utils.clone(arr[i]));
				}
			}
			return this.ordinamentoRisultati(arr2);
		},
		getRisultatiRicercaRitorno : function(){
			var arrayResult = new Array();
			for (var i = 0 ; i < this.arrayRisultatiRicerca.length ; i++){
				if (this.arrayRisultatiRicerca[i].FLG_RITORNO == 1){
					arrayResult.push(this.arrayRisultatiRicerca[i]);
				}
			}
			var arr =  utils.inGrid(arrayResult,this.search).arr; 
			return this.ordinamentoRisultati(arr);
		}
	},

    created : function () {
		this.startup();
    }
}
</script>


<style scoped>
.ordering { padding: 0rem !important; width: 21.7rem !important; margin-top: 0.3rem; } 
@media (max-width: 991.98px){ .ordering {width: 18.5rem !important; } }
</style>