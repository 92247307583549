<template>

    <div>
    	<Utenti :livello="2" :ref="'utenti'"></Utenti>
    </div>

</template>



<script>

import Utenti from '@/components/common/Utenti.vue'


export default {
    name:"UtentiCms",
    components:{
        Utenti,
    },

    methods:{
        refreshEnte : function(){
            this.$refs.utenti.getUtenti();
        }
    }
}
</script>