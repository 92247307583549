<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getUtenti()"> Utenti </a> \
					<span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> {{levelName}}</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>	 
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
						
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;" v-on:click="nuovoUtente()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Utente</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a v-show="this.modulo != 2" href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(livello == 2 ? 21 : 22)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(livello == 2 ? 21 : 22)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(livello == 2 ? 21 : 22)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>

					</div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#utenti-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>{{levelName}}
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation" v-if="livello == 5">
						<a href="#utenti-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="statisticheStartup()" tabindex="-1">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity me-2"></i>
								<Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate>
							</div>
						</a>
					</li>
				</ul>

 
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div v-show="livello == 5" class="dropdown-divider"></div>
									<a v-show="livello == 5" href="#" class="dropdown-item"><i class="far fa-code-merge fa-fw me-2"></i><span class="text-muted me-1">|</span>Merge Utenti</a>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>
						<li class="nav-item">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="utenti-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="utente-a">
									<div class="row">
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Utente" v-model="search.ID_UTENTE.VALUE" :tabindex="7" />
										</div>
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<select class="form-select form-select-sm" v-model="search.SESSO.VALUE" data-placeholder="Sesso" :tabindex="13">
												<option value="">Sesso </option>   
												<option value="1"> &nbsp; Uomo</option>
												<option value="2"> &nbsp; Donna</option>
											</select>
										</div>
										<div class="col-12 col-md-6 col-lg-6">
											<div class="row">
												<div class="col-6 mbx-1" v-bind:class="{'col-md-3':livello == 5,'col-md-4':livello != 5}">
													<select v-show="livello != 5" class="form-select form-select-sm" v-model="search.FK_ID_RUOLO.VALUE">
														<option value="">Ruolo</option>
														<option v-for="ruolo in $root.arrayRuoliUtente" v-bind:value="ruolo.ID_OPZIONE_TIPOLOGICA" v-show="checkShowRuolo(ruolo)" v-bind:key="ruolo.DESCRIZIONE"> &nbsp; {{ ruolo.DESCRIZIONE }}</option>
													</select>
													<select v-show="livello == 5" class="form-select form-select-sm" v-model="search.FK_ID_TIPOLOGIA.VALUE">
														<option value="">Tipologia</option>
														<option v-for="opzione in arrayTipologie" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.DESCRIZIONE"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
													</select>
												</div> 
												<div class="col-6 mbx-1" v-bind:class="{'col-md-3':livello == 5,'col-md-4':livello != 5}">
													<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Cognome" :tabindex="9" v-model="search.COGNOME.VALUE" />
												</div>
												<div class="col-6 mbx-1" v-bind:class="{'col-md-3':livello == 5,'col-md-4':livello != 5}">
													<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Email" :tabindex="10" v-model="search.EMAIL.VALUE" />
												</div> 	
												<div class="col-6 col-md-3 col-lg-3 mbx-1">
													<select v-show="livello == 5" class="form-select form-select-sm" id="" data-placeholder="Associazione" :tabindex="4">
														<option value="">Associazione</option>
													</select>
												</div>										
											</div>
										</div>
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
											<select v-show="livello == 5" class="form-select form-select-sm" v-model="search.FK_ID_POSIZIONE.VALUE">
												<option value="">Posizione</option>
												<option v-for="opzione in arrayPosizioni" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:key="opzione.DESCRIZIONE"> &nbsp; {{ opzione.DESCRIZIONE }}</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1 mbx-1">
										</div>
										<div class="col-6 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.STATO.VALUE" :tabindex="13">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivati</option>
												<option value="0"> &nbsp; Disattivati</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" v-on:click="filters.resizeGrid('collapse-a','tableFixHead','box-card')" role="button" aria-expanded="false">
			                                    + FILTRI
											</a>
										</div>
									</div>					   	
									<div class="collapse" id="collapse-a" style="" v-show="livello > 3">
										<div class="row">
											<div class="col-12 col-md-2 col-lg-2 mt-1">
												<Datepicker autocomplete="off" class="bg-light" style="width:100%" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" placeholder="Data registrazione" :tabindex="2" />
											</div>  
											<div class="col-6 col-md-2 col-lg-2 mt-1">
												<Select2 :api="'core/geo/stati/select2'" :placeholder="'Nazione'" v-model="search.FK_ID_STATO_RESIDENZA.VALUE" :tabindex="16"></Select2>
											</div>
											<div class="col-6 col-md-2 col-lg-2 mt-1">
												<Select2 v-model="search.FK_ID_REGIONE.VALUE" :w="250" :api="'core/geo/regioni/select2'" :apiselected="'core/basic/element?className=Regione&ID=ID_REGIONE&DESCRIZIONE=NOME&select2=1'" :tabindex="17" :placeholder="'Regione'"></Select2>
											</div>
											<div class="col-12 col-md-2 col-lg-2 mt-1">
												<Select2 v-model="search.FK_ID_COMUNE.VALUE" :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'" :tabindex="18" :placeholder="'Comune'"></Select2>
											</div> 
											<div class="col-6 col-md-2 col-lg-2 mt-1">
												<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice Fiscale" :tabindex="11" v-model="search.CODICE_FISCALE.VALUE" />
											</div> 
											<div class="col-6 col-md-2 col-lg-2 mt-1">
												<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Telefono" :tabindex="9" v-model="search.TELEFONO.VALUE" />
											</div>
										</div>
									</div>
								</form>
							</div>
						</div> 
					</div>
				
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllUsers" v-on:click="filters.selectAll('chkAllUsers',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm">Nominativo<br />Contatti</th>
										<th class="table-head-sm" style="width:17%;">
											<span v-if="livello < 3">Ruolo /<br />Gruppo</span>
											<span v-else>Tipologia /<br />Nazionalità</span>
										</th>
										<th class="table-head-sm" style="width:17%;">Data di Nascita /<br />Residenza</th>
										<th class="table-head-sm" style="width:17%;">
											<div v-if="livello < 3">Accesso /<br />Livello</div>
											<div v-else>Ordini /<br />Fatturati</div>
										</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="utente in paginatedList.arr" v-bind:key="utente.ID_UTENTE">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="utente.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{utente.ID_UTENTE}}</span><br />
											<small class="badge bg-light text-primary float-end"> &nbsp; </small>
										</td>
										<td class="td-class text-center"> 
											<img v-if="utente.ID_UTENTE > 0 && utente.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',utente.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="utente.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
										<td class="td-class fs-sm">
											<span v-show="utente.NOTE != ''"><span class="badge badge-xs bg-danger text-danger bg-opacity-20 cursor-pointer me-2 mb-0"  title="Note personali" data-bs-toggle="tooltip" data-bs-placement="top" v-on:click="showNote(utente)"><i class="fal fa-info fa-fw"></i></span></span>
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneUtente(utente)">{{utente.COGNOME}} {{utente.NOME}}</span><br />
											<span v-show="livello < 4" class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="far fa-user fa-fw"></i> {{utente.USERNAME}}</span>
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="utente.EMAIL" v-bind:href="`mailto:${utente.EMAIL}`"><i class="fal fa-envelope fa-fw"></i> Email</span>
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="utente.TELEFONO" v-bind:href="`tel:${utente.TELEFONO}`"><i class="fal fa-phone fa-fw"></i> Tel.</span>																					
										</td>  
										<td class="td-class fs-sm">
											<div v-if="livello != 5">
												<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="far fa-square fa-fw" v-bind:style="getStileColore(utente)" data-bs-toggle="tooltip" data-bs-placement="top" :title="'Ruolo'"></i> {{utente.RUOLO}}</span><br/>
												<span>Gruppo</span>
											</div>
											<div v-if="livello == 5">
												<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;"><i class="fal fa-tag fa-fw"></i> Privato </span><br/>
												<span><Flag :country="utente.FK_ID_NAZIONALITA" class="rounded me-2"></Flag> {{ utente.STATO_NAZIONALITA }}</span>
											</div>
										</td>
										<td class="td-class fs-sm">
											<span class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Data di Nascita"><i class="fal fa-calendar-alt fa-fw me-1"></i>{{filters.formatDate(utente.DATA_NASCITA)}}</span><br />
											<span class="text-muted"><i class="fal fa-map-marker-alt fa-fw me-1"></i>{{utente.STATO_RESIDENZA}} - {{utente.REGIONE}} - {{utente.COMUNE}}</span>
										</td>
										<td class="td-class fs-sm">
											<div v-if="livello < 5"><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Ultimo accesso: <a href="#">{{ filters.formatDateTime(utente.DATA_ACCESSO) }}</a></div>
											<div v-if="livello < 5"><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Livello: <a href="#">Admin</a></div>
											<div v-if="livello == 5"><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Fatturato {{ annoCorrente }}: <a href="#" v-on:click="openOffCanvas();openSchedaVisualizzazioneUtente(utente,'ordini')"> {{ utente.FATTURATO_ANNO_CORRENTE }} </a></div>
											<div v-if="livello == 5"><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Fatturato {{ annoPrecedente }}: <a href="#" v-on:click="openOffCanvas();openSchedaVisualizzazioneUtente(utente,'ordini')"> {{ utente.FATTURATO_ANNO_PREC }} </a></div>
										</td>
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoUtente(utente)" v-model="utente.STATO" :size="'sm'" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaUtente(utente)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Utente</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="resetPassword(utente)"><i class="fad fa-key fa-fw me-2"></i><span class="text-muted me-1">|</span>Reset Password</a>
													<a href="#" class="dropdown-item" v-on:click="login(utente)"><i class="fad fa-cogs fa-fw me-2"></i><span class="text-muted me-1">|</span>Login diretto</a>
													<a v-if="livello == 5" href="#" class="dropdown-item" ><i class="fad fa-code-merge fa-fw me-2"></i><span class="text-muted me-1">|</span>Merge Utente</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteUtente(utente)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Utente</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">UTENTI: {{ paginatedList.count }}</span> 
                                    <span v-show="livello == 5" class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: </span>
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="utenti-b">
				<div class="row">
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-house-line ph-2x text-primary me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ getTotali.COUNT }}</h4>
									<span class="text-muted">Totale Utenti</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-up ph-2x text-success me-3"></i>

								<div class="flex-fill text-end">
									<h4 class="mb-0">{{ statsUtenti.TOT_UTENTI_CON_ORDINI }}</h4>
									<span class="text-muted">Utenti attivi</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<i class="ph-thumbs-down ph-2x text-secondary me-3"></i>
								<div class="flex-fill text-end">
									<h4 class="mb-0">{{  getTotali.COUNT - statsUtenti.TOT_UTENTI_CON_ORDINI }}</h4>
									<span class="text-muted">Utenti 0 pratiche</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">{{ getTotali.USERKO }}</h4>
									<span class="text-muted">Utenti bannati</span>
								</div>
								<i class="ph-house-simple ph-2x text-danger ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">2365</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-sketch-logo ph-2x text-indigo ms-3"></i>
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-2">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center">
								<div class="flex-fill">
									<h4 class="mb-0">389,438</h4>
									<span class="text-muted">Utenti </span>
								</div>
								<i class="ph-globe-hemisphere-east ph-2x text-purple ms-3"></i>
							</div>
						</div>
					</div>
				</div>

				<div class="card shadow">
					<div class="card-body mt-0 mb-0">
						<nav class="tabbable">
							<ul class="nav nav-tabs nav-tabs-underline">
								<li class="nav-item" role="presentation">
									<a href="#statics-1" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab" v-on:click="getStatisticheOrdiniChart()">VENDITE</a>
								</li>
								<li class="nav-item" role="presentation">
									<a href="#statics-2" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" v-on:click="getStatisticheOrdiniFatt()">FATT. VENDUTO</a>
								</li>
								<li class="nav-item" role="presentation">
									<a href="#statics-3" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">FATT: PARTITO</a>
								</li>
								<li class="nav-item" role="presentation">
									<a href="#statics-4" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">GEO</a>
								</li>
							</ul>
						</nav>
						<div class="tab-content">

							<div class="tab-pane fade mt-1 active show" id="statics-1" role="tabpanel" style="padding: 0px;">
								<div class="alert alert-2 alert-secondary alert2 border-0">
									
									<div class="row">
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :api="'core/geo/regioni/select2'" :placeholder="'Seleziona Regione'" v-model="searchStatisticheOrdini.FK_ID_REGIONE.VALUE" :tabindex="6"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :api="'core/geo/province/select2'" :placeholder="'Seleziona Provincia'" v-model="searchStatisticheOrdini.FK_ID_PROVINCIA.VALUE" :tabindex="7"></Select2>
										</div>
										<div class="col-12 col-md-2 mbx-1">
											<Select2 :w="250" :api="'core/geo/comuni/select2'" placeholder="Seleziona Citt&agrave;" v-model="searchStatisticheOrdini.FK_ID_COMUNE.VALUE" :tabindex="19"></Select2>
										</div> 
										<div class="col-12 col-md-5">
											<div class="row">
												<div class="col-12 col-md-4 mbx-1">
													<select class="form-select form-select-sm" data-placeholder="Sesso" :tabindex="13">
														<option value="">Sesso </option>   
														<option value="1"> &nbsp; Uomo</option>
														<option value="2"> &nbsp; Donna</option>
													</select>
												</div>
												<div class="col-12 col-md-4 mbx-1">
													<select class="form-select form-select-sm"  data-placeholder="Sesso" :tabindex="13">
														<option value="">Sesso </option>   
														<option value="1"> &nbsp; Uomo</option>
														<option value="2"> &nbsp; Donna</option>
													</select>
												</div>
												<div class="col-12 col-md-4 mbx-1">
													<select class="form-select form-select-sm" data-placeholder="Sesso" :tabindex="13">
														<option value="">Sesso </option>   
														<option value="1"> &nbsp; Uomo</option>
														<option value="2"> &nbsp; Donna</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-12 col-md-1 mbx-1">
											<button class="btn btn-flat-info btn-sm float-end" v-on:click="getStatisticheOrdiniChart()">
												AGGIORNA
											</button>
										</div>
									</div>
									
								</div>
								<div class="box-advstats mt-2">
									<div class="grafico" id="chart1">
                    
									</div>
								</div>
							</div>
			
							<div class="tab-pane fade mt-1" id="statics-2" role="tabpanel">
								<div class="alert alert-2 alert-secondary alert2 border-0">
									
									<div class="row">
										<div class="col-12 col-md-2 mbx-1">
											<Datepicker autocomplete="off" class="bg-light" style="width:253px" v-model="dataCreazioneRangeFatt" @update:model-value="dataCreazioneRangeChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" placeholder="Seleziona Range Date" />
										</div>
										<div class="col-12 col-md-9 mbx-1">

										</div>
									</div>

								</div>
								<div class="box-27 mt-1">
									
									<div class="box-23">
										<table class="table table-bordered table-hover table-striped table-togglable table-cms"> 
											<thead class="sticky-top">
												<tr class="bg-secondary text-white">
													<th class="table-head-sm" style="width:6%;"> # </th>
													<th class="table-head-sm" style="">Nome </th>
													<th v-if="livello == 1" class="table-head-sm" style="width:13%;"><span>Promotore</span></th>
													<th v-if="livello == 1" class="table-head-sm" style="width:10%;"><span>Gruppo</span></th>
													<th v-if="livello == 1" class="table-head-sm" style="width:13%;"><span>Network</span></th>
													<th class="table-head-sm" style="width:13%;">Citt&agrave; / Regione</th>
													<th class="table-head-sm" style="width:17%;">Fatturato</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="utente in paginatedListFatt.arr" v-bind:key="utente.ID_UTENTE">
													<td class="td-class fs-sm">
														<span>{{utente.ID_UTENTE}}</span>
													</td>
													<td class="td-class fs-sm">
														<span class="text-primary text-uppercase fw-bold cursor-pointer">{{utente.COGNOME}}</span>
													</td>
													<td v-show="livello == 1" class="td-class fs-sm">
														<span class="text-dark">{{azienda.PROMOTORI}}</span>
													</td>
													<td v-show="livello == 1" class="td-class fs-sm">
														<span class="text-muted">Gruppo</span>
													</td>
													<td v-show="livello == 1" class="td-class fs-sm">
														<span class="text-muted">Network</span>
													</td>
														
													<td class="td-class fs-sm">	
														<span class="text-muted me-1" v-show="utente.FK_ID_NAZIONALITA == 118">{{ utente.GEO_REGIONE }} - {{ utente.GEO_COMUNE }}</span>
														<span class="text-muted me-1" v-show="utente.FK_ID_NAZIONALITA != 118">{{ utente.COMUNE }} - {{ utente.GEO_STATO }} </span>
													</td>
													<td class="td-class fs-sm">
														<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Fatturato: <a href="#">{{ filters.formattaImporto(utente.IMPORTO) }} </a></div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="row page-bottom">
										<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
											<div style="position: relative; top: 10px;">
												<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
												<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">AGENZIE: </span> 
												<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FATTURATO: </span>
											</div>
										</div>
										<div class="col-9 col-md-6" :tabindex="27">
											<div class="float-end">
												<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListFatt.count" :numpagine="searchStatisticheOrdiniFatt.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
											</div>
										</div>
										<div class="col-3 col-md-1" :tabindex="28">
											<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchStatisticheOrdiniFatt.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
												<option value="5">5</option>
												<option value="10">10</option>
												<option value="25">25</option>
												<option value="50">50</option>
												<option value="75">75</option>
												<option value="100">100</option>
												<option value="250">250</option>
												<option value="500">500</option>
											</select>
										</div> 
									</div>	
								</div>
							</div>

							<div class="tab-pane fade mt-1" id="statics-3" role="tabpanel">
								This is some placeholder content the <strong>third</strong> tab's associated content
							</div>

							<div class="tab-pane fade mt-1" id="statics-4" role="tabpanel">





							</div>
								
						</div>
					</div>
				</div>
			</div>

			<SchedaUtente :ref="'schedaUtente'" @updateGriglia="getUtenti();"></SchedaUtente>

		</div>
	</div>	


	<!-- START MODALI -->
	<div class="modal fade" id="modalNote" aria-modal="true" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> NOTE IMPORTANTI</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">

					<div class="textarea rounded" v-html="utenteSelezionato.NOTE"></div>

				</div>
				<div class="modal-footer bg-light p-1">                                
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
			</div>
		</div>
	</div> 
	    
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" v-show="livello == 5" :tabindex="2">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneUtente :ref="'schedaVisualizzazioneUtente'" @modificaUtente="modificaUtente($event)"></SchedaVisualizzazioneUtente>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>	 

	<div class="modal fade" id="modalPassword" aria-modal="true" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Reset Password</h6>  
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
				<div class="modal-body">

					<div class="form-group">
						<label>Nuova password</label>
						<input type="password" class="form-control" placeholder="Nuova password" v-model="utenteSelezionato.PASSWORD">
					</div>
					<div class="form-group">
						<label>Conferma password</label>
						<input type="password" class="form-control" placeholder="Conferma nuova password" v-model="utenteSelezionato.CONFERMA_PASSWORD">
					</div>
				</div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
					<button type="button" class="btn btn-success float-end btn-sm" v-on:click="doResetPassword()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
				</div>
			</div>
		</div>
	</div> 

    <div class="modal fade" id="popUpLogAzioniUtente" aria-modal="true" role="dialog" tabindex="-1">
    	<div class="modal-dialog modal-lg">
            <div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Logs dettagli Azioni - {{utenteSelezionato.USERNAME}}</h6>  
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
					<div class="row">
						<div class="col-md-4">
							<Datepicker autocomplete="off" class="bg-light" style="width:250px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy'" auto-apply  :partial-range="false" :tabindex="2" />
						</div>
						<div class="col-md-4">
							<button class="btn btn-light btn-sm" v-on:click="aggiornaLogAzioni()">MOSTRA</button>
						</div>
						<div class="col-md-4"></div>
					</div>
					<hr class="hr-2" />
					<div class="tableFixHead tableFixHeadPopUp">
						<table class="table table-bordered table-hover table-striped table-togglable"> 
							<thead>
								<tr>
									<th class="table-head-sm" style="width:20%;"> Data </th>
									<th class="table-head-sm" style="">Api</th>
								<th class="table-head-sm" style="width:5.4%;">Azione</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="azione in arrayAzioniUtente" v-bind:key="azione">
									<td class="table-head-sm" style="">{{filters.formatDateTime(azione.time)}}</td>
									<td class="table-head-sm" style="">{{ azione.message }}</td>
									<td class="text-center">
										<div class="list-icons" :tabindex="26">
											<a href="#" class="list-icons-item" v-on:click="apriDettaglioAzione(azione)"><i class="icon-menu9"></i></a>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded" >
						<!-- 
						<Pagine v-model="searchArrayAzioniUtente.PAGINATION.PAGE_NUMBER" :numpagine="searchArrayAzioniUtente.PAGINATION.LENGTH"></Pagine>-->
					</div>
					
				</div>
				<div class="modal-footer bg-light p-1"> 
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
			</div>
		</div>
	</div>

    <div class="modal fade" id="popUpDettaglioLogAzioniUtente" aria-modal="true" role="dialog" tabindex="-1">
    	<div class="modal-dialog modal-lg">
            <div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Logs dettagli Azioni - {{utenteSelezionato.USERNAME}}</h6>  
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
					<pre class="box_error" style="font-size:10px">
						{{ azioneSelezionata.context }}
					</pre>
				</div>
				<div class="modal-footer bg-light p-1">                                
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
			</div>
		</div>
	</div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Flag from '@/components/utils/Flag.vue'
import Datepicker from '@vuepic/vue-datepicker' 
import SchedaUtente from '@/components/SchedaUtente.vue'
import SchedaVisualizzazioneUtente from '@/components/SchedaVisualizzazioneUtente.vue'

export default {
    name:"Utenti",

    props:['livello'],	

    components:{
        Pagine,
        ISwitch,
        Select2,
		Flag,
		Datepicker,
        SchedaUtente,
		SchedaVisualizzazioneUtente,
    },	 

    data: function () {
        return {   
            apiStartup:'travel/admin/utenti',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            livelloMaxFiltroSocieta: 2,
            arrayUtenti: new Array(),
            utenteSelezionato:{personaFisica:{}},
            utenteCorrente:{},
			modulo:0,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ID_UTENTE:{TYPE:'TEXT',VALUE:''},
				DENOMINAZIONE : {TYPE:'TEXT',VALUE:''},
                EMAIL:{TYPE:'TEXT',VALUE:''},
                NOME:{TYPE:'TEXT',VALUE:''},
                SESSO:{TYPE:'TEXT',VALUE:''},
                COGNOME:{TYPE:'TEXT',VALUE:''},
				CODICE_FISCALE:{TYPE:'TEXT',VALUE:''},
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
				TELEFONO:{TYPE:'TEXT',VALUE:''},
                FK_ID_RUOLO:{TYPE:'COMBO',VALUE:''},
				FK_ID_POSIZIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPOLOGIA:{TYPE:'COMBO',VALUE:''},				
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},	
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},	
				FK_ID_STATO_RESIDENZA:{TYPE:'COMBO',VALUE:''},	
                STATO:{TYPE:'COMBO',VALUE:'1'},	

            },
			selectedMenuItem:{},
			dataCreazioneRange:{},
			dataCreazioneRangeFatt:{},
			azioneSelezionata : {},
			arrayCampiPopUpUtente : new Array(),
			arrayAzioniUtente : new Array(),
			arrayOpzioniAll : new Array(),
			arrayPosizioni: new Array(),
			arrayTipologie : new Array(),
			arrayRuoli : new Array(),
			statsUtenti:{},
			searchStatisticheOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},				
            },
			arrayStatisticheOrdiniFatt : new Array(),
			searchStatisticheOrdiniFatt:{
				PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},
				FK_ID_PROVINCIA:{TYPE:'COMBO',VALUE:''},
				FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},
				FK_ID_TIPO:2,
				DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},	
			}
			
        }
    },

    methods: {
        getUtenti : function () {
            utils.ajax(this.apiStartup,{FK_ID_LIVELLO:this.livello,ANNO:this.search.ANNO_ORDINE.VALUE}, (response) => {
                this.arrayUtenti = response.data;
            }); 
        }, 

        nuovoUtente : function(){
            this.utenteSelezionato = {ID_UTENTE:0,persona:{SESSO:""},FK_ID_LIVELLO:this.livello,FK_ID_ENTE:this.$root.idEnteCorrente};
            this.$refs.schedaUtente.openSchedaUtente(this.utenteSelezionato);
        }, 

		modificaUtente: function(utente){
			this.$refs.schedaUtente.openSchedaUtente(utente);
		}, 

        updateStatoUtente : function(utente){
            setTimeout(() => {
                utils.ajax('core/utente/stato/update',utente, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },
        
        resetPassword : function(utente){
            this.utenteSelezionato = utils.clone(utente);
			this.filters.openPopUp("modalPassword"); 
        }, 

        doResetPassword : function(){
			this.filters.closePopUp("modalPassword");
            utils.ajax('core/admin/utente/password/reset',this.utenteSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }  
            }); 
        },
        
        showNote : function(utente){
            this.utenteSelezionato = utils.clone(utente);
			this.filters.openPopUp("modalNote"); 
        },	

		login : function(utente){
			utils.ajax('travel/utente/login',{ID_UTENTE:utente.ID_UTENTE}, (response) => {
				if (response.esito == 0){
					window.location.reload();
				}
			});
		}, 

		datePickerChangeHandler : function(){
			this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
			if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
				this.search.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
			}
		},

		dataCreazioneRangeChangeHandler : function(){
            this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
                this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
            }
        },

		getTipologia: function(utente){
			if(this.ID_UTENTE = 0){
				return 'Privato';
			} 
			else {
				return 'Aziendale';
			} 
		},

		logAzioni : function(utente){	
			this.utenteSelezionato = utils.clone(utente);
			this.filters.openPopUp("popUpLogAzioniUtente"); 			
		},

		aggiornaLogAzioni : function(){
			utils.ajax('core/utente/azioni',{ID_UTENTE:this.utenteSelezionato.ID_UTENTE,DATA_DA:this.searchAzioni.DATA_CREAZIONE.VALUE_FROM,DATA_A:this.searchAzioni.DATA_CREAZIONE.VALUE_TO}, (response) => {
				console.log(response.data);
				this.arrayAzioniUtente = response.data;
			});			
		},	

		apriDettaglioAzione : function(azione){
			this.azioneSelezionata = azione;
			this.filters.openPopUp("popUpDettaglioLogAzioniUtente");	
		},	

		getStileColore : function(utente){
			return {color:utente.COLORE};
		},	

		checkShowRuolo : function(ruolo){			
			if (this.livello != 5){
				if (ruolo.CUSTOM_1 == "" || ruolo.CUSTOM_1 == this.livello){
					return true;
				}
			}
			else{
				if (ruolo.CUSTOM_1 == this.livello){
					return true;
				}
			}
			return false;
		}, 

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneUtente : function(utente,tab = ""){
			this.utenteSelezionato = utils.clone(utente);	
			this.$refs.schedaVisualizzazioneUtente.openSchedaVisualizzazioneUtente(utente,tab);
		},

		getStatisticheOrdiniFatt(){
			utils.ajax('travel/statistiche/ordini/utenti',this.searchStatisticheOrdiniFatt, (response) => {
				this.arrayStatisticheOrdiniFatt = response.data;
			});			
		},
		
        getStatisticheOrdiniChart : function(){
            //$('.grafico').removeAttr("_echarts_instance_");
            var arr = document.getElementsByClassName("grafico");//.removeAttribute("disabled");
            for (var i = 0; i < arr.length; i++){
                arr[i].removeAttribute("_echarts_instance_");
                arr[i].innerHTML = "";
            }
            utils.ajax('travel/statistiche/ordini/importo',this.searchStatisticheOrdini, (response) => {
                var option= {};
                //var chart1 = echarts.init(document.getElementById('chart1'));
                let chart1 = echarts.getInstanceByDom (document.getElementById ('chart1')); // get some words DOM node has echarts instance.
                if (chart1 == null) {// If not, it is initialized.
                    chart1 = echarts.init(document.getElementById('chart1'));
                }

                var arrayDistinctMesi = utils.array.distinct(response.data,"MESE","MESE");
                var arrayDistinctAnni = utils.array.distinct(response.data,"ANNO","ANNO");
                var series = new Array();
                var arrayAnni = [];
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    arrayAnni.push(arrayDistinctAnni[i].ANNO.toString());
                }
                var arrayMesi = [];
                for (var i = 0 ; i < arrayDistinctMesi.length ; i++){
                    arrayMesi.push(utils.array.select(utils.arrayMesi,"ID_MESE",arrayDistinctMesi[i].MESE,1)[0].DESCRIZIONE);
                }
                for (var i = 0 ; i < arrayDistinctAnni.length ; i++){
                    var serie = {
                        name: arrayDistinctAnni[i].ANNO.toString(),
                        type: 'bar',
                        barGap: 0.2,
                        data: []
                    };
                    for (var j = 0 ; j < arrayDistinctMesi.length ; j++){
                        var trovato = 0;
                        for (var k = 0 ; k < response.data.length ; k++ ){
                            if (response.data[k].MESE == arrayDistinctMesi[j].MESE && response.data[k].ANNO == arrayDistinctAnni[i].ANNO){
                            trovato = 1;
                            serie.data.push(parseFloat(response.data[k].IMPORTO));
                            break;
                            }
                        }
                        if (trovato == 0){
                            serie.data.push(0);
                        }
                    }
                    series.push(serie);
                }
                option = {
                    tooltip: {
                        trigger: 'item',
                        formatter : (params) => {
                            return "<strong>" + params.name + " " +  params.seriesName + "</strong><br/>€ "+ utils.formatNumber(params.data) ;
                        }
                    },
                    legend: {
                        data: arrayAnni
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {show: true},
                            magicType: {show: true, type: ['line', 'bar', 'stack', 'tiled']},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: arrayMesi
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel : {
                            formatter : (param) => {
                                return utils.formatNumberInt(param);//"<strong>" + params.name + " " +  params.seriesName + "</strong><br/>"+ params.data ;
                            }
                            },
                        }
                    ],
                    series: series
                };    
                chart1.setOption(option);
                         
            });
        },

		statisticheStartup : function(){
			utils.ajax('travel/statistiche/utenti/startup',{}, (response) => {
				this.statsUtenti = response.data;
				this.getStatisticheOrdiniChart();
			});
		},
        
    },	

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayUtenti,this.search);
            return arr;
        },	 

		paginatedListFatt(){
            var arr = utils.inGrid(this.arrayStatisticheOrdiniFatt,this.searchStatisticheOrdiniFatt);
            return arr;
        },
		
        levelName(){
            switch (this.livello){
                case 1:		return 'Autori';
                case 2:		return 'Amministratori';
                case 3:		return 'Fornitori';
                case 4:		return 'Agenzie';
                default:	return 'Clienti';
            }
			return "";
        },	  

        iconLevel(){
            switch (this.livello){
                case 1:		return 'fa-key';
                case 2:		return 'fa-user';
                case 4:		return 'fa-plane';
                default:	return 'fal fa-box';
            }
			return "";
        }, 

		getTotali(){
			var attive = 0;
            var inattive = 0; 
			for (var i = 0; i < this.arrayUtenti.length; i++) {
				if (this.arrayUtenti[i].FLG_ATTIVO == 1) {
					attive++;
				} else {
					inattive++;
				}
            }
            return {
                COUNT:this.arrayUtenti.length,
				ADVOK:attive,
				ADVKO:inattive
            }
        },

		annoCorrente : function(){
			return new Date().getFullYear();
		},

		annoPrecedente : function(){
			return new Date().getFullYear() - 1;
		}

    },

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.utenteCorrente = utils.clone(this.$root.utente);
		utils.ajax("travel/utenti/startup",{ID_LIVELLO:this.livello}, (response) => {
			this.arrayTipologie = utils.array.select(response.data,"FK_ID_TIPOLOGICA",this.$root.COSTANTI.TIPOLOGICA_CLIENT_TIPOLOGIE);
			this.arrayPosizioni = utils.array.select(response.data,"FK_ID_TIPOLOGICA",this.$root.COSTANTI.TIPOLOGICA_CLIENT_POSIZIONI);
			this.getUtenti();
		}); 
		switch (this.livello){
			case 2:
				this.modulo = 21;
				break;
			case 5:
				this.modulo = 22;
				break;
		}		        
        
    },

}
</script>

<style scoped>
	.grafico { min-height: 451px; }
</style>