<template>

	    <div class="row">
			<div class="col-5 col-lg-2 mb-2"> 
	            <label class="form-label text-danger mb-1">Sesso:</label>
				<select class="form-select form-select-sm select-icons" v-model="persona.SESSO" :tabindex="3"> 
					<option value="" data-icon="chrome" selected="selected">Seleziona</option>
					<option value="1"> - Uomo</option>
					<option value="2"> - Donna</option>
				</select> 
			</div>
	        <div class="col-12 col-lg-5 mb-2"> 
				<label class="form-label text-danger mb-1">Cognome:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.COGNOME" :tabindex="4">
				</div>
	        </div>
	        <div class="col-12 col-lg-5 mb-2">
				<label class="form-label text-danger mb-1">Nome:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.NOME" :tabindex="5">
				</div>               
	    	</div>
		
	        <div class="col-6 col-lg-4 mb-2">
	            <label class="form-label text-danger mb-1">Nazionalit&agrave;:</label>
	         	<Select2 v-model="persona.FK_ID_NAZIONALITA" :w="250" :api="'core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" ::tabindex="2"></Select2>
	        </div>
			<div class="col-6 col-lg-4 mb-2">
	            <label class="form-label text-danger mb-1">Nato a:</label>
				<Select2 v-show="persona.FK_ID_NAZIONALITA == 118" @selecteditem="setCap($event)" v-model="persona.FK_ID_COMUNE_NASCITA" :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'" :tabindex="6"></Select2>   
				<input v-show="persona.FK_ID_NAZIONALITA != 118" autocomplete="off" type="text" v-model="persona.DESCR_COMUNE_NASCITA" class="form-control form-control-sm" placeholder="Comune nascita" :tabindex="2">                                                                                                                
	        </div>      
	        <div class="col-6 col-lg-4 mb-2">
	            <label class="form-label text-danger mb-1">In Data:</label>
                <Datepicker  v-model="persona.DATA_NASCITA" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :tabindex="7"></Datepicker>
	        </div>	
		
			<div class="col-6 col-lg-3 mb-2">
	            <label class="form-label text-danger mb-1">Residente:</label>
	            <select class="form-select form-select-sm" data-placeholder="Residente" v-model="persona.FLG_RESIDENTE_ITALIA" :tabindex="8">
	                <option value="1">Italia</option>
	                <option value="0">Estero</option>
	            </select>
	        </div>	
	        <div class="col-12 col-lg-6 mb-2">
	            <label class="form-label text-danger mb-1">Indirizzo:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-directions fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.INDIRIZZO" :tabindex="10">
				</div> 				
	        </div>
	        <div class="col-5 col-lg-3 mb-2">
	            <label class="form-label text-danger mb-1">Civico:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-sort-numeric-up-alt fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.CIVICO" :tabindex="11">
				</div> 		            
	        </div>  
		
	        <div class="col-7 col-lg-4 mb-2">
	            <label class="form-label text-danger mb-1">Citt&agrave;:</label>				
				<Select2 v-show="persona.FLG_RESIDENTE_ITALIA == 1" @selectedItem="handlerChangeResidenza($event)" v-model="persona.FK_ID_COMUNE" :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'" ::tabindex="9"></Select2>
				<input v-show="persona.FLG_RESIDENTE_ITALIA != 1" autocomplete="off" type="text" v-model="persona.COMUNE" class="form-control form-control-sm" placeholder="Comune nascita" :tabindex="2">
	        </div>
	        <div class="col-6 col-lg-3 mb-2">
	            <label class="form-label text-danger mb-1">Cap:</label>	
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-qrcode fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.CAP" :tabindex="12">
				</div> 	            
	        </div>
	        <div class="col-6 col-lg-2 mb-2">
	            <label class="form-label text-danger mb-1">Provincia:</label>	
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-map-pin fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" :disabled="persona.FLG_RESIDENTE_ITALIA == 1" v-model="persona.PROV" :tabindex="13">
				</div> 	 	                                                                          
	        </div>
	        <div class="col-12 col-lg-3 mb-2">
	            <label class="form-label text-danger mb-1">Cellulare:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-phone fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.TELEFONO" :tabindex="15">
				</div> 	        	
	        </div>
				
	        <div class="col-12 col-lg-4 mb-2">
	            <label class="form-label text-danger mb-1">Email:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.EMAIL" :tabindex="14">
				</div> 
	        </div>
	        <div class="col-12 col-lg-4 mb-2">
	            <label class="form-label text-danger mb-1">Cod. Fiscale:</label>
				<div class="input-group">
					<span class="input-group-text"><i class="far fa-barcode fa-fw"></i></span>
					<input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.CODICE_FISCALE" :tabindex="16">
				</div> 	        	
			</div>
	    </div>

</template>



<script>
import { computed } from 'vue'
import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker';


export default {
    name: 'PersonaFisica',
    components:{
        Select2,
        Datepicker
    },
    props: {
      modelValue: {
          type: Object,
          default: () => ({}),
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const persona = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });
        return { persona };
    },
    data:function(){
        return{
            
        }
    },
    methods: {
        setCap(item){
            this.persona.CAP = item.CAP;
        },
		handlerChangeResidenza : function(val){	
			if (val > 0){
				utils.ajax("core/geo/comune",{ID_COMUNE:val}, (response) => {
					this.persona.PROV = response.data.provincia.DESC_BREVE;
					this.setCap(response.data);
				},false);	
			}		
		}
    },
    computed: {
    
    },
    watch: {
        
    },
}

</script>