const { filters } = require("./filters");
const { utils } = require("./utils");

var t = {
    currentlang:'it',
    dizionario:null,

    translate(lingua,percorso){
        console.log(percorso);
        if (this.dizionario != null){
            var percorsi = percorso.split(".");
            var parziale = this.dizionario;
            for (var i = 0 ; i < percorsi.length ; i++){
                parziale = parziale[percorsi[i]];
                if (parziale == undefined){
                    return "";
                }
            }
            return parziale[lingua];
        }
        else{
            utils.wait(()=>{
                this.translate(lingua,percorso);
            });
        }

    },
    getDizionarioPercorso : function(percorso){
        var percorsi = percorso.split(".");
        var parziale = this.dizionario.dizionario;
        for (var i = 0 ; i < percorsi.length ; i++){
            parziale = parziale[percorsi[i]];
            if (parziale == undefined){
                return null;
            }
        }
        var arrayDefinizioni = new Array();
        console.log(filters.root.arrayLingue);
        Object.keys(parziale).forEach(key => {
            for (var i = 0 ; i < filters.root.arrayLingue.length ; i++){
                var FK_ID_LINGUA = filters.root.arrayLingue[i].ID_GEO_STATO;
                var val = parziale[key][FK_ID_LINGUA];
                arrayDefinizioni.push({DEFINIZIONE:key,VALORE:val,FK_ID_LINGUA:FK_ID_LINGUA,FK_ID_DEFINIZIONE:key,ID_DEFINIZIONE:i.toString()+"_"+FK_ID_LINGUA});
            }
        });
        return arrayDefinizioni;
    },
    getDizionario: function(percorso = null,handler = null){
        global.utils.ajax('travel/dizionario',{}, (response,status) => {
            this.dizionario = response.data;
            filters.root.dizionario = response.data;
            if (percorso != null){
                var arr = global.t.getDizionarioPercorso(percorso);
                handler(arr);
            }
        });
    }
   
};


//module.exports = utils;
exports.t = t;