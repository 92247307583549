<template>

	<!-- Content area -->
	<div class="content content-login justify-content-center align-items-center">
		
		
    </div>


	<!-- Content area -->

</template>


<script>
export default {
    name: 'Install',
    data:function(){
        return {
			GLOBAL_IMAGES_PATH:'',
         
        }
    },

    methods : {
        install(){
            global.utils.ajax('core/login',{USERNAME:this.utente.USERNAME,PASSWORD:this.utente.PASSWORD}, (response) => {
                

            });
        },

		
    },

    created : function(){
		this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;

    }
	
}
</script>