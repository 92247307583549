<template>
        
    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
                    <a href="#" class="d-inline-block text-dark"> Contabilità </a> \
                    <span class="text-muted"> Fatturazione</span>
					<code> - Lingua italiano</code>
                </h4>
                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Fatturazione</h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoFattura(1)"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="statisticheFatture()"><i class="icon-bars-alt"></i><span>STATISTICHE</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-center d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Menu Fatturazione
				</button>
    		</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#product-a" class="navbar-nav-link active" data-bs-toggle="tab">
							<i class="icon-menu7 me-2"></i> Elenco Fatture
						</a>
					</li>
				</ul>


				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item">
						<div class="btn-group btn-group-sm justify-content-center">
							<a href="#" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-cog5 me-1"></i> Azioni Multiple</a>

							<div class="dropdown-menu">
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="icon-trash"></i> Elimina</a>
							</div>
						</div>
					</li>
					<li class="nav-item ms-2">
						<select class="form-control form-control-sm wmin-100 changeyear" id="comboAnno" @change="getPayments()" v-model="search.ANNO_ORDINE.VALUE">
                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
                        </select>
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="product-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body card-body-2">

							<div class="row">
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="me-3 align-self-center">
												<i class="icon-file-stats icon-3x text-success"></i>
											</div>
				
											<div class="media-body text-right">
												<h3 class="font-weight-semibold mb-0">&euro; 652,549</h3>
												<span class="text-uppercase fs-12 text-muted">Fatturato totale</span>
											</div>
										</div>
									</div>
								</div>
				
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="me-3 align-self-center">
												<i class="icon-coin-euro icon-3x text-indigo"></i>
											</div>
				
											<div class="media-body text-right">
												<h3 class="font-weight-semibold mb-0">&euro; 245,382</h3>
												<span class="text-uppercase fs-12 text-muted">Fatture pagate</span>
											</div>
										</div>
									</div>
								</div>
				
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="media-body">
												<h3 class="font-weight-semibold mb-0">&euro; 54,390</h3>
												<span class="text-uppercase fs-12 text-muted">Fatture in attesa</span>
											</div>
				
											<div class="ms-3 align-self-center">
												<i class="icon-calendar52 icon-3x text-primary"></i>
											</div>
										</div>
									</div>
								</div>
				
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="media-body">
												<h3 class="font-weight-semibold mb-0">&euro; 389,438</h3>
												<span class="text-uppercase fs-12 text-muted">Fatture stornate</span>
											</div>
				
											<div class="ms-3 align-self-center">
												<i class="icon-blocked icon-3x text-danger"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
				

	                        <div class="alert alert-2 alert-secondary alert2 border-0">
								<form action="#" class="">
		                            <div class="row ">	
	                                    <div class="col-6 col-md-1 form-group-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id fattura" :tabindex="2" v-model="search.ID_ORDINE.VALUE" />
	                                    </div> 
	                                    <div class="col-6 col-md-1 form-group-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Pnr pratica" :tabindex="2" v-model="search.PNR.VALUE" />
	                                    </div>  
	                                    <div class="col-12 col-md-2 form-group-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Intestatario" :tabindex="3" v-model="search.INTESTATARIO.VALUE" />
	                                    </div>	 
	                                    <div class="col-12 col-md-2 form-group-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" :tabindex="3" v-model="search.TITOLO_PRATICA.VALUE" />
	                                    </div> 
										<div class="col-12 col-md-6">
	                                        <div class="row">
	                                            <div class="col-6 col-md-3 form-group-1">
	                                                <div class="input-group">
	                                                    <span class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar22"></i></span></span>
	                                                    <DatePicker class="form-control form-control-sm" aria-placeholder="Data partenza da"></DatePicker> 
	                                                </div> 
	                                            </div>
	                                            <div class="col-6 col-md-3 form-group-1">
	                                                <div class="input-group">
	                                                    <DatePicker class="form-control form-control-sm" aria-placeholder="Data partenza a"></DatePicker> 
	                                                    <span class="input-group-append"><span class="input-group-text"><i class="icon-calendar22"></i></span></span>
	                                                </div>
	                                            </div> 
		                                        <div class="col-12 col-md-2 form-group-1">
	                                                <select class="form-control form-control-sm" data-placeholder="Saldo" tabindex="4"> 
	                                                    <option>Movimento</option> 
	                                                    <option v-bind:value="0"> - Banca</option> 
	                                                    <option v-bind:value="1"> - Contanti</option>
	                                                </select>
	                                            </div>
						                        <div class="col-12 col-md-3 form-group-1">
						                            <select class="form-control form-control-sm" data-placeholder="Stato Fatture">
					                                    <option>Stato Fatture</option> 
					                                    <option v-bind:value="0"> - Da Emettere</option> 
					                                    <option v-bind:value="1"> - Emessa</option>                                                                    
						                            </select>
						                        </div>
												<div class="col-md-1 form-group-1">
													<a href="#collapse-icon" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="12">
														<span style="font-weight: 500;"><i class="far fa-plus fa-fw"></i></span>
													</a>
												</div>  
	                                        </div>
	                                    </div>  
	                                </div>
	                                <div class="collapse" id="collapse-icon" style="">
	                                    <div class="row">
	                                        <div class="col-md-2 form-group-1">
	                                            <select class="form-control form-control-sm" data-placeholder="Tipologia booking" tabindex="4" v-model="search.FK_ID_TIPO.VALUE">
	                                                <option v-bind:value="0">Booking</option> 
	                                                <option v-bind:value="1"> - Booking x Agenzia</option> 
	                                                <option v-bind:value="2"> - Booking x Cliente</option> 
	                                                <option v-bind:value="3"> - Agenzia Diretta</option> 
	                                                <option v-bind:value="4"> - Cliente Diretto</option>
	                                            </select>
	                                        </div>
	                                        <div class="col-6 col-md-2 form-group-1">
	                                            <select class="form-control form-control-sm" data-placeholder="Promotore" :tabindex="7">
	                                                <option value="">Promotore </option>   
	                                            </select>
	                                        </div>
	                                        <div class="col-6 col-md-2 form-group-1">
	                                            <select class="form-control form-control-sm" data-placeholder="Agente" :tabindex="5">
	                                                <option>Agente</option>
	                                            </select>
	                                        </div>
	                                        <div class="col-6 col-md-2 form-group-1">
	                                            <select class="form-control form-control-sm" data-placeholder="Sito Web" :tabindex="5">
	                                                <option>Sito</option>
	                                            </select>
	                                        </div>
	                                        <div class="col-6 col-md-4 form-group-1">
	                                        </div>
	                                    </div>
	                                </div>
	                        	</form>
	                        </div>
	                    </div>
	                </div>
	
	                <div class="card-body card-body-2 card-body-nobottom card-body-notop">
	                    <div class="tableFixHead">
	                        <table class="table table-bordered table-hover table-striped table-togglable"> 
	                            <thead>
	                                <tr>
	                                    <th class="table-head-sm" style="width: 2%;"> <input id="chkAllProducts" v-on:click="filters.selectAll('chkAllProducts',paginatedList.arr)" type="checkbox" class=""> </th>
	                                    <th class="table-head-sm text-center" style="width: 6%;"> # </th>
	                                    <th class="table-head-sm text-center" style="width: 7%;">Data Emissione</th>
	                                    <th class="table-head-sm" style="width: 19%;"> Cliente </th>
	                                    <th class="table-head-sm text-center"> Descrizione </th>
	                                    <th class="table-head-sm text-center" style="width: 8%;"> Importo </th>
	
										<th class="table-head-sm text-center" style="width: 7%;">Data scadenza</th>
	                                    <th class="table-head-sm text-center" style="width: 10%;">Stato</th>
										<th class="table-head-sm text-center" style="width: 6%;">Catturata</th>
	                                    <th class="table-head-sm text-center" style="width: 5.4%;"> Azioni </th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="fatture in paginatedList.arr" v-bind:key="fatture.ID_ORDINE">
	                                    <td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="fatture.SELEZIONATO" class=""> </td>
	                                    <td class="text-center f-08">
											{{filters.getPnr(fatture)}}
										</td>
	                                    <td class="text-center f-08">{{filters.formatDate(fatture.DATA_CREAZIONE)}}</td>
	                                    <td class="">
	                                        <span class="badge" v-bind:class="getTipoBookClass(fatture)">{{getTipoBook(fatture)}}</span> - 
	                                        <span class="text-secondary fw-bold" v-show="fatture.FK_ID_AGENCY > 0">{{ fatture.AGENZIA }}</span>
	                                        <span class="text-secondary fw-bold" v-show="fatture.FK_ID_AGENCY == 0 && fatture.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">{{ fatture.INTESTATARIO_PERSONA_FISICA }}</span>
	                                        <span class="text-secondary fw-bold" v-show="fatture.FK_ID_AGENCY == 0 && fatture.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">{{ fatture.INTESTATARIO_PERSONA_GIURIDICA }}</span>
	                                    </td>
	                                    <td class="text-center f-08"></td>
	
	
	
	                                    <td class="text-center"><strong>&euro; {{fatture.PREZZO_TOTALE}} </strong></td>
										<td class="text-center"> Bonifico </td>
	
	                                    <td class="text-center">
	                                        <span class="badge badge-danger cursor-pointer">Da pagare</span>
	                                    </td>        
	                                    <td class="text-center">
	                                        <span class="badge badge-warning cursor-pointer" v-on:click="openCatturaFattura()"><i class="far fa-thumbs-down"></i></span>
	                                    </td>
	                                    <td class="text-center">
	                                        <div class="list-icons">
	                                            <div class="dropdown">
	                                                <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
	                                                <div class="dropdown-menu dropdown-menu-right">
	                                                    <a href="#" class="dropdown-item" v-on:click="openFattura(fatture)"><i class="far fa-pencil fa-fw"></i> Modifica fattura</a>
	                                                    <div class="dropdown-divider"></div>
	                                                    <a href="#" class="dropdown-item" v-on:click="openFattura(fatture)"><i class="far fa-eye fa-fw"></i> Anteprima fattura</a>
	                                                    <a href="#" class="dropdown-item" v-on:click="openFattura(fatture)"><i class="far fa-paper-plane fa-fw"></i> Invia fattura</a>
	                                                    <div class="dropdown-divider"></div>
	                                                    <a href="#" class="dropdown-item" v-on:click="deleteFattura(fatture)"><i class="far fa-copy fa-fw text-danger"></i> Copia fattura</a>
	                                                    <a href="#" class="dropdown-item" v-on:click="deleteFattura(fatture)"><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina fattura</a>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
		                        </div>
		                    </div>
		                    <div class="col-4 col-md-1" :tabindex="28">
		                        <select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
		                    </div>
			       		</div>
	
	                </div>
	            </div>
			</div>		
	
		</div>
	</div>


	<!-- Modal -->
    <div class="modal fade" id="popUpCatturaFattura" role="dialog"  aria-modal="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> IMPORTA DATI NEL GESTIONALE </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">  

                    <p class="text-center" style="font-size: 14px;">
                        <span id="modal_status_subtitle">sei sicuro di voler importare questa Pratica nel tuo Gestionale?</span> <br> 
                        <span id="modal_status_accept">Proseguendo, la Pratica e le relative Quote, saranno importate nel tuo Gestionale.</span>
                    </p> 
                    <hr> 
                    <div class="row">
                        <div class="col-md-6">
                            <h6><strong>1.</strong> Seleziona Gestionale</h6> 
                            <div class="form-group">
                                <select name="order_status" class="form-control form-control-sm" disabled="disabled">
                                    <option value="easy">Cattura Easy</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <h6><strong>2.</strong> Scegli Operazione</h6>
                            <div class="form-group">
                                <select class="form-control form-control-sm" disabled="disabled">
                                    <option value="Set_Pratica">Importa pratica</option> 
                                    <option value="Set_Quota">Importa quota</option> 
                                    <option value="Set_Fatture">Importa incasso</option> 
                                    <option value="anagrafica">Importa anagrafica</option> 
                                    <option value="voucher">Importa voucher</option>
                                </select>
                            </div>
                        </div> 
                    </div>

                </div>
                <div class="modal-footer bg-light">
                    Vuoi Procedere&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">NO ESCI</button>&nbsp;
                    <button type="button" class="btn btn-success btn-sm">SI IMPORTA</button><!--  v-on:click="sendStatus()" -->
                </div>
            </div>
        </div>
    </div>  


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import DatePicker from '@/components/utils/DatePicker.vue'

export default {
    name:"Invoice",		

    components:{
        Pagine,
        DatePicker,
    },

    data:function(){
        return {
            apiStartup:'travel/fatture',
            filters:global.filters,
            arrayFatture:new Array(),
            fatturaSelezionata:{},
			GLOBAL_IMAGES_PATH:'',
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_ORDINE:{TYPE:'TEXT',VALUE:''},
                PNR:{TYPE:'TEXT',VALUE:''},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
                CODICE_ORDINE:{TYPE:'TEXT',VALUE:''},
                FK_ID_STATO:{TYPE:'COMBO',VALUE: 0},
	            INTESTATARIO:{TYPE:'TEXT',VALUE:''}, 
	            TITOLO_PRATICA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''},
                FK_ID_FORNITORE:{TYPE:'COMBO',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
        }
    },

    methods:{
        getFatture:function(){
            utils.ajax(this.apiStartup,{ANNO_ORDINE:this.search.ANNO_ORDINE.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayFatture = response.data;
                }
            });  
        },

        nuovaFattura : function(){ 
			this.fatturaSelezionata = {ID_FATTURA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
            this.filters.openPopUp("popUpFattura");
        },

        modificaFattura : function(fattura){
            this.fatturaSelezionata = utils.clone(fattura);
			this.filters.openPopUp("popUpFattura"); 
        },

        salvaFattura : function(){
            var api = this.fatturaSelezionata.ID_FATTURA > 0 ? "travel/fatture/update" : "travel/fatture/insert";
            utils.ajax(api,this.fatturaSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpFattura");  
                    utils.ajax(this.apiStartup,{}, (response) => {
                        this.arrayFatture = response.data;
                    });
                }
            });
        }, 

       	openFattura:function(fatture){
            this.$root.fatturaSelezionata = utils.clone(fatture);
            global.router.push("Fattura");
        },
		
        getBtnStatusClass : function(fatture){
            switch (fatture.FK_ID_STATO){
                case 1:
                    return 'badge-success'
                case 2:
                    return 'badge-primary'
                case 3:
                    return 'badge-info'
				case 4:
                    return 'badge-warning'
                case 5:
                    return 'badge-yellow'
                case 6:                
                    return "badge-secondary";
                case 7:
                case 8:
                    return 'badge-danger'
                default :
                    return 'badge-light'
            }
        },

        openStatoFattura : function(){
			this.fatturaSelezionata = {ID_ORDINE:0};
			this.filters.openPopUp('#popUpStatoFattura');
        },

        openCatturaFattura : function(){
            this.fatturaSelezionata = {ID_ORDINE:0};
			this.filters.openPopUp("popUpCatturaFattura");
        },	  

        openSchedaFattura : function(fatture){
            this.$refs.schedaFattura.openSchedaFattura(fatture);
        }

    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayFatture,this.search);
	      	return arr;
        },
    }, 

    created : function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.fatturaCorrente = utils.clone(this.$root.fattura);
        utils.ajax(this.apiStartup,{}, (response) => {
           this.arrayFatture = response.data;
        });
    },

}
</script>