<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
					<a href="#" class="text-dark fw-semibold" v-on:click="getProdotto()"> Prodotto </a> \
					<router-link href="#" class="text-dark cursor-pointer" :to="'Hotels'"> Elenco Hotels </router-link> \
                    <a href="#" class="text-dark"> Link Nome Hotel </a> \
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Camere'"> Elenco Camere </router-link> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Varianti</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovoGruppoTipologiche()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1">Nuova Variante</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(5)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#varianti-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Varianti</span>
							</div>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getVarianti()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i> Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i> Activity log
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-trash me-2"></i> Elimina
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getProdotti()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
								<option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
							</select>	
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="varianti-a">
		        <div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="variante-a">
			                        <div class="row">           
			                            <div class="col-5 col-md-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Variante" :tabindex="1" v-model="search.ID_VARIANTE.VALUE" />
			                            </div>           
			                            <div class="col-7 col-md-1">
			
			                            </div>                
			                            <div class="col-12 col-md-2">
			
			                            </div>
			                            <div class="d-none d-sm-block col-md-3">
			
			                            </div>
			                            <div class="col-8 col-md-2">
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div>
												<div class="col-6 col-md-5">
					                                <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="4">
					                                    <option value="">Tutti </option>   
					                                    <option value="1"> &nbsp; Attivati</option>
					                                    <option value="0"> &nbsp; Disattivati</option>
					                                </select>
					                            </div>
												<div class="col-6 col-md-4">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
					                            </div>  
					                        </div>
										</div>
					                </div>
								</form>
				            </div>
		                </div>
		            </div>
							 
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllVarianti" v-on:click="filters.selectAll('chkAllVarianti',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> # </th>
			                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
			                            <th class="table-head-sm">Nome Prodotto /<br />SEO / Codice</th>
			                            <th class="table-head-sm" style="width:12%;">Valido Dal /<br />Valido Al</th>
			                            <th class="table-head-sm" style="width:15%;">Tipo azione</th>
			                            <th class="table-head-sm" style="width:5%;">Tipo pax</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
		                            <tr v-for="variante in paginatedList.arr" v-bind:key="variante.ID_VARIANTE">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="variante.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center cursor-pointer"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="td-class fs-sm">
											<span>{{variante.ID_VARIANTE}}</span><br />
											<small class="badge bg-light text-primary float-end"> </small>
										</td>
		                                <td class="td-class">
										</td>
		                                <td class="td-class fs-sm">
		                                    <span v-on:click="modificaVariante(variante)" class="text-primary fw-bold cursor-pointer">{{variante.CODICE_VARIANTE}}</span><br />
		                                    <span class="badge bg-dark me-1">a</span> 
		                                    <span class="badge bg-success me-1">Prenotabile</span> 
		                                    <span class="badge bg-info me-1">Metti in Evidenza</span> 
		                                    <span class="badge bg-success">Visibile</span> 
		                                </td>
		                                <td class="td-class fs-sm">{{filters.formatDate(variante.DATA_START)}}<br />{{filters.formatDate(variante.DATA_END)}}</td>
		                                <td class=""><span class="badge" v-bind:class="{'bg-success': (variante.TIPO_AZIONE == 'MINUS_VAL' || variante.TIPO_AZIONE == 'MINUS_PERC'),'bg-info':(variante.TIPO_AZIONE == 'FIXED_VAL'), 'badge-danger': (variante.TIPO_AZIONE == 'PLUS_VAL' || variante.TIPO_AZIONE == 'PLUS_PERC')}">{{variante.TARGET}} |  {{variante.TIPO_AZIONE}}  | {{variante.VALORE}}</span></td>
		                                <td class="">{{variante.PAX_TYPE}}</td>
		                                <td class="">
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <a href="#" class="dropdown-item" v-on:click="modificaVariante(variante)"><i class="fal fa-pencil fa-fw"></i> Modifica Variante</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="copiaVariante(variante)"><i class="fal fa-copy fa-fw"></i> Copia Variante</a>
													<div class="dropdown-divider"></div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteVariante(variante)"><i class="fal fa-trash-alt fa-fw"></i> Elimina Variante</a>
	                                            </div>
	                                        </div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
	                    </div>
		
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
	                		</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
	            			</div>
	        			</div>
					</div>
				</div>	   
    		</div>	
 
		</div>    
	</div>


	<!-- START MODALI -->
	<div id="popUpVariante" class="modal fade" data-backdrop="true" aria-modal="true" role="dialog" tabindex="-1">
		<div class="modal-dialog modal-lg">
			<div class="modal-content" >
				<div class="modal-header bg-secondary text-white">
					<h6 class="modal-title pt-1"> SCHEDA VARIANTE - </h6>
					<div class="btn-group float-end">
						<button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="fa fa-bolt fa-fw"></i> AZIONI
						</button>
						<div class="dropdown-menu dropdown-menu-right">
							<a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
							<a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
							<a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
						</div>
					</div>
				</div>
				<div class="modal-body-2">	

					<div class="row">
						<div class="col-12 col-lg-3 mb-3 pr-0">

							fdsfsdfs

						</div>
						<div class="col-12 col-lg-9">                     
							<ul class="nav nav-tabs mb-2 nav-tabs-highlight" id="myTab" role="tablist">
								<li class="nav-item">
									<a class="nav-link active" id="main-tab" data-bs-toggle="tab" href="#main" role="tab" aria-controls="main" aria-selected="true">
										<i class="far fa-hotel fa-fw"></i> <span class="d-none d-sm-inline"><strong>Generale </strong></span>
									</a>
								</li> 
							</ul>
							<div class="tab-content profile-tab" id="myTabContent">

								<div class="tab-pane fade show active" id="main" role="tabpanel" aria-labelledby="main-tab">
									<div class="box_450">

										<div class="row mt-1">
											<div class="col-6 col-md-6">
												<label class="text-danger custom-control custom-control-right">Titolo Variante
													<a class="pop_over" href="#" data-popup="popover" title="" data-html="true" data-content="Add <u>some</u> <i>HTML</i> <strong>stuff</strong>." data-original-title="Popover <span class='text-danger'>title</span>" aria-describedby="popover877761"><i class="far fa-info-circle float-end" aria-hidden="true"></i></a>
												</label>
												<div class="input-group">
													<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Codice" tabindex="2" v-model="varianteSelezionato.CODICE_VARIANTE">
												</div>
											</div>
											<div class="col-6 col-md-3">
												<label class="text-danger custom-control custom-control-right">Valido Dal</label>
												<Datepicker class="form-control form-control-sm" v-model="varianteSelezionato.DATA_START"></Datepicker>
											</div>
											<div class="col-6 col-md-3">
												<label class="text-danger custom-control custom-control-right">Valido Al</label>
												<Datepicker class="form-control form-control-sm" v-model="varianteSelezionato.DATA_END"></Datepicker>
											</div>
										</div>
										<div class="row mt-1">
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Tipo pax</label>
												<select class="form-control form-control-sm"   v-model="varianteSelezionato.PAX_TYPE">
													<option v-bind:value="'ALL'">ALL</option>
													<option v-bind:value="'INF'">INF</option>
													<option v-bind:value="'CHD'">CHD</option>
													<option v-bind:value="'ADU'">ADU</option>
												</select>
											</div> 
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">N° Pax<i class="fal fa-asterisk" style="color:#1cc88a"></i></label>
												<select class="form-control form-control-sm" v-model="varianteSelezionato.PAX_NUM">
													<option value="0">Tutti</option>
													<option v-for="i in 10" v-bind:value="i" v-bind:key="i">{{i}}</option>
												</select>
											</div>
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Cumulabile</label>
												<select class="form-control form-control-sm"   v-model="varianteSelezionato.CUMULABILE">
													<option v-bind:value="1">SI</option>
													<option v-bind:value="0">NO</option>
												</select>
											</div> 
										</div>
										<div class="row">
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Early booking data</label>
												<Datepicker :dim="'sm'"  v-model="varianteSelezionato.EARLYB_DATA"></Datepicker>
											</div> 
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Early booking giorni</label>
												<select class="form-control form-control-sm" v-model="varianteSelezionato.EARLYB_GIORNI">
													<option value="0">Tutti</option>
													<option v-for="i in 100" v-bind:value="i" v-bind:key="i">{{i}}</option>
												</select>
											</div>
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Min stay</label>
				
												<select class="form-control form-control-sm" v-model="varianteSelezionato.MIN_STAY">
													<option value="0">Nessuna condizione</option>
													<option v-for="i in 31" v-bind:value="i" v-bind:key="i">{{i}}</option>
												</select>
											</div>
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Anno nascita min</label>
												<select class="form-control form-control-sm"   v-model="varianteSelezionato.ANNO_NASCITA_MIN">
													<option v-for="i in 120" v-bind:value="i + (new Date().getFullYear() - 120)" v-bind:key="i">{{new Date().getFullYear() - 120 + i}}</option>
												</select>
											</div>
											<div class="form-group col-md-2" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Anno nascita max</label>
												<select class="form-control form-control-sm"   v-model="varianteSelezionato.ANNO_NASCITA_MAX">
													<option v-for="i in 120" v-bind:value="i + (new Date().getFullYear() - 120)" v-bind:key="i">{{new Date().getFullYear() - 120 + i}}</option>
												</select>
											</div>
											<div class="form-group col-md-1" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Età min</label>
												<select class="form-control form-control-sm" v-model="varianteSelezionato.ETA_MIN">
													<option value="0">0</option>
													<option v-for="i in 100" v-bind:value="i + 1" v-bind:key="i">{{1 + i}}</option>
												</select>
											</div>
											<div class="form-group col-md-1" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Età max</label>
												<select class="form-control form-control-sm" v-model="varianteSelezionato.ETA_MAX">
													<option value="0">0</option>
													<option v-for="i in 100" v-bind:value="i + 1" v-bind:key="i">{{1 + i}}</option>
												</select>
											</div>
										</div> 
										<div class="row">
											<div class="form-group col-md-3" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Target</label>
												<select class="form-control form-control-sm" v-model="varianteSelezionato.TARGET">
													<option v-bind:value="'PREZZO'">PREZZO</option>
													<option v-bind:value="'COSTO'">COSTO</option>
													<option v-bind:value="'MARKUP'">MARKUP</option>
													<option v-bind:value="'QUOTA'">QUOTA</option>
												</select>
											</div>
											<div class="form-group col-md-3" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Tipo operazione</label>
												<select class="form-control form-control-sm" v-model="varianteSelezionato.TIPO_AZIONE">
													<option v-bind:value="'MINUS_PERC'">MINUS_PERC</option>
													<option v-bind:value="'MINUS_VAL'">MINUS_VAL</option>
													<option v-bind:value="'PLUS_PERC'">PLUS_PERC</option>
													<option v-bind:value="'PLUS_VAL'">PLUS_VAL</option>
													<option v-bind:value="'FIXED_PRICE'">FIXED_PRICE</option>
												</select>
											</div>
											<div class="form-group col-md-3" style="margin-bottom:.75rem">
												<label style="color:#008a59;font-weight:bold">Valore</label>
												<input type="text" class="form-control form-control-sm" placeholder="Codice" v-model="varianteSelezionato.VALORE">
											</div>
											<div class="form-group col-md-3" style="margin-bottom:.75rem" v-show="varianteSelezionato.ID_VARIANTE == 0 && varianteSelezionato.TARGET == 'PREZZO'">
												<input type="checkbox" class="form-control form-control-sm" true-value="1" false-value="0" placeholder="Codice" v-model="varianteSelezionato.FLG_COSTO">Crea variante anche per il costo
											</div>
										</div> 											

									</div>
								</div>
									
							</div>
						</div>
					</div>

				</div>
				<div class="modal-footer mt-2 pt-2" style="border: 1px solid #eee;">
					<button class="btn btn-success float-end" v-on:click="salvaVariante()">SALVA MODIFICHE</button>
					<button type="button" class="btn btn-light" data-bs-dismiss="modal">CHIUDI</button>&nbsp;&nbsp;&nbsp;                                         
				</div>
			</div>
		</div>
	</div>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Datepicker from '@vuepic/vue-datepicker' 


export default {
    name:"Varianti",  

    components:{
        Pagine,
        draggable: VueDraggableNext,
		Datepicker,
    }, 

    data:function(){
        return {
            apiStartup:'travel/varianti',
            filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayVarianti:new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_VARIANTE:{TYPE:'TEXT',VALUE:''},
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},

            },
            selezionaTutto:0,
            prodotto:{},
            varianteSelezionato:{}
        }
    },

    methods : {
		getVarianti : function(){
			utils.ajax(this.apiStartup,{ID_VARIANTE:this.$root.prodotto.ID_VARIANTE,ANNO:this.search.ANNO_ORDINE.VALUE},(response)=>{
				this.arrayVarianti = response.data;
			});
		},

        nuovaVariante : function(){
            this.varianteSelezionato = {
                ID_VARIANTE:0,
                PAX_TYPE:'ALL',
                PAX_NUM:0,
				FLG_COSTO:0,
                FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO,
                DATA_START:moment().format("DD/MM/YYYY"),
                DATA_END:moment().format("DD/MM/YYYY"),
                ANNO_NASCITA_MIN:new Date().getFullYear()-119,
                ANNO_NASCITA_MAX:new Date().getFullYear(),
                ETA_MIN:0,
                ETA_MAX:100,
                ORDINE:0,
                TIPO_AZIONE:'MINUS_PERC',
                VALORE:0,
                CUMULABILE:1,
                MIN_STAY:0
            };
			this.filters.openPopUp('#popUpVariante');
        },

        salvaVariante : function(){
            var api = this.varianteSelezionato.ID_VARIANTE == 0 ? "travel/variante/insert" : "travel/variante/update"; 
            utils.ajax(api,this.varianteSelezionato,(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.varianteSelezionato.ID_VARIANTE = response.id_inserito;
					this.filters.closePopUp("popUpVariante"); 
					this.getVarianti();
                }
            });    
        },	

        copiaVariante : function(variante){
            console.log(variante);
            var rCopy = utils.clone(variante);
            rCopy.ID_VARIANTE = 0;
            rCopy.DATA_START = filters.formatDate(rCopy.DATA_START);
            rCopy.DATA_END = filters.formatDate(rCopy.DATA_END);
            this.varianteSelezionato = rCopy;
			this.filters.openPopUp("popUpVariante"); 
        },

        modificaVariante : function (variante) {
            utils.ajax("travel/variante",{ID_VARIANTE:variante.ID_VARIANTE},(response)=>{
                this.varianteSelezionato = response.data;
				this.filters.openPopUp("popUpVariante"); 
            });
        },	

        deleteVariante : function(variante){      
            utils.alert.confirm("Sei sicuro di voler eliminare la variante selezionata?",
                ()=>{
                    utils.ajax("travel/variante/delete",variante,(response)=>{
                        if (response.esito == 0){
                            utils.alert.success(response.messaggio);
                            for (var i = 0; i < this.arrayVarianti.length ; i++) {
                                if (this.arrayVarianti[i].ID_VARIANTE == variante.ID_VARIANTE){
                                    this.arrayVarianti.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    });                
                },
            this.$root);
        },

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaProdotti',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayVarianti = this.filters.draggableChangeHandler(event,this.arrayVarianti,this.search,"ID_VARIANTE");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayVarianti.length ; i++){
					arr.push({"ID": this.arrayVarianti[i].ID_VARIANTE,"O": this.arrayVarianti[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'ProdottoTuristico',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getVarianti();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		
    },	  

    computed:{
        paginatedList(){
            var arr = utils.inGrid(this.arrayVarianti,this.search);
            return arr;
        }

    },		

    created : function(){
        utils.ajax("travel/varianti/startup",{ID_PRODOTTO:this.$root.prodotto.ID_PRODOTTO},(response)=>{
            this.prodotto = response.data.PRODOTTO;
            this.arrayVarianti = response.data.VARIANTI;
            if (this.prodotto.sottoProdotti.length > 0){
                this.sottoProdottoCorrente = this.varianteSelezionato.sottoProdotti[0].ID_PRODOTTO;
            }
            
        });
    }
}
</script>