<template>
        
	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
                    <a href="#" class="text-dark fw-semibold" v-on:click="getTickets()"> Generico </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Tickets</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovoTicket()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Ticket</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div> 
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#tickets-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Pratiche</span>
							</div>
						</a>
					</li>
				</ul>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i> Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i> Activity log
								</a>
								<a href="#" class="dropdown-item" v-on:click="deleteTicket(ticket)">
									<i class="ph-trash me-2"></i> Elimina
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getUtenti()" v-model="search.ANNO.VALUE" :tabindex="4">
								<option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
							</select>	
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

	<!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="tickets-a">
				<div class="row">
					<div class="col-sm-6 col-xl-3">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center mb-3">
								<div class="flex-fill">
									<h6 class="mb-0">Server maintenance</h6>
									<span class="text-muted">Until 1st of June</span>
								</div>

								<i class="ph-gear ph-2x text-indigo opacity-75 ms-3"></i>
							</div>

							<div class="progress mb-2" style="height: 0.125rem;">
								<div class="progress-bar bg-indigo" style="width: 67%"></div>
							</div>

							<div>
								<span class="float-end">67%</span>
								Re-indexing
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-3">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center mb-3">
								<div class="flex-fill">
									<h6 class="mb-0">Services status</h6>
									<span class="text-muted">April, 19th</span>
								</div>

								<i class="ph-activity ph-2x text-danger opacity-75 ms-3"></i>
							</div>

							<div class="progress mb-2" style="height: 0.125rem;">
								<div class="progress-bar bg-danger" style="width: 80%"></div>
							</div>
							
							<div>
								<span class="float-end">80%</span>
								Partly operational
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-3">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center mb-3">
								<i class="ph-gear ph-2x text-primary opacity-75 me-3"></i>

								<div class="flex-fill">
									<h6 class="mb-0">Server maintenance</h6>
									<span class="text-muted">Until 1st of June</span>
								</div>
							</div>

							<div class="progress mb-2" style="height: 0.125rem;">
								<div class="progress-bar bg-primary" style="width: 67%"></div>
							</div>
							
							<div>
								<span class="float-end">67%</span>
								Re-indexing
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-xl-3">
						<div class="card card-body mb-2">
							<div class="d-flex align-items-center mb-3">
								<i class="ph-activity ph-2x text-success opacity-75 me-3"></i>

								<div class="flex-fill">
									<h6 class="mb-0">Services status</h6>
									<span class="text-muted">April, 19th</span>
								</div>
							</div>

							<div class="progress mb-2" style="height: 0.125rem;">
								<div class="progress-bar bg-success" style="width: 80%"></div>
							</div>
							
							<div>
								<span class="float-end">80%</span>
								Partly operational
							</div>
						</div>
					</div>
				</div>

        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="ticket-a">
		                            <div class="row ">	
	                                    <div class="col-6 col-md-1 col-lg-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Ticket" :tabindex="2" v-model="search.ID_TICKET.VALUE" />
	                                    </div> 
										<div class="col-12 col-md-2 col-lg-2">
											<select class="form-select form-select-sm" data-placeholder="Tipo">
												<option value="">Seleziona Autore</option>
											</select>
										</div>
	                                    <div class="col-12 col-md-2 col-lg-2">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Titolo" :tabindex="4"  />
	                                    </div>	 
	                                    <div class="col-12 col-md-2 col-lg-2">
											<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />
										</div> 
										<div class="col-12 col-md-6 col-lg-5">
	                                        <div class="row">
												<div class="col-12 col-md-2 col-lg-2">
											   </div>
						                        <div class="col-12 col-md-4">
													<select class="form-select form-select-sm" data-placeholder="Tipo">
														<option value="">Seleziona Priorit&agrave;</option>
													</select>
						                        </div>
                                                <div class="col-12 col-md-4">
													<select class="form-select form-select-sm" data-placeholder="Tipo">
														<option value="">Seleziona Stato</option>
													</select>
	                                            </div>
												<div class="col-md-2 col-lg-2">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>  
	                                        </div>
	                                    </div>  
	                                </div>
		                            <div class="collapse" id="collapse-a" style="">
	                                    <div class="row">
											<div class="col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Tipologia booking" tabindex="4" >
													<option v-bind:value="0">Booking</option> 
												</select>
											</div>
											<div class="col-6 col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Promotore" :tabindex="7">
													<option value="">Promotore </option>   
												</select>
											</div>
											<div class="col-6 col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Agente" :tabindex="5">
													<option>Agente</option>
												</select>
											</div>
											<div class="col-6 col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Sito Web" :tabindex="5">
													<option>Sito</option>
												</select>
											</div>
											<div class="col-6 col-md-4">
											</div>
	                                    </div>
	                                </div>
	                        	</form>
	                        </div>
	                    </div>
	                </div>
		
			        <div class="card-body mt-0 mb-0">
		                <div class="box-ticket">
	                        <table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
	                                    <th class="table-head-sm" style="width: 2%;"> <input id="chkAllTickets" v-on:click="filters.selectAll('chkAllTickets',paginatedList.arr)" type="checkbox" class=""> </th>
	                                    <th class="table-head-sm" style="width: 6%;"> # </th>
										<th class="table-head-sm" style="width: 8%;">Data creazione</th>
	                                    <th class="table-head-sm"> Titolo </th>
										<th class="table-head-sm" style="width: 10%;">Autore</th>
	                                    <th class="table-head-sm text-center" style="width: 8%;">Priorit&agrave;</th>
	                                    <th class="table-head-sm" style="width: 10%;"> Assegnato a </th>
										<th class="table-head-sm" style="width: 8%;">Data rilascio</th>
										<th class="table-head-sm text-center" style="width: 5%;">Stato</th>
	                                    <th class="table-head-sm text-center" style="width: 5%;"> Azioni </th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="ticket in paginatedList.arr" v-bind:key="ticket.ID_TICKET">
	                                    <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="ticket.SELEZIONATO" class=""> 
										</td>
	                                    <td class="td-class fs-sm">
											<span>{{ticket.ID_TICKET}}</span><br />
											<small class="badge bg-light text-primary float-end"></small>
										</td>
	                                    <td class="td-class fs-sm">
	                                        <span class="text-primary text-uppercase fw-bold cursor-pointer"></span>
		                                    <br/>
										</td>
	                                    <td class="td-class text-center fs-sm">
											
										</td>
	                                    <td class="td-class text-center fs-sm">
											
										</td>
										<td class="text-center">
											<h6 class="fs-sm fw-bold mb-0">
												
											</h6>
										</td> 
										<td class="fs-sm text-center">
											
										</td>
	                                    <td class="fs-sm text-center">
	                                        <span class="badge badge-xs cursor-pointer">
												
											</span>
	                                    </td> 
	                                    <td class="td-class fs-sm"> 

										</td>  
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Pratica</div>
	                                                <a href="#" class="dropdown-item" v-on:click="openTicket(ticket)"><i class="fad fa-pencil fa-fw ms-2 me-2"></i> Modifica Ticket</a>
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Stampe</div>
	                                                <a href="#" class="dropdown-item" v-on:click="stampaRiepilogo(ticket)"><i class="fad fa-envelope fa-lg fa-fw ms-2 me-2"></i> Riepilogo Ticket</a>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteTicket(ticket)"><i class="fad fa-trash-alt fa-fw text-danger ms-2 me-2"></i> Elimina Ticket</a>                                        
	                                            </div>
	                                        </div>
	                                    </td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	
		                <div class="row page-bottom">
                            <div class="col-8 col-md-6" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">LORDO: {{ filters.formattaImporto(getTotali.LORDO) }}</span> - 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">COMM: {{ filters.formattaImporto(getTotali.COMM )}}</span> = 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">NETTO: {{filters.formattaImporto(getTotali.NETTO)}}</span>
                                </div>
                            </div>
							<div class="col-8 col-md-5" :tabindex="27">
	                            <div class="float-end">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
	                            </div>
	                        </div>
		                    <div class="col-4 col-md-1" :tabindex="28">
		                        <select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
	                                <option value="5">5</option>
	                                <option value="10">10</option>
	                                <option value="25">25</option>
	                                <option value="50">50</option>
	                                <option value="75">75</option>
	                                <option value="100">100</option>
	                                <option value="250">250</option>
	                                <option value="500">500</option>
	                            </select>
	                        </div>
	                    </div>
	                </div>
	            </div>
			</div>

		</div>		
	</div>

	<!-- Modal -->
	<div class="modal fade" id="popUpTicket"  role="dialog"  aria-modal="true" >
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title d-none d-sm-block">'Nuovo' : 'Modifica' preventivo</h6>   
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0.8rem !important;">
					
					<h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-user fa-fw me-1"></i> Dati intestatario Preventivo</h3>
                  	<div class="box_ticket">  

						<div class="row">
							<div class="col-5 col-md-2 form-group-1"> 
								<label class="text-danger custom-control custom-control-right">Sesso:</label>
								<select class="form-control form-control-sm select-icons" :tabindex="3"> 
									<option value="" data-icon="chrome" selected>Seleziona</option>
									<option value="1"> - Uomo</option>
									<option value="2"> - Donna</option>
								</select> 
							</div>
							<div class="col-12 col-md-5 form-group-1"> 
								<label class="text-danger custom-control custom-control-right">Cognome:</label>
								<div class="form-group-feedback form-group-feedback-left">
									<input autocomplete="off" type="text" class="form-control form-control-sm" :tabindex="4">
									<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
								</div>
							</div>
							<div class="col-12 col-md-5 form-group-1">
								<label class="text-danger custom-control custom-control-right">Nome:</label>
								<div class="form-group-feedback form-group-feedback-left">
									<input autocomplete="off" type="text" class="form-control form-control-sm" :tabindex="5">
									<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-user fa-fw"></i></div>
								</div>                
							</div>

							<div class="col-12 col-md-3 form-group-1">
								<label class="text-danger custom-control custom-control-right">Nazionalit&agrave;:</label>
								<Select2 :w="250" :api="'/core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" ::tabindex="2"></Select2>
							</div> 
							<div class="col-12 col-md-5 form-group-1">
								<label class="text-danger custom-control custom-control-right">Email:</label>
								<div class="form-group-feedback form-group-feedback-left">
									<input autocomplete="off" type="text" class="form-control form-control-sm"  :tabindex="14">
									<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-envelope fa-fw"></i></div>
								</div> 
							</div>
							<div class="col-12 col-md-4 form-group-1">
								<label class="text-danger custom-control custom-control-right">Cellulare:</label>
								<div class="form-group-feedback form-group-feedback-left">
									<input autocomplete="off" type="text" class="form-control form-control-sm"  :tabindex="15">
									<div class="form-control-feedback form-control-feedback-sm"><i class="far fa-phone fa-fw"></i></div>
								</div> 	        	
							</div>
						</div>	
						<hr class="hr-2">
						<h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;">
							<i class="fad fa-city fa-fw me-1"></i> Proposte
							<span class="badge badge-flat border-warning text-warning mt-1 float-end">NUOVA PROPOSTA</span>
						</h3>




					</div>
					
				</div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaTicket()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
                </div>
			</div>
		</div>
	</div>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'

export default {
    name:"Tickets",	

    components:{
        Pagine,
        Datepicker,
    },

    data:function(){
        return {
            apiStartup:'travel/incassi',
            filters:global.filters,
			anno:new Date().getFullYear(),
            arrayTickets:new Array(),
            ticketSelezionato:{},
			dataCreazioneRange:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ANNO:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_TICKET:{TYPE:'TEXT',VALUE:''},                   
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
        }
    },

    methods:{
        getTickets : function(){
            utils.ajax(this.apiStartup,{ANNO:this.search.ANNO.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayTickets = response.data;
                }
            });  
        },

        openTicket : function(ticket){
            this.$root.ticketSelezionato = utils.clone(ticket);
            global.router.push("Ticket");
        },
		

        nuovoTicket : function(){ 
			this.ticketSelezionato = {ID_CATEGORIA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
            this.filters.openPopUp("popUpTicket");
        },

        modificaTicket : function(ticket){            
            utils.ajax(this.apiStartup,ticket, (response) => {
                this.ticketSelezionato = utils.clone(response.data);
                this.filters.openPopUp("popUpTicket");
            });             
        },

        salvaTicket : function(){
            var api = this.ticketSelezionato.ID_CATEGORIA > 0 ? "travel/ticket/update" : "travel/ticket/insert";
            utils.ajax(api,this.ticketSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax(this.apiStartup,ticket, (response) => {                        
                        this.filters.closePopUp("popUpTicket");
                        utils.ajax(this.apiStartup,{}, (response) => {
                            this.arrayCategorie = response.data;
                        });
                    }); 
                }
            });
        }, 
		
        getBtnStatusClass : function(ticket){
            switch (ticket.FK_ID_STATO){
                case 1:
                    return 'badge-success'
                case 2:
                    return 'badge-primary'
                case 3:
                    return 'badge-info'
				case 4:
                    return 'badge-warning'
                case 5:
                    return 'badge-yellow'
                case 6:                
                    return "badge-secondary";
                case 7:
                case 8:
                    return 'badge-danger'
                default :
                    return 'badge-light'
            }
        },

        getTipoBookClass : function(ticket){
            switch (ticket.FK_ID_TIPO){
                case 1:
                    return "badge-danger";
                case 2:
                    return "badge-info";                   
                case 3:
                    return "badge-warning";
                case 4:
                    return "badge-primary";                   
            }
            return "";
        },

        getDateRange : function(ticket){
            $('.daterange-predefined').daterangepicker(
                {
                    startDate: moment().subtract(29, 'days'),
                    endDate: moment(),
                    minDate: '01/01/2014',
                    maxDate: '12/12/2019',
                    dateLimit: { days: 60 },
                    timePicker: true,
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                },
                function(start, end) {
                    $('.daterange-predefined span').html(start.format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + end.format('MMMM D, YYYY'));
                    $.jGrowl('Date range has been changed', { header: 'Update', theme: 'bg-primary text-white', position: 'center', life: 1500 });
                }
            );

            // Display date format
            $('.daterange-predefined span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + moment().format('MMMM D, YYYY'));
        },

        openSchedaTicket : function(ticket){
            this.$refs.schedaTicket.openSchedaTicket(ticket);
        },

		openRoute : function(route){
			global.router.push(route);
		},

    },

    computed: {
        paginatedList(){
			var arr = utils.inGrid(this.arrayTickets,this.search);
	        return arr;

            if (this.search.FK_ID_STATO.VALUE == 0){
                var arr = utils.clone(this.arrayTickets);
                for (var i = arr.length -1 ; i > -1 ; --i){
                    if (arr[i].FK_ID_STATO > 5){
                        arr.splice(i,1);
                    }
                }
                return utils.inGrid(arr,this.search);
            }
            else{
	            var arr = utils.inGrid(this.arrayTickets,this.search);
	            return arr;
            }            
        },

        getTotali(){
            var filtrato = global.utils.filter(this.arrayTickets,this.search);
            var lordo = 0;
            var comm = 0;
            for (var i = 0 ; i < filtrato.length ; i++){
                lordo += parseFloat(filtrato[i].PREZZO_TOTALE);
                comm += parseFloat(filtrato[i].COMMISSIONI);
            }
            return {
                LORDO:lordo,
                COMM:comm,
                NETTO:lordo-comm,
                COUNT:filtrato.length
            }
        }
    }, 

    created : function(){
        this.getTickets();
    }
}
</script>