<template>

    <div class="row" style="height: 8.75rem;">
        <div class="col-md-4 mb-2">
            <img v-if="contenuto.ID_CONTENUTO > 0 && contenuto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',contenuto.URL_AVATAR)" class="rounded" style="width: 200px !important;" alt="" />
            <div v-if="contenuto.URL_AVATAR == 'placeholder.jpg'" src="../assets/images/placeholders/placeholder.jpg" class="squared"></div>
        </div>
        <div class="col-md-8 mb-2" style="height: 6.7rem;">
            <h4 class="text-primary mb-1"> 
                {{contenuto.DESCRIZIONE }}
            </h4>
            <p class="text-dark mb-1"><i class="fal fa-tag fa-fw me-1"></i><span class="text-light me-1">|</span> {{ contenuto.NOME_MODULO }} </p>
            <p class="text-dark mb-1"><i class="fal fa-calendar fa-fw me-1"></i><span class="text-light me-1">|</span>{{ filters.formatDateTime(contenuto.DATE_ADD) }}</p>
            <p class="fs-sm text-dark mb-1">&nbsp;</p>
            <p class="mb-0">&nbsp;</p>
        </div> 
    </div>

    <p>&nbsp;</p>

    <div class="row bg-light" style="border-top: 1px solid #ccc;">
        <div class="col-12">
            <div class="navbar navbar-expand-lg bg-light border-bottom py-1">
                <div class="container-fluid">
                    <ul class="nav navbar-nav flex-row flex-fill" role="tablist">
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-1" class="navbar-nav-link navbar-nav-link-icon active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-info"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Info</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="scheda-1">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-info me-2"></i>Anagrafica
                            <span v-on:click="modificaContenuto()" class="badge bg-danger bg-opacity-20 text-danger cursor-pointer float-end p-1">
                                <span class="fw-semibold text-danger">Modifica Contenuto</span>
                            </span>
                        </legend>	
                        <div class="row mb-1">
                            <div class="col-lg-4">
                                <div class="form-floating">
                                    <div class="form-control-plaintext" style="border-bottom: 1px dashed #ccc;">  </div>
                                    <label class="fs-sm">NOME</label>
                                </div>
                            </div>
 

	                    </div>


                    </fieldset>
                </form>
            </div>

        </div>
    </div>


</template>



<script>


export default {
   name:"SchedaVisualizzazioneContenuto",

   props:["modulo"],

   components:{
       //Select2
   },

   data:function(){
       return{
           filters:global.filters,
           contenuto : {lang:{}}, 
           categoria : {},     
           tipo : 0,  
           ts:new Date().getTime(),
       }
   },

   methods:{
        openSchedaVisualizzazioneContenuto : function(param, _tipo){
            this.tipo = _tipo;
            //categoria
            if (_tipo == 1){
                utils.ajax("travel/categoria",{ID_CATEGORIA:param.ID_CATEGORIA}, (response) => {
                    this.contenuto = response.data.contenuto;
                    this.categoria = response.data;
                }); 
            }
            //contenuto
            else{
                utils.ajax("travel/contenuto",{ID_CONTENUTO:param.ID_CONTENUTO}, (response) => {
                    this.contenuto = response.data;
                }); 
            }
                      
        },
        modificaContenuto : function(){
            this.$emit("modificaContenuto",this.contenuto);
        }

   },
   computed:{
       
   }

}
</script>