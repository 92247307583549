<template>
        
	<!-- Page header -->
	<div class="page-header page-header-light">
		<div class="page-header-content header-elements-lg-inline">
			<div class="page-title d-flex">
				<h4 class="d-none d-sm-block">
					<router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="d-inline-block text-dark" v-on:click="getNotifiche()"> Pratiche </a> \
					<span class="text-muted">Gestione Notifiche</span>
					<code> - Lingua italiano</code>
				</h4>
				<h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Gestione Notifiche</h4>
				<a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
			</div>
			<div class="header-elements d-none">
				<div class="d-flex justify-content-center">
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoNotifica()"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="openRoute('/Quota')"><i class="icon-earth"></i> <span>DISPONIBILITA'</span></a>
					<a href="#" class="btn btn-link btn-float text-pink" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
				</div>
			</div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg navbar-light border-top">
			<div class="text-left d-lg-none w-100">
				<button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
					<i class="icon-menu7 me-2"></i> Richieste Notifiche
				</button>
			</div>
			<div class="navbar-collapse collapse" id="navbar-second">
				<ul class="nav navbar-nav">
					<li class="nav-item">
						<a href="#notifica-a" class="navbar-nav-link active" data-bs-toggle="tab">
							<i class="icon-calendar3 me-2"></i> Elenco Notifiche
						</a>
					</li>
					<li class="nav-item">
						<a href="#notifica-b" class="navbar-nav-link" data-bs-toggle="tab">
							<i class="icon-cancel-square me-2"></i> Notifiche evase
						</a>
					</li>
					<li class="nav-item">
						<a href="#notifica-c" class="navbar-nav-link" data-bs-toggle="tab">
							<i class="icon-bars-alt me-2"></i> Statistiche
						</a>
					</li>
				</ul>

           		<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />

				<ul class="navbar-nav ms-lg-auto">
					<li class="nav-item">
						<div class="btn-group btn-group-sm justify-content-center">
							<a href="#" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-list me-2"></i> Azioni Multiple</a>
	                        <div class="dropdown-menu">
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
	                                </div>
	                            </div>
	                            <div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
	                                <div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
	                                </div>
	                            </div>
	                            <div class="dropdown-divider"></div>
								<div class="dropdown-submenu dropdown-submenu-left">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
	                                <div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
	                                </div>
	                            </div>
	                            <div class="dropdown-divider"></div>
                                <a href="#" class="dropdown-item" v-on:click="deleteNotificheSelezionate(notificheSelezionate)"><i class="icon-trash text-danger"></i> Elimina</a>
	                        </div>
						</div>
					</li>
					<li class="nav-item ms-4">
                        <select class="form-control form-control-sm changeyear" id="comboAnno" @change="getOrdini()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
                        </select>
					</li>
				</ul>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
	<div class="content content-2">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="notifica-a">
				<div class="card shadow mb-0">
					<div class="card-body card-body-2">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							<form action="#">
								<div class="row ">	
									<div class="col-12 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Richiedente notifica" :tabindex="4" />
									</div>  
									<div class="col-12 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Destinazione" :tabindex="3" />
									</div>  
									<div class="col-6 col-md-2 form-group-filter">
										<Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" placeholder="Dalla Data"></Datepicker>
									</div>  
									<div class="col-6 col-md-2 form-group-filter">
										<Datepicker  model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" placeholder="Alla Data"></Datepicker>
									</div> 
									<div class="col-12 col-md-4">
										<div class="row"> 
											<div class="col-6 col-md-4 col-lg-8 form-group-filter d-none d-sm-block">
											</div> 
											<div class="col-12 col-md-4 form-group-filter">
												<select class="form-control form-control-sm" data-placeholder="Stato Richiesta">
													<option>Stato Richiesta</option> 
													<option v-bind:value="0"> - Da completare</option> 
													<option v-bind:value="1"> - Risposta</option> 
													<option v-bind:value="2"> - Evasa</option> 
													<option v-bind:value="3"> - Cancellata</option>                                                                    
												</select>
											</div> 
										</div>
									</div>  
								</div>
							</form>
						</div>
					</div>
				
					<div class="card-body card-body-2 card-body-nobottom card-body-notop">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
								<thead>
									<tr>
										<th class="table-head-sm" style="width: 2%;"> <input id="chkAllNotifiche" v-on:click="filters.selectAll('chkAllNotifiche',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width: 6%;"> # </th>
										<th class="table-head-sm" style=";"> Richiesta<br />del notifica </th>
										<th class="table-head-sm" style="width: 10%;">Priorità</th>
										<th class="table-head-sm" style="width: 12%;">Data Richiesta</th>
										<th class="table-head-sm text-center" style="width: 10%;">Stato</th>
										<th class="table-head-sm" style="width: 20%;"> Destinatari </th>
										<th class="table-head-sm" style="width: 5%;"> Azioni </th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
										<td class="font-size-md ">25<br />&nbsp;</td>
										<td>
											<div class="font-weight-semibold"><a href="task_manager_detailed.html">New blog layout</a></div>
											<div class="text-muted">Grumbled ripely eternal sniffed the when hello less much..</div>
										</td>
										<td class="text-center">
											<div class="btn-group">
												<a href="#" class="badge badge-danger dropdown-toggle" data-bs-toggle="dropdown">Blocker</a>
												<div class="dropdown-menu dropdown-menu-right">
													<a href="#" class="dropdown-item active"><span class="badge badge-mark me-2 bg-danger border-danger"></span> Blocker</a>
													<a href="#" class="dropdown-item"><span class="badge badge-mark me-2 bg-warning border-warning"></span> High priority</a>
													<a href="#" class="dropdown-item"><span class="badge badge-mark me-2 bg-primary border-primary"></span> Normal priority</a>
													<a href="#" class="dropdown-item"><span class="badge badge-mark me-2 bg-success border-success"></span> Low priority</a>
												</div>
											</div>
										</td>
										<td class="text-center">
											<div class="d-inline-flex align-items-center">
												<i class="icon-calendar2 me-2"></i>
												22 January, 15
											</div>
										</td>
										<td>
											<select name="status" class="custom-select">
												<option value="open">Open</option>
												<option value="hold">On hold</option>
												<option value="resolved" selected="selected">Resolved</option>
												<option value="dublicate">Dublicate</option>
												<option value="invalid">Invalid</option>
												<option value="wontfix">Wontfix</option>
												<option value="closed">Closed</option>
											</select>
										</td>
										<td class="text-center">
											<a href="#"><img src="../../assets/images/placeholders/placeholder.jpg" class="rounded-circle" width="32" height="32" alt=""></a>
											<a href="#"><img src="../../assets/images/placeholders/placeholder.jpg" class="rounded-circle" width="32" height="32" alt=""></a>
										</td>
										<td class="text-center">
											<div class="list-icons">
												<div class="dropdown">
													<a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
													<div class="dropdown-menu dropdown-menu-right">
														<a href="#" class="dropdown-item"><i class="icon-alarm-add"></i> Check in</a>
														<a href="#" class="dropdown-item"><i class="icon-attachment"></i> Attach screenshot</a>
														<a href="#" class="dropdown-item"><i class="icon-rotate-ccw2"></i> Reassign</a>
														<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item"><i class="icon-pencil7"></i> Edit task</a>
														<a href="#" class="dropdown-item"><i class="icon-cross2"></i> Remove</a>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="notifica-b">
				<div class="card shadow mb-0">
					<div class="card-body card-body-2">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							<form action="#">
								<div class="row ">	
									<div class="col-6 col-md-1 col-lg-1 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id pratica" :tabindex="6" />
									</div> 
									<div class="col-6 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Intestatario notifica" />
									</div>  
									<div class="col-12 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="estinazione" :tabindex="3" />
									</div>  
									<div class="col-12 col-md-1 form-group-filter">
										<Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
									</div>  
									<div class="col-12 col-md-1 form-group-filter">
										<Datepicker  model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
									</div> 
									<div class="col-12 col-md-5">
										<div class="row">
											<div class="col-6 col-md-3 form-group-filter">
											</div>
											<div class="col-6 col-md-3 form-group-filter">
											</div> 
											<div class="col-12 col-md-3 form-group-filter">
												<select class="form-control form-control-sm" data-placeholder="Saldo" tabindex="4"> 
													<option>Movimento</option> 
													<option v-bind:value="0"> - Banca</option> 
													<option v-bind:value="1"> - Contanti</option>
												</select>
											</div>
											<div class="col-12 col-md-3 form-group-filter">
												<select class="form-control form-control-sm" data-placeholder="Stato Incasso">
													<option>Stato Incasso</option> 
													<option v-bind:value="0"> - Da pagare</option> 
													<option v-bind:value="1"> - Pagata</option>                                                                    
												</select>
											</div> 
										</div>
									</div>  
								</div>
							</form>
						</div>
					</div>
				
					<div class="card-body card-body-2 card-body-nobottom card-body-notop">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
								<thead>
									<tr>
										<th class="table-head-sm" style="width: 2%;"> <input id="chkAllNotifiche" v-on:click="filters.selectAll('chkAllNotifiche',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width: 6%;"> # </th>
										<th class="table-head-sm" style="width: 7%;">Data notifica</th>
										<th class="table-head-sm" style="width: 19%;"> Intestatario </th>
										<th class="table-head-sm text-center" style=";"> Descrizione<br />Dati notifica </th>
										<th class="table-head-sm text-center" style="width: 8%;"> Importo </th>
										<th class="table-head-sm text-center" style="width: 8%;">Movimento<br />Tipo</th>
										<th class="table-head-sm text-center" style="width: 10%;">Utente</th>
										<th class="table-head-sm text-center" style="width: 10%;">Stato</th>
										<th class="table-head-sm" style="width: 5%;"> Azioni </th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
										<td class="text-center f-08">
											<span class=""></span>
										</td>
										<td class="text-center f-08">

										</td>
										<td class="f-08">

										</td>
										<td>

										</td>
										<td class="text-center"><strong> </strong></td>
										<td class="text-center"> Bonifico </td>
										<td class="text-center"> Banca </td>
										<td class="text-center">
											<span class="badge badge-danger cursor-pointer">Da pagare</span>
										</td> 
										<td class="text-center">
											<div class="list-icons">
												<div class="dropdown">
													<a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
													<div class="dropdown-menu dropdown-menu-right">
														<a href="#" class="dropdown-item" v-on:click="openNotifica(notifica)"><i class="far fa-pencil fa-fw"></i> Modifica Notifica</a>
														<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item" v-on:click="deleteNotifica(notifica)"><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina Notifica</a>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="notifica-c">
				<div class="card shadow mb-0">
					<div class="card-body card-body-2">

						<div class="row">
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="me-3 align-self-center">
											<i class="icon-coin-euro icon-3x text-indigo"></i>
										</div>

										<div class="media-body text-right">
											<h3 class="font-weight-semibold mb-0">&euro; 245,382</h3>
											<span class="text-uppercase fs-12 text-muted">Totale notifiche</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="me-3 align-self-center">
											<i class="icon-file-stats icon-3x text-success"></i>
										</div>

										<div class="media-body text-right">
											<h3 class="font-weight-semibold mb-0">652</h3>
											<span class="text-uppercase fs-12 text-muted">Notifiche inviati</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="media-body">
											<h3 class="font-weight-semibold mb-0">154</h3>
											<span class="text-uppercase fs-12 text-muted">Notifiche accettati</span>
										</div>

										<div class="ms-3 align-self-center">
											<i class="icon-calendar52 icon-3x text-primary"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="media-body">
											<h3 class="font-weight-semibold mb-0">258</h3>
											<span class="text-uppercase fs-12 text-muted">Notifiche rifiutati</span>
										</div>

										<div class="ms-3 align-self-center">
											<i class="icon-blocked icon-3x text-danger"></i>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
	</div>		

	<!-- Modal -->
	<div class="modal fade" id="popUpNotifica"  role="dialog"  aria-modal="true" >
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">Nuovo notifica</h6>   
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
				
							
					
				</div>
			</div>
		</div>
	</div>			


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
//import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker'

export default {
    name:"Notifiche",

    components:{
        Pagine,
		//Select2,
        Datepicker,


    },

    emits:[''],
    
    data:function(){
        return {
			anno:new Date().getFullYear(),
            apiStartup:'',
            filters:global.filters,
            arrayNotifiche:new Array(),
            notificaSelezionata:{storico:new Array()},
			notificheSelezionate:new Array(),
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_NOTIFICA:{TYPE:'TEXT',VALUE:''},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            TITOLO_NOTIFICA:{TYPE:'TEXT',VALUE:''}, 
                DATA_SCADENZA:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},              
                DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
	        },
            periodoSelezionato:{},
            arrayPromotori : new Array(),
            dataCreazioneRange:{},
        }
    },

    methods:{
        getNotifiche:function(){
            utils.ajax(this.apiStartup,{ANNO_ORDINE:this.search.ANNO_ORDINE.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayNotifiche = response.data;
                    for (var i = 0 ; i < this.arrayNotifiche.length ; i++){
                        this.arrayNotifiche[i].SELEZIONATO = 0;
                    }
                }
            });  
        },

		openRoute : function(route){
			global.router.push(route);
		},
		
    },

    computed: {
        paginatedList(){
        var arr = utils.inGrid(this.arrayNotifiche,this.search);            
	    return arr;         
        },
    }, 

    created : function(){
        this.arrayPromotori = this.$root.arrayPromotori;
        this.arrayAgenti = this.$root.arrayAgenti;
        this.getNotifiche();
    }
}
</script>