<template>
    <Contenuti :modulo="33"></Contenuti>
</template>
<script>
import Contenuti from '@/views/content/Contenuti.vue'


export default {
    name:"Blogs",
    components:{
        Contenuti,
    },
}
</script>