<template>

    <!-- ATTIVITA -->
    <div class="" v-bind:class="{'riga-quota-margin-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}" v-if="quota.FK_ID_MODULO == 1">
        <div class="d-flex flex-column flex-lg-row mb-2" v-bind:class="{'mb-3':quota.FK_ID_QUOTA_PRINCIPALE > 0}" >
            <div class="me-lg-3 mb-2 mb-lg-0">
                <img :src="quota.prodotto.IMMAGINE_PRODOTTO" class="squared-circle" v-bind:class="{'riga-quota-img-madre':quota.FK_ID_QUOTA_PRINCIPALE == 0,'riga-quota-img-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}"  alt="" />
            </div>
            <!-- DATI QUOTA PRINCIPALE -->
            <div class="flex-fill">
                <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',quota.prodotto.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                <span class="badge bg-info bg-opacity-20 text-reset badge-xs ms-2" v-if="quota.FLG_EXTRA_FACOLTATIVO == 1">Extra facoltativo</span>
                <span class="ms-1 me-1">|</span> 
                <a href="#" class="fw-semibold cursor-pointer" v-on:click="openProdotto(quota)">{{ quota.DESCRIZIONE }}</a>
                <!-- BOTTONE AZIONI -->
                <span class="fs-sm text-muted float-end">
                    <div class="dropdown align-self-center" v-show="modalita == 2">
                        <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ph-list"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </a>
                            <a href="#" class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</a>
                            <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</a>
                        </div>
                    </div>
                </span>
                <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                <div class="row">
                    <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                        <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                            </ul>
                            <p class="fs-sm mb-3">
                                <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                    <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                </span>
                            </p>                 
                        </div>
                        <div v-else>
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                                <span class="ms-1 me-1">|</span> 
                                <li class="list-inline-item fs-sm ms-2">
                                    <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                        <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                    </span>
                                </li>
                            </ul>                   
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                            <label class="form-label mb-0">Prezzo</label><br>
                            <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </div> 
            </div>
        </div>	
    </div>

    <!-- HOTEL -->
    <div class="" v-bind:class="{'riga-quota-margin-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}" v-if="quota.FK_ID_MODULO == 2">
        <div class="d-flex flex-column flex-lg-row mb-0" v-bind:class="{'mb-1':quota.FK_ID_QUOTA_PRINCIPALE > 0}" >
            <div class="me-lg-3 mb-2 mb-lg-0">
                <img :src="quota.prodotto.IMMAGINE_PRODOTTO" class="squared-circle" v-bind:class="{'riga-quota-img-madre':quota.FK_ID_QUOTA_PRINCIPALE == 0,'riga-quota-img-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}"  alt="" />
            </div>
            <!-- DATI QUOTA PRINCIPALE -->
            <div class="flex-fill">
                <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',quota.prodottoPadre.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                <span class="badge bg-info bg-opacity-20 text-reset badge-xs ms-2" v-if="quota.FLG_EXTRA_FACOLTATIVO == 1">Extra facoltativo</span>
                <span class="ms-1 me-1">|</span> 
                <a href="#" class="fw-semibold cursor-pointer" v-on:click="openProdotto(quota)">{{ quota.DESCRIZIONE_PADRE }}</a>
                <!-- BOTTONE AZIONI -->
                <span class="fs-sm text-muted float-end">
                    <div class="dropdown align-self-center" v-show="modalita == 2">
                        <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ph-list"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </a>
                            <a href="#" class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</a>
                            <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</a>
                        </div>
                    </div>
                </span>
                <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                <div class="row">
                    <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                        <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item">
                                    <i class="fad fa-map-signs fa-fw me-1"></i> 
                                    <a href="#" class="cursor-pointer">
                                        {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'STATO') }} - 
                                        {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }}, 
                                        {{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} 
                                    </a>
                                </li>
                                <span class="ms-0 me-2">|</span>
                                <i class="fad fa-phone-alt fa-fw ms-1 me-1"></i><a href="#" class="cursor-pointer"> +39</a>
                            </ul> 
                            <p class="fs-sm mb-3">
                                <span class="me-1"><i class="ph-thumbs-up text-success"></i>  </span>
                            </p>  
                        </div>
                        <div v-else>
                            <ul class="list-inline fs-sm mb-0 mt-0">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                            </ul>
                            <p class="fs-sm mb-3 mt-0">
                                <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                    <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                </span>
                            </p>                  
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                            <label class="form-label mb-0">Prezzo</label><br>
                            <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </div>           
            </div>
        </div>	
        <!-- CAMERE -->
        <div class="riga-quota-margin-figlia" >
            <div class="d-flex flex-column flex-lg-row mb-1">
                <div class="me-lg-3 mb-2 mb-lg-0">
                    <img :src="quota.prodotto.IMMAGINE_PRODOTTO" class="squared-circle riga-quota-img-figlia" alt="" />
                </div>
                <!-- DATI QUOTA PRINCIPALE -->
                <div class="flex-fill">
                    <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">Camera</span>
                    <span class="ms-1 me-1">|</span> 
                    <a href="#" class="fw-semibold cursor-pointer" v-on:click="openProdotto(quota)">{{ quota.DESCRIZIONE }}</a>
                    <!-- BOTTONE AZIONI -->
                    <span class="fs-sm text-muted float-end">
                        <div class="dropdown align-self-center" v-show="modalita == 2">
                            <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="ph-list"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" style="">
                                <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </a>
                                <a href="#" class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</a>
                                <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</a>
                                <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</a>
                                <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                                <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</a>
                            </div>
                        </div>
                    </span>
                    <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                    <div class="row">
                        <div class="col-8 " v-bind:class="{'ps-0':modalita == 2}">
                            <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                                <ul class="list-inline fs-sm mb-0">
                                    <li class="list-inline-item">
                                        <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                            <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                        </span>                                
                                    </li>
                                    <span class="ms-0 me-2">|</span>
                                    <li class="list-inline-item"><i class="fad fa-utensils fa-fw ms-1 me-1"></i><a href="#" class="cursor-pointer"> All</a></li>
                                </ul>
                                <p class="fs-sm mb-3">
                                    <span class="me-1"><i class="ph-thumbs-up text-success"></i>  </span>
                                </p> 
                            </div>
                            <div v-else>
                                <ul class="list-inline fs-sm mb-0 mt-0">
                                    <li class="list-inline-item">
                                        <i class="fad fa-bed fa-fw ms-1 me-1"></i>
                                        {{filters.getLabel($root.arrayRtsType,'ID_OPZIONE_TIPOLOGICA',quota.quote[0].FK_ID_OPZIONE,'DESCRIZIONE')}} - 
                                        {{filters.getLabel($root.arrayRtsType,'ID_OPZIONE_TIPOLOGICA',quota.quote[0].FK_ID_OPZIONE,'CUSTOM_1')}}
                                    </li>
                                    <span class="ms-0 me-1">|</span>
                                    <li class="list-inline-item"><i class="fad fa-utensils fa-fw ms-1 me-1"></i><a href="#" class="cursor-pointer"> All</a></li>
                                </ul>
                                <p class="fs-sm mb-3 mt-0">
                                    <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                        <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                    </span>
                                </p>                    
                            </div>
                        </div>
                        <div class="col-3 text-end">
                            <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                                <label class="form-label mb-0">Prezzo</label><br>
                                <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                            </div>
                        </div>
                        <div class="col-1"></div>
                    </div>            
                </div>
            </div>	
        </div>
    </div>

    <!-- PACCHETTO -->
    <div class="" v-bind:class="{'riga-quota-margin-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}" v-if="quota.FK_ID_MODULO == 3">
        <div class="d-flex flex-column flex-lg-row mb-2" v-bind:class="{'mb-3':quota.FK_ID_QUOTA_PRINCIPALE > 0}" >
            <div class="me-lg-3 mb-2 mb-lg-0">
                <img :src="quota.prodotto.IMMAGINE_PRODOTTO" class="squared-circle" v-bind:class="{'riga-quota-img-madre':quota.FK_ID_QUOTA_PRINCIPALE == 0,'riga-quota-img-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}"  alt="" />
            </div>
            <!-- DATI QUOTA PRINCIPALE -->
            <div class="flex-fill">
                <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">Pacchetto {{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',quota.prodotto.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                <span class="badge bg-info bg-opacity-20 text-reset badge-xs ms-2" v-if="quota.FLG_EXTRA_FACOLTATIVO == 1">Extra facoltativo</span>
                <span class="ms-1 me-1">|</span> 
                <a href="#" class="fw-semibold cursor-pointer" v-on:click="openProdotto(quota)">{{ quota.DESCRIZIONE }}</a>
                <!-- BOTTONE AZIONI -->
                <span class="fs-sm text-muted float-end">
                    <div class="dropdown align-self-center" v-show="modalita == 2">
                        <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ph-list"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </a>
                            <a href="#" class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</a>
                            <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</a>
                        </div>
                    </div>
                </span>
                <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                <div class="row">
                    <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                        <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                            </ul>
                            <p class="fs-sm mb-3">
                                <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                    <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                </span>
                            </p>                 
                        </div>
                        <div v-else>
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                                <span class="ms-1 me-1">|</span> 
                                <li class="list-inline-item fs-sm ms-2">
                                    <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                        <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                    </span>
                                </li>
                            </ul>                   
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <div class="mt-1 text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                            <label class="form-label mb-0">Prezzo</label><br>
                            <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>
        </div>	
    </div>

    <!-- FLIGHT -->
    <div class="" v-bind:class="{'riga-quota-margin-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}" v-if="quota.FK_ID_MODULO == 4">
        <div class="d-flex flex-column flex-lg-row mb-2" v-bind:class="{'mb-1':quota.FK_ID_QUOTA_PRINCIPALE > 0}" >
            <div class="me-lg-3 mb-2 mb-lg-0">
                <img  :src="quota.prodotto.dettagli.airline.URL_AVATAR" class="squared-circle" v-bind:class="{'riga-quota-img-madre':quota.FK_ID_QUOTA_PRINCIPALE == 0,'riga-quota-img-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}"  alt="" />
            </div>
            <!-- DATI QUOTA PRINCIPALE -->
            <div class="flex-fill">
                <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',quota.prodotto.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                <span class="badge bg-info bg-opacity-20 text-reset badge-xs ms-2" v-if="quota.FLG_EXTRA_FACOLTATIVO == 1">Extra facoltativo</span>
                <span class="ms-1 me-1">|</span> 
                <a href="#" class="fw-semibold cursor-pointer" v-on:click="openProdotto(quota)">{{ quota.DESCRIZIONE }}</a>
                <!-- BOTTONE AZIONI -->
                <span class="fs-sm text-muted float-end">
                    <div class="dropdown align-self-center" v-show="modalita == 2">
                        <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ph-list"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </a>
                            <a href="#" class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</a>
                            <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</a>
                        </div>
                    </div>
                </span>
                <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                <div class="row">
                    <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                        <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                            <ul class="list-inline fs-sm mt-0">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                            </ul>
                            <p class="fs-sm mb-3">
                                <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                    <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                </span>
                            </p>                 
                        </div>
                        <div v-else>
                            <ul class="list-inline fs-sm mb-0 mt-0">
                                <li class="list-inline-item"><i class="fad fa-plane-departure fa-fw me-1"></i>{{filters.formatTime(quota.price.ORA_START)}}</li>
                                <span class="ms-1 me-1">|</span>
                                <li class="list-inline-item"><i class="fad fa-plane-arrival fa-fw ms-1 me-1"></i>{{filters.formatTime(quota.price.ORA_END)}}</li>
                            </ul>
                            <ul class="list-inline fs-sm mb-3 mt-0">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',quota.price.FK_ID_OPZIONE,'DESCRIZIONE') }}</li>
                                <span class="me-1">|</span>
                                <li class="list-inline-item">
                                    <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                        <i class="fad fa-users fa-fw ms-1 me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                    </span>
                                </li>
                            </ul>                
                        </div>
                    </div>
                    <div class="col-2 text-end">
                        <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                            <label class="form-label mb-0">Prezzo</label><br>
                            <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>
        </div>	
    </div>

    <!-- GENERIC -->
    <div class="" v-bind:class="{'riga-quota-margin-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}" v-if="quota.FK_ID_MODULO == 6">
        <div class="d-flex flex-column flex-lg-row mb-2" v-bind:class="{'mb-3':quota.FK_ID_QUOTA_PRINCIPALE > 0}" >
            <div class="me-lg-3 mb-2 mb-lg-0">
                <img :src="quota.prodotto.IMMAGINE_PRODOTTO" class="squared-circle" v-bind:class="{'riga-quota-img-madre':quota.FK_ID_QUOTA_PRINCIPALE == 0,'riga-quota-img-figlia':quota.FK_ID_QUOTA_PRINCIPALE > 0}"  alt="" />
            </div>
            <!-- DATI QUOTA PRINCIPALE -->
            <div class="flex-fill">
                <span class="badge bg-dark bg-opacity-20 text-reset badge-xs">{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',quota.prodotto.FK_ID_TIPO,'DESCRIZIONE') }}</span>
                <span class="badge bg-info bg-opacity-20 text-reset badge-xs ms-2" v-if="quota.FLG_EXTRA_FACOLTATIVO == 1">Extra facoltativo</span>
                <span class="ms-1 me-1">|</span> 
                <a href="#" class="fw-semibold cursor-pointer" v-on:click="openProdotto(quota)">{{ quota.DESCRIZIONE }}</a>
                <!-- BOTTONE AZIONI -->
                <span class="fs-sm text-muted float-end">
                    <div class="dropdown align-self-center" v-show="modalita == 2">
                        <a href="#" class="badge bg-secondary bg-opacity-20 text-secondary text-body" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="ph-list"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" style="">
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="modificaDate(quota)"><i class="icon-truck me-2"></i> Gestione </a>
                            <a href="#" class="dropdown-item" v-on:click="editQuota(quota)"><i class="icon-truck me-2"></i> Edita Quota</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-file-download me-2"></i> Quota solo Costi</a>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"><i class="icon-wallet me-2"></i> Rendi quota visibile</a>
                            <div class="dropdown-divider" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0"></div>
                            <a href="#" class="dropdown-item" v-show="quota.FK_ID_QUOTA_PRINCIPALE == 0" v-on:click="eliminaQuota(quota)"><i class="icon-warning2 me-2"></i> Elimina Quota</a>
                        </div>
                    </div>
                </span>
                <!-- DATI QUOTA PRINCIPALE SUBMENU -->
                <div class="row">
                    <div class="col-9 " v-bind:class="{'ps-0':modalita == 2}">
                        <div v-if="quota.FK_ID_QUOTA_PRINCIPALE == 0">
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                            </ul>
                            <p class="fs-sm mb-3">
                                <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                    <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                </span>
                            </p>                 
                        </div>
                        <div v-else>
                            <ul class="list-inline fs-sm mb-0 mt-1">
                                <li class="list-inline-item"><i class="fad fa-map-signs fa-fw me-1"></i>{{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'COMUNE') }} ({{ filters.getLabel($root.arrayGeoComuni,'ID_COMUNE',quota.prodotto.FK_ID_COMUNE,'REGIONE') }})</li>
                                <span class="ms-1 me-1">|</span> 
                                <li class="list-inline-item fs-sm ms-2">
                                    <span v-show="quota.FK_ID_PRODOTTO > 0 && (quota.TOT_ADU + quota.TOT_CHD + quota.TOT_INF) > 0">
                                        <i class="fad fa-users fa-fw me-1"></i>{{ quota.TOT_ADU }} Adu, {{ quota.TOT_CHD }} Chd, {{ quota.TOT_INF }} Inf 
                                    </span>
                                </li>
                            </ul>                   
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="text-indigo " v-bind:class="{'testo-12':modalita == 2,'testo-10':modalita == 1}">
                            <label class="form-label mb-0">Prezzo</label><br>
                            <span style="font-weight: bold;">&euro; {{ quota.PREZZO_TOTALE }}</span>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </div>    
            </div>
        </div>	



    </div>

</template>

<script>
export default {
    name:'RigaQuota',
    emits: ['elimina', 'modifica', 'modificaDate','selezionaProdotto'],
    props:['quota','modalita'],
    components:{
    },
    data:function(){
        return {
            filters:global.filters,
        }
    },
    methods:{
        eliminaQuota : function(){
            this.$emit("elimina");
        },
        editQuota : function(){
            this.$emit("modifica");            
        },
        modificaDate : function(){
            this.$emit("modificaDate");            
        },
        getStileTitolo : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['padding-left'] = "40px";
            }
            return stile;
        },
        getStileRiga : function(){
            var stile = {};
            if (this.quota.FK_ID_QUOTA_PRINCIPALE > 0){
                stile['font-size'] = "14px";
            }
            else{
                stile['font-size'] = "18px";
            }
            return stile;
        },
        getLblStatoQuota : function(quota){
            switch (quota.FK_ID_STATO){
                case 1:
                    return "CONFERMATA";
                case 2:
                    return "IN RICHIESTA";
                default:
                    return "-";
            }
        },
        openProdotto : function(){
            if(this.quota.FK_ID_MODULO != 6){
                this.$emit("selezionaProdotto"); 
            }
        },
       
    }
}
</script>
