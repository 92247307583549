<template>

    <div class="card">
        <div class="card-header p-3 d-flex">
            <h5 class="mb-0">Chat: {{ chatHeader }}</h5>
            <div class="ms-auto">
                <button class="close-btn float-end" @click.stop="closeComponent">&times;</button>
            </div>
        </div>

        <!-- Action toolbar -->
        <div class="card-body">
            <div class="media-chat-scrollable p-3 mb-2">
                <div class="media-chat vstack gap-2" v-for="(messages,data) in arrayMessaggi.messaggi" :key="data">
                    
                    <div class="text-center justify-content-center text-muted mx-0"><span class="badge bg-dark bg-opacity-20 text-reset fs-sm px-2">{{ filters.formatDayWithDate(data) }}</span></div>

                    <div v-for="message in messages" v-bind:key="message.ID_MESSAGE"  class="media-chat-item align-items-start gap-3" :class="{'media-chat-item-reverse hstack': message.USERNAME === $root.utente.USERNAME, 'hstack': message.USERNAME !== $root.utente.USERNAME}">
                        <a href="#" class="d-block status-indicator-container">
                            <img v-if="message.FK_ID_USER > 0 && message.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',message.URL_AVATAR)" class="w-40px h-40px rounded-pill" alt="" />
                            <div v-if="message.URL_AVATAR == 'placeholder.jpg' || message.FK_ID_USER == 0"><img src="../assets/images/placeholders/placeholder.jpg" class="w-40px h-40px rounded-pill" alt=""></div>
                            <span class="status-indicator bg-success"></span>
                        </a>
                        <div>
                            <div class="media-chat-message">{{ message.TEXT }}</div>
                            <div class="fs-sm text-muted mt-2">{{ message.ORA_INVIO }}</div>
                        </div> 
                    </div>

                </div>
            </div>

            <input class="form-control form-control-content mb-3" contenteditable="" data-placeholder="Type message here and hit enter..." v-model="messaggio.TEXT" v-on:keyup.enter="messaggio.TEXT != '' ? inserisciMessaggio() : null">

            <div class="d-flex align-items-center">
                <div>
                    <a href="#" class="btn btn-light btn-icon border-transparent rounded-pill btn-sm me-1" data-bs-popup="tooltip" aria-label="Formatting" data-bs-original-title="Formatting">
                        <i class="ph-text-aa"></i>
                    </a>
                    <a href="#" class="btn btn-light btn-icon border-transparent rounded-pill btn-sm me-1" data-bs-popup="tooltip" aria-label="Emoji" data-bs-original-title="Emoji">
                        <i class="ph-smiley"></i>
                    </a>
                    <a href="#" class="btn btn-light btn-icon border-transparent rounded-pill btn-sm me-1" data-bs-popup="tooltip" aria-label="Send file" data-bs-original-title="Send file">
                        <i class="ph-paperclip"></i>
                    </a>
                </div>

                <button v-show="this.messaggio.TEXT != ''" type="button" class="btn btn-primary ms-auto float-end" v-on:click="inserisciMessaggio()">
                    Send
                    <i class="ph-paper-plane-tilt ms-2"></i>
                </button>
            </div>

        </div>
    </div>

</template>

<script>
export default {
    name: "ChatComp",

    props: {
        chatId: Number,
        utentiSelezionati: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            filters: global.filters,
            search: {
                PAGINATION: { LENGTH: 0, PAGE_NUMBER: 1, PAGE_SIZE: 50 },
                DESCRIZIONE: { TYPE: 'TEXT', VALUE: '' },
            },
            arrayMessaggi: [],
            arrayChat: [],
            messaggio: { TEXT: '', FK_ID_USER: this.$root.utente.ID_UTENTE, USERNAME: this.$root.utente.USERNAME },
            utente: { FK_ID_USER_CREATOR: this.$root.utente.ID_UTENTE },
            connessioneSocket: 0,
            appoggio: {
                socket: null
            },
            chatHeader: '' 
        };
    },

    methods: {
        connectSocket() {
            utils.connect(this.$root.CHAT_PATH, this, 'http', this.handleSocketConnect); 
            this.getMessaggiPerChat();
        },

        handleSocketConnect() {
            this.appoggio.socket.on('receiveMessage', (message) => {
                if(message && message.TEXT != ''){
                    if (message.FK_ID_CHAT === this.chatId) {
                        const dateKey = message.DATA_INVIO
                        if(!this.arrayMessaggi.messaggi[dateKey]){
                            this.arrayMessaggi.messaggi[dateKey] = [];
                        }
                        this.arrayMessaggi.messaggi[dateKey].push(message);
                    }  
                }
            });
        },

        inserisciMessaggio() {
            this.messaggio.FK_ID_CHAT = this.chatId;
            this.messaggio.DATA_INVIO = filters.formatDateReverse(filters.formatDate(new Date()));
            this.messaggio.ORA_INVIO = filters.formatTimeOnly(new Date());
            this.messaggio.URL_AVATAR = this.$root.utente.URL_AVATAR;
            console.log(this.messaggio);
            utils.ajax('chad_2/messaggio', this.messaggio, (response) => {
                if (response.esito == 0) {
                    this.appoggio.socket.emit('sendMessage', this.messaggio);
                    this.$emit('messageSent',this.messaggio);
                    this.messaggio.TEXT = '';
                }
            });
        },

        getMessaggiPerChat() {
            utils.ajax('chad_2/messaggi/chat', { FK_ID_CHAT: this.chatId }, (response) => {
                this.arrayMessaggi = response.data;
                if (this.arrayMessaggi.utenti) {
                    this.chatHeader = this.arrayMessaggi.utenti
                    .map(utente => utente.USERNAME === this.$root.utente.USERNAME ? 'Tu' : utente.USERNAME)
                    .join(', ');
                }
            });
        },

        closeComponent() {
            this.$emit('close');
        },

    },

    watch: {
        chatId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getMessaggiPerChat();
            }
        }
    },
    
    created() {
        this.connectSocket();
    }
}
</script>

<style scoped>

.message {
        padding: 10px;
        border-radius: 15px;
        margin: 5px;
        max-width: 60%;
        position: relative;
        word-wrap: break-word;
        margin-bottom: 5px;
    }

.message.sent {
        background-color: #addefb;
        align-self: flex-end;
        text-align: right;
    }

.message.received {
        background-color: #ceedf9; 
        align-self: flex-start;
        text-align: left;
    }

.username {
    font-weight: bold;
    color: #005180;
}

.status {
        font-size: 0.75em;
        color: #888;
        position: absolute;
        bottom: 5px;
        right: 10px;
    }

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 0; /* Rimuove margini che potrebbero causare overflow */
}

.input {
    border-radius: 30px;
    flex: 1;
    margin-right: 10px; /* Spazio tra input e bottone */
}

.circle-btn {
    border-radius: 50%;
    background-color: #005180;
    height: 38px; 
    width: 40px; 
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.circle-btn i {
    color: #fff;
    font-size: 16px; 
    }
</style>
