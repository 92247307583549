<template>  

    <div class="modal fade my-modal" :id="'popUpIncasso'+rs" data-backdrop="static">
        <div class="modal-dialog modal-medium">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        INCASSI PRATICA - PNR: {{ }}
                    </h6>
                    <button type="button" class="close" v-on:click="chiudiPopUp()" aria-label="Close">X</button>
                </div>
                <div class="modal-body modal-body-2">
                    <GrigliaIncassi :modalita="2" :ref="'grigliaIncassi'"></GrigliaIncassi>
                </div>
                <div class="modal-footer bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>&nbsp;&nbsp;
                    <button type="button" class="btn btn-warning btn-sm" v-on:click="stampaRiepilogo()"><i class="far fa-print fa-fw"></i> STAMPA RIEPILOGO </button>
                </div>
            </div>
        </div>
    </div> 
      
</template>



<script>

import GrigliaIncassi from '@/components/GrigliaIncassi.vue';



export default {
    name:"SchedaIncasso",
    props:[],
    components:{
        GrigliaIncassi
    },
    data:function(){
        return{
            filters:global.filters, 
            rs : utils.generateRandomString(30),
            ordine:{incassi:new Array()},
        }
    },
    
    methods:{
        openIncasso(ordine){
            this.ordine = utils.clone(ordine);
            this.$refs.grigliaIncassi.arrayIncassi = new Array();
            this.filters.openPopUp('popUpIncasso'+this.rs);
            setTimeout(() => {
                this.filters.popover();
                this.$refs.grigliaIncassi.startup(ordine);
            }, 300);  
        },

        chiudiPopUp : function(){
            var check = utils.array.select(this.$refs.grigliaIncassi.arrayIncassi,"ID_INCASSO",0,1);
            if (check.length > 0){
                utils.alert.confirm("Risultano non salvati alcuni incassi, sei sicuro di voler chiudere?",()=>{
                    this.filters.closePopUp('popUpIncasso'+this.rs);     
                },this.$root);
            }
            else{
                this.filters.closePopUp('popUpIncasso'+this.rs);  
            }
        },



    }
}

</script>