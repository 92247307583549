<template>

    <h6 class="profile-username" style="padding: 5px 5px 0 5px;"><i class="far fa-users fa-fw"></i> Gestione Costi 
        <div class="btn-group btn-group-sm justify-content-center float-end" v-show="modalita == 2">
            <a href="#" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle" data-bs-toggle="dropdown"><i class="icon-cog5 me-1"></i> Azioni Multiple</a>
            <div class="dropdown-menu">
                <a href="#" class="dropdown-item" v-on:click="addCosto(1)"><i class="icon-menu7"></i> Genera pagamento</a>
                <a href="#" class="dropdown-item" v-on:click="addCosto(2)"><i class="icon-menu7"></i> Genera 2 rate</a>
                <a href="#" class="dropdown-item" v-on:click="addCosto(3)"><i class="icon-menu7"></i> Genera 3 rate</a>  
            </div>
        </div>
    </h6>
    <div class="row">
        <div class="col-md-12">

            <div class="tableFixHead mb-3" style="min-height: 25.2rem !important; max-height: 25.2rem !important;">
 
                <div class="card-group-control card-group-control-left" id="accordion-control">
                    <div class="card" style="border: 1px solid #fff;">
                        <div class="card-header bg-light" style="padding: 0.3rem;">
                            <h6 class="card-title">
                                <a data-bs-toggle="collapse" class="text-body collapsed" href="#accordion-control-group1" aria-expanded="false">{{ ordine.TITOLO_PRATICA }}</a>
                            </h6>
                        </div>
                        <div class="card-body" style="padding: 0;">

                            <div id="accordion-control-group1" class="collapse show" data-parent="#accordion-control" style="">

                                <table class="table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th style="padding: 0.35rem 1.25rem;">Descrizione</th> 
                                            <th class="" style="width: 10%; padding: 0.35rem 1.25rem;">Tipo</th> 
                                            <th class="" style="width: 13%; padding: 0.35rem 1.25rem;">Calcolo</th> 
                                            <th class="" style="width: 15%; padding: 0.35rem 1.25rem;">Costo</th> 
                                            <th class="" style="width: 15%; padding: 0.35rem 1.25rem;">Importo</th> 
                                            <th class="" style="width: 12%; padding: 0.35rem 1.25rem;">Comm</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="quota in ordine.quote" v-bind:key="quota.ID_QUOTA">
                                            <td colspan="6" class="bg-light text-dark fs-12" style="padding: 0.35rem;">{{ quota.DESCRIZIONE }}</td>
                                        </tr>
                                        <tr v-for="quota in ordine.quote" v-bind:key="quota.ID_QUOTA">
                                            <td>{{ quota.DESCRIZIONE }}</td> 
                                            <td></td> 
                                            <td></td> 
                                            <td>€ {{ quota.COSTO_TOTALE }}</td> 
                                            <td>
                                                <div v-for="pax in quota.pax" v-bind:key="pax.ID_PAX">
                                                    {{ pax.DESCRIZIONE }} {{ pax.COSTO_TOTALE }}
                                                </div>
                                            </td> 
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr class="fs-12">
                                            <th colspan="6">SCONTI APPLICATI</th>
                                        </tr>
                                    </thead> 
                                    <tbody>
                                        <tr class="fs-12">
                                            <td style="padding: 0.35rem 1.25rem;">SCONTO RASPA </td> 
                                            <td style="width: 10%; padding: 0.35rem 1.25rem;">MI-3</td> 
                                            <td style="width: 13%; padding: 0.35rem 1.25rem;">p</td> 
                                            <td style="width: 15%; padding: 0.35rem 1.25rem;"></td> 
                                            <td style="width: 15%; padding: 0.35rem 1.25rem;">10.00 % </td> 
                                            <td style="width: 12%; padding: 0.35rem 1.25rem;"></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </div>

                        <div class="footer mt-2">                               
                            <table class="table table-hover table-striped table-bordered" style="font-size: 16px;">
                                <thead>
                                    <tr class="text-center fs-12">
                                        <th style="padding: 0.35rem;">TOTALE</th> 
                                        <th style="width: 12%; padding: 0.35rem;">x 2</th> 
                                        <th style="width: 15%; padding: 0.35rem;">7 notti</th> 
                                        <th style="width: 15%; padding: 0.35rem;">&euro; </th> 
                                        <th style="width: 15%; padding: 0.35rem;">&euro; {{ ordine.COSTO_TOTALE }} </th> 
                                        <th style="width: 14%; padding: 0.35rem;">&euro; {{ ordine.COMMISSIONI }} </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                    </div>
                </div>               

            </div>

        </div>
    </div>

</template>

<script>

//import Datepicker from '@vuepic/vue-datepicker';


export default {
    name:"GrigliaCosti",
    //modalita 1: sola lettura - modalita 2: modifica
    props:['modalita'],
    components:{
        //Datepicker
    },
    data:function(){
        return{
            filters:global.filters, 
            ordine:{costi:new Array()},
            arrayCosti:new Array()
        }
    },
    methods:{
        startup : function(ordine,ricaricaOrdini = 1){
            if (ricaricaOrdini == 1){
                utils.ajax("travel/ordine",{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
                    this.ordine = response.data;                      
                });
            }
            else{
                this.ordine = utils.clone(ordine);
            }
        },
    }
}
</script>