<template>
    <Contenuti :modulo="38"></Contenuti>
</template>
<script>

import Contenuti from '@/views/content/Contenuti.vue'

export default {
    name:"Faqs",
    components:{
        Contenuti,
    },
}
</script>