<template>

    <div class="tableFixHead tableFixHeadRoles">
        <table class="table table-bordered table-hover table-striped table-togglable">
            <thead>
                <tr class="bg-secondary text-white">
                    <th class="table-head-sm-2" style="width: 10%;">#</th> 
                    <th class="table-head-sm-2" style="width: 30%;">Intestatario</th> 
                    <th class="table-head-sm-2" style="width: 30%;">Titolo</th>  
                    <th class="table-head-sm-2" style="width: 30%;">Stato</th>  
                </tr>
            </thead> 
            <tbody>
                <tr v-for="ordine in paginatedList.arr" v-bind:key="ordine.ID_ORDINE">
                    <td><a href="#" class="badge bg-light badge-pill">2223</a></td>  
                    <td>Mr - Castelforte Egidio </td> 
                    <td style="text-align: center;">Pacchetto volo + soggiorno</td> 
                    <td style="text-align: center;">Richiesta impegantiva</td> 
                </tr>
            </tbody>
        </table>
    </div>

</template>


<script>
export default {
    name:'OrdiniPopUp',
    props:['modalita'],//1 UTENTI CLIENTI - //2 AZIENDE(AGENCCY)
    data:function(){
        return {
            arrayOrdini:new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000},
                FK_ID_STATO:{TYPE:'COMBO',VALUE: ""},                
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
            },
        }
    },
    methods:{
        getOrdini : function(id){
            var api = this.modalita == 1 ? "travel/utente/ordini" : "travel/azienda/ordini";
            var params = this.modalita == 1 ? {ID_UTENTE:id} : {ID_AZIENDA:id};
            utils.ajax(api,params, (response) => {
                this.arrayOrdini = response.data;
            }); 
        }
    },
    computed : {
        paginatedList : function(){
            var arr = utils.inGrid(this.arrayOrdini,this.search);
            return arr;
        }
    }
}
</script>