<template>

    <div>
    	<Utenti :livello="5"></Utenti>
    </div>

</template>



<script>

import Utenti from '@/components/common/Utenti.vue'	


export default {
    name:"UtentiClienti",
    components:{
        Utenti,
    },		

}
</script>