<template>
 
	<div class="card shadow mb-0">
		<div class="card-header d-lg-none p-1">
			<div class="d-grid gap-2">
				<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
					VEDI FILTRI
				</a>
			</div>
		</div>
		<div class="collapse show" id="collapse-all" style="">				
			<div class="card-body pb-0">
				<div class="alert alert-2 alert-secondary border-0">
					<form autocomplete="off" id="utente-a">
						<div class="row">
							<div class="col-8 col-md-2 col-lg-3 mbx-1">
								<Datepicker class="bg-light" style="width:100%" v-model="intervalloDate" range multi-calendars multi-calendars-solo :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :partial-range="false" position="left" placeholder="Data Ordine da > a" />
							</div> 
							<div class="col-4 col-md-2 col-lg-1 mbx-1">
								<span class="btn btn-primary btn-sm float-start"  v-on:click="getPianoOccupazione()">AGGIORNA</span>
							</div>
							<div class="col-6 col-md-2 col-lg-1 mbx-1">

							</div>
							<div class="col-12 col-md-4 col-lg-7">
								<div class="row">
									<div class="col-12 col-md-2 col-lg-4 mbx-1">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Prodotto" v-model="search.TITOLO_RICERCA.VALUE" :tabindex="7" />
									</div>
									<div class="col-6 col-md-2 col-lg-2 mbx-1">
										<select class="form-select form-select-sm" data-placeholder="Modello" :tabindex="13">
											<option value="">Modello </option>   
											<option value="1"> &nbsp; Uomo</option>
											<option value="2"> &nbsp; Donna</option>
										</select>
									</div>
									<div class="col-6 col-md-3 col-lg-2">
										<select class="form-select form-select-sm" data-placeholder="Tipo" :tabindex="13">
											<option value="">Tipo </option>   
											<option value="1"> &nbsp; Attivati</option>
											<option value="0"> &nbsp; Disattivati</option>
										</select>
									</div>
									<div class="col-6 col-md-3 col-lg-2">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Cod. Prodotto" :tabindex="7" />
									</div>
									<div class="col-6 col-md-3 col-lg-2">
										<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13">
											<option value="">Stato </option>   
											<option value="1"> &nbsp; Attivati</option>
											<option value="0"> &nbsp; Disattivati</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div> 
		</div>
	
		<div class="card-body box-card mt-0 mb-0" id="box-card">
			<div class="tableFixHead" id="tableFixHead">
				<!-- ATTIVITA -->




				<!-- END ATTIVITA -->
					

				<!-- HOTELS -->
				<table class="table table-bordered table-hover table-striped table-togglable"> 
					<thead class="sticky-top">	
						<tr class="bg-secondary text-white">
							<th class="table-head-sm" style="min-width:10px;"> <input id="chkAllRighe" v-on:click="filters.selectAll('chkAllRighe',paginatedList.arr)" type="checkbox" class=""> </th>
							<th class="table-head-sm" style="min-width: 260px;">Prodotto<br />Tipologia</th>
							<th class="table-head-sm text-center" style="min-width: 84px;" v-for="colonna in arrayDate" v-bind:key="colonna.DATA">{{ filters.formatDateMonth(colonna.DATA) }}</th>
							
						</tr>
					</thead>
					<tbody >
						<tr>
							<td></td>
							<td class="text-center">VENDUTO TOTALE</td>
							<td class="td-class fs-sm text-center" v-for="(cella,$index) in arrayDate" v-bind:key="cella.DATA" style="vertical-align: text-top; height:30px !important;">
								<span class="badge bg-primary badge-xs">{{ getTotali[$index].VENDUTO }}</span>
							</td>

						</tr>
						<tr v-for="riga in paginatedList.arr" v-bind:key="riga.CHIAVE">
							<td class="td-class text-center">
								<span class="footable-toggle"></span>
								<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="riga.SELEZIONATO" class=""> 
							</td>
							<td class="td-class">
								<div v-if="riga.ID_PADRE > 0">
									<small class="badge badge-xs bg-secondary bg-opacity-20 text-secondary fs-sm">{{ riga.ID_PADRE }}</small> - 
									<span class="text-primary text-uppercase fw-bold fs-sm cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneProdotto(riga.ID_PADRE)">{{ riga.TITOLO_PADRE }}</span> <br/>
								</div>
								<small class="badge badge-xs bg-secondary bg-opacity-20 text-secondary fs-sm">{{ riga.FK_ID_PRODOTTO }}</small> -  
								<small class="cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneProdotto(riga.FK_ID_PRODOTTO)">{{ riga.TITOLO }} | {{ riga.RTS_TYPE }} </small>
							</td> 	
							<td class="td-class fs-sm" v-for="cella in riga.ARRAY_DATE" v-bind:key="cella.DATA" style="vertical-align: text-top;" v-bind:class="getCellStyle(cella)" v-on:click="openOrdiniCella(cella)">
								<span v-if="cella.hasOwnProperty('CALENDARIO')"><span class="cursor-pointer fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" title="Master">M:</span> {{ cella.CALENDARIO.MAX_PRENOTAZIONI }}</span><br/>
								<div v-if="cella.hasOwnProperty('CALENDARIO')">
									<span class="fw-bold" v-if="cella.hasOwnProperty('PRENOTAZIONI')"><span class="cursor-pointer fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" title="Venduti">V:</span> {{ cella.PRENOTAZIONI.TOT }}</span>
									<span v-else><span class="cursor-pointer fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" title="Venduti">V:</span> 0</span>
									di <span class="cursor-pointer fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" title="Allotment">A:</span> {{ cella.CALENDARIO.MAX_PRENOTAZIONI_OPZIONE }}
								</div>
								
								<hr v-if="cella.hasOwnProperty('PRENOTAZIONI')" class="m-1" />
								<span v-if="cella.hasOwnProperty('PRENOTAZIONI')"><i class="fal fa-users fa-fw me-1"></i><strong>{{ cella.PRENOTAZIONI.TOT_ADU }}</strong> | {{ cella.PRENOTAZIONI.TOT_CHD }} | {{ cella.PRENOTAZIONI.TOT_INF }}</span>
							</td>
						</tr>
					</tbody>
				</table>							
				<!-- END HOTELS -->


				<!-- TRASPORTI -->

				<!-- END TRASPORTI -->

					
				<!-- EXTRA -->

				<!-- END EXTRA -->

				<!--
				<div>
					<div style="width:25%;float:left">
						<div style="height:60px;">Descrizione</div>
						<div style="height:60px;border:1px solid #000" v-for="riga in paginatedList.arr" v-bind:key="riga.CHIAVE">
							<small class="badge badge-xs bg-secondary bg-opacity-20 text-secondary fs-sm">{{ riga.ID_PADRE }}</small> - <span class="text-primary text-uppercase fw-bold fs-sm cursor-pointer">{{ riga.TITOLO_PADRE }}</span> <br/>
							<small class="badge badge-xs bg-secondary bg-opacity-20 text-secondary fs-sm">{{ riga.FK_ID_PRODOTTO }}</small> - <span class="text-light text-uppercase fs-sm">{{ riga.CODICE_PRODOTTO }}</span> <small>{{ riga.TITOLO }}</small> | {{ riga.RTS_TYPE }} 										
						</div>
					</div>
					<div style="width:75%;float:left;overflow-x:auto">
						<div style="height:60px;display:block" v-bind:style="getWidthStyle">
							<div  style="height:60px;width:90px;float:left" v-for="colonna in arrayDate" v-bind:key="colonna.DATA">{{ filters.formatDateMonth(colonna.DATA) }}</div>
						</div>
						<div style="height:60px;" v-bind:style="getWidthStyle" v-for="riga in paginatedList.arr" v-bind:key="riga.CHIAVE">
							<div style="height:60px;width:90px;float:left;border:1px solid #000"   v-for="cella in riga.ARRAY_DATE" v-bind:key="cella.DATA">
								<small class="ms-1"><small>{{ filters.formatDateMonth(cella.DATA) }}</small></small>
								<div class="ms-1">
									<span class="me-1" v-if="cella.hasOwnProperty('CALENDARIO')"><span class="fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" :title="Allotment">A:</span> {{ cella.CALENDARIO.MAX_PRENOTAZIONI }}</span>
									<span v-if="cella.hasOwnProperty('CALENDARIO')"><span class="fw-bold" data-bs-toggle="tooltip" data-bs-placement="top" :title="Totali">T:</span> {{ cella.CALENDARIO.MAX_PRENOTAZIONI_OPZIONE }}</span>
								</div>
								<div class="ms-1">
									<span class="badge badge-xs bg-success bg-opacity-20 text-success fw-bold" v-if="cella.hasOwnProperty('PRENOTAZIONI')"><span data-bs-toggle="tooltip" data-bs-placement="top" :title="Venduti">V:</span> {{ cella.PRENOTAZIONI.TOT }}</span>
								</div>											
							</div>
						</div>
						
					</div>
				</div>
				-->


			</div>

			<div class="row page-bottom">
				<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
					<div style="position: relative; top: 10px;">
						<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
						<span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">PRODOTTI: {{ paginatedList.count }}</span> 
					</div>
				</div>
				<div class="col-9 col-md-6" :tabindex="27">
					<div class="float-end">
						<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
					</div>
				</div>
				<div class="col-3 col-md-1" :tabindex="28">
					<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
						<option value="5">5</option>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="75">75</option>
						<option value="100">100</option>
						<option value="250">250</option>
						<option value="500">500</option>
					</select>
				</div>
			</div>

		</div>
	</div>
		

	<div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" v-show="livello == 5" :tabindex="2">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneProdotto :ref="'schedaVisualizzazioneProdotto'"></SchedaVisualizzazioneProdotto>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>	

	<div class="modal fade" id="popupOrdini" data-bs-backdrop="false" aria-modal="true" role="dialog" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title"><i class="fa fa-fw fa-key fa-fw"></i> NOTE IMPORTANTI</h6>  
                    <button type="button" class="btn-close text-white" data-bs-dismiss="modal">X</button>
                </div>
				<div class="modal-body">

					<div class="tableFixHead tableFixHeadRoles">
						<table class="table table-bordered table-hover table-striped table-togglable">
							<thead class="sticky-top">
								<tr class="bg-secondary text-white">
									<th class="table-head-sm" style="width:12%;"><span >PNR</span></th>
									<th class="table-head-sm" style="width:16%;"><span>Data e ora</span></th>
									<th class="table-head-sm" style=""><span>TITOLO</span></th>
									<th class="table-head-sm text-center" style="width:16%;"><span>Importo</span></th>
									<th class="table-head-sm text-center" style="width:17%;"><span>Stato</span></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="ordine in arrayOrdiniPrice" v-bind:key="ordine.ID_ORDINE">
									<td class="td-class fs-sm" style=""><span class="badge bg-info bg-opacity-20 text-info badge-xs cursor-pointer" v-on:click="openOffCanvasOrdini();openSchedaOrdine(ordine)"> {{ ordine.ID_ORDINE }} </span></td>
									<td class="td-class fs-sm" style=""><span> {{filters.formatDate(ordine.DATA_CREAZIONE) }} </span></td>
									<td class="td-class fs-sm" style="">
										<span class="fw-bold"> {{ ordine.TITOLO_PRATICA }} </span><br />
										<span class="text-primary text-uppercase fw-bold cursor-pointer" v-show="ordine.FK_ID_AZIENDA_AGENZIA > 0">{{filters.accorciaTesto(ordine.AGENZIA,35)}}</span>
										<span class="text-primary text-uppercase fw-bold cursor-pointer" v-show="ordine.FK_ID_AZIENDA_AGENZIA == 0 && ordine.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">{{filters.accorciaTesto(ordine.INTESTATARIO_PERSONA_FISICA,35)}}</span>
										<span class="text-primary text-uppercase fw-bold cursor-pointer" v-show="ordine.FK_ID_AZIENDA_AGENZIA == 0 && ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">{{filters.accorciaTesto(ordine.INTESTATARIO_PERSONA_GIURIDICA,35)}}</span>
									</td>
									<td class="td-class fs-sm text-center" style=""><span>&euro; {{ ordine.PREZZO_TOTALE }} </span></td>
									<td class="td-class fs-sm text-center" style="">
										<span class="badge badge-xs text-lowercase" v-bind:class="getBtnStatusClass(ordine)"> 
											{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',ordine.FK_ID_STATO,'DESCRIZIONE') }} 
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
				<div class="modal-footer bg-light p-1">                                
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</button>
				</div>
			</div>
		</div>
	</div> 

    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right-2" role="dialog" tabindex="-1" style="z-index: 11111111111;">
		<div class="offcanvas-header bg-dark text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">PRATICA - {{ ordineSelezionato.TITOLO_PRATICA }}</h5>
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaOrdine :ref="'schedaOrdine'" ></SchedaOrdine>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <div style="position: relative; top: 2px;">
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="LORDO"><strong class=" me-1">L:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COMMISSIONE"><strong class=" me-1">A:</strong> € {{ filters.formattaImporto(ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="NETTO"><strong class=" me-1">N:</strong> € {{ filters.formattaImporto(ordineSelezionato.PREZZO_TOTALE - ordineSelezionato.COMMISSIONI) }}</span>
                <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 me-2" title="COSTO"><strong class=" me-1">C:</strong> € {{ filters.formattaImporto(ordineSelezionato.COSTO_TOTALE) }}</span>
                <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
            </div>       
        </div>  
	</div>


</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker' 
import SchedaVisualizzazioneProdotto from '@/components/SchedaVisualizzazioneProdotto.vue'
import SchedaOrdine from '@/components/SchedaOrdine.vue'



export default {
    name:'PianoOccupazione',
	props:["modulo"],
    components: {
        Pagine,
        Datepicker,
		SchedaOrdine,
        SchedaVisualizzazioneProdotto
    },

    data: function (){
      	return{
            apiStartup:'',
        	filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
			arrayPianoOccupazione: new Array(),
			arrayDate : new Array(),
			search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				TITOLO_RICERCA:{TYPE:'TEXT',VALUE:''}, 
            },
			intervalloDate:{},
			dataStart:'',
			dataEnd:'',
			arrayOrdiniPrice : new Array(),
			ordineSelezionato : {},
      	}
    },

    methods: {
		getPianoOccupazione : function(){
			/*
			if (this.intervalloDate != null && this.intervalloDate != undefined){ 
				dati.DATA_START = this.filters.formatDate(this.intervalloDate[0]);
				dati.DATA_END = this.filters.formatDate(this.intervalloDate[1]);
			}*/
			global.utils.ajax('travel/occupazione',{DATA_START:this.dataStart,DATA_END:this.dataEnd,FK_ID_MODULO:this.modulo}, (response,status) => {    
				this.arrayPianoOccupazione = response.data;
				if (this.arrayPianoOccupazione.length > 0){
					this.arrayDate = utils.clone(response.data[0].ARRAY_DATE);
				}				
			});
		},
 

		datePickerChangeHandler : function(){
            this.search.DATA_ADD = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
                this.search.DATA_ADD = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
            }
        }, 

		dataCreazioneRangeChangeHandler : function(){
            this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRangeFatt != null && this.dataCreazioneRangeFatt.length > 0){
                this.searchStatisticheOrdiniFatt.DATA_CREAZIONE = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRangeFatt[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRangeFatt[1])};
            }
        },

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneProdotto : function(idProdotto,tab = ""){
			this.$refs.schedaVisualizzazioneProdotto.openSchedaVisualizzazioneProdotto(idProdotto,tab);
		},

		openOffCanvasOrdini : function(){
            var myOffcanvas = document.getElementById('panel_right-2')
            var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
            bsOffcanvas.show();
        },

		openSchedaOrdine : function(ordine){
            this.ordineSelezionato = utils.clone(ordine);
            this.$refs.schedaOrdine.openSchedaOrdine(ordine);
        },

		openOrdiniCella : function(cella){
			if (cella.hasOwnProperty("PRENOTAZIONI")){
				global.utils.ajax('travel/ordini/price',{DATA:cella.DATA,FK_ID_PRODOTTO:cella.PRENOTAZIONI.FK_ID_PRODOTTO,FK_ID_OPZIONE:cella.PRENOTAZIONI.FK_ID_OPZIONE}, (response,status) => {    
					this.arrayOrdiniPrice = response.data;
					this.filters.openPopUp("popupOrdini");				
				});
			}	
		},

		getCellStyle : function(cella){
			if (cella.hasOwnProperty("PRENOTAZIONI")){
				var allotment = cella.CALENDARIO.MAX_PRENOTAZIONI_OPZIONE ;
				var vendute = cella.PRENOTAZIONI.TOT;
				var perc = vendute / allotment;
				if (perc < 0.2){
					return "bg-info bg-opacity-20 cursor-pointer";
				}
				else if (perc < 0.6){
					return "bg-success bg-opacity-20 cursor-pointer";
				}
				else if (perc < 0.8){
					return "bg-warning bg-opacity-20 cursor-pointer";
				}
				else{
					return "bg-danger bg-opacity-20 cursor-pointer";
				}
			}
		},

		getBtnStatusClass : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaStato,"ID_OPZIONE_TIPOLOGICA",ordine.FK_ID_STATO,1);
            if (arr.length > 0){
                return "bg-"+ arr[0].ICONA;
            }
            return "";
        },

	},

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayPianoOccupazione,this.search);
            return arr;
        },	
		getTotali : function(){
			var arr = utils.clone(this.arrayDate);
			var arrayFiltrato = utils.filter(this.arrayPianoOccupazione,this.search);
			for (var i = 0 ; i < arr.length ; i++){
				//arr[i].MAX_PRENOTAZIONI = 0;
				arr[i].VENDUTO = 0;
				//arr[i].DISPONIBILE = 0;
				//var CALENDARIO.MAX_PRENOTAZIONI_OPZIONE = 0;
				//var cella.PRENOTAZIONI.TOT = 0;
				for (var j = 0 ; j < arrayFiltrato.length ; j++){
					for  (var k = 0 ; k < arrayFiltrato[j].ARRAY_DATE.length ; k++){
						if (arrayFiltrato[j].ARRAY_DATE[k].DATA == arr[i].DATA){
							if (arrayFiltrato[j].ARRAY_DATE[k].hasOwnProperty("PRENOTAZIONI")){								
								arr[i].VENDUTO += parseFloat(arrayFiltrato[j].ARRAY_DATE[k].PRENOTAZIONI.TOT);
							}												
							break;
						}
					}
					
				}
				
			}
			return arr;
		},

		getWidthStyle : function(){
			var style = {};
			style.width = (this.arrayDate.length * 84) + "px" ;
			return style;
		} 

    },

	watch : {
		intervalloDate : function(valore,valoreOld){
            if (this.intervalloDate != null && this.intervalloDate != undefined){ 
                this.dataStart = this.filters.formatDate(this.intervalloDate[0]);
                this.dataEnd = this.filters.formatDate(this.intervalloDate[1]);
            }
        },
	},

    created : function () {	 

    },

}
</script>
