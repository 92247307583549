<template>

    <!-- Search field -->
    <div class="card">
        <div class="card-body">
            <form action="#">
                <div class="d-sm-flex">
                    <div class="form-group-feedback form-group-feedback-left flex-grow-1 mb-3 mb-sm-0">
                        <input type="text" class="form-control form-control-lg" placeholder="Search our knowledgebase">
                        <div class="form-control-feedback form-control-feedback-lg">
                            <i class="icon-search4 text-muted"></i>
                        </div>
                    </div>

                    <div class="dropdown ms-sm-3 mb-3 mb-sm-0">
                        <select class="custom-select custom-select-lg">
                            <option value="0">All categories</option>
                            <option value="1">Getting started</option>
                            <option value="2">Registration</option>
                            <option value="3">General info</option>
                            <option value="4">Your settings</option>
                            <option value="5">Copyrights</option>
                            <option value="6">Contacting authors</option>
                        </select>
                    </div>

                    <div class="ms-sm-3">
                        <button type="submit" class="btn btn-primary btn-lg w-100 w-sm-auto">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- /search field -->


    <!-- Info blocks -->
    <div class="row">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-body text-center">
                <i class="icon-book icon-2x text-success border-success border-3 rounded-pill p-3 mb-3 mt-1"></i>
                    <h5 class="card-title">Knowledge Base</h5>
                    <p class="mb-3">Ouch found swore much dear conductively hid submissively hatchet vexed far inanimately alongside candidly much and jeez</p>
                    <a href="#" class="btn btn-success">Browse articles</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card">
                <div class="card-body text-center">
                    <i class="icon-lifebuoy icon-2x text-warning border-warning border-3 rounded-pill p-3 mb-3 mt-1"></i>
                    <h5 class="card-title">Support center</h5>
                    <p class="mb-3">Dear spryly growled much far jeepers vigilantly less and far hideous and some mannishly less jeepers less and and crud</p>
                    <a href="#" class="btn btn-warning">Open a ticket</a>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card">
                <div class="card-body text-center">
                    <i class="icon-reading icon-2x text-primary border-primary border-3 rounded-pill p-3 mb-3 mt-1"></i>
                    <h5 class="card-title">Articles and news</h5>
                    <p class="mb-3">Diabolically somberly astride crass one endearingly blatant depending peculiar antelope piquantly popularly adept much</p>
                    <a href="#" class="btn btn-primary">Browse articles</a>
                </div>
            </div>
        </div>
    </div>
    <!-- /info blocks -->


    <!-- Directory -->
    <div class="mb-3 py-2">
        <h4 class="font-weight-semibold mb-0">Browse articles by category</h4>
        <span class="text-muted d-block">Yikes snuffed goat macaw overlay much and goodness and alas kookaburra a goldfish</span>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Getting started <span class="ms-1">(93)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> And hello exotic staunch <span class="badge badge-primary ms-auto">Popular</span>
                        </a>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> That and well ecstatically
                        </a>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Sheared coasted so concurrent
                        </a>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Into darn intrepid belated
                        </a>
                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (93)
                        </a>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Becoming an author <span class="ms-1">(56)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Jeepers therefore one
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Near and ladybug forewent <span class="badge badge-success ms-auto">Review</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Well much strove when stuck
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Lorikeet much fantastic less
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (56)
                        </a>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> General info for all authors <span class="ms-1">(29)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Lackadaisical dear crude <span class="badge badge-danger ms-auto">Closed</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Effortless one powerlessly
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Some less hey and less <span class="badge badge-indigo ms-auto">Article</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Jeepers pill nonsensically
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (29)
                        </a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Your statement &amp; documents <span class="ms-1">(58)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Incongruously gorilla <span class="badge badge-teal ms-auto">New</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Playful amongst hence
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Sobbingly altruistic nasty
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Hung insecure far ferret
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (58)
                        </a>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Account settings <span class="ms-1">(92)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Reined and this vigorous <span class="badge badge-primary ms-auto">Popular</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Oh positively well crab
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Recast then impalpable cried
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Eclectic mechanically as on <span class="badge badge-danger ms-auto">Closed</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (92)
                        </a>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Protecting your copyright <span class="ms-1">(15)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> And dear dealt bat far redid
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Trout some after effective <span class="badge badge-secondary ms-auto">On hold</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> The one rhythmically whale
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Admirably spun a the belched <span class="badge badge-indigo ms-auto">Article</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (15)
                        </a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Intellectual Property <span class="ms-1">(12)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Gagged more much around <span class="badge badge-danger ms-auto">Closed</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Much oh much along gnu
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Aardvark far the until alas <span class="badge badge-success ms-auto">Review</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Hence expeditiously goldfish
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (12)
                        </a>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Item support <span class="ms-1">(34)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Shark hello less well
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Far pious gosh cuttingly <span class="badge badge-success ms-auto">Review</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Tonally guardedly jeepers
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Since growled overheard
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (34)
                        </a>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="mb-3">
                        <h6 class="font-weight-semibold mt-2"><i class="icon-folder6 me-2"></i> Contacting authors <span class="ms-1">(85)</span></h6>
                        <div class="dropdown-divider mb-2"></div>
                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Jeepers circa sneered well
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Oh one ouch ouch armadillo <span class="badge badge-primary ms-auto">Popular</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Thus went far and pending <span class="badge badge-secondary ms-auto">On hold</span>
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-file-text2"></i> Or cat together chose eagle
                        </a>

                        <a href="#" class="dropdown-item">
                            <i class="icon-arrow-right22"></i> Show all articles (85)
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /directory -->


    <!-- Latest added -->
    <div class="mb-3 py-2">
        <h4 class="font-weight-semibold mb-0">Latest articles &amp; videos</h4>
        <span class="text-muted d-block">Dear bawled since some the contrary much hyena jeez clear fox despite the concomitant</span>
    </div>

    <div class="row">
        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-text2 text-success icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Walking away fallaciously</a></h6>
                        Ouch licentiously lackadaisical crud together began gregarious below near darn goodness
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-play text-warning icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Nutria and rewound</a></h6>
                        Strove the darn hey as far oh alas and yikes and gosh knitted this slept via gerbil baneful
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-xml text-info icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Bound befell well</a></h6>
                        And since left before understandably much groomed along burped through dear and gosh
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-text2 text-success icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Porcupine strict nodded</a></h6>
                        Left extravagant leered crab repaid outgrew said knelt hello astride within oh disbanded 
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-play text-warning icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Ducked ravenously dear</a></h6>
                        Reran sincere said monkey one slapped jeepers rubbed fleetly incongruously due yet llama
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-xml text-info icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Contemptibly bleakly</a></h6>
                        Speechless far goodness bent as boa crud because vague far koala the that lantern alas sold
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-text2 text-success icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Blamelessly wow hence</a></h6>
                        A without walking some objective hiccupped some this overlay immorally crud and gosh
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-play text-warning icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Manatee broadcast</a></h6>
                        And some where indecently manta floated raptly youthful unlike swept dragonfly pulled moth
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="card card-body">
                <div class="media">
                    <div class="me-3">
                        <a href="#"><i class="icon-file-xml text-info icon-2x mt-1"></i></a>
                    </div>

                    <div class="media-body">
                        <h6 class="media-title font-weight-semibold"><a href="#" class="text-body">Stretched flamboyant</a></h6>
                        Some when foolhardy dangerous so less less aimlessly along hazardously oversaw much stopped
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /latest added -->


    <!-- Featured articles -->
    <div class="mb-3 py-2">
        <h4 class="font-weight-semibold mb-0">Featured articles and tutorials</h4>
        <span class="text-muted d-block">And porcupine the wallaby far the due thus rash did near dear far pangolin parrot less</span>
    </div>

    <div class="row">
        <div class="col-sm-6 col-lg-3">
            <div class="card">
                <div class="card-img-actions">
                    <img class="card-img-top img-fluid" src="../../assets/images/placeholders/placeholder.jpg" alt="">
                    <div class="card-img-actions-overlay card-img-top">
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill">
                            <i class="icon-download4"></i>
                        </a>
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill ms-2">
                            <i class="icon-link2"></i>
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <h5 class="card-title">For ostrich much</h5>
                    <p class="card-text">Some various less crept gecko the jeepers dear forewent far the ouch far a incompetent saucy wherever towards</p>
                </div>

                <div class="card-footer bg-transparent d-flex justify-content-between">
                    <span class="text-muted">April 12, 2018</span>
                    <span>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-half font-size-base text-warning"></i>
                        <span class="text-muted ms-2">(12)</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="card">
                <div class="card-img-actions">
                    <img class="card-img-top img-fluid" src="../../assets/images/placeholders/placeholder.jpg" alt="">
                    <div class="card-img-actions-overlay card-img-top">
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill">
                            <i class="icon-download4"></i>
                        </a>
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill ms-2">
                            <i class="icon-link2"></i>
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <h5 class="card-title">Helpfully stolidly</h5>
                    <p class="card-text">Hippopotamus aside while a shrewdly this after kookaburra wow in haphazardly much salmon buoyantly sullen gosh</p>
                </div>

                <div class="card-footer bg-transparent d-flex justify-content-between">
                    <span class="text-muted">April 11, 2018</span>
                    <span>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <span class="text-muted ms-2">(35)</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="card">
                <div class="card-img-actions">
                    <img class="card-img-top img-fluid" src="../../assets/images/placeholders/placeholder.jpg" alt="">
                    <div class="card-img-actions-overlay card-img-top">
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill">
                            <i class="icon-download4"></i>
                        </a>
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill ms-2">
                            <i class="icon-link2"></i>
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <h5 class="card-title">Considering far</h5>
                    <p class="card-text">Kookaburra so hey a less tritely far congratulated this winked some under had unblushing beyond sympathetic</p>
                </div>

                <div class="card-footer bg-transparent d-flex justify-content-between">
                    <span class="text-muted">April 10, 2018</span>
                    <span>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-empty3 font-size-base text-warning"></i>
                        <span class="text-muted ms-2">(42)</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-3">
            <div class="card">
                <div class="card-img-actions">
                    <img class="card-img-top img-fluid" src="../../assets/images/placeholders/placeholder.jpg" alt="">
                    <div class="card-img-actions-overlay card-img-top">
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill">
                            <i class="icon-download4"></i>
                        </a>
                        <a href="#" class="btn btn-outline-white btn-icon border-2 rounded-pill ms-2">
                            <i class="icon-link2"></i>
                        </a>
                    </div>
                </div>

                <div class="card-body">
                    <h5 class="card-title">Despite perversely</h5>
                    <p class="card-text">Coming merits and was talent enough far. Sir joy northward sportsmen education. Put still any about manor heard</p>
                </div>

                <div class="card-footer bg-transparent d-flex justify-content-between">
                    <span class="text-muted">April 09, 2018</span>
                    <span>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <span class="text-muted ms-2">(59)</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- /featured articles -->


    <!-- Submit a ticket -->
    <div class="card card-body">
        <div class="media align-items-center align-items-lg-start flex-column flex-lg-row">
            <a href="#" class="text-teal me-lg-3 align-self-lg-center mb-3 mb-lg-0">
                <i class="icon-question7 text-success border-success border-3 rounded-pill p-2"></i>
            </a>

            <div class="media-body text-center text-lg-left">
                <h6 class="media-title font-weight-semibold">Can't find what you're looking for?</h6>
                Maladroit forgetfully under until the fraternally on one much whispered waked much cumulatively some rabidly after thanks hey
            </div>

            <a href="#" class="btn btn-warning align-self-lg-center ms-lg-3 mt-3 mt-lg-0"><i class="icon-mail5 me-2"></i> Submit a ticket</a>
        </div>
    </div>
    <!-- /submit a ticket -->

</template>



<script>
//import Pagine from '@/components/utils/Pagine.vue'
	


export default {
    name:'Guida',
	emits: [],
    components: {
        //Pagine,

        
    },
    data:function(){
        return{
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
                search:{

            }
        }
    },

    methods:{
        



    },

    created : function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
               

    }

}
</script>
