<template>
	
    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="text-dark fw-semibold" v-on:click="getProdotti()"> {{moduleName}} </a> \
					<span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> {{moduleName}}</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovoProdotto()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> {{moduleName}}</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a v-show="this.modulo != 2" href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(this.modulo)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<div v-show="this.modulo == 2" class="dropdown w-100 w-sm-auto" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<a href="#" class="d-flex dropdown-toggle btn btn-outline-light btn-icon w-42px h-32px squared-pill ms-3" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
								<i class="ph-lightning"></i> 
							</a>
							<div class="dropdown-menu dropdown-menu-lg-end w-100 w-lg-auto wmin-300 wmin-sm-350 pt-0">
								<a href="#" class="dropdown-item active py-2" v-on:click="$root.confTravelTipologiche(this.modulo)">
									<i class="ph-buildings w-32px h-32px me-2"></i>
									<div>
										<div class="fw-semibold">Hotels</div>
										<div class="fs-sm text-muted">Gestione Configurazioni</div>
									</div>
								</a>
								<a href="#" class="dropdown-item py-2" v-on:click="$root.confTravelTipologiche(5)">
									<i class="ph-bed w-32px h-32px me-2"></i>
									<div>
										<div class="fw-semibold">Camere</div>
										<div class="fs-sm text-muted">Gestione Configurazioni</div>
									</div>
								</a>
							</div>
						</div>					

						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#product-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> {{moduleName}}</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#product-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-calendar"></i>
								<span class="d-none d-lg-inline-block ms-2">Piano Occupazione</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#product-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
					<li class="nav-item d-lg-none ms-auto">
						<a href="#profile_nav" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded collapsed" data-bs-toggle="collapse" aria-expanded="false">
							<i class="ph-caret-down collapsible-indicator"></i>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getProdotti()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i> Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i> Activity log
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-trash me-2"></i> Elimina
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="product-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="prodotto-a">
				                    <div class="row ">
				                  		<div class="col-6 col-md-1 col-lg-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Prodotto" :tabindex="1" v-model="search.ID_PRODOTTO.VALUE" />
				                    	</div>	
										<div class="col-6 col-md-1 col-lg-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Vecchio" :tabindex="1" v-model="search.FK_ID_CAMPO_OLD.VALUE" />
				                    	</div>	
			                            <div class="col-6 col-md-1 col-lg-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" v-model="search.CODICE_PRODOTTO.VALUE" />
			                            </div> 	
										<div class="col-6 col-md-2">
											<select class="form-select form-select-sm" v-model="search.FK_ID_TIPO.VALUE" data-placeholder="Tipologia" :tabindex="3" >
												<option v-bind:value="''">Tipologia</option>
												<option v-for="t in arrayTipologieProdotto" v-bind:value="t.ID_OPZIONE_TIPOLOGICA" v-bind:key="t.ID_OPZIONE_TIPOLOGICA" >{{ t.DESCRIZIONE }}</option>
											</select>
										</div>               
			                            <div class="col-12 col-md-2">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Prodotto" :tabindex="3" v-model="search.DESCRIZIONE.VALUE" />
			                            </div>
										<div class="col-md-2">
											<Select2 :w="250" :api="'travel/s2/fornitori'" v-model="search.FK_ID_AZIENDA_FORNITORE.VALUE" :tabindex="13" :placeholder="'Seleziona Fornitore'"></Select2>
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-4">
												</div>
												<div class="col-6 col-md-5">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Stato </option>   
														<option value="1"> &nbsp; Attivati</option>
														<option value="0"> &nbsp; Disattivati</option>
													</select>
												</div> 
												<div class="col-6 col-md-3 col-lg-3">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>
											</div>
										</div>  
			                        </div>
			                        <div class="collapse" id="collapse-a" style="">
			                            <div class="row">
			                                <div class="col-6 col-md-2 mt-1">
			                                    <Select2 :w="250" :api="'core/geo/stati/select2'" v-model="search.FK_ID_STATO.VALUE" :tabindex="10" :placeholder="'Seleziona Stato'"></Select2>
			                                </div>
			                                <div class="col-6 col-md-2 mt-1">
			                                    <Select2 :w="250" :api="'core/geo/regioni/select2'"  v-model="search.FK_ID_REGIONE.VALUE" :tabindex="11" :placeholder="'Seleziona Regione'"></Select2>
			                                </div>
			                                <div class="col-6 col-md-2 mt-1">
			                                    <Select2 :w="250" :api="'core/geo/comuni/select2'"  v-model="search.FK_ID_COMUNE.VALUE" :tabindex="12" :placeholder="'Seleziona Comune'"></Select2>
			                                </div>   
		                                    <div class="col-6 col-md-2 mt-1">
												<select class="form-select form-select-sm" data-placeholder="Seleziona Router" :tabindex="6">
													<option>Seleziona Router</option>
												</select>
		                                    </div>
		                                    <div class="col-6 col-md-2 mt-1">
		
		                                    </div>
		                                    <div class="col-6 col-md-2 mt-1">
			                                </div>  
			                            </div>
			                        </div>
							 	</form>
		                    </div>
		                </div>
		            </div>
		
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th class="table-head-sm" style="width:2%;"> <input id="chkAllProducts" v-on:click="filters.selectAll('chkAllProducts',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
			                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
			                            <th class="table-head-sm">Nome Prodotto / SEO /<br />Codice</th>
										<th v-show="modulo == 2 || modulo == 5" class="table-head-sm" style="width:15%;">Servizio / Speciale<br />Categorie</th>
										<th v-show="modulo == 1 || modulo == 3 || modulo == 4" class="table-head-sm" style="width:15%;">Servizio / Durata /<br />Categorie</th>
										<th class="table-head-sm" style="width:17%;">Fornitore  /<br />Località</th>
			                            <th v-show="modulo == 2 || modulo == 5" class="table-head-sm text-center" style="width:5%;">Camere</th>
										<th v-show="modulo == 1 || modulo == 3 || modulo == 4" class="table-head-sm text-center" style="width:7%;">Data inizio<br />Data fine</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Hits</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
			                        </tr>
			                    </thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
		                            <tr v-for="prodotto in paginatedList.arr" v-bind:key="prodotto.ID_PRODOTTO">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="prodotto.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center cursor-pointer"><img src="../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="td-class fs-sm">
											<span>{{prodotto.ID_PRODOTTO}}</span><br />
											<small class="badge bg-light text-primary float-end" v-on:click="modificaOrdinamento(prodotto)">{{prodotto.ORDINE}}</small>
										</td>
		                                <td class="td-class text-center">
											<img v-if="prodotto.ID_PRODOTTO > 0 && prodotto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',prodotto.URL_AVATAR)" class="rounded" width="50" height="35" alt="" />
											<span v-if="prodotto.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto fs-sm" style="line-height: 1rem !important;">No<br />Photo</span>
		                                </td>
		                                <td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openSchedaProdotto(prodotto)">{{prodotto.DESCRIZIONE}}</span>
											<br />
											<span class="me-2" style="border: 1px #dddddd solid;"><i class="far fa-code fa-fw"></i> {{prodotto.CODICE_PRODOTTO}}</span> 
		                                    <span class="badge badge-xs cursor-pointer me-1" v-on:click="updateFlgPrenotabile(prodotto)" v-bind:class="{'bg-success':prodotto.FLG_PRENOTABILE == 1,'bg-danger':prodotto.FLG_PRENOTABILE == 0}"><i class="fal fa-tag fa-fw"></i> <span v-if="prodotto.FLG_PRENOTABILE == 1">Prenotabile</span><span v-else>Stop sale</span></span>  
		                                    <span class="badge bg-light badge-xs text-dark cursor-pointer me-1" style="border: 1px #dddddd solid;"><i class="fal fa-thumbs-up fa-fw"></i> Metti in Evidenza</span>
		                                </td>
		                                <td class="td-class fs-sm">
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info" v-show="modulo == 1 || modulo == 3 || modulo == 4">{{prodotto.DESC_TIPOLOGIA_PRODOTTO}} / {{prodotto.DURATA}}</span>
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info" v-show="modulo == 2 || modulo == 5">{{prodotto.DESC_TIPOLOGIA_PRODOTTO}} / {{prodotto.SPECIALE}}</span>	
											<br />
											<span class="text-muted fs-sm cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="top" :title="prodotto.CATEGORIE">Mostra Categorie</span>
										</td>
		                                <td class="fs-sm">
											<span style="line-height: 1.325rem !important">{{prodotto.FORNITORE}}</span><br />
											<span class="text-muted" style="line-height: 1.325rem !important">{{prodotto.GEO_STATO}}, {{prodotto.GEO_COMUNE}}</span>
										</td>
		                                <td class="fs-sm">
											<span class="cursor-pointer badge rounded-pill" v-show="modulo == 2 || modulo == 5" v-bind:class="{'bg-primary':prodotto.CAMERE>0,'bg-light text-body':prodotto.CAMERE==0}" v-on:click="camere(prodotto)">{{prodotto.CAMERE}}</span>
											<div v-show="modulo == 1 || modulo == 3 || modulo == 4">
												<i class="ph-calendar fs-base lh-base align-top text-success me-1" style="line-height: 1.325rem !important"></i><a class="cursor-default" href="#">{{filters.formatDate(prodotto.DATA_MIN)}}</a>
											</div>
											<div v-show="modulo == 1 || modulo == 3 || modulo == 4">
												<i class="ph-calendar fs-base lh-base align-top text-danger me-1" style="line-height: 1.325rem !important"></i><a class="cursor-default" href="#">{{filters.formatDate(prodotto.DATA_MAX)}}</a>
											</div>
										</td>
		                                <td class="td-class fs-sm text-center">
											<span class="text-secondary">{{prodotto.COUNTER}}</span>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateFlgVisibile(prodotto)" v-show="prodotto.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(prodotto)" v-show="prodotto.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>
										<td class="td-class text-center">
		                                    <ISwitch @toggle="updateFlgAttivo(prodotto)" :size="'sm'" v-model="prodotto.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
		                                <td class="td-class text-center fs-sm">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaProdotto(prodotto)"><i class="fad fa-pencil fa-fw me-2"></i>| Modifica Prodotto</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="modificaContenuti(prodotto)"><i class="fad fa-text fa-fw me-2"></i>| Gestione Contenuti</a>
													<a v-show="modulo != 2" href="#" class="dropdown-item" v-on:click="goToExtra(prodotto)"><i class="fad fa-text fa-fw me-2"></i>| Gestione Extra</a>
													<a href="#" class="dropdown-item" v-show="modulo == 2" v-on:click="camere(prodotto)"><i class="fad fa-bed-alt fa-fw me-2"></i>| Gestione Camere</a>
													<a href="#" class="dropdown-item" v-on:click="calendario(prodotto)"><i class="fad fa-calendar fa-fw me-2"></i>| Gestione Calendario</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteProdotto(prodotto)"><i class="fad fa-trash fa-fw text-danger me-2"></i>| Elimina Prodotto</a>
												</div>
											</div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
		                </div>
		
		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
					</div>
				</div>
    		</div>

			<div class="tab-pane fade" id="product-b">
        	
		        <PianoOccupazione :modulo="modulo"> </PianoOccupazione>

			</div>

			<div class="tab-pane fade" id="product-c">
				<div class="card shadow mb-0">
		            <div class="collapse show">
			            <div class="card-body card-body-2">
							<div class="row">
								<div class="col-3 col-md-2">
									<div class="card card-body card-body-2 bg-primary text-white has-bg-image mb-1">
										<div class="media">
											<div class="media-body">
												<h3 class="mb-0">54,390</h3>
												<span class="text-uppercase fs-sm">total comments</span>
											</div>
											<div class="ms-3 align-self-center">
												<i class="icon-bubbles4 icon-3x opacity-75"></i>
											</div>
										</div>
									</div>
								</div>
							<div class="col-3 col-md-2">
									<div class="card card-body card-body-2 bg-danger text-white has-bg-image mb-1">
										<div class="media">
											<div class="media-body">
												<h3 class="mb-0">389,438</h3>
												<span class="text-uppercase fs-sm">total orders</span>
											</div>
											<div class="ms-3 align-self-center">
												<i class="icon-bag icon-3x opacity-75"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-md-2">
									<div class="card card-body card-body-2 bg-success text-white has-bg-image mb-1">
										<div class="media">
											<div class="me-3 align-self-center">
												<i class="icon-pointer icon-3x opacity-75"></i>
											</div>
											<div class="media-body text-right">
												<h3 class="mb-0">652,549</h3>
												<span class="text-uppercase fs-sm">total clicks</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-md-2">
									<div class="card card-body card-body-2 bg-indigo text-white has-bg-image mb-1">
										<div class="media">
											<div class="me-3 align-self-center">
												<i class="icon-enter6 icon-3x opacity-75"></i>
											</div>
											<div class="media-body text-right">
												<h3 class="mb-0">245,382</h3>
												<span class="text-uppercase fs-sm">total visits</span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-md-2">
									<div class="card card-body card-body-2 bg-primary text-white has-bg-image mb-1">
										<div class="media">
											<div class="media-body">
												<h3 class="mb-0">54,390</h3>
												<span class="text-uppercase fs-sm">total comments</span>
											</div>
											<div class="ms-3 align-self-center">
												<i class="icon-bubbles4 icon-3x opacity-75"></i>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-md-2">
									<div class="card card-body card-body-2 bg-danger text-white has-bg-image mb-1">
										<div class="media">
											<div class="media-body">
												<h3 class="mb-0">389,438</h3>
												<span class="text-uppercase fs-sm">total orders</span>
											</div>
											<div class="ms-3 align-self-center">
												<i class="icon-bag icon-3x opacity-75"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

		                    <div class="alert alert-2 alert-secondary alert2 border-0">
								<form action="#" id="prodotto-b">
				                    <div class="row ">
				                  		<div class="col-6 col-md-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Prodotto" :tabindex="1" v-model="search.ID_PRODOTTO.VALUE" />
				                    	</div>	
										<div class="col-6 col-md-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Vecchio" :tabindex="1" v-model="search.FK_ID_CAMPO_OLD.VALUE" />
				                    	</div>	
			                            <div class="col-7 col-md-1">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice" :tabindex="2" v-model="search.CODICE_PRODOTTO.VALUE" />
			                            </div> 	
										<div class="col-md-2">
											<select class="form-control form-control-sm" v-model="search.FK_ID_TIPO.VALUE" data-placeholder="Tipologia" :tabindex="3" >
												<option v-bind:value="''">Tipologia</option>
												<option v-for="t in arrayTipologieProdotto" v-bind:value="t.ID_OPZIONE_TIPOLOGICA" v-bind:key="t.ID_OPZIONE_TIPOLOGICA" >{{ t.DESCRIZIONE }}</option>
											</select>
										</div>               
			                            <div class="col-12 col-md-2">
			                                <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Prodotto" :tabindex="3" v-model="search.DESCRIZIONE.VALUE" />
			                            </div>
										<div class="col-md-2">
											<Select2 :w="250" :api="'travel/s2/fornitori'" v-model="search.FK_ID_AZIENDA_FORNITORE.VALUE" :tabindex="13" :placeholder="'Seleziona Fornitore'"></Select2>
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div>
												<div class="col-6 col-md-5">
													<select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Stato </option>   
														<option value="1"> &nbsp; Attivati</option>
														<option value="0"> &nbsp; Disattivati</option>
													</select>
												</div> 
												<div class="col-6 col-md-4">
					                                <a href="#collapse-icon" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="14">
					                                    <span style="font-weight: 500;">Altri Filtri</span>
													</a>
												</div>
											</div>
										</div>  
			                        </div>
			                        <div class="collapse" id="collapse-icon" style="">
			                            <div class="row">
			                                <div class="col-6 col-md-2">
			                                    <Select2 :w="250" :api="'/core/geo/stati/select2'" v-model="search.FK_ID_STATO.VALUE" :tabindex="10" :placeholder="'Seleziona Stato'"></Select2>
			                                </div>
			                                <div class="col-6 col-md-2">
			                                    <Select2 :w="250" :api="'core/geo/regioni/select2'"  v-model="search.FK_ID_REGIONE.VALUE" :tabindex="11" :placeholder="'Seleziona Regione'"></Select2>
			                                </div>
			                                <div class="col-6 col-md-2">
			                                    <Select2 :w="250" :api="'core/geo/comuni/select2'"  v-model="search.FK_ID_COMUNE.VALUE" :tabindex="12" :placeholder="'Seleziona Comune'"></Select2>
			                                </div>   
		                                    <div class="col-6 col-md-2">
												<select class="form-control form-control-sm" data-placeholder="Seleziona Router" :tabindex="6">
													<option>Seleziona Router</option>
												</select>
		                                    </div>
		                                    <div class="col-6 col-md-2">
		
		                                    </div>
		                                    <div class="col-6 col-md-2">
			                                </div>  
			                            </div>
			                        </div>
							 	</form>
		                    </div>
		                </div>
		            </div>

					<div class="card-body card-body-2 card-body-nobottom card-body-notop">


						
					</div>
				</div>
			</div>

			<SchedaProdotto @updateProdotto="getProdotti()" :modulo="modulo" :ref="'schedaProdotto'" :arrayCategorie="arrayCategorie"></SchedaProdotto>
			
			<PopupOrdinamento @ordinamentoAggiornato="handlerOrdinamentoAggiornato($event)" :modulo="modulo" :ref="'modalOrdinamento'"></PopupOrdinamento>

		</div>
	</div>	


	<!-- START MODALI -->
    <div class="modal fade" :id="'popUpUploadImgProdotto'+rs" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-light text-dark">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Upload immagine Prodotto </span>
					</h6>  
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body"> 
   
                    <Upload :args="argsProdotto"  @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
	 
                </div>
                <div class="modal-footer bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import SchedaProdotto from '@/components/SchedaProdotto.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'
import PopupOrdinamento from '@/components/utils/PopupOrdinamento.vue'
import PianoOccupazione from '@/components/PianoOccupazione.vue'


export default {
    name:"Prodotti",

    components:{
        Pagine,
        ISwitch,
        Select2,
        SchedaProdotto,
        draggable: VueDraggableNext,
        Upload,
		PopupOrdinamento,
		PianoOccupazione
    },

    props:["modulo"], 

    data:function(){
		return{
            apiStartup:'travel/prodotti',
	        filters:global.filters,
			rs : utils.generateRandomString(30),
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
	        arrayProdotti:new Array(),
	        prodottoSelezionato:{},
			arrayCategorie : new Array(),
			arrayTipologieProdotto : new Array(), 
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_PRODOTTO:{TYPE:'TEXT',VALUE:''},
                CODICE_PRODOTTO:{TYPE:'TEXT',VALUE:''},
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''}, 
	            DESCRIZIONE:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},         	
				FK_ID_STATO:{TYPE:'COMBO',VALUE:''},               
                FK_ID_REGIONE:{TYPE:'COMBO',VALUE:''},               
                FK_ID_COMUNE:{TYPE:'COMBO',VALUE:''},              
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				FK_ID_CAMPO_OLD:{TYPE:'TEXT',VALUE:''},
	        },
			ordineHasChanged: false,
			indirizzoSelezionato:{},
			prodottoExt : {lang:{},dettagli:{}},
            argsProdotto:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:1
			},
      	}
    },

    methods:{
        getProdotti : function(){
            utils.ajax(this.apiStartup,{MODULO:this.modulo,ANNO:this.search.ANNO_ORDINE.VALUE}, (response) => {
				this.ordineHasChanged = false;
                this.arrayProdotti = response.data;
            });
        },	

        nuovoProdotto : function(){
            this.$refs.schedaProdotto.openSchedaProdotto({ID_PRODOTTO:0,FK_ID_MODULO:this.modulo,FK_ID_MODELLO_VENDITA:1,FK_ID_TAG_SPECIALE:0,lang:{FLG_ATTIVO:1},dettagli:{},opzioni:new Array()});
        },		   

        modificaProdotto : function(prodotto){
            this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
        },	

        deleteProdotto : function(prodotto){
            utils.alert.confirm("Sei sicuro di voler eliminare il prodotto <strong>"+prodotto.TITOLO+"</strong>?",()=>{
                utils.ajax("travel/prodotto/delete",{ID_PRODOTTO:prodotto.ID_PRODOTTO}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getProdotti();
                    }
                });
            },this.$root)
        }, 

        updateFlgPrenotabile : function(prodotto){
			prodotto.FLG_PRENOTABILE = prodotto.FLG_PRENOTABILE == 1 ? 0 : 1;
			utils.ajax("travel/prodotto/update",prodotto, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					prodotto.FLG_PRENOTABILE = prodotto.FLG_PRENOTABILE == 1 ? 0 : 1;
				}
			});                
        },	

		updateFlgVisibile : function(prodotto){
			prodotto.FLG_VISIBILE = prodotto.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/prodotto/update",prodotto, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					prodotto.FLG_VISIBILE = prodotto.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
        },	

		updateFlgAttivo : function(prodotto){
            setTimeout(() => {
                utils.ajax("travel/prodotto/lang/update/flgattivo",prodotto, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getProdotti();
                    }
                });                
            },300)
        },	

        calendario : function(prodotto){
            this.$root.prodotto = prodotto;
			this.$root.sottoProdotto = {};
            global.router.push("Calendario");
        },
		
		goToExtra : function(prodotto){
            this.$root.prodotto = prodotto;
			this.$root.sottoProdotto = {};
            global.router.push("Extra");
		},

        camere : function(prodotto){
            this.$root.prodotto = prodotto;
            global.router.push("Camere");
        },	

        openSchedaProdotto: function(prodotto){
            this.$refs.schedaProdotto.openSchedaProdotto(prodotto);
        },

		modificaContenuti : function(prodotto){
            this.$root.prodotto = prodotto;
            global.router.push("ProdottoLang");
        },

      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaProdotti',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayProdotti = this.filters.draggableChangeHandler(event,this.arrayProdotti,this.search,"ID_PRODOTTO");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayProdotti.length ; i++){
					arr.push({"ID": this.arrayProdotti[i].ID_PRODOTTO,"O": this.arrayProdotti[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'ProdottoTuristico',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getProdotti();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		modificaOrdinamento : function(prodotto){
			this.$refs.modalOrdinamento.openPopUpOrdinamento(prodotto,this.arrayProdotti,"ID_PRODOTTO");
		},
		
		handlerOrdinamentoAggiornato : function(_array){
			this.arrayProdotti = utils.clone(_array);
			this.salvaOrdinamento();
		}


    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayProdotti,this.search);
            return arr;
        },

        moduleName(){
           return this.filters.moduleName(this.modulo);
        }, 

        iconaProdotto(){
           return this.filters.iconaProdotto(this.modulo);
        },	
  
		tipoModulo(){
			switch (this.modulo){
				case 1:		return '25';
				case 2:		return '30';
				case 3:		return '35';
				case 4:		return '40';
				case 5:		return '45';
				case 47:	return '30';
			}
			return "";
		}

    }, 
	watch:{
	
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.filters.wait(300,()=>{
			utils.ajax('travel/prodotti/startup',{MODULO:this.modulo}, (response) => {
				this.arrayProdotti = response.data.PRODOTTI;
				var arrayCategorie = response.data.CATEGORIE;
				for (var i = 0 ; i < arrayCategorie.length ; i++){
					this.arrayCategorie.push({id:arrayCategorie[i].ID_CATEGORIA, text:arrayCategorie[i].DESCRIZIONE });
				}
				this.filters.wait(300,()=>{
					var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
					var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
					return new bootstrap.Tooltip(tooltipTriggerEl)
					})
				});
				var idTipologica = 0;
				switch (this.modulo){
					//attivita
					case 1:
						idTipologica = 25;
						break;
					//hotel
					case 2:
						idTipologica = 30;
						break;
					//pacchetti
					case 3:
						idTipologica = 35;
						break;
					//voli
					case 4:
						idTipologica = 40;
						break;
					//camere
					case 5:
						idTipologica = 45;
						break;
				}
				this.arrayTipologieProdotto = utils.array.select(response.data.OPZIONI,"FK_ID_TIPOLOGICA",idTipologica);
			});
		});

        if (this.$root.savedSearch.hasOwnProperty("modulo") && this.$root.savedSearch.modulo == this.modulo){
            this.search.DESCRIZIONE.VALUE = this.$root.savedSearch.TITOLO;
			this.$root.savedSearch.TITOLO = "";
        }
    },

}
</script>