<template>

	<div class="row">
		<div class="col-5 col-lg-2 mb-1">
			<label class="form-label text-danger mb-0">Sesso:</label>
			<div class="input-group">
				<select class="form-select form-select-sm" v-model="pax.personaFisica.SESSO" data-placeholder="Sesso" tabindex="4">
					<option value="0" selected="selected">Sesso</option>
					<option value="1">MR</option>
					<option value="2">MRs</option>
				</select> 
			</div>
		</div>
		<div class="col-5 col-md-5 mb-1"> 
			<label class="form-label text-danger mb-0">Cognome:</label>
			<div class="input-group">
				<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
				<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Cognome Utente" tabindex="5" v-model="pax.personaFisica.COGNOME">
			</div>
		</div>
		<div class="col-5 col-md-5 mb-1">
			<label class="form-label text-danger mb-0">Nome:</label>
			<div class="input-group">
				<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
				<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Nome Utente" tabindex="6" v-model="pax.personaFisica.NOME">
			</div>
		</div>
	      
		<div class="col-3 col-md-3 mb-1">
			<label class="form-label text-danger mb-0">Data nascita</label>
			<Datepicker  v-model="pax.personaFisica.DATA_NASCITA" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
		</div>
		<div class="col-3 mb-1">
			<label class="form-label text-danger mb-0">Cod. Fiscale:</label>
			<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Codice Fiscale" tabindex="18" v-model="pax.personaFisica.CODICE_FISCALE">                                                         	
		</div>
		<div class="col-3 mb-1">
			<label class="form-label text-danger mb-0">Email:</label>
			<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Email utente"  tabindex="16" v-model="pax.personaFisica.EMAIL">
		</div>
		<div class="col-3 mb-1">
			<label class="form-label text-danger mb-0">Cellulare:</label>
			<input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Cellulare"  tabindex="17" v-model="pax.personaFisica.TELEFONO">
		</div>
	</div>

</template>



<script>
import { computed } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'


export default {
    name: 'Pax',
    components:{
        Datepicker
    },
    props: {
      modelValue: {
          type: Object,
          default: () => ({}),
      },
    },
    emits: ['update:modelValue','change','ricerca','update'],
    setup(props, { emit }) {
        const pax = computed({
            get: () => props.modelValue,
            set: (val) => {emit('update:modelValue', val), console.log("AGGIORNATO")},
        });
        return { pax };
    },
    data:function(){
        return{
            
        }
    },
    methods: {
        setCap(item){
            this.pax.personaFisica.CAP = item.CAP;
        },
    },
    computed: {
    
    },
    watch: {
        
    },
}

</script>