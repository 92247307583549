<template>

    <div class="modal fade" id="popUpInvioEmailOrdine" aria-modal="true" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg" > 
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 75%!important;">SCHEDA INVIO EMAIL</h6>
					<button type="button" class="btn-close" data-bs-dismiss="modal"></button>                   
                </div>
                <div class="modal-body p-2" v-show="tipo == 1"> 

                    <div class="row">
                        <div class="col-md-4">
                            <h3>Scegli la lingua</h3> 
                            <div class="alert alert-primary">
                                <label  class="control-label">Seleziona in quale lingua si vuole emettere il documento.</label>
                                <SelectLingue v-model="email.LANG"></SelectLingue>		
                            </div>
                        </div> 
                        <div class="col-md-8">
                            <h3>Seleziona i file <small>(solo per allegarli alla Email)</small></h3> 
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead>
                                    <tr>
                                        <th class="table-head-sm" style="width: 5%; text-align: center;">
                                            <input type="checkbox" name="select_rows" id="chkSelectAllDocsPopUpAllegati" class="select_rows">
                                        </th>
                                        <th class="table-head-sm"><strong>Nome Documento</strong></th>
                                        <th class="table-head-sm" style="width: 15%; text-align: center;">
                                            <strong>Azioni</strong>
                                        </th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    <tr>
                                        <td class="text-center">
                                            <input true-value="1" false-value="0" type="checkbox" class="select_row">
                                        </td>
                                        <td class="fw-bold">
                                            Id_0_Modulo_Prenotazione_Montagna_2024_1_BB.pdf
                                        </td>
                                        <td class="text-center">

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-show="ordine.isPreventivo == 1 || ordine.FK_ID_STATO == 657">
                        <hr />
                        <div class="row">						
                            <div class="col-4" >
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 2rem;"><strong>2.</strong> Data scadenza Preventivo</h3> 
                                <Datepicker v-model="ordine.DATA_SCADENZA_OPZIONE" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>
                            </div>
                            <div class="col-4 bg-light form-group-1">
                                <span class="font-size-lg float-end">Oggetto:</span>
                                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="email.OGGETTO" />
                            </div>
                            
                            <div class="col-4 bg-light form-group-1">
                                <span class="font-size-lg float-end">Invia a :</span>
                                <input autocomplete="off" type="text" class="form-control form-control-sm"  v-model="email.EMAIL" />
                            </div>
                        </div>
                    </div>

                    <div class="card card-collapsed">
                        <div class="card-header header-elements-inline">
                            <h5 class="card-title">
                                <i class="fal fa-eye fa-fw me-1"></i><a class="list-icons-item cursor-pointer ms-3" data-bs-toggle="collapse" data-bs-target="#collapseOneMail" aria-expanded="false" aria-controls="collapseOne"> Mostra Anteprima Email</a>
                            </h5>
                        </div>
                        <div id="collapseOneMail" class="collapse">
                            <div class="card-body">
                                <div v-html="htmlOrdine" style="height:400px;overflow-y: scroll;"></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer bg-light p-2">
                    <div class="row w-100">
                        <div class="col-4">                         
                        </div>
                        <div class="col-8">
                            <span class="btn btn-link float-end" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>
                            <button class="btn btn-primary float-end" v-show="currentTab < 5" v-on:click="generaPdf(1)">GENERA PDF <i class="icon-arrow-right8"></i></button>
                            <button class="btn btn-light float-end me-2" v-show="currentTab > 1" v-on:click="inviaPreventivo(-1)"><i class="icon-arrow-left8 me-2"></i> INVIA PREVENTIVO</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
			
</template>



<script>

import SelectLingue  from '@/components/utils/SelectLingue.vue'
import Datepicker from '@vuepic/vue-datepicker'

export default {
    name:"SchedaEmailOrdine",
    components:{
        SelectLingue,
        Datepicker
    },
    props:['tipo'],
    data : function(){
        return {
            filters:global.filters,
			email:{},
			htmlOrdine:"",
            ordine:{},
            currentTab:1
        }
    },
    methods:{
        startup:function(ordine){
            utils.ajax("travel/ordine/html",{ID_ORDINE:ordine.ID_ORDINE}, (response) => {
				this.htmlOrdine = response.data.HTML;
                this.ordine = response.data.ORDINE;
				const ip = utils.clone(this.ordine.intestatarioPersonaFisica);
				this.email = {
					ID_ORDINE:this.ordine.ID_ORDINE,
					intestatarioPersonaFisica:utils.clone(this.ordine.intestatarioPersonaFisica),
					EMAIL:ip.EMAIL,
					OGGETTO: this.ordine.TITOLO_PRATICA.toString(),
					LANG:this.$root.currentLang
				};
				this.filters.openPopUp("popUpInvioEmailOrdine");
			}); 
        }
    }
}
</script>