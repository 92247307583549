<template>
	
    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<router-link href="#" class="text-dark cursor-pointer" :to="'Hotels'"><span class="font-weight-semibold"> Hotels </span></router-link> \
                    <a v-on:click="back()" class="text-dark cursor-pointer"> {{prodotto.lang.TITOLO}} </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Contenuti</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>	                    
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation" v-for="lang in $root.arrayLingue" v-bind:key="lang.ID_GEO_STATO">
						<a :href="'#tab_lingua'+lang.ID_GEO_STATO" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded prodLang" :data-tab="lang.ID_GEO_STATO" data-bs-toggle="tab" aria-selected="true" role="tab" :id="'btnLingua'+lang.ID_GEO_STATO" v-on:click="linguaSelezionata = lang.ID_GEO_STATO">
							<div class="d-flex align-items-center mx-lg-1">
								<span class="d-none d-lg-inline-block ms-2"><Flag :country="lang.ID_GEO_STATO" class="me-1"></Flag> {{ lang.NOME }}</span>
							</div>
						</a>
					</li>
				</ul>


				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							
						</li>
						<li class="nav-item ms-3">
                      		
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->		
			
    </div>
    <!-- /page header -->	

    <!-- Content area -->
    <div class="content content-1">

        <div class="card shadow mb-0">          
	        <div class="card-body box-card mt-0 mb-0">
				<div class="row">
					<div class="col-lg-4 mbx-1">
						<label>Titolo Prodotto</label>
						<input class="form-control" type="text" v-model="prodottoLang.TITOLO"  />							
					</div>
					<div class="col-lg-4 mbx-1">
						<label>Slogan Prodotto</label>
						<input class="form-control" type="text" v-model="prodottoLang.SOTTOTITOLO" />							
					</div>
					<div class="col-lg-2 mbx-1">
						<label>Stagione Prodotto</label>
						<SelectStagioni @change="changeComboStagioneLingua()"  v-model="currentStagione"></SelectStagioni>				
					</div>
					<div class="col-6 col-lg-1 mbx-1">
						<label>Mese DA</label>
						<input class="form-control" type="text" v-bind:value="getMeseStagione(1)" disabled />							
					</div>
					<div class="col-6 col-lg-1 mbx-1">
						<label>Mese A</label>
						<input class="form-control" type="text" v-bind:value="getMeseStagione(2)" disabled />							
					</div>
				</div> 
				<div class="row mt-3">
					<div class="alert alert-danger alert-styled-left alert-dismissible" v-show="prodottoLang.ID_LANG == 0">
						<span class="font-weight-semibold">Attenzione:</span><br />
						contunto mai salvato per questa lingua / stagione
					</div>

					<div class="col-lg-12">                        
						<ul class="nav nav-tabs mb-2 nav-tabs-highlight" id="myTab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" id="home-tab" data-bs-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
									<i class="far fa-info-square fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Intro </strong></span>
								</a>
							</li> 
							<li class="nav-item">
								<a class="nav-link" id="body-tab" data-bs-toggle="tab" href="#body" role="tab" aria-controls="body" aria-selected="false">
									<i class="far fa-h-square fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Desc. </strong></span>
								</a>
							</li> 
							<li class="nav-item">
								<a class="nav-link" id="include-tab" data-bs-toggle="tab" href="#include" role="tab" aria-controls="include" aria-selected="false">
									<i class="far fa-thumbs-up fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Q. Inc. </strong></span>
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="exclude-tab" data-bs-toggle="tab" href="#exclude" role="tab" aria-controls="exclude" aria-selected="false">
									<i class="far fa-thumbs-down fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Q. Esc. </strong></span>
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="inloco-tab" data-bs-toggle="tab" href="#inloco" role="tab" aria-controls="inloco" aria-selected="false">
									<i class="far fa-map-marker-check fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>In Loco </strong></span>
								</a>
							</li> 
							<li class="nav-item">
								<a class="nav-link" id="orari-tab" data-bs-toggle="tab" href="#orari" role="tab" aria-controls="orari" aria-selected="false">
									<i class="far fa-clock fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Orari </strong></span>
								</a>
							</li> 
							<li class="nav-item">
								<a class="nav-link" id="extra-tab" data-bs-toggle="tab" href="#extra" role="tab" aria-controls="extra" aria-selected="false">
									<i class="far fa-layer-plus fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Suppl. </strong></span>
								</a>
							</li> 

							<li class="nav-item">
								<a class="nav-link" id="policy-tab" data-bs-toggle="tab" href="#policy" role="tab" aria-controls="policy" aria-selected="false">
									<i class="far fa-user-secret fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Policy </strong></span>
								</a>
							</li> 

							<li class="nav-item">
								<a class="nav-link" id="voucher-tab" data-bs-toggle="tab" href="#voucher" role="tab" aria-controls="voucher" aria-selected="false">
									<i class="far fa-file-invoice fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Vouch. </strong></span>
								</a>
							</li> 
						
							
							<li class="nav-item">
								<a class="nav-link" id="note-tab" data-bs-toggle="tab" href="#note" role="tab" aria-controls="note" aria-selected="false">
									<i class="far fa-clipboard fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Note </strong></span>
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="notes-tab" data-bs-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">
									<i class="far fa-user-shield fa-fw me-1"></i> <span class="d-none d-sm-inline"><strong>Admin </strong></span>
								</a>
							</li>    
						</ul>
						<div class="tab-content profile-tab" id="myTabContent">
							
							<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
								<div class="box_450"> 
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo introduttivo:</span><br />
										lunghezza consigliata non più di tre righe.
										Questo testo viene posizionato nei box dei risultati della ricerca e nel dettaglio del prodotto, 
										sotto la galleria fotografica.
									</div><ckeditor :editor="editor" v-model="prodottoLang.TESTO_HOME" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="body" role="tabpanel" aria-labelledby="body-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo descrittivo:</span><br />
										lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info"..
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_BODY" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo Informativo:</span><br />
										lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info"..
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_INFO" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="info" role="tabpanel" aria-labelledby="body-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo info:</span><br />
										info
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_INFO" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="include" role="tabpanel" aria-labelledby="include-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Cosa Include:</span> <br />
										lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato nel dettaglio del prodotto nel 
										Tab nominato "Inc. / Esc." lato sinistro.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_INCLUDE" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="exclude" role="tabpanel" aria-labelledby="exclude-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Cosa Esclude</span> <br />
										lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato nel dettaglio del prodotto nel 
										Tab nominato "Inc. / Esc." lato destro.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_ESCLUDE" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="inloco" role="tabpanel" aria-labelledby="inloco-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo In Loco</span><br />
										lunghezza consigliata nessun limite. Questo testo viene posizionato nella scheda del prodotto verso la fine.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_INLOCO" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="incontro" role="tabpanel" aria-labelledby="inloco-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo In Loco</span><br />
										lunghezza consigliata nessun limite. Questo testo viene posizionato nella scheda del prodotto verso la fine.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_INCONTRO" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="extra" role="tabpanel" aria-labelledby="extra-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo Voucher</span><br /> 
										lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato solo ed esclusivamente nei Voucher.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_VOUCHER" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="policy" role="tabpanel" aria-labelledby="policy-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo Policy</span> <br />
										lunghezza consigliata nessun limite. Questo testo viene posizionato nel dettaglio del prodotto nel 
										Tab nominato "Policy"
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_POLICY" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="voucher" role="tabpanel" aria-labelledby="voucher-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo Voucher</span><br /> 
										lunghezza consigliata non più di 500 caratteri. Questo testo viene posizionato solo ed esclusivamente nei Voucher.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_RECENSIONE" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="note" role="tabpanel" aria-labelledby="note-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo Policy</span> <br />
										This alert needs your attention, but it's not <a href="#" class="alert-link">super important</a>.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_NOTE" :config="editorConfig"></ckeditor>
								</div>
							</div>

							<div class="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
								<div class="box_450">
									<div class="alert alert-info alert-styled-left alert-dismissible">
										<span class="font-weight-semibold">Testo note interne</span><br />
										This alert needs your attention, but it's not <a href="#" class="alert-link">super important</a>.
									</div>
									<ckeditor :editor="editor" v-model="prodottoLang.TESTO_ADMIN" :config="editorConfig"></ckeditor>
								</div>
							</div>

						</div>
						<button type="submit" class="btn btn-primary float-end mt-3 mb-2 me-2" v-on:click="salvaContenuto()"> SALVA </button>
					</div>
				</div>
	        </div>
		</div>	    
    </div>

</template>



<script>


import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import SelectLingue  from '@/components/utils/SelectLingue.vue'
import SelectStagioni  from '@/components/utils/SelectStagioni.vue'
import Flag from '@/components/utils/Flag.vue'


export default {
    name:"ProdottoLang",

    components:{
		//SelectLingue,
		SelectStagioni,
		Flag
    },

    props:["modulo"],
	
    data:function(){
		return{
	        filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
	        arrayProdottoLang:new Array(),
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_LANG:{TYPE:'TEXT',VALUE:''},
                FK_FK_ID_PRODOTTO:{TYPE:'COMBO',VALUE:''}, 
	            TITOLO:{TYPE:'TEXT',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
	        },
			currentLang:0,
			currentStagione:0,
	        prodottoLang:{},
			prodotto:{lang:{}},
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            }
      	}
    },

    methods:{
       salvaContenuto : function(){
			var api = this.prodottoLang.ID_LANG > 0 ? "travel/prodottolang/update" : "travel/prodottolang/insert"; 
            utils.ajax(api,this.prodottoLang, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
					if (this.prodottoLang.ID_LANG == 0){
						this.prodottoLang.ID_LANG = response.id_inserito;
					}
                }
            });
        },
		changeComboStagioneLingua : function(){
			utils.ajax("travel/prodotto/lang",{FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO,FK_ID_LINGUA:this.currentLang,FK_ID_STAGIONE:this.currentStagione},(response)=>{
				this.prodottoLang = response.data;
				if (!this.prodottoLang.ID_LANG > 0){
					this.prodottoLang.ID_LANG = 0;
					this.prodottoLang.FK_ID_PRODOTTO = this.prodotto.ID_PRODOTTO;
					this.prodottoLang.FK_ID_LINGUA = this.currentLang;
					this.prodottoLang.FK_ID_STAGIONE = this.currentStagione;
				}
			});
		},
		getMeseStagione : function(custom){
			var a = utils.array.select(this.$root.arrayStagioni,"ID_OPZIONE_TIPOLOGICA",this.prodottoLang.FK_ID_STAGIONE,1)[0];
			if (a != undefined){
				return a['CUSTOM_'+custom];
			}
			return "";
		}
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayProdottoLang,this.search);
            return arr;
        },

    }, 

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		utils.ajax("travel/prodotto",{ID_PRODOTTO:this.$root.prodotto.ID_PRODOTTO},(response)=>{
            this.prodotto = response.data;
			//this.prodottoLang = this.$root.stagione;
			this.currentLang = this.$root.currentLang;
			this.currentStagione = this.$root.arrayStagioni[0].ID_OPZIONE_TIPOLOGICA;
			var btn = document.getElementById("btnLingua"+this.currentLang);
			btn.click();
			//this.changeComboStagioneLingua();
        });
		//
		setTimeout(() => {
			var tabEl = document.querySelectorAll('.prodLang');
			for (var i = 0 ; i < tabEl.length; i++){
				tabEl[i].addEventListener('shown.bs.tab',  (event) => {
					//event.target // newly activated tab
					//event.relatedTarget // previous active tab
					var tab = event.target.dataset.tab;
					this.currentLang = tab;
					this.changeComboStagioneLingua();
				});
			}
		},300);
    },
}
</script>


