<template>
    <Prodotti :modulo="47"></Prodotti>
</template>
<script>

import Prodotti from '@/components/Prodotti.vue'

export default {
    name:"Shops",
    components:{
        Prodotti,
    },
}
</script>