<template>
    
    <div v-if="modalitaVistaRisultati == 1">
        
        <div class="card h-100">
            <img v-if="risultato.PRODOTTO.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',risultato.PRODOTTO.URL_AVATAR)" class="card-img-top" width="100%" height="150" alt="" />
            <div v-if="risultato.PRODOTTO.URL_AVATAR == 'placeholder.jpg'" class="card-img-top" style="background-color: #ccc; padding: 2.2rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</div>      
            <span class="card-img-actions-overlay card-img">
                <i class="icon-plus3 icon-2x"></i>
            </span>
            <div class="card-body p-1">
                <h6 class="">
                    <a href="#" class="text-body">{{risultato.PRODOTTO.TITOLO}}</a><br />
                    <span class="fs-12">ghjgjgjhgjhg</span>
                </h6>
                <p class="text-center mb-0" style="font-size: 1.5rem;">
                    <strong>&euro; {{filters.formattaImporto(risultato.PREZZO_TOTALE)}}</strong>
                </p>
            </div>
            <div class="ribbon-container">
                <div class="ribbon text-white" v-bind:class="{'bg-teal':risultato.STATO_DISPONIBILITA == 1,'bg-warning':risultato.STATO_DISPONIBILITA == 2}">{{risultato.STATO_DISPONIBILITA == 1 ? 'DISPONIBILE' : 'IN RICHIESTA'}}</div>
            </div>
            <div class="card-footer p-1 border-top-0 bg-light">
                <div class="text-center">
                    <button type="button" class="btn btn-primary" v-on:click="selezionaQuota(this)">
                        <i class="far fa-cart-plus fa-fw me-2"></i> {{preventivo == 1 ? 'PREVENTIVO' : 'PRENOTA'}}
                    </button>
                </div>
            </div>
        </div>

        <!--
        <div class="card-body card-body-2 mb-0" style="padding: 0rem;">
            <div class="card-img-actions">
                <img v-if="risultato.PRODOTTO.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',risultato.PRODOTTO.URL_AVATAR)" class="rounded" width="100%" height="150" alt="" />
                <div v-if="risultato.PRODOTTO.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 2.5rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</div>      
                <span class="card-img-actions-overlay card-img">
                    <i class="icon-plus3 icon-2x"></i>
                </span>
            </div>
        </div>
        <div class="card-body" style="padding: 0.5rem !important;">
            <div class="mb-2">
                <h6 class="font-weight-semibold mb-0">
                    <a href="#" class="text-body">{{risultato.PRODOTTO.TITOLO}}</a>
                </h6>
                <a href="#" class="text-muted">{{risultato.PRODOTTO.CODICE_PRODOTTO}} </a>
            </div>
            <h3 class="text-center font-weight-semibold mb-0">&euro; {{filters.formattaImporto(risultato.PREZZO_TOTALE)}}</h3>

        </div>
        <div class="ribbon-container">
            <div class="ribbon text-white" v-bind:class="{'bg-teal':risultato.STATO_DISPONIBILITA == 1,'bg-warning':risultato.STATO_DISPONIBILITA == 2}">{{risultato.STATO_DISPONIBILITA == 1 ? 'DISPONIBILE' : 'IN RICHIESTA'}}</div>
        </div>
        <div class="card-footer text-center">
            <button type="button" class="btn btn-sm btn-primary font-size-xs" v-on:click="selezionaQuota(this)">
                <i class="far fa-cart-plus fa-fw"></i> {{preventivo == 1 ? 'PREVENTIVO' : 'PRENOTA'}}
            </button>
           
        </div>
        -->
    </div>
    <div v-else >
        <div class="card card-body card-body-2">
            <div class="media align-items-center align-items-lg-start text-center text-lg-left flex-column flex-lg-row">
                <div class="me-lg-3 mb-3 mb-lg-2">
                    <a :href="filters.getImgPath('PRODOTTI/',risultato.PRODOTTO.URL_AVATAR)" data-popup="lightbox">
                        <img v-if="risultato.PRODOTTO.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',risultato.PRODOTTO.URL_AVATAR)" class="rounded" width="250" height="150" alt="" />
                        <div v-if="risultato.PRODOTTO.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 4rem 4rem; width: 99% !important; font-size: 1.5rem;">No<br />Photo</div>      
                    </a>
                </div>
                <div class="media-body">
                    <h6 class="media-title font-weight-semibold">
                        <a href="#">{{risultato.PRODOTTO.TITOLO}}</a>
                    </h6>
                    <ul class="list-inline list-inline-dotted mb-3 mb-lg-2">
                        <li class="list-inline-item"><a href="#" class="text-muted">{{risultato.PRODOTTO.DESCRIZIONE}}</a></li>
                    </ul>
                    <p class="mb-3">{{risultato.PRODOTTO.TITOLO}}</p>
                    <ul class="list-inline list-inline-dotted mb-0">
                        <li class="list-inline-item">All items from <a href="#">Aloha</a></li>
                        <li class="list-inline-item">Add to <a href="#">wishlist</a></li>
                    </ul>
                </div>
                <div class="mt-3 mt-lg-0 ms-lg-3 text-center">
                    <h3 class="mb-0 font-weight-semibold">&euro; {{filters.formattaImporto(risultato.PREZZO_TOTALE)}}</h3>
                    <div>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                        <i class="icon-star-full2 font-size-base text-warning"></i>
                    </div>

                    <div class="text-muted">85 reviews</div>
                    <button type="button" class="btn btn-sm mt-3" :disabled="selezionabile == false" v-on:click="selezionaQuota(this)"  v-bind:class="{'btn-teal':risultato.STATO_DISPONIBILITA == 1,'btn-warning':risultato.STATO_DISPONIBILITA == 2}">
                        <i class="icon-cart-add me-1"></i>  {{risultato.STATO_DISPONIBILITA == 1 ? 'DISPONIBILE' : 'IN RICHIESTA'}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name:'CardRisultatoRicerca',
    props:['risultato','deep',"FK_ID_SERVIZIO","selezionabile","modalitaVistaRisultati","preventivo"],
    emits: ['opzioneSelezionata', 'selezionato'],
    data:function(){
        return{
            filters:global.filters,
            arrayTipologieOpzioni:new Array()
        }
    },
    methods:{
        getQuote : function(){
            if (this.deep == 1){
                if (!(this.FK_ID_SERVIZIO > 0)){
                    return this.risultato.quote;
                }
                else{
                    return utils.array.select(this.risultato.quote,"FK_ID_SERVIZIO",this.FK_ID_SERVIZIO);
                }                
            }
            else{
                return this.risultato.RISULTATI;
            }
        },
        getIdCollapse : function(){
            if (this.deep == 1){
                if (!(this.FK_ID_SERVIZIO > 0)){
                    return this.risultato.ID_QUOTA;
                }
                else{
                    return this.risultato.ID_QUOTA + "_" + this.FK_ID_SERVIZIO;
                }                
            }
            else{
                return this.risultato.PRODOTTO.ID_PRODOTTO;
            }            
        },
        getDescrizioneOpzione : function(idOpzione){
            var r = utils.array.select(this.arrayTipologieOpzioni,"ID_OPZIONE",idOpzione,1)[0];
            return r.CODICE + " - " + r.DESCRIZIONE;
        },
        selezionaQuota : function(quota){
            console.log(quota);
            this.$emit("selezionato");
        },
        selezionaOpzione : function(quota){
            var risultato = utils.array.select(this.risultato.RISULTATI,"ID_QUOTA",quota.FK_ID_QUOTA_PRINCIPALE,1)[0];
            var opzioniSelezionate = utils.clone(risultato.opzioniSelezionate);
            for (var i = 0 ; i < opzioniSelezionate.length ; i++){
                if (opzioniSelezionate[i].FK_ID_SERVIZIO == quota.FK_ID_SERVIZIO){
                    opzioniSelezionate[i].FK_ID_PRICE = quota.FK_ID_PRICE;
                }
            }
            this.$emit("opzioneSelezionata",opzioniSelezionate);
        }
    },
    created : function(){
        this.arrayTipologieOpzioni = this.$root.arrayTipologieOpzioni;
    }
}
</script>