<template>

    <div class="modal fade" id="popUpSchedaQuotaEdit">
	    <div class="modal-dialog modal-lg" > 
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> SCHEDA DETTAGLIO QUOTA </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body"> 

                    <div class="row">
                        <div class="col-7">
                           
                           <div class="row">
                               <div class="col-4">
                                   <div class="form-group-1">
                                       <label class="text-dark custom-control custom-control-right">Tipo Fiscale</label>
                                       <select class="form-control form-control-sm" v-model="quota.FK_ID_TIPO_FISCALE" >
                                            <option v-for="tipo in $root.arrayTipoFiscale" v-bind:value="tipo.ID_OPZIONE_TIPOLOGICA" v-bind:key="tipo.ID_OPZIONE_TIPOLOGICA">{{ tipo.DESCRIZIONE }}</option>
                                       </select>
                                   </div>                                    
                               </div>
                               <div class="col-8">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Fornitore</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control form-control-sm" :disabled="true" v-if="quota.fornitore.personaGiuridica != undefined"  v-bind:value="quota.fornitore.personaGiuridica.DENOMINAZIONE">
                                            <input type="text" class="form-control form-control-sm" :disabled="true" v-else  >
                                            <span class="input-group-prepend"><button class="btn btn-info btn-sm" v-on:click="this.$refs.ricercaFornitore.openRicerca();"><i class="fal fa-search fa-fw"></i></button></span>                                            
                                        </div>
                                    </div> 
                               </div>
                           </div>
       
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Descrizione</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-edit fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm" v-model="quota.DESCRIZIONE">
                                        </div>
                                    </div>                                  
                                </div>
                            </div>

                        </div>
                        <div class="col-5">

                            <div class="row">                          
                                <div class="col-6">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Dal</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-calendar fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm" placeholder="Left addon"  :disabled="true" v-model="quota.DATA_START">
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="col-6">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Al</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control form-control-sm" placeholder="Left addon" :disabled="true" v-model="quota.DATA_END">
                                            <span class="input-group-append"><span class="input-group-text"><i class="fal fa-calendar fa-fw"></i></span></span>
                                        </div>
                                    </div> 
                                </div>  
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Pax ADU</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-user fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm"  :disabled="true" placeholder="Adulti" v-model="quota.TOT_ADU">
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-4">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Pax CHD</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-user fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm"  :disabled="true" placeholder="Bambini" v-model="quota.TOT_CHD">
                                        </div>
                                    </div> 
                                </div>
                                <div class="col-4">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Pax INF</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-user fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm"  :disabled="true" placeholder="Infant" v-model="quota.TOT_INF">
                                        </div>
                                    </div> 
                                </div>
                                
                            </div>
                        
                        </div>
                    </div>

                    <hr class="hr-2">

                    <div class="row">
                        <div class="col-8">

                            <div class="row">
                                <div class="col-3">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Prezzo Totale</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-euro-sign fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm" v-model="quota.PREZZO_TOTALE">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Iva Prezzo</label>
                                        <select class="form-control form-control-sm" v-model="quota.FK_ID_IVA_PREZZO" >
                                            <option v-for="cod in $root.arrayCodiciIva" v-bind:value="cod.ID_OPZIONE_TIPOLOGICA" v-bind:key="cod.ID_OPZIONE_TIPOLOGICA">{{ cod.DESCRIZIONE }}</option> 
                                        </select>
                                    </div>
                                </div>                                
                            
                                <div class="col-3">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Costo Totale</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-euro-sign fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm" v-model="quota.COSTO_TOTALE">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Iva Costo</label>
                                        <select class="form-control form-control-sm" v-model="quota.FK_ID_IVA_COSTO" >
                                            <option v-for="cod in $root.arrayCodiciIva" v-bind:value="cod.ID_OPZIONE_TIPOLOGICA" v-bind:key="cod.ID_OPZIONE_TIPOLOGICA">{{ cod.DESCRIZIONE }}</option> 
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Commissioni Passive</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-euro-sign fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm" v-model="quota.COMMISSIONI">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Iva</label>
                                        <select class="form-control form-control-sm" v-model="quota.FK_ID_IVA_COMMISSIONI" >
                                            <option v-for="cod in $root.arrayCodiciIva" v-bind:value="cod.ID_OPZIONE_TIPOLOGICA" v-bind:key="cod.ID_OPZIONE_TIPOLOGICA">{{ cod.DESCRIZIONE }}</option> 
                                        </select>
                                    </div>
                                </div>
                            
                                <div class="col-4">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Commissioni Attive</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-euro-sign fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm" v-model="quota.COMMISSIONI_ATTIVE">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Iva</label>
                                        <select class="form-control form-control-sm" v-model="quota.FK_ID_IVA_COMMISSIONI_ATTIVE" >
                                            <option v-for="cod in $root.arrayCodiciIva" v-bind:value="cod.ID_OPZIONE_TIPOLOGICA" v-bind:key="cod.ID_OPZIONE_TIPOLOGICA">{{ cod.DESCRIZIONE }}</option> 
                                        </select>
                                    </div>
                                </div>
                            </div>  
                            
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Note</label>
                                        <textarea rows="4" cols="3" class="form-control" placeholder="Default textarea" v-model="quota.NOTE"></textarea>
                                    </div>
                                </div>
                            </div>                     

                        </div>
                        <div class="col-4">

                            <div class="row">
                                <div class="col-4 mb-1">
                                    <div class="custom-control custom-switch mb-2">
                                        <label  for="sc_ls_b">Confermata</label>
                                        <ISwitch :size="'sm'" v-model="quota.FLG_CONFERMATA" style="z-index: 0;"></ISwitch>                                        
                                    </div>
                                    <div class="custom-control custom-switch mb-2">
                                        <label  for="sc_ls_f">Voucher</label>
                                        <ISwitch :size="'sm'" v-model="quota.FLG_VOUCHER" style="z-index: 0;"></ISwitch>                                                                                                                                                                
                                    </div>

                                </div>
                                <div class="col-4 mb-1">
                                    <div class="custom-control custom-switch mb-2">
                                        <label  for="sc_ls_a">Visibile</label>
                                        <ISwitch :size="'sm'" v-model="quota.FLG_VISIBILE" style="z-index: 0;"></ISwitch>                                        
                                    </div>
                                    <div class="custom-control custom-switch mb-2">
                                        <label  for="sc_ls_e">Documenti</label>
                                        <ISwitch :size="'sm'" v-model="quota.FLG_DOCUMENTI" style="z-index: 0;"></ISwitch>                                                                                                                        
                                    </div>

                                </div>
                                <div class="col-4 mb-1">
                                    <div class="custom-control custom-switch mb-2">
                                        <label  for="sc_ls_d">Stampabile</label>
                                        <ISwitch :size="'sm'" v-model="quota.FLG_STAMPABILE" style="z-index: 0;"></ISwitch>                                                                                
                                    </div>
                                    <div class="custom-control custom-switch mb-2">
                                        <label for="sc_ls_c">Solo Costi</label>
                                        <ISwitch :size="'sm'" v-model="quota.FLG_QUOTA_SOLO_COSTI" style="z-index: 0;"></ISwitch>                                        
                                    </div>

                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group-1">
                                        <label class="text-dark custom-control custom-control-right">Modalità di calcolo</label>
                                        <div class="input-group">
                                            <span class="input-group-prepend"><span class="input-group-text"><i class="fal fa-euro-sign fa-fw"></i></span></span>
                                            <input type="text" class="form-control form-control-sm"  :disabled="true" placeholder="Infant" v-model="quota.MODALITA_DI_CALCOLO">
                                        </div>
                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaQuota()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div> 
            </div>
        </div>
    </div>
    
    <RicercaPersonaGiuridica :tipo="2" :ref="'ricercaFornitore'" @selezionato="doModificaFornitore($event)"></RicercaPersonaGiuridica>

</template>



<script>

import Pax from '@/components/common/Pax.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import RicercaPersonaGiuridica from '@/components/RicercaPersonaGiuridica.vue'

export default {
    name:"EditQuota",
    emits:["updateQuota"],
    components:{
        ISwitch,
        RicercaPersonaGiuridica,
        
        //Pax
       /* RisultatoRicerca,
        CardRisultatoRicerca*/
    },
    props:[],
    data:function(){
        return{
            quota : {PRODOTTO:{TITOLO:''},CAMERE:new Array(),fornitore:{personaGiuridica:{}}},
            datiRicerca : {},
            filters:global.filters,
            arrayExtraAggiunti : new Array(),
            importoExtra : 0
        }
    },
    methods:{
        openSchedaQuota : function(quota,datiRicerca){
            utils.ajax("travel/ordine/quota",{ID_QUOTA:quota.ID_QUOTA}, (response) => {
                this.filters.openPopUp("popUpSchedaQuotaEdit");
                this.quota = utils.clone(response.data);
            });
            /*
            if (q.ID_UTENTE == 0){
                this.utente = utils.clone(utente);
                var modal = global.$('#popUpSchedaQuota');
                modal.modal('show');
            }
            else{
                utils.ajax("travel/utente",{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                    this.utente = response.data;
                    var modal = global.$('#popUpSchedaUtente');
                    modal.modal('show');
                });
            }*/
        },
        ///travel/ricerca/getQuote
        getQuoteServizio : function(camera,idServizio){
            //console.log("sadasd");
            return utils.array.select(camera.RISULTATI[0].quote,"FK_ID_SERVIZIO",idServizio);
        },
        selezionaOpzione : function(camera,quota){
            //console.log("seleziona opzione");
            var opzioniSelezionate = utils.clone(camera.RISULTATI[0].opzioniSelezionate);
            for (var i = 0 ; i < opzioniSelezionate.length ; i++){
                opzioniSelezionate[i].ID_CAMERA = camera.CAMERA.ID_CAMERA;
                if (opzioniSelezionate[i].FK_ID_SERVIZIO == quota.FK_ID_SERVIZIO){
                    opzioniSelezionate[i].FK_ID_PRICE = quota.FK_ID_PRICE;
                }
            }
            this.aggiornaRicerca(camera,new Array({CHIAVE:"opzioniSelezionate",VALORE:opzioniSelezionate}));
        },
        aggiornaRicerca : function(camera,arrayParametriAggiuntivi = new Array()){
            var dati = utils.clone(this.datiRicerca);
            for (var i = 0 ; i < arrayParametriAggiuntivi.length ; i++){
                dati[arrayParametriAggiuntivi[i]['CHIAVE']] = arrayParametriAggiuntivi[i]['VALORE'];
            }
            utils.ajax('travel/ricerca',dati, (response) => {
                if (response.esito == 0){
                    for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                        //console.log(this.quota.CAMERE[i].CAMERA.ID_CAMERA);
                        for (var j = 0 ; j < response.data[0].CAMERE.length ; j++){
                            //console.log(response.data[0].CAMERE[j].CAMERA.ID_CAMERA);
                            if (this.quota.CAMERE[i].CAMERA.ID_CAMERA == response.data[0].CAMERE[j].CAMERA.ID_CAMERA){
                                this.quota.CAMERE[i].RISULTATI = response.data[0].CAMERE[j].RISULTATI;
                                break;
                            }
                        }                        
                    }
                   // setTimeout(() => {})
                   // $('#linkQuotaCamera'+camera.ID_CAMERA).click();
                }
            });
        },        
        salvaPax : function(pax){
            utils.ajax("travel/quota/pax/update",pax, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            }); 
        },
        calcolaExtra : function(quota){           
            utils.ajax("travel/quota/extra/calcola",{extra_aggiunti:this.arrayExtraAggiunti}, (response) => {
                if (response.esito == 0){
                    this.importoExtra = response.data.IMPORTO_EXTRA;
                    this.updateQuota();
                }
            }); 
        },
        isExtraSelezionato : function(extra){
            for (var i = 0 ; i < this.arrayExtraAggiunti.length ; i++){
                if (this.arrayExtraAggiunti[i].ID_PAX == extra.ID_PAX && this.arrayExtraAggiunti[i].ID_QUOTA == extra.ID_QUOTA){
                    return true;
                }
            }
            return false;
        },
        getExtraPax : function(extra_disponibili,pax,idQuota){
            var arr = new Array();//utils.clone(extra_disponibili);
            for (var i = 0 ; i < extra_disponibili.length ; i++){
                var extra = utils.clone(extra_disponibili[i]);
                if (extra.MODALITA_DI_CALCOLO == 'NOTTE_A_PERSONA' || extra.MODALITA_DI_CALCOLO == 'TOTALE_A_PERSONA'){
                    if (extra.PAX_TYPE == 'ALL' || extra.PAX_TYPE == pax.TYPE){
                        extra.ID_PAX = pax.ID_PAX;
                        extra.ID_QUOTA = idQuota;
                        arr.push(extra);
                    }                    
                }                
            }
            return arr;
        },
        getExtraCamera : function (extra_disponibili,idQuota){
            var arr = new Array();//utils.clone(extra_disponibili);
            for (var i = 0 ; i < extra_disponibili.length ; i++){
                var extra = utils.clone(extra_disponibili[i]);
                if (extra.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO' || extra.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO'){
                    extra.ID_PAX = 0;
                    extra.ID_QUOTA = idQuota;
                    arr.push(extra);                                     
                }                
            }
            //console.log(arr);
            return arr;
        },
        gestioneSelezioneExtra : function(extra){
            if (this.isExtraSelezionato(extra)){
                for (var i = 0 ; i < this.arrayExtraAggiunti.length ; i++){
                    if (extra.ID_EXTRA == this.arrayExtraAggiunti[i].ID_EXTRA && extra.ID_PAX == this.arrayExtraAggiunti[i].ID_PAX){
                        this.arrayExtraAggiunti.splice(i,1);
                    }
                }
            }
            else{
                this.arrayExtraAggiunti.push(extra);
            }
            this.calcolaExtra();
        },
        updateQuota : function(){
            utils.ajax("travel/ricerca/getQuote",{}, (response) => {
                for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                    this.quota.CAMERE[i]['RISULTATI'][0] = response.data[i];
                }
            });
        }  ,
        getTotaleQuote : function(){
            var prezzoTotale = 0;
            for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                prezzoTotale += parseFloat(this.quota.CAMERE[i]['RISULTATI'][0]['PREZZO_TOTALE']);
            }
            return prezzoTotale;
        },
        doModificaFornitore : function(pg){
            this.quota.FK_ID_AZIENDA_FORNITORE = pg.ID_FORNITORE;
            this.quota.fornitore = {ID_AZIENDA:pg.ID_FORNITORE,personaGiuridica:utils.clone(pg)}
        },
        salvaQuota : function(){
            utils.ajax("travel/ordine/quota/update",this.quota, (response) => {
                if (response.esito == 0){
                    this.filters.closePopUp("popUpSchedaQuotaEdit");
                    utils.alert.success(response.messaggio);    
                    this.$emit("updateQuota");
                }
            });  
            /*
            if (this.quota.IS_TEMP == true){
                utils.ajax("travel/quota/insert",{ID_ORDINE:this.ordine.ID_ORDINE,ID_QUOTA:this.quota.ID_QUOTA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                       
                    }
                });  
            }
            else{
                utils.ajax("travel/quota/update",this.quota, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }
                }); 
            }  */          
        },
    },
    computed:{
        
    }

}
</script>

     