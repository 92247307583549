<template>	 
     
    <div class="row" style="height: 8.75rem;">
        <div class="col-md-4 mb-2">
            <img v-if="azienda.ID_AZIENDA > 0 && azienda.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',azienda.URL_AVATAR)" class="rounded" style="width: 200px !important;" alt="" />
            <div v-if="azienda.URL_AVATAR == 'placeholder.jpg'" src="../assets/images/placeholders/placeholder.jpg" class="squared"></div>
        </div>
        <div class="col-md-8 mb-2" style="height: 6.7rem;">
            <h4 class="text-primary mb-1"> 
                {{azienda.personaGiuridica.ALIAS}}
            </h4>
            <p class="text-dark mb-1"><i class="fal fa-tag fa-fw me-1"></i><span class="text-light me-1">|</span>{{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',azienda.FK_ID_TIPOLOGIA_AZIENDA,'DESCRIZIONE') }}</p>
            <p class="text-dark mb-1"><i class="fal fa-calendar fa-fw me-1"></i><span class="text-light me-1">|</span>{{ filters.formatDateTime(azienda.personaGiuridica.DATA_CREAZIONE) }}</p>
            <p class="fs-sm text-dark mb-1">&nbsp;</p>
            <p class="mb-0">&nbsp;</p>
        </div> 
    </div>

    <p>&nbsp;</p>

    <div class="row bg-light" style="border-top: 1px solid #ccc;">
        <div class="col-12">
            <div class="navbar navbar-expand-lg bg-light border-bottom py-1">
                <div class="container-fluid">
                    <ul class="nav navbar-nav flex-row flex-fill" role="tablist">
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-1" class="navbar-nav-link navbar-nav-link-icon active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-info"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Azienda</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-2" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-users"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Collaboratori</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-3" id="tab-ordini" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-coins"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Ordini</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item me-1" role="presentation">
                            <a href="#scheda-4" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-megaphone"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Accessi</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="scheda-1">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-info me-2"></i>Anagrafica
                            <span v-on:click="modificaAzienda()" class="badge bg-danger bg-opacity-20 text-danger cursor-pointer float-end p-1">
                                <span class="fw-semibold text-danger">Modifica Azienda</span>
                            </span>
                        </legend>	 



                    </fieldset>
                </form>
            </div>
            
			<div class="tab-pane fade" id="scheda-2">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-2"><i class="ph-list-checks me-2"></i>Moduli e Funzioni abilitate
                            
                        </legend>	

                        <div class="box-29">

                            <div class="tableFixHead tableFixHeadRoles">
                                <table class="table table-bordered table-hover table-striped table-togglable">
                                    <thead class="sticky-top">
                                        <tr class="bg-secondary text-white">
                                            <th class="table-head-sm" style="width:12%;"><span >PNR</span></th>
                                            <th class="table-head-sm" style="width:17%;"><span>Data e ora</span></th>
                                            <th class="table-head-sm" style=""><span>TITOLO</span></th>
                                            <th class="table-head-sm text-center" style="width:17%;"><span>Importo</span></th>
                                            <th class="table-head-sm text-center" style="width:17%;"><span>Stato</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="utente in azienda.utenti" v-bind:key="utente.ID_UTENTE">
                                            <td class="td-class fs-sm" style=""><span class="badge bg-info bg-opacity-20 text-info badge-xs">  </span></td>
                                            <td class="td-class fs-sm" style=""><span class="fw-bold cursor-pointer"> </span></td>
                                            <td class="td-class fs-sm" style=""><span>{{ utente.COGNOME }}  </span></td>
                                            <td class="td-class fs-sm text-center" style=""><span>&euro; </span></td>
                                            <td class="td-class fs-sm text-center" style=""><span class="badge bg-opacity-20 badge-xs ">  </span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>  

                        </div>

                    </fieldset>
                </form>        
            </div>            

            <div class="tab-pane fade" id="scheda-3">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-1 mb-2"><i class="ph-coins me-2"></i>Storico delle prenotazioni ({{ filters.formattaImporto(getImportoOrdini) }})
                            <div class="row float-end" style="position: relative; top: -5px;">
                                <div class="col-6">  
                                    <select class="form-select form-select-sm form-select-slim" data-placeholder="Stato Pratica" v-model="searchOrdini.FK_ID_STATO.VALUE" :tabindex="14">
                                        <option v-bind:value="''">Sel. Stato</option>
                                        <option v-for="stato in $root.arrayPraticaStato" v-bind:key="stato.ID_OPZIONE_TIPOLOGICA" v-bind:value="stato.ID_OPZIONE_TIPOLOGICA"> - {{ stato.DESCRIZIONE }}</option>  
                                    </select>
                                </div>
                                <div class="col-6">
                                    <select class="form-select form-select-sm form-select-slim changeyear" id="comboAnno" v-model="searchOrdini.ANNO_ORDINE.VALUE" :tabindex="4">
                                        <option value="">Sel. Anno</option>
                                        <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
                                    </select>
                                </div>
                            </div> 
                        </legend>	
                    
                        <div class="tableFixHead tableFixHeadRoles">
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:12%;"><span >PNR</span></th>
										<th class="table-head-sm" style="width:17%;"><span>Data e ora</span></th>
										<th class="table-head-sm" style=""><span>TITOLO</span></th>
										<th class="table-head-sm text-center" style="width:17%;"><span>Importo</span></th>
										<th class="table-head-sm text-center" style="width:17%;"><span>Stato</span></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="ordine in paginatedListOrdini.arr" v-bind:key="ordine.ID_ORDINE">
										<td class="td-class fs-sm" style=""><span class="badge bg-info bg-opacity-20 text-info badge-xs fs-sm"> {{ ordine.PNR_FORMATTATO }} </span></td>
										<td class="td-class fs-sm" style=""><span class="cursor-pointer"> {{filters.formatDate(ordine.DATA_CREAZIONE) }} </span></td>
										<td class="td-class fs-sm" style=""><span class="text-lowercase"> {{ ordine.TITOLO_PRATICA }} </span></td>
                                        <td class="td-class fs-sm text-center" style=""><span>&euro; {{ ordine.PREZZO_TOTALE }} </span></td>
                                        <td class="td-class fs-sm text-center" style=""><span class="badge bg-opacity-20 badge-xs " v-bind:class="getBtnStatusClass(ordine)"> {{ filters.getLabel($root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',ordine.FK_ID_STATO,'DESCRIZIONE') }} </span></td>
									</tr>
								</tbody>
                            </table>
                        </div>                         

                    </fieldset>
                </form>  
        	</div> 

			<div class="tab-pane fade" id="scheda-4">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-megaphone me-2"></i>Elenco Accessi
                            
                        </legend>	
                      
                        <div class="tableFixHead tableFixHeadRoles">
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:6%;"><span >Utente</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Data e ora</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Azione</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Ip</span></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="accesso in paginatedListAccessi.arr" v-bind:key="accesso.ID_ACCESSO">
										<td class="td-class fs-sm" style=""><span class="badge bg-primary">{{accesso.USERNAME}}</span></td>
										<td class="td-class fs-sm" style=""><span class="fw-bold cursor-pointer">{{filters.formatDateTime(accesso.DATA_ACCESSO)}}</span></td>
										<td class="td-class fs-sm" style=""><span>{{accesso.INDIRIZZO_IP}}</span></td>
                                        <td class="td-class fs-sm" style=""><span>{{accesso.INDIRIZZO_IP}}</span></td>
									</tr>
								</tbody>
                            </table>
                        </div> 
                        
                    </fieldset>
                </form>  
            </div>

        </div>
    </div>


</template>



<script>


export default {
   	name:"SchedaVisualizzazioneAzienda",

   	props:["modulo"],
   	emits:['modificaAzienda'],

   	components:{
		//Select2
   	},

	data:function(){
       	return{
           	filters:global.filters,
           	azienda : {personaGiuridica:{},utenti:new Array()},      
			ts:new Date().getTime(),
           	tipo : 0,  
            arrayAccessi:new Array(),
            arrayOrdini:new Array(),
            searchAccessi:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000000},
                /*
                ESITO:{TYPE:'COMBO',VALUE:''},
                USERNAME:{TYPE:'TEXT',VALUE:''},
                SESSION_ID:{TYPE:'TEXT',VALUE:''},
                DATA_ACCESSO:{TYPE:'DATE',VALUE:''},
                */
            },
            anno:new Date().getFullYear(),
            searchOrdini:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000000000},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: ''},
                FK_ID_STATO:{TYPE:'COMBO',VALUE: ''},
            },
		}
	},

   methods:{
        openSchedaVisualizzazioneAzienda : function(azienda, _tipo, tab = ""){
            this.tipo = _tipo;
            utils.ajax("travel/azienda/detail",{ID_AZIENDA:azienda.ID_AZIENDA}, (response) => {
                this.azienda = response.data; 

                utils.ajax("core/admin/accessi",{ID_AZIENDA:azienda.ID_AZIENDA}, (response) => {
                    this.arrayAccessi = response.data
                    
                    utils.ajax("travel/ordini",{ID_AZIENDA:azienda.ID_AZIENDA,ANNO_ORDINE:0}, (response) => {
                        this.arrayOrdini = response.data
                        for (var i = 0 ; i < this.arrayOrdini.length ; i++){
                            this.arrayOrdini[i].PNR_FORMATTATO = this.filters.getPnr(this.arrayOrdini[i]);
                            this.arrayOrdini[i].SELEZIONATO = 0;
                        }
                    });
                    if (tab == "ordini"){
                        var t = document.getElementById("tab-ordini");
                        t.click();
                    }
                });
                

            });           
        },

        modificaAzienda : function(){
            this.$emit("modificaAzienda",this.azienda);
        },

        getLabelOpzioneAzienda : function(label){
            var opzione = utils.array.select(this.azienda.opzioni,"LABEL",label,1)[0];
            if (opzione != undefined){
                return filters.getLabel(this.$root.arrayAllOpzioni,'ID_OPZIONE_TIPOLOGICA',opzione.VALORE,'DESCRIZIONE');
            }
        },

        getBtnStatusClass : function(ordine){
            var arr = utils.array.select(this.$root.arrayPraticaStato,"ID_OPZIONE_TIPOLOGICA",ordine.FK_ID_STATO,1);
            if (arr.length > 0){
                return "bg-"+ arr[0].ICONA + " " + "text-" + arr[0].ICONA ;
            }
            return "";
        },

   },

   computed:{
       
        paginatedListAccessi(){
            var arr = utils.inGrid(this.arrayAccessi,this.searchAccessi);
            return arr;
        },
        paginatedListOrdini(){
            var arr = utils.inGrid(this.arrayOrdini,this.searchOrdini);
            return arr;
        },
        getImportoOrdini(){
            var filtrato = global.utils.filter(this.arrayOrdini,this.searchOrdini);
            return utils.array.sumByField(filtrato,"PREZZO_TOTALE");
        }

   }

}
</script>