<template>

    <div>
        <Aziende :tipo="1"></Aziende>
    </div>

</template>



<script>

import Aziende from '@/components/common/Aziende.vue'


export default {
    name:"Agenzie",
    components:{
        Aziende,
    },
    
}
</script>