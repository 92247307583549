<template>

	<!-- Content area -->
	<div class="content content-login justify-content-center align-items-center">
		
		<div class="row no-gutter" style="margin-right: 0; margin-left: 0;">
			<div class="col-md-8 d-none d-md-flex bg-image" v-bind:style="swapseason()">
				
			</div>
			<div class="col-md-4 bg-light bar-login">
				<div class="login d-flex align-items-center py-5">
					<div class="container">
						<div class="row">
							<div class="col-lg-10 col-xl-7 mx-auto">
								<h3 class="display-login">Login Amministratore</h3>
								<p class="text-muted mb-4">Inserisci la tua Login e Password per entrare.</p>
								
								<div class="form-floating mb-3">
									<input type="text" class="form-control shadow-sm px-4" v-model="utente.USERNAME" placeholder="Email address">
									<label for="floatingInput">Email address</label>
								</div>
								<div class="form-floating">
									<input  :type="pwd" id="pwd" class="form-control shadow-sm px-4 text-primary" v-on:keyup.enter="login()" v-model="utente.PASSWORD" placeholder="Password">
									<a v-on:click="togglePassword()" class="form-control-feedback cursor-pointer">
										<i class="icon-lock2 text-muted"></i>
									</a>
									<label for="floatingPassword">Password</label>
								</div>
								<br />
								<button v-on:click="login()" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">Collegati</button>
								<div class="text-center d-flex justify-content-between mt-4">
									<p class="form-text text-center text-muted">By continuing, you're confirming that you've read<br />
										our <a href="#">Terms &amp; Conditions</a> and <a href="#">Cookie Policy</a>
									</p>
								</div>
								
								<div class="copyco">
									<p class="text-muted">&copy; 2022 <a href="https://www.webfortravel.it" target="_blank">Web for Travel</a></p> 
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	<!-- Content area -->

</template>


<script>
export default {
    name: 'Login',
    data:function(){
        return {
			GLOBAL_IMAGES_PATH:'',
            utente:{
       
            },
			pwd:'password'
        }
    },

    methods : {
        login(){
            global.utils.ajax('core/login',{USERNAME:this.utente.USERNAME,PASSWORD:this.utente.PASSWORD}, (response) => {
                if (response.esito == 0){
                    global.utils.setCookie("session_token",response.data.SESSION_TOKEN);
                    global.utils.TOKEN = response.token;
                    this.$root.startHandler();
                }
                else{
                    global.utils.alert.warning(response.responseText);
                }
            });
        },
		togglePassword : function(){
			this.pwd = this.pwd == 'password' ? 'text' : 'password';
		},
		swapseason : function(){
			var currentTime = new Date();
			var month = currentTime.getMonth() + 1;
			switch(month) {
				case 6:
				case 7:
				case 8:
					var season = this.GLOBAL_IMAGES_PATH+'BACKGROUNDS/summer.jpg';
					break;
				case 9:
				case 10:
				case 11:
					var season = this.GLOBAL_IMAGES_PATH+'BACKGROUNDS/fall.jpg';
					break;
				case 12:
				case 1:
				case 2:
					var season = this.GLOBAL_IMAGES_PATH+'BACKGROUNDS/winter.jpg';
					break;
				case 3:
				case 4:
				case 5:
					var season = this.GLOBAL_IMAGES_PATH+'BACKGROUNDS/spring.jpg';
					break;					
				default:
					var season = this.GLOBAL_IMAGES_PATH+'/assets/images/backgrounds/login.jpg';
			}
			var stile = {};
			stile['background-image'] = "url('" + season + "')";
			return stile;
		}
		
    },

    created : function(){
		this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.$root.utente = {ID_UTENTE:0,funzionalita: new Array()};
        global.utils.setHeader('fa-sign-in','Login');
    }
}
</script>