<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                    <router-link href="#" class="text-dark fw-semibold" :to="''+moduleRoute+''">{{moduleName}}</router-link> \
                    <router-link href="#" class="text-dark fw-semibold" :to="''+moduleRoute+''">Elenco Hotels</router-link> \
                    <a class="text-dark fw-semibold cursor-pointer" v-on:click="goToRoute()"> {{prodotto.lang.TITOLO}} </a> \
                    <router-link href="#" class="text-dark fw-semibold cursor-pointer" v-if="prodotto.FK_ID_MODULO == 2" :to="'Camere'"> Elenco Camere \ </router-link>
                    <span class="text-muted"> Calendario</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaQuota()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Ordine</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div> 

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#calendario-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2">Calendario Camere</span>
							</div>
						</a>
					</li>

				</ul> 

				
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Importa Listino
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Esporta Listino
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-trash me-2"></i> Elimina Calendario
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
	                        <select class="form-select form-select-sm" v-model="annoCorrente" @change="buildCalendario()" :tabindex="4" style="width:100px;">
	                            <option v-for="anno in arrayAnni" v-bind:key="anno">{{anno}}</option>
	                        </select>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
    <!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="calendario-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#">
			                        <div class="row">
			                            <div class="col-4 col-md-2" v-if="prodotto.FK_ID_MODULO == 2">
			                                <select class="form-select form-select-sm" v-model="sottoProdottoCorrente" @change="buildCalendario()">
			                                    <option v-bind:value="0">Seleziona una camera</option>
                                                <optgroup label="CAMERE ATTIVE">
                                                    <template v-for="sp in prodotto.sottoProdotti"  v-bind:key="sp.ID_PRODOTTO" >
                                                        <option v-if="sp.FLG_ATTIVO == 1" v-bind:value="sp.ID_PRODOTTO">
                                                            {{ sp.TITOLO }}
                                                        </option>
                                                    </template>
                                                </optgroup>
                                                <optgroup label="CAMERE NON ATTIVE">
                                                    <template v-for="sp in prodotto.sottoProdotti"  v-bind:key="sp.ID_PRODOTTO" >
                                                        <option v-if="sp.FLG_ATTIVO == 0" v-bind:value="sp.ID_PRODOTTO">
                                                            {{ sp.TITOLO }}
                                                        </option>
                                                    </template>
                                                </optgroup>
			                                </select>
			                            </div>
			                            <div class="col-4 col-md-2" v-else disabled="disabled">
			                                <select class="form-select form-select-sm" v-model="sottoProdottoCorrente" @change="sottoProdottoChangeHandler()">
			                                    <option v-bind:value="prodotto.ID_PRODOTTO">{{prodotto.DESCRIZIONE}}</option>
			                                </select>
			                            </div>
			                            <div class="col-4 col-md-2">
			                                <select class="form-select form-select-sm" v-model="opzioneCorrente" @change="buildCalendario()">
			                                    <option value="0">Mostra tutte le tipologie</option>
			                                    <option v-for="opzione in opzioniSottoProdotto" v-bind:key="opzione.FK_ID_OPZIONE" v-bind:value="opzione.FK_ID_OPZIONE">{{opzione.CUSTOM_1}} - {{opzione.DESC_OPZIONE}} </option>
			                                </select>
			                            </div>
			                            <div class="col-4 col-md-2">
			                                <select class="form-select form-select-sm" v-model="meseCorrente" @change="buildCalendario()">
			                                    <option value="0">Mostra Tutto l'anno</option>
			                                    <option v-for="i in 12" v-bind:key="i" v-bind:value="i">{{filters.getMese(i,"DESCRIZIONE")}}</option>
			                                </select>
			                            </div>
			                            <div class="col-4 col-md-5">
			                            </div>
			                            <div class="col-4 col-md-1">
			                            </div>
			                        </div> 	  
								</form>
							</div>
						</div> 
					</div>
						
			        <div class="card-body mt-0 mb-0">
						<div class="box-calendar">
			                <table class="table table-bordered table-hover table-striped table-togglable">
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
			                            <th v-bind:style="getStileHeaderCalendario()" class="cell-black" style="width:5%;">&nbsp;</th>
			                            <th v-bind:style="getStileHeaderCalendario()" class="text-center" v-for="(n,i) in 37" v-bind:key="i" style="width:2.567%; padding: 0;">
			                                <span style="font-size:12px; font-weight:bold;">{{getLblGiorno(i)}}</span>
			                            </th>
			                        </tr>
			                    </thead>
								<tbody>
			                        <template v-for="mese in calendario" v-bind:key="mese.ID">
			                            <tr class="cell-grey" v-show="meseCorrente == 0 || mese.ID == meseCorrente">
			                                <td class="cell-black" style="width:5%; vertical-align: text-top;">
			                                    <span class="text-dark text-left" style="font-size:12px; height:20px;"><strong>{{getLblMese(mese.ID)}}</strong></span>
			                                </td>
			                                <td style="width:2.575%; background-color: #eee; font-size:12px; vertical-align: text-top;" v-for="cella in mese.CELLE" v-bind:key="cella.INDEX"  v-bind:style="getStyleGiorno(cella)">
			                                    <div v-show="cella.GIORNO > 0" style="text-align:center; height:20px; font-weight:bold; font-size:12px;">{{cella.GIORNO}}</div>
			                                </td>                   
			                            </tr> 
			                            <template v-if="meseCorrente == 0 || mese.ID == meseCorrente" > 
			                                <tr v-for="opzione in mese.OPZIONI" v-bind:key="opzione.FK_ID_OPZIONE" v-show="opzioneCorrente == 0 || opzioneCorrente == opzione.FK_ID_OPZIONE" >
			                                    <template v-if="opzione.FK_ID_PRODOTTO == sottoProdottoCorrente">
			                                        <td style="width:5%; padding:4px" v-on:click="gestioneSelezioneCelleMese(mese,opzione)">
			                                            <span style="font-size:10px;margin-top:4px;font-weight:bold" v-bind:class="{'bg-secondary':(prodotto.FK_ID_MODULO == 2 && getSottoProdottoCorrente.FLG_ATTIVO == 1) || (prodotto.FK_ID_MODULO != 2 && prodotto.lang.FLG_ATTIVO == 1), 'bg-danger':getSottoProdottoCorrente.FLG_ATTIVO == 0}" class="badge badge-xs d-block" >
			                                                {{opzione.DESC_OPZIONE}}
			                                            </span>
			                                        </td>
			                                        <td style="width:2.575%; font-size:10px; vertical-align:text-top; padding:4px;" class="calendario-td" v-bind:style="getStyleCellaPrezzo(cella,opzione.FK_ID_OPZIONE)" v-for="cella in mese.CELLE" v-bind:key="cella.INDEX" @mouseover="mouseOverHandler(mese,cella)" v-on:click="selezionaCella(cella,opzione.FK_ID_OPZIONE,mese,$event)" >
			                                            <span style="background-color: #fff; font-size:10px;" v-if="cellaByOpzione(cella.PRICE,opzione.FK_ID_OPZIONE) != null" class="badge badge-flat border-primary text-primary rounded-0">{{cellaByOpzione(cella.PRICE,opzione.FK_ID_OPZIONE)}}</span>
			                                        </td>
			                                    </template>
			                                </tr>
			                            </template>
			                        </template>
			                    </tbody>
			                </table>
						</div>

					</div>
				</div>
			</div>



		</div>
	</div>	  
	

	<!-- START MODALI -->
    <div class="modal fade" id="popUpDettaglioPartenze" role="dialog"  aria-hidden="true" tabindex="-1">
	    <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;"> Scheda Calendario </h6>                                                        
                    <div class="btn-group float-end" :tabindex="2">
						<a href="#" class="btn btn-sm btn-yellow btn-yellow-100 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
						<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
                            <a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Modifiche multiple </a>
                            <a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Clona stessa camera </a> 
                            <a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Clona camera divers </a> 
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2"> 

                    <div class="row " v-if="arrayCelleSelezionate.length > 0">
                        <div class="col-6 col-md-3 form-group-1">
                            <label class="text-danger custom-control custom-control-right">Data Da</label>
                            <Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" v-model="dataDa"></Datepicker>
                        </div>
                        <div class="col-6 col-md-3 form-group-1">
                            <label class="text-danger custom-control custom-control-right">Data A</label>
                            <Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" v-model="dataA"></Datepicker>
                        </div>
                        <div class="col-6 col-md-3 form-group-1">
                            <label class="text-danger custom-control custom-control-right">Abilita quantità</label>
                            <ISwitch v-model="flgModificaMaster"></ISwitch>
                        </div>
                        <div class="col-6 col-md-3 form-group-1">
                            <label class="text-danger custom-control custom-control-right">Quantità Master</label>
                            <select class="form-control" v-model="priceSelezionata.MAX_PRENOTAZIONI" >
                                <option v-for="i in 100" v-bind:key="i">{{i}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-7">
                            <fieldset>
                                <legend class="font-weight-semibold">Dati Tecnici</legend>
                                <div class="row">
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Min Partecipanti</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.MIN_PAX">
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Quantita</label>
                                        <select v-model="priceSelezionata.MAX_PRENOTAZIONI_OPZIONE" class="form-control">
                                            <option v-for="i in 100" v-bind:key="i">{{i}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Rimborsabile</label>
                                        <select v-model="priceSelezionata.COMMISSIONABILE" class="form-control">
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>

                                    <div class="col-6 col-md-4 form-group-1" v-show="prodotto.FK_ID_MODULO != 1">
                                        <label class="text-danger custom-control custom-control-right">Min stay</label>
                                        <select v-model="priceSelezionata.MIN_STAY" class="form-control">
                                            <option v-for="i in 100" v-bind:key="i">{{i}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-show="prodotto.FK_ID_MODULO != 1">
                                        <label class="text-danger custom-control custom-control-right">Max stay</label>
                                        <select v-model="priceSelezionata.MAX_STAY" class="form-control">
                                            <option v-for="i in 100" v-bind:key="i">{{i}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-show="prodotto.FK_ID_MODULO != 1">
                                        <label class="text-danger custom-control custom-control-right">Release</label>
                                        <select v-model="priceSelezionata.RELEASE_DAYS" class="form-control">
                                            <option v-for="i in 100" v-bind:key="i">{{i}}</option>
                                        </select>
                                    </div> 

                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Entrata</label>
                                        <select v-model="priceSelezionata.CHECKIN_DAY" class="form-control">
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Uscita</label>
                                        <select v-model="priceSelezionata.CHECKOUT_DAY" class="form-control">
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div> 
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Commissionabile</label>
                                        <select v-model="priceSelezionata.COMMISSIONABILE" class="form-control">
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-5 line-v-left-1">
                            <fieldset>
                                <legend class="font-weight-semibold">Listino prezzi</legend>
                                <div class="row">
                                    <div class="col-6 col-md-6 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Tipo di calcolo</label>
                                        <select v-model="priceSelezionata.TIPO_CALCOLO" class="form-control" @change="priceSelezionata.MODALITA_DI_CALCOLO = ''">
                                            <option value="">Seleziona</option>
                                            <option value="PREZZO">Prezzo</option>
                                            <option value="MARKUP">Markup</option>
                                            <option value="PRICE_GAIN">Price Gain</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-6 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Modalità di calcolo</label>
                                        <select v-model="priceSelezionata.MODALITA_DI_CALCOLO" class="form-control" :disabled="priceSelezionata.TIPO_CALCOLO == ''">
                                            <option value="">Seleziona</option>
                                            <option value="TOTALE_A_PERSONA" v-show="priceSelezionata.TIPO_CALCOLO != 'PRICE_GAIN'">Totale a persona</option>
                                            <option value="NOTTE_A_PERSONA"  v-show="priceSelezionata.TIPO_CALCOLO != 'PRICE_GAIN'">Notte a persona</option>
                                            <option value="TOTALE_A_SERVIZIO">Totale a servizio</option>
                                            <option value="NOTTE_A_SERVIZIO">Notte a servizio</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row" v-show="priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_PERSONA' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_PERSONA'">
                                    <div class="col-6 col-md-4 form-group-1" v-if="priceSelezionata.TIPO_CALCOLO == 'PREZZO'">
                                        <label class="text-danger custom-control custom-control-right">Prezzo ADU</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.PREZZO_FISSO_ADU" :disabled="priceSelezionata.TIPO_CALCOLO != 'PREZZO' || priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-if="priceSelezionata.TIPO_CALCOLO == 'PREZZO'">
                                        <label class="text-danger custom-control custom-control-right">Prezzo CHD</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.PREZZO_FISSO_CHD" :disabled="priceSelezionata.TIPO_CALCOLO != 'PREZZO' || priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-if="priceSelezionata.TIPO_CALCOLO == 'PREZZO'">
                                        <label class="text-danger custom-control custom-control-right">Prezzo INF</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.PREZZO_FISSO_INF" :disabled="priceSelezionata.TIPO_CALCOLO != 'PREZZO' || priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                    </div>

                                    <div class="col-6 col-md-4 form-group-1" v-if="priceSelezionata.TIPO_CALCOLO == 'MARKUP'">
                                        <label class="text-danger custom-control custom-control-right">Markup ADU</label>
                                        <select  class="form-control"  v-model="priceSelezionata.MARKUP_ADU" :disabled="priceSelezionata.TIPO_CALCOLO != 'MARKUP' || priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                            <option v-for="i in 100" v-bind:value="i" v-bind:key="i">{{ i }}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-if="priceSelezionata.TIPO_CALCOLO == 'MARKUP'">
                                        <label class="text-danger custom-control custom-control-right">Markup CHD</label>
                                        <select  class="form-control"  v-model="priceSelezionata.MARKUP_CHD" :disabled="priceSelezionata.TIPO_CALCOLO != 'MARKUP' || priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                            <option v-for="i in 100" v-bind:value="i" v-bind:key="i">{{ i }}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-if="priceSelezionata.TIPO_CALCOLO == 'MARKUP'">
                                        <label class="text-danger custom-control custom-control-right">Markup INF</label>
                                        <select  class="form-control"  v-model="priceSelezionata.MARKUP_INF" :disabled="priceSelezionata.TIPO_CALCOLO != 'MARKUP' || priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                            <option v-for="i in 100" v-bind:value="i" v-bind:key="i">{{ i }}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1" v-show="priceSelezionata.MODALITA_DI_CALCOLO != 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO != 'NOTTE_A_SERVIZIO'">
                                        <label class="text-danger custom-control custom-control-right">Costo ADU</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.COSTO_ADU">
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Costo CHD</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.COSTO_CHD" :disabled="priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                    </div>
                                    <div class="col-6 col-md-4 form-group-1">
                                        <label class="text-danger custom-control custom-control-right">Costo INF</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.COSTO_INF" :disabled="priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                    </div>
                                </div>

                                <div class="row" v-show="priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO'">
                                    <div  class="col-6 form-group-1" v-show="priceSelezionata.TIPO_CALCOLO == 'PRICE_GAIN'" >
                                        <label class="text-danger custom-control custom-control-right">Price gain</label>
                                        <select  class="form-control"  v-model="priceSelezionata.PRICE_GAIN" >
                                            <option v-for="i in 200" v-bind:value="i" v-bind:key="i">{{getValorePercentuale(i)}}</option>
                                        </select>
                                    </div>

                                    <div  class="col-6 form-group-1" v-show="priceSelezionata.TIPO_CALCOLO == 'PREZZO'" >
                                        <label class="text-danger custom-control custom-control-right">Prezzo fisso servizio</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.PREZZO_FISSO" >
                                    </div>

                                    <div class="col-6 form-group-1" v-show="priceSelezionata.TIPO_CALCOLO == 'MARKUP'" >
                                        <label class="text-danger custom-control custom-control-right">Markup servizio</label>
                                        <select  class="form-control"  v-model="priceSelezionata.MARKUP" >
                                            <option v-for="i in 100" v-bind:value="i" v-bind:key="i">{{i}}</option>
                                        </select>
                                    </div>

                                    <div class="col-6 form-group-1"  >
                                        <label class="text-danger custom-control custom-control-right">Costo servizio</label>
                                        <input type="text" class="form-control"  v-model="priceSelezionata.COSTO" :disabled="priceSelezionata.MODALITA_DI_CALCOLO == 'TOTALE_A_PERSONA' || priceSelezionata.MODALITA_DI_CALCOLO == 'NOTTE_A_PERSONA'">
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                                
                    <div class="row">
                        <div class="col-md-3 form-group-1">
                            <label class="text-dark custom-control custom-control-right">Custom 1</label>
                            <input type="text" class="form-control"  v-model="priceSelezionata.CUSTOM_1">
                        </div>
                        <div class="col-md-3 form-group-1">
                            <label class="text-dark custom-control custom-control-right">Custom 2</label>
                            <input type="text" class="form-control"  v-model="priceSelezionata.CUSTOM_2">
                        </div>
                        <div class="col-md-3 form-group-1">
                            <label class="text-dark custom-control custom-control-right">Custom 3</label>
                            <input type="text" class="form-control"  v-model="priceSelezionata.CUSTOM_3">
                        </div>
                        <div class="col-md-3 form-group-1">
                            <label class="text-dark custom-control custom-control-right">Custom 4</label>
                            <input type="text" class="form-control"  v-model="priceSelezionata.CUSTOM_4">
                        </div>
                    </div>
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaPrice()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>


</template>



<script>  

import ISwitch from '@/components/utils/ISwitch.vue';
import Datepicker from '@vuepic/vue-datepicker'

export default {
    name:'Calendario',
    props:["modulo"],

    components: {
        ISwitch,
        Datepicker
    },	   

    data:function(){
        return{
            filters:global.filters,
            calendario:new Array(),
            arrayAnni:[new Date().getFullYear()-1,new Date().getFullYear(),new Date().getFullYear()+1,new Date().getFullYear()+2],
            annoCorrente : new Date().getFullYear(),
            meseCorrente: new Date().getMonth()+1,
            opzioneCorrente:0,
            sottoProdottoCorrente:0,
            lastCellaSelezionata:{GIORNO:0},
            prodotto:{lang:{},sottoProdotti:new Array(),opzioniSottoProdotti:new Array()},
            selezioneAperta:0,
            arrayCelleSelezionate : new Array(),
            priceSelezionata:{},
            dataDa:'',
            dataA:'',
            flgModificaMaster:0
        }
    },

    methods: {
        buildCalendario : function(){
            utils.ajax("travel/prodotto/calendario",{ID_PRODOTTO:this.prodotto.ID_PRODOTTO,DATA_START:this.annoCorrente+"-01-01",DATA_END:this.annoCorrente+"-12-31"},(response)=>{
                this.calendario = new Array();
                var arrayPrice = response.data.PRICE;
                if (this.prodotto.FK_ID_MODULO == 2){
                    arrayPrice = utils.array.select(arrayPrice,"FK_ID_PRODOTTO",this.sottoProdottoCorrente);
                }
                console.log("***AVVIO CALENDARIO***");
                //CREO I MESI
                var dataCorrente = moment(this.annoCorrente+"-01-01").startOf('Year');
                for (var i = 1 ; i < 13 ; i++){
                    var endOfMonth = moment(dataCorrente).endOf('month');
                    //CREO IL MESE
                    var mese = {ID:i,CELLE:new Array(),OPZIONI:new Array()}
                    //CREO I GIORNI PARTENDO DALLA DOMENICA
                    var currentWeekDay = 0;
                    var meseIniziato = 0;
                    if (i == 12){
                        console.log("ciano");
                    }
                    for (var j = 0 ; j < 37 ; j++){
                        var cella = {GIORNO:0,WEEKDAY:currentWeekDay,INDEX:j,DATA:'',PRICE:new Array()};
                        //SE SONO NELLA PRIMA SETTIMANA E NON HO ANCORA TROVATO IL PRIMO GIORNO DEL MESE
                        if (meseIniziato == 0){
                            if (dataCorrente.day() == currentWeekDay){
                                meseIniziato = 1;
                                cella.GIORNO = dataCorrente.date();
                                cella.DATA = dataCorrente.format('YYYY-MM-DD');
                                dataCorrente.add(1, "days");
                            }
                        }
                        else{
                            if (dataCorrente <= endOfMonth){
                                cella.GIORNO = dataCorrente.date();
                                cella.DATA = dataCorrente.format('YYYY-MM-DD');
                                if (dataCorrente < endOfMonth){
                                    dataCorrente.add(1, "days");  
                                } 
                            }
                        }
                        currentWeekDay++;
                        if (currentWeekDay == 7){
                            currentWeekDay = 0;
                        }
                        if (cella.DATA != ''){
                            for (var z = arrayPrice.length - 1 ; z > -1 ; --z ){
                                if (arrayPrice[z].DATA_START == cella.DATA){
                                    var p = utils.clone(arrayPrice[z]);
                                    cella.PRICE.push(p);
                                    arrayPrice.splice(z,1);
                                }
                            }
                        }
                        mese.CELLE.push(cella);
                    }
                    this.calendario.push(mese);
                }
                for (var i = 0 ; i < this.calendario.length ; i++){
                    this.calendario[i].OPZIONI = new Array();
                    if (this.sottoProdottoCorrente == this.prodotto.ID_PRODOTTO){
                        for (var k = 0 ; k < this.prodotto.opzioni.length ; k++){
                            this.calendario[i].OPZIONI.push({FK_ID_PRODOTTO:this.prodotto.ID_PRODOTTO,
                                                            FK_ID_OPZIONE:this.prodotto.opzioni[k].FK_ID_OPZIONE,
                                                            DESC_OPZIONE: this.valutaDescOpzione(utils.array.select(this.prodotto.opzioni,"FK_ID_OPZIONE",this.prodotto.opzioni[k].FK_ID_OPZIONE,1)[0]['DESC_OPZIONE']) });
                        }
                        for (var j = 0 ; j < this.calendario[i].CELLE.length ; j++){
                            this.calendario[i].CELLE[j].OPZIONI = new Array();
                            for (var k = 0 ; k < this.prodotto.opzioni.length ; k++){
                                this.calendario[i].CELLE[j].OPZIONI.push({GIORNO:this.calendario[i].CELLE[j].GIORNO,MESE:this.calendario[i].ID,FK_ID_OPZIONE:this.prodotto.opzioni[k].FK_ID_OPZIONE,SELEZIONATO:0});
                            }
                        }  
                    }
                    else{
                        for (var k = 0 ; k < this.prodotto.opzioniSottoProdotti.length ; k++){
                            this.calendario[i].OPZIONI.push({FK_ID_PRODOTTO:this.prodotto.opzioniSottoProdotti[k].FK_ID_PRODOTTO,
                                                            FK_ID_OPZIONE:this.prodotto.opzioniSottoProdotti[k].FK_ID_OPZIONE,
                                                            DESC_OPZIONE: this.valutaDescOpzione(utils.array.select((this.prodotto.FK_ID_MODULO == 2 ? this.prodotto.opzioniSottoProdotti :this.prodotto.opzioni),"FK_ID_OPZIONE",this.prodotto.opzioniSottoProdotti[k].FK_ID_OPZIONE,1)[0]['DESC_OPZIONE'])});
                        }
                        for (var j = 0 ; j < this.calendario[i].CELLE.length ; j++){
                            this.calendario[i].CELLE[j].OPZIONI = new Array();
                            for (var k = 0 ; k < this.prodotto.opzioniSottoProdotti.length ; k++){
                                this.calendario[i].CELLE[j].OPZIONI.push({GIORNO:this.calendario[i].CELLE[j].GIORNO,MESE:this.calendario[i].ID,FK_ID_OPZIONE:this.prodotto.opzioniSottoProdotti[k].FK_ID_OPZIONE,SELEZIONATO:0});
                            }
                        }                        
                    }
                }
            });
        },	

        getLblGiorno(giorno){
            var giornoCorrente = 0;
            for (var i = 0 ; i < 37 ; i++){
                if (i == giorno){
                    return utils.getGiornoSettimana(giornoCorrente+1).DESC_2;
                }
                giornoCorrente++;
                if (giornoCorrente > 6){
                    giornoCorrente = 0;
                }
            }
        },	   

        getLblMese(mese){
            return utils.getMese(mese).DESC_3;
        }, 

        getStyleGiorno(cella){
            if (cella.WEEKDAY == 0){
                return {background:'#aaa'};
            }
            else if (cella.WEEKDAY == 6){
                return {background:'#bbb'};
            }
            return {};
        }, 

        cellaByOpzione : function(arrayPrice,opzione){
            for (var i = 0 ; i < arrayPrice.length ; i++){
                if (arrayPrice[i].FK_ID_OPZIONE == opzione){
                    return this.filters.formattaImportoInt(arrayPrice[i].PREZZO_FISSO_ADU);
                }
            }
            return null;
        }, 

        sottoProdottoChangeHandler : function(){
            this.opzioneCorrente = 0;
        }, 

        getStyleCellaPrezzo:function(cella,opzione){
            var stile = {};
            for (var i = 0 ; i < cella.OPZIONI.length ; i++){
                if (cella.OPZIONI[i].FK_ID_OPZIONE == opzione){
                    if (cella.OPZIONI[i].SELEZIONATO == 1){
                        stile.background = '#244ec9ed';
                        stile.color = "#fff";
                    }    
                    else if (cella.OPZIONI[i].HOVER == 1){
                        stile.background = '#36b9cc';
                    }      
                    else{
                        if (cella.WEEKDAY == 0 ){
                            return {background:'#aaa'};
                        }
                        else if (cella.WEEKDAY == 6 ){
                            return {background:'#bbb'};
                        }
                    }         
                }
            }
            return stile;
        }, 

        selezionaCella : function(cella,opzione,mese,event){
            console.log("selezionaCella");
            if (cella.GIORNO > 0){
                if (this.lastCellaSelezionata.GIORNO > 0) {
                    if (cella.GIORNO >= this.lastCellaSelezionata.GIORNO){
                        for (var j = 0 ; j < mese.CELLE.length ; j++){
                            var c = mese.CELLE[j];
                            if (c.GIORNO >= this.lastCellaSelezionata.GIORNO && c.GIORNO <= cella.GIORNO){
                                for (var i = 0 ; i < c.OPZIONI.length ; i++){
                                    if (c.OPZIONI[i].FK_ID_OPZIONE == opzione && opzione == this.lastCellaSelezionata.OPZIONE){
                                        c.OPZIONI[i].SELEZIONATO = 1;//cella.OPZIONI[i].SELEZIONATO == 1 ? 0 : 1;
                                        if (cella.GIORNO == c.GIORNO){
                                            var c = utils.clone(cella);
                                            c.MESE = mese.ID;
                                            c.OPZIONE = opzione;
                                            this.lastCellaSelezionata = c;
                                            this.selezioneAperta = 0;
                                            break;                                            
                                        }
                                    }
                                }  
                                this.setCelleSelezionate();
                                this.priceSelezionata = {
                                    MAX_PRENOTAZIONI:1,
                                    MIN_PAX:1,
                                    MIN_STAY:1,
                                    MAX_STAY:28,
                                    RELEASE_DAYS:1,
                                    CHECKIN_DAY:1,
                                    CHECKOUT_DAY:1,
                                    COMMISSIONABILE:0,
                                    MARKUP:100,
                                    PRICE_GAIN:100,
                                    PREZZO:100,
                                    TIPO_CALCOLO:'',
                                    MARKUP_ADU:100,
                                    MARKUP_CHD:100,
                                    MARKUP_INF:100
                                };
                                this.filters.openPopUp("popUpDettaglioPartenze"); 
                                var myModalEl = document.getElementById('popUpDettaglioPartenze')
                                myModalEl.addEventListener('hidden.bs.modal', (event) => {
                                    this.azzeraSelezionati();
                                })
                                return; 
                            }
                        }
                    } 
                }     
                else{
                    this.selezioneAperta = 1;
                    for (var i = 0 ; i < cella.OPZIONI.length ; i++){
                        if (cella.OPZIONI[i].FK_ID_OPZIONE == opzione){
                            cella.OPZIONI[i].SELEZIONATO = cella.OPZIONI[i].SELEZIONATO == 1 ? 0 : 1;
                            var c = utils.clone(cella);
                            c.MESE = mese.ID;
                            c.OPZIONE = opzione;
                            this.lastCellaSelezionata = c;
                            break;
                        }
                    }  
                }    
                if (this.arrayCelleSelezionate.length == 1){
                    this.priceSelezionata = utils.clone(this.lastCellaSelezionata.PRICE[0]);
                }    
            }
        },	

        gestioneSelezioneCelleMese:function(mese,opzione){
            opzione.SELEZIONATO = 1;
            this.lastCellaSelezionata.OPZIONE = opzione;
            this.lastCellaSelezionata.MESE = mese.ID;
            for (var j = 0 ; j < mese.CELLE.length ; j++){
                var c = mese.CELLE[j];
                for (var i = 0 ; i < c.OPZIONI.length ; i++){
                    if (c.OPZIONI[i].FK_ID_OPZIONE == opzione.FK_ID_OPZIONE && c.GIORNO > 0){
                        c.OPZIONI[i].SELEZIONATO = opzione.SELEZIONATO;
                    }
                }                                 
            }
            this.setCelleSelezionate();
            this.filters.openPopUp("popUpDettaglioPartenze");
            var myModalEl = document.getElementById('popUpDettaglioPartenze')
            myModalEl.addEventListener('hidden.bs.modal', (event) => {
                this.azzeraSelezionati();
            })
        },

        mouseOverHandler : function(mese,cella){
            if (this.selezioneAperta == 1 && mese.ID == this.lastCellaSelezionata.MESE){
                console.log(mese);
                console.log(cella.GIORNO);
                for (var i = 0 ; i < mese.CELLE.length ; i++){
                    var hover = 0;
                    if (mese.CELLE[i].GIORNO >= this.lastCellaSelezionata.GIORNO && cella.GIORNO >= mese.CELLE[i].GIORNO){
                        hover = 1;
                    }
                    for (var j = 0 ; j < mese.CELLE[i].OPZIONI.length ; j++){
                        if (mese.CELLE[i].OPZIONI[j].FK_ID_OPZIONE == this.lastCellaSelezionata.OPZIONE ){
                            mese.CELLE[i].OPZIONI[j].HOVER = hover;
                        }
                        else{
                            mese.CELLE[i].OPZIONI[j].HOVER = 0;
                        }
                    }
                }
            }
        },	

        setCelleSelezionate : function(){
            this.arrayCelleSelezionate = new Array();
            var opzione = this.lastCellaSelezionata.OPZIONE;
            for (var i = 0 ; i < this.calendario.length ; i++){
                if (this.calendario[i].ID == this.lastCellaSelezionata.MESE){
                    for (var j = 0 ; j < this.calendario[i].CELLE.length ; j++){
                        for (var k = 0 ; k < this.calendario[i].CELLE[j].OPZIONI.length ; k++){
                            if (this.calendario[i].CELLE[j].OPZIONI[k].SELEZIONATO == 1){
                                this.arrayCelleSelezionate.push(this.calendario[i].CELLE[j].OPZIONI[k]);
                            }
                        }
                    }
                    break;
                }
            }
            this.dataDa = this.formattaData(this.arrayCelleSelezionate[0].GIORNO)+"/"+this.formattaData(this.arrayCelleSelezionate[0].MESE)+"/"+this.annoCorrente;
            this.dataA = this.formattaData(this.arrayCelleSelezionate[this.arrayCelleSelezionate.length-1].GIORNO)+"/"+this.formattaData(this.arrayCelleSelezionate[this.arrayCelleSelezionate.length-1].MESE)+"/"+this.annoCorrente;
/*
            if (this.arrayCelleSelezionate.length > 1){
                this.dataA = this.formattaData(this.arrayCelleSelezionate[this.arrayCelleSelezionate.length-1].GIORNO)+"/"+this.formattaData(this.arrayCelleSelezionate[this.arrayCelleSelezionate.length-1].MESE)+"/"+this.annoCorrente;
            }
            else{
                this.dataA = this.dataDA;
            }*/
        }, 

        azzeraSelezionati : function(){
            for (var i = 0 ; i < this.calendario.length ; i++){
                for (var j = 0 ; j < this.calendario[i].CELLE.length ; j++){
                    for (var k = 0 ; k < this.calendario[i].CELLE[j].OPZIONI.length ; k++){
                        this.calendario[i].CELLE[j].OPZIONI[k].SELEZIONATO = 0;
                        this.calendario[i].CELLE[j].OPZIONI[k].HOVER = 0;
                    }
                }
            }
            this.lastCellaSelezionata = {GIORNO:0};
            this.selezioneAperta = 0;
        },	

        formattaData(data){
            if (data < 10){
                return '0'+data;
            }
            return data;
        }, 

        salvaPrice : function (){
            utils.ajax("travel/calendario/salva",{price:this.priceSelezionata,dataDa:this.dataDa,dataA:this.dataA,FK_ID_OPZIONE:this.arrayCelleSelezionate[0].FK_ID_OPZIONE,FK_ID_PRODOTTO:this.sottoProdottoCorrente},(response)=>{
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    //$('#popUpDettaglioPartenze').on('hide.bs.modal', this.azzeraSelezionati);
                    this.buildCalendario();                    
                }
            });
        }, 

        back : function(){
            switch (this.prodotto.FK_ID_MODULO){
                case 2:
                    this.$root.savedSearch = {TITOLO:this.prodotto.lang.TITOLO,modulo:this.prodotto.FK_ID_MODULO};
                    global.router.push("Hotels");
                    break;
            }
        },	

        getValorePercentuale : function($i){
            var $descr = "";
            if ($i == 0){
                $descr = "0";
            }    
            else if ($i < 100){
                $descr = " - " + (100 - $i) + "%";
            }
            else if ($i == 100){
                $descr = "Stesso valore";
            }
            else{
                $descr = " + " + ($i - 100) + "%";
            }
            return $descr;
        }, 

        getStileHeaderCalendario : function(){
            var stile = {};
            if (this.getSottoProdottoCorrente.FLG_ATTIVO == 0){
                stile['background-color'] = "#ef5350";
            }
            return stile;
        }, 

        goToRoute : function(){
            this.$root.savedSearch.TITOLO = this.prodotto.lang.TITOLO;
            this.$root.savedSearch.modulo = this.prodotto.FK_ID_MODULO;
            this.filters.openRoute(this.moduleRoute)
        }, 

        valutaDescOpzione : function(val){
            if (this.prodotto.FK_ID_MODULO == 2){
                var arr = utils.array.select(this.$root.arrayRtsType,"DESCRIZIONE",val,1)[0];
                if (arr != undefined){
                    return arr['CUSTOM_1'];
                }
                return val;
            }
            return val
        } 

    },	

    computed:{
        opzioniSottoProdotto: function() {
            if (this.sottoProdottoCorrente > 0){
                if (this.prodotto.ID_PRODOTTO == this.sottoProdottoCorrente){
                    return this.prodotto.opzioni;    
                }
                return utils.array.select(this.prodotto.opzioniSottoProdotti,"FK_ID_PRODOTTO",this.sottoProdottoCorrente);
            }
            return new Array();
        },
        getSottoProdottoCorrente : function(){
            console.log("sp");
            var arr = utils.array.select(this.prodotto.sottoProdotti,"ID_PRODOTTO",this.sottoProdottoCorrente,1)[0];
            if (arr != undefined){
                return arr;
            }
            return {};
        },
        moduleName(){
            switch (this.prodotto.FK_ID_MODULO){
                case 1:
                    return 'Attività';
                case 2:
                    return 'Hotel';
                case 3:
                    return 'Pacchetti';
                case 4:
                    return 'Voli';
                default:
                    return 'Hotel';
            }
        },
        moduleRoute(){
            switch (this.prodotto.FK_ID_MODULO){
                case 1:
                    return 'Activities';
                case 2:
                    return 'Hotels';
                case 3:
                    return 'Packages';
                case 4:
                    return 'Flights';
                case 4:
                    return 'Camere';
                default:
                    return 'Hotel';
            }
        },
        customName(){
            switch (this.prodotto.FK_ID_MODULO){
                case 1:
                    return 'Attività';
                case 2:
                    return 'Hotel';
                case 3:
                    return 'Pacchetti';
                case 4:
                    return 'Voli';
                default:
                    return 'Hotel';
            }
        },

    },
    created : function(){
        utils.ajax("travel/prodotto",{ID_PRODOTTO:this.$root.prodotto.ID_PRODOTTO},(response)=>{
            this.prodotto = response.data;
            if (this.$root.sottoProdotto.ID_PRODOTTO > 0){
                this.sottoProdottoCorrente = this.$root.sottoProdotto.ID_PRODOTTO;
            }
            else{
                if (this.prodotto.sottoProdotti.length > 0){
                    this.sottoProdottoCorrente = 0;//this.prodotto.sottoProdotti[0].ID_PRODOTTO;
                }    
                else{
                    this.sottoProdottoCorrente = utils.clone(this.prodotto).ID_PRODOTTO;
                }            
            }
            //$('#popUpDettaglioPartenze').on('hide.bs.modal', this.azzeraSelezionati);
            this.buildCalendario();
        });
    }
}
</script>