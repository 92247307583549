<template>

    <div class="card">
        <div class="sidebar-section-header border-bottom">
            <span class="fw-semibold">Chat</span>
            <div class="ms-auto">
                <div class="input-group">
                    <span class="input-group-text"><i class="ph-magnifying-glass"></i></span>
                    <input class="form-control form-control-sm" autocomplete="off" name="filterUsername" placeholder="Filtra per username" type="text" v-model="searchChat.USERNAME">
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs nav-tabs-underline nav-justified mb-0" role="tablist">
            <li class="nav-item fw-bold" role="presentation">
                <a href="#staff-c" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
                    <i class="ph-user me-1"></i>Staff
                </a>
            </li>
            <li class="nav-item fw-bold" role="presentation">
                <a href="#groups-c" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                    <i class="ph-users-three me-1"></i>Gruppi 
                    <span class="badge bg-primary badge-xs ms-4" v-if="isGod" @click="openGroupModal"><i class="fal fa-plus-circle"></i></span>
                </a>
            </li>
        </ul>
        <div class="tab-content card-body p-0">

            <div class="tab-pane fade active show" id="staff-c" role="tabpanel">
                <div class="box-chat pt-2">   

                    <div class="list-group list-group-borderless pt-1 pb-0" v-for="chat in paginatedList.arr" :key="chat.ID_CHAT" v-on:click="selectChat(chat.ID_CHAT)">
                        <a href="#" class="list-group-item list-group-item-action hstack gap-3">
                            <div class="status-indicator-container">
                                <img v-if="chat.idUtente > 0 && chat.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',chat.URL_AVATAR)" class="w-40px h-40px rounded-pill" alt="" />
                                <div v-if="chat.URL_AVATAR == 'placeholder.jpg' || chat.idUtente == 0"><img src="../assets/images/placeholders/placeholder.jpg" class="w-40px h-40px rounded-pill" alt=""></div>
                                <span class="status-indicator bg-success"></span>
                            </div>
                            <div class="flex-fill">
                                <div class="d-flex justify-content-between">
                                    <div v-if="!isGod">
                                        <a href="#">{{ chat.username }}</a>
                                    </div>
                                    <div v-else>
                                        <a href="#">{{ chat.utenti.map(utente => utente.USERNAME === this.$root.utente.USERNAME ? 'Tu' : utente.USERNAME).join(' => ') }}</a>
                                    </div>
                                    <span class="fs-sm text-muted">{{chat.ultimo_messaggio ? filters.formatTimeOnly(chat.ultimo_messaggio.DATA_RICEZIONE) : '' }} </span>
                                </div>
                                <span class="fs-sm text-muted">{{ chat.ultimo_messaggio ? filters.accorciaTesto(chat.ultimo_messaggio.TEXT, 10) : '' }}</span>
                                <span v-if="isGod" class="badge bg-danger badge-xs cursor-pointer float-end" @click.stop="deleteChat(chat.ID_CHAT)"><i class="far fa-trash text-white"></i></span>
                            </div>
                        </a>
                    </div>
                
                </div>
                <div class="p-1 pb-2">
                    <Pagine v-model="searchChat.PAGINATION.PAGE_NUMBER" :modalita="2" :numrighe="paginatedList.count" :numpagine="searchChat.PAGINATION.LENGTH"></Pagine>
                </div>
            </div>

            <div class="tab-pane fade" id="groups-c" role="tabpanel">
                <div class="box-chat">

                    <div class="list-group list-group-borderless py-1" v-for="chat in filteredGroups" :key="chat.ID_CHAT" v-on:click="selectChat(chat.ID_CHAT)">
                        <a href="#" class="list-group-item list-group-item-action hstack gap-3">
                            <div class="status-indicator-container">
                                <img v-if="chat.FK_ID_USER_CREATOR == 1 && chat.IMAGE_GRUPPO != 'placeholder.jpg'" :src="filters.getImgPath('USERS',chat.IMAGE_GRUPPO)" class="w-40px h-40px rounded-pill" alt="" />
                                <div v-if="chat.IMAGE_GRUPPO == 'placeholder.jpg'"><img src="../assets/images/placeholders/placeholder.jpg" class="w-40px h-40px rounded-pill" alt=""></div>
                                <span class="status-indicator bg-success"></span>
                            </div>
                            <div class="flex-fill">
                                <div class="d-flex justify-content-between">
                                    <a href="#">{{ chat.NOME_GRUPPO }}</a>
                                    <span class="fs-sm text-muted">14:58</span>
                                </div>
                                {{ chat.ultimo_messaggio ? filters.accorciaTesto(chat.ultimo_messaggio.TEXT, 10) : '' }}
                            </div>
                            <div v-if="isGod" style="font-size: 80%; text-align: right;">
                                <button class="btn btn-warning btn-sm" @click.stop="editChat(chat)">
                                    <i class="fas fa-pencil-alt"></i> Modifica
                                </button>
                                <button class="btn btn-danger btn-sm" @click.stop="deleteChat(chat.ID_CHAT)">
                                    <i class="fas fa-trash-alt"></i> Elimina
                                </button>
                            </div>
                        </a>
                    </div>

                </div>
            </div>

        </div>
    </div>


    <!-- Modale per la creazione del gruppo -->
    <div class="modal" id="groupModal" tabindex="-1" aria-labelledby="groupModalLabel">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="groupModalLabel">Crea Gruppo</h5>
            <button type="button" class="btn-close" @click="closeGroupModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Pagina 1: Seleziona utenti -->
            <div v-if="modalStep === 1">
              <div class="mb-3">
                <div class="row">
                  <div class="col">
                    <label for="filterUsername" class="form-label">Filtra per username</label>
                    <input class="form-control form-control-sm" autocomplete="off" name="filterUsername"
                      placeholder="Filtra per username" type="text" v-model="search.USERNAME.VALUE">
                  </div>
                </div>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in paginatedListUtenti.arr" :key="user.ID_UTENTE">
                    <td><input type="checkbox" :value="user.ID_UTENTE" v-model="selectedUsers"></td>
                    <td>{{ user.USERNAME }}</td>
                  </tr>
                </tbody>
              </table>
              <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListUtenti.count"
                :numpagine="search.PAGINATION.LENGTH"></Pagine>
            </div>

            <!-- Pagina 2: Inserisci nome del gruppo -->
            <div v-if="modalStep === 2">
              <div class="mb-3">
                <label for="groupName" class="form-label">Nome del Gruppo</label>
                <input type="text" class="form-control form-control-sm" placeholder="Inserisci nome del gruppo"
                  id="groupName" v-model="groupName">
              </div>
              <div class="mb-3">
                <label for="groupNameImage" class="form-label">Nome Immagine Gruppo</label>
                <input type="text" class="form-control form-control-sm" placeholder="Inserisci nome immagine del gruppo"
                  id="groupNameImage" v-model="groupNameImage">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- Pulsanti per navigare tra le pagine -->
            <button type="button" class="btn btn-secondary" v-if="modalStep === 2"
              @click="modalStep = 1">Indietro</button>
            <button type="button" class="btn btn-primary" v-if="modalStep === 1" @click="modalStep = 2">Vai
              Avanti</button>
            <button type="button" class="btn btn-primary" v-if="modalStep === 2" @click="createGroup">Salva</button>
          </div>
        </div>
      </div>
    </div>

</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'

export default {
    name: "ListChat",

    components: {
        Pagine,
    },

    data() {
        return {
            filters: global.filters,
            searchChat: {
                PAGINATION: { LENGTH: 0, PAGE_NUMBER: 1, PAGE_SIZE: 7 },
                USERNAME: ''  // Aggiungi questa proprietà per il filtro
            },
            search: {
                PAGINATION: { LENGTH: 0, PAGE_NUMBER: 1, PAGE_SIZE: 25 },
                USERNAME: { TYPE: 'TEXT', VALUE: '' },
            },
            arrayChatUtente: [],
            groupChats: [],
            view: 'chats',
            allUsers: [],
            selectedUsers: [],
            groupName: '',
            groupNameImage: '',
            isGod: false,
            modalStep: 1,
            ID_CHAT: 0,
            clonedChat: null,
        };
    },
  
  methods: {
    getChat() {
      utils.ajax('chad_2/chats', { FK_ID_USER_CREATOR: this.$root.utente.ID_UTENTE }, (response) => {
        this.arrayChatUtente = response.data.filter(chat => chat.utenti.length === 2);
        this.groupChats = response.data.filter(chat => chat.utenti.length > 2);
      });
    },
    selectChat(idChat) {
      this.$emit('chat-selezionata', idChat);
    },
    toggleView(view) {
      this.view = view;
    },
    openGroupModal() {
      this.selectedUsers = [];
      this.groupName = '';
      this.groupNameImage = '',
      this.ID_CHAT = 0; 
      this.modalStep = 1; 
      this.clonedChat = null; 
      this.fetchAllUsers();
      this.filters.openPopUp("groupModal");
    },
    closeGroupModal() {
        this.filters.closePopUp("groupModal");
    },
    fetchAllUsers() {
      utils.ajax('chad_2/utenti', {}, (response) => {
        this.allUsers = response.data;
        this.preselectUsers(); 
      });
    },
    preselectUsers() {
      if (this.clonedChat) {
        this.selectedUsers = this.clonedChat.utenti.map(user => user.ID_UTENTE);
      }
    },
    editChat(chat) {
      this.clonedChat = utils.clone(chat); 
      this.groupName = this.clonedChat.NOME_GRUPPO; 
      this.groupNameImage = this.clonedChat.IMAGE_GRUPPO
      this.ID_CHAT = this.clonedChat.ID_CHAT;
      this.modalStep = 1;
      this.fetchAllUsers();
      this.filters.openPopUp("groupModal");
    },
    createGroup() {
      if (this.selectedUsers.length <= 2) {
        alert("Seleziona almeno 2 utenti per creare un gruppo.");
        return;
      }
      if (!this.groupName) {
        alert("Inserisci un nome per il gruppo.");
        return;
      }
      
      const payload = {
        utentiSelezionati: this.selectedUsers,
        FK_ID_USER_CREATOR: this.$root.utente.ID_UTENTE,
        NOME_GRUPPO: this.groupName,
        IMAGE_GRUPPO: (this.groupNameImage !='' ? this.groupNameImage : 'placeholder.jpg' )
      };

      if (this.ID_CHAT > 0) {
        payload.ID_CHAT = this.ID_CHAT;
        utils.ajax('chad_2/chat/update', payload, (response) => {
          if (response.esito == 0) {
            this.closeGroupModal();
            this.getChat();
            utils.alert.success(response.messaggio);
          }
        });
      } else {
        utils.ajax('chad_2/chat', payload, (response) => {
          if (response.esito == 0) {
            this.closeGroupModal();
            this.getChat();
            utils.alert.success(response.messaggio);
          }
        });
      }
    },
    deleteChat(idChat) {
      utils.alert.confirm("Sei sicuro di voler eliminare la chat selezionata?", () => {
        utils.ajax('chad_2/chat/delete', { ID_CHAT: idChat }, (response) => {
          if (response.esito == 0) {
            this.getChat(); 
            utils.alert.success("Chat eliminata con successo.");
          } else {
            utils.alert.error("Errore nell'eliminazione della chat.");
          }
        });
      }, this.$root)
    },
    createDefaultChats() {
      utils.ajax('chad_2/createDefaultChats', { ID_UTENTE: this.$root.utente.ID_UTENTE }, (response) => {
        if (response.data.messaggio) {
          this.getChat();
        }
      });
    },
    checkIfGod() {
      utils.ajax('user/details', { ID_UTENTE: this.$root.utente.ID_UTENTE }, (response) => {
        this.isGod = response.data.isGod;
        this.createDefaultChats();
      });
    }
  },
  computed: {
    paginatedList() {
      var arr = utils.inGrid(this.filteredChats, this.searchChat);
      return arr;
    },

    paginatedListUtenti() {
      var arr = utils.inGrid(this.allUsers, this.search);
      return arr;
    },

    filteredChats() {
      //filtra le chat individuali in base all'username. Restituisce solo le chat che contengono un utente il cui username include la stringa inserita nel filtro.
      return this.arrayChatUtente.filter(chat => 
        chat.utenti.some(user => user.USERNAME.toLowerCase().includes(this.searchChat.USERNAME.toLowerCase()))
      );
    },

    filteredGroups() {
      //Filtra i gruppi di chat in base agli utenti che fanno parte del gruppo e al filtro di username.
      return this.groupChats.filter(chat => 
        chat.utenti.some(user => user.USERNAME.toLowerCase().includes(this.searchChat.USERNAME.toLowerCase()))
      );
    },

  },
  created() {
    this.checkIfGod();
  }
}
</script>
