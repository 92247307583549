<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="text-dark fw-semibold" v-on:click="getAirlines()"> Destinazioni </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Compagnie </span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaAirline()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Compagnie</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#airlines-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Compagnie</span>
							</div>
						</a>
					</li>
				</ul>
				<button class="ms-lg-auto btn btn-danger btn-sm text-center" v-on:click="getAirlines()" v-show="ordineHasChanged">Annulla ordinamento</button>
				<button class="ms-lg-auto btn btn-success btn-sm text-center" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</button>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i>
									Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i>
									Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i>
									Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i>
									Activity log
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-gear me-2"></i>
									Profile settings
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="airlines-a">
				<div class="card shadow mb-0">
					<div class="card-body ">
						<div class="alert alert-2 alert-secondary border-0">
							<form action="#" id="airline-a">
								<div class="row">           
									<div class="col-6 col-md-1 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Compagnia" :tabindex="2" v-model="search.ID_AIRLINE.VALUE" />
									</div>          
									<div class="col-6 col-md-1 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Codice Hub" :tabindex="3" v-model="search.AIRLINE_CODE.VALUE" />
									</div>   
									<div class="col-12 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Compagnia" :tabindex="4" v-model="search.DESCRIZIONE.VALUE" />
									</div>          
									<div class="col-12 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Hub Compagnia" :tabindex="3" v-model="search.FK_ID_COMUNE_HUB.VALUE" />
									</div>  
									<div class="col-12 col-md-6">
										<div class="row"> 
											<div class="col-12 col-md-4 form-group-filter">
	
											</div>
											<div class="col-12 col-md-6 form-group-filter">
	
											</div>
											<div class="col-12 col-md-2 form-group-filter">
												<select class="form-control form-control-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="6">
													<option value="">Tutti </option>   
													<option value="1"> &nbsp; Attivati</option>
													<option value="0"> &nbsp; Disattivati</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</form>  
						</div>
					</div>
				
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllAirlines" v-on:click="filters.selectAll('chkAllAirlines',paginatedListAirlines.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm" style="width:4%;"> Img </th>
										<th class="table-head-sm">Ragione Sociale /<br />Impostazioni </th>
										<th class="table-head-sm" style="width:20%;">Sigla </th>
										<th class="table-head-sm" style="width:10%;">Hub Citt&agrave;</th>
										<th class="table-head-sm" style="width:5%;">Hits</th>
										<th class="table-head-sm" style="width:5%;">Visibile</th>
										<th class="table-head-sm" style="width:5%;">Stato</th>
										<th class="table-head-sm" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
									<tr v-for="airline in paginatedListAirlines.arr" v-bind:key="airline.ID_AIRLINE">
										<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="airline.SELEZIONATO" class=""> </td>
										<td class="text-center"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
										<td class="fs-12">
											{{airline.ID_AIRLINE}}<br />
											<span class="badge bg-light badge-xs text-muted float-end cursor-pointer">{{airline.ORDINE}}</span>
										</td>
										<td class="text-center">
											<img v-if="airline.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',airline.URL_AVATAR)" class="rounded" width="50" height="35" alt="" />
											<div v-if="airline.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto">No<br />Photo</div>
										</td>
										<td class="">
											<span v-on:click="modificaAirline(airline)" class="text-primary text-uppercase fw-bold cursor-pointer">{{airline.DENOMINAZIONE}}</span><br />
										</td>
										<td class="fs-12">
											<span class="text-uppercase">{{airline.NOME}}</span><br />
										</td>
										<td class="fs-12 text-uppercase">
											{{airline.GEO_COMUNE}}<br />
										</td>
		                                <td class="fs-12 text-center">
											<span class="badge badge-flat badge-pill border-secondary text-secondary">0</span>
										</td>
										<td class="fs-12 text-center">
											<span v-on:click="updateFlgVisibile(airline)" v-show="airline.FLG_VISIBILE == 1" class="badge badge-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateFlgVisibile(airline)" v-show="airline.FLG_VISIBILE == 0" class="badge badge-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>
										<td class="text-center">
											<ISwitch @toggle="updateStatoAirline(airline)" :size="'sm'" v-model="airline.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="text-center">
											<div class="list-icons" :tabindex="26">
												<div class="dropdown">
													<a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
													<div class="dropdown-menu dropdown-menu-right">
														<a href="#" class="dropdown-item" v-on:click="modificaAirline(airline)"><i class="fal fa-pencil fa-fw"></i> Modifica Compagnia</a>
														<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item" v-on:click="deleteAirline(airline)"><i class="fal fa-trash-alt fa-fw text-danger"></i> Elimina Compagnia</a>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</draggable>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
								<div class=" float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListAirlines.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div> 
						</div> 
		
					</div>
				</div>
			</div>

		</div>
	</div>	


	<!-- Modal -->
	<div class="modal fade" id="popUpAirline" role="dialog" aria-modal="true">
	    <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title modal-title-2 w-89 d-none d-sm-block"> SCHEDA {{airlineSelezionato.ID_AIRLINE == 0 ? 'NUOVO' : 'MODIFICA'}} STATO </h6>                                                        
                    <div class="btn-group float-end" v-show="airlineSelezionato.ID_AIRLINE > 0" :tabindex="2">
                        <button type="button" class="btn btn-yellow btn-yellow-100 btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> AZIONI
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body" style="padding: 0 !important;">

					<div class="d-block d-sm-flex">
                        <div class="profile-tab-nav border-right">
                            <div class="p-3">
                                <div class="img-circle text-center cursor-pointer mb-0" v-on:click="uploadImmagineStato()" :tabindex="3">
									<img v-if="airlineSelezionato.ID_AIRLINE > 0 && airlineSelezionato.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('GEO',airlineSelezionato.URL_AVATAR)" class="rounded shadow" alt="" />
									<span v-if="airlineSelezionato.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 2rem 4rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</span>
                                </div>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="padding-right: 5px !important; min-width: 13rem;">
                                <a class="nav-link strech-nav-pill active" id="geodata-tab" data-bs-toggle="pill" href="#geo-data" role="tab" aria-controls="geo-data" aria-selected="true" :tabindex="4">
                                    <i class="fal fa-globe fa-fw"></i> Dati Geografici
                                </a>
                            </div>
                        </div>
                        <div class="tab-content p-md-2" style="padding-right: 0px !important;" id="v-pills-tabContent">

                            <div class="tab-pane fade show active" id="geo-data" role="tabpanel" aria-labelledby="geodata-tab">
                                <h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 3rem;"><i class="fad fa-globe fa-fw me-1"></i> Dati Geografici</h3>
                                <div class="box_26">   

									
								</div>
							</div> 

						</div>
					</div>

                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaStato()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>                
            </div>
        </div>
	</div>


    <div class="modal fade" :id="'popUpUploadImgAirline'+rs" role="dialog" aria-modal="true" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Compagnia</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsAirline" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button type="button" class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>


</template>



<script>		

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'

export default {
    name:'Airlines',	

    components:{
       	Pagine,
        ISwitch,
        draggable: VueDraggableNext,
        Upload,
    },					

    data: function () {
		return {   
			apiStartup:'travel/airline',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
        	arrayAirlines:new Array(),
        	airlineSelezionato:{enti:new Array()},
	        search:{
	          	PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_AIRLINE:{TYPE:'TEXT',VALUE:''},
	          	DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
	          	AIRLINE_CODE:{TYPE:'TEXT',VALUE:''},
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},	
				FK_ID_COMUNE_HUB:{TYPE:'COMBO',VALUE:''},	
			},
			rs : utils.generateRandomString(30),
			ordineHasChanged: false,
            argsAirline:{
                modalita:'MOONLIGHT_uploadImgGeo',
				tipologia:1
			},


	   }
    },

    methods: {  
      	getAirlines : function() {
	     	utils.ajax(this.apiStartup,{}, (response) => {
				this.ordineHasChanged = false;
	          	this.arrayAirlines = response.data;
	        });
 		}, 

        nuovoAirline : function(){
			this.airlineSelezionato = {ID_AIRLINE:0};
			this.filters.openPopUp("popUpAirline");
        },

        modificaAirline : function(t){
			utils.ajax(this.apiStartup,{ID_AIRLINE:t.ID_AIRLINE}, (response) => {
				if (response.esito == 0){
					this.airlineSelezionato = utils.clone(response.data);
					this.argsAirline.ID_AIRLINE = t.ID_AIRLINE;
					this.filters.openPopUp("popUpAirline");
				}
			});
        },

        salvaAirline : function(){
			utils.salvaElemento("travel/airline/stato/",this.airlineSelezionato,"ID_AIRLINE","popUpAirline",(response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
					this.getAirlines();
				}
			});				
        },	

		updateFlgVisibile : function(stato){
			stato.FLG_VISIBILE = stato.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/airline/visibilita/update",stato, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					stato.FLG_VISIBILE = stato.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},

        updateAirlineStato : function(stato){
            setTimeout(() => {
                utils.ajax("travel/airline/gen/stato/update",stato, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },
        
        deleteAirline : function(t){
            utils.alert.confirm("Sicuro di voler eliminare questa Compagnia Aerea" + t.NOME + "?",()=>{
                utils.ajax('core/basic/delete',{className:'Stato',id:t.ID_AIRLINE}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getAirlines();
                    }
                });
            });
        },
        
      	esportazione : function(formato){
        	utils.report.token({modalita:'esportaAiline',formato:formato});
      	},

		ordineChangeHandler : function(event){
			this.arrayAirlines = this.filters.draggableChangeHandler(event,this.arrayAirlines,this.search,"ID_AIRLINE");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayAirlines.length ; i++){
					arr.push({"ID": this.arrayAirlines[i].ID_AIRLINE,"O": this.arrayAirlines[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Stato',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getAirlines();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

        uploadImmagineAiline : function(){
            this.filters.openPopUp("popUpUploadImgAirline"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgAirline"+this.rs);
            utils.ajax("travel/airline/stato",{ID_AIRLINE:this.airlineSelezionato.ID_AIRLINE}, (response) => {
                this.airlineSelezionato = response.data;
				this.getAirlines();
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgAirline"+this.rs);
        },

    },

    computed: {
      	paginatedListAirlines(){
        	var arr = utils.inGrid(this.arrayAirlines,this.search);
        	return arr;
      	}
    },

    created:function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		utils.ajax("travel/airline",{}, (response) => {
			this.ordineHasChanged = false;
			this.arrayAirlines = response.data;
			this.getAirlines();
		});
    }

}
</script>