<template>

    <div class="modal fade" id="popUpSchedaQuota" aria-modal="true" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-xl" > 
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 75%!important;">SCHEDA DETTAGLIO QUOTE</h6>
                    <div class="float-end" style="height: 24px;">Modifica date </div>
                    <div class="float-start ms-2" style="margin-top:-6px">
                        <select class="form-select form-select-sm" v-model="this.datiRicerca.DATA_START" @change="aggiornaPriceRicerca()">
                            <option v-for="price in arrayPartenzeAlternative" v-bind:key="price.DATA_START" v-bind:value="price.DATA_START">{{price.DATA_START }}</option>
                        </select>
                    </div>
					<button type="button" class="btn-close" data-bs-dismiss="modal"></button>                   
                </div>
                <div class="modal-body p-2"> 
 
                    <div class="d-lg-flex align-items-lg-start">
                        <div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none me-lg-3">
                            <div class="sidebar-content">
                                <ul class="nav nav-sidebar" role="tablist">
                                    <li class="nav-item pt-0" role="presentation">
                                        <a v-on:click="currentTab = 1" id="wizardTab1" href="#vertical-left-tab1" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
                                            <strong>1.</strong> <span class="ms-2">Presentazione</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a v-on:click="currentTab = 2; selezionaCamera1('linkQuotaCamera1',200)" id="wizardTab2" href="#vertical-left-tab2" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                            <strong>2.</strong> <span class="ms-2">Dettaglio</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a v-on:click="currentTab = 3; selezionaCamera1('linkQuotaCameraPax1',200)" id="wizardTab3" href="#vertical-left-tab3" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                            <strong>3.</strong> <span class="ms-2">Passeggeri</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a v-on:click="currentTab = 4; selezionaCamera1('linkExtraCamera1',200)"  id="wizardTab4" href="#vertical-left-tab4" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                            <strong>4.</strong> <span class="ms-2">Extra</span>
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a v-on:click="currentTab = 5" id="wizardTab5" href="#vertical-left-tab5" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                            <strong>5.</strong> <span class="ms-2">Riepilogo</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="tab-content flex-fill">
                            
                            <div class="tab-pane fade active show" id="vertical-left-tab1" role="tabpanel">
                               <h5 class="label-bar"><i class="fad fa-user fa-fw me-1"></i> Presentazione</h5>
                               <div class="box-utenti ps-1">  
                                    <div class="card blog-horizontal">
                                        <div class="card-body mb-2">
                                            <div class="card-img-actions me-sm-3 mb-3 mb-sm-0">
                                                <img v-if="quota.PRODOTTO.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',quota.PRODOTTO.URL_AVATAR)" class="img-fluid card-img" alt="" />
                                                <img v-if="quota.PRODOTTO.URL_AVATAR == 'placeholder.jpg'" :src="filters.getImgPath('NOPHOTO/placeholder.jpg')" class="img-fluid card-img" alt="">
                                            </div>
                                            <div class="mb-3">
                                                <h5 class="d-flex flex-nowrap my-1">
                                                    <a href="#" class="me-2">{{quota.PRODOTTO.DESCRIZIONE}}</a>
                                                    <span class="text-success ms-auto">
                                                        
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    </span>
                                                </h5>
                                                <ul class="list-inline list-inline-bullet text-muted mb-0">
                                                    <li class="list-inline-item">Tipologia</li>
                                                    <li class="list-inline-item">Località</li>
                                                </ul>
                                            </div>
                                            <p>Intro</p>
                                        </div>
                                    </div>                                      
                                    <div class="card border-0">
                                        <div class="nav-tabs-responsive">
                                            <ul class="nav nav-tabs nav-tabs-underline flex-nowrap mb-0 bg-light" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a href="#course-overview" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
                                                        <i class="ph-list me-2"></i>
                                                        Overview
                                                    </a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a href="#course-attendees" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                                        <i class="ph-users-three me-2"></i>
                                                        Attendees
                                                    </a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a href="#course-schedule" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">
                                                        <i class="ph-calendar me-2"></i>
                                                        Schedule
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="tab-content">

                                            <div class="tab-pane fade active show" id="course-overview" role="tabpanel">
                                                <div class="card-body">
                                                    <div class="mt-1 mb-4">
                                                        <h6>Course overview</h6>
                                                        <p>Then sluggishly this camel learned woodchuck far stretched unspeakable notwithstanding the walked owing stung mellifluously glumly rooster more examined one that combed until a less less witless pouted up voluble timorously glared elaborate giraffe steady while grinned and got one beaver to walked. Connected picked rashly ocelot flirted while wherever unwound much more one inside emotionally well much woolly amidst upon far burned ouch sadistically became.</p>
                                                        <p>A collection of textile samples lay spread out on the table - Samsa was a travelling salesman - and above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. Gregor then turned to look out the window at the dull weather.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="course-attendees" role="tabpanel">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-center pt-3 mb-3">
                                                        <ul class="pagination pagination-flat">
                                                            <li class="page-item"><a href="#" class="page-link rounded-pill"><i class="ph-arrow-left"></i></a></li>
                                                            <li class="page-item active"><a href="#" class="page-link rounded-pill">1</a></li>
                                                            <li class="page-item"><a href="#" class="page-link rounded-pill">2</a></li>
                                                            <li class="page-item align-self-center"><span class="mx-2">...</span></li>
                                                            <li class="page-item"><a href="#" class="page-link rounded-pill">58</a></li>
                                                            <li class="page-item"><a href="#" class="page-link rounded-pill">59</a></li>
                                                            <li class="page-item"><a href="#" class="page-link rounded-pill"><i class="ph-arrow-right"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="course-schedule" role="tabpanel">
                                                <div class="card-body">

                                                </div>
                                            </div>

                                        </div>
                                    </div>
	                            </div>
	                        </div>

                            <div class="tab-pane fade" id="vertical-left-tab2" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-cogs fa-fw me-1"></i> Dettaglio</h5>
                                <ul class="nav nav-tabs nav-tabs-highlight" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation" v-for="camera in quota.CAMERE" v-bind:key="camera">
                                        <button class="nav-link" type="button" :id="'linkQuotaCamera'+camera.CAMERA.ID_CAMERA" :aria-controls="'quotaCamera'+camera.CAMERA.ID_CAMERA" :data-bs-target="'#quotaCamera'+camera.CAMERA.ID_CAMERA" data-bs-toggle="tab" role="tab" aria-selected="true">
                                            <strong>Camera {{camera.CAMERA.ID_CAMERA}}</strong>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade" v-for="camera in quota.CAMERE" :id="'quotaCamera'+camera.CAMERA.ID_CAMERA" v-bind:key="camera" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="card-body mb-2 p-0">

                                            <!--  PACCHETTI -->
                                            <div v-if="quota.PRODOTTO.FK_ID_MODULO == 3" class="box-quota ps-1">
                                                <template v-for="servizio in camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].servizi" v-bind:key="servizio.ID_SERVIZIO">
                                                    <div class="collapse show" :id="'sidebar-collapsible-sections_'+servizio.ID_SERVIZIO">
                                                        <div class="mb-3">
                                                            <h6 class="font-weight-semibold bg-light mt-2" style="margin-bottom: 0.05rem;">
                                                                <i class="fal fa-fw me-1" v-bind:class="filters.iconaProdotto(servizio.FK_ID_MODULO)"></i> <span class="text-primary">{{servizio.DESCRIZIONE}}</span> 
                                                                <span class="ms-1">(5)</span>
                                                            </h6>
                                                            <div class="dropdown-divider mb-0"></div>
                                                            <div v-for="qServ in getQuoteServizio(camera,servizio.ID_SERVIZIO)"  v-bind:key="qServ" style="border-bottom: 1px dashed #ccc;">
                                                                <a href="#" class="dropdown-item">
                                                                    <i class="icon-arrow-right5 me-1"></i>[{{qServ.FK_ID_PRICE}}] - {{qServ.DESCRIZIONE}}  
                                                                    <span class="badge bg-warning ms-auto" v-on:click="selezionaOpzione(camera,qServ)" v-if="qServ.SELEZIONATO == 0">Seleziona</span>
                                                                    <span class="badge bg-success ms-auto" v-else>Selezionato</span>
                                                                </a>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>

                                            <!-- VOLI -->
                                            <div v-else-if="quota.PRODOTTO.FK_ID_MODULO == 4" class="box-quota ps-1">
                                                <div v-for="(q,index) in camera.RISULTATI" class="w-100" v-bind:key="q" style="border-radius:6px;background:#fff;margin-bottom:10px;border-top:1px solid #ddd">
                                                    {{ q.DESCRIZIONE }} - {{ q.FK_ID_OPZIONE }} - {{ index }}
                                                    <span class="badge bg-success ms-auto" v-if="q.SELEZIONATO == 1">Selezionato</span>
                                                    <span class="badge bg-warning ms-auto"  v-on:click="selezionaIndiceRisultato(camera,index);" v-else>Seleziona</span>
                                                    <!-- <button  class="btn btn-warning btn-grid">Seleziona</button>                                                                -->
                                                </div>
                                            </div>

                                            <!-- ATTIVITA' -->
                                            <div v-else-if="quota.PRODOTTO.FK_ID_MODULO == 1" class="box-quota ps-1">
                                                <div v-for="(q,index) in camera.RISULTATI" class="w-100" v-bind:key="q" style="border-radius:6px;background:#fff;margin-bottom:10px;border-top:1px solid #ddd">
                                                    <h6 class="font-weight-semibold bg-light mt-2" style="padding: 0.5rem;">
                                                        {{ q.DESCRIZIONE }} - {{ q.FK_ID_OPZIONE }} - {{ index }}
                                                        <span v-if="q.SELEZIONATO == 1" class="badge bg-success ms-auto float-end">Selezionato</span>
                                                        <span v-else class="badge bg-warning ms-auto float-end" v-on:click="selezionaIndiceRisultato(camera,index);">Seleziona</span>
                                                    </h6>
                                                </div>
                                            </div>

                                            <!-- CAMERE HOTEL -->
                                            <div v-else class="box-quota ps-1">
                                                <div v-for="(q,index) in camera.RISULTATI" class="w-100" v-bind:key="q" style="background:#fff; margin-bottom:10px;">
                                                    <h6 class="font-weight-semibold bg-light mt-2" style="padding: 0.5rem;">
                                                        {{ q.quote[0].DESCRIZIONE }} - {{ q.quote[0].FK_ID_OPZIONE }} - {{ index }}
                                                        <span class="badge bg-success ms-auto float-end" v-if="q.SELEZIONATO == 1">Selezionato</span>
                                                        <span class="badge bg-warning ms-auto float-end"  v-on:click="selezionaIndiceRisultato(camera,index);" v-else>Seleziona</span>  
                                                    </h6>                                                           
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>   
                            </div>

                            <div class="tab-pane fade" id="vertical-left-tab3" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-users fa-fw me-1"></i> Passeggeri</h5>
                                <ul class="nav nav-tabs nav-tabs-highlight" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation" v-for="camera in quota.CAMERE" v-bind:key="camera">
                                        <button class="nav-link" type="button" :id="'linkQuotaCameraPax'+camera.CAMERA.ID_CAMERA" :aria-controls="'quotaCameraPax'+camera.CAMERA.ID_CAMERA" :data-bs-target="'#quotaCameraPax'+camera.CAMERA.ID_CAMERA" data-bs-toggle="tab" role="tab" aria-selected="true">
                                            <strong>Camera {{camera.CAMERA.ID_CAMERA}} </strong>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">

                                    <div class="tab-pane fade" v-for="camera in quota.CAMERE" :id="'quotaCameraPax'+camera.CAMERA.ID_CAMERA" v-bind:key="camera" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="card-body box-quota mb-2 p-0">
                                            <div v-for="(pax,i) in camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax" v-bind:key="pax.ID_PAX" class="ps-1">
                                                <div class="collapse show" :id="'sidebar-collapsible-sections_pax_'+camera.CAMERA.ID_CAMERA+'_'+i">
                                                    <div class="mb-1"> 
                                                        <div class="sidebar-section-header bg-secondary-100 text-white d-lg-flex" style="display: block; border-bottom: 1px solid #ccc; height: 3.1rem; background-color: #f9f9f9;">
                                                            <a class="text-body font-weight-semibold collapsed" data-bs-toggle="collapse" :href="'#collapse_pax_'+camera.CAMERA.ID_CAMERA+'_'+i" aria-expanded="false">
                                                                <span class="ms-1">{{pax.NUM}} - {{pax.TYPE}} <strong>{{pax.personaFisica.COGNOME}} {{pax.personaFisica.NOME}}</strong> </span>
                                                            </a>
                                                            <div class="ms-auto">
                                                                <div class="list-icons float-end" :tabindex="26">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="btn btn-warning btn-sm me-1" data-bs-toggle="dropdown"><i class="icon-user me-1"></i>Importa</a>
                                                                        <div class="dropdown-menu dropdown-menu-right">
                                                                            <a href="#" class="dropdown-item"  v-on:click="selezionaAmico(ordine.intestatarioPersonaFisica,camera,i,1)"><i class="fad fa-pencil fa-fw"></i> <strong class="me-2">Intestatario:</strong> {{ ordine.intestatarioPersonaFisica.COGNOME }} {{ ordine.intestatarioPersonaFisica.NOME }}</a>                                                                                
                                                                            <!--
                                                                                1. TE STESSO (INTESTATARIO)
                                                                                2. DISTINCT PAX DELL'ORDINE
                                                                                3. PAX CHE HANNO CONDIVISO CON TE UN ORDINE IN PASSATO (PARENTI)
                                                                                4. AMICI
                                                                            -->
                                                                            <a href="#" class="dropdown-item"  v-for="p in ordine.distinctPax" v-bind:key="p.FK_ID_PERSONA_FISICA" v-on:click="selezionaAmico(p,camera,i,0)"><i class="fad fa-pencil fa-fw"></i> {{ p.personaFisica.COGNOME }} {{ p.personaFisica.NOME }}</a>                                                                                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-info btn-sm me-3" v-on:click="ricercaPax(camera,i)"><i class="icon-search4 me-1"></i>Cerca</button>                                                                       
                                                            </div>
                                                        </div>
                                                        <div :id="'collapse_pax_'+camera.CAMERA.ID_CAMERA+'_'+i" class="collapse show mt-1" style="" >
                                                            <Pax v-model="camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax[i]"  @update="paxChangeHandler(pax[i])" @change="paxChangeHandler(pax[i])"></Pax>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="tab-pane fade" id="vertical-left-tab4" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-users fa-fw me-1"></i> Extra</h5>
                                <ul class="nav nav-tabs nav-tabs-highlight" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation" v-for="camera in quota.CAMERE" v-bind:key="camera">
                                        <button class="nav-link" type="button" :id="'linkExtraCamera'+camera.CAMERA.ID_CAMERA" :aria-controls="'extraCamera'+camera.CAMERA.ID_CAMERA" :data-bs-target="'#extraCamera'+camera.CAMERA.ID_CAMERA" data-bs-toggle="tab" role="tab" aria-selected="true">
                                            <strong>Camera {{camera.CAMERA.ID_CAMERA}} </strong>
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">

                                    <div class="tab-pane fade" v-for="camera in quota.CAMERE" :id="'extraCamera'+camera.CAMERA.ID_CAMERA" v-bind:key="camera" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="card-body box-quota mb-2 p-0">
                                            <div v-for="(pax,i) in camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax" v-bind:key="pax.ID_PAX" class="ps-1">
                                                <div class="collapse show" :id="'sidebar-collapsible-sections_extra_'+camera.CAMERA.ID_CAMERA+'_'+i">
                                                    <div class="mb-1">
                                                        <div class="sidebar-section-header bg-secondary-100 text-white" style="display: block; border-bottom: 1px solid #ccc; height: 2.3rem; background-color: #f9f9f9;">
                                                            <a class="text-body font-weight-semibold collapsed" data-bs-toggle="collapse" :href="'#collapseExtra_'+camera.CAMERA.ID_CAMERA+'_'+i" aria-expanded="false">
                                                                <span class="ms-1" style="position: relative; top: 8px;">{{pax.NUM}} {{pax.TYPE}} - <strong>{{pax.personaFisica.COGNOME}} {{pax.personaFisica.NOME}}</strong> </span>
                                                            </a>
                                                        </div>
                                                        <div :id="'collapseExtra_'+camera.CAMERA.ID_CAMERA+'_'+i" class="collapse show" style="">
                                                            <div class="row">
                                                                <div class="col-12 p-2">
                                                                    <div v-for="extra in getExtraPax(camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].extra_disponibili,pax,camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].ID_QUOTA)"  v-bind:key="extra.FK_ID_PRODOTTO" >
                                                                        <div class="badge border-secondary text-secondary cursor-pointer rounded-0 p-2" v-bind:class="{'bg-success text-white':isExtraSelezionato(extra) == true, 'bg-light':isExtraSelezionato(extra) == false }" v-on:click="gestioneSelezioneExtra(extra)">
                                                                            <i class="far fa-tags me-2"></i>{{ extra.TITOLO }}
                                                                        </div>                                                        
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>                                                                
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            
                                            <h4 class="mb-0">Extra a servizio</h4>
                                            <div class="row">
                                                <div class="col-12 p-2">
                                                    <div v-for="extra in getExtraCamera(camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].extra_disponibili,camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].ID_QUOTA)"  v-bind:key="extra.FK_ID_PRODOTTO" >
                                                        <div class="badge badge-flat border-secondary text-secondary cursor-pointer" v-bind:class="{'bg-success text-white':isExtraSelezionato(extra) == true}" v-on:click="gestioneSelezioneExtra(extra)">
                                                            {{ extra.TITOLO }}
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>

                            <div class="tab-pane fade" id="vertical-left-tab5" role="tabpanel">
                                <div class="card mb-1">
                                    <div class="card-header bg-light header-elements-inline" style="padding: 0.5rem 1rem !important;">
                                        <div class="d-flex">
                                            <div class="flex-grow-1">
                                                <h5 class="card-title mb-0">{{quota.PRODOTTO.DESCRIZIONE}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="camera in quota.CAMERE" v-bind:key="camera.CAMERA.ID_CAMERA">
                                        <div class="card-body">
                                            <div class="d-sm-flex align-items-lg-start text-center text-lg-start">
                                                <div class="me-lg-3 mb-3 mb-lg-0">
                                                    <a href="" data-bs-popup="lightbox">
                                                        <img v-if="quota.PRODOTTO.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('PRODOTTI/',quota.PRODOTTO.URL_AVATAR)" class="rounded" width="170" alt="" />
                                                        <div v-if="quota.PRODOTTO.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto" style="border-radius: 1rem; padding: 2rem 4rem; width: 100% !important; font-size: 1.5rem;">No<br />Photo</div>
                                                    </a>
                                                </div>
                                                <div class="flex-fill">
                                                    <h6 class="mb-0">
                                                        <a href="#">Riepilogo Camera {{camera.CAMERA.ID_CAMERA}}</a>
                                                    </h6>
                                                    <ul class="list-inline list-inline-bullet mb-0">
                                                        <li class="list-inline-item"><a href="#" class="text-muted">Fashion</a></li>
                                                        <li class="list-inline-item"><a href="#" class="text-muted">Men's Accessories</a></li>
                                                    </ul>
                                                    <ul class="list-inline list-inline-bullet mb-0">
                                                        <li class="list-inline-item">All items from <a href="#">Aloha</a></li>
                                                        <li class="list-inline-item">Add to <a href="#">wishlist</a></li>
                                                    </ul>
                                                </div>
                                                <div class="flex-shrink-0 text-center mt-3 mt-lg-0 ms-lg-3">
                                                    <div class="my-1">
                                                        <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                        <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                        <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                        <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                        <i class="ph-star fs-base lh-base align-top text-warning"></i>
                                                    </div>
                                                    <h5 class="mt-1">$49.99</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                            
                                    <div class="table-responsive table-card mt-3">
                                        <table class="table table-borderless table-scrollable-1 text-nowrap">
                                            <tbody>
                                                <tr>
                                                    <td class="fw-semibold" colspan="2" style="width: 85%;">Sub Totale :</td>
                                                    <td class="fw-semibold text-end" style="width: 15%;">€ {{filters.formattaImporto(getTotaleQuoteNoExtra())}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Discount <span class="text-muted">Extra</span>: </td>
                                                    <td class="text-end">€ {{filters.formattaImporto(getTotaleQuoteExtra())}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Shipping Charge :</td>
                                                    <td class="text-end">$ 24.99</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">Estimated Tax (12%): </td>
                                                    <td class="text-end">$ 18.20</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3">&nbsp;</td>
                                                </tr>
                                                <tr class="table-active" style="height: 3rem !important;">
                                                    <th colspan="2" class="text-dark">TOTALE:</th>
                                                    <td class="text-end"><span class="font-weight-black">&euro; {{filters.formattaImporto(getTotaleQuote())}}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>                                  
                                </div> 
                            </div>
                        </div>


                              
                    </div>
                </div>
                <div class="modal-footer bg-light p-2">
                    <div class="row w-100">
                        <div class="col-4">                         
                        </div>
                        <div class="col-8">
                            <button class="btn btn-success float-end" v-show="currentTab == 5" v-on:click="salvaQuota()">SALVA <i class="icon-paperplane"></i></button>
                            <button class="btn btn-primary float-end" v-show="currentTab < 5" v-on:click="clickStepHandler(1)">SUCCESSIVO <i class="icon-arrow-right8"></i></button>
                            <button class="btn btn-light float-end me-2" v-show="currentTab > 1" v-on:click="clickStepHandler(-1)"><i class="icon-arrow-left8 me-2"></i> PRECEDENTE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <RicercaPersonaFisica :tipo="1" :ref="'ricercaPfIntestario'" @selezionato="handlerSelezionaPax($event)"></RicercaPersonaFisica>    

</template>



<script>
import RisultatoRicerca from '@/components/RisultatoRicerca.vue'
import CardRisultatoRicerca from '@/components/CardRisultatoRicerca.vue'
import Pax from '@/components/common/Pax.vue'
import RicercaPersonaFisica from '@/components/RicercaPersonaFisica.vue'

//import DatePicker from '@/components/utils/DatePicker.vue'


export default {
    name:"SchedaQuota",
    components:{
        Pax,
        RicercaPersonaFisica,
        
        //DatePicker
       /* RisultatoRicerca,
        CardRisultatoRicerca*/
    },
    emits:['salva','update'],
    props:['idOrdine'],
    data:function(){
        return{
            filters:global.filters,
            quota : {PRODOTTO:{TITOLO:''},CAMERE:new Array()},
            ordine:{},
            datiRicerca : {},
            arrayExtraAggiunti : new Array(),
            importoExtra : 0,
            currentTab:1,
            arrayPartenzeAlternative : new Array(),
            idQuotaDelete : 0,
            INDICE_RISULTATO_SELEZIONATO:0,
            cameraSelezionata:{},
            indicePaxSelezionato:0
        }
    },
    methods:{
        openSchedaQuota : function(quota,datiRicerca,ordine){
            this.currentTab = 1;
            setTimeout(() => {
                document.getElementById('wizardTab1').click();   
                this.currentTab = 1; 
            }, 250);            
            this.quota = utils.clone(quota);
            this.datiRicerca = utils.clone(datiRicerca);
            this.ordine = utils.clone(ordine);
            this.filters.openPopUp("popUpSchedaQuota");
            this.quota.DATI_RICERCA = utils.clone(datiRicerca);
            utils.ajax("travel/ricerca/salva",{QUOTA:this.quota}, (response) => {});
            this.arrayPartenzeAlternative = new Array();
            for (var i = 0 ; i < this.quota.PRICE_VICINE.length ; i++) {
                var item = utils.clone(this.quota.PRICE_VICINE[i]);
                item.DATA_START = filters.formatDate(item.DATA_START);
                this.arrayPartenzeAlternative.push(item);
            }
            //SE SI TRATTA DI UNA QUOTA VOLO (DI RITORNO)
            if (this.quota.PRODOTTO.FK_ID_MODULO == 4 && this.quota.PRODOTTO.CUSTOM_1 == "2"){
                //VADO A CERCARMI NELL'ORDINE SE ESISTE UNA QUOTA QUALSIASI VOLO DI ANDATA
                for (var i = 0 ; i < this.ordine.quote.length ; i++){
                    if (this.ordine.quote[i].prodotto.FK_ID_MODULO == 4 && this.ordine.quote[i].prodotto.CUSTOM_1 == "1"){
                        //QUOTA ANDATA TROVATA
                        var quotaAndata = utils.clone(this.ordine.quote[i]);
                        for (var j = 0 ; j < this.quota.CAMERE.length ; j++){
                            for (var k = 0 ; k < this.quota.CAMERE[j].RISULTATI.length ; k++){
                                for (var l = 0 ; l < quotaAndata.pax.length ; l++){
                                    this.quota.CAMERE[j].RISULTATI[k].pax[l].personaFisica = utils.clone(quotaAndata.pax[l].personaFisica);
                                    this.quota.CAMERE[j].RISULTATI[k].pax[l].FK_ID_PERSONA_FISICA = utils.clone(quotaAndata.pax[l].FK_ID_PERSONA_FISICA);
                                }
                            }   
                        }
/*
                        pax.DATA_NASCITA = this.filters.formatDate(pax.DATA_NASCITA);
                        this.cameraSelezionata.RISULTATI[this.cameraSelezionata.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax[this.indicePaxSelezionato].personaFisica = utils.clone(pax);
                        this.cameraSelezionata.RISULTATI[this.cameraSelezionata.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax[this.indicePaxSelezionato].FK_ID_PERSONA_FISICA = pax.FK_ID_PERSONA_FISICA;
*/


                        break;
                    }
                }
            }
            
            /*
            if (q.ID_UTENTE == 0){
                this.utente = utils.clone(utente);
                this.filters.openPopUp('#popUpSchedaQuota');
                var modal = global.$('#popUpSchedaQuota');
                modal.modal('show');
            }
            else{
                utils.ajax("travel/utente",{ID_UTENTE:utente.ID_UTENTE}, (response) => {
                    this.utente = response.data;
                    this.filters.openPopUp('#popUpSchedaUtente');
                    var modal = global.$('#popUpSchedaUtente');
                    modal.modal('show');
                });
            }*/
        },
        //DA MODIFICARE IN ARRAY QUOTE
        openSchedaQuoteEdit : function(quota,ordine = null){
            this.idQuotaDelete = quota.ID_QUOTA;
            var idProdotto = quota.FK_ID_PRODOTTO;
            //ROOMS => ALLORA PRENDO HOTEL_ID E IMPOSTO MODULO 2 PER RIFARE LA RICERCA
            if (quota.FK_ID_MODULO == 2){
                idProdotto = quota.prodotto.FK_ID_PRODOTTO;
            }
            if (ordine != null){
                this.ordine = utils.clone(ordine);
            }
            this.datiRicerca = {camere:new Array(this.trasformaPaxDbInPaxRicerca(quota)),ID_PRODOTTO:idProdotto,DATA_START:quota.DATA_START,DATA_END:quota.DATA_END,FK_ID_MODULO:quota.FK_ID_MODULO,INDICE_RISULTATO_SELEZIONATO:0};
            utils.ajax('travel/ricerca',this.datiRicerca, (response) => {
                if (response.esito == 0){
                    this.quota = response.data[0];
                    this.arrayPartenzeAlternative = new Array();
                    if (this.quota.PRICE_VICINE != undefined){
                        for (var i = 0 ; i < this.quota.PRICE_VICINE.length ; i++) {
                            var item = utils.clone(this.quota.PRICE_VICINE[i]);
                            item.DATA_START = filters.formatDate(item.DATA_START);
                            this.arrayPartenzeAlternative.push(item);
                        }
                    }                                        
                    this.filters.openPopUp("popUpSchedaQuota");
                    utils.ajax("travel/ricerca/salva",{QUOTA:this.quota}, (response) => {});
                }
            });
        },
        aggiornaPriceRicerca : function(quota) {
            //RICALCOLO IL NUMERO DI NOTTI SOLO PER HOTEL
            if (this.datiRicerca.FK_ID_MODULO == 2){
                var diff = filters.dateDiff(this.datiRicerca.DATE_RANGE[0],this.datiRicerca.DATE_RANGE[1]);
                console.log(diff);
                this.datiRicerca.DATA_END = filters.addToDate(this.datiRicerca.DATA_START,diff,"days");
                this.datiRicerca.DATE_RANGE[0] = this.datiRicerca.DATA_START;
                this.datiRicerca.DATE_RANGE[1] = this.datiRicerca.DATA_END;
            }
            
            
            utils.ajax('travel/ricerca',this.datiRicerca, (response) => {
                if (response.esito == 0){
                    this.quota = response.data[0];
                    this.arrayPartenzeAlternative = new Array();
                    for (var i = 0 ; i < this.quota.PRICE_VICINE.length ; i++) {
                        var item = utils.clone(this.quota.PRICE_VICINE[i]);
                        item.DATA_START = filters.formatDate(item.DATA_START);
                        this.arrayPartenzeAlternative.push(item);
                    }
                    utils.ajax("travel/ricerca/salva",{QUOTA:this.quota}, (response) => {});
                    this.clickStepHandler(0);
                }
            });
        },
        ///travel/ricerca/getQuote
        getQuoteServizio : function(camera,idServizio){
            return utils.array.select(camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].quote,"FK_ID_SERVIZIO",idServizio);
        },
        trasformaPaxDbInPaxRicerca : function(quota){
            var numAdu = 0;
            var numChd = 0;
            var numInf = 0;
            var camera = {ID_CAMERA:1,ARRAY_ADULTI:new Array(),ARRAY_BAMBINI:new Array(),INDICE_RISULTATO_SELEZIONATO:0};
            for (var i = 0 ; i < quota.pax.length ; i++ ){
                var pax = utils.clone(quota.pax[i]);
                if (pax.TYPE == 'ADU'){
                    numAdu ++;
                    pax.ID_ADULTO = numAdu;
                    camera.ARRAY_ADULTI.push(pax);
                }
                else {                    
                    pax.ID_BAMBINO = numChd;
                    pax.NUM_ADU = numAdu;
                    if (quota.pax[i].TYPE == 'CHD'){
                        numChd ++;
                    }
                    else{
                        numInf ++;
                    }
                    camera.ARRAY_BAMBINI.push(pax);
                }
                
            }
            camera.NUM_ADULTI = numAdu;
            camera.NUM_BAMBINI = numChd + numInf;
            return camera;
        },
        selezionaIndiceRisultato : function(camera,indice){
            for (var i = 0 ; i < this.datiRicerca.camere.length ; i++){
                if (camera.CAMERA.ID_CAMERA == this.datiRicerca.camere[i].ID_CAMERA){
                    this.datiRicerca.camere[i].INDICE_RISULTATO_SELEZIONATO = indice;
                }
            }          
            this.aggiornaRicerca(camera,);
        },
        selezionaOpzione : function(camera,quota){
            var opzioniSelezionate = utils.clone(camera.RISULTATI[camera.CAMERA.INDICE_RISULTATO_SELEZIONATO].opzioniSelezionate);
            for (var i = 0 ; i < opzioniSelezionate.length ; i++){
                opzioniSelezionate[i].ID_CAMERA = camera.CAMERA.ID_CAMERA;
                if (opzioniSelezionate[i].FK_ID_SERVIZIO == quota.FK_ID_SERVIZIO){
                    opzioniSelezionate[i].CHIAVE_QUOTA = quota.FK_ID_PRODOTTO + "_" + quota.FK_ID_OPZIONE;
                }
            }
            this.aggiornaRicerca(camera,new Array({CHIAVE:"opzioniSelezionate",VALORE:opzioniSelezionate}));
        },
        aggiornaRicerca : function(camera,arrayParametriAggiuntivi = new Array()){
            var dati = utils.clone(this.datiRicerca);
            for (var i = 0 ; i < arrayParametriAggiuntivi.length ; i++){
                dati[arrayParametriAggiuntivi[i]['CHIAVE']] = arrayParametriAggiuntivi[i]['VALORE'];
            }
            utils.ajax('travel/ricerca',dati, (response) => {
                if (response.esito == 0){
                    for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                        for (var j = 0 ; j < response.data[0].CAMERE.length ; j++){
                            if (this.quota.CAMERE[i].CAMERA.ID_CAMERA == response.data[0].CAMERE[j].CAMERA.ID_CAMERA){
                                this.quota.CAMERE[i].RISULTATI = response.data[0].CAMERE[j].RISULTATI;
                                this.quota.CAMERE[i].INDICE_RISULTATO_SELEZIONATO = response.data[0].CAMERE[j].INDICE_RISULTATO_SELEZIONATO;                                
                                break;
                            }
                        }                        
                    }
                   // setTimeout(() => {})
                   // $('#linkQuotaCamera'+camera.ID_CAMERA).click();
                   utils.ajax("travel/ricerca/salva",{QUOTA:this.quota}, (response) => {});
                }
            });
        },        
        salvaPax : function(pax){
            utils.ajax("travel/quota/pax/update",pax, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            }); 
        },
        calcolaExtra : function(quota){           
            //COMPONGO ARRAYPAX
            var arrayCamere = new Array();
            for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                var item = this.quota.CAMERE[i].RISULTATI[this.quota.CAMERE[i].INDICE_RISULTATO_SELEZIONATO].pax;
                item.ID_CAMERA = this.quota.CAMERE[i].CAMERA.ID_CAMERA;
                arrayCamere.push(item);
            }
            utils.ajax("travel/quota/extra/calcola",{extra_aggiunti:this.arrayExtraAggiunti,pax:arrayCamere}, (response) => {
                if (response.esito == 0){
                    this.importoExtra = response.data.IMPORTO_EXTRA;
                    this.updateQuota();
                }
            }); 
        },
        isExtraSelezionato : function(extra){
            for (var i = 0 ; i < this.arrayExtraAggiunti.length ; i++){
                if (this.arrayExtraAggiunti[i].ID_PAX == extra.ID_PAX && this.arrayExtraAggiunti[i].ID_QUOTA == extra.ID_QUOTA){
                    return true;
                }
            }
            return false;
        },
        getExtraPax : function(extra_disponibili,pax,idQuota){
            var arr = new Array();//utils.clone(extra_disponibili);
            for (var i = 0 ; i < extra_disponibili.length ; i++){
                var extra = utils.clone(extra_disponibili[i]);
                if (extra.MODALITA_DI_CALCOLO == 'NOTTE_A_PERSONA' || extra.MODALITA_DI_CALCOLO == 'TOTALE_A_PERSONA'){
                    if (extra.PAX_TYPE == 'ALL' || extra.PAX_TYPE == pax.TYPE){
                        extra.ID_PAX = pax.ID_PAX;
                        extra.ID_QUOTA = idQuota;
                        arr.push(extra);
                    }                    
                }                
            }
            return arr;
        },
        getExtraCamera : function (extra_disponibili,idQuota){
            var arr = new Array();//utils.clone(extra_disponibili);
            for (var i = 0 ; i < extra_disponibili.length ; i++){
                var extra = utils.clone(extra_disponibili[i]);
                if (extra.MODALITA_DI_CALCOLO == 'NOTTE_A_SERVIZIO' || extra.MODALITA_DI_CALCOLO == 'TOTALE_A_SERVIZIO'){
                    extra.ID_PAX = 0;
                    extra.ID_QUOTA = idQuota;
                    arr.push(extra);                                     
                }                
            }
            return arr;
        },
        gestioneSelezioneExtra : function(extra){
            if (this.isExtraSelezionato(extra)){
                for (var i = 0 ; i < this.arrayExtraAggiunti.length ; i++){
                    if (extra.ID_EXTRA == this.arrayExtraAggiunti[i].ID_EXTRA && extra.ID_PAX == this.arrayExtraAggiunti[i].ID_PAX){
                        this.arrayExtraAggiunti.splice(i,1);
                    }
                }
            }
            else{
                this.arrayExtraAggiunti.push(extra);
            }
            this.calcolaExtra();
        },
        updateQuota : function(){
            utils.ajax("travel/ricerca/getQuote",{}, (response) => {
                for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                    this.quota.CAMERE[i]['RISULTATI'][this.quota.CAMERE[i].CAMERA.INDICE_RISULTATO_SELEZIONATO] = response.data[i];
                }
            });
        },
        getTotaleQuote : function(){
            var prezzoTotale = 0;
            for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                prezzoTotale += parseFloat(this.quota.CAMERE[i]['RISULTATI'][this.quota.CAMERE[i].CAMERA.INDICE_RISULTATO_SELEZIONATO]['PREZZO_TOTALE']);
            }
            return prezzoTotale;
        },
        getTotaleQuoteExtra : function(){
            var prezzoTotale = 0;
            for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                prezzoTotale += parseFloat(this.quota.CAMERE[i]['RISULTATI'][this.quota.CAMERE[i].CAMERA.INDICE_RISULTATO_SELEZIONATO]['IMPORTO_EXTRA']);
            }
            return prezzoTotale;
        },
        getTotaleQuoteNoExtra : function(){
            var prezzoTotale = 0;
            for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                var p = parseFloat(this.quota.CAMERE[i]['RISULTATI'][this.quota.CAMERE[i].CAMERA.INDICE_RISULTATO_SELEZIONATO]['PREZZO_TOTALE']);
                var pe = parseFloat(this.quota.CAMERE[i]['RISULTATI'][this.quota.CAMERE[i].CAMERA.INDICE_RISULTATO_SELEZIONATO]['IMPORTO_EXTRA']);
                if (!isNaN(pe)){
                    prezzoTotale += (p - pe);
                }
                else{
                    prezzoTotale += p;
                }
                
            }
            return prezzoTotale;
        },
        clickStepHandler : function(direzione){
            var a;
            
            if (direzione == 1){
                this.currentTab++;
               // a = $('.step-quota > .active').parent().next('li').find('a');
            }
            else if (direzione == 0){
                this.currentTab = 1;
               // a = $('.step-quota > .active');
            }
            else{
                --this.currentTab;
                //a = $('.step-quota > .active').parent().prev('li').find('a');
            } 
            var e = document.getElementById("wizardTab"+this.currentTab);
            e.click();
            
            if (this.currentTab == 2){
                this.selezionaCamera1("linkQuotaCamera1");
            }
            else if (this.currentTab == 3){
                this.selezionaCamera1("linkQuotaCameraPax1");                
            }
            else  if (this.currentTab == 4){
                this.selezionaCamera1("linkExtraCamera1");                  
            }  
        },        
        selezionaCamera1 : function(item,time = 100){
            setTimeout(() => {
                document.getElementById(item).click();//trigger('click');
            }, time);
        },
        ricercaPax:function(camera,i){
            this.indicePaxSelezionato = i;
            this.cameraSelezionata = camera;
            this.$refs.ricercaPfIntestario.openRicerca();
        },
        selezionaAmico : function(pax,camera,i,flgIntestatario){
            this.indicePaxSelezionato = i;
            this.cameraSelezionata = camera;
            if (flgIntestatario == 0){
                pax.personaFisica.FK_ID_PERSONA_FISICA = pax.ID_PERSONA_FISICA;
                this.handlerSelezionaPax(pax.personaFisica,1);
            }
            else{
                this.handlerSelezionaPax(pax,1);
            }            
        },
        handlerSelezionaPax(pax,flgAmico = 0){
            if (flgAmico == 0){
                pax.DATA_NASCITA = this.filters.formatDate(pax.DATA_NASCITA);
            }            
            this.cameraSelezionata.RISULTATI[this.cameraSelezionata.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax[this.indicePaxSelezionato].personaFisica = utils.clone(pax);
            this.cameraSelezionata.RISULTATI[this.cameraSelezionata.CAMERA.INDICE_RISULTATO_SELEZIONATO].pax[this.indicePaxSelezionato].FK_ID_PERSONA_FISICA = pax.FK_ID_PERSONA_FISICA;
            if (this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA == 0 && this.ordine.INTESTATARIO_FK_ID_PERSONA_GIURIDICA == 0){
                utils.alert.confirm("Vuoi impostare la persona selezionata anche come intestatario dell'ordine? Potrai anche modificare la tua scelta in seguito",()=>{
                    this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA = pax.ID_PERSONA_FISICA;
                },this.$root);
            }
        },       
        salvaQuota : function(){
            //COMPONGO ARRAYPAX
            var arrayCamere = new Array();
            for (var i = 0 ; i < this.quota.CAMERE.length ; i++){
                var item = this.quota.CAMERE[i].RISULTATI[this.quota.CAMERE[i].INDICE_RISULTATO_SELEZIONATO].pax;
                item.ID_CAMERA = this.quota.CAMERE[i].CAMERA.ID_CAMERA;
                arrayCamere.push(item);
            }
            utils.ajax("travel/quota/insert",{ID_ORDINE:this.idOrdine,ID_QUOTA:this.quota.ID_QUOTA,ID_QUOTA_DELETE:this.idQuotaDelete,pax:arrayCamere,INTESTATARIO_FK_ID_PERSONA_FISICA:this.ordine.INTESTATARIO_FK_ID_PERSONA_FISICA}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpSchedaQuota");
                    if (this.idOrdine == 0){
                        this.$root.ordineSelezionato = utils.clone({ID_ORDINE:response.data.ID_ORDINE});
                        if (this.quota.PRODOTTO.FK_ID_MODULO == 4){
                            this.$emit("salva",response.data.ID_ORDINE);
                        }
                        else{
                            global.router.push("Ordine");
                        }                        
                    }
                    else{
                        this.$emit("salva",response.data.ID_ORDINE);
                    }                    
                }
            });  
            /*
            if (this.quota.IS_TEMP == true){
                utils.ajax("travel/quota/insert",{ID_ORDINE:this.ordine.ID_ORDINE,ID_QUOTA:this.quota.ID_QUOTA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                       
                    }
                });  
            }
            else{
                utils.ajax("travel/quota/update",this.quota, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }
                }); 
            }  */          
        },
    },
    computed:{
        
    }

}
</script>

     