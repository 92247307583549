<template>

    <div class="row">
        <div class="col-12 col-md-6 mb-2">	
            <label class="form-label text-danger">Alias Azienda:</label>
            <div class="input-group">
				<span class="input-group-text"><i class="far fa-store-alt fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.ALIAS" :tabindex="4">
            </div>
        </div>
        <div class="col-12 col-md-6 mb-2">
            <label class="form-label text-danger">Ragione Sociale:</label>
            <div class="input-group">
				<span class="input-group-text"><i class="far fa-building fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.DENOMINAZIONE" :tabindex="5">
            </div>            
        </div>                                    

        <div class="col-12 col-md-3 mb-2">
            <label class="form-label text-danger">Nazionalit&agrave;:</label>
            <Select2 v-model="persona.FK_ID_NAZIONALITA" :w="250" :api="'/core/geo/stati/select2'" :apiselected="'core/basic/element?className=Stato&ID=ID_STATO&DESCRIZIONE=NOME&select2=1'" ::tabindex="2"></Select2>
        </div>
        <div class="col-8 col-md-6 mb-2">
            <label class="form-label text-danger">Indirizzo:</label>
            <div class="input-group">
				<span class="input-group-text"><i class="far fa-directions fa-fw"></i></span>
                <input type="text" class="form-control form-control-sm" v-model="persona.INDIRIZZO" :tabindex="6">                
            </div>            
        </div>
        <div class="col-4 col-md-3 mb-2">
            <label class="form-label text-danger">Civico:</label>
            <div class="input-group">
				<span class="input-group-text"><i class="far fa-sort-numeric-up-alt fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.CIVICO" :tabindex="7">       
            </div>              
        </div>
  
        <div class="col-4 col-md-3 mb-2">
            <label class="form-label text-danger">Cap:</label>
            <div class="input-group">
				<span class="input-group-text"><i class="far fa-qrcode fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.CAP" :tabindex="8">               
            </div>             
        </div>  
        <div class="col-4 col-md-6 mb-2">
            <label class="form-label text-danger">Citt&agrave;:</label>
            <Select2 v-show="persona.FK_ID_NAZIONALITA == 118" @selecteditem="setCap($event)" @updateValore="handlerChangeResidenza($event)"  v-model="persona.FK_ID_COMUNE" :w="250" :api="'core/geo/comuni/select2'" :apiselected="'core/basic/element?className=Comune&ID=ID_COMUNE&DESCRIZIONE=NOME&select2=1'" :tabindex="9"></Select2>
            <input v-show="persona.FK_ID_NAZIONALITA != 118" autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Comune" :tabindex="2" v-model="persona.COMUNE">                                                                                                                
        </div> 
        <div class="col-4 col-md-3 mb-2">
            <label class="form-label text-danger">Provincia:</label>
            <div class="input-group">
				<span class="input-group-text"><i class="far fa-map-pin fa-fw"></i></span>
                <input :disabled="persona.FK_ID_NAZIONALITA == 118" type="text" class="form-control form-control-sm" v-model="persona.PROV" :tabindex="10">                
            </div>             
        </div> 

        <div class="col-7 col-md-4 mb-2">
            <label class="form-label text-danger">Email</label>
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-envelope fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.EMAIL" :tabindex="11">
            </div>             
        </div>
        <div class="col-8 col-md-4 mb-2">
            <label class="form-label text-danger">Email certificata: (PEC)</label>
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-file-certificate fa-fw"></i></span>
                <input type="text" class="form-control form-control-sm" v-model="persona.PEC" :tabindex="12">
            </div>             
        </div>  
        <div class="col-5 col-md-4 mb-2">
            <label class="form-label text-danger">Telefono Ufficio:</label>
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-phone fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.TELEFONO" :tabindex="13">
            </div>             
        </div>
  
        <div class="col-4 col-md-3 mb-2">
            <label class="form-label text-danger">Codice SDI</label>
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-code fa-fw"></i></span>
                <input type="text" class="form-control form-control-sm" v-model="persona.CODICE_DESTINATARIO" :tabindex="14">
            </div>             
        </div>
        <div class="col-6 col-md-4 mb-2">
            <label class="form-label text-danger">Partita IVA</label>
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-shield-check fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.PARTITA_IVA" :tabindex="15">
            </div>             
        </div>
        <div class="col-6 col-md-5 mb-2">
            <label class="form-label text-danger">Codice fiscale</label>
            <div class="input-group">
                <span class="input-group-text"><i class="far fa-barcode fa-fw"></i></span>
                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="persona.CODICE_FISCALE" :tabindex="16">
            </div>             
        </div> 
    </div>

</template>



<script>
import { computed } from 'vue'
import Select2 from '@/components/utils/Select2.vue'
													

export default {
    name: 'PersonaGiuridica',
    components:{
        Select2,
    },
    props: {
      modelValue: {
          type: Object,
          default: () => ({}),
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const persona = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });
        return { persona };
    },
    data:function(){
        return{
            
        }
    },
    methods: {
        setCap(item){
            this.persona.CAP = item.CAP;
        },
        handlerChangeResidenza : function(val){			
			if (val > 0){
				utils.ajax("core/geo/comune",{ID_COMUNE:val}, (response) => {
					this.persona.PROV = response.data.provincia.DESC_BREVE;
				},false);	
			}		
		}
    },
    computed: {
    
    },
    watch: {
    
    },
}

</script>