<template>
    <Prodotti :modulo="2"></Prodotti>
</template>


<script>

import Prodotti from '@/components/Prodotti.vue'

export default {
    name:"Hotels",
    components:{
        Prodotti,
    },
}
</script>