<template>

    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" style="color:#000;"><span class="font-weight-semibold"> Preferenze </span></a> \
                    <span class="text-muted">Preferenze </span><code> - Lingua italiano</code>
                </h4>
                <h4 class="d-block d-xl-none"> Preferenze </h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body"> </a>
                    <a href="#" class="btn btn-link btn-float text-body"><i class="icon-reset"></i> <span>INDIETRO</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>
        </div>
    </div>
    <!-- /page header -->
    
    <!-- Content area -->
    <div class="content" style="padding: 0.5rem;">

       <div class="card shadow mb-0">
            <div class="collapse show">
			    <div class="card-body">

                    <ul class="nav nav-tabs mt-0 mb-1" id="tabs" role="tablist" style="margin-top:14px">
                        <li class="nav-item">
                            <a class="nav-link isprint-tab active" data-bs-toggle="tab" data-tab="configurazione" href="#configurazione" role="tab" aria-controls="configurazione" aria-selected="true">
                            <i class="fas fa-cogs fa-fw"></i> Configurazione
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="livelli" href="#livelli" role="tab" aria-controls="livelli" aria-selected="false">
                                <i class="fas fa-layer-group fa-fw"></i> Livelli
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="gruppi" href="#gruppi" role="tab" aria-controls="gruppi" aria-selected="false">
                                <i class="fas fa-list fa-fw"></i> Gruppi
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab"  data-bs-toggle="tab" data-tab="funzionalita" href="#funzionalita" role="tab" aria-controls="funzionalita" aria-selected="false">
                                <i class="fas fa-puzzle-piece fa-fw"></i> Funzionalità
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="menu" href="#menu" role="tab" aria-controls="menu" aria-selected="false">
                                <i class="fas fa-align-justify fa-fw"></i> Menu
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="customForm" href="#customForm" role="tab" aria-controls="customForm" aria-selected="false">
                            <i class="fas fa-bolt fa-fw"></i> Custom Form
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="email" href="#email"  role="tab" aria-controls="email" aria-selected="false">
                            <i class="far fa-envelope fa-fw"></i> Testi email
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="gruppiTipologiche" href="#gruppiTipologiche"  role="tab" aria-controls="gruppiTipologiche" aria-selected="false">
                            <i class="far fa-keyboard fa-fw"></i> Gruppi tipologiche
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="test" href="#test"  role="tab" aria-controls="test" aria-selected="false">
                            <i class="far fa-keyboard fa-fw"></i> Test
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link isprint-tab" data-bs-toggle="tab" data-tab="test" href="#nuovaTabella"  role="tab" aria-controls="nuovaTabella" aria-selected="false">
                            <i class="far fa-file fa-fw"></i> Nuova tabella
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">

                        <div class="tab-pane active" id="configurazione" role="tabpanel" style="padding: 0.5rem;">
                            <div class="row">
                                <div class="col-md-12">
                                    <button v-on:click="salvaOpzioni()" class="btn btn-success btn-sm float-end" style="margin-top:32px"><i class="fa fa-check"></i>Salva opzioni</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div id="accordion" style="margin-top:30px">
                                        <div class="card" v-for="gruppo in arrayOpzioniConfigurazione" v-bind:key="gruppo.id">
                                            <div class="card-header" :id="'heading__' + gruppo.id" style="background:#017cb7;">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" style="color:#fff" data-bs-toggle="collapse" :data-target="'#collapse__'+gruppo.id" aria-expanded="true" :aria-controls="'collapse__'+gruppo.id">
                                                        {{gruppo.titolo}}
                                                    </button>
                                                    <button class="btn btn-success btn-sm float-end" v-if="gruppo.id == 100" v-on:click="nuovaOpzione()" style="margin-top:4px"><i class="fal fa-check"></i> Nuova opzione</button>
                                                </h5>
                                            </div>
                                            <div :id="'collapse__'+ gruppo.id" class="collapse" :aria-labelledby="'heading__'+ gruppo.id" data-parent="#accordion">
                                                <div class="card-body">
                                                    <ul class="list-group" >
                                                        <li class="list-group-item" v-for="opzione in gruppo.opzioni" v-bind:key="opzione.LABEL"> 
                                                            <div class="col-md-8">
                                                                <span>{{opzione.LABEL}}</span>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <IControl :campo="opzione" v-model="opzione.VALORE"></IControl>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>                    
                            </div>
                        </div>

                        <div class="tab-pane" id="livelli" role="tabpanel" style="padding: 0.5rem;">
                            <div class="alert alert-secondary alert2 border-0">
                                <form class="isprint-filter" autocomplete="off"> 
                                    <div class="row">
                                        <div class="col-4 col-md-2 form-group2">
                                            <input class="form-control form-control-sm" autocomplete="off" placeholder="Descrizione" type="text" v-model="searchLivelli.DESCRIZIONE.VALUE">
                                        </div>           
                                        <div class="col-8 col-md-8 form-group2">
                                        </div>          
                                        <div class="col-8 col-md-2 form-group2">
                                            <button v-on:click="nuovoLivello()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> Nuovo livello</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <table class="table table-bordered" style="margin-top: 1.4rem;"> 
                                <thead>
                                    <tr class="bg-secondary text-white">
                                        <th style="width:2%;"> <input id="chkAllUsers" v-on:click="filters.selectAll('chkAllUsers',searchLivelli.arr)" type="checkbox" class=""> </th>
                                        <th style="width:5.95%;"> # </th>
                                        <th style="width:5%;"> Ico </th>
                                        <th>Descrizione</th>
                                        <th style="width:5%;">Core</th>
                                        <th style="width:5%;">Stato</th>
                                        <th style="width:5.4%;">Azioni</th>
                                    </tr>
                                </thead>
                            </table>
                            <div class="table-scrollable">
                                <table class="table table-bordered table-hover table-striped">
                                    <tbody>
                                        <tr v-for="livello in paginatedListLivelli.arr" v-bind:key="livello.ID_LIVELLO">
                                            <td class="text-center" style="width:2.4%;"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
                                            <td class="" style="width:5.9%;">{{livello.ID_LIVELLO}}</td>
                                            <td class="" style="width:5%;"> </td>
                                            <td class="" style="width:71.3%;">
                                                <span class="text-primary fw-bold cursor-pointer">{{livello.DESCRIZIONE}}</span><br />
                                                <span class="badge text-secondary font-size-xs text-muted">Descrizione: </span>
                                            </td>
                                            <td class="" style="width:5%;">{{livello.FLG_CORE}}</td>
                                            <td class="" style="width:5%;"></td>
                                            <td class="text-center" style="width:5.4%;">
                                                <div class="list-icons">
                                                    <div class="dropdown">
                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a href="#" class="dropdown-item" v-on:click="modificaLivello(livello)"><i class="fal fa-pencil fa-fw"></i> Modifica livello</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-12 float-end">
                                    <div class="float-end">
                                        <Pagine v-model="searchLivelli.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListLivelli.count" :numpagine="searchLivelli.PAGINATION.LENGTH"></Pagine>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="tab-pane" id="gruppi" role="tabpanel" style="padding: 0.5rem;">
                            <div class="alert alert-secondary alert2 border-0">
                                <form class="isprint-filter" autocomplete="off"> 
                                    <div class="row">
                                        <div class="col-4 col-md-2 form-group2">
                                            <input class="form-control form-control-sm" autocomplete="off" placeholder="Descrizione" type="text" v-model="searchGruppi.DESCRIZIONE.VALUE">
                                        </div>           
                                        <div class="col-8 col-md-8 form-group2">
                                        </div>          
                                        <div class="col-8 col-md-2 form-group2">
                                            <button v-on:click="nuovoGruppo()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> Nuovo gruppo</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <table class="table table-bordered" style="margin-top: 1.4rem;"> 
                                <thead>
                                    <tr class="bg-secondary text-white">
                                        <th style="width:2%;"> <input id="chkAllUsers" v-on:click="filters.selectAll('chkAllUsers',paginatedListGruppi.arr)" type="checkbox" class=""> </th>
                                        <th style="width:5.95%;"> # </th>
                                        <th style="width:5%;"> Ico </th>
                                        <th>Descrizione</th>
                                        <th style="width:5%;">Core</th>
                                        <th style="width:5%;">Stato</th>
                                        <th style="width:5.4%;">Azioni</th>
                                    </tr>
                                </thead>
                            </table>
                            <div class="table-scrollable">
                                <table class="table table-bordered table-hover table-striped">
                                    <tbody>
                                        <tr v-for="gruppo in paginatedListGruppi.arr" v-bind:key="gruppo.ID_GRUPPO">
                                            <td class="text-center" style="width:2.4%;"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
                                            <td class="" style="width:5.95%;">{{gruppo.ID_GRUPPO}}</td>
                                            <td class="" style="width:5%;"> </td>
                                            <td class="" style="width:71.6%;">
                                                <span class="text-primary fw-bold cursor-pointer">{{gruppo.DESCRIZIONE}}</span><br />
                                                <span class="badge text-secondary font-size-xs text-muted">Descrizione: </span>
                                            </td>
                                            <td class="" style="width:5%;">{{gruppo.FLG_CORE}}</td>
                                            <td class="" style="width:5%;"></td>
                                            <td class="text-center" style="width:5.4%;">
                                                <div class="list-icons">
                                                    <div class="dropdown">
                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a href="#" class="dropdown-item" v-on:click="modificaGruppo(gruppo)"><i class="fal fa-pencil fa-fw"></i> Modifica Gruppo</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a href="#" class="dropdown-item" v-on:click="funzionalitaGruppo(gruppo)"><i class="fal fa-list fa-fw"></i> Elenco Funzionalit&agrave;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-12 float-end">
                                    <div class="float-end">
                                        <Pagine v-model="searchGruppi.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListGruppi.count" :numpagine="searchGruppi.PAGINATION.LENGTH"></Pagine>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="tab-pane" id="funzionalita" role="tabpanel" style="padding: 0.5rem;">
                            <div class="alert alert-secondary alert2 border-0">
                                <form class="isprint-filter" autocomplete="off"> 
                                    <div class="row">
                                        <div class="col-4 col-md-2 form-group2">
                                            <input class="form-control form-control-sm" autocomplete="off" placeholder="Descrizione" type="text" v-model="searchFunzionalita.DESCRIZIONE.VALUE">
                                        </div>           
                                        <div class="col-8 col-md-8 form-group2">
                                        </div>          
                                        <div class="col-8 col-md-2 form-group2">
                                            <button v-on:click="nuovaFunzionalita()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> Nuova funzionalit&agrave;</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <table class="table table-bordered" style="margin-top: 1.4rem;"> 
                                <thead>
                                    <tr class="bg-secondary text-white">
                                        <th style="width:2%;"> <input id="chkAllUsers" v-on:click="filters.selectAll('chkAllUsers',paginatedList.arr)" type="checkbox" class=""> </th>
                                        <th style="width:5.95%;"> # </th>
                                        <th style="width:5%;"> Ico </th>
                                        <th>Descrizione</th>
                                        <th style="width:5%;">Core</th>
                                        <th style="width:5%;">Stato</th>
                                        <th style="width:5.4%;">Azioni</th>
                                    </tr>
                                </thead>
                            </table>
                            <div class="table-scrollable">
                                <table class="table table-bordered table-hover table-striped">
                                    <tbody>
                                        <tr v-for="funz in paginatedListFunzionalita.arr" v-bind:key="funz.ID_FUNZIONALITA">
                                            <td class="text-center" style="width:2.4%;"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
                                            <td class="" style="width:5.95%;">{{funz.ID_FUNZIONALITA}}</td>
                                            <td class="" style="width:5%;"> </td>
                                            <td class="" style="width:71.6%;">
                                                <span class="text-primary fw-bold cursor-pointer">{{funz.DESCRIZIONE}}</span><br />
                                                <span class="badge text-secondary font-size-xs text-muted">Descrizione: </span>
                                            </td>
                                            <td class="" style="width:5%;">{{funz.FLG_CORE}}</td>
                                            <td class="" style="width:5%;"></td>
                                            <td class="text-center" style="width:5.4%;">
                                                <div class="list-icons">
                                                    <div class="dropdown">
                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a href="#" class="dropdown-item" v-on:click="modificaFunzionalita(funz)"><i class="fal fa-pencil fa-fw"></i> Modifica funzionalit&agrave;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-12 float-end">
                                    <div class="float-end">
                                        <Pagine v-model="searchFunzionalita.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListFunzionalita.count" :numpagine="searchFunzionalita.PAGINATION.LENGTH"></Pagine>
                                    </div>
                                </div>
                            </div>     
                        </div>

                        <div class="tab-pane" id="menu" role="tabpanel" style="padding: 0.5rem;">
                            <div class="col-md-12" style="margin-top:20px">
                                <div  v-for="menu in arrayMenu" v-bind:key="menu.ID_MENU">
                                    <Tree v-bind:item="menu" :openicon="'fa fa-minus'" :closeicon="'fa fa-plus'" :leaficon="'far fa-file'" :buttons="buttonsMenu"></Tree>                        
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane" id="customForm" role="tabpanel" style="padding: 0.5rem;">
                            <form class="isprint-filter" autocomplete="off">            
                                <div class="form-group">
                                    <label>Descrizione</label><br/>
                                    <input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchCustomForm.DESCRIZIONE.VALUE">
                                </div>
                                <button v-on:click="installaCustomForm()" class="btn btn-info btn-sm float-end" style="margin-top:32px">Installa da JSON</button>
                                <button v-on:click="esportaSelezionati()" class="btn btn-info btn-sm float-end" style="margin-top:32px">Esporta selezionati</button>
                                <button v-on:click="nuovoCustomForm()" class="btn btn-success btn-sm float-end" style="margin-top:32px"><i class="fal fa-plus fa-fw"></i> Nuovo form</button>
                            </form>

                            <div class="isprint-grid-container" >    
                                <table class="isprint-grid shadow" >
                                    <thead class="isprint-grid-thead" >
                                        <tr style="left: 0px;background:#d6f80a" >
                                            <th  style="width:10%"><span>ID Form</span></th>
                                            <th  style="width:50%"><span >Descrizione</span></th>
                                            <th  style="width:15%"><span >Azioni</span></th>
                                            <th  style="width:10%"><span >Seleziona</span></th>
                                        </tr>
                                    </thead>
                                    <tbody class="isprint-grid-tbody" >
                                        <tr dstyle="left: 0px;" v-for="form in paginatedListCustomForm.arr" v-bind:key="form.ID_CUSTOM_FORM">
                                            <td style="width:10%"><span >{{form.ID_CUSTOM_FORM}}</span></td>
                                            <td style="width:50%"><span >{{form.DESCRIZIONE}}</span></td>
                                            <td style="width:15%">
                                                <span >
                                                    <button class="btn btn-sm pull-right btn-warning" v-on:click="modificaCustomForm(form)"><i class="fa fa-pencil-alt fa-fw"></i></button>
                                                    <button class="btn btn-sm pull-right btn-primary" v-on:click="controlliForm(form)"><i class="fa fa-list-alt fa-fw"></i></button>
                                                </span>
                                            </td>
                                            <td style="width:10%"><span ><ISwitch v-model="form.SELEZIONATO"></ISwitch></span></td>
                                        </tr>
                                    </tbody>
                                </table>     
                                <pagine v-model="searchGruppiTipologiche.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListCustomForm.count" :numpagine="searchGruppiTipologiche.PAGINATION.LENGTH"></pagine>
                            </div>
                        </div>

                        <div class="tab-pane" id="email" role="tabpanel" style="padding: 0.5rem;">
                            <div class="alert alert-secondary alert2 border-0">
                                <form class="isprint-filter" autocomplete="off"> 
                                    <div class="row">
                                        <div class="col-4 col-md-2 form-group2">
                                            <input class="form-control form-control-sm" autocomplete="off" placeholder="Descrizione" type="text" v-model="searchEmail.DESCRIZIONE.VALUE">
                                        </div>           
                                        <div class="col-8 col-md-8 form-group2">
                                        </div>          
                                        <div class="col-8 col-md-2 form-group2">
                                            <button v-on:click="nuovoTestoEmail()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> Nuovo testo</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <table class="table table-bordered" style="margin-top: 1.4rem;"> 
                                <thead>
                                    <tr class="bg-secondary text-white">
                                        <th style="width:2%;"> <input id="chkAllUsers" v-on:click="filters.selectAll('chkAllUsers',searchEmail.arr)" type="checkbox" class=""> </th>
                                        <th style="width:5.95%;"> # </th>
                                        <th style="width:5%;"> Ico </th>
                                        <th>Descrizione</th>
                                        <th style="width:5%;">Core</th>
                                        <th style="width:5%;">Stato</th>
                                        <th style="width:5.4%;">Azioni</th>
                                    </tr>
                                </thead>
                            </table>
                            <div class="table-scrollable">
                                <table class="table table-bordered table-hover table-striped">
                                    <tbody>
                                        <tr v-for="testo in paginatedListTestiEmail.arr" v-bind:key="testo.ID_TESTO_EMAIL">
                                            <td class="text-center" style="width:2.4%;"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
                                            <td class="" style="width:5.9%;">{{testo.ID_TESTO_EMAIL}}</td>
                                            <td class="" style="width:5%;"> </td>
                                            <td class="" style="width:71%;">
                                                <span class="text-primary fw-bold cursor-pointer">{{testo.DESCRIZIONE}}</span><br />
                                                <span class="badge text-secondary font-size-xs text-muted">Descrizione: </span>
                                            </td>
                                            <td class="" style="width:5%;">{{testo.FLG_CORE}}</td>
                                            <td class="" style="width:5%;"></td>
                                            <td class="text-center" style="width:5.4%;">
                                                <div class="list-icons">
                                                    <div class="dropdown">
                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a href="#" class="dropdown-item" v-on:click="modificaTestoEmail(testo)"><i class="fal fa-pencil fa-fw"></i> Modifica Gruppo</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a href="#" class="dropdown-item" v-on:click="testEmail(testo)"><i class="fal fa-envelope fa-fw"></i> Test email</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-12 float-end">
                                    <div class="float-end">
                                        <Pagine v-model="searchEmail.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListTestiEmail.count" :numpagine="searchEmail.PAGINATION.LENGTH"></Pagine>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="tab-pane" id="gruppiTipologiche" role="tabpanel" style="padding: 0.5rem;">
                            <div class="alert alert-secondary alert2 border-0">
                                <form class="isprint-filter" autocomplete="off"> 
                                    <div class="row">
                                        <div class="col-4 col-md-2 form-group2">
                                            <input class="form-control form-control-sm" autocomplete="off" placeholder="Descrizione" type="text" v-model="searchGruppiTipologiche.DESCRIZIONE.VALUE">
                                        </div>           
                                        <div class="col-8 col-md-8 form-group2">
                                        </div>          
                                        <div class="col-8 col-md-2 form-group2">
                                            <button v-on:click="nuovoGruppoTipologiche()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> Nuovo gruppo</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="tableFixHead">
                                <table class="table table-bordered" style="margin-top: 1.4rem;"> 
                                    <thead>
                                        <tr class="bg-secondary text-white">
                                            <th style="width:2%;"> <input id="chkAllUsers" v-on:click="filters.selectAll('chkAllUsers',searchGruppiTipologiche.arr)" type="checkbox" class=""> </th>
                                            <th style="width:6%;"> # </th>
                                            <th style="width:5%;"> Ico </th>
                                            <th>Descrizione</th>
                                            <th style="width:10%;">Livello</th>
                                            <th style="width:10%;">Sito</th>
                                            <th style="width:5.4%;">Azioni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="gruppo in paginatedListGruppiTipologiche.arr" v-bind:key="gruppo.ID_GRUPPO">
                                            <td class="text-center" style=""><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
                                            <td class="" style="">{{gruppo.ID_GRUPPO_TIPOLOGICA}}</td>
                                            <td class="" style=""> </td>
                                            <td class="" style="">
                                                <span class="text-primary fw-bold cursor-pointer">{{gruppo.DESCRIZIONE}}</span><br />
                                                <span class="badge text-secondary font-size-xs text-muted">Descrizione: </span>
                                            </td>
                                            <td class="" style=""><span class="">{{gruppo.LIVELLO}}</span></td>
                                            <td class="" style=""><span class="">{{gruppo.ENTE}}</span></td>
                                            <td class="text-center" style="">
                                                <div class="list-icons">
                                                    <div class="dropdown">
                                                        <a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a href="#" class="dropdown-item" v-on:click="modificaGruppoTipologiche(gruppo)"><i class="fal fa-pencil fa-fw"></i> Modifica Gruppo</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a href="#" class="dropdown-item" v-on:click="tipologicheGruppo(gruppo)"><i class="fal fa-list fa-fw"></i> Elenco Funzionalit&agrave;</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-12 float-end">
                                    <div class="float-end">
                                        <Pagine v-model="searchGruppiTipologiche.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListGruppiTipologiche.count" :numpagine="searchGruppiTipologiche.PAGINATION.LENGTH"></Pagine>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="tab-pane" id="test" role="tabpanel" style="padding: 0.5rem;">

                            <button class="btn btn-danger" v-on:click="backup()">BackUp()</button>


                            <button class="btn btn-success" v-on:click="test()">Test()</button>

                            <button class="btn btn-info" v-on:click="test2()">Test2()</button>


                        
                        </div>

                        <div class="tab-pane" id="nuovaTabella" role="tabpanel" style="padding: 0.5rem;">

                            <div class="form-group">
                                <label>Nome tabella</label><br/>
                                <input type="text" v-model="tabella" class="form-control">
                            </div>
                            <button class="btn btn-success pull-right" v-on:click="buildClass()"><i class="fa fa-check"></i>Crea classe</button>
                        </div>

                    </div>
							
                    <div class="modal fade" id="popUpFunzionalita" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Funzionalita</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="funzionalitaSelezionata.DESCRIZIONE">
                                    </div>
                                    <div class="form-group">
                                        <label>Voce di menu correlata</label>
                                        <select  class="form-control" placeholder="Descrizione" v-model="funzionalitaSelezionata.FK_ID_ITEM">
                                            <option value="0">Nessuna voce</option>
                                            <option v-for="menu in linearMenuItems" v-bind:value="menu.ID_ITEM" v-bind:key="menu.ID_ITEM">{{menu.ID_ITEM}} - {{menu.DESCRIZIONE}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaFunzionalita()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpMenu" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Menu</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="menuSelezionato.DESCRIZIONE">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaMenu()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="modal fade" id="popUpLivello" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Livello</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="livelloSelezionato.DESCRIZIONE">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaLivello()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpNuovaOpzione" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Nuova opzione</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Label</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="opzioneSelezionata.LABEL">
                                    </div>
                                    <div class="form-group">
                                        <label>Valore</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="opzioneSelezionata.VALORE">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaOpzioneSito()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="modal fade" id="popUpGruppo" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Gruppo</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="gruppoSelezionato.DESCRIZIONE">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaGruppo()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpGruppoTipologiche" tabindex="-1" role="dialog" aria-modal="true" >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-2 bg-light text-dark">
                                    <h6 class="modal-title">Gruppo Tipologica</h6>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-2 form-group-1"><label class="font-weight-semibold">Descrizione:</label></div>
                                        <div class="col-md-10 form-group-1"><input type="text" class="form-control" placeholder="Descrizione" v-model="gruppoTipologicaSelezionato.DESCRIZIONE"></div>
                                        <div class="col-md-2 form-group-1"><label class="font-weight-semibold">Livello:</label></div>
                                        <div class="col-md-10 form-group-1">
                                            <select class="form-control form-control-sm" v-model="gruppoTipologicaSelezionato.FK_ID_LIVELLO">
                                                <option v-for="livello in $root.arrayLivelli" v-bind:value="livello.ID_LIVELLO" v-bind:key="livello.ID_LIVELLO" v-show="livello.ID_LIVELLO > 1">{{ livello.DESCRIZIONE }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-2 form-group-1"><label class="font-weight-semibold">Ente:</label></div>
                                        <div class="col-md-10 form-group-1">
                                            <select class="form-control form-control-sm" v-model="gruppoTipologicaSelezionato.FK_ID_ENTE">
                                                <option v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE" v-bind:value="ente.ID_ENTE">{{ ente.LABEL }}</option>
                                            </select>
                                        </div>                                    
                                    </div>
                                </div>
                                <div class="modal-footer modal-footer-1 bg-light">
                                    <button type="button" class="btn btn-light btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> ANNULLA </button>
                                    <button type="button" class="btn btn-secondary btn-sm" v-on:click="salvaGruppoTipologiche()"><i class="far fa-save fa-fw"></i> SALVA </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpTipologicheGruppo" tabindex="-1"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-2 bg-light text-dark">
                                    <h6 class="modal-title">{{gruppoTipologicaSelezionato.DESCRIZIONE}} - Elenco Tipologiche</h6>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-2 form-group-1"><label class="font-weight-semibold">Descrizione:</label></div>
                                        <div class="col-md-10 form-group-1"><input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchTipologiche.DESCRIZIONE.VALUE"></div>
                                    </div>
                                    <hr class="hr-2" />
                                    <div class="box_26">
                                    <table class="table table-hover table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="table-head-sm-2" style="width: 10%;">#</th> 
                                                <th class="table-head-sm-2" style="width: 38%;">Descrizione</th>  
                                                <th class="table-head-sm-2" style="width: 38%;">Valore</th>  
                                                <th class="table-head-sm-2" style="width: 14%;">Azioni <div class="badge bg-light badge-xs float-end" v-on:click="nuovaTipologica()"><i class="fa fa-plus fa-fw"></i></div></th> 
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            <tr v-for="tipologica in paginatedListTipologiche.arr" v-bind:key="tipologica.ID_TIPOLOGICA">
                                                <td>{{tipologica.ID_TIPOLOGICA}}</td>
                                                <td><input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="tipologica.DESCRIZIONE"></td> 
                                                <td><input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="tipologica.VALORE"></td> 
                                                <td style="text-align: center;">
                                                    <button class="btn btn-success btn-sm me-2" v-on:click="updateTipologica(tipologica)"><i class="fa fa-check"></i></button>
                                                    <button class="btn btn-danger btn-sm" v-on:click="deleteTipologica(tipologica)"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div class="modal-footer modal-footer-1 bg-light" style="display: unset;">
                                    <div class="float-start">
                                        <pagine class="mt-0" v-model="searchTipologiche.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListTipologiche.count" :numpagine="searchTipologiche.PAGINATION.LENGTH"></pagine>
                                    </div>
                                    <div class="float-end">
                                        <button type="button" class="btn btn-light btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> ANNULLA </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpTipologica" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Tipologica</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="tipologicaSelezionata.DESCRIZIONE">
                                    </div>
                                    <div class="form-group">
                                        <label>Valore</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="tipologicaSelezionata.VALORE">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaTipologica()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpCustomForm" tabindex="-1" role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Custom Form</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <input type="text" class="form-control" placeholder="Descrizione" v-model="customFormSelezionato.DESCRIZIONE">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Annulla</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaCustomForm()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpFunzionalitaGruppo"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Funzionalità gruppo {{gruppoSelezionato.DESCRIZIONE}}</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12" style="margin-top:10px">
                                        <div class="row" >
                                            <div class="isprint-filter" style="width:100%">            
                                                <div class="form-group">
                                                    <label>Descrizione</label><br/>
                                                    <input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchFunzGruppo.DESCRIZIONE.VALUE">
                                                </div>   
                                            </div>
                                        </div>
                                        <div class="row" >
                                            <ul class="list-group" style="width:100%">
                                                <li class="list-group-item isprint-list" v-for="funzionalita in paginatedLisFunzGruppo.arr" v-bind:key="funzionalita.ID_FUNZIONALITA">
                                                    <div class="col-md-9">{{funzionalita.DESCRIZIONE}}</div>
                                                    <div class="col-md-3"  >
                                                        <ISwitch color="#5cb85c" @toggle="updateFunzionalitaGruppo($event,funzionalita)" :size="'sm'" v-model="funzionalita.SELEZIONATO" class="pull-right"></ISwitch>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>                       
                                    </div>

                                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded" >
                                        <pagine v-model="searchFunzGruppo.PAGINATION.PAGE_NUMBER" :numrighe="paginatedLisFunzGruppo.count" :numpagine="searchFunzGruppo.PAGINATION.LENGTH"></pagine>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Chiudi</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpMenuChildren"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg"  >
                            <div class="modal-content" >
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Elementi menu {{menuSelezionato.DESCRIZIONE}}</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12" style="margin-top:10px">
                                        <div class="row" >
                                            <IForm :campi="arrayCampiMenuChildren" v-bind:model="menuSelezionato"></IForm>
                                        </div>                       
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa fa-times fa-fw"></i>Chiudi</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaMenuChildren()"><i class="fa fa-check fa-fw"></i>Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpCustomFormElements"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg"   >
                            <div class="modal-content" >
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Elementi Custom Form {{customFormSelezionato.DESCRIZIONE}}</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12" style="margin-top:10px">
                                        <div class="row" >
                                            <div class="col-md-12" >
                                                <button class="float-end btn btn-success"  v-on:click="nuovoCustomFormElement()"><i class="fa fa-plus fa-fw"></i>Nuovo</button>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top:10px" >
                                        
                                            <draggable class="list-group" :list="arrayCustomFormElement" group="people" @change="customFormElementOrdineHandler" style="width:100%">
                                                <li class="list-group-item isprint-list" style="height:56px" v-for="item in arrayCustomFormElement" v-bind:key="item.LABEL">
                                                    <div class="col-md-5" style="margin-top:-8px" ><span style="font-weight:bold">{{item.LABEL}}</span><br/><small>{{item.TYPE}}</small></div>
                                                    <div class="col-md-4" style="margin-top:-8px">{{item.VMODEL}}<br/><small>{{item.CLASSE}}</small></div>
                                                    <div class="col-md-3">
                                                        <button class="btn btn-sm float-end btn-warning" v-on:click="modificaCustomFormElement(item)"><i class="fa fa-pencil-alt fa-fw"></i></button>
                                                        <button class="btn btn-sm float-end btn-danger" v-on:click="deleteCustomFormElement(item)"><i class="fa fa-trash fa-fw"></i></button>
                                                    </div>
                                                </li>
                                            </draggable>
                                        </div>                       
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Chiudi</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaOrdineCustomFormElements()">SalvaOrdine</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popInstallazioneCustomForm"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog" style="max-width:574px"  >
                            <div class="modal-content" >
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Modifica avatar</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <Upload :args="argsCustomFormElements"  @onsuccess="chiudiPopUpInstallazioneCustomForm($event)" @onerror="chiudiPopUpInstallazioneCustomForm($event)"></Upload>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa fa-times fa-fw"></i>Chiudi</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpEmail"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog" style="max-width:574px"  >
                            <div class="modal-content" >
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Modifica avatar</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12">
                                        <label>Indirizzo email</label>
                                        <input type="text"  class="form-control" v-model="emailTest">
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-success" v-on:click="doTestEmail()"><i class="fa fa-check fa-fw"></i> Invia</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpTestoEmail"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog" style="max-width:1000px"  >
                            <div class="modal-content" >
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Email</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12">
                                        <label>Descrizione</label>
                                        <input type="text"  class="form-control" v-model="testoEmailSelezionato.DESCRIZIONE">
                                    </div>
                                    <div class="col-md-12">
                                        <label>Oggetto</label>
                                        <input type="text"  class="form-control" v-model="testoEmailSelezionato.OGGETTO">
                                    </div>
                                    <div class="col-md-12">
                                        <label>Messaggio</label>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><i class="fa fa-times fa-fw"></i>Chiudi</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaTestoEmail()"><i class="fa fa-check fa-fw"></i> Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="popUpCustomFormElement"  role="dialog"  aria-modal="true" >
                        <div class="modal-dialog modal-lg"  style="max-width:1200px" >
                            <div class="modal-content" >
                                <div class="modal-header modal-header-info">
                                    <h5 class="modal-title">Elementi form {{customFormSelezionato.DESCRIZIONE}}</h5>
                                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12" style="margin-top:10px">
                                        <div class="row" >
                                            <div class="col-md-6">
                                                <label>Label</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.LABEL">
                                            </div>
                                            <div class="col-md-6">
                                                <label>Type</label>
                                                <select   class="form-control" v-model="customFormElementSelezionato.TYPE">
                                                    <option v-bind:value="'TEXT'">TEXT</option>
                                                    <option v-bind:value="'PASSWORD'">PASSWORD</option>
                                                    <option v-bind:value="'DATE'">DATE</option>
                                                    <option v-bind:value="'SELECT'">SELECT</option>
                                                    <option v-bind:value="'SELECT2'">SELECT2</option>
                                                    <option v-bind:value="'BUTTON'">BUTTON</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>VMODEL</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.VMODEL">
                                            </div>
                                            <div class="col-md-6">
                                                <label>STYLE</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.STYLE">
                                            </div>
                                        </div>    
                                        <div class="row">
                                            <div class="col-md-4">
                                                <label>Classe</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.CLASSE">
                                            </div>
                                            <div class="col-md-4">
                                                <label>Classe CTRL</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.CLASSE_CTRL">
                                            </div>
                                            <div class="col-md-4">
                                                <label>Options</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.OPTIONS">
                                            </div>
                                        </div>    
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label>Api</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.API">
                                            </div>
                                            <div class="col-md-6">
                                                <label>Api Selected</label>
                                                <input type="text"  class="form-control" v-model="customFormElementSelezionato.API_SELECTED">
                                            </div>
                                        </div>            
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Chiudi</button>
                                    <button type="button" class="btn btn-success" v-on:click="salvaCustomFormElement()">Salva</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
       </div>

    </div>

</template>


<script>

import Pagine from '@/components/utils/Pagine.vue'
import Tree from '@/components/utils/Tree.vue'
import IForm from '@/components/utils/IForm.vue'
import IControl from '@/components/utils/IControl.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Upload from '@/components/utils/Upload.vue'
import { VueDraggableNext } from 'vue-draggable-next'


export default {
    name:"Configurazione",
    components:{
        Pagine,
        Tree,
        IForm,
        draggable: VueDraggableNext,
        IControl,
        ISwitch,
        Upload,
       
    },
    data: function () {
        return {   
            arrayFunzionalita: new Array(),
            arrayLivelli: new Array(),
            arrayGruppi : new Array(),
            arrayMenu : new Array(),
            arrayMenuChildren : new Array(),
            arrayGruppiTipologiche : new Array(),
            arrayTipologiche : new Array(),
            arrayFunzionalitaGruppo : new Array(),
            arrayCustomForm : new Array(),
            arrayTestiEmail : new Array(),
            linearMenuItems : new Array(),
            opzioneSelezionata : {},
            tabella:'',
            buttonsMenu:new Array(
                {class:'btn btn-info btn-sm',method:this.childrenMenu,icon:"fa fa-list-alt "},
                {class:'btn btn-success btn-sm',method:this.nuovoMenuChildren,icon:"fa fa-plus "},
                {class:'btn btn-warning btn-sm',method:this.modificaMenuChildren,icon:"fa fa-pencil-alt"},
                {class:'btn btn-danger btn-sm',method:this.deleteMenuChildren,icon:"fa fa-trash"},
                ),
            selectedMenuItem:{},
            arrayCampiMenuChildren : new Array(),
            searchFunzionalita:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            funzionalitaSelezionata:{},
            searchGruppi:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            gruppoSelezionato:{},
            searchEmail:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            emailTest : '',
            testoEmailSelezionato:{},
            searchLivelli:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            livelloSelezionato:{},
            searchFunzGruppo:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            gruppoTipologicaSelezionato:{},
            searchGruppiTipologiche:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            tipologicaSelezionata:{},
            searchTipologiche:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            menuSelezionato:{},
            searchMenu:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            menuChildrenSelezionato : {},
            searchMenuChidren:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            customFormSelezionato : {},
            searchCustomForm:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            arrayCustomFormElement : new Array(),
            customFormElementSelezionato : {},
            selected: 2,
            argsCustomFormElements:{
                'modalita' : 'uploadCustomFormElements'
            },
            //ULTIMO ID_INSERITO = 50
            arrayOpzioniConfigurazione : new Array(
                {
                    titolo:"Generali",
                    id:1,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:1,LABEL:'NOME_SITO', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:35,LABEL:'URL_SITO', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:26,LABEL:'TITLE', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:29,LABEL:'MASCHERA_LOGIN_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:42,LABEL:'EMAIL_ADMIN', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:44,LABEL:'TIMEOUT_SESSIONE', TYPE:"TEXT",VALORE:3600},
                        {ID_OPZIONE_BASE:45,LABEL:'RELOAD_SESSIONE_SCADUTA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:46,LABEL:'FLG_UNA_SESSIONE_PER_UTENTE', TYPE:"SELECT",VALORE:0,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:47,LABEL:'SOGLIA_MULTIPLE_INSERT', TYPE:"TEXT",VALORE:3000},
                        {ID_OPZIONE_BASE:48,LABEL:'DROPBOX_CLIENT_ID', TYPE:"TEXT",VALORE:''},
                    )
                },
                {
                    titolo:"Stile",
                    id:4,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:2,LABEL:'COLORE_HEAD', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:3,LABEL:'LOGO_HEAD', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:4,LABEL:'LOGO_MAIN', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:36,LABEL:'BACKGROUND', TYPE:"TEXT",VALORE:'#f8f9fc'},
                        {ID_OPZIONE_BASE:21,LABEL:'MOSTRA_MENU_LATERALE', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:22,LABEL:'MOSTRA_MENU_TOP', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:23,LABEL:'COLORE_TESTO_HEAD', TYPE:"TEXT", TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:24,LABEL:'ALTEZZA_HEAD', TYPE:"TEXT", TYPE:"TEXT",VALORE:46},
                        {ID_OPZIONE_BASE:25,LABEL:'MOSTRA_BREADCRUMB', TYPE:"SELECT", TYPE:"TEXT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:39,LABEL:'DELTA_MARGIN_TOP', TYPE:"TEXT", TYPE:"TEXT",VALORE:'0'},
                        {ID_OPZIONE_BASE:40,LABEL:'BG_LOGIN', TYPE:"TEXT", TYPE:"TEXT",VALORE:'#FFF'},

                    )
                },
                {
                    titolo:"Email",
                    id:2,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:5,LABEL:'USERNAME', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:6,LABEL:'PASSWORD', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:7,LABEL:'FROM', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:8,LABEL:'REPLY_TO', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:9,LABEL:'SERVER_SMTP', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:10,LABEL:'PORTA', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:11,LABEL:'SICUREZZA', TYPE:"SELECT",VALORE:'0',OPTIONS:new Array({ID:0,DESCRIZIONE:'NESSUNA'},{ID:'ssl',DESCRIZIONE:'SSL'},{ID:'tls',DESCRIZIONE:'TLS'})},
                        {ID_OPZIONE_BASE:12,LABEL:'DKIM_DOMAIN', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:13,LABEL:'DKIM_KEY', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:14,LABEL:'DKIM_SELECTOR', TYPE:"TEXT",VALORE:'-'},
                        {ID_OPZIONE_BASE:41,LABEL:'TESTATA_EMAIL', TYPE:"TEXT",VALORE:'-'},
                    )
                },
                {
                    titolo:"Registrazione",
                    id:3,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:15,LABEL:'REGISTRAZIONE_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:16,LABEL:'REGISTRAZIONE_CON_FACEBOOK_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:17,LABEL:'ID_APP_FACEBOOK', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:18,LABEL:'FACEBOOK_APP_SECRET', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:19,LABEL:'REGISTRAZIONE_CON_GOOGLE_ABILITATA', TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:20,LABEL:'ID_APP_GOOGLE', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:27,LABEL:'FK_ID_LIVELLO_DEFAULT', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:28,LABEL:'FK_ID_GRUPPO_DEFAULT', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:43,LABEL:'NOTIFICA_REGISTRAZIONE_ADMIN', TYPE:"TEXT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'NO'},{ID:1,DESCRIZIONE:'SI'})},
                        {ID_OPZIONE_BASE:49,LABEL:'LIVELLO_PASSWORD',TYPE:"SELECT",VALORE:1,OPTIONS:new Array({ID:0,DESCRIZIONE:'LIBERA'},{ID:1,DESCRIZIONE:'LUNGHEZZA MINIMA 8'},{ID:2,DESCRIZIONE:'ALMENO UN CARATTERE ALFABETICO'},{ID:3,DESCRIZIONE:'ALMENO UN NUMERO'},{ID:4,DESCRIZIONE:'ALMENO UN CARATTERE SPECIALE'})},
                        {ID_OPZIONE_BASE:50,LABEL:'MESSAGGIO_REGISTRAZIONE', TYPE:"TEXT",VALORE:''},
                    )
                },
                {
                    titolo:"Stripe",
                    id:5,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:32,LABEL:'MODALITA_STRIPE', TYPE:"SELECT",VALORE:0,OPTIONS:new Array({ID:0,DESCRIZIONE:'TEST'},{ID:1,DESCRIZIONE:'LIVE'})},
                        {ID_OPZIONE_BASE:30,LABEL:'TEST_STRIPE_PUBLIC_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:31,LABEL:'TEST_STRIPE_SECRET_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:37,LABEL:'TEST_STRIPE_CLIENT_ID', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:33,LABEL:'LIVE_STRIPE_PUBLIC_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:34,LABEL:'LIVE_STRIPE_SECRET_KEY', TYPE:"TEXT",VALORE:''},
                        {ID_OPZIONE_BASE:38,LABEL:'LIVE_STRIPE_CLIENT_ID', TYPE:"TEXT",VALORE:''},
                    )
                },
                {
                    titolo:"Altre",
                    id:100,
                    opzioni: new Array(
                        
                    )
                },
            ),
          //  editor:ClassicEditor,
          //  editorData : ''
            
        }
    },
    methods: {
        
        add: function() {
            this.list.push({ name: "Juan" });
          },
          replace: function() {
            this.list = [{ name: "Edgard" }];
          },
          clone: function(el) {
            return {
              name: el.name + " cloned"
            };
          },
          log: function(evt) {
            window.console.log(evt);
        },
        customFormElementOrdineHandler: function(evt) {
            window.console.log(evt);
            for (var i = 0 ; i < this.arrayCustomFormElement.length ; i++){
                this.arrayCustomFormElement[i].ORDINE = (i+1);
            }
        },
        salvaOrdineCustomFormElements : function(){
            utils.ajax("core/customformelement/ordine",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM,arrayCustomFormElement:this.arrayCustomFormElement}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        },
        getFunzionalita: function () {
            utils.ajax("core/funzionalita",{}, (response) => {
                this.arrayFunzionalita = response.data;
            });
        },
        nuovaFunzionalita:function(){
            this.funzionalitaSelezionata = utils.nuovoElemento(this.funzionalitaSelezionata,{ID_FUNZIONALITA:0,FLG_CORE:0,DESCRIZIONE:"",FK_ID_ITEM:0},"popUpFunzionalita");
        },
        modificaFunzionalita:function(funzionalita){
            this.funzionalitaSelezionata = utils.modificaElemento(this.funzionalitaSelezionata,funzionalita,"popUpFunzionalita");
        },
        salvaFunzionalita:function(){
            utils.salvaElemento("core/funzionalita/",this.funzionalitaSelezionata,"ID_FUNZIONALITA","popUpFunzionalita",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getFunzionalita();
                }
            });
        },
        getGruppi: function () {
            utils.ajax("core/gruppi",{}, (response) => {
                this.arrayGruppi = response.data;
            });
        },
        funzionalitaGruppo : function(gruppo){
            this.arrayFunzionalitaGruppo = new Array();
            utils.ajax("core/gruppo/funzionalita",gruppo, (response) => {
                this.gruppoSelezionato = response.data.gruppo;
                for (var i = 0 ; i < response.data.funzionalita.length ; i++){
                    var selezionato = utils.array.select(this.gruppoSelezionato.funzionalita,"ID_FUNZIONALITA",response.data.funzionalita[i]['ID_FUNZIONALITA'],1).length == 1 ? 1 : 0;
                    var item = utils.clone(response.data.funzionalita[i]);
                    item.SELEZIONATO = selezionato;
                    this.arrayFunzionalitaGruppo.push(item);
                }
                this.filters.openPopUp('#popUpFunzionalitaGruppo');
            });
        },
        nuovoGruppo:function(){
            this.gruppoSelezionato = utils.nuovoElemento(this.gruppoSelezionato,{ID_GRUPPO:0,FLG_CORE:0,DESCRIZIONE:""},"popUpGruppo");
        },
        modificaGruppo:function(gruppo){
            this.gruppoSelezionato = utils.modificaElemento(this.gruppoSelezionato,gruppo,"popUpGruppo");
        },
        salvaGruppo:function(){
            utils.salvaElemento("core/gruppi/",this.gruppoSelezionato,"ID_GRUPPO","popUpGruppo",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getGruppi();
                }
            });
        },
        getLivelli: function () {
            utils.ajax("core/livelli",{}, (response) => {
                this.arrayLivelli = response.data;
            });
        },
        nuovoLivello:function(){
            this.livelloSelezionato = utils.nuovoElemento(this.livelloSelezionato,{ID_LIVELLO:0,FLG_CORE:0,DESCRIZIONE:""},"popUpLivello");
        },
        modificaLivello:function(funzionalita){
            this.livelloSelezionato = utils.modificaElemento(this.livelloSelezionato,funzionalita,"popUpLivello");
        },
        salvaLivello:function(){
            utils.salvaElemento("core/livelli/",this.livelloSelezionato,"ID_LIVELLO","popUpLivello",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getLivelli();
                }
            });
        },
        updateFunzionalitaGruppo:function(selezionato,funzionalita){
            setTimeout(() => {
                utils.ajax("core/gruppi/funzionalita/update",{ID_FUNZIONALITA:funzionalita.ID_FUNZIONALITA,ID_GRUPPO:this.gruppoSelezionato.ID_GRUPPO,SELEZIONATO:funzionalita.SELEZIONATO}, (response) => {
                
                });                
            }, 200);
        },

        // TESTI EMAIL
        getTestiEmail: function () {
            utils.ajax("core/testiemail",{}, (response) => {
                this.arrayTestiEmail = response.data;
            });
        },
        nuovoTestoEmail:function(){
            this.testoEmailSelezionato = utils.nuovoElemento(this.testoEmailSelezionato,{ID_TESTO_EMAIL:0,className:"TestoEmail"},"popUpTestoEmail");
        },
        modificaTestoEmail:function(testo){
            testo.className = "TestoEmail";
            this.testoEmailSelezionato = utils.modificaElemento(this.testoEmailSelezionato,testo,"popUpTestoEmail");
        },
        salvaTestoEmail:function(){
            utils.salvaElemento("core/basic/",this.testoEmailSelezionato,"ID_TESTO_EMAIL","popUpTestoEmail",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getTestiEmail();
                }
            });
        },
        testEmail : function(testo){
            this.testoEmailSelezionato = testo;
            this.filters.openPopUp('#popUpEmail');
        },
        doTestEmail : function(){
            this.filters.closePopUp('#popUpEmail');
            utils.ajax("core/testiemail/test",{ID_TESTO_EMAIL:this.testoEmailSelezionato.ID_TESTO_EMAIL,EMAIL:this.emailTest}, (response) => {
                
            });
        },

        /// MENU

        getMenu: function () {
            utils.ajax("core/menu",{}, (response) => {
                this.arrayMenu = response.data;
            });
        },
        nuovoMenu:function(){
            this.menuSelezionato = utils.nuovoElemento(this.menuSelezionato,{ID_MENU:0,FLG_CORE:0,DESCRIZIONE:""},"popUpMenu");
        },
        nuovoMenuChildren:function(menu){
            this.menuSelezionato = utils.nuovoElemento(this.menuSelezionato,{FK_ID_MENU:menu.hasOwnProperty("ID_MENU") ? menu.ID_MENU : menu.FK_ID_MENU,
                                                                                DESCRIZIONE:"",
                                                                                ID_ITEM:0,
                                                                                ID_ITEM_PADRE:menu.hasOwnProperty("ID_MENU") ? 0 : menu.ID_ITEM},"popUpMenuChildren");
        },
        modificaMenu:function(menu){
            this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenu");
        },
        modificaMenuChildren:function(menu){
            if (menu.hasOwnProperty("ID_MENU")){
                this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenu");
            }
            else{
                this.menuSelezionato = utils.modificaElemento(this.menuSelezionato,menu,"popUpMenuChildren");
            }
        },
        salvaMenu:function(){
            utils.salvaElemento("core/menu/",this.menuSelezionato,"ID_MENU","popUpMenu",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getMenu();
                }
            });
        },
        salvaMenuChildren:function(){
            this.menuSelezionato.className = "MenuItem";
            utils.salvaElemento("core/basic/",this.menuSelezionato,"ID_ITEM","popUpMenuChildren",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    //this.getMenu();
                }
            });
        },
        deleteMenuChildren : function (menu){
            utils.alert.confirm("Sicuro di voler eliminare il menu e tutti i suoi figli?",() => {
                utils.ajax('core/basic/delete',{className:"MenuItem","id" : menu.ID_ITEM}, (response) => {
                    this.getMenu();
                });
            });
        },
        childrenMenu : function(menu){
            utils.ajax("core/menu/children",{ID_MENU:menu.ID_MENU}, (response) => {
                var arr = utils.clone(this.arrayMenu);
                for (var i = 0 ; i < arr.length ; i++){
                    if (arr[i].ID_MENU == menu.ID_MENU){
                        var item = utils.clone(arr[i]);
                        item.open = true;
                        item.children = response.data.items;
                        for (var j = 0 ; j < item.children.length ; j++){
                            item.children[j].children = item.children[j].items;
                            item.open = true;
                        }
                        arr[i] = item;
                        break;
                    }
                }
                this.arrayMenu = arr;
                this.$forceUpdate();
            });
        },
        

        //// GRUPPI TIPOLOGICHE

        getGruppiTipologiche: function () {
            utils.ajax("core/tipologiche/gruppi",{}, (response) => {
                this.arrayGruppiTipologiche = response.data;
            });
        },
        nuovoGruppoTipologiche:function(){
            this.gruppoTipologicaSelezionato = utils.nuovoElemento(this.gruppoTipologicaSelezionato,{ID_GRUPPO_TIPOLOGICA:0,DESCRIZIONE:"",FK_ID_LIVELLO:0,FK_ID_ENTE:1},"popUpGruppoTipologiche");
        },
        modificaGruppoTipologiche:function(gr){
            this.gruppoTipologicaSelezionato = utils.modificaElemento(this.gruppoTipologicaSelezionato,gr,"popUpGruppoTipologiche");
        },
        salvaGruppoTipologiche:function(){
            utils.salvaElemento("core/tipologiche/gruppi/",this.gruppoTipologicaSelezionato,"ID_GRUPPO_TIPOLOGICA","popUpGruppoTipologiche",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getGruppiTipologiche();
                }
            });
        },

        tipologicheGruppo : function(gruppo){
            this.gruppoTipologicaSelezionato = utils.clone(gruppo);
            utils.ajax("core/tipologiche",{FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA}, (response) => {
                this.arrayTipologiche = response.data;
                this.filters.openPopUp('#popUpTipologicheGruppo');
            });
        },


        ///// TIPOLOGICHE

        nuovaTipologica:function(){
            this.tipologicaSelezionata = utils.nuovoElemento(this.tipologicaSelezionata,{ID_TIPOLOGICA:0,DESCRIZIONE:"",VALORE:"",FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA},"popUpTipologica");
        },
        modificaTipologica:function(t){
            this.tipologicaSelezionata = utils.modificaElemento(this.tipologicaSelezionata,t,"popUpTipologica");
        },
        updateTipologica : function(t){
            this.tipologicaSelezionata = utils.clone(t);
            this.salvaTipologica('popUpTipologica');
        },
        deleteTipologica : function(t){
            utils.alert.confirm("Sei sicuro di voler eliminare la tipologica selezionata?",() => {
                utils.ajax('/core/tipologiche/delete',{ID_TIPOLOGICA:t.ID_TIPOLOGICA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax("core/tipologiche",{FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA}, (response) => {
                            this.arrayTipologiche = response.data;
                        });                        
                    }
                });
            });
        },
        salvaTipologica:function(popup = "popUpTipologica"){
            utils.salvaElemento("core/tipologiche/",this.tipologicaSelezionata,"ID_TIPOLOGICA",popup,(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax("core/tipologiche",{FK_ID_GRUPPO_TIPOLOGICA:this.gruppoTipologicaSelezionato.ID_GRUPPO_TIPOLOGICA}, (response) => {
                        this.arrayTipologiche = response.data;
                        console.log(this.arrayTipologiche);
                    });
                }
            });
        },


        //CUSTOM FORM
        getCustomForm: function () {
            utils.ajax("core/customform",{}, (response) => {
                this.arrayCustomForm = response.data;
            });
        },
        nuovoCustomForm:function(){
            this.customFormSelezionato = utils.nuovoElemento(this.customFormSelezionato,{ID_CUSTOM_FORM:0,DESCRIZIONE:""},"popUpCustomForm");
        },
        modificaCustomForm:function(menu){
            this.customFormSelezionato = utils.modificaElemento(this.customFormSelezionato,menu,"popUpCustomForm");
        },
        salvaCustomForm:function(){
            utils.salvaElemento("core/customform/",this.customFormSelezionato,"ID_CUSTOM_FORM","popUpCustomForm",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getCustomForm();
                }
            });
        },

        controlliForm : function(_form){
            this.customFormSelezionato = utils.clone(_form);
            this.filters.openPopUp('#popUpCustomFormElements');
            utils.ajax("core/customformelement",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM}, (response) => {
                this.arrayCustomFormElement = response.data;
            });
        },
        
        nuovoCustomFormElement:function(){
            this.customFormElementSelezionato = utils.nuovoElemento(this.customFormElementSelezionato,{ID_ELEMENT:0,CLASSE_CTRL:'input-sm',CLASSE:'col-md-',FK_ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM},"popUpCustomFormElement");
        },
        modificaCustomFormElement:function(t){
            this.customFormElementSelezionato = utils.modificaElemento(this.customFormElementSelezionato,t,"popUpCustomFormElement");
        },
        updateCustomFormElement : function(t){
            this.customFormElementSelezionato = utils.clone(t);
            this.salvaCustomFormElement('');
        },
        deleteCustomFormElement : function(t){
            utils.alert.confirm("Sei sicuro di voler eliminare l'elemento selezionato?",() => {
                utils.ajax('/core/customformelement/delete',{ID_ELEMENT:t.ID_ELEMENT}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        utils.ajax("core/customformelement",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM}, (response) => {
                            this.arrayCustomFormElement = response.data;
                        });                       
                    }
                });
            });
        },
        salvaCustomFormElement:function(popup = "popUpCustomFormElement"){
            utils.salvaElemento("core/customformelement/",this.customFormElementSelezionato,"ID_ELEMENT",popup,(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax("core/customformelement",{ID_CUSTOM_FORM:this.customFormSelezionato.ID_CUSTOM_FORM}, (response) => {
                        this.arrayCustomFormElement = response.data;
                    });
                }
            });
        },
        esportaSelezionati : function(){
            var arr = utils.array.select(this.arrayCustomForm,"SELEZIONATO",1);
            utils.ajax("core/customform/esporta",{arr:arr}, (response) => {
                $key = response.data.key;
                window.open("CORE/SERVER/API/core/report?ID_REPORT=1&KEY="+$key);
            });
        },
        installaCustomForm : function(){
            this.filters.openPopUp('#popInstallazioneCustomForm');
        },
        chiudiPopUpInstallazioneCustomForm : function(){
            this.filters.openPopUp('#popInstallazioneCustomForm');
        },
        getOpzioni : function(){
            utils.ajax('core/admin/opzioni',{}, (response) => {
                var arrayOpzioni = response.data;
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                        this.arrayOpzioniConfigurazione[i].opzioni[j].CLASSE_CTRL = 'input-sm';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].VMODEL = 'VALORE';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = 0;
                        for (var k = arrayOpzioni.length-1 ; k > -1  ; --k){
                            if (this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE_BASE == arrayOpzioni[k].ID_OPZIONE_BASE){
                                this.arrayOpzioniConfigurazione[i].opzioni[j].VALORE = arrayOpzioni[k].VALORE;
                                this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = arrayOpzioni[k].ID_OPZIONE;
                                arrayOpzioni.splice(k,1);
                                break;
                            }
                        }
                    }
                }
                //AGGIUNGO LE OPZIONI CUSTOM DEL SITO
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    if (this.arrayOpzioniConfigurazione[i].id == 100){
                        for (var j = 0 ; j < arrayOpzioni.length ; j++){
                            this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:arrayOpzioni[j].LABEL,VALORE:arrayOpzioni[j].VALORE,TYPE:'TEXTAREA',ID_OPZIONE:arrayOpzioni[j].ID_OPZIONE});
                        }
                        this.$forceUpdate();
                        break;
                    }
                }
            });
        },
        nuovaOpzione : function(){
            this.opzioneSelezionata = {};
            this.filters.openPopUp('#popUpNuovaOpzione');
        },
        salvaOpzioneSito : function(){
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                if (this.arrayOpzioniConfigurazione[i].id == 100){
                    var opzione = utils.clone(this.opzioneSelezionata);
                    this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:opzione.LABEL,VALORE:opzione.VALORE,TYPE:'TEXT'});
                    this.$forceUpdate();
                    this.filters.closePopUp('#popUpNuovaOpzione');
                    break;
                }
            }
        },
        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                    arr.push(this.arrayOpzioniConfigurazione[i].opzioni[j]);
                }
            }
            utils.ajax('core/admin/opzioni/update',{opzioni:arr}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        },
        test:function(){
            utils.ajax('core/test',{DESCRIZIONE:"PROVA"}, (response) => {
                
            });
        },
        test2:function(){
            utils.report.token({modalita:'esportaUtenti',formato:1});
        },
        backup : function(){
            utils.ajax('core/admin/backup',{}, (response) => {
                
            });
            
        },
        buildClass:function(){
            utils.ajax('core/new_class',{tabella:this.tabella}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        }

    },
    computed: {
        uploadDisabled() {
            return this.files.length === 0;
          },
        paginatedListFunzionalita(){
            var arr = utils.inGrid(this.arrayFunzionalita,this.searchFunzionalita);
            return arr;
        },
        paginatedListGruppi(){
            var arr = utils.inGrid(this.arrayGruppi,this.searchGruppi);
            return arr;
        },
        paginatedListLivelli(){
            var arr = utils.inGrid(this.arrayLivelli,this.searchLivelli);
            return arr;
        },
        paginatedLisFunzGruppo(){
            var arr = utils.inGrid(this.arrayFunzionalitaGruppo,this.searchFunzGruppo);
            return arr;
        },
        paginatedListGruppiTipologiche(){
            var arr = utils.inGrid(this.arrayGruppiTipologiche,this.searchGruppiTipologiche);
            return arr;
        },
        paginatedListTipologiche(){
            var arr = utils.inGrid(this.arrayTipologiche,this.searchTipologiche);
            return arr;
        },
        paginatedListMenu(){
            var arr = utils.inGrid(this.arrayMenu,this.searchMenu);
            return arr;
        },
        paginatedListMenuChildren (){
            var arr = utils.inGrid(this.arrayMenuChildren,this.searchMenuChidren);
            return arr;
        },
        paginatedListCustomForm(){
            var arr = utils.inGrid(this.arrayCustomForm,this.searchCustomForm);
            return arr;
        },
        paginatedListTestiEmail(){
            var arr = utils.inGrid(this.arrayTestiEmail,this.searchEmail);
            return arr;
        }
    },
    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },
    created: function () {
        this.selectedMenuItem = this.$root.selectedMenuItem;
        setTimeout(() => {
            $('a[data-bs-toggle="tab"]').on('shown.bs.tab', (e) => {
                var tab = $(e.target).data("tab");
                switch(tab){
                    case 'funzionalita':
                        this.getFunzionalita();
                        break;
                    case 'gruppi':
                        this.getGruppi();
                        break;
                    case 'livelli':
                        this.getLivelli();
                        break;
                    case 'menu':
                        this.getMenu();
                        break;
                    case 'gruppiTipologiche':
                        this.getGruppiTipologiche();
                        break;
                    case 'tipologiche':
                        this.getTipologiche();
                        break;
                    case 'customForm':
                        this.getCustomForm();
                        break;
                    case 'configurazione':
                        this.getOpzioni();
                        break;
                    case 'email':
                        this.getTestiEmail();
                        break;
                }
            });            
        },300);
        //
        utils.ajax('core/basic/element',{"className":"CustomForm","id":1}, (response) => {
            this.arrayCampiMenuChildren = response.data.elements;
            utils.ajax('core/menu/children/linear',{}, (response) => {
                this.linearMenuItems = response.data;
                //this.getFunzionalita(); 
                this.getOpzioni();
            }); 
        });
    },
}
</script>