<template>
  	<div>

    	<multiselect 
					v-model="OPZIONE_SELEZIONATA" 
					:track-by="'id'" 
					:value="'Integer'"
					:placeholder="placeholder" 
					open-direction="bottom" 
					:options="options" 
					@select="formattaInUscita"
					:multiple="false" 
					:searchable="true" 
					:loading="isLoading" 
					:internal-search="false" 
					label="text" 
					:clear-on-select="false" 
					:close-on-select="true" 
					:options-limit="300" 
					:limit="3" 
					:max-height="600" 
					:show-no-results="false" 
					:hide-selected="true" 
					@search-change="asyncFind"
					:selectLabel="''"
					>
					<!-- 
					<div class="multiselect__clear" v-if="selectedCountries.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
					-->
					<template v-slot:clear >
						<div class="multiselect__clear" v-if=" this.currentId > 0" @mousedown.prevent.stop="clearAll()"></div>
					</template>
					<template v-slot:noOptions >Scrivi min. 3 caratteri</template>

					
		</multiselect>
  	</div>
</template>


<script>
import { computed,ref } from 'vue'
import Multiselect from 'vue-multiselect'
import { utils } from '../../assets/js/utils';


export default {
	name: 'Select2',

	components:{
		Multiselect
	},

	data() {
		return {
			opzioni:new Array(),
			isLoading:false,
			options:new Array(),
			currentId:0,
			isWriting : false,
			currentQuery:""
		};
	},

  	emits: ['update:modelValue','selectedItem'],

  	setup(props, { emit,ref,getCurrentInstance,context }) {
	
		const CURRENT_TIME = new Date().getTime();
		const OPZIONE_SELEZIONATA = computed({          	
			get: () => {
				var t = props.modelValue;
				if (t == undefined || t == null){
					return {id: 0,text:'Selezionare un elemento'};
				}
				var underscore = (t.toString().search("_") > -1);
				if (isNaN(t) && underscore == false){
					return {id: 0,text:'Selezionare un elemento'};
				}		
				else{
					if (t != undefined && underscore == false && t > 0){
						console.log(" B ");
						var arr = utils.appoggio['select2_'+CURRENT_TIME];
						//SE HO IN CASA UN array valorizzato correttamente, valorizzo la input
						if (arr != undefined && arr.length > 0){
							var item = utils.array.select(arr,"id",t,1)[0];
							if (item != undefined){
								return {id: t,text:item.text};
							}     
							else{
								utils.ajax(props.apiselected,{q:t}, (response) => {
									utils.appoggio['select2_'+CURRENT_TIME] = new Array(response.data);
									emit('update:modelValue',"_"+t);
								},false);
								return {id: 0,text:'-'};
							}         							
						}
						else{
							//SE NON HO UN array VALORIZZATO, E HO LE API PER FARE UNA CHIAMATA, PROVO A FARE LA CHIAMATA
							if (props.apiselected != null && props.apiselected != ''){
								utils.ajax(props.apiselected,{q:t}, (response) => {
									utils.appoggio['select2_'+CURRENT_TIME] = new Array(response.data);
									emit('update:modelValue',"_"+t);
								},false);								
								return {id: 0,text:'Loading'};
							}
							else{
								return {id: 0,text:'Selezionare un elemento'};
							}
						}						
					}
					//SE E' STATO VALORIZZATO IN PRECDENZA E E' STATA FATTA LA CHIAMATA PER RECUPERARE L'ELEMENTO
					else if (t != undefined && underscore == true){
						console.log(" A ");
						var arr = utils.appoggio['select2_'+CURRENT_TIME];
						emit('update:modelValue',t.replace("_",""));
						/*
						var item = utils.array.select(arr,"id",t,1)[0];
						if (item != undefined){
							return {id: t,text:item.text};
						}    */
						return {id: 0,text:'Loading'};          
					}
					else{
						return {id: 0,text: props.placeholder};
					}
				}	
			},
			set : (newVal) => {
				if (newVal == null || newVal.hasOwnProperty("id") == false){
					emit('selectedItem',newVal);
					return newVal;
				}
				console.log("setter => " + newVal.id);
				emit('selectedItem',newVal.id);
				return newVal;
			}
		});
		return { OPZIONE_SELEZIONATA, CURRENT_TIME };
  	},

	props: {
		modelValue: [String, Array, Number, Object], // previously was `value: String`
		placeholder: {
			type: String,
			default: ''
		},
		api : {
			type : String,
			default:null
		},
		apiselected:{
			type : String,
			default : null
		},    
	},

	watch: {    
		modelValue: {
			handler(val) {
				this.formattaInUscita(val);
			},
			deep: true
		},
	},

  	methods: {
		asyncFind (query) {
			this.options = new Array();
			if (query.length > 2){
				this.currentQuery = query;
				utils.wait(()=>{
					if (this.currentQuery == query) {
						this.doFind();
					}
				},500);			
			}
		},
		doFind(){
			this.isLoading = true;
			utils.ajax(this.api,{q:this.currentQuery}, (response) => {
				this.options = response.data;
				utils.appoggio['select2_'+this.CURRENT_TIME] = response.data;
				this.isLoading = false;
			},false);
		},
		formattaInUscita(selectedOption,id){
			if (selectedOption != undefined){
				if (selectedOption.hasOwnProperty("id")){
					this.$emit('update:modelValue',selectedOption.id);
					this.currentId = selectedOption.id
				}
				else{
					if (selectedOption > 0){
						this.currentId = selectedOption;
						this.$emit('update:modelValue',selectedOption);
					}          
				}
			}
    	},
		clearAll:function(){
			this.selectedOption = {id:0,text:''}	;
			this.currentId = 0;
			this.formattaInUscita(this.selectedOption,0);
		},
		
		setOption(val = []) {      
			this.setValue(this.modelValue);
		},

    	setValue(val) {
			if (val instanceof Array) {
				/*
				this.select2.val([...val]);
				this.select2.trigger('change');
				*/
			} 
        	else {
				if (this.apiselected != null && val > 0){
					global.utils.ajax(this.apiselected,{q:val}, (response) => {
						if (response.hasOwnProperty('data')){
							$(this.select2).select2("trigger", "select", {
								data: { id: val,text:response.data.text }
							});   
							this.$forceUpdate();                         
						}
					},false);
				}
				else{
					/*
					this.select2.val([val]);
					this.select2.trigger('change');
					*/
				}
      		}
    	},

		onSelect : function(){
			console.log("alerting");
			//this.changeHandler();
		}

	},

  	mounted() {
		this.GET_OPZIONI = () => {
			return this.options;
		}
    	return;
		var opz = {};
		if (this.api != null){
			opz.ajax = {
				url: () => {
					return global.utils.BASE_API + this.api + "?SESSION_TOKEN="+global.utils.SESSION_TOKEN;
				},
				minimumInputLength:2,
				quietMillis: 700,
				dataType: 'json',
				cache: false,
				method:'POST',
				processResults:  (data,params) => {
					this.items = data.data;
					return {results:data.data};
				}
			}
		};
		let mergedSettings = {...this.settings, ...opz};
		mergedSettings.multiple = this.multiple;
		mergedSettings.allowClear = true;
		mergedSettings.minimumInputLength = 2;
		this.select2 = global.$(this.$el)
		.find('select')
		.select2({
			placeholder: this.placeholder,
			...mergedSettings,
			data: this.options
		})
		.on('select2:select select2:unselect', ev => {
		// console.log("selezionato");
			this.$emit('update:modelValue', this.select2.val());
			this.onSelect();
			//this.$emit('select', ev['params']['data']);
		});
		this.setValue(this.value);
	},

	beforeUnmount() {
		return;
		this.select2.select2('destroy');
	},
};
</script>