<template>

    <div class="modal fade" :id="'popUpContenuto'+rs" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-xl"> 
            <div class="modal-content" >
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;"> Scheda: {{contenutoLangSelezionato.TITOLO}}</h6>                           
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body p-2" style="height: 32rem;">
              
                    <div class="d-lg-flex align-items-lg-start">
                        <div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none me-lg-3">
                            <div class="sidebar-content">
                                <div class="card sidebar-section-body text-center p-2">
                                    <div class="card-img-actions d-inline-block">
                                        <img v-if="contenuto.ID_CONTENUTO > 0 && contenuto.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('CONTENUTO',contenuto.URL_AVATAR)" class="img-fluid squared-circle rounded-0" alt="" width="215" height="150" />
                                        <span v-if="contenuto.URL_AVATAR == 'placeholder.jpg' || contenuto.ID_CONTENUTO == 0">
                                            <img src="../assets/images/placeholders/placeholder.jpg" class="img-fluid rounded-circle" width="150" height="150" alt="">
                                        </span>
                                        <div class="card-img-actions-overlay card-img rounded-circle">
                                            <a href="#" class="btn btn-outline-white btn-icon rounded-pill" v-on:click="uploadImmagineContenuto()">
                                                <i class="ph-pencil"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-sidebar" role="tablist">
                                    <li v-if="modalita == 1" class="nav-item" role="presentation">
                                        <a href="#cont-main" id="cont-main-tab" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
		                                    <i class="fal fa-info-square fa-fw"></i> Info Contenuto
		                                </a> 
                                    </li>
                                    <li v-if="modalita == 2" class="nav-item" role="presentation">
                                        <a href="#cont-main-cat" id="cont-main-cat-tab" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
		                                    <i class="fal fa-info-square fa-fw"></i> Info Categoria
		                                </a> 
                                    </li>
                                    <li v-show="contenuto.ID_CONTENUTO > 0" class="nav-item" role="presentation">
                                        <a href="#cont-testi" id="cont-testi-tab" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">
		                                    <i class="fal fa-text fa-fw"></i> Gestione Contenuti 
		                                </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a href="#cont-seo" id="cont-seo-tab" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab">
		                                    <i class="fal fa-globe fa-fw"></i> Configurazioni Seo 
		                                </a>
                                    </li>
	                            </ul>
                        	</div>
						</div>

                        <div class="tab-content flex-fill">

                            <div v-if="modalita == 1" class="tab-pane fade active show" id="cont-main" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-info-square fa-fw me-1"></i> Info Generali</h5>
			                    <div class="box_26"> 

                                    <div class="row mt-1">
                                        <div class="col-6 col-md-3 mb-2">
                                            <label class="form-label text-danger mb-1">Modello Contenuto</label>	
                                            <span class="float-end cursor-pointer" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Modello Contenuto"><i class="far fa-info-circle text-primary"></i></span>
                                            <select class="form-select form-select-sm" v-model="contenuto.FK_ID_MODELLO">
                                                <option v-bind:value="0">Tutti</option>
                                                <option v-for="model in $root.arrayTipoContenuto" v-bind:key="model.ID_OPZIONE_TIPOLOGICA"  v-bind:value="model.ID_OPZIONE_TIPOLOGICA" >{{model.DESCRIZIONE}}</option>
                                            </select>  
                                        </div>
                                        <div class="col-6 col-md-9 mb-2">
                                            <label class="form-label text-danger mb-1">Titolo:</label>
                                            <div class="input-group">
                                                <span class="input-group-text"><i class="far fa-tag fa-fw"></i></span>
                                                <input autocomplete="off" type="text" class="form-control form-control-sm valid" placeholder="Descrizione" v-model="contenutoLangSelezionato.TITOLO" :tabindex="4">
                                            </div>
                                        </div>
									</div>

                                    <hr class="hr-1" />

                                    <div class="row mt-2">	
                                        <div class="col-12 col-md-6">

                                            <div class="row">
		                                        <div class="col-12 col-md-12 mb-2"> 
		                                            <label class="form-label text-danger mb-1">Slogan:</label>
		                                            <div class="input-group">
                                                        <span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
		                                                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="contenutoLangSelezionato.SOTTOTITOLO" :tabindex="4">
		                                            </div>
		                                        </div>
                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Visibile nella Categoria</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Tematiche associate" data-bs-original-title="Popover title"><i class="far fa-info-circle text-primary"></i></span>
                                                    <Multiple v-bind:options="filters.toSelect2(arrayCategorie,'ID_CATEGORIA','DESCRIZIONE')" v-model="contenuto.categorie"></Multiple>
		                                        </div>	 
                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Pubblica sul Sito</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="15">
                                                    	<span class="input-group-text cursor-pointer" v-on:click="contenuto.enti = filters.select2AddAll(contenuto.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span>
                                                        <Multiple class="w-88" v-bind:options="filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL')" v-model="contenuto.enti"></Multiple>
                                                    </div>
                                                </div>  
                                    		</div>

                                		</div>
                                        <div class="col-12 col-md-6" style="border-left: 1px solid #ddd;">
                                            
                                            <div class="row">
                                                <div class="col-12 col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Custom 1</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 1" v-model="contenuto.CUSTOM_1">
                                                </div>
                                                <div class="col-12 col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Custom 2</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>													
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 2" v-model="contenuto.CUSTOM_2">
                                                </div>

                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">In Evidenza  </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-contselectrol-sm" v-model="contenuto.FLG_INHOME" :tabindex="27">
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-bind:value="1"> &nbsp; Si</option>        
                                                        <option v-bind:value="0"> &nbsp; No</option>        
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">Counter</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 2" v-model="contenuto.COUNTER">
                                                </div>

                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">Visibile </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="contenuto.FLG_VISIBILE" :tabindex="27">
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-bind:value="1"> &nbsp; Pubblico</option>        
                                                        <option v-bind:value="0"> &nbsp; Admin</option>        
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">Stato </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="contenuto.lang.FLG_ATTIVO">  
                                                        <option value="1"> &nbsp; Attivata</option>
                                                        <option value="0"> &nbsp; Disattivata</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div v-if="modalita == 2" class="tab-pane fade active show" id="cont-main-cat" role="tabpanel">                                
                                <h5 class="label-bar"><i class="fad fa-info-square fa-fw me-1"></i> Info Generali</h5>
                                <div class="box_26">
                                
                                    <div class="row mt-1">
                                        <div class="col-6 col-md-3">
                                            <label class="form-label text-danger mb-1"> Modello Contenuto </label>
                                            <span class="float-end cursor-pointer" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Modello Contenuto"><i class="far fa-info-circle text-primary"></i></span>
                                            <select class="form-select form-select-sm" v-model="contenuto.FK_ID_MODELLO">
                                                <option v-bind:value="0">Tutti</option>
                                                <option v-for="model in $root.arrayTipoContenuto" v-bind:key="model.ID_OPZIONE_TIPOLOGICA"  v-bind:value="model.ID_OPZIONE_TIPOLOGICA" >{{model.DESCRIZIONE}}</option>
                                            </select> 
                                        </div>
                                        <div class="col-6 col-md-9 mb-2">
                                            <label class="form-label text-danger mb-1">Titolo:</label>
                                            <div class="input-group">
												<span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
												<input autocomplete="off" type="text" class="form-control form-control-sm valid" placeholder="Descrizione" v-model="categoriaSelezionata.DESCRIZIONE" :tabindex="4">
                                            </div> 
                                        </div>
									</div>

                                    <hr class="hr-1" />

                                    <div class="row mt-2">	
                                        <div class="col-12 col-md-6">

                                            <div class="row">
		                                        <div class="col-12 col-md-12 mb-2"> 
                                                    <label class="form-label text-danger mb-1">Slogan:</label>
                                                    <div class="input-group">
                                                        <span class="input-group-text"><i class="far fa-user fa-fw"></i></span>
		                                                <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="contenutoLangSelezionato.SOTTOTITOLO" :tabindex="4">
                                                    </div> 
		                                        </div>
                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Visibile nel Modulo</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Tematiche associate"><i class="far fa-info-circle text-primary"></i></span>
                                                    <Multiple v-bind:options="filters.toSelect2(arrayModuli,'ID_OPZIONE_TIPOLOGICA','DESCRIZIONE')" v-model="categoriaSelezionata.moduli"></Multiple>
                                                </div>
                                                <div class="col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Pubblica sul Sito</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <div class="input-group input-group-sm" :tabindex="15">
                                                        <span class="input-group-text cursor-pointer" v-on:click="categoriaSelezionata.enti = filters.select2AddAll(categoriaSelezionata.enti,filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL'))">Tutti</span>
                                                        <Multiple class="w-88" v-bind:options="filters.toSelect2($root.arrayEnti,'ID_ENTE','LABEL')" v-model="categoriaSelezionata.enti"></Multiple>
                                                    </div>
                                                </div>  
                                            </div>

                                        </div>  
                                        <div class="col-12 col-md-6" style="border-left: 1px solid #ddd;">
                                            
                                            <div class="row">
                                                <div class="col-12 col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Custom 1</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 1" v-model="categoriaSelezionata.CUSTOM_1">
                                                </div>
                                                <div class="col-12 col-md-12 mb-2">
                                                    <label class="form-label text-danger mb-1">Custom 2</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>													
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 2" v-model="categoriaSelezionata.CUSTOM_2">
                                                </div>

                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">In Evidenza  </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-contselectrol-sm" v-model="categoriaSelezionata.FLG_INHOME" :tabindex="27">
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-bind:value="1"> &nbsp; Si</option>        
                                                        <option v-bind:value="0"> &nbsp; No</option>        
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">Counter</label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <input autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Custom 2" v-model="categoriaSelezionata.COUNTER">
                                                </div>

                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">Visibile </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" v-model="categoriaSelezionata.FLG_VISIBILE" :tabindex="27">
                                                        <option selected="selected">Seleziona</option>
                                                        <option v-bind:value="1"> &nbsp; Pubblico</option>        
                                                        <option v-bind:value="0"> &nbsp; Admin</option>        
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <label class="text-danger custom-control custom-control-right">Stato </label>
                                                    <span class="float-end" data-bs-popup="popover" data-bs-placement="left" data-bs-content="Pubbica su quale sito"><i class="far fa-info-circle text-primary"></i></span>
                                                    <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="categoriaSelezionata.FLG_ATTIVO">  
                                                        <option value="1"> &nbsp; Attivata</option>
                                                        <option value="0"> &nbsp; Disattivata</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

			                <div v-show="contenuto.ID_CONTENUTO > 0" class="tab-pane fade" id="cont-testi" role="tabpanel">  
                                <h5 class="label-bar"><i class="fad fa-text fa-fw"></i> Gestione Contenuti</h5>      
                                <ul class="nav nav-tabs nav-tabs-highlight mb-0">
                                    <li class="nav-item"><a href="#bordered-TESTO_HOME" class="nav-link active" data-bs-toggle="tab">{{ modulo != 38 ? 'Intro' : 'Risposta' }}</a></li>
                                    <li v-if="modulo != 38" class="nav-item"><a href="#bordered-TESTO_BODY" class="nav-link" data-bs-toggle="tab">Descrizione</a></li>
                                    <li v-if="modulo != 38" class="nav-item"><a href="#bordered-TESTO_INFO" class="nav-link" data-bs-toggle="tab">Info Utili</a></li>
                                    <li v-if="modulo != 38" class="nav-item"><a href="#bordered-TESTO_DOCUMENTI" class="nav-link" data-bs-toggle="tab">Documenti</a></li>
                                    <li v-if="modulo != 38" class="nav-item"><a href="#bordered-TESTO_NOTE" class="nav-link" data-bs-toggle="tab">Note</a></li>
                                    <li v-if="modulo != 38" class="nav-item"><a href="#bordered-TESTO_ADMIN" class="nav-link" data-bs-toggle="tab">Note Admin</a></li>
			                    </ul>
                                <div class="tab-content border-top-0 rounded-top-0 mb-0">

                                    <div class="tab-pane fade show active me-2" id="bordered-TESTO_HOME">
                                        <div class="alert alert-info alert-2 border-0 alert-dismissible fade show mt-1 mb-1">
                                            lunghezza consigliata nessun limite.<br />
                                            Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                        </div>
                                        <ckeditor :editor="editor" v-model="contenutoLangSelezionato.TESTO_HOME" :config="editorConfig"></ckeditor>
                                    </div>

                                    <div class="tab-pane fade me-2" id="bordered-TESTO_BODY">
                                        <div class="alert alert-info alert-2 border-0 alert-dismissible fade show mt-1 mb-1">
                                            lunghezza consigliata nessun limite.<br />
                                            Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                        </div>
                                        <ckeditor :editor="editor" v-model="contenutoLangSelezionato.TESTO_BODY" :config="editorConfig"></ckeditor>
                                    </div>

                                    <div class="tab-pane fade me-2" id="bordered-TESTO_INFO">
                                        <div class="alert alert-info alert-2 border-0 alert-dismissible fade show mt-1 mb-1">
                                            lunghezza consigliata nessun limite.<br />
                                            Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                        </div>
                                        <ckeditor :editor="editor" v-model="contenutoLangSelezionato.TESTO_INFO" :config="editorConfig"></ckeditor>
                                    </div>

                                    <div class="tab-pane fade me-2" id="bordered-TESTO_DOCUMENTI">
                                        <div class="alert alert-info alert-2 border-0 alert-dismissible fade show mt-1 mb-1">
                                            lunghezza consigliata nessun limite.<br />
                                            Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                        </div>
                                        <ckeditor :editor="editor" v-model="contenutoLangSelezionato.TESTO_DOCUMENTI" :config="editorConfig"></ckeditor>
                                    </div>

                                    <div class="tab-pane fade me-2" id="bordered-TESTO_NOTE">
                                        <div class="alert alert-info alert-2 border-0 alert-dismissible fade show mt-1 mb-1">
                                            lunghezza consigliata nessun limite.<br />
                                            Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                        </div>
                                        <ckeditor :editor="editor" v-model="contenutoLangSelezionato.TESTO_NOTE" :config="editorConfig"></ckeditor>
                                    </div>

                                    <div class="tab-pane fade me-2" id="bordered-TESTO_ADMIN">
                                        <div class="alert alert-info alert-2 border-0 alert-dismissible fade show mt-1 mb-1">
                                            lunghezza consigliata nessun limite.<br />
                                            Questo testo viene posizionato nel dettaglio del prodotto nel Tab nominato "Info".
                                            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                                        </div>
                                        <ckeditor :editor="editor" v-model="contenutoLangSelezionato.TESTO_ADMIN" :config="editorConfig"></ckeditor>
                                    </div>   
                                    
			                    </div> 
					   	    </div>
                            
                            <div class="tab-pane fade" id="cont-seo" role="tabpanel">
                                <h5 class="label-bar"><i class="fad fa-globe fa-fw me-1"></i> Configurazioni Seo</h5>
                                <div class="box_26">
                                    
                                    <Seo v-model="contenutoLangSelezionato"></Seo>
                                            
                                </div>
                            </div>

					    </div>	 
                    </div>

                </div> 
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaContenuto()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                  
                </div>
            </div>
        </div>
    </div>
				   

    <div class="modal fade" :id="'popUpUploadImgContenuto'+rs" role="dialog"  aria-modal="true" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Profilo</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsContenuto"  @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                 
                </div>
            </div>
        </div>
    </div>
    		  

</template>



<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Upload from '@/components/utils/Upload.vue'
import Seo from '@/components/utils/Seo.vue'
import Multiple from '@/components/utils/Multiple.vue'


export default {
    name:"SchedaContenuto",
    emits:["updateGriglia"],
    props:["modulo","showPrincipale","modalita"],
    components:{
        Upload,
        Seo,
        Multiple
    },
    data:function(){
        return {
            filters:global.filters,	 
            contenuto:{lang:{}},
            categoriaSelezionata:{enti:new Array()},
            idLinguaSelezionata:0,
            contenutoLangSelezionato:{},
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            arrayCategorie : new Array(),
            arrayModuli : new Array(),
			rs : utils.generateRandomString(30),
            argsContenuto:{
                modalita:'MOONLIGHT_uploadImgContenuto',
            }, 

        }
    },

    methods: {
        startup : function(item){          
            this.filters.openPopUp('popUpContenuto'+this.rs);
			global.filters.popover();
            if (this.modalita == 1){
                document.getElementById("cont-main-tab").click();
                if (item.ID_CONTENUTO > 0){                
                    utils.ajax("travel/contenuto",{ID_CONTENUTO:item.ID_CONTENUTO}, (response) => {
                        if (response.esito == 0){
                            this.contenuto = response.data;
                            this.selezionaLang();
                        }                       
                    });            
                }     
                else{
                    this.contenuto = utils.clone(item);
                    this.contenutoLangSelezionato = {};
                }   
            } 
            else{
                document.getElementById("cont-main-cat-tab").click(); 
                if (item.ID_CATEGORIA > 0){
                    utils.ajax('travel/categoria',item, (response) => {
                        this.categoriaSelezionata = utils.clone(response.data);
                        utils.ajax("travel/contenuto",{ID_CONTENUTO:this.categoriaSelezionata.FK_ID_CONTENUTO}, (response) => {
                            if (response.esito == 0){
                                this.contenuto = response.data;
                                this.selezionaLang();
                            }
                        });    
                    }); 
                }
                else{
                    this.categoriaSelezionata = utils.clone(item);
                    this.contenuto = {ID_CONTENUTO:0,FK_ID_MODULO:0};
                }
            }                    
            utils.wait(()=>{
                /*
                $('#cont-testi').removeClass("show");                        
                $('#cont-testi').removeClass("active");
                $('#cont-testi-tab').removeClass("active");
                if (this.modalita == 1){
                    $('#cont-main').addClass("show active");
                    $('#cont-main-tab').addClass("active");
                }
                else{
                    $('#cont-main-categorie').addClass("show active");
                    $('#cont-main-tab-categorie').addClass("active");
                }    */            
            });             
        },	

        selezionaLang : function(){
            for (var i = 0 ; i < this.contenuto.langs.length ; i++){
                if (this.contenuto.langs[i].FK_ID_LINGUA == this.idLinguaSelezionata){
                    this.contenutoLangSelezionato = this.contenuto.langs[i];
                    break;
                }
            }
        }, 

        salvaContenuto(){
            if (this.modalita == 2){
                var api = this.categoriaSelezionata.ID_CATEGORIA > 0 ? "travel/categoria/update" : "travel/categoria/insert";
                utils.ajax(api,this.categoriaSelezionata, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        if (this.categoriaSelezionata.ID_CATEGORIA == 0){
                            this.categoriaSelezionata.ID_CATEGORIA = response.id_inserito;
                            utils.ajax('travel/categoria',this.categoriaSelezionata, (response) => {       
                                this.categoriaSelezionata = response.data;
                                this.contenuto = utils.clone(this.categoriaSelezionata.contenuto);
                                this.selezionaLang();
                                this.salvaContenutoLang();
                            }); 
                        }
                        else{
                            this.salvaContenutoLang();
                        }
                    }
                });
            }     
            else{
                this.salvaContenutoLang();
            }                            
        },	 

        salvaContenutoLang(){
            if (this.contenuto.ID_CONTENUTO == 0){
                utils.ajax("travel/contenuto/insert",this.contenuto, (response) => {
                    if (response.esito == 0){
                        console.log("*****");
                        utils.alert.success(response.messaggio);
                        this.contenuto.ID_CONTENUTO = response.id_inserito;
                        this.contenutoLangSelezionato.FK_ID_CONTENUTO = response.id_inserito;
                        this.contenutoLangSelezionato.categorie = utils.clone(this.contenuto.categorie);
                        utils.ajax("travel/contenuto",{ID_CONTENUTO:this.contenuto.ID_CONTENUTO}, (response) => {
                            this.contenuto.langs = response.data.langs;
                            if (response.esito == 0){                                
                                for (var i = 0 ; i < this.contenuto.langs.length ; i++){
                                    if (this.contenuto.langs[i].FK_ID_LINGUA == this.idLinguaSelezionata){
                                        this.contenutoLangSelezionato.ID_CONTENUTO_LANG = this.contenuto.langs[i].ID_CONTENUTO_LANG;
                                        utils.ajax("travel/contenutolang/update",this.contenutoLangSelezionato, (response) => {
                                            if (response.esito == 0){
                                                utils.alert.success(response.messaggio)                                        ;
                                            }
                                        }); 
                                        this.$emit("updateGriglia");
                                        break;
                                    }
                                }
                            }
                        });                        
                    }
                });     
            }
            else{
                this.contenutoLangSelezionato.categorie = utils.clone(this.contenuto.categorie);
                utils.ajax("travel/contenutolang/update",this.contenutoLangSelezionato, (response) => {
                    utils.ajax("travel/contenuto/update",this.contenuto,(response)=>{
                        if (response.esito == 0){
                            utils.alert.success(response.messaggio);
                            this.$emit("updateGriglia");
                        }
                    });
                }); 
            }
        }, 	

        uploadImmagineContenuto : function(){
            this.filters.openPopUp("popUpUploadImgContenuto"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgContenuto"+this.rs);
            utils.ajax("travel/contenuto/detail",{ID_CONTENUTO_LANG:this.contenutoLangSelezionato.ID_CONTENUTO_LANG}, (response) => {
				utils.alert.success(response.messaggio);
                this.azienda = response.data;
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgContenuto"+this.rs);
        },
    
    },

    created : function(){
        this.arrayModuli = this.$root.arrayTipoModuli;
        utils.ajax("travel/categorie/modulo",{FK_ID_MODULO:this.modulo}, (response) => {
            this.arrayCategorie = response.data;
            this.azienda = response.data;
            this.idLinguaSelezionata = this.$root.arrayLingue[0].ID_GEO_STATO;
        },false);
    }
}
</script>

<style>
    .ck-editor__editable {
        height: 17.43rem !important;
    }
</style>