<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                    <a href="#" class="text-dark fw-semibold"> Tessere </a> \ 
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Stagioni</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaSeason()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1">Nuova Stagione</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(48)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div> 
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#seasons-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-list"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Stagioni</span>
    						</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#seasons-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-shield-plus"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Assicurazioni</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#seasons-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-credit-card"></i>
								<span class="d-none d-lg-inline-block ms-2">Gestione GoSkipass</span>
							</div>
						</a>
					</li>
				</ul>  


				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-2"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i> Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i> Activity log
								</a>
								<a href="#" class="dropdown-item" v-on:click="deleteSeason()">
									<i class="ph-trash me-2"></i> Elimina
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getSeasons()" v-model="search.ANNO.VALUE" :tabindex="4">
								<option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
							</select>	
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="seasons-a">
				<div class="card shadow mb-0">
					<div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="season-a">
									<div class="row ">
										<div class="col-4 col-md-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id stagione" :tabindex="1" />
										</div> 
										<div class="col-8 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" :tabindex="1"  />
										</div> 	
										<div class="col-md-2">
		
										</div>              
										<div class="d-none d-sm-block col-md-3">
		
										</div>	
									  	<div class="col-8 col-md-1">
										</div>
										<div class="col-12 col-md-3">
											<div class="row">
												<div class="col-8 col-md-3">
												</div> 
												<div class="col-6 col-md-5">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Stato </option>   
														<option value="1"> &nbsp; Attivate</option>
														<option value="0"> &nbsp; Disattivate</option>
													</select>
												</div>
												<div class="col-6 col-md-4">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>  
											</div>
										</div>  
									</div>
									<div class="collapse" id="collapse-a" style="">
										<div class="row">	
											<div class="col-6 col-md-2">
		
		
		
											</div>  
											<div class="col-6 col-md-2">
		
		
		
											</div>
											<div class="col-6 col-md-2">
		
		
		
											</div>
											<div class="col-6 col-md-2">
		
		
		
											</div>
											<div class="col-6 col-md-2">
											</div>
											<div class="col-6 col-md-2">
											</div>
										</div>
									</div>	
								</form>
							</div> 
						</div>
					</div>
		
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
								<thead class="sticky-top">
					           		<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllSeasons" v-on:click="filters.selectAll('chkAllSeasons',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:6%;"> #<br /><span class="float-end">Ord. </span></th>
			                            <th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm">Titolo /<br />Stagione</th>
										<th class="table-head-sm" style="width:15%;">Data creazione</th>
										<th class="table-head-sm" style="width:20%;">Season</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Stato</th>
			                            <th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="season in paginatedList.arr" v-bind:key="season.ID_SEASON">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="season.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{season.ID_SEASON}}</span><br />
											<small class="badge bg-light text-primary float-end"> </small>
										</td>
										<td class="text-center">
											<a :href="$root.GLOBAL_IMAGES_PATH+'/CATEGORIA/'+season.ID_SEASON+'.jpg'" data-popup="lightbox">
												<img :src="$root.GLOBAL_IMAGES_PATH+'/CATEGORIA/'+season.ID_SEASON+'.jpg'" class="rounded-circle me-2" height="24" alt="">
											</a>
										</td>
										<td class="td-class fs-sm">
											<span class="text-secondary fw-bold">{{season.ID_SEASON}} - </span>
											<span class="text-primary fw-bold cursor-pointer" v-on:click="modificaSeason(season)">DESCRIZIONE</span><br />	                                   
											<span class="badge bg-dark me-1">CODICE_PRODOTTO</span> 
											<span class="badge badge-info me-1">Metti in Evidenza</span> 
										</td>
										<td class="td-class text-center fs-sm">1</td>
										<td class="td-class text-center fs-sm">1</td>
										<td class="td-class text-center fs-sm">
											<ISwitch @toggle="updateStatoSeason(season)" :size="'sm'" v-model="season.FLG_ATTIVO" style="margin-top:6px"></ISwitch>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaSeason(season)"><i class="fal fa-pencil fa-fw"></i> Modifica Tessera</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="openPageRange(season)"><i class="far fa-list fa-fw"></i> Gestione Range</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteSeason(season)"><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina Tessera</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
								<div class="float-end">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>	
		
					</div>	
				</div>
			</div>

			<div class="tab-pane fade" id="seasons-b">
				<div class="card shadow mb-0">
					<div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="season-b">


								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="seasons-c">
				<div class="card shadow mb-0">
					<div class="collapse show">
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="season-c">


								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>		
	</div>


	<!-- START MODALI -->
    <div class="modal fade" id="popUpSeason" aria-modal="true" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-xl">
	        <div class="modal-content">
	            <div class="modal-header bg-secondary text-white border-0">
	                <div class="d-flex d-lg-none me-2">
	                    <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
	                        <i class="ph-arrow-down"></i>
	                    </button>
	                </div>
	                <h6 class="modal-title" style="width: 85% !important;">Scheda: </h6>  
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body p-2"> 





                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaSeason()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>



        
</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'


export default {
    name:"CardSeason",  

    components:{
        Pagine,
        ISwitch,
    },	 

    data: function (){
      	return{
            apiStartup:'travel/seasons',
	        filters:global.filters,	
			rs : utils.generateRandomString(30),
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arraySeasons:new Array(),
            seasonSelezionata:new Array(),

	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ANNO:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
			    FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},
	        },

      	}
    },

    methods: {
        getSeasons: function () {
            utils.ajax(this.apiStartup,{ANNO:this.search.ANNO.VALUE}, (response) => {
                this.arraySeasons = response.data;
            }); 
        },

        nuovaSeason : function(){ 
			this.seasonSelezionata = {ID_SEASON:0,FLG_ATTIVO:1};
            this.filters.openPopUp("popUpSeason");  
        },

        modificaSeason : function(season){
            this.seasonSelezionata = utils.clone(season);
            this.filters.openPopUp("popUpSeason");  
        },

        salvaSeason : function(){
            var api = this.seasonSelezionata.ID_SEASON > 0 ? "travel/season/update" : "travel/season/insert";
            utils.ajax(api,this.seasonSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpSeason"); 
                    utils.ajax(this.apiStartup,{}, (response) => {
                        this.arraySeasons = response.data;
                    });
                }
            });
        },

		openPageRange:function(season){
			this.$root.seasonSelezionata = utils.clone(season);
			global.router.push("Range");
		},

        updateStatoSeason : function(season){
            setTimeout(() => {
                utils.ajax('travel/season/stato/update',season, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.filters.closePopUp("popUpSeason"); 
                    }  
                }); 
            },250);
        }

        
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arraySeasons,this.search);
            return arr;
        },	

	},

    created: function () {
        this.getSeasons();
    }
}
</script>