<template>
    
    <div class="row mt-1" style="height: 8.55rem;">
        <div class="col-md-4 mb-2">
            <img v-if="help.ID_CONTENUTO > 0 && help.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('CONTENUTO',help.URL_AVATAR)" class="rounded" style="width: 150px !important;" alt="" />
            <div v-if="help.URL_AVATAR == 'placeholder.jpg'" src="../../assets/images/placeholders/placeholder.jpg" class="squared"></div>
        </div>
        <div class="col-md-8 mb-2" style="height: 6.7rem;">
            <h4 class="text-primary mb-1"> 
				{{help.TITOLO}}
            </h4>
            <p class="text-dark mb-1"><i class="fal fa-tag fa-fw me-1"></i><span class="text-light me-1">|</span>{{help.SOTTOTITOLO}}</p>
            <p class="text-dark mb-1"><i class="fal fa-calendar fa-fw me-1"></i><span class="text-light me-1">|</span>{{ filters.formatDateTime(help.DATE_ADD) }}</p>
            <p class="fs-sm text-dark mb-1">&nbsp;</p>
            <p class="mb-0">&nbsp;</p>
        </div> 
    </div>

    <p>&nbsp;</p>

    <div class="row bg-light" style="border-top: 1px solid #ccc;">
        <div class="col-12">
            <div class="navbar navbar-expand-lg bg-light border-bottom py-1">
                <div class="container-fluid">
                    <ul class="nav navbar-nav flex-row flex-fill" role="tablist">
                        <li class="nav-item me-1" role="presentation">
                            <a href="#help-1" class="navbar-nav-link navbar-nav-link-icon active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-info"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Intro</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item me-1" role="presentation">
                            <a href="#help-2" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-question"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Faq</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item me-1" role="presentation">
                            <a href="#help-3" class="navbar-nav-link navbar-nav-link-icon rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                <div class="d-flex align-items-center mx-lg-1">
                                    <i class="ph-file-video"></i>
                                    <span class="d-none d-lg-inline-block ms-1">Video</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
						     
    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="help-1">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-info me-2"></i>Intro
                            <span v-if="$root.utente.FK_ID_LIVELLO == 1" v-on:click="modificaHelp(21)" class="badge bg-danger bg-opacity-20 text-danger cursor-pointer float-end p-1">
                                <span class="fw-semibold text-danger">Modifica Pagina</span>
                            </span>
                        </legend>	

                        <div v-html="help.TESTO_HOME"></div>
                        <hr/>
                        <div class="mt-3" v-html="help.TESTO_BODY"></div>

                    </fieldset>
                </form>
            </div>
            
			<div class="tab-pane fade" id="help-2">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-question me-2"></i>Domande &amp; risposte su questa pagina
                            
                        </legend>	

                        <div class="tableFixHead tableFixHeadRoles">
                            <table class="table table-bordered table-hover table-striped table-togglable">
                                <thead class="sticky-top">
                                    <tr class="bg-secondary text-white">
                                        <th class="p-1 fs-12" style="width:30%;"> Data </th>
                                        <th class="p-1 fs-12" style="width:20%;">Modulo</th>
                                        <th class="p-1 fs-12">Pagina</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-1 font-size-xs">30/12/2021 12:58:01</td>
                                        <td class="p-1 font-size-xs"><span class="text-primary fw-bold">Home</span></td>
                                        <td class="p-1 font-size-xs">op=users_list</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> 


                    </fieldset>
                </form>        
            </div>            

            <div  class="tab-pane fade" id="help-3">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-file-video me-2"></i>Video Tutorial
                            
                        </legend>	
                    
                       {{ help }}
                        
                    </fieldset>
                </form>  
            </div>           

            <div class="tab-pane fade" id="help-4">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-cards me-2"></i>Storico delle Tesssere
                            
                        </legend>	
                      
                        
                        
                    </fieldset>
                </form>  
            </div>

            <div class="tab-pane fade" id="help-5">
                <form action="#">
                    <fieldset>
                        <legend class="fs-base fw-bold border-bottom pb-2 mb-3"><i class="ph-clock-clockwise me-2"></i>Storico del cliente
                            
                        </legend>	
                    
                        <div class="tableFixHead tableFixHeadRoles">
                            <div class="list-feed">
                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 12, 12:47</div>
                                    <a href="#">David Linner</a> requested refund for a double bank card charge
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 11, 10:25</div>
                                    User <a href="#">Christopher Wallace</a> from Google is awaiting for staff reply
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 10, 09:37</div>
                                    Ticket <strong>#43683</strong> has been resolved by <a href="#">Victoria Wilson</a>
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 9, 08:28</div>
                                    <a href="#">Eugene Kopyov</a> merged <strong>Master</strong>, <strong>Demo</strong> and <strong>Dev</strong> branches
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 8, 07:58</div>
                                    All sellers have received payouts for December, 2016!
                                </div>

                                <div class="list-feed-item">
                                    <div class="text-muted">Jan 7, 06:32</div>
                                    <a href="#">Chris Arney</a> created a new ticket <strong>#43136</strong> and assigned to <a href="#">John Nod</a>
                                </div>
                            </div>
                        </div> 
                        
                    </fieldset>
                </form>  
            </div>


        </div>
    </div>
              

                
                
  

</template>



<script>


export default {
   name:"SchedaVisualizzazioneHelp",

   props:["modulo"],

   components:{
       
   },

   data:function(){
       return{
           filters:global.filters,
           help : {},      
           
           ts:new Date().getTime(),
       }
   },

   methods:{
        openSchedaVisualizzazioneHelp : function(_modulo){
            this.help = _modulo;
            utils.ajax("travel/contenuti/help",{ID_MODULO:_modulo}, (response) => {
                if (response.esito == 0){
                    this.help = response.data;
                }                
            });
        },
      

   },
   computed:{
       
   }

}
</script>