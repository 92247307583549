<template>
    <Prodotti :modulo="3"></Prodotti>
</template>


<script>

import Prodotti from '@/components/Prodotti.vue'

export default {
    name:"Packages",
    components:{
        Prodotti,
    },
}
</script>