<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer"> Accessi </a> \
					<span class="text-muted"> Gestione Accessi </span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">

					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(this.modulo)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
				</div>
			</div>
		</div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#accessi-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded confaccessi" data-tab="livelli" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-list-numbers"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Livelli
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#accessi-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded confaccessi" data-tab="gruppi" data-bs-toggle="tab" aria-selected="false" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-circles-four me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Gruppi
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#accessi-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded confaccessi" data-tab="funzionalita" data-bs-toggle="tab" aria-selected="false" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-bandaids"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Funzionalità
							</div>
						</a>
					</li>
				</ul>
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">

						</li>  
						<li class="nav-item ms-3">
							<SelectLingue class="form-select form-select-sm" @change="getTravelTipologiche()" v-model="currentLang">
							</SelectLingue>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->
	
	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="accessi-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="accesso-a">
									<div class="row">
										<div class="col-4 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" placeholder="Id Livello" type="text" v-model="searchLivelli.ID_LIVELLO.VALUE">
										</div> 
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchLivelli.DESCRIZIONE.VALUE">
										</div>           
										<div class="col-8 col-md-7">
	
										</div>          
										<div class="col-8 col-md-2">
											<button v-on:click="nuovoLivello()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> NUOVO </button>
										</div>
									</div>
								</form>
		                    </div>
						</div>
					</div>

			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllLevel" v-on:click="filters.selectAll('chkAllLevel',paginatedListLivelli.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm">Titolo<br />Livello</th>
										<th class="table-head-sm" style="width:30%;">Descrizione</th>
										<th class="table-head-sm" style="width:15%;">Core</th>
										<th class="table-head-sm" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="livello in paginatedListLivelli.arr" v-bind:key="livello.ID_LIVELLO">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{livello.ID_LIVELLO}}</span><br />
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer"></small>
										</td>
										<td class="td-class fs-sm"> 
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="modificaLivello(livello)">{{livello.DESCRIZIONE}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" >--</span>
										</td>
										<td class="td-class fs-sm"> 
											<span class="badge text-secondary text-muted">Descrizione: </span>
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Utenti: <a href="#">{{livello.FLG_CORE}}</a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Core: <a href="#">0</a></div>
										</td>	 
		                                <td class="td-class text-center">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase line-height-xs">Gestione</div>
	                                                <a href="#" class="dropdown-item" v-on:click="modificaLivello(livello)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Livello</a>
	                                                <div class="dropdown-header text-uppercase line-height-xs">Admin</div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteLivello(livello)"><i class="fad fa-trash-alt fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Livello</a>                                        
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>     
                   		</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">LIVELLI: {{ paginatedListLivelli.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
		                            <Pagine v-model="searchLivelli.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListLivelli.count" :numpagine="searchLivelli.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
		                        </div>
	                        </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchLivelli.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
                            </div>
						</div>
					</div>
				</div>
			</div>
						
			<div class="tab-pane fade" id="accessi-b">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-allb" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
		            <div class="collapse show" id="collapse-allb" style="">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="accesso-b">
									<div class="row">
										<div class="col-4 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" placeholder="Id Gruppo" type="text" v-model="searchGruppi.ID_GRUPPO.VALUE">
										</div> 
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchGruppi.DESCRIZIONE.VALUE">
										</div>           
										<div class="col-8 col-md-7">
	
										</div>          
										<div class="col-8 col-md-2">
											<button v-on:click="nuovoGruppo()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> NUOVO</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
								<thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllGroups" v-on:click="filters.selectAll('chkAllGroups',paginatedListGruppi.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm">Titolo<br />Gruppo</th>
										<th class="table-head-sm" style="width:30%;">Descrizione</th>
										<th class="table-head-sm" style="width:15%;">Core</th>
										<th class="table-head-sm" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="gruppo in paginatedListGruppi.arr" v-bind:key="gruppo.ID_GRUPPO">
										<td class="td-class text-center" style=""><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
										<td class="td-class fs-sm">{{gruppo.ID_GRUPPO}}<br />&nbsp;</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="modificaGruppo(gruppo)">{{gruppo.DESCRIZIONE}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px solid rgb(221, 221, 221);" >--</span>
										</td>
										<td class="td-class fs-sm">
											<span class="badge text-secondary text-muted">Descrizione: </span>
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Utenti: <a href="#">{{gruppo.FLG_CORE}}</a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Core: <a href="#">0</a></div>
										</td>	
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase line-height-xs">Gestione</div>
	                                                <a href="#" class="dropdown-item" v-on:click="modificaGruppo(gruppo)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Gruppo</a>
	                                                <a href="#" class="dropdown-item" v-on:click="funzionalitaGruppo(gruppo)"><i class="fad fa-list fa-fw me-2"></i><span class="text-muted me-1">|</span>Elenco Funzionalit&agrave;</a>
	                                                <div class="dropdown-header text-uppercase line-height-xs">Admin</div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteGruppo(gruppo)"><i class="fad fa-trash-alt fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Gestione documenti</a>                                        
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">GRUPPI: {{ paginatedListGruppi.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
	                                <Pagine v-model="searchGruppi.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListGruppi.count" :numpagine="searchGruppi.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchGruppi.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<div class="tab-pane fade" id="accessi-c">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-allc" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
		            <div class="collapse show" id="collapse-allc" style="">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="accesso-c">
									<div class="row">
										<div class="col-4 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" placeholder="Id Funzionalità" type="text" v-model="searchFunzionalita.ID_FUNZIONALITA.VALUE">
										</div> 
										<div class="col-4 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchFunzionalita.DESCRIZIONE.VALUE">
										</div>           
										<div class="col-8 col-md-7">
	
										</div>          
										<div class="col-8 col-md-2">
											<button v-on:click="nuovaFunzionalita()" class="btn btn-success float-end btn-sm"><i class="fal fa-plus fa-fw"></i> NUOVO </button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable">
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllFunzionalita" v-on:click="filters.selectAll('chkAllFunzionalita',paginatedListFunzionalita.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm">Titolo<br />Funzionalità</th>
										<th class="table-head-sm" style="width:30%;">Descrizione</th>
										<th class="table-head-sm" style="width:15%;">Item</th>
										<th class="table-head-sm" style="width:15%;">Core</th>
										<th class="table-head-sm" style="width:5%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="funz in paginatedListFunzionalita.arr" v-bind:key="funz.ID_FUNZIONALITA">
										<td class="td-class text-center" style=""><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> </td>
										<td class="td-class fs-sm">{{funz.ID_FUNZIONALITA}}<br />&nbsp;</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="modificaFunzionalita(funz)">{{funz.DESCRIZIONE}}</span><br />
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px solid rgb(221, 221, 221);" >--</span>
										</td>
										<td class="td-class fs-sm">
											<span class="badge text-secondary text-muted">Descrizione: </span>
										</td>	
										<td class="td-class fs-sm">
										</td> 
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Utenti: <a href="#">{{funz.FLG_CORE}}</a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Core: <a href="#">0</a></div>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase line-height-xs">Gestione</div>
	                                                <a href="#" class="dropdown-item" v-on:click="modificaFunzionalita(funz)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica funzionalit&agrave;</a>
	                                                <div class="dropdown-header text-uppercase line-height-xs">Admin</div>
	                                                <a href="#" class="dropdown-item" v-on:click="deleteFunzionalita(funz)"><i class="fad fa-trash-alt fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina funzionalit&agrave;</a>                                        
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">FUNZIONALITA': {{ paginatedListFunzionalita.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
	                                <Pagine v-model="searchFunzionalita.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListFunzionalita.count" :numpagine="searchFunzionalita.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchFunzionalita.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div> 
	</div>

					
	<!-- MODAL -->
	<div class="modal fade" id="popUpLivello" role="dialog"  aria-hidden="true" tabindex="-1">
	    <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Nuovo Livello</h6>  	 
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
				<div class="modal-body">

					<div class="form-group">
						<label>Descrizione</label>
						<input type="text" class="form-control" placeholder="Descrizione" v-model="livelloSelezionato.DESCRIZIONE">
					</div>

				</div>	
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaLivello()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div> 
			</div>
		</div>
	</div>

	<div class="modal fade" id="popUpGruppo" role="dialog"  aria-hidden="true" tabindex="-1">
	    <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Nuovo Gruppo</h6>  	 
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
				<div class="modal-body">
	
					<div class="form-group">
						<label>Descrizione</label>
						<input type="text" class="form-control" placeholder="Descrizione" v-model="gruppoSelezionato.DESCRIZIONE">
					</div>
	
				</div>
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaGruppo()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>
			</div>
		</div>
	</div>
			
	<div class="modal fade" id="popUpFunzionalita" role="dialog"  aria-hidden="true" tabindex="-1">
	    <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title">Nuova Funzionalit&agrave;</h6>  	 
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
				<div class="modal-body">
	
					<div class="form-group mb-2">
						<label>Descrizione</label>
						<input type="text" class="form-control" placeholder="Descrizione" v-model="funzionalitaSelezionata.DESCRIZIONE">
					</div>
					<div class="form-group">
						<label>Voce di menu correlata</label>
						<select  class="form-control" placeholder="Descrizione" v-model="funzionalitaSelezionata.FK_ID_ITEM">
							<option value="0">Nessuna voce</option>
							<option v-for="menu in linearMenuItems" v-bind:value="menu.ID_ITEM" v-bind:key="menu.ID_ITEM">{{menu.ID_ITEM}} - {{menu.DESCRIZIONE}}</option>
						</select>
					</div>
	
				</div>
				<div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="popUpFunzionalitaGruppo" role="dialog"  aria-hidden="true" tabindex="-1">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-light text-dark">
					<h6 class="modal-title">{{gruppoSelezionato.DESCRIZIONE}} - Elenco Funzionalità</h6>					
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">
	
					<div class="row" >
						<div class="col-md-2"><label class="font-weight-semibold">Descrizione:</label></div>
						<div class="col-md-10"><input class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchFunzGruppo.DESCRIZIONE.VALUE"></div>
					</div>   
					<hr class="hr-2" />
					<div class="box_26">
						<table class="table table-hover table-striped table-bordered">
							<thead>
								<tr>
									<th class="table-head-sm-2" style="width: 10%;">#</th> 
									<th class="table-head-sm-2" style="width: 38%;">Descrizione</th>  
									<th class="table-head-sm-2" style="width: 38%;">Valore</th>  
								</tr>
							</thead> 
							<tbody>
								<tr v-for="funzionalita in paginatedLisFunzGruppo.arr" v-bind:key="funzionalita.ID_FUNZIONALITA">
									<td>{{funzionalita.ID_FUNZIONALITA}}</td>
									<td>{{funzionalita.DESCRIZIONE}}</td> 
									<td><ISwitch color="#5cb85c" @toggle="updateFunzionalitaGruppo($event,funzionalita)" :size="'sm'" v-model="funzionalita.SELEZIONATO" class="pull-right"></ISwitch></td> 
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer modal-footer-1 bg-light" style="display: unset;">
					<div class="float-start">
						<pagine class="mt-0" v-model="searchFunzGruppo.PAGINATION.PAGE_NUMBER" :numrighe="paginatedLisFunzGruppo.count" :numpagine="searchFunzGruppo.PAGINATION.LENGTH"></pagine>
					</div>
					<div class="float-end">
						<button type="button" class="btn btn-light btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> ANNULLA </button>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'

export default {
    name:"Configurazione",

    components:{
        Pagine,
        ISwitch,
		SelectLingue    
    }, 

    props:['livello'],

    data: function () {
        return {   
			apiStartup:'core/livelli',
			filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
            arrayLivelli: new Array(),
            arrayGruppi : new Array(),
            arrayFunzionalita: new Array(),
            arrayFunzionalitaGruppo : new Array(),
            linearMenuItems : new Array(),
            opzioneSelezionata : {},
			currentLang:0,
            searchLivelli:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ID_LIVELLO:{TYPE:'TEXT',VALUE:''},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            livelloSelezionato:{},        
            searchGruppi:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ID_GRUPPO:{TYPE:'TEXT',VALUE:''},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            gruppoSelezionato:{},
            searchFunzionalita:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ID_FUNZIONALITA:{TYPE:'TEXT',VALUE:''},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
            funzionalitaSelezionata:{},
            searchFunzGruppo:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:10},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
            },
        }
    },

    methods: {
        getLivelli: function () {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayLivelli = response.data;
            });
        },

        nuovoLivello:function(){
            this.livelloSelezionato = utils.nuovoElemento(this.livelloSelezionato,{ID_LIVELLO:0,FLG_CORE:0,DESCRIZIONE:""},"popUpLivello");
        },

        modificaLivello:function(funzionalita){
            this.livelloSelezionato = utils.modificaElemento(this.livelloSelezionato,funzionalita,"popUpLivello");
        },

        salvaLivello:function(){
            utils.salvaElemento("core/livelli/",this.livelloSelezionato,"ID_LIVELLO","popUpLivello",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getLivelli();
                }
            });
        },

        delateLivello:function(){
            
        },

        getGruppi: function () {
            utils.ajax("core/gruppi",{}, (response) => {
                this.arrayGruppi = response.data;
            });
        },

        nuovoGruppo:function(){
            this.gruppoSelezionato = utils.nuovoElemento(this.gruppoSelezionato,{ID_GRUPPO:0,FLG_CORE:0,DESCRIZIONE:""},"popUpGruppo");
        },

        modificaGruppo:function(gruppo){
            this.gruppoSelezionato = utils.modificaElemento(this.gruppoSelezionato,gruppo,"popUpGruppo");
        },

        salvaGruppo:function(){
            utils.salvaElemento("core/gruppi/",this.gruppoSelezionato,"ID_GRUPPO","popUpGruppo",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getGruppi();
                }
            });
        },

        delateGruppo:function(){
            
        },
		
        funzionalitaGruppo : function(gruppo){
            this.arrayFunzionalitaGruppo = new Array();
            utils.ajax("core/gruppo/funzionalita",gruppo, (response) => {
                this.gruppoSelezionato = response.data.gruppo;
                for (var i = 0 ; i < response.data.funzionalita.length ; i++){
                    var selezionato = utils.array.select(this.gruppoSelezionato.funzionalita,"ID_FUNZIONALITA",response.data.funzionalita[i]['ID_FUNZIONALITA'],1).length == 1 ? 1 : 0;
                    var item = utils.clone(response.data.funzionalita[i]);
                    item.SELEZIONATO = selezionato;
                    this.arrayFunzionalitaGruppo.push(item);
                }
				this.filters.openPopUp('popUpFunzionalitaGruppo');
            });
        },

		updateFunzionalitaGruppo:function(selezionato,funzionalita){
			utils.wait(()=>{
				utils.ajax("core/gruppi/funzionalita/update",{ID_FUNZIONALITA:funzionalita.ID_FUNZIONALITA,ID_GRUPPO:this.gruppoSelezionato.ID_GRUPPO,SELEZIONATO:funzionalita.SELEZIONATO}, (response) => {
				
				});
			});            
		},

        getFunzionalita: function () {
            utils.ajax("core/funzionalita",{}, (response) => {
                this.arrayFunzionalita = response.data;
            });
        },

        nuovaFunzionalita:function(){
            this.funzionalitaSelezionata = utils.nuovoElemento(this.funzionalitaSelezionata,{ID_FUNZIONALITA:0,FLG_CORE:0,DESCRIZIONE:"",FK_ID_ITEM:0},"popUpFunzionalita");
        },

        modificaFunzionalita:function(funzionalita){
            this.funzionalitaSelezionata = utils.modificaElemento(this.funzionalitaSelezionata,funzionalita,"popUpFunzionalita");
        },

        salvaFunzionalita:function(){
            utils.salvaElemento("core/funzionalita/",this.funzionalitaSelezionata,"ID_FUNZIONALITA","popUpFunzionalita",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getFunzionalita();
                }
            });
        },

		delateFunzionalita:function(){
			
		},
       
        esportaSelezionati : function(){
            var arr = utils.array.select(this.arrayCustomForm,"SELEZIONATO",1);
            utils.ajax("core/customform/esporta",{arr:arr}, (response) => {
                $key = response.data.key;
                window.open("CORE/SERVER/API/core/report?ID_REPORT=1&KEY="+$key);
            });
        },

    },

    computed: {
        paginatedListLivelli(){
            var arr = utils.inGrid(this.arrayLivelli,this.searchLivelli);
            return arr;
        },
        paginatedListGruppi(){
            var arr = utils.inGrid(this.arrayGruppi,this.searchGruppi);
            return arr;
        },
        paginatedListFunzionalita(){
            var arr = utils.inGrid(this.arrayFunzionalita,this.searchFunzionalita);
            return arr;
        },
        paginatedLisFunzGruppo(){
            var arr = utils.inGrid(this.arrayFunzionalitaGruppo,this.searchFunzGruppo);
            return arr;
        }
    },

    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            //global.utils.resizeContent();     
        }, 300);
    },
    
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },

    created: function () {
        this.selectedMenuItem = this.$root.selectedMenuItem;
        setTimeout(() => {
			var tabEl = document.querySelectorAll('.confaccessi');
			for (var i = 0 ; i < tabEl.length; i++){
				tabEl[i].addEventListener('shown.bs.tab',  (event) => {
					//event.target // newly activated tab
					//event.relatedTarget // previous active tab
					var tab = event.target.dataset.tab;
					switch(tab){
						case 'livelli':
							this.getLivelli();
							break;
						case 'gruppi':
							this.getGruppi();
							break;
						case 'funzionalita':
							this.getFunzionalita();
							break;
					}
				});
                  
			}
			   
        },- 300);
        //
        utils.ajax('core/basic/element',{"className":"CustomForm","id":1}, (response) => {
            this.arrayCampiMenuChildren = response.data.elements;
            utils.ajax('core/menu/children/linear',{}, (response) => {
                this.linearMenuItems = response.data;
                this.currentLang = this.$root.currentLang;
				this.getLivelli(); 
            }); 
        });
    },
}
</script>


