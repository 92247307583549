<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
                    <a href="#" class="text-dark fw-semibold" v-on:click="getCategorie()"> Categorie </a> \ 
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Categorie</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;" v-on:click="nuovaCategoria()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Categoria</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(34)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(34)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(34)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
                </div>
            </div>
        </div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#categorie-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Categorie
							</div>
						</a>
					</li>
				</ul>

				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getCategorie()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>
						<li class="nav-item ms-3">
							<SelectLingue class="form-select form-select-sm" @change="getCategorie()" v-model="currentLang" :tabindex="4">
							</SelectLingue>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="categorie-a">
        		<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="utente-a">
				                    <div class="row ">
				                  		<div class="col-5 col-md-1 col-lg-1 mbx-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" v-model="search.ID_CATEGORIA.VALUE" placeholder="Id Categoria" :tabindex="1" />
				                    	</div>	
			                            <div class="col-7 col-md-2 col-lg-2 mbx-1">
		                                    <select class="form-select form-select-sm" v-model="search.FK_ID_MODULO.VALUE" placeholder="Seleziona Modulo">
		                                        <option v-bind:value="''">Seleziona Modulo</option>
		                                        <option v-for="modulo in arrayModuli" v-bind:key="modulo.FK_ID_MODULO"  v-bind:value="modulo.FK_ID_MODULO" > &nbsp; {{modulo.DESCRIZIONE}}</option>
		                                    </select>
			                            </div> 
				                  		<div class="col-5 col-md-2 col-lg-2 mbx-1">
				                     		<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Titolo Categoria" :tabindex="1" v-model="search.DESCRIZIONE.VALUE" />
				                    	</div>               
			                            <div class="col-12 col-md-3 col-lg-3 mbx-1">
		
		                                </div>	
										<div class="col-12 col-md-4 col-lg-4">
		                                    <div class="row">
												<div class="col-12 col-md-3 col-lg-3 mbx-1">
												</div> 
												<div class="col-12 col-md-3 col-lg-3 mbx-1">
												</div>
												<div class="col-12 col-md-3 col-lg-3">
					                                <select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="13" v-model="search.FLG_ATTIVO.VALUE">
					                                    <option value="">Tutti </option>   
					                                    <option value="1"> &nbsp; Attivati</option>
					                                    <option value="0"> &nbsp; Disattivati</option>
					                                </select>
					                            </div>
												<div class="col-6 col-md-3 col-lg-3">
					                                <a href="#collapse-a" class="btn btn-flat-info btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
					                                    + FILTRI
													</a>
												</div>
					                        </div>
		                                </div>  
		                            </div>
							 	</form>
							</div>
		          		</div> 
			        </div>
		
			        <div class="card-body box-card mt-0 mb-0">
		             	<div class="tableFixHead">
		                    <table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
		                                <th class="table-head-sm" style="width:2%;"> <input id="chkAllCategorie" v-on:click="filters.selectAll('chkAllCategorie',paginatedList.arr)" type="checkbox" class=""> </th>
			                            <th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
			                            <th class="table-head-sm" style="width:6%;"> # </th>
		                                <th class="table-head-sm text-center" style="width:4%;"> Img </th>
		                                <th class="table-head-sm">Titolo Categoria /<br />Sito / SEO</th>
										<th class="table-head-sm" style="width:20%;">Applicabile a</th>
										<th class="table-head-sm" style="width:15%;">Modello <br /></th>
										<th class="table-head-sm" style="width:5%;">Hits</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
		                            </tr>
		                        </thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
		                            <tr v-for="categoria in paginatedList.arr" v-bind:key="categoria.ID_CATEGORIA">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="categoria.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center"><img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" /></td>
		                                <td class="td-class fs-sm">
											<span>{{categoria.ID_CATEGORIA}}</span><br /> 
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer" v-on:click="modificaOrdinamento(categoria)">{{ categoria.ORDINE }}</small>
										</td>
		                                <td class="td-class text-center"> 
											<img v-if="categoria.ID_CATEGORIA > 0 && categoria.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('CONTENUTO',categoria.URL_AVATAR)" class="squared" style="height: 40px;" alt="" />
											<span v-if="categoria.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="width: 40px; height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
		                                </td>
		                                <td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="openOffCanvas();openSchedaVisualizzazioneContenuto(categoria)">{{categoria.DESCRIZIONE}}</span><br /> 	                                   
		                                    <span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="Ente"><i class="fal fa-tag fa-fw"></i> {{categoria.FK_ID_ENTE}}</span>
		  									<span v-on:click="updateCategoriaFlgInHome(categoria)" v-show="categoria.FLG_INHOME == 1" class="badge bg-info badge-xs text-white cursor-pointer"><i class="fal fa-home fa-fw"></i> In Home</span>
											<span v-on:click="updateCategoriaFlgInHome(categoria)" v-show="categoria.FLG_INHOME == 0" class="badge bg-light badge-xs text-dark cursor-pointer" style="border: 1px #dddddd solid;"><i class="fal fa-thumbs-up fa-fw"></i> Metti in Home</span>
										</td>
										<td class="td-class fs-sm">
                                            <span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;">{{ categoria.NOME_MODULO }}</span><br />
                                            <span class="text-muted">&nbsp;</span>
                                        </td>
										<td class="td-class fs-sm">
											<div><i class="ph-cube fs-base lh-base align-top text-success me-1"></i><span class="double-items">{{categoria.MODEL}}</span></div>
											<div><i class="ph-calendar fs-base lh-base align-top text-danger me-1"></i> <span class="double-items">{{ filters.formatDateTime(categoria.DATE_ADD) }}</span></div>
										</td>
		                                <td class="td-class fs-sm text-center"><span class="badge badge-flat badge-pill border-secondary text-secondary">{{categoria.COUNTER}}</span></td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateCategoriaFlgVisibile(categoria)" v-show="categoria.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateCategoriaFlgVisibile(categoria)" v-show="categoria.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>
		                                <td class="td-class text-center">
		                                    <ISwitch @toggle="updateStatoCategoria(categoria)" :size="'sm'" v-model="categoria.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
		                                </td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="modificaCategoria(categoria)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Categoria</a>
													<div class="dropdown-divider"></div>
	                                            	<a href="#" class="dropdown-item" v-on:click="deleteCategoria(categoria)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Categoria</a>
	                                            </div>
	                                        </div>
		                                </td>
		                            </tr>
		                        </draggable>
		                    </table>
		                </div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">CATEGORIE: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
		                        <div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
					</div>	
		    	</div>
			</div>

        </div>
	</div> 


	<!-- START MODALI --> 	    
    <div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right" data-bs-backdrop="false" role="dialog" aria-hidden="true" tabindex="-1">
		<div class="offcanvas-header bg-secondary text-white" style="height: 4rem;">
			<h6 class="modal-title" style="width: 65% !important;">SCHEDA INFORMATIVA: </h6>  
			<div class="btn-group float-end" :tabindex="2">
				<a href="#" class="badge bg-yellow text-dark p-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Comunicazioni</a>
				<div class="dropdown-menu dropdown-position dropdown-menu-end" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);">
					<a href="#" class="dropdown-item"><i class="fal fa-print me-2"></i> Stampa scheda </a>
					<a href="#" class="dropdown-item"><i class="fal fa-envelope me-2"></i> Invia comunicazione </a>
					<a href="#" class="dropdown-item"><i class="fal fa-paper-plane me-2"></i> Invia sms </a>
				</div>
			</div> 	
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneContenuto @modificaContenuto="openSchedaCategoria($event)" :ref="'schedaVisualizzazioneContenuto'"></SchedaVisualizzazioneContenuto>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i> CHIUDI</span>     
        </div>  
	</div>

    <SchedaContenuto :ref="'schedaContenuto'" @updateGriglia="getCategorie()" :modalita="2"></SchedaContenuto>

	<PopupOrdinamento @ordinamentoAggiornato="handlerOrdinamentoAggiornato($event)" :modulo="modulo" :ref="'modalOrdinamento'"></PopupOrdinamento>
        
</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import SchedaContenuto from '@/components/SchedaContenuto.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import SchedaVisualizzazioneContenuto from '@/components/SchedaVisualizzazioneContenuto.vue'
import PopupOrdinamento from '@/components/utils/PopupOrdinamento.vue'


export default {
    name:"Categorie",	 
    emits:['updateGriglia'],

    components:{
        Pagine,
        ISwitch,
        SchedaContenuto,
        draggable: VueDraggableNext,
		SelectLingue,
		SchedaVisualizzazioneContenuto,
		PopupOrdinamento

    },

    data: function (){
      	return{
            apiStartup:'travel/categorie',
	        filters:global.filters,	
            GLOBAL_IMAGES_PATH:'',
	        arrayCategorie:new Array(),
            categoriaSelezionata:{enti:new Array()},
            categoriaCorrente:{},
            arrayCampiPopUpCategorie : new Array(),
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ID_CATEGORIA:{TYPE:'TEXT',VALUE:''},
	            DESCRIZIONE:{TYPE:'TEXT',VALUE:''},	
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				FK_ID_MODULO:{TYPE:'COMBO_IN_ARRAY',VALUE:'',ARRAY:"MODULI"}
	        },
			rs : utils.generateRandomString(30),
            arrayModuli : new Array(),
			ordineHasChanged: false,
			currentLang:0,
      	}
		
    },

    methods: {
        getCategorie: function () {
            utils.ajax(this.apiStartup,{}, (response) => {
				this.ordineHasChanged = false;
                this.arrayCategorie = response.data;
				this.arrayModuli = new Array();
				
				for (let i = 0 ; i < this.arrayCategorie.length ; i++){
                    this.arrayCategorie[i].FK_ID_MODULO = 0;
                    this.arrayCategorie[i].MODULI = new Array();
					var arrayModuli = this.arrayCategorie[i].NOME_MODULO.split(",");
					var arrayId = this.arrayCategorie[i].ID_MODULI.split(",");
					var arrayFinale = new Array();
					for (var j = 0 ; j < arrayModuli.length ; j++){
						arrayFinale.push({FK_ID_MODULO:arrayId[j],DESCRIZIONE:arrayModuli[j]});
						var check = 0;
						for (var k = 0 ; k < this.arrayModuli.length ; k++){
							if (this.arrayModuli[k].FK_ID_MODULO == arrayId[j]){
								check = 1;
								break;
							}
						}
						if (check == 0){
							this.arrayModuli.push({FK_ID_MODULO:arrayId[j],DESCRIZIONE:arrayModuli[j]});
						}
					}
					this.arrayCategorie[i].MODULI = arrayFinale;
                }
				
            }); 
        },		

        nuovaCategoria : function(){ 
            this.$refs.schedaContenuto.startup({ID_CATEGORIA:0,FLG_ATTIVO:1});
        },

        modificaCategoria : function(categoria){            
            this.categoriaSelezionata = utils.clone(categoria);
            this.$refs.schedaContenuto.startup(categoria);
        },

        salvaCategoria : function(){
            var api = this.categoriaSelezionata.ID_CATEGORIA > 0 ? "travel/categoria/update" : "travel/categoria/insert";
            utils.ajax(api,this.categoriaSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (this.categoriaSelezionata.ID_CATEGORIA == 0){
                        this.categoriaSelezionata.ID_CATEGORIA = response.id_inserito;
                    }
                    utils.ajax('travel/categoria',this.categoriaSelezionata, (response) => {                        
                        //this.filters.closePopUp("popUpCategoria");
                        utils.ajax('travel/categorie',{}, (response) => {
                            this.arrayCategorie = response.data;
                        });
                    }); 
                }
            });
        },  

        updateStatoCategoria : function(categoria){
            setTimeout(() => {
                utils.ajax('travel/categoria/stato/update',categoria, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.filters.closePopUp("popUpCategoria");
                    }  
                }); 
            },250);
        },

         deleteCategoria : function(t){
            utils.alert.confirm("Sicuro di voler eliminare questa Categoria " + t.NOME + "?",()=>{
                utils.ajax('travel/categoria/delete',{className:'Categoria',id:t.ID_CATEGORIA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getCategorie();
                    }
                });
            });
        }, 
	
		updateCategoriaFlgVisibile : function(categoria){
			categoria.FLG_VISIBILE = categoria.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("travel/categoria/update",categoria, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					categoria.FLG_VISIBILE = categoria.FLG_VISIBILE == 1 ? 0 : 1;
				}
            });
        },

		updateCategoriaFlgInHome : function(categoria){
			categoria.FLG_INHOME = categoria.FLG_INHOME == 1 ? 0 : 1;
			utils.ajax("travel/categoria/update",categoria, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					categoria.FLG_INHOME = categoria.FLG_INHOME == 1 ? 0 : 1;
				}
			});                
        },	
        
        esportazione : function(formato){
            utils.report.token({modalita:'esportaCategorie',formato:formato});
        },

        openSchedaCategoria: function(categoria){
            this.$refs.schedcategoria.openSchedaCategoria(categoria);
        },
        
		ordineChangeHandler : function(event){
			this.arrayCategorie = this.filters.draggableChangeHandler(event,this.arrayCategorie,this.search,"ID_CATEGORIA");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arrayCategorie.length ; i++){
					arr.push({"ID": this.arrayCategorie[i].ID_CATEGORIA,"O": this.arrayCategorie[i].ORDINE});
				}			
				utils.ajax('travel/peso/update',{CLASS:'Categoria',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getCategorie();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

		openOffCanvas : function(){
			var myOffcanvas = document.getElementById('panel_right')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
		},

		openSchedaVisualizzazioneContenuto : function(categoria){
			this.categoriaSelezionato = utils.clone(categoria);	
			this.$refs.schedaVisualizzazioneContenuto.openSchedaVisualizzazioneContenuto(categoria,1);
		},

		modificaOrdinamento : function(categoria){
			this.$refs.modalOrdinamento.openPopUpOrdinamento(categoria,this.arrayCategorie,"ID_CATEGORIA");
		},
		
		handlerOrdinamentoAggiornato : function(_array){
			this.arrayCategorie = utils.clone(_array);
			this.salvaOrdinamento();
		}
       
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayCategorie,this.search);
            return arr;
        },
/*
		getTotali(){
            var filtrato = global.utils.filter(this.arrayUtenti,this.search);
            var totale = 0;
            var fatturato = 0;
            for (var i = 0 ; i < filtrato.length ; i++){
                totale += parseFloat(filtrato[i]);
                fatturato += parseFloat(filtrato[i]);
            }
            return {
                LORDO:lordo,
                COMM:comm,
                NETTO:lordo-comm,
                COUNT:filtrato.length
            }
        }
*/

        moduleName(){
           return this.filters.moduleName(this.modulo);
        }
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.categoriaCorrente = utils.clone(this.$root.categoria);
        this.arrayModuli = utils.array.toSelect2(this.$root.arrayTipoModuli,"ID_OPZIONE_TIPOLOGICA","DESCRIZIONE");
		this.currentLang = this.$root.currentLang;
		/*
        utils.ajax(this.apiStartup,{}, (response) => {
		   this.ordineHasChanged = false;
           this.arrayCategorie = response.data;
        });*/
		this.getCategorie();
    }

}
</script>