<template>
    <div :id="'popUpRicercaProdotto_'+ts" class="modal fade">
        <div class="modal-dialog modal-medium">
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-info text-white">
                    Ricerca prodotto
                </div>
                <div class="modal-body-2">	
                    <div class="row">
                        <div class="col-md-12 form-group-1">
                            <label class="text-muted custom-control custom-control-right" style="font-weight: 400;">Descrizione </label>
                            <div class="input-group input-group-sm">
                                <span class="input-group-prepend"><span class="input-group-text"><i class="far fa-search"></i></span></span>
                                <input type="text" class="form-control form-control-sm" v-on:keyup.enter="ricerca()" v-model="descrizione" >
                            </div>
                        </div>    
                        <button class="btn btn-block btn-lg btn-info" v-on:click="ricerca()">Cerca</button>        
                    </div>
                    <ul class="list-group">
                        <li class="list-group-item" v-for="prodotto in arrayProdotti" v-bind:key="prodotto.ID_PRODOTTO" v-on:click="selezionaProdotto(prodotto)">
                            {{prodotto.CODICE_PRODOTTO}} - {{prodotto.TITOLO}}
                        </li>
                    </ul>
                </div>                
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name:"RicercaProdotto",
    props:["ts"],
    data:function(){
        return {
            modulo:0,
            descrizione:'',
            arrayProdotti:new Array()
        }
    },
    methods:{
        openRicerca:function(modulo){
            this.modulo = modulo;
            this.filters.openPopUp('#popUpRicercaProdotto_'+this.ts);
        },
        ricerca:function(){
            utils.ajax("travel/prodotto/ricerca",{q:this.descrizione,FK_ID_MODULO:this.modulo}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.arrayProdotti = response.data;
                }
            });   
        },
        selezionaProdotto:function(prodotto){
            this.filters.closePopUp('#popUpRicercaProdotto_'+this.ts);
            this.$emit("prodottoSelezionato",prodotto);
        }
    }
}
</script>
