<template>
    <div>

        <div class="col-md-12">
            <div style="background:#fff;border:1px solid #cfcfcf;margin-bottom:4px;border-radius:8px">
                <button :disabled="!item.hasOwnProperty('children')" v-on:click="handleOpen(item)" class="btn btn-sm btn-primary"><i v-bind:class="getCurrentClass()"></i></button>
                <span style="margin-left:4px;">{{item.ID_ITEM}} - {{item.DESCRIZIONE}}</span>
                <div class="float-end">
                    <button v-for="button in buttons" v-on:click="button.method(item)" style="margin-left:5px" v-bind:class="button.class" v-bind:key="button"><i v-bind:class="button.icon"></i></button>
                </div>
            </div>
            <div v-for="child in item.children" v-show="open" v-bind:key="child" >   
                <tree v-bind:item="child" :openicon="openicon" :closeicon="closeicon" :leaficon="leaficon" :buttons="buttons"></tree>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name:"Tree",
    props:['item','openicon','closeicon','leaficon','buttons'],
    data:function(){
        return{
            open:true
        }
    },
    methods: {
        handleOpen : function(item){
            this.open = !this.open;
        },
        getCurrentClass : function(){
            if (!this.item.hasOwnProperty('children'))
                return this.leaficon;
            return this.open ? this.openicon : this.closeicon;
        }
    },
    computed: {
    },
    watch: {

    },
}
</script>