<template>
      
    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
                    <a href="#" class="text-dark fw-semibold"> Mailbox </a> \
                    <span class="text-muted">Gestione email</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaEmail()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1">Nuova Email</div>
							<div class="fw-semibold">Scrivi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
            </div>
		</div>

        <!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#email-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-list"></i>
								<span class="d-none d-lg-inline-block ms-2">Email in Arrivo</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#email-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2">Bozze Email</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#email-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2">Email Inviate</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#email-d" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2">Email Eliminate</span>
							</div>
						</a>
					</li>
				</ul>


				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item dropdown-toggle"><i class="far fa-check-square fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
	                        		<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Attiva seleionati</a>
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Disattiva seleionati</a>
	                                </div>
	                            </div>
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item dropdown-toggle"><i class="far fa-file-excel fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
	                                <div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Utenti seleionati</a>
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Tutti gli Utenti</a>
	                                </div>
	                            </div>
								<a v-show="livello == 5" href="#" class="dropdown-item"><i class="far fa-users fa-fw me-2"></i><span class="text-muted me-1">|</span>Merge Utente</a>
	                            <div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina</a>
							</div>
						</li>
						<li class="nav-item ms-3" v-show="livello == 5">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getEmail()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
								<option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
	                        </select>                        
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="email-a">
				<div class="card shadow mb-0">
					<div class="collapse show">
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="utente-a">	
									<div class="row">
										<div class="col-6 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Cognome" :tabindex="9" />
										</div>
										<div class="col-6 col-md-1 col-lg-1">
			                                <a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
			                                    + FILTRI
											</a>
										</div>
									</div>
								</form>
							</div>
						</div> 
					</div>
				
			        <div class="card-body box-card mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-hover table-striped table-togglable table-inbox"> 
								<thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm text-center" style="width:3%;"> <input id="chkAllEmail" v-on:click="filters.selectAll('chkAllEmail',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm text-center" style="width:3%;"> # </th>
										<th class="table-head-sm text-center" style="width:5%;"> Img </th>
										<th class="table-head-sm" style="width:15%;">Nominativo</th>
										<th class="table-head-sm">Oggetto /<br />testo</th>
										<th class="table-head-sm text-center" style="width:4%;">Attach</th>
										<th class="table-head-sm text-center" style="width:9%;">Data</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="email in paginatedList.arr" v-bind:key="email.ID_UTENTE">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" class=""> 
										</td>
										<td class="td-class table-inbox-star text-center">
											<a href="#">
												<i class="ph-star text-muted opacity-25"></i>
											</a>
										</td>
										<td class="td-class table-inbox-image text-center">
											<img src="../assets/images/brands/spotify.svg" class="rounded-circle" width="32" height="32" alt="">
										</td>
										<td class="td-class table-inbox-name">
											<a href="mail_read.html">
												<div class="letter-icon-title text-body">Spotify</div>
											</a>
										</td>
										<td class="td-class text-truncate">
											<div class="table-inbox-subject">On Tower-hill, as you go down</div>
											<span class="text-muted fw-normal">To the London docks, you may have seen a crippled beggar (or KEDGER, as the sailors say) holding a painted board before him, representing the tragic scene in which he lost his leg</span>
										</td>
										<td class="td-class table-inbox-attachment text-center">
											<i class="ph-paperclip text-muted"></i>
										</td>
										<td class="td-class table-inbox-time">
											11:09 pm
										</td>
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-9 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
			                    </div>
			                </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
			            	</div>
			        	</div>
		
					</div>
				</div>
            </div>

            <div class="tab-pane fade" id="email-b">


            </div>

            <div class="tab-pane fade" id="email-c">


            </div>

            <div class="tab-pane fade" id="email-d">


            </div>
	
   		</div>
    </div>

</template>



<script>	 

import Pagine from '@/components/utils/Pagine.vue'
//import DatePicker from '@/components/utils/DatePicker.vue'

export default {
    name:"Inbox",

    components:{
        Pagine,
        //DatePicker,
    },			

    data:function(){
        return {
            apiStartup:'travel/admin/utenti',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),

            arrayMessaggi:new Array(),
            messaggioSelezionato:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	        },
        }
    },

    methods:{
        getEmail : function () {
            utils.ajax(this.apiStartup,{ANNO:this.search.ANNO_ORDINE.VALUE}, (response) => {
                this.arrayMessaggi = response.data;
            }); 
        }, 

         
        
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayMessaggi,this.search);
            return arr;
               
        },
    }, 

    created : function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.utenteCorrente = utils.clone(this.$root.utente);
        this.getEmail();
    }
    
}
</script>
