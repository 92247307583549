<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">

		<!-- breadcrumb -->
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-1"></i></router-link>
					<a href="#" class="text-dark fw-semibold cursor-pointer" v-on:click="getTypes()"> Template </a> \ 
					<span class="text-muted"> Gestione Slider</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
	
					<a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;">
						
					</a>

					<div class="vr flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex float-end mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(35)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue()" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help()" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
        </div>
		<!-- /breadcrumb -->

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#sliders-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Posizioni
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#sliders-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-vignette me-2"></i><Translate :path="'dizionario.base.ELENCO'" class="me-1"></Translate>Immagini
							</div>
						</a>
					</li>
				</ul>
				
				<a class="rounded btn bg-danger bg-opacity-20 btn-sm-light me-2" v-on:click="getTypes()" v-show="ordineHasChanged">Annulla ordinamento</a>
				<a class="rounded btn bg-success bg-opacity-20 btn-sm-light" v-on:click="salvaOrdinamento()" v-show="ordineHasChanged">Salva ordinamento</a>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<div class="btn-group">
								<a href="#" class="btn btn-flat-indigo border-transparent btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="ph-gear me-1"></i><span style="font-size:0.8rem;">Azioni Multiple</span></a>
								<div class="dropdown-menu dropdown-menu-end" style="">
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-ballot-check fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-check-square fa-fw me-2"></i>Attiva selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-square fa-fw me-2"></i>Disattiva selezionati</a>
										</div>
									</div>
									<div class="dropdown-submenu dropdown-submenu-start">
										<a href="#" class="dropdown-item"><i class="far fa-file-export fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
										<div class="dropdown-menu">
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Pagina corrente</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Utenti selezionati</a>
											<a href="#" class="dropdown-item"><i class="far fa-file-excel fa-fw me-2"></i>Tutti gli Utenti</a>
										</div>
									</div>
									<div class="dropdown-divider"></div>
									<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina Utenti</a>
								</div>
							</div>
						</li>	   
						<li class="nav-item ms-3">
							<SelectLingue class="form-select form-select-sm" @change="getTypes()" v-model="currentLang">
							</SelectLingue>						
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
    <div class="content content-1 table-cms-wrapper">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="sliders-a">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-all" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-all" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="slider-a">
									<div class="row ">
										<div class="col-5 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Posizione" v-model="search.ID_TYPE.VALUE" :tabindex="1" />
										</div>
				                  		<div class="col-5 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" v-model="search.DESCRIZIONE.VALUE" :tabindex="1" />
										</div>  	
			                            <div class="col-7 col-md-2 col-lg-2 mbx-1">
		                                    
			                            </div>               
			                            <div class="col-12 col-md-4 col-lg-4 mbx-1">
		
										</div>
										<div class="col-12 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="search.FLG_ATTIVO.VALUE" :tabindex="13">
												<option value="">Tutte </option>   
												<option value="1"> &nbsp; Attivate</option>
												<option value="0"> &nbsp; Disattivate</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-2">
			                                <a href="#" class="btn btn-flat-info btn-sm w-100 collapsed" role="button" aria-expanded="false" v-on:click="newType()">
			                                    AGGIUNGI POSIZIONE
											</a>
										</div>
									</div>
								</form>
							</div>
						</div> 
			        </div>
						
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllTypes" v-on:click="filters.selectAll('chkAllTypes',search.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm">Nome Posizione</th>
										<th class="table-head-sm" style="width:15%;">Sito /<br />Posizione</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <tbody>
									<tr v-for="place in paginatedList.arr" v-bind:key="place.ID_TYPE">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="place.SELEZIONATO" class=""> 
										</td>
										<td class="td-class fs-sm">
											<span>{{ place.ID_TYPE}}</span><br /> 
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer">&nbsp;</small>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="editType(place)">{{place.DESCRIZIONE}}</span><br />	                                   
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="Ente"><i class="fal fa-tag fa-fw"></i> </span> 
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> <a href="#" style="position: relative; top: 1.5px;">{{place.FK_ID_ENTE}}</a></div>
											<div>&nbsp;</div>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateTypeFlgVisibile(place)" v-show="place.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateTypeFlgVisibile(place)" v-show="place.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>		                                
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoType(place)" :size="'sm'" v-model="place.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="editType(place)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Slider</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteType(place)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Slider</a>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">POSIZIONI: {{ paginatedList.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="sliders-b">
				<div class="card shadow mb-0">
					<div class="card-header d-lg-none p-1">
						<div class="d-grid gap-2">
							<a href="#collapse-allb" class="btn btn-flat-info btn-sm collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
								VEDI FILTRI
							</a>
						</div>
					</div>
					<div class="collapse show" id="collapse-allb" style="">				
						<div class="card-body pb-0">
							<div class="alert alert-2 alert-secondary border-0">
								<form autocomplete="off" action="#" id="slider-b">
									<div class="row ">
										<div class="col-5 col-md-1 col-lg-1 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Slider" v-model="searchSlider.ID_SLIDER.VALUE" :tabindex="1" />
										</div>
				                  		<div class="col-5 col-md-2 col-lg-2 mbx-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="searchSlider.FK_ID_TYPE.VALUE" :tabindex="13">
												<option value="">Seleziona Posizione </option>   
												<option v-for="place in arrayTypes" v-bind:key="place.ID_TYPE" v-bind:value="place.ID_TYPE"> &nbsp;{{ place.DESCRIZIONE }} </option>
											</select>
										</div>  	
			                            <div class="col-7 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" v-model="searchSlider.DESCRIZIONE.VALUE" :tabindex="1" />
			                            </div>               
			                            <div class="col-12 col-md-4 col-lg-4 mbx-1">
		
										</div>
										<div class="col-12 col-md-1 col-lg-1">
											<select class="form-select form-select-sm" data-placeholder="Stato" v-model="searchSlider.FLG_ATTIVO.VALUE" :tabindex="13">
												<option value="">Tutti </option>   
												<option value="1"> &nbsp; Attivati</option>
												<option value="0"> &nbsp; Disattivati</option>
											</select>
										</div>
										<div class="col-6 col-md-1 col-lg-2">
			                                <a href="#" class="btn btn-flat-info btn-sm w-100 collapsed" role="button" aria-expanded="false" v-on:click="newSlider()">
			                                    AGGIUNGI IMMAGINE
											</a>
										</div>
									</div>
								</form>
							</div>
						</div> 
			        </div>
						
			        <div class="card-body box-card mt-0 mb-0" id="box-card">
						<div class="tableFixHead" id="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:2%;"> <input id="chkAllSliders" v-on:click="filters.selectAll('chkAllSliders',searchSlider.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width:2%;"> <i class="fa fa-code"></i> </th>
										<th class="table-head-sm" style="width:6%;"> # </th>
										<th class="table-head-sm text-center" style="width:4%;"> Img </th>
										<th class="table-head-sm">Titolo Immagine</th>
										<th class="table-head-sm" style="width:15%;">Posizione /<br />Modello</th>
										<th class="table-head-sm" style="width:20%;">Descrizione</th>
										<th class="table-head-sm text-center" style="width:5%;">Visibile</th>
										<th class="table-head-sm text-center" style="width:5%;">Stato</th>
										<th class="table-head-sm text-center" style="width:5%;">Azioni</th>
									</tr>
								</thead>
		                        <draggable tag="tbody" @end="ordineChangeHandler($event)">
									<tr v-for="slide in paginatedListSlider.arr" v-bind:key="slide.ID_SLIDER">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="slide.SELEZIONATO" class=""> 
										</td>
		                                <td class="td-class text-center">
											<img src="../../assets/images/icons/arrow.png" width="22" height="22" alt="" />
										</td>
										<td class="td-class fs-sm">
											<span>{{slide.ID_SLIDER}}</span><br /> 
											<small class="badge bg-light badge-xs text-muted float-end cursor-pointer">{{ slide.ORDINE}}</small>
										</td>
										<td class="td-class text-center">
											<img v-if="slide.ID_SLIDER > 0 && slide.ICONA != 'placeholder.jpg'" :src="filters.getImgPath('SLIDERS',slide.ICONA)" class="squared" style="height: 40px;" alt="" />
											<span v-if="slide.ICONA == 'placeholder.jpg'" class="btn-nophoto squared fs-sm" style="width: 40px; height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
										<td class="td-class fs-sm">
											<span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="editSlider(slide)">{{slide.DESCRIZIONE}}</span><br />	                                   
											<span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;" data-bs-toggle="tooltip" data-bs-placement="top" :title="Ente"><i class="fal fa-tag fa-fw"></i> {{slide.FK_ID_ENTE}}</span> 
										</td>
										<td class="td-class fs-sm">
                                            <span class="badge bg-light badge-xs text-dark rounded-0 me-1" style="border: 1px #dddddd solid;">MODULO</span><br />
                                            <span class="text-muted">BOTTONE</span>
										</td>
										<td class="td-class fs-sm">
											<div><i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Ultimo accesso: <a href="#"></a></div>
											<div><i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Livello: <a href="#">Admin</a></div>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateSliderFlgVisibile(slide)" v-show="slide.FLG_VISIBILE == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">Pubblico</span>
											<span v-on:click="updateSliderFlgVisibile(slide)" v-show="slide.FLG_VISIBILE == 0" class="badge bg-pink badge-xs text-white cursor-pointer">Admin</span>
										</td>		                                
										<td class="td-class text-center">
											<ISwitch @toggle="updateStatoSlider(slide)" :size="'sm'" v-model="slide.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
										<td class="td-class text-center">
											<div class="dropdown">
												<a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
													<i class="ph-list"></i>
												</a>
												<div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="editSlider(slide)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica Slider</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteSlider(slide)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina Slider</a>
												</div>
											</div>
										</td>
									</tr>
								</draggable>
							</table>
						</div>
		
		                <div class="row page-bottom">
							<div class="col-12 col-md-5 d-none d-sm-block" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">RISULTATI ></span>
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2 ms-2">IMMAGINI: {{ paginatedListSlider.count }}</span> 
                                </div>
                            </div>
							<div class="col-9 col-md-6" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="searchSlider.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListSlider.count" :numpagine="searchSlider.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchSlider.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

		
	<!-- START MODALI -->
	<div class="modal fade" id="popUpType" role="dialog"  aria-modal="true" tabindex="-1">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header bg-light">
					<h5 class="modal-title">Slider</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">
					<div class="form-group">
						<label>Descrizione</label>
						<input type="text" class="form-control" placeholder="Descrizione" v-model="typeSelezionata.DESCRIZIONE">
					</div>
				</div>
				<div class="modal-footer bg-light p-2">
					<button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
					<button type="button" class="btn btn-secondary float-end" v-on:click="saveType()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="popUpSliderChildren" aria-modal="true" role="dialog" tabindex="-1">
	    <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 85% !important;">Scheda: {{sliderSelezionata.DESCRIZIONE}}</h6>   
                    <button type="button" class="btn-close" data-bs-dismiss="modal">X</button>
                </div> 
                <div class="modal-body p-2" style="height: 32rem;"> 

                    <div class="d-lg-flex align-items-lg-start">
                        <div class="sidebar sidebar-component sidebar-expand-lg bg-transparent shadow-none me-lg-3">
                            <div class="sidebar-content">
                                <div class="card sidebar-section-body text-center p-2">
                                    <div class="card-img-actions d-inline-block">
                                        <img v-if="sliderSelezionata.ID_SLIDER > 0 && sliderSelezionata.ICONA != 'placeholder.jpg'" :src="filters.getImgPath('SLIDERS',sliderSelezionata.ICONA)" class="img-fluid squared-circle rounded-0" alt="" width="215" height="150" />
                                        <span v-if="sliderSelezionata.ICONA == 'placeholder.jpg' || sliderSelezionata.ID_SLIDER == 0">
                                            <img src="../../assets/images/placeholders/placeholder.jpg" class="img-fluid rounded-circle" width="150" height="150" alt="">
                                        </span>
                                        <div class="card-img-actions-overlay card-img rounded-circle">
                                            <a href="#" class="btn btn-outline-white btn-icon rounded-pill" v-on:click="uploadImmagineSlider()">
                                                <i class="ph-pencil"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-sidebar" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a href="#profile" id="datiPersonali" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab" v-on:click="tabSelezionato='datiPersonali'">
                                            <i class="ph-user me-2"></i>Dati Personali
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div class="tab-content flex-fill">
                            
                            <div class="tab-pane fade active show" id="profile" role="tabpanel">
                               <h5 class="label-bar"><i class="fad fa-user fa-fw me-1"></i> Dati Personali</h5>
                               <div class="box-utenti"> 
 
                                    
								<div class="row">
						<div class="col-md-6">
							<label class="text-danger custom-control custom-control-right">Posizione</label>
							<select class="form-select form-select-sm" data-placeholder="Limite" v-model="sliderSelezionata.FK_ID_TYPE" :tabindex="20">
								<option value="">Seleziona Posizione</option>
								<option v-for="position in this.arrayTypes" v-bind:key="position.ID_TYPE" v-bind:value="position.ID_TYPE" v-show="position.FLG_ATTIVO == 1">&nbsp;&nbsp;&nbsp;{{position.DESCRIZIONE}}</option>
							</select>
						</div>
						<div class="col-md-6"></div>
					</div>
					
					<hr />

					<div class="row" style="margin-top:10px">
						<div class="col-md-12 form-group">
							<label>Username</label>
							<input v-model="sliderSelezionata.TITOLO" class="input-sm form-control input-sm" placeholder="Username" type="text">
						</div>
						<div class="col-md-12 form-group">
							<label>Password</label>
							<input v-model="sliderSelezionata.SOTTOTITOLO" class="input-sm form-control input-sm" placeholder="Username" type="text">
						</div>
						<div class="col-md-6 form-group">
							<label>Email</label>
							<input v-model="sliderSelezionata.DESCRIZIONE" class="input-sm form-control input-sm" placeholder="Email" type="text">
						</div>
						<div class="col-md-6 form-group" >
							<label>Conferma email</label>
							<input  class="input-sm form-control input-sm" placeholder="Conferma Email" type="text">
						</div>
						<div class="col-md-6 form-group">
							<label>Nome</label>
							<input  class="input-sm form-control input-sm" placeholder="Nome" type="text">
						</div>
						<div class="col-md-6 form-group">
							<label>Cognome</label>
							<input  class="input-sm form-control input-sm" placeholder="Cognome" type="text">
						</div>
					</div>  
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
        
                </div>
                <div class="modal-footer bg-light p-2">
                    <button type="button" class="btn btn-link" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw me-1"></i> Chiudi </button>
                    <button class="btn btn-secondary float-end" v-on:click="saveSlider()"><i class="fal fa-save fa-fw me-1"></i> Salva Modifiche </button>                                   
                </div>                
            </div>
        </div>
    </div>
		    
    <div class="modal fade" :id="'popUpUploadImgStato'+rs" role="dialog" aria-modal="true" tabindex="-1">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-light text-dark">
                    <h6 class="modal-title">Upload immagine Nazione</h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">    
                    <Upload :args="argsSlide" @onsuccess="successCaricamento($event)" @onerror="errorCaricamento($event)"></Upload>
                </div>
                <div class="modal-footer modal-footer-1 bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaFunzionalita()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div> 


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Upload from '@/components/utils/Upload.vue'

export default {
    name:"ConfPlaces",	

    components:{
        Pagine,
        ISwitch,
		SelectLingue,
        draggable: VueDraggableNext,
        Upload,

    },

    data: function () {
        return {   
            apiStartup:'core/slider',
            filters:global.filters,
            GLOBAL_IMAGES_PATH:'',
			rs : utils.generateRandomString(30),
			currentLang:0,
			
			arrayTypes: new Array(),
			typeSelezionata:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_TYPE:{TYPE:'TEXT',VALUE:''},
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
				FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 

            },

			arraySlider: new Array(),
            sliderSelezionata:{},
			searchSlider:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	          	ID_SLIDER:{TYPE:'TEXT',VALUE:''},
				DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
				FK_ID_TYPE:{TYPE:'COMBO',VALUE: ''},
				FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'},

            },
			
            argsSlider:{
                modalita:'MOONLIGHT_uploadImgSlider',
				tipologia:1
            },

        }
		
    },

    methods: {
		getTypes : function () {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayTypes = response.data;
            });
        },

        newType : function(){
            this.typeSelezionata = utils.nuovoElemento(this.typeSelezionata,{ID_TYPE:0,DESCRIZIONE:""},"popUpType");
        },
		
        editType : function(nav){
            this.typeSelezionata = utils.modificaElemento(this.typeSelezionata,nav,"popUpType");
        },

        deleteType : function(nav){
            utils.alert.confirm("Sicuro di voler eliminare la Slider e tutte le sue Immagini?",() => {
                utils.ajax('core/basic/delete',{className:"SliderItem","id" : menu.ID_TYPE}, (response) => {
                    this.getTypes();
                });
            });
        },

        saveType : function(){
            utils.salvaElemento(this.apiStartup,this.typeSelezionata,"ID_TYPE","popUpType",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getTypes();
					this.filters.closePopUp("popUpType");
                }
            });
        },

        updateStatoType : function(type){
            setTimeout(() => {
                utils.ajax("core/slider/stato/update",type, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },

		updateTypeFlgVisibile : function(type){
			type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("core/slider/update",type, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					type.FLG_VISIBILE = type.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},


		//---------//
		getSliderAlls : function(){
			utils.ajax("core/slider/childrens",{}, (response) => {
                this.arraySlider = response.data;
            });
		},

        getSlider : function(slider){
            utils.ajax("core/slider/children",{ID_SLIDER:slider.ID_SLIDER}, (response) => {
                var arr = utils.clone(this.arraySlider); 
				this.ordineHasChanged = false;
                for (var i = 0 ; i < arr.length ; i++){
                    if (arr[i].ID_SLIDER == slider.ID_SLIDER){
                        var item = utils.clone(arr[i]);
                        item.open = true;
                        item.children = response.data.items;
                        for (var j = 0 ; j < item.children.length ; j++){
                            item.children[j].children = item.children[j].items;
                            item.open = true;
                        }
                        arr[i] = item;
                        break;
                    }
                }
                this.arraySlider = arr;
                this.$forceUpdate();
            });
        },

        newSlider : function(){
            this.sliderSelezionata = utils.nuovoElemento(this.sliderSelezionata,{DESCRIZIONE:"",ID_SLIDER:0},"popUpSliderChildren");
        },
		
        editSlider : function(slider){
            if (slider.hasOwnProperty("ID_SLIDER")){
                this.sliderSelezionata = utils.modificaElemento(this.sliderSelezionata,slider,"popUpSliderChildren");
            }
            else{
                this.sliderSelezionata = utils.modificaElemento(this.sliderSelezionata,slider,"popUpSliderChildren");
            }
        },

        deleteSlider : function (slider){
            utils.alert.confirm("Sicuro di voler eliminare la Posizione e tutte le sue Immagini?",() => {
                utils.ajax('core/basic/delete',{className:"SliderItem","id" : slider.ID_SLIDER}, (response) => {
                    this.getSliderAlls();
                });
            });
        },
		
        saveSlider : function(){
			if(this.sliderSelezionata.ID_SLIDER > 0){
                utils.ajax('core/slider/children/update',this.sliderSelezionata, (response) => {
                if (response.esito == 0){
						this.filters.closePopUp("popUpSliderChildren");
                        this.getSliderAlls();
                }
            });
            }
			else {
                utils.ajax('core/menu/children/insert',this.menuSelezionato,(response)=>{
                    if (response.esito == 0){
                        this.filters.closePopUp("popUpSliderChildren");
                        this.getSliderAlls();
                    }
                });
            }
        },

        updateStatoSlider : function(slider){
            setTimeout(() => {
                utils.ajax("core/slider/children/stato/update",slider, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                    }  
                }); 
            },250);
        },

		updateSliderFlgVisibile : function(slider){
			slider.FLG_VISIBILE = slider.FLG_VISIBILE == 1 ? 0 : 1;
			utils.ajax("core/slider/children/update",slider, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					slider.FLG_VISIBILE = slider.FLG_VISIBILE == 1 ? 0 : 1;
				}
			});                
		},
 
		ordineChangeHandler : function(event){
			this.arrayStati = this.filters.draggableChangeHandler(event,this.arraySlider,this.searchSlider,"ID_SLIDER");
			this.ordineHasChanged = true;		
		},	 

		salvaOrdinamento : function(){
			utils.alert.confirm("Sei sicuro di voler salvare l'ordinamento?",()=>{
				var arr = new Array();			
				for (var i = 0 ; i < this.arraySlider.length ; i++){
					arr.push({"ID": this.arraySlider[i].ID_SLIDER,"O": this.arraySlider[i].ORDINE});
				}			
				utils.ajax('core/peso/update',{CLASS:'Stato',items:arr}, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						this.getSliderAlls();
						this.ordineHasChanged = false;
					}
				});
			},this.$root);
		},

        uploadImmagineSlider : function(){
            this.filters.openPopUp("popUpUploadImgStato"+this.rs);
        },

        successCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgStato"+this.rs);
            utils.ajax("core/slider/stato",{ID_ITEM:this.sliderSelezionata.ID_SLIDER}, (response) => {
                this.sliderSelezionata = response.data;
				this.getSlider();
            });
        },

        errorCaricamento:function(){
            this.filters.closePopUp("popUpUploadImgStato"+this.rs);
        },
       

    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayTypes,this.search);
            return arr;
    	},
        paginatedListSlider(){
            var arr = utils.inGrid(this.arraySlider,this.searchSlider);
            return arr;
    	},
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.ordineHasChanged = false;
		this.getTypes();
		this.getSliderAlls();
    },

}
</script>