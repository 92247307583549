<template>
        
    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
                    <a href="#" class="text-dark fw-semibold" v-on:click="getIncassi()"> Contabilità </a> \
                    <span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Incassi</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovoIncasso()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Incasso</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#incassi-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Incassi</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#incassi-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#incassi-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
				</ul>

           		<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i> Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i> Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i> Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i> Activity log
								</a>
								<a href="#" class="dropdown-item" v-on:click="deleteincasso(incasso)">
									<i class="ph-trash me-2"></i> Elimina
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getIncassi()" v-model="search.ANNO.VALUE" :tabindex="4">
	                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
	                        </select>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
	<!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="incassi-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="incasso-a">
		                            <div class="row ">	
	                                    <div class="col-6 col-md-1 col-lg-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Incasso" :tabindex="2" v-model="search.ID_INCASSO.VALUE" />
	                                    </div> 
	                                    <div class="col-6 col-md-1 col-lg-1">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Pnr pratica" :tabindex="3" v-model="search.PNR_FORMATTATO.VALUE"  />
	                                    </div> 
	                                    <div class="col-12 col-md-2 col-lg-2">
	                                        <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Intestatario Pratica" v-model="search.INTESTATARIO.VALUE" :tabindex="4"  />
	                                    </div>	 
	                                    <div class="col-12 col-md-2 col-lg-2">
                                       		<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />
	                                    </div> 
										<div class="col-12 col-md-6 col-lg-6">
	                                        <div class="row">
												<div class="col-12 col-md-4 col-lg-4">
											   </div>
						                        <div class="col-12 col-md-3">
						                        </div>
                                                <div class="col-12 col-md-3">
													<select class="form-select form-select-sm" v-model="search.FK_ID_CONTO.VALUE" data-placeholder="Tipo">
														<option value="">Seleziona Movimento</option>
														<option v-for="opzione in getModalitaIncassoPrincipali()" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA">{{ opzione.DESCRIZIONE }}</option>
													</select>
	                                            </div>
												<div class="col-md-2 col-lg-2">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>  
	                                        </div>
	                                    </div>  
	                                </div>
		                            <div class="collapse" id="collapse-a" style="">
	                                    <div class="row">
											<div class="col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Tipologia booking" tabindex="4" >
													<option v-bind:value="0">Booking</option> 
													<option v-bind:value="1"> - Booking x Agenzia</option> 
													<option v-bind:value="2"> - Booking x Cliente</option> 
													<option v-bind:value="3"> - Agenzia Diretta</option> 
													<option v-bind:value="4"> - Cliente Diretto</option>
												</select>
											</div>
											<div class="col-6 col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Promotore" :tabindex="7">
													<option value="">Promotore </option>   
												</select>
											</div>
											<div class="col-6 col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Agente" :tabindex="5">
													<option>Agente</option>
												</select>
											</div>
											<div class="col-6 col-md-2">
												<select class="form-select form-select-sm" data-placeholder="Sito Web" :tabindex="5">
													<option>Sito</option>
												</select>
											</div>
											<div class="col-6 col-md-4">
											</div>
	                                    </div>
	                                </div>
	                        	</form>
	                        </div>
	                    </div>
	                </div>
		
			        <div class="card-body mt-0 mb-0">
		                <div class="tableFixHead">
	                        <table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
	                                    <th class="table-head-sm" style="width: 2%;"> <input id="chkAllIncassi" v-on:click="filters.selectAll('chkAllIncassi',paginatedList.arr)" type="checkbox" class=""> </th>
	                                    <th class="table-head-sm" style="width: 6%;"> #<br /><span class="float-end">Ord. </span></th>
	                                    <th class="table-head-sm"> Intestatario </th>
										<th class="table-head-sm text-center" style="width: 7%;">Data incasso</th>
	                                    <th class="table-head-sm text-center" style="width: 8%;"> Incasso </th>
										<th class="table-head-sm text-center" style="width: 12%;">Tipo<br />Movimento</th>
	                                    <th class="table-head-sm text-center" style="width: 8%;">Stato</th>
										<th class="table-head-sm text-center" style="width: 12%;">Totale<br />Residuo</th>
	                                    <th class="table-head-sm text-center" style="width: 5%;"> Azioni </th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="incasso in paginatedList.arr" v-bind:key="incasso.ID_INCASSO">
	                                    <td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="incasso.SELEZIONATO" class=""> 
										</td>
	                                    <td class="td-class fs-sm">
											<span v-on:click="search.PNR_FORMATTATO.VALUE = incasso.PNR_FORMATTATO">{{incasso.PNR_FORMATTATO}}</span><br />
											<small class="badge bg-light text-primary float-end">{{incasso.ID_INCASSO}}</small>
										</td>
	                                    <td class="td-class fs-sm">
	                                        <span class="text-primary text-uppercase fw-bold cursor-pointer" v-on:click="search.INTESTATARIO.VALUE = incasso.INTESTATARIO" >{{ incasso.INTESTATARIO }}</span>
		                                    <br/>
		                                    <span v-show="incasso.FK_ID_AZIENDA_AGENZIA > 0" data-popup="tooltip" :title="incasso.AGE_EMAIL" class="cursor-pointer me-2" style="border: 1px #dddddd solid;"><i class="far fa-envelope-open fa-fw"></i> Email</span>
		                                    <span v-show="incasso.FK_ID_AZIENDA_AGENZIA > 0" data-popup="tooltip" :title="incasso.AGE_TELEFONO" class="cursor-pointer" style="border: 1px #dddddd solid;"><i class="far fa-phone-square-alt fa-fw"   ></i> Telefono </span>
											<span v-show="incasso.FK_ID_AZIENDA_AGENZIA == 0 && incasso.INTESTATARIO_FK_ID_PERSONA_FISICA > 0" data-popup="tooltip" :title="incasso.EMAIL" class="cursor-pointer me-2"><i class="far fa-envelope-open fa-fw"></i> Email</span>
		                                    <span v-show="incasso.FK_ID_AZIENDA_AGENZIA == 0 && incasso.INTESTATARIO_FK_ID_PERSONA_FISICA > 0" data-popup="tooltip" :title="incasso.TELEFONO" class="cursor-pointer"><i class="far fa-phone-square-alt fa-fw"   ></i> Telefono </span>
											<span v-show="incasso.FK_ID_AZIENDA_AGENZIA == 0 && incasso.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0" data-popup="tooltip" :title="incasso.AGE_EMAIL" class="cursor-pointer me-2"><i class="far fa-envelope-open fa-fw"></i> Email</span>
		                                    <span v-show="incasso.FK_ID_AZIENDA_AGENZIA == 0 && incasso.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0" data-popup="tooltip" :title="incasso.AGE_TELEFONO" class="cursor-pointer"><i class="far fa-phone-square-alt fa-fw"   ></i> Telefono </span>
	                                    </td>
	                                    <td class="td-class text-center fs-sm">
											{{filters.formatDateTime(incasso.DATA_INCASSO)}}
										</td>
										<td class="text-center">
											<h6 class="fs-sm fw-bold mb-0">
												&euro; {{incasso.IMPORTO}}
											</h6>
										</td> 
										<td class="fs-sm text-center">
											{{incasso.DCP}}<br />
											{{incasso.DCS}}
										</td>
	                                    <td class="fs-sm text-center">
	                                        <span class="badge badge-xs cursor-pointer" v-bind:class="getBtnStatusIncassoClass(incasso)">
												{{getStatusIncasso(incasso)}}
											</span>
	                                    </td> 
	                                    <td class="td-class fs-sm"> 
											<div>
												<i class="ph-check-circle fs-base lh-base align-top text-success me-1"></i> Totale: <a href="#">&euro; {{incasso.PREZZO_TOTALE}}</a>
											</div>
											<div>
												<i class="ph-clock fs-base lh-base align-top text-danger me-1"></i> Residuo: <a href="#">&euro; {{filters.formattaImporto(incasso.PREZZO_TOTALE - incasso.IMPORTO)}}</a>
											</div>
										</td>  
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Pratica</div>
	                                                <a href="#" class="dropdown-item" v-on:click="openOrdine(ordine)"><i class="fad fa-pencil fa-fw ms-2 me-2"></i> Modifica pratica</a>
	                                                <a href="#" class="dropdown-item" v-on:click="storicoOrdine(ordine)"><i class="fad fa-list fa-fw ms-2 me-2"></i> Storico pratica</a>
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Stampe</div>
	                                                <a href="#" class="dropdown-item" v-on:click="stampaRiepilogo(ordine)"><i class="fad fa-envelope fa-lg fa-fw ms-2 me-2"></i> Riepilogo email</a>
	                                                <a href="#" class="dropdown-item" v-on:click="stampePratica(ordine,1001)"><i class="fad fa-file-invoice-dollar fa-lg fa-fw ms-2 me-2"></i> Estratto conto</a>
	                                                <a href="#" class="dropdown-item" v-on:click="stampePratica(ordine,1002)"><i class="fad fa-file-contract fa-lg fa-fw ms-2 me-2"></i> Contratto viaggio</a>                                        
	                                                <a href="#" class="dropdown-item" v-on:click="deletePratica(ordine)"><i class="fad fa-trash-alt fa-fw text-danger ms-2 me-2"></i> Gestione documenti</a>                                        
	                                            </div>
	                                        </div>
	                                    </td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	
		                <div class="row page-bottom">
                            <div class="col-8 col-md-6" :tabindex="27">
                                <div style="position: relative; top: 10px;">
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">LORDO: {{ filters.formattaImporto(getTotali.LORDO) }}</span> - 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">COMM: {{ filters.formattaImporto(getTotali.COMM )}}</span> = 
                                    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-0 p-2">NETTO: {{filters.formattaImporto(getTotali.NETTO)}}</span>
                                </div>
                            </div>
							<div class="col-8 col-md-5" :tabindex="27">
	                            <div class="float-end">
		                            <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
	                            </div>
	                        </div>
		                    <div class="col-4 col-md-1" :tabindex="28">
		                        <select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
	                                <option value="5">5</option>
	                                <option value="10">10</option>
	                                <option value="25">25</option>
	                                <option value="50">50</option>
	                                <option value="75">75</option>
	                                <option value="100">100</option>
	                                <option value="250">250</option>
	                                <option value="500">500</option>
	                            </select>
	                        </div>
	                    </div>
	                </div>
	            </div>
			</div>		

			<div class="tab-pane fade" id="incassi-b">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body card-body-2">
	
							<div class="row">
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="me-3 align-self-center">
												<i class="icon-file-stats icon-3x text-success"></i>
											</div>
			
											<div class="media-body text-right">
												<h3 class="font-weight-semibold mb-0">&euro; 652,549</h3>
												<span class="text-uppercase fs-sm text-muted">Incassi totale</span>
											</div>
										</div>
									</div>
								</div>
			
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="me-3 align-self-center">
												<i class="icon-coin-euro icon-3x text-indigo"></i>
											</div>
			
											<div class="media-body text-right">
												<h3 class="font-weight-semibold mb-0">&euro; 245,382</h3>
												<span class="text-uppercase fs-sm text-muted">Incassi ricevuti</span>
											</div>
										</div>
									</div>
								</div>
			
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="media-body">
												<h3 class="font-weight-semibold mb-0">&euro; 54,390</h3>
												<span class="text-uppercase fs-sm text-muted">Incassi in attesa</span>
											</div>
			
											<div class="ms-3 align-self-center">
												<i class="icon-calendar52 icon-3x text-primary"></i>
											</div>
										</div>
									</div>
								</div>
			
								<div class="col-sm-6 col-xl-3">
									<div class="card card-body card-2">
										<div class="media">
											<div class="media-body">
												<h3 class="font-weight-semibold mb-0">&euro; 389,438</h3>
												<span class="text-uppercase fs-sm text-muted">Incassi stornati</span>
											</div>
			
											<div class="ms-3 align-self-center">
												<i class="icon-blocked icon-3x text-danger"></i>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

		</div>
	</div>


	<!-- Modal -->
    <div class="modal fade" id="popUpCatturaOrdine" role="dialog"  aria-modal="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> IMPORTA DATI NEL GESTIONALE </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">  

                    <p class="text-center" style="font-size: 14px;">
                        <span id="modal_status_subtitle">sei sicuro di voler importare questa Pratica nel tuo Gestionale?</span> <br> 
                        <span id="modal_status_accept">Proseguendo, la Pratica e le relative Quote, saranno importate nel tuo Gestionale.</span>
                    </p> 
                    <hr> 
                    <div class="row">
                        <div class="col-md-6">
                            <h6><strong>1.</strong> Seleziona Gestionale</h6> 
                            <div class="form-group">
                                <select name="order_status" class="form-control form-control-sm" disabled="disabled">
                                    <option value="easy">Cattura Easy</option>
                                </select>
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <h6><strong>2.</strong> Scegli Operazione</h6>
                            <div class="form-group">
                                <select class="form-control form-control-sm" disabled="disabled">
                                    <option value="Set_Pratica">Importa pratica</option> 
                                    <option value="Set_Quota">Importa quota</option> 
                                    <option value="Set_Incassi">Importa incasso</option> 
                                    <option value="anagrafica">Importa anagrafica</option> 
                                    <option value="voucher">Importa voucher</option>
                                </select>
                            </div>
                        </div> 
                    </div>

                </div>
                <div class="modal-footer bg-light">
                    Vuoi Procedere&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">NO ESCI</button>&nbsp;
                    <button type="button" class="btn btn-success btn-sm">SI IMPORTA</button><!--  v-on:click="sendStatus()" -->
                </div>
            </div>
        </div>
    </div>  


	<div class="modal fade" id="popUpIncasso" role="dialog"  aria-modal="true" tabindex="-1" >
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Intestatario </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
					 
                    		<td class="text-center">
                                <Datepicker :disabled="true" v-model="incassoSelezionato.DATA_INCASSO" model-type="dd/MM/yyyy HH:mm" :format="'dd/MM/yyyy HH:mm'" auto-apply></Datepicker>                       
                            </td> 
                            <td class="text-center">
                                <input class="form-control form-control-sm" type="text" v-model="incassoSelezionato.IMPORTO" data-placeholder="Importo">
                            </td>  
                            <td class="text-center">
                                <select class="form-control form-control-sm" v-model="incassoSelezionato.FK_ID_CONTO" data-placeholder="Tipo">
                                    <option value="0">Seleziona Movimento</option>
                                    <option v-for="opzione in getModalitaIncassoPrincipali()" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA" v-bind:value="opzione.ID_OPZIONE_TIPOLOGICA">{{ opzione.DESCRIZIONE }}</option>
                                </select>
                                <select class="form-control form-control-sm" :disabled="!incassoSelezionato.FK_ID_CONTO > 0" v-model="incassoSelezionato.FK_ID_SUB_MODALITA_INCASSO" data-placeholder="Tipo">
                                    <option value="0">Seleziona Pagamento</option>
                                    <option v-for="conto in getSubModalitaIncasso(incassoSelezionato)" v-bind:key="conto.ID_OPZIONE_TIPOLOGICA" v-bind:value="conto.ID_OPZIONE_TIPOLOGICA">{{ conto.DESCRIZIONE }}</option>
                                </select>
                            </td> 
                            <td class="text-center">
                                <textarea class="form-control" aria-multiline="true" v-model="incassoSelezionato.NOTE"  placeholder="Inserisci una nota"></textarea>                   
                            </td> 
                            
                </div>
                <div class="modal-footer bg-light">
                    Vuoi Procedere&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-success btn-sm" v-on:click="salvaIncasso()">Salva</button>&nbsp;&nbsp;<!--  v-on:click="sendStatus()" -->
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>
                </div>
            </div>
        </div>
    </div>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'

export default {
    name:"Incassi",

    components:{
        Pagine,
        Datepicker,
    },

    data:function(){
        return {
            apiStartup:'travel/incassi',
            filters:global.filters,
			anno:new Date().getFullYear(),
            arrayIncassi:new Array(),
            incassoSelezionato:{},
			periodoSelezionato:{},
			dataCreazioneRange:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
				ANNO:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_INCASSO:{TYPE:'TEXT',VALUE:''},
				PNR_FORMATTATO:{TYPE:'TEXT',VALUE:''},
                FK_ID_CONTO:{TYPE:'COMBO',VALUE:''},
				DATA_INCASSO:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
	            INTESTATARIO:{TYPE:'TEXT',VALUE:''}, 
                //FK_ID_SUB_MODALITA_INCASSO:{TYPE:'COMBO',VALUE: 0},
				/*

                PNR:{TYPE:'TEXT',VALUE:''},
                CODICE_ORDINE:{TYPE:'TEXT',VALUE:''},
	            TITOLO_PRATICA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				*/
	        },
        }
    },

    methods:{
        getIncassi : function(){
            utils.ajax(this.apiStartup,{ANNO:this.search.ANNO.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayIncassi = response.data;
					for (var i = 0 ; i < this.arrayIncassi.length ; i++) {
						this.arrayIncassi[i].PNR_FORMATTATO = this.filters.getPnr(this.arrayIncassi[i]);
					}					
                }
            });  
        },

        openIncassi : function(ordine){
            this.$refs.schedaIncasso.openIncasso(ordine);
        },


        nuovaQuota : function(id){
            this.$root.incassoSelezionato = utils.clone(this.incasso);
            global.router.push("Quota");
        },

		datePickerChangeHandler : function(){
            this.search.DATA_INCASSO = {TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''};
            if (this.dataCreazioneRange != null && this.dataCreazioneRange.length > 0){
                this.search.DATA_INCASSO = {TYPE:'DATE_RANGE',VALUE_FROM:this.filters.formatDate(this.dataCreazioneRange[0]),VALUE_TO:this.filters.formatDate(this.dataCreazioneRange[1])};
            }
        },

        getBtnStatusClass : function(incasso){
            switch (incasso.FK_ID_STATO){
                case 1:
                    return 'badge-success'
                case 2:
                    return 'badge-primary'
                case 3:
                    return 'badge-info'
				case 4:
                    return 'badge-warning'
                case 5:
                    return 'badge-yellow'
                case 6:                
                    return "badge-secondary";
                case 7:
                case 8:
                    return 'badge-danger'
                default :
                    return 'badge-light'
            }
        },

        getTipoBook : function(incasso){
            var arr = utils.array.select(this.$root.arrayPraticaBook,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_TIPO,1);
            if (arr.length > 0){
                return arr[0].CUSTOM_1;
            }
            return "";
    	},

        getTipoBookClass : function(incasso){
            switch (incasso.FK_ID_TIPO){
                case 1:
                    return "badge-danger";
                case 2:
                    return "badge-info";                   
                case 3:
                    return "badge-warning";
                case 4:
                    return "badge-primary";                   
            }
            return "";
        },

        getSubModalitaIncasso : function(incasso){
            if (incasso.FK_ID_CONTO > 0){
                var principale = utils.array.select(this.$root.arrayConti,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_CONTO,1)[0];
                if (principale == undefined){
                    return new Array();
                }
                var arr = utils.array.select(this.$root.arrayConti,"CUSTOM_2",principale.CUSTOM_2);
                if (incasso.FK_ID_SUB_MODALITA_INCASSO == undefined){
                    incasso.FK_ID_SUB_MODALITA_INCASSO = 0;//arr[0].ID_OPZIONE_TIPOLOGICA;
                }
                else{
                    var check = utils.array.select(arr,"ID_OPZIONE_TIPOLOGICA",incasso.FK_ID_SUB_MODALITA_INCASSO,1);
                    if (check.length == 0){
                        incasso.FK_ID_SUB_MODALITA_INCASSO = 0;//arr[0].ID_OPZIONE_TIPOLOGICA;
                    }
                }                
                return arr;
            }
            return new Array();
        },	   

        getModalitaIncassoPrincipali : function(){
            var arr = new Array();
            for (var i = 0 ; i< this.$root.arrayConti.length ; i++){
                if (this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 46 || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 47 || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 48  || this.$root.arrayConti[i].ID_OPZIONE_TIPOLOGICA == 49 ){
                    arr.push(utils.clone(this.$root.arrayConti[i]));
                }
            }
            return arr;
        },

		getBtnStatusIncassoClass : function(incasso){
			switch (incasso.STATO_INCASSO){
                case 0:
                    return "badge-danger";
                case 1:
                    return "badge-indigo";
                case 2:
                    return "badge-success";                 
            }
        },

		getStatusIncasso : function(incasso){
			switch (incasso.STATO_INCASSO){
                case 0:
                    return "DA SALDARE";
                case 1:
                    return "ACCONTO";
                case 2:
                    return "SALDATO";                 
            }
        },

        getDateRange : function(incasso){
            $('.daterange-predefined').daterangepicker(
                {
                    startDate: moment().subtract(29, 'days'),
                    endDate: moment(),
                    minDate: '01/01/2014',
                    maxDate: '12/12/2019',
                    dateLimit: { days: 60 },
                    timePicker: true,
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                },
                function(start, end) {
                    $('.daterange-predefined span').html(start.format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + end.format('MMMM D, YYYY'));
                    $.jGrowl('Date range has been changed', { header: 'Update', theme: 'bg-primary text-white', position: 'center', life: 1500 });
                }
            );

            // Display date format
            $('.daterange-predefined span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + moment().format('MMMM D, YYYY'));
        },

        openSchedaOrdine : function(incasso){
            this.$refs.schedaOrdine.openSchedaOrdine(incasso);
        }, 

		openIncasso : function(incasso){
			this.incassoSelezionato = utils.clone(incasso);
			this.incassoSelezionato.DATA_INCASSO = this.filters.formatDateTimeDp(this.incassoSelezionato.DATA_INCASSO);
			this.filters.openPopUp("popUpIncasso");
		},	

		salvaIncasso : function(){
			utils.ajax("travel/incasso/update",this.incassoSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
					this.getIncassi();
					this.filters.closePopUp("popUpIncasso");									
                }
            });  
		}

    },

    computed: {
        paginatedList(){
			var arr = utils.inGrid(this.arrayIncassi,this.search);
	        return arr;

            if (this.search.FK_ID_STATO.VALUE == 0){
                var arr = utils.clone(this.arrayIncassi);
                for (var i = arr.length -1 ; i > -1 ; --i){
                    if (arr[i].FK_ID_STATO > 5){
                        arr.splice(i,1);
                    }
                }
                return utils.inGrid(arr,this.search);
            }
            else{
	            var arr = utils.inGrid(this.arrayIncassi,this.search);
	            return arr;
            }            
        },
        getTotali(){
            var filtrato = global.utils.filter(this.arrayIncassi,this.search);
            var lordo = 0;
            var comm = 0;
            for (var i = 0 ; i < filtrato.length ; i++){
                lordo += parseFloat(filtrato[i].PREZZO_TOTALE);
                comm += parseFloat(filtrato[i].COMMISSIONI);
            }
            return {
                LORDO:lordo,
                COMM:comm,
                NETTO:lordo-comm,
                COUNT:filtrato.length
            }
        }
    }, 

    created : function(){
		/*
		if (this.$root.incassoSelezionato.ID_INCASSO > 0){
			this.search.FK_ID_INCASSO.VALUE = this.$root.incassoSelezionato.ID_INCASSO;
		}*/
        this.getIncassi();
    }
}
</script>