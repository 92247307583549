<template>
    <span v-html="$root.translate(path)"></span>
</template>

<script>
export default {
    name:"Translate",
    props:["path"],
    data : function(){
        return {}
    },
}
</script>