<template>

    <div id="popUpSchedaProdottoLang" class="modal fade">
        <div class="modal-dialog modal-medium">
            <div class="modal-content">
                <div class="modal-header modal-header-2 bg-info text-white">
                    <h6 class="modal-title pt-1"> SCHEDA contenuti prodotto  - {{prodotto.lang.TITOLO}}</h6>
                    <div class="btn-group float-end">
                        <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-bolt fa-fw"></i> PERIODO
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-print"></i> Stampa scheda </a>
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-envelope"></i> Invia comunicazione </a> 
                            <a href="#" class="dropdown-item">&nbsp;&nbsp;&nbsp;<i class="fal fa-paper-plane"></i> Invia sms </a> 
                        </div>
                    </div>
                </div>
                <div class="modal-body-2">
                    
                    <div class="row">
                        <div class="col-lg-12">                        
                            <ul class="nav nav-tabs mb-2 nav-tabs-highlight" id="myTab" role="tablist">
		                        <li class="nav-item">
		                            <a class="nav-link active" id="intro-tab" data-bs-toggle="tab" href="#intro" role="tab" aria-controls="intro" aria-selected="true">
                                        <i class="far fa-info-square fa-fw"></i> <span class="d-none d-sm-inline"><strong>Intro </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="body-tab" data-bs-toggle="tab" href="#body" role="tab" aria-controls="body" aria-selected="false">
                                        <i class="far fa-h-square fa-fw"></i> <span class="d-none d-sm-inline"><strong>Desc. </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="include-tab" data-bs-toggle="tab" href="#include" role="tab" aria-controls="include" aria-selected="false">
                                        <i class="far fa-thumbs-up fa-fw"></i> <span class="d-none d-sm-inline"><strong>Q. Inc. </strong></span>
		                            </a>
		                        </li>
		                        <li class="nav-item">
		                            <a class="nav-link" id="exclude-tab" data-bs-toggle="tab" href="#exclude" role="tab" aria-controls="exclude" aria-selected="false">
                                        <i class="far fa-thumbs-down fa-fw"></i> <span class="d-none d-sm-inline"><strong>Q. Esc. </strong></span>
		                            </a>
		                        </li>
		                        <li class="nav-item">
		                            <a class="nav-link" id="inloco-tab" data-bs-toggle="tab" href="#inloco" role="tab" aria-controls="inloco" aria-selected="false">
                                        <i class="far fa-map-marker-check fa-fw"></i> <span class="d-none d-sm-inline"><strong>In Loco </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="orari-tab" data-bs-toggle="tab" href="#orari" role="tab" aria-controls="orari" aria-selected="false">
                                        <i class="far fa-clock fa-fw"></i> <span class="d-none d-sm-inline"><strong>Orari </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="extra-tab" data-bs-toggle="tab" href="#extra" role="tab" aria-controls="extra" aria-selected="false">
                                        <i class="far fa-layer-plus fa-fw"></i> <span class="d-none d-sm-inline"><strong>Suppl. </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="policy-tab" data-bs-toggle="tab" href="#policy" role="tab" aria-controls="policy" aria-selected="false">
                                        <i class="far fa-user-secret fa-fw"></i> <span class="d-none d-sm-inline"><strong>Policy </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="voucher-tab" data-bs-toggle="tab" href="#voucher" role="tab" aria-controls="voucher" aria-selected="false">
                                        <i class="far fa-file-invoice fa-fw"></i> <span class="d-none d-sm-inline"><strong>Vouch. </strong></span>
		                            </a>
		                        </li> 
		                        <li class="nav-item">
		                            <a class="nav-link" id="note-tab" data-bs-toggle="tab" href="#note" role="tab" aria-controls="note" aria-selected="false">
                                        <i class="far fa-clipboard fa-fw"></i> <span class="d-none d-sm-inline"><strong>Note </strong></span>
		                            </a>
		                        </li>
		                        <li class="nav-item">
		                            <a class="nav-link" id="notes-tab" data-bs-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">
                                        <i class="far fa-user-shield fa-fw"></i> <span class="d-none d-sm-inline"><strong>Admin </strong></span>
		                            </a>
		                        </li>    
		                    </ul>
                    		<div class="tab-content profile-tab" id="myTabContent">

                                <!--
		                        <div class="tab-pane fade show active" id="intro" role="tabpanel" aria-labelledby="intro-tab">
                                    <div class="box_450">
                                        <div class="alert alert-info alert-styled-left alert-dismissible">
                                            <button type="button" class="close" data-dismiss="alert"><span>×</span></button>
                                            <span class="font-weight-semibold">Heads up!</span> This alert needs your attention, but it's not <a href="#" class="alert-link">super important</a>.
                                        </div>
                                        <ckeditor :editor="editor" v-model="testo" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="body" role="tabpanel" aria-labelledby="body-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo1" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="include" role="tabpanel" aria-labelledby="include-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo2" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="exclude" role="tabpanel" aria-labelledby="exclude-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo3" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="inloco" role="tabpanel" aria-labelledby="inloco-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo4" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="orari" role="tabpanel" aria-labelledby="orari-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo5" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="extra" role="tabpanel" aria-labelledby="extra-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo6" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="policy" role="tabpanel" aria-labelledby="policy-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo7" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="voucher" role="tabpanel" aria-labelledby="voucher-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo8" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="note" role="tabpanel" aria-labelledby="note-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo9" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                                    <div class="box_450">
                                        <ckeditor :editor="editor" v-model="testo10" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>
                            -->

							</div>
						</div>
					</div>
                </div>
                <div class="modal-footer mt-2 pt-2" style="border: 1px solid #eee;">
                    <button class="btn btn-success float-end" v-on:click="salvaSchedaProdottoLang()"> SALVA MODIFICHE </button> 
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">CHIUDI</button>&nbsp;&nbsp;&nbsp;                                         
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import SchedaProdottoLang from '@/components/SchedaProdottoLang.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



export default {
    name:"SchedaProdottoLang",
    props:["modulo"],
    components:{
    },
    data:function(){
        return{
            prodotto : {lang:{}},
            lang:{},
			filters:global.filters,
            /*
            testo: "ciao mamma guarda come mi 0",
            testo1: "ciao mamma guarda come mi 1",
            testo2: "ciao mamma guarda come mi 2",
            testo3: "ciao mamma guarda come mi 3",
            testo4: "ciao mamma guarda come mi 4",
            testo5: "ciao mamma guarda come mi 5",
            testo6: "ciao mamma guarda come mi 6",
            testo7: "ciao mamma guarda come mi 7",
            testo8: "ciao mamma guarda come mi 8",
            testo9: "ciao mamma guarda come mi 9",
            testo10: "ciao mamma guarda come mi 10",*/
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            }
        }
    },
    components:{
        ckeditor: ClassicEditor.component,
    },
    props: ['todo'],

    methods:{
        openSchedaProdottoLang: function(agency){
            this.filters.openPopUp('#popUpSchedaProdottoLang');
            
            if (prodotto.FK_ID_PRODOTTO == 0){
                utils.ajax("travel/prodotto",{FK_ID_PRODOTTO:prodotto.FK_ID_PRODOTTO}, (response) => {
                    this.prodotto = response.data;
                    this.filters.openPopUp('#popUpSchedaProdotto');
                    this.getRoomTypes();
                });
            }

        },
        salvaSchedaProdottoLang : function(){
            /*
            var api = this.agency.ID_AGENCY > 0 ? "travel/agency/update" : "travel/agency/insert";
            utils.ajax(api,this.agency, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    if (response.data != undefined){
                        this.$emit("updateGriglia",response.data);
                    }
                }
            });
            */
        }
    },
    computed:{
        
    }

}
</script>