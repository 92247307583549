<template>
  	<div>

	    <!-- Page header -->
	    <div class="page-header page-header-light">
	        <div class="page-header-content header-elements-lg-inline">
	            <div class="page-title d-flex">
	                <h4 class="d-none d-sm-block">
	                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
						<a href="#" class="d-inline-block text-dark"><span class="font-weight-semibold"> Preferenze </span></a> \
	                    <span class="text-muted">Configurazione Email </span>
						<code> - Lingua italiano</code>
	                </h4>
	                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Configurazione Email </h4>
	                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
	            </div>
	            <div class="header-elements d-none">
	                <div class="d-flex justify-content-center">
	                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
	                </div>
	            </div>
	        </div>
	    </div>
	    <!-- /page header -->
	    
	    <!-- Content area -->
	    <div class="content content-2">
	
	       <div class="card shadow mb-0">
                <div class="card-header card-header-2 bg-transparent header-elements-sm-inline py-sm-0">
                    <h6 class="card-title py-sm-2 d-none d-sm-block">  
                        <div class="btn-group position-static">
                            <div class="alert alert-2 alert-secondary alert2 border-0">
                                <span style="position:relative; top:-5px;">I campi contrassegnati in <span class="text-danger">rosso</span> sono <strong>Obbligatori</strong>. 
                                Inserire dati corretti e veritieri, la maggior parte sono pubblicati su documenti fiscali.</span>	                                
                            </div>
                        </div>
                    </h6>
	                <div class="header-elements">
	                    <div class="list-icons">
	                        <a class="list-icons-item" data-action="collapse"></a>
	                    </div>
	           		</div>
	        	</div>
	            <div class="collapse show">
		            <div class="card-body card-body-2">
                        <div class="row">
                            <div class="col-lg-8">
                                <fieldset>
                                    <legend class="font-weight-semibold p-2 mb-2"><i class="icon-wrench2 me-2"></i> Dati principali</legend>
                                    <div class="row" v-if="arrayOpzioniConfigurazione.length > 0">
                                        <div v-for="opzione in arrayOpzioniConfigurazione[0].opzioni" v-bind:key="opzione.LABEL" class="form-group-1" v-bind:class="opzione.COL">
                                            <span class="label" v-html="opzione.ETICHETTA"></span>
                                            <IControl :campo="opzione" v-model="opzione.VALORE" :inputgroup="opzione.GROUP" :icon="opzione.ICON"></IControl>
                                            </div>
                                        </div> 
                                    <hr class="hr-1" />

                                </fieldset>
                            </div>
                            <div class="col-lg-4">
                                <fieldset>
                                    <legend class="font-weight-semibold p-2 mb-2"><i class="icon-cog3 me-2"></i> Dati Configurazione</legend>
                                    <div class="box_600" style="min-height: 33.5rem; max-height: 33.5rem;">
                                        <div class="row mt-2">                         
                                            <div class="col-md-12">
                                                <div class="card-group-control card-group-control-right" id="accordion-control-right">
                                                    <div class="card" v-for="gruppo in arrayOpzioniConfigurazione" v-bind:key="gruppo.id">
                                                        <div class="card-header card-header-1" :id="'heading_'+gruppo.id" v-if="gruppo.id > 1">
                                                            <h6 class="card-title">
                                                                <a class="collapsed text-body" data-bs-toggle="collapse" :href="'#accordion-control-'+gruppo.id"><i class="fa-fw" v-bind:class="gruppo.icona"></i> 
                                                                    {{gruppo.titolo}}
                                                                </a>
                                                            </h6>
                                                        </div>
                                                        <div :id="'accordion-control-'+ gruppo.id" class="collapse" data-parent="#accordion-control-right" :aria-labelledby="'heading_'+ gruppo.id">
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div v-for="opzione in gruppo.opzioni" v-bind:key="opzione.LABEL" class="form-group-1" v-bind:class="opzione.COL">
                                                                        <label>{{opzione.ETICHETTA}}</label>
                                                                        <IControl :campo="opzione" v-model="opzione.VALORE" :inputgroup="opzione.GROUP" :icon="opzione.ICON"></IControl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                        
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <hr class="hr-2" />
                        <div class="row mt-0 mb-2">
                            <div class="col-lg-12">
                                <div class="text-right">
                                    <button type="submit" class="btn btn-primary" v-on:click="salvaOpzioni()"><i class="icon-floppy-disk me-2"></i> Salva Modifiche </button>
                                </div>
                            </div>
                        </div>
	                </div>
	            </div>
	       </div>
	    </div>

	</div>
</template>



<script>
import IControl from '@/components/utils/IControl.vue'

export default {
    name:"ConfOwner",
    components:{
        IControl,

    },

    data: function () {
        return {   
            arrayFunzionalita: new Array(),
             searchFunzionalita:{

            },
            idEnteCorrente:0,
            arrayOpzioniConfigurazione:new Array(),
            arrayOpzioniConfigurazioneDefault : new Array(
                {
                    titolo:"Generali",
					icona:'',
                    id:1,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:300, ETICHETTA:'Alias Azienda',                            LABEL:'RAG_NAME',                       TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-industry'},
                        {ID_OPZIONE_BASE:301, ETICHETTA:'Ragione Sociale',                          LABEL:'RAG_SOC',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-industry-alt'},
                        {ID_OPZIONE_BASE:302, ETICHETTA:"Nazionalità",                              LABEL:'NATION',                         TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-flag'},                       
                        {ID_OPZIONE_BASE:303, ETICHETTA:'Indirizzo',                                LABEL:'ADDRESS',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-map'},
                        {ID_OPZIONE_BASE:304, ETICHETTA:'Civico',                                   LABEL:'CIVIC',                          TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-align-center'},                        
                        {ID_OPZIONE_BASE:305, ETICHETTA:'Cap',                                      LABEL:'CAP',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-link'},
                        {ID_OPZIONE_BASE:306, ETICHETTA:'Città',                                    LABEL:'CITY',                           TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-city'},
                        {ID_OPZIONE_BASE:307, ETICHETTA:'Provincia',                                LABEL:'PROV',                           TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-circle'},
                        {ID_OPZIONE_BASE:308, ETICHETTA:'Email',                                    LABEL:'EMAIL',                          TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6 ',             CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:309, ETICHETTA:'Email certificata',                        LABEL:'PEC',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-certificate'},                        
                        {ID_OPZIONE_BASE:310, ETICHETTA:'Telefono Ufficio',                         LABEL:'OFFICE_PHONE',                   TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-phone'},
                        {ID_OPZIONE_BASE:311, ETICHETTA:'Codice SDI',                               LABEL:'SDI',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-code'},                     
                        {ID_OPZIONE_BASE:312, ETICHETTA:'Partita IVA',                              LABEL:'IVA',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-balance-scale'},                                            
                        {ID_OPZIONE_BASE:313, ETICHETTA:'Codice fiscale',                           LABEL:'CF',                             TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-3',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-barcode'},                                            
                    
                    
                    )
                },
                {
                    titolo:"Societario",
                    icona:'far fa-globe',
                    id:2,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:320, ETICHETTA:'Amministratore Societario',                LABEL:'CEO',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-user-cog'},
                        {ID_OPZIONE_BASE:321, ETICHETTA:'Licenza Turistica',                        LABEL:'LICENCE',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-file-alt'},
                        {ID_OPZIONE_BASE:322, ETICHETTA:'REA',                                      LABEL:'REA',                            TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-brackets-curly'},
                        {ID_OPZIONE_BASE:323, ETICHETTA:'Capitale Sociale',                         LABEL:'CAPITAL',                        TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-money'},
                        {ID_OPZIONE_BASE:324, ETICHETTA:'Fondo di garanzia',                        LABEL:'GARANTY_FOUND',                  TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-project-diagram'},
                        {ID_OPZIONE_BASE:325, ETICHETTA:'Assicurazione',                            LABEL:'INSURANCE',                      TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-shield-check'},
                    )
                },
                {
                    titolo:"Email",
                    icona:'far fa-paper-plane',
                    id:3,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:330, ETICHETTA:'Email Amministrazione',                    LABEL:'EMAIL_ADMIN',                    TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:331, ETICHETTA:'Email Documenti',                          LABEL:'EMAIL_DOCS',                     TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:332, ETICHETTA:'Email Gruppi',                             LABEL:'EMAIL_GROUP',                    TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:333, ETICHETTA:'Email Commerciale',                        LABEL:'EMAIL_COMMERCIAL',               TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:334, ETICHETTA:'Email Booking',                            LABEL:'EMAIL_BOOKING',                  TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:335, ETICHETTA:'Email Booking 2',                          LABEL:'EMAIL_BOOKING2',                 TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:336, ETICHETTA:'Email Registrazione Clienti',              LABEL:'EMAIL_CLIENT',                   TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:337, ETICHETTA:'Email Registrazione Agenzie',              LABEL:'EMAIL_ADV',                      TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:338, ETICHETTA:'Email Preventivi Clienti',                 LABEL:'EMAIL_REQUEST_CLIENT',           TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                        {ID_OPZIONE_BASE:339, ETICHETTA:'Email Preventivi Agenzie',                 LABEL:'EMAIL_REQUEST_ADV',              TYPE:"TEXT",    VALORE:'-', COL:'col-12',                       CLASSE_CTRL:'',   GROUP:1,    ICON: 'far fa-envelope'},
                    )
                },
                {
                    titolo:"Assistenza",
                    icona:'far fa-life-ring',
                    id:4,
                    opzioni: new Array(
                        {ID_OPZIONE_BASE:340, ETICHETTA:'Titolo',                                   LABEL:'ASSISTANCE_TITLE',               TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',    GROUP:1,    ICON: 'far fa-ballot-check'},
                        {ID_OPZIONE_BASE:341, ETICHETTA:'Data prossima scadenza',                   LABEL:'ASSISTANCE_DATA',                TYPE:"TEXT",    VALORE:'-', COL:'col-12 col-md-6',              CLASSE_CTRL:'',    GROUP:1,    ICON: 'far fa-calendar'},
                        {ID_OPZIONE_BASE:342, ETICHETTA:'Messaggio canone assistenza',              LABEL:'ASSISTANCE_MESSAGE',             TYPE:"TEXTAREA",VALORE:'-', COL:'col-12'},
                    )
                },
                        
            ),
        }
    },

    methods: {
        getOpzioni : function(){
            utils.ajax('core/admin/opzioni',{ID_ENTE:this.idEnteCorrente}, (response) => {
                var arrayOpzioni = response.data;
                this.arrayOpzioniConfigurazione = utils.clone(this.arrayOpzioniConfigurazioneDefault);
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                        this.arrayOpzioniConfigurazione[i].opzioni[j].CLASSE_CTRL = 'input-sm';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].VMODEL = 'VALORE';
                        this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = 0;
                        for (var k = arrayOpzioni.length-1 ; k > -1  ; --k){
                            if (this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE_BASE == arrayOpzioni[k].ID_OPZIONE_BASE){
                                this.arrayOpzioniConfigurazione[i].opzioni[j].VALORE = arrayOpzioni[k].VALORE;
                                this.arrayOpzioniConfigurazione[i].opzioni[j].ID_OPZIONE = arrayOpzioni[k].ID_OPZIONE;
                                arrayOpzioni.splice(k,1);
                                break;
                            }
                        }
                    }
                }
                //AGGIUNGO LE OPZIONI CUSTOM DEL SITO
                for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                    if (this.arrayOpzioniConfigurazione[i].id == 100){
                        for (var j = 0 ; j < arrayOpzioni.length ; j++){
                            this.arrayOpzioniConfigurazione[i].opzioni.push({LABEL:arrayOpzioni[j].LABEL,VALORE:arrayOpzioni[j].VALORE,TYPE:'TEXTAREA',ID_OPZIONE:arrayOpzioni[j].ID_OPZIONE});
                        }
                        this.$forceUpdate();
                        break;
                    }
                }
            });
        },
        salvaOpzioni : function(){
            var arr = new Array();
            for (var i = 0 ; i < this.arrayOpzioniConfigurazione.length ; i++){
                for (var j = 0 ; j < this.arrayOpzioniConfigurazione[i].opzioni.length ; j++){
                    arr.push(this.arrayOpzioniConfigurazione[i].opzioni[j]);
                }
            }
            utils.ajax('core/admin/opzioni/update',{opzioni:arr,ID_ENTE:this.idEnteCorrente}, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getOpzioni();
                }
            });
        },
        getConfigurazioniEnte : function(){
            this.getOpzioni();
        }

        },

    computed: {
        uploadDisabled() {
            return this.files.length === 0;
        }
    },

    beforeCreate : function(){
        setTimeout(() => {
            global.utils.deltaGriglia = 100;   
            global.utils.resizeContent();     
        }, 300);
    },
    
    unmounted : function(){
       global.utils.deltaGriglia = 0;
    },

    created: function () {
        this.idEnteCorrente = this.$root.idEnteCorrente;
                        this.getOpzioni();
    },
}
</script>