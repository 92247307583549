<template>
        
	<!-- Page header -->
	<div class="page-header page-header-light">
		<div class="page-header-content header-elements-lg-inline">
			<div class="page-title d-flex">
				<h4 class="d-none d-sm-block">
					<router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" v-on:click="startup()" class="d-inline-block text-dark"> Pratiche </a> \
					<span class="text-muted">Disponibilit&agrave; prodotto</span>
					<code> - Lingua italiano</code>
				</h4>
				<h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Disponibilit&agrave; </h4>
				<a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
			</div>
			<div class="header-elements d-none">
				<div class="d-flex justify-content-center">
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoPreventivo()"><i class="icon-search4 text-success"></i><span>CERCA</span></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoPreventivo()"><i class="icon-googleplus5"></i><span>ORDINE</span></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoPreventivo()"><i class="icon-balance"></i><span>PREVENTIVO</span></a>
					<a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoPreventivo()"><i class="icon-users"></i><span>GRUPPI</span></a>
					<a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i><span>HELP</span></a>
				</div>
			</div>
		</div>
	</div>
	<!-- /page header -->

	<!-- Content area -->
	<div class="content content-2">

		<div class="mb-3 py-2">
			<h4 class="font-weight-semibold mb-0">Browse articles by category</h4>
			<span class="text-muted d-block">Yikes snuffed goat macaw overlay much and goodness and alas kookaburra a goldfish</span>
		</div>

		<div class="row">
			<div class="col-lg-3">
				<div class="card">
					<div class="card-body text-center">
					<i class="icon-search4 icon-2x text-primary border-primary border-3 rounded-pill p-3 mb-3 mt-1"></i>
						<h5><a href="#" class="btn btn-primary" v-on:click="openSearchBox(1)">Cerca</a></h5>
						<p class="mb-3">Ouch found swore much dear conductively hid submissively hatchet vexed far inanimately alongside candidly much and jeez</p>
						
					</div>
				</div>
			</div>

			<div class="col-lg-3">
				<div class="card">
					<div class="card-body text-center">
						<i class="icon-googleplus5 icon-2x text-success border-success border-3 rounded-pill p-3 mb-3 mt-1"></i>
						<h5><a href="#" class="btn btn-success" v-on:click="openSearchBox(2)">Crea Ordine</a></h5>
						<p class="mb-3">Dear spryly growled much far jeepers vigilantly less and far hideous and some mannishly less jeepers less and and crud</p>
						
					</div>
				</div>
			</div>

			<div class="col-lg-3">
				<div class="card">
					<div class="card-body text-center">
						<i class="icon-balance icon-2x text-warning border-warning border-3 rounded-pill p-3 mb-3 mt-1"></i>
						<h5><a href="#" class="btn btn-warning" v-on:click="openSearchBox(3)">Crea Preventivos</a></h5>
						<p class="mb-3">Diabolically somberly astride crass one endearingly blatant depending peculiar antelope piquantly popularly adept much</p>
						
					</div>
				</div>
			</div>
			
			<div class="col-lg-3">
				<div class="card">
					<div class="card-body text-center">
					<i class="icon-users icon-2x text-indigo border-indigo border-3 rounded-pill p-3 mb-3 mt-1"></i>
						<h5><a href="#" class="btn btn-indigo" v-on:click="openSearchBox(4)">Pratica Gruppi</a></h5>
						<p class="mb-3">Ouch found swore much dear conductively hid submissively hatchet vexed far inanimately alongside candidly much and jeez</p>
						
					</div>
				</div>
			</div>
		</div>

	</div>


	<!-- Basic modal -->
	<div id="PopUpSearch" class="modal fade" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-secondary text-white">
					<h6 class="modal-title">Large modal</h6>
					<button type="button" class="close" data-bs-dismiss="modal">X</button>
				</div>

				<div class="modal-body">
	
					<h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 2rem;">
						<strong>1.</strong> Dati intestatario 
						<div class="dropdown float-end position-static" style="position: relative !important; top: -5px;">
							<button class="btn btn-warning btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fal fa-user fa-fw"></i> CLIENTE </button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenu2" style="">
								<button class="dropdown-item" type="button"><i class="fa-fw fal fa-search"></i> Privato</button>
								<button class="dropdown-item" type="button"><i class="fa-fw fal fa-search"></i> Aziendale</button>
							</div>
						</div>
						<button class="btn btn-warning btn-sm me-3 float-end" style="position: relative; top: -4px;"><i class="fal fa-store-alt fa-fw"></i> AGENZIA </button>
					</h3>
					<div class="row">
						<div class="col-2">
							<div class="profile-image text-center">
								<img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt="" />
							</div>
						</div>
						<div class="col-10">
							<div class="row">
								<div class="col-2 form-group">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="Mr" readonly />
								</div>
								<div class="col-5 form-group">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="Accastelli" readonly />
								</div>
								<div class="col-5 form-group">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="Andrea Maria" readonly />
								</div>
							</div>
							<div class="row">
								<div class="col-3">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="Italiana" readonly />
								</div>
								<div class="col-3">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="1967-04-21" readonly />
								</div>
								<div class="col-3">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="ahacca1967@gmail.com" readonly />
								</div>
								<div class="col-3">
									<input autocomplete="off" type="text" class="form-control form-control-sm" value="3357600883" readonly />
								</div>
							</div>

						</div>
					</div>
					
					<hr />

					<h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 2rem;">
						<strong>2.</strong> Ricerca 
					</h3>

					<ul class="nav nav-tabs nav-tabs-highlight mb-0">
						<li class="nav-item"><a href="#search-a-1" class="nav-link active" data-bs-toggle="tab"><i class="icon-office me-2"></i>Hotels</a></li>
						<li class="nav-item"><a href="#search-a-2" class="nav-link" data-bs-toggle="tab"><i class="icon-bag me-2"></i>Pacchetti</a></li>
						<li class="nav-item"><a href="#search-a-3" class="nav-link" data-bs-toggle="tab"><i class="icon-airplane3 me-2"></i>Solo Volo</a></li>
						<li class="nav-item"><a href="#search-a-4" class="nav-link" data-bs-toggle="tab"><i class="icon-direction me-2"></i>Tour</a></li>
						<li class="nav-item"><a href="#search-a-5" class="nav-link" data-bs-toggle="tab"><i class="icon-bus me-2"></i>Transfer</a></li>
						<li class="nav-item"><a href="#search-a-6" class="nav-link" data-bs-toggle="tab"><i class="icon-boat me-2"></i>Crociere</a></li>
						<li class="nav-item"><a href="#search-a-7" class="nav-link" data-bs-toggle="tab"><i class="icon-train2 me-2"></i>Treni</a></li>
					</ul>

					<div class="tab-content card card-body border-top-0 rounded-top-0 mb-0">
						<div class="tab-pane fade show active" id="search-a-1">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>

						<div class="tab-pane fade" id="search-a-2">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>

						<div class="tab-pane fade" id="search-a-3">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>

						<div class="tab-pane fade" id="search-a-4">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>

						<div class="tab-pane fade" id="search-a-5">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>

						<div class="tab-pane fade" id="search-a-6">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>

						<div class="tab-pane fade" id="search-a-7">

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Eugene" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="Kopyov" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="eugene@kopyov.com" class="form-control">
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-lg-4">
									<div class="form-group">
										<input type="text" placeholder="+99-99-9999-9999" class="form-control">
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<select data-placeholder="Select your country" class="form-control form-control-select2 select2-hidden-accessible" data-fouc="" data-select2-id="28" tabindex="-1" aria-hidden="true">
											<option data-select2-id="30"></option>
											<option value="Cambodia">Cambodia</option> 
											<option value="Cameroon">Cameroon</option> 
											<option value="Canada">Canada</option> 
											<option value="Cape Verde">Cape Verde</option> 
										</select><span class="select2 select2-container select2-container--default" dir="ltr" data-select2-id="29" style="width: 100%;"><span class="selection"><span class="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-64r9-container"><span class="select2-selection__rendered" id="select2-64r9-container" role="textbox" aria-readonly="true"><span class="select2-selection__placeholder">Select your country</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
									</div>
								</div>

								<div class="col-lg-4">
									<div class="form-group">
										<button type="submit" class="btn btn-primary">Submit form <i class="icon-paperplane ms-2"></i></button>
									</div>
								</div>
							</div>

						</div>
					</div>
												
				</div>

			</div>
		</div>
	</div>


</template>



<script>

//import Ricerca from '@/components/Ricerca.vue'



export default {
    name:'PreQuota',

    components: {
        //Ricerca,

    },

    data:function(){
		return{
			filters:global.filters,
			
			search:{
					PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:1000},	          	
					STATO_DISPONIBILITA:{TYPE:'COMBO',VALUE:''},
					ORDINAMENTO:{TYPE:'COMBO',VALUE:''},
			},
		}
    },

    methods:{
		
		openSearchBox : function(flg_search){
			this.filters.openPopUp("PopUpSearch");
		},

    },

	computed : {
		
	
	},

    created : function () {

	}
}
</script>


<style scoped>

</style>