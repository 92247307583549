<template>

    <!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
                    <router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="text-dark fw-semibold" v-on:click="getExtra()"><Translate :path="'dizionario.base.ELENCO'"></Translate> Extra </a> \
                    <span class="text-muted">Nome Prodotto</span>
                </h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
            </div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
   
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="openExtra({ID_EXTRA:0,FK_ID_PRODOTTO_PRINCIPALE:prodotto.ID_PRODOTTO})" title="Aggiungi Extra" data-bs-toggle="tooltip" data-bs-placement="left">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Extra</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-secondary btn-icon w-32px h-32px squared-pill me-2" v-on:click="$root.confTravelTipologiche(6)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-equalizer"></i>
						</a>
						<a href="#" class="btn btn-outline-secondary btn-icon w-32px h-32px squared-pill me-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-flag4"></i>
						</a>
						<a href="#" class="btn btn-outline-pink btn-icon w-32px h-32px squared-pill" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7 pink"></i>
						</a>
	                </div>
	            </div>
	        </div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#extra-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Extra</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#extra-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
				</ul>



				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item dropdown-toggle"><i class="far fa-check-square fa-fw me-2"></i><span class="text-muted me-1">|</span>Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Attiva seleionati</a>
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Disattiva seleionati</a>
									</div>
								</div>
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item dropdown-toggle"><i class="far fa-file-excel fa-fw me-2"></i><span class="text-muted me-1">|</span>Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Utenti seleionati</a>
										<a href="#" class="dropdown-item"><i class="ph-image me-2"></i>Tutti gli Utenti</a>
									</div>
								</div>
								<a href="#" class="dropdown-item"><i class="far fa-users fa-fw me-2"></i><span class="text-muted me-1">|</span>Merge Utente</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="far fa-trash fa-fw me-2 text-danger"></i><span class="text-muted me-1">|</span>Elimina</a>
							</div>
						</li>
						<li class="nav-item ms-3">
							<select class="form-select form-select-sm changeyear" id="comboAnno" @change="getExtra()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
	                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
	                        </select>
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

    </div>
    <!-- /page header -->

    <!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="extra-a">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="extra-a">
		                            <div class="row">
		                                <div class="col-6 col-md-1 col-lg-1 mbx-1">
		                                    <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id Extra" v-model="search.ID_EXTRA.VALUE" :tabindex="6" />
		                                </div> 
		                                <div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Tipologia Extra" v-model="search.DESC_TIPOLOGIA_PRODOTTO_PRINCIPALE.VALUE" :tabindex="6" />
		                                </div> 
		                                <div class="col-6 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Extra" v-model="search.TITOLO.VALUE" :tabindex="6" />
		                                </div>  
		                                <div class="col-12 col-md-2 col-lg-2 mbx-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Prodotto" v-model="search.DESC_PADRE.VALUE" :tabindex="6" />
		                                </div>	 
										<div class="col-12 col-md-6 col-lg-5 mbx-1">
											<div class="row">
												<div class="col-md-4 col-lg-4"></div>
												<div class="col-md-3 col-lg-3">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_OBBLIGATORIO.VALUE">
														<option value="">Tipo </option>   
														<option value="1"> &nbsp; Obbligatori</option>
														<option value="0"> &nbsp; Facoltativi</option>
													</select>													
												</div>
												<div class="col-3 col-md-3">
													<select class="form-select form-select-sm" data-placeholder="Stato" :tabindex="7" v-model="search.FLG_ATTIVO.VALUE">
														<option value="">Stato </option>   
														<option value="1"> &nbsp; Attivati</option>
														<option value="0"> &nbsp; Disattivati</option>
													</select>
												</div>
												<div class="col-md-2 col-lg-2">
													<a href="#collapse-a" class="btn btn-secondary btn-sm w-100 collapsed" data-bs-toggle="collapse" role="button" aria-expanded="false">
														+ FILTRI
													</a>
												</div>  
											</div>
                                        </div>
                                    </div>  
								</form>
		                    </div>
		                </div>
		            </div>
				
			        <div class="card-body box-card mt-0 mb-0">
						<div class="tableFixHead">
		                    <table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
		                                <th class="table-head-sm" style="width: 2%;"> <input id="chkAllExtra" v-on:click="filters.selectAll('chkAllExtra',paginatedList.arr)" type="checkbox" class=""> </th>
		                                <th class="table-head-sm" style="width: 6%;"> # </th>
		                                <th class="table-head-sm text-center" style="width:4%;"> Img </th>
		                                <th class="table-head-sm" style="width: 22%;">Nome Extra<br />Codice / Visibile / Comm. / Stampa</th>
		                                <th class="table-head-sm">Prodotto associato</th>
		                                <th class="table-head-sm" style="width: 13%;">Applicabile/<br />Pax</th>
		                                <th class="table-head-sm" style="width: 8%;">Costo /<br />Markup / Fisso </th>
		                                <th class="table-head-sm" style="width: 13%;">Date inizio /<br />Data fine </th>
										<th class="table-head-sm text-center" style="width:5%;">Richiesto</th>
		                                <th class="table-head-sm text-center" style="width: 5%;">Stato</th>
		                                <th class="table-head-sm text-center" style="width: 5%;">Azioni</th>
		                            </tr>
		                        </thead>
		                        <tbody>
		                            <tr v-for="extra in paginatedList.arr" v-bind:key="extra.ID_EXTRA">
		                                <td class="td-class text-center">
											<span class="footable-toggle"></span>
		                                    <input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="extra.SELEZIONATO" class="">
		                                </td>
                                        <td class="td-class fs-sm">
											<span>{{extra.ID_EXTRA}}</span><br />
											<small class="badge bg-light text-primary float-end"> &nbsp; </small>
										</td>
										<td class="td-class text-center"> 
											<img v-if="extra.ID_EXTRA > 0 && extra.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('EXTRA',extra.URL_AVATAR)" class="rounded-circle" style="width: 40px; height: 40px;" alt="" />
											<span v-if="extra.URL_AVATAR == 'placeholder.jpg'" class="btn-nophoto rounded-circle fs-sm" style="width: 40px; height: 40px !important; line-height: 10px; padding-top: 8px; font-size: 0.6rem !important;">No<br />Photo</span>
										</td>
		                                <td class="td-class fs-sm">
                                            <span class="text-primary fw-bold text-uppercase cursor-pointer" v-on:click="openSchedaProdotto({ID_PRODOTTO:extra.FK_ID_PRODOTTO})">{{extra.TITOLO}}</span><br/>
											<span class="badge bg-light badge-xs text-dark rounded-0 me-2" style="border: 1px solid rgb(221, 221, 221);"><i class="far fa-code fa-fw"></i> {{extra.CODICE_PRODOTTO}}</span>
											<span class="badge bg-info badge-xs text-white rounded-0 cursor-pointer me-2"><i class="fal fa-tag fa-fw"></i> Visibile</span>
											<span class="badge bg-success badge-xs text-white rounded-0 cursor-pointer me-2"><i class="far fa-thumbs-up fa-fw"></i> Comm.</span>											
											<span class="badge bg-light badge-xs text-dark rounded-0 cursor-pointer" style="border: 1px solid rgb(221, 221, 221);"><i class="far fa-print fa-fw"></i> Voucher</span>
		                                </td>
                                        <td class="td-class fs-sm">
											<span class="badge bg-light border-start border-width-3 text-body rounded-start-0 border-info cursor-pointer" v-on:click="openSchedaProdotto({ID_PRODOTTO:extra.FK_ID_PRODOTTO_PRINCIPALE})">{{ extra.DESC_PADRE }}</span><br/>
											<span>{{ extra.DESC_TIPOLOGIA_PRODOTTO_PRINCIPALE }}</span>
                                        </td>
                                        <td class="td-class fs-sm">
                                            <span>{{extra.MODALITA_DI_CALCOLO}}</span><br />
											<span class="text-muted">{{extra.PAX_TYPE}}</span>
                                        </td>
                                        <td class="td-class text-center fs-sm">
                                            <span>&euro; {{extra.VALORE}}</span><br />
											<span class="text-muted"></span>
                                        </td>
                                        <td class="td-class fs-sm">
											<div>
												<i class="ph-calendar fs-base lh-base align-top text-success me-1"></i> Data Inizio: <a href="#">{{filters.formatDate(extra.DATA_START)}}</a>
											</div>
											<div>
												<i class="ph-calendar fs-base lh-base align-top text-danger me-1"></i> Data Fine: <a href="#">{{filters.formatDate(extra.DATA_END)}}</a>
											</div>
										</td>
										<td class="td-class fs-sm text-center">
											<span v-on:click="updateFlgRichiesto(extra)" v-show="extra.FLG_OBBLIGATORIO == 1" class="badge bg-indigo badge-xs text-white cursor-pointer">SI</span>
											<span v-on:click="updateFlgRichiesto(extra)" v-show="extra.FLG_OBBLIGATORIO == 0" class="badge bg-pink badge-xs text-white cursor-pointer">NO</span>											
										</td>
		                                <td class="td-class text-center fs-sm">
		                                    <ISwitch @toggle="updateStatoExtra(extra)" :size="'sm'" v-model="extra.FLG_ATTIVO" style="z-index: 0;"></ISwitch>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
													<a href="#" class="dropdown-item" v-on:click="openExtra(extra)"><i class="fad fa-pencil fa-fw me-2"></i><span class="text-muted me-1">|</span>Modifica extra</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="deleteExtra(extra)"><i class="fad fa-trash fa-fw text-danger me-2"></i><span class="text-muted me-1">|</span>Elimina extra</a>
	                                            </div>
	                                        </div>
		                                </td>
		                            </tr>
							   	</tbody>
		                    </table>
		                </div>
		
		                <div class="row page-bottom">
							<div class="col-9 col-md-11" :tabindex="27">
		                        <div class="float-end">
			                        <Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
                            	</div>
			                </div>
							<div class="col-3 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
		                            <option value="5">5</option>
		                            <option value="10">10</option>
		                            <option value="25">25</option>
		                            <option value="50">50</option>
		                            <option value="75">75</option>
		                            <option value="100">100</option>
		                            <option value="250">250</option>
		                            <option value="500">500</option>
		                        </select>
		                	</div>
		            	</div>
		
		        	</div>
		    	</div>
			</div>

			<div class="tab-pane fade" id="extra-b">
        		<div class="card shadow mb-0">
		            <div class="collapse show">
						<div class="card-body">

						</div>
					</div>
	  			</div>
 			</div>
		</div>		
	</div>


	<!-- START MODALI -->
    <div class="modal fade" id="popUpExtra" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 80% !important;"> Scheda Extra </h6>                                                        
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body"> 

					<div class="row">
						<div class="col-6 col-lg-4 mb-2" v-show="$root.prodotto.ID_PRODOTTO == 0 && extraSelezionato.ID_EXTRA == 0">
							<button class="btn btn-info" v-on:click="openPopUpProdotti()">
								<span v-show="getProdottiSelezionati.length == 0">Seleziona prodotti</span>
								<span v-show="getProdottiSelezionati.length > 0">Hai selezionato {{ getProdottiSelezionati.length }} prodotti - rivedi</span>
							</button>	
						</div>  
					</div>

					<div class="row">
						<div class="col-6 col-lg-4 mb-2">
							<label class="form-label text-danger mb-1">Prodotto</label>
							<Select2 v-model="extraSelezionato.FK_ID_PRODOTTO" :w="250" :api="'travel/prodotto/extra/s2'" :apiselected="'travel/prodotto/extra/s2/element'" :tabindex="6"></Select2>   
						</div>    
					</div>

					<div class="row">	
						<div class="col-6 col-lg-4 mb-2">
							<label class="form-label text-danger mb-1">Valido dal</label>
							<Datepicker  v-model="extraSelezionato.DATA_START" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :tabindex="7"></Datepicker>
						</div>	
						<div class="col-6 col-lg-4 mb-2">
							<label class="form-label text-danger mb-1">Valido al</label>
							<Datepicker  v-model="extraSelezionato.DATA_END" model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" :tabindex="7"></Datepicker>
						</div>	
					</div>	

					<div class="row mb-2">
						<div class="col-6 col-md-4">
							<label class="text-danger custom-control custom-control-right">Tipo di calcolo</label>
							<select v-model="extraSelezionato.TIPO_CALCOLO" class="form-select form-select-sm" @change="extraSelezionato.MODALITA_DI_CALCOLO = ''">
								<option value="">Seleziona</option>
								<option value="PREZZO">Prezzo</option>
								<option value="MARKUP">Markup</option>
								<option value="PRICE_GAIN">Price Gain</option>
							</select>
						</div>
						<div class="col-6 col-md-4">
							<label class="text-danger custom-control custom-control-right">Modalità di calcolo</label>
							<select v-model="extraSelezionato.MODALITA_DI_CALCOLO" class="form-select form-select-sm" :disabled="extraSelezionato.TIPO_CALCOLO == ''">
								<option value="">Seleziona</option>
								<option value="TOTALE_A_PERSONA" v-show="extraSelezionato.TIPO_CALCOLO != 'PRICE_GAIN'">Totale a persona</option>
								<option value="NOTTE_A_PERSONA"  v-show="extraSelezionato.TIPO_CALCOLO != 'PRICE_GAIN'">Notte a persona</option>
								<option value="TOTALE_A_SERVIZIO">Totale a servizio</option>
								<option value="NOTTE_A_SERVIZIO">Notte a servizio</option>
							</select>
						</div>
						<div class="col-6 col-md-4">
							<label class="text-danger custom-control custom-control-right">Valore</label>
							<input type="text" class="form-control form-control-sm" v-model="extraSelezionato.VALORE">
						</div>
					</div>

					<div class="row">
						<div class="col-6 col-md-4">
							<label class="text-danger custom-control custom-control-right">Tipo PAX</label>
							<select v-model="extraSelezionato.PAX_TYPE" class="form-select form-select-sm" :disabled="extraSelezionato.TIPO_CALCOLO == ''">
								<option value="ALL">QUALSIASI</option>								
								<option value="ADU">ADU</option>
								<option value="CHD">CHD</option>
								<option value="INF">INF</option>
							</select>
						</div>
						<div class="col-6 col-md-4">
							<label class="text-danger custom-control custom-control-right">Tipo PAX</label>
							<select v-model="extraSelezionato.FLG_OBBLIGATORIO" class="form-select form-select-sm">
								<option value="1">OBBLIGATORIO</option>
								<option value="0">FACOLTATIVO</option>
							</select>
						</div>
						<div class="col-6 col-md-4">

						</div>
					</div>

                </div>
                <div class="modal-footer bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaExtra()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                </div>
            </div>
        </div>
    </div>

	<div class="modal fade" id="popUpProdotti" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-secondary text-white border-0">
                    <div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 80% !important;"> Scheda Extra </h6>                                                        
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div> 
                <div class="modal-body"> 

                    <div class="row">
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <label class="form-label">ID:</label>
                                    <div class="input-group">
                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="searchProdotti.ID_PRODOTTO.VALUE" placeholder="Id Prodotto">
                                    </div>
                                </div>  
                                <div class="col-md-3">
                                    <label class="form-label">Tipo Modulo:</label>
									<select class="form-select form-select-sm mb-1" v-model="searchProdotti.FK_ID_MODULO.VALUE" placeholder="Tipo Modulo">
										<option value="">Tutto</option>
										<option value="1">Attività</option>
										<option value="2">Hotel</option>
										<option value="3">Pacchetti</option>
										<option value="4">Voli</option>
									</select>
                                </div>
                                <div class="col-md-3">
                                    <label class="form-label">Nome Prodotto:</label>
                                    <div class="input-group">
                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-model="searchProdotti.TITOLO.VALUE" placeholder="Nome Prodotto">
                                    </div>
                                </div> 
                            </div>   
							<div class="col-md-3">

							</div>
                        </div>
                    </div>  

                    <hr class="hr-1" />
 
                    <div class="tableFixHead" style="max-height: 30.6rem !important;" v-show="arrayProdotti.length > 0">
                        <table class="table table-bordered table-hover table-striped table-togglable"> 
                            <thead class="sticky-top">
                                <tr class="bg-info text-white">
									<th class="table-head-sm" style="width: 2%;"> </th>
									<th class="table-head-sm text-center" style="width:10%;"> Id </th>
									<th class="table-head-sm text-center" style="width:7%;"> Img </th>
                                    <th class="table-head-sm">Titolo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="p in paginatedListProdotti.arr" v-bind:key="p.ID_PRODOTTO">
									<td class="td-class text-center">
										<span class="footable-toggle"></span>
										<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="p.SELEZIONATO" class="">
									</td>
									<td class="td-class text-center fs-sm"><span class="badge bg-indigo bg-opacity-20 text-indigo badge-xs">{{p.ID_PRODOTTO}}</span></td>
									<td class="td-class text-center"> 
										<i class="fal fa-fw fa-lg" v-bind:class="filters.iconaProdotto(p.FK_ID_MODULO)"></i>
									</td>
                                    <td class="fs-sm text-uppercase">
										<span v-show="p.FK_ID_MODULO == 5" class="text-primary fw-bold me-2">{{p.TITOLO_PADRE}} - </span>
										<span class="text-primary fw-bold">{{p.TITOLO}}</span>
									</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="modal-footer bg-light">
					<div class="row page-bottom float-start">
						<div class="col-9 col-md-11" :tabindex="27">
							<div class="float-end">
								<Pagine v-model="searchProdotti.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListProdotti.count" :numpagine="searchProdotti.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
							</div>
						</div>
						<div class="col-3 col-md-1" :tabindex="28">
							<select class="form-select form-select-sm wmin-100 mt-2 float-end" data-placeholder="Limite" :tabindex="20" v-model="searchProdotti.PAGINATION.PAGE_SIZE" style="position: relative; top: 0px; padding: 0.4375rem 0.5rem;">
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="75">75</option>
								<option value="100">100</option>
								<option value="250">250</option>
								<option value="500">500</option>
							</select>
						</div>
					</div>

                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>
                </div>
            </div>
        </div>
    </div>
	

    <SchedaVisualizzazioneProdotto :ref="'schedaVisualizzazioneProdotto'"></SchedaVisualizzazioneProdotto>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker'
import SchedaVisualizzazioneProdotto from '@/components/SchedaVisualizzazioneProdotto.vue'


export default {
    name:"Extra",	  

    components:{
        Pagine,
        ISwitch,
        Select2,
        Datepicker,
        SchedaVisualizzazioneProdotto,


    },			 

    emits:['selezionaProdotto'],
    
    data:function(){
        return {
            apiStartup:'travel/prodotto/extra',
            filters:global.filters,
			GLOBAL_IMAGES_PATH:'',
			anno:new Date().getFullYear(),
            arrayExtra:new Array(),
			prodotto:{},
			extraSelezionato:{},
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_EXTRA:{TYPE:'TEXT',VALUE:''},
				TITOLO : {TYPE:'TEXT',VALUE:''},
				DESC_PADRE : {TYPE:'TEXT',VALUE:''},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
				FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
				FLG_OBBLIGATORIO:{TYPE:'COMBO',VALUE:''}, 	
				DESC_TIPOLOGIA_PRODOTTO_PRINCIPALE:{TYPE:'TEXT',VALUE:''}, 			
	        },
			ordineHasChanged: false,
			arrayProdotti : new Array(),
			searchProdotti:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
	            ID_PRODOTTO:{TYPE:'TEXT',VALUE:''},
				TITOLO : {TYPE:'TEXT',VALUE:''},
				FK_ID_MODULO:{TYPE:'COMBO',VALUE:''}, 
	        },

        }
    },

    methods:{
        getExtra:function(){
            utils.ajax(this.apiStartup,{ID_PRODOTTO:this.prodotto.ID_PRODOTTO}, (response) => {	
			   	this.ordineHasChanged = false;
                if (response.esito == 0){
                    this.arrayExtra = response.data;                   
                }
            });  
        },

		openExtra : function(_extra){
			if (_extra.ID_EXTRA > 0){
				utils.ajax("travel/prodotto/extra/singolo",{ID_EXTRA:_extra.ID_EXTRA}, (response) => {	
					this.extraSelezionato = utils.clone(response.data);
					this.filters.openPopUp("popUpExtra");			   	
				}); 
			}
			else{
				this.arrayProdotti = new Array();
				this.extraSelezionato = utils.clone(_extra);
				this.filters.openPopUp("popUpExtra");	
			}
		},

		salvaExtra : function(){
			var api = this.extraSelezionato.ID_EXTRA == 0 ? 'travel/prodotto/extra/insert' : 'travel/prodotto/extra/update';
			if (this.$root.prodotto.ID_PRODOTTO == 0){
				this.extraSelezionato.arrayProdottiDestinazione = this.getProdottiSelezionati;
			}
			utils.ajax(api,this.extraSelezionato, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
					this.filters.closePopUp("popUpExtra");
					this.getExtra();
                }
            }); 
		},

		updateFlgRichiesto : function(extra){
			extra.FLG_OBBLIGATORIO = extra.FLG_OBBLIGATORIO == 1 ? 0 : 1;
			extra.FK_ID_TIPO = -6;
			utils.ajax("travel/prodotto/extra/update",extra, (response) => {
				if (response.esito == 0){
					utils.alert.success(response.messaggio);
				}
				else{
					extra.FLG_OBBLIGATORIO = extra.FLG_OBBLIGATORIO == 1 ? 0 : 1;
				}
			});                
		},	 

		updateStatoExtra : function(extra){
            setTimeout(() => {
                utils.ajax("travel/prodotto/extra/update",extra, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getExtra();
                    }
                });                
            },250)
        }, 

        deleteExtra : function(t){
            utils.alert.confirm("Sicuro di voler eliminare l'Extra " + t.NOME + "?",()=>{
                utils.ajax('core/basic/delete',{className:'Extra',id:t.ID_EXTRA}, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.getExtra();
                    }
                });
            });
        },
        
        esportazione : function(formato){
            utils.report.token({modalita:'esportaExtra',formato:formato});
        }, 

		openSchedaProdotto : function(prodotto){
            this.$refs.schedaVisualizzazioneProdotto.openSchedaProdotto(prodotto);
        },

		openPopUpProdotti : function(){
			this.filters.openPopUp("popUpProdotti");
			if (this.arrayProdotti.length == 0){
				utils.ajax('travel/prodotti/light',{}, (response) => {
					this.arrayProdotti = response.data;
				});
			}
		}




    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayExtra,this.search);            
            return arr;
        },
		paginatedListProdotti(){
            var arr = utils.inGrid(this.arrayProdotti,this.searchProdotti);            
            return arr;
        },
		getProdottiSelezionati:function(){
			return utils.array.select(this.arrayProdotti,"SELEZIONATO",1);
		}

    }, 

    created : function(){
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.ordineHasChanged = false;
        this.prodotto = utils.clone(this.$root.prodotto);
        this.getExtra();
    }

}
</script>