<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="d-inline-block text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
					<a href="#" class="d-inline-block text-dark" v-on:click="getUtenti()"> Gestione Logs </a> 
					 - <code>italiano</code>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>	 
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
					</div>
				</div>
			</div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#logs-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2">
									 <Translate :path="'dizionario.base.ELENCO'"></Translate> Accessi
								</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#logs-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-calendar"></i>
								<span class="d-none d-lg-inline-block ms-2">
									<Translate :path="'dizionario.base.ELENCO'"></Translate> Errori
								</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#logs-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2">
									<Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate>
								</span>
							</div>
						</a>
					</li>
				</ul>

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item ms-3">

						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->
	
	<!-- Content area -->
    <div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="logs-a">
				<div class="card shadow mb-0">
					<div class="collapse show">
						<div class="card-body card-body-2">
							<div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="utente-a">
									<div class="row "> 
										<div class="col-12 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Username" :tabindex="2" v-model="search.USERNAME.VALUE">
										</div>
										<div class="col-12 col-md-2">
											<Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']" v-model="search.DATA_ACCESSO.VALUE"></Datepicker>
			
			
			
			
										</div>	  
										<div class="d-none d-sm-block col-md-6">
										</div>
										<div class="col-12 col-md-1">
											<select class="form-control form-control-sm" data-placeholder="Seleziona Anno">
												<option>Anno</option>
			
											</select>
										</div>
										<div class="col-md-1">
											<a class="btn btn-secondary btn-sm btn-block" v-on:click="getAccessi()">
												<span style="font-weight: 500;">Aggiorna</span>				
											</a>          
										</div>  
			
			
									</div>
								</form>
							</div>
						</div> 
					</div>
					</div>
				
			        <div class="card-body card-body-notop">
						<div class="tableFixHead">
		               		<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead>
									<tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width:10%;"><span >Utente</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Data e ora</span></th>
										<th class="table-head-sm" style=""><span>Session Id</span></th>
										<th class="table-head-sm" style="width:10%;"><span>Ip</span></th>
										<th class="table-head-sm" style="width:5.4%;">Azioni</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="accesso in paginatedList.arr" v-bind:key="accesso.ID_ACCESSO">
										<td style=""><span class="badge badge-success">{{accesso.USERNAME}}</span></td>
										<td style=""><span class="text-primary fw-bold cursor-pointer">{{filters.formatDateTime(accesso.DATA_ACCESSO)}}</span></td>
										<td style=""><span>{{accesso.SESSION_ID}}</span></td>
										<td style=""><span>{{accesso.INDIRIZZO_IP}}</span></td>
										<td class="text-center">
											<div class="list-icons" :tabindex="26">
												<div class="dropdown">
													<a href="#" class="list-icons-item" data-bs-toggle="dropdown" v-on:click="openStackTrace(accesso)"><i class="icon-menu9"></i></a>
													<div class="dropdown-menu dropdown-menu-right">
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>     
						</div>
					</div>

				<div class="row">
					<div class="col-md-11" :tabindex="27">
						<div class="float-end"> 
							<Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH"></Pagine>
						</div>
					</div>
					<div class="col-md-1" :tabindex="28">
						<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
							<option value="5">5</option>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="75">75</option>
							<option value="100">100</option>
							<option value="250">250</option>
							<option value="500">500</option>
						</select>
					</div>
				</div>

			</div>	
		</div>	

	</div>

	<!-- Modal -->
	<div class="modal fade" id="popUpStackTrace" tabindex="-1" role="dialog"  aria-modal="true" >
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header modal-header-2 bg-danger text-white">
					<h6 class="modal-title">Log dettaglio Accesso</h6>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-2 form-group-1"><label class="font-weight-semibold">Utente:</label></div>
						<div class="col-md-10 form-group-1"><input type="text" class="form-control" v-model="accessoSelezionato.USERNAME" /></div>
						<div class="col-md-2"><label class="font-weight-semibold">Data accesso:</label></div>
						<div class="col-md-10"><p>{{filters.formatDateTime(accessoSelezionato.DATA_ACCESSO)}}</p></div>
					</div>                      
					<pre class="box_error" style="font-size:10px">
						{{accessoSelezionato.SESSION_ID}}
					</pre>
				</div>
				<div class="modal-footer">

				</div>
			</div>
		</div>
	</div>

</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'
import Datepicker from '@vuepic/vue-datepicker'


export default {
    name:"Accessi",
    components:{
        Pagine,
		Datepicker,
    },
    data: function () {
        return {   
            filters:global.filters,
            arrayAccessi: new Array(),
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ESITO:{TYPE:'COMBO',VALUE:''},
                USERNAME:{TYPE:'TEXT',VALUE:''},
                SESSION_ID:{TYPE:'TEXT',VALUE:''},
                DATA_ACCESSO:{TYPE:'DATE',VALUE:''},
            },
            accessoSelezionato:{}
        }
    },

    methods: {
        getAccessi: function () {
            utils.ajax("core/admin/accessi",{}, (response) => {
                this.arrayAccessi = response.data;
            });
        },	 

        getStringErrore:function(_accesso){
            var item = _accesso.context;
            if (item == undefined)
                return "";
            if (item.hasOwnProperty("SESSION_ID")){
                return item.SESSION_ID;
            }
            else {
                if (item.hasOwnProperty("query")){
                    return item.accesso ;
                }
                return "";
            }
    	}, 

        openStackTrace:function(accesso){
			this.filters.openPopUp("popUpStackTrace"); 
            this.accessoSelezionato = utils.clone(accesso);
            console.log(this.accessoSelezionato);
            if (this.accessoSelezionato.context.hasOwnProperty("SESSION_ID")){
                this.accessoSelezionato.stackTrace = this.accessoSelezionato.context.stackTrace;
            }
            else {
                if (this.accessoSelezionato.context.hasOwnProperty("query")){
                    this.accessoSelezionato.stackTrace = this.accessoSelezionato.context.query + "\n\n---\n" + this.accessoSelezionato.context.parametri.join(",") ;
                }
            }
        }, 

        updateStatoAccessi : function(accessi){
            setTimeout(() => {
                utils.ajax('core/admin/accessi/stato/update',utente, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
						this.filters.closePopUp("popUpUtente"); 
                    }  
                }); 
            },250);
        },

    },
    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayAccessi,this.search);
            return arr;
        }
    },
    created: function () {
        this.getAccessi();
    },
}
</script>