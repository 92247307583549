<template>

    <div class="modal fade" :id="'popUpRicercaPersonaGiuridica'+ts" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header bg-info text-white border-0">
					<div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 87%!important;">Ricerca Persona Giuridica</h6> 
                    <button class="btn btn-light btn-sm" style="padding: 0.2rem 0.75rem;" v-on:click="nuovaPersonaGiuridica()">NUOVA</button> 
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-8">
                    		<label class="form-label">Denominazione </label>
                            <input type="text" class="form-control form-control-sm" v-on:keyup.enter="ricerca()" v-model="denominazione" placeholder="Cerca">
                        </div>    
                        <div class="col-md-4">
							<label class="form-label">&nbsp;</label>
                            <button class="btn btn-info btn-sm float-start mt-4" v-on:click="ricerca()"><i class="fal fa-search me-2"></i> Cerca</button>     
                        </div>
                    </div>

                    <hr class="hr-1" />
 
                    <div class="tableFixHead" style="max-height: 30.6rem !important;" v-show="arrayPersoneGiuridiche.length > 0">
                        <table class="table table-bordered table-hover table-striped table-togglable"> 
                            <thead class="sticky-top">
                                <tr class="bg-info text-white">
                                    <th class="table-head-sm" style="width:9%;"> Id </th>
                                    <th class="table-head-sm">Ragione Sociale</th>
                                    <th class="table-head-sm" style="width:7%;">Seleziona</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pg in arrayPersoneGiuridiche" v-bind:key="pg.ID_PERSONA_GIURIDICA">
                                    <td class="text-center fs-12 p-2"> {{pg.ID_PERSONA_GIURIDICA}} </td>
                                    <td class="fs-12 text-uppercase"><span class="text-primary fw-bold">{{pg.DENOMINAZIONE}}</span></td>
                                    <td class="text-center">
                                        <a href="#" class="badge badge-flat border-secondary text-secondary" v-on:click="selezionaPg(pg)"><i class="fal fa-save fa-2x" ></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>                
            </div>
        </div>
    </div>  

</template>

<script> 


export default {
    name:"RicercaPersonaGiuridica",	

    props:["tipo"],		

    data:function(){
        return {
            filters:global.filters,
            modulo:0,
            denominazione:'',
            arrayPersoneGiuridiche:new Array(),
            ts:0
        }
    },
    methods:{
        openRicerca:function(){
            this.filters.openPopUp("popUpRicercaPersonaGiuridica" + this.ts);            
        },
        ricerca:function(){
            utils.ajax("travel/pg/ricerca",{denominazione:this.denominazione,tipo:this.tipo}, (response) => {
                if (response.esito == 0){
                    this.arrayPersoneGiuridiche = response.data;
                }
            });   
        },
        selezionaPg: function(pg){
            this.filters.closePopUp("popUpRicercaPersonaGiuridica" + this.ts);            
            this.$emit("selezionato",pg);
        }
    },
    created : function(){
        this.ts = utils.getRandomString(100);
    }
}
</script>
