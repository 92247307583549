<template>
    <select class="form-control form-control-sm" data-placeholder="Stato"  v-model="localValue">
        <option v-for="lingua in $root.arrayLingue" v-bind:value="lingua.ID_GEO_STATO" v-bind:key="lingua.ID_GEO_STATO">{{lingua.NOME.toString().toUpperCase()}}</option>														
    </select>
</template>
<script>


import { computed } from 'vue'


export default {
    name:"SelectLingue",
    components:{
   
    },
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: [String, Number]
        },        
    },
    //props:['campo','inputgroup'],
    setup(props, { emit }) {
        console.log(props);
        const localValue = computed({
            get: () => props.modelValue,
            set: (val) => emit('update:modelValue', val),
        });
        return { localValue };
    }, 
    computed:{
     
    }
}
</script>

