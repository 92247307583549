<template>

    <div class="modal fade" :id="'popUpRicercaPersonaFisica'+ts" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header bg-info text-white border-0">
					<div class="d-flex d-lg-none me-2">
                        <button type="button" class="navbar-toggler sidebar-mobile-component-toggle rounded-pill">
                            <i class="ph-arrow-down"></i>
                        </button>
                    </div>
                    <h6 class="modal-title" style="width: 87%!important;">Ricerca Persona Fisicaa</h6>   
                    <button class="btn btn-light btn-sm" style="padding: 0.2rem 0.75rem;" v-on:click="nuovaPersonaFisica()">NUOVA</button>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="form-label">Cognome:</label>
                                    <div class="input-group">
                                        <span class="input-group-text"><i class="far fa-search fa-fw"></i></span>
                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-on:keyup.enter="ricerca()" v-model="cognome" :tabindex="4">
                                    </div>
                                </div>  
                                <div class="col-md-4">
                                    <label class="form-label">Nome:</label>
                                    <div class="input-group">
                                        <span class="input-group-text"><i class="far fa-search fa-fw"></i></span>
                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-on:keyup.enter="ricerca()" v-model="nome" :tabindex="4">
                                    </div>
                                </div> 
                                <div class="col-md-4">
                                    <label class="form-label">Codice fiscale:</label>
                                    <div class="input-group">
                                        <span class="input-group-text"><i class="far fa-search fa-fw"></i></span>
                                        <input autocomplete="off" type="text" class="form-control form-control-sm" v-on:keyup.enter="ricerca()" v-model="codiceFiscale" :tabindex="4">
                                    </div>
                                </div>
                            </div>   
                        </div>
                        <div class="col-md-2">
                            <label class="form-label">&nbsp;</label>
                            <button class="btn btn-info btn-sm float-start mt-4" v-on:click="ricerca()"><i class="fal fa-search me-2"></i> Cerca</button> 
                        </div>
                    </div>  

                    <hr class="hr-1" />
 
                    <div class="tableFixHead" style="max-height: 30.6rem !important;" v-show="arrayPersoneFisiche.length > 0">
                        <table class="table table-bordered table-hover table-striped table-togglable"> 
                            <thead class="sticky-top">
                                <tr class="bg-info text-white">
                                    <th class="table-head-sm" style="width:9%;"> Id </th>
                                    <th class="table-head-sm">Cognome Nome</th>
                                    <th class="table-head-sm" style="width:7%;">Seleziona</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="pf in arrayPersoneFisiche" v-bind:key="pf.ID_PERSONA_FISICA">
                                    <td class="text-center fs-12 p-2"> {{pf.ID_PERSONA_FISICA}} </td>
                                    <td class="fs-12 text-uppercase"><span class="text-primary fw-bold">{{pf.COGNOME}} {{pf.NOME}}</span></td>
                                    <td class="text-center">
                                        <a href="#" class="badge badge-flat border-secondary text-secondary" v-on:click="selezionaPf(pf)"><i class="fal fa-save fa-2x" ></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        
                </div>                
            </div>
        </div>
    </div>    


    <div class="modal fade" :id="'popUpPersonaFisica'+ts" aria-modal="true" role="dialog" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" >
                <div class="modal-header bg-info text-white border-0">
                    <h6 class="modal-title">Nuova persona fisica</h6>   
                    <button type="button" class="close" data-bs-dismiss="modal">X</button>
                </div>
                <div class="modal-body">	
                    <PersonaFisica :ref="'pf'" v-model="personaFisica"></PersonaFisica>
                </div> 
                <div class="modal-footer modal-footer-1 bg-light">
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaPersonaFisica()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">CHIUDI <i class="far fa-sign-out-alt fa-fw"></i> </button>
                </div>               
            </div>
        </div>
    </div>

</template>

<script>

import PersonaFisica from '@/components/common/PersonaFisica.vue';

export default {
    name:"RicercaPersonaFisica", 

    props:["tipo"],

    emits:['selezionato'], 

    components:{
        PersonaFisica,
    },				  

    data:function(){
        return {
            filters:global.filters,
            modulo:0,
            cognome:'',
            nome:'',
            codiceFiscale:'',
            arrayPersoneFisiche:new Array(),
            ts:0,
            personaFisica:{}
        }
    },
    methods:{
        openRicerca:function(){
            this.filters.openPopUp("popUpRicercaPersonaFisica" + this.ts);
        },
        ricerca:function(){
            utils.ajax("travel/pf/ricerca",{nome:this.nome,cognome:this.cognome,codiceFiscale:this.codiceFiscale,tipo:this.tipo}, (response) => {
                if (response.esito == 0){
                    this.arrayPersoneFisiche = response.data;
                }
            });   
        },
        selezionaPf: function(pf){
            this.filters.closePopUp("popUpRicercaPersonaFisica" + this.ts);
            this.$emit("selezionato",pf);
        },
        nuovaPersonaFisica : function(){
            this.filters.openPopUp("popUpPersonaFisica" + this.ts);
        },
        salvaPersonaFisica : function(){
            utils.ajax("travel/pf/insert",this.personaFisica, (response) => {
                if (response.esito == 0){
                    this.filters.closePopUp("popUpPersonaFisica" + this.ts);
                    this.personaFisica.ID_PERSONA_FISICA = response.id_inserito;
                    this.filters.closePopUp("popUpRicercaPersonaFisica" + this.ts);
                    this.$emit("selezionato",this.personaFisica);                    
                }
            }); 
        }
    },
    created : function(){
        this.ts = utils.getRandomString(100);
    }
}
</script>
