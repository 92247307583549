<template>
        
	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
					<a href="#" class="text-dark fw-semibold" v-on:click="openRoute('/Ordini')"> Pratiche </a> \
					<a href="#" class="text-dark fw-semibold" v-on:click="getPreventivi()"> Preventivi </a> \
					<span class="text-muted"><Translate :path="'dizionario.base.ELENCO'"></Translate> Preventivi</span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">	 

					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovoPreventivo()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Preventivo</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill" v-on:click="$root.confTravelTipologiche(10)" title="Opzioni Prodotto" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-lightning"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
	            </div>
	        </div>
		</div>

		<!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#preventivi-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate> Preventivi</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#preventivi-b" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-calendar"></i>
								<span class="d-none d-lg-inline-block ms-2">Preventivi rifiutati</span>
							</div>
						</a>
					</li>
					<li class="nav-item me-1" role="presentation">
						<a href="#preventivi-c" class="navbar-nav-link navbar-nav-link-icon navbar-sm rounded" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="ph-activity"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.BTN_STATISTICHE'"></Translate></span>
							</div>
						</a>
					</li>
				</ul>

           		<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />

				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">
							<a href="#" class="btn btn-light btn-icon btn-sm rounded dropdown-toggle" data-bs-toggle="dropdown">
								<i class="ph-gear me-1"></i> Azioni Multiple
								<span class="d-lg-none ms-2">More</span>
							</a>
							<div class="dropdown-menu dropdown-position dropdown-menu-end">
								<a href="#" class="dropdown-item">
									<i class="ph-image me-2"></i>
									Update cover
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-clipboard-text me-2"></i>
									Update info
								</a>
								<a href="#" class="dropdown-item">
									<i class="ph-rows me-2"></i>
									Manage sections
								</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item">
									<i class="ph-activity me-2"></i>
									Activity log
								</a>
								<a href="#" v-on:click="deletePreventiviSelezionati(preventiviSelezionati)" class="dropdown-item">
									<i class="ph-gear me-2"></i>
									Profile settings
								</a>
							</div>
						</li>
						<li class="nav-item ms-3">
	                        <select class="form-select form-select-sm changeyear" id="comboAnno" @change="getPreventivi()" v-model="search.ANNO_ORDINE.VALUE" :tabindex="4">
	                            <option v-for="anno in filters.getAnniOrdine()" v-bind:value="anno.ID" v-bind:key="anno.ID">{{anno.DESCRIZIONE}}</option>
	                        </select>
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->

	<!-- Content area -->
	<div class="content content-1">
		<div class="tab-content flex-1 order-2 order-lg-1">

			<div class="tab-pane fade active show" id="preventivi-a">
				<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="preventivo-a">
									<div class="row ">	
										<div class="col-12 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Richiedente preventivo" :tabindex="4" v-model="search.INTESTATARIO.VALUE" />
										</div>  
										<div class="col-12 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Destinazione" :tabindex="3" v-model="search.INTESTATARIO.VALUE" />
										</div>  
										<div class="col-6 col-md-2">
											<Datepicker autocomplete="off" class="ms-lg-auto bg-light text-center" style="width:300px" v-model="dataCreazioneRange" @update:model-value="datePickerChangeHandler()"  range multi-calendars   :format="'dd/MM/yyyy HH:mm'" auto-apply  :partial-range="false" :tabindex="2" />
										</div> 
										<div class="col-6 col-md-2"></div>
										<div class="col-12 col-md-4">
											<div class="row"> 
												<div class="col-6 col-md-4 col-lg-8 d-none d-sm-block">
												</div> 
												<div class="col-12 col-md-4">
													<select class="form-select form-select-sm" data-placeholder="Stato Richiesta">
														<option>Stato Richiesta</option> 
														<option v-bind:value="0"> - Da completare</option> 
														<option v-bind:value="1"> - Risposta</option> 
														<option v-bind:value="2"> - Evasa</option> 
														<option v-bind:value="3"> - Dalavorare</option>                                                                    
													</select>
												</div> 
	
											</div>
										</div>  
									</div>
								</form>
							</div>
						</div>
		            </div>
				
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width: 2%;"><input id="chkAllPreventivi" v-on:click="filters.selectAll('chkAllPreventivi',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width: 6%;"> # </th>
										<th class="table-head-sm text-center" style="width: 7%;">Data Richiesta</th>
										<th class="table-head-sm text-center" style="width: 8%;">Stato Preventivo</th>
										<th class="table-head-sm"> Richiesta<br>del preventivo </th>
										<th class="table-head-sm" style="width: 20%;"> Richiedente </th>
										<th class="table-head-sm text-center" style="width: 9%;"> Data Dal<br>Data Al</th>
										<th class="table-head-sm text-center" style="width: 8%;">Email</th>
										<th class="table-head-sm text-center" style="width: 8%;">Stato</th>
										<th class="table-head-sm text-center" style="width: 5%;"> Azioni </th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="preventivo in paginatedList.arr" v-bind:key="preventivo.ID_PREVENTIVO">
										<td class="td-class text-center">
											<span class="footable-toggle"></span>
											<input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="preventivo.SELEZIONATO" class=""> 
										</td>
										<td class="td-class">
											<span class="fs-sm">{{preventivo.ID_PREVENTIVO}}</span><br />
											&nbsp;
										</td>
										<td class="td-class text-center fs-sm">
											{{filters.formatDateTime(preventivo.DATA_CREAZIONE)}}
										</td>
										<td class="td-class fs-sm">
											<span class="badge" v-bind:class="getTipoBookClass(preventivo)">
												{{getTipoBook(preventivo)}} - Preventivo
											</span>
										</td> 
										<td class="td-class fs-sm">
											<span class="text-primary fw-bold text-uppercase cursor-pointer">{{preventivo.DESTINAZIONE}} - {{preventivo.TITOLO_PRATICA}}</span><br />
											<span class="fs-sm">
											 	i camera 2 adulti 
											</span>
										</td>
										<td class="td-class fs-sm">
											<span class="text-secondary text-uppercase fw-bold cursor-pointer">{{ preventivo.AGENZIA }}</span>
											<span class="text-secondary text-uppercase fw-bold cursor-pointer" v-show="preventivo.FK_ID_AZIENDA_AGENZIA == 0 && preventivo.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">{{ preventivo.INTESTATARIO_PERSONA_FISICA }}</span>
											<span class="text-secondary text-uppercase fw-bold cursor-pointer" v-show="preventivo.FK_ID_AZIENDA_AGENZIA == 0 && preventivo.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">{{ preventivo.INTESTATARIO_PERSONA_GIURIDICA }}</span>
											<br>
											<span data-popup="tooltip" :title="preventivo.EMAIL" class="me-2"><i class="far fa-envelope-open fa-fw"></i> Email</span>
											<span data-popup="tooltip" :title="preventivo.TELEFONO"><i class="far fa-phone-square-alt fa-fw"></i> Telefono </span>
										</td>
										<td class="td-class fs-sm text-center">
											{{filters.formatDate(preventivo.DATA_INIZIO_SOGGIORNO)}}<br />
											{{filters.formatDate(preventivo.DATA_FINE_SOGGIORNO)}}
										</td>
										<td class="td-class fs-sm text-center">
											<span class="badge bg-flat border-success text-success">Inviata</span>
										</td>
										<td class="td-class text-center fs-sm">
											<span class="badge badge-xs bg-primary">In Attesa</span>
										</td>
		                                <td class="td-class text-center fs-sm">
	                                        <div class="dropdown">
                                                <a href="#" class="text-body" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ph-list"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-end">
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Preventivo</div>
	                                                <a href="#" class="dropdown-item" v-on:click="openPreventivo(preventivo)"><i class="fad fa-pencil fa-fw ms-2 me-2"></i> Modifica pratica</a>
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Stampe</div>
	                                                <a href="#" class="dropdown-item" v-on:click="stampaRiepilogo(ordine)"><i class="fad fa-envelope fa-lg fa-fw ms-2 me-2"></i> Riepilogo email</a>
	                                                <div class="dropdown-header text-uppercase font-size-xs line-height-xs">Azioni</div>
	                                                <a href="#" class="dropdown-item" v-on:click="storicoOrdine(ordine)"><i class="fad fa-list fa-fw ms-2 me-2"></i> Storico pratica</a>
	                                                <a href="#" class="dropdown-item" v-on:click="deletePreventivo(preventivo)"><i class="fad fa-trash-alt fa-fw text-danger ms-2 me-2"></i> Gestione documenti</a>                                        
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="preventivi-b">
				<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="preventivo-b">
									<div class="row ">	
										<div class="col-6 col-md-1 col-lg-1">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id pratica" :tabindex="6" v-model="search.ID_PREVENTIVO.VALUE" />
										</div> 
										<div class="col-6 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Intestatario preventivo" :tabindex="4" v-model="search.INTESTATARIO.VALUE" />
										</div>  
										<div class="col-12 col-md-2">
											<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="estinazione" :tabindex="3" v-model="search.TITOLO_PRATICA.VALUE" />
										</div>  
										<div class="col-12 col-md-1">
											<Datepicker model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
										</div>  
										<div class="col-12 col-md-1">
											<Datepicker  model-type="dd/MM/yyyy" :format="'dd/MM/yyyy'" auto-apply :hide-navigation="['time']"></Datepicker>
										</div> 
										<div class="col-12 col-md-5">
											<div class="row">
												<div class="col-6 col-md-3">
												</div>
												<div class="col-6 col-md-3">
												</div> 
												<div class="col-12 col-md-3">
													<select class="form-control form-control-sm" data-placeholder="Saldo" tabindex="4"> 
														<option>Movimento</option> 
														<option v-bind:value="0"> - Banca</option> 
														<option v-bind:value="1"> - Contanti</option>
													</select>
												</div>
												<div class="col-12 col-md-3">
													<select class="form-control form-control-sm" data-placeholder="Stato Incasso">
														<option>Stato Incasso</option> 
														<option v-bind:value="0"> - Da pagare</option> 
														<option v-bind:value="1"> - Pagata</option>                                                                    
													</select>
												</div> 
											</div>
										</div>  
									</div>
								</form>
							</div>
						</div>
					</div>
				
			        <div class="card-body mt-0 mb-0">
						<div class="tableFixHead">
							<table class="table table-bordered table-hover table-striped table-togglable"> 
			                    <thead class="sticky-top">
			                        <tr class="bg-secondary text-white">
										<th class="table-head-sm" style="width: 2%;"> <input id="chkAllProducts" v-on:click="filters.selectAll('chkAllProducts',paginatedList.arr)" type="checkbox" class=""> </th>
										<th class="table-head-sm" style="width: 6%;"> # </th>
										<th class="table-head-sm" style="width: 7%;">Data preventivo</th>
										<th class="table-head-sm" style="width: 19%;"> Intestatario </th>
										<th class="table-head-sm text-center" style=""> Descrizione<br />Dati preventivo </th>
										<th class="table-head-sm text-center" style="width: 8%;"> Importo </th>
										<th class="table-head-sm text-center" style="width: 8%;">Movimento<br />Tipo</th>
										<th class="table-head-sm text-center" style="width: 10%;">Utente</th>
										<th class="table-head-sm text-center" style="width: 10%;">Stato</th>
										<th class="table-head-sm" style="width: 5%;"> Azioni </th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="preventivo in paginatedList.arr" v-bind:key="preventivo.ID_PREVENTIVO">
										<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="preventivo.SELEZIONATO" class=""> </td>
										<td class="text-center f-08"><span class="">{{preventivo.ID_PREVENTIVO}}</span></td>
										<td class="text-center f-08">{{filters.formatDate(preventivo.DATA_CREAZIONE)}}</td>
										<td class="f-08">
											<span class="" v-show="preventivo.FK_ID_AGENCY > 0">{{ preventivo.AGENZIA }}</span>
											<span class="" v-show="preventivo.FK_ID_AGENCY == 0 && preventivo.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">{{ preventivo.INTESTATARIO_PERSONA_FISICA }}</span>
											<span class="" v-show="preventivo.FK_ID_AGENCY == 0 && preventivo.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">{{ preventivo.INTESTATARIO_PERSONA_GIURIDICA }}</span>
										</td>
										<td>
											<span class="text-secondary fw-bold">Acconto</span><br />
											<a href="#" class="badge bg-light text-primary" title="Apri dettaglio Pratica">{{filters.getPnr(preventivo)}}</a> - <span class="f-08">{{preventivo.TITOLO_PRATICA}}</span>
										</td>
										<td class="text-center"><strong>&euro; {{preventivo.PREZZO_TOTALE}} </strong></td>
										<td class="text-center"> Bonifico </td>
										<td class="text-center"> Banca </td>
										<td class="text-center">
											<span class="badge badge-danger cursor-pointer">Da pagare</span>
										</td> 
										<td class="text-center">
											<div class="list-icons">
												<div class="dropdown">
													<a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
													<div class="dropdown-menu dropdown-menu-right">
														<a href="#" class="dropdown-item" v-on:click="openPreventivo(preventivo)"><i class="far fa-pencil fa-fw"></i> Modifica Preventivo</a>
														<div class="dropdown-divider"></div>
														<a href="#" class="dropdown-item" v-on:click="deletePreventivo(preventivo)"><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina Preventivo</a>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

		                <div class="row page-bottom">
							<div class="col-8 col-md-11" :tabindex="27">
								<div class="float-end">
									<Pagine v-model="search.PAGINATION.PAGE_NUMBER" :numrighe="paginatedList.count" :numpagine="search.PAGINATION.LENGTH" @esportazione="esportazione($event,1)"></Pagine>
								</div>
							</div>
							<div class="col-4 col-md-1" :tabindex="28">
								<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="75">75</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="preventivi-c">
				<div class="card shadow mb-0">
		            <div class="collapse show">
		                <div class="card-body pb-0">
		                    <div class="alert alert-2 alert-secondary border-0">
								<form action="#" id="preventivo-c">

								</form>
							</div>
						</div>
					</div>

					<div class="card-body mt-0 mb-0">
						<div class="row">
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="me-3 align-self-center">
											<i class="icon-coin-euro icon-3x text-indigo"></i>
										</div>

										<div class="media-body text-right">
											<h3 class="font-weight-semibold mb-0">&euro; 245,382</h3>
											<span class="text-uppercase fs-12 text-muted">Totale preventivi</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="me-3 align-self-center">
											<i class="icon-file-stats icon-3x text-success"></i>
										</div>

										<div class="media-body text-right">
											<h3 class="font-weight-semibold mb-0">652</h3>
											<span class="text-uppercase fs-12 text-muted">Preventivi inviati</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="media-body">
											<h3 class="font-weight-semibold mb-0">154</h3>
											<span class="text-uppercase fs-12 text-muted">Preventivi accettati</span>
										</div>

										<div class="ms-3 align-self-center">
											<i class="icon-calendar52 icon-3x text-primary"></i>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-6 col-xl-3">
								<div class="card card-body card-2">
									<div class="media">
										<div class="media-body">
											<h3 class="font-weight-semibold mb-0">258</h3>
											<span class="text-uppercase fs-12 text-muted">Preventivi rifiutati</span>
										</div>

										<div class="ms-3 align-self-center">
											<i class="icon-blocked icon-3x text-danger"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>	
	

	<!-- START MODALI -->
	<div class="modal fade" id="popUpPreventivo" role="dialog"  aria-hidden="true" tabindex="-1">
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
						<i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> NUOVO PREVENTIVO </span> 
					</h6> 
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
				
					<h3 class="border-bottom-2 border-bottom-light mb-2" style="line-height: 2rem;">
						<strong>1.</strong> Dati intestatario
						<div class="dropdown float-end" style="position: relative !important; top: -5px;">
							<button class="btn btn-warning btn-sm dropdown-toggle" type="button"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="fal fa-user fa-fw"></i> CLIENTE
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownMenu2">
								<button class="dropdown-item" v-on:click="modificaIntestatario(1)" type="button"><i class="fa-fw fal fa-search"></i> Privato</button>
								<button class="dropdown-item" v-on:click="modificaIntestatario(2)" type="button"><i class="fa-fw fal fa-search"></i> Aziendale</button>
							</div>
						</div>
						<button class="btn btn-warning btn-sm me-3 float-end" style="position: relative; top: -4px;" v-on:click="modificaAgenzia()">
							<i class="fal fa-store-alt fa-fw"></i> AGENZIA
						</button>
					</h3>	
					<div class="row" v-if="preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_FISICA > 0">
						<div class="col-2">
							<div class="profile-image text-center"> 
								<img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt=""> 
							</div>
						</div>
						<div class="col-10">
							<h6 class="mt-0 mb-0">
								<span class="text-muted">{{preventivoSelezionato.intestatarioPersonaFisica.COGNOME}} {{preventivoSelezionato.intestatarioPersonaFisica.NOME}}</span>
								<span class="text-muted float-end">{{preventivoSelezionato.intestatarioPersonaFisica.SESSO}}</span>
							</h6>
							<i class="fad fa-birthday-cake fa-fw"></i> <span class="text-muted me-2">{{preventivoSelezionato.intestatarioPersonaFisica.DATA_NASCITA}}</span>
							<i class="fad fa-flag fa-fw"></i> <span class="text-muted">{{preventivoSelezionato.intestatarioPersonaFisica.FK_ID_NAZIONALITA}} </span>
							<p class="mb-0"><i class="fad fa-map-signs fa-fw"></i> <span class="text-muted">{{preventivoSelezionato.intestatarioPersonaFisica.INDIRIZZO}}</span></p>
							<p>
								<i class="fad fa-envelope-open fa-fw"></i> <span class="text-muted me-2">{{preventivoSelezionato.intestatarioPersonaFisica.EMAIL}}</span>
								<i class="fad fa-phone-square-alt fa-fw"></i> <span class="text-muted">{{preventivoSelezionato.intestatarioPersonaFisica.TELEFONO}}</span>
							</p>
						</div>             
					</div>
					<div class="row" v-if="preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_GIURIDICA > 0">
						<div class="col-2">
							<div class="profile-image float-md-right"> 
								<img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="38" height="38" alt=""> 
							</div>
						</div>
						<div class="col-10">
							<h6 class="mt-0 mb-0">
								<span class="text-muted">{{preventivoSelezionato.intestatarioPersonaGiuridica.DENOMINAZIONE}}</span>
								<span class="text-muted float-end">{{preventivoSelezionato.intestatarioPersonaGiuridica.FK_ID_ENTE}}</span>
							</h6>
							<i class="fad fa-balance-scale fa-fw"></i> <span class="text-muted me-2">{{preventivoSelezionato.intestatarioPersonaGiuridica.PARTITA_IVA}}</span>
							<i class="fad fa-thumbtack fa-fw"></i> <span class="text-muted me-2">{{preventivoSelezionato.intestatarioPersonaGiuridica.CODICE_DESTINATARIO}}</span>
							<i class="fad fa-flag fa-fw"></i> <span class="text-muted">{{preventivoSelezionato.intestatarioPersonaGiuridica.FK_ID_NAZIONALITA}} </span>
							<p class="mb-0"><i class="fad fa-map-signs fa-fw"></i> <span class="text-muted">{{preventivoSelezionato.intestatarioPersonaGiuridica.INDIRIZZO}} 80, {{preventivoSelezionato.intestatarioPersonaGiuridica.CAP}} {{preventivoSelezionato.intestatarioPersonaGiuridica.FK_ID_COMUNE}} (RM)</span></p>
							<p>
								<i class="fad fa-envelope-open fa-fw"></i> <span class="text-muted me-2">{{preventivoSelezionato.intestatarioPersonaGiuridica.EMAIL}}</span>
								<i class="fad fa-phone-square-alt fa-fw"></i> <span class="text-muted">{{preventivoSelezionato.intestatarioPersonaGiuridica.TELEFONO}}</span>
							</p>
						</div>              
					</div> 
			
				</div>
				<div class="modal-footer bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaPreventivo()"><i class="far fa-plus-square fa-fw"></i> CREA PREVENTIVO</button>                                   
                </div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="popUpSearchPreventivo" role="dialog"  aria-hidden="true" tabindex="-1">
        <div class="modal-dialog modal-lg"> 
            <div class="modal-content" >
                <div class="modal-header modal-header-2 bg-secondary text-white">
                    <h6 class="modal-title">
                        <i class="fal fa-location fa-fw fa-lg" style="color:#FFF;"></i> <span style="top: -1px; position: relative;"> Nuovo preventivo </span>
                    </h6>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">

					<form class="form-horizontal">
					  	<div class="form-group">
							<div class="col-lg-8">
								<label class="custom-file">
									<input type="file" class="custom-file-input"><span class="custom-file-label">Cerca</span>
								</label>
							</div>
					  	</div>
					</form>
					<div class="outer_div">
						<div class="table-responsive">
							<table class="table" style="font-size: 12px;">
								<tbody>
									<tr class="warning">
										<th>Codice</th>
										<th>Titolo</th>
										<th>Marca</th>
										<th><span class="pull-right">Qnt.</span></th>
										<th><span class="pull-right">Prezzo</span></th>
										<th style="width: 36px;"></th>
									</tr>
									<tr>
										<td>25</td>
										<td>Mouse inalambrico</td>
										<td>Mega</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_1" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_1" value="10.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('1')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>TCL01</td>
										<td>Teclado multimedia</td>
										<td>Mega</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_2" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_2" value="15.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('2')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>ILK059</td>
										<td>Nuevo mini 2.4g micro inalambrico de teclado</td>
										<td>Mega</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_3" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_3" value="25.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('3')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>4520</td>
										<td>TECLADO para HP 4520</td>
										<td>HP</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_4" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_4" value="40.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('4')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td>A081</td>
										<td>Altavoz de la computadora</td>
										<td>HP</td>
										<td class="col-xs-1">
										<div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="cantidad_5" value="1">
										</div></td>
										<td class="col-xs-2"><div class="pull-right">
										<input type="text" class="form-control" style="text-align:right" id="precio_venta_5" value="18.00">
										</div></td>
										<td><span class="pull-right"><a href="#" onclick="agregar('5')"><i class="glyphicon glyphicon-plus"></i></a></span></td>
									</tr>
									<tr>
										<td colspan="5"><span class="pull-right"><ul class="pagination pagination-large"><li class="disabled"><span><a>‹ Prev</a></span></li><li class="active"><a>1</a></li><li><a href="javascript:void(0);" onclick="load(2)">2</a></li><li><span><a href="javascript:void(0);" onclick="load(2)">Next ›</a></span></li></ul></span></td>
									</tr>
								</tbody>
							</table>
						</div>

					</div>
				</div>
				<div class="modal-footer bg-light">
                    <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="far fa-sign-out-alt fa-fw"></i> CHIUDI </button>
                    <button class="btn btn-success float-end btn-sm" v-on:click="salvaPreventivo()"><i class="far fa-plus-square fa-fw"></i> SALVA</button>                                   
                </div>
			</div>
		</div>
	</div>				

    <SchedaUtente :ref="'schedaUtente'" ></SchedaUtente> 
    <SchedaAzienda :ref="'schedaAzienda'" :tipo="1" ></SchedaAzienda>
    <RicercaPersonaGiuridica :tipo="1" :ref="'ricercaPg'" @selezionato="doModificaAgenzia($event)"></RicercaPersonaGiuridica>
    <RicercaPersonaGiuridica :tipo="3" :ref="'ricercaPgIntestatario'" @selezionato="doModificaIntestatario($event,2)"></RicercaPersonaGiuridica>
    <RicercaPersonaFisica :tipo="1" :ref="'ricercaPfIntestario'" @selezionato="doModificaIntestatario($event,1)"></RicercaPersonaFisica>


</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
//import Select2 from '@/components/utils/Select2.vue'
import Datepicker from '@vuepic/vue-datepicker'
import SchedaUtente from '@/components/SchedaUtente.vue'
import SchedaAzienda from '@/components/SchedaAzienda.vue'	
import RicercaPersonaGiuridica from '@/components/RicercaPersonaGiuridica.vue'
import RicercaPersonaFisica from '@/components/RicercaPersonaFisica.vue'
//import Ricerca from '@/components/Ricerca.vue'


export default {
    name:"Preventivi",

    components:{
        Pagine,
		//Select2,
        Datepicker,
        SchedaUtente,
		SchedaAzienda,
		RicercaPersonaGiuridica,
        RicercaPersonaFisica
		//Ricerca,
    },

    emits:['selezionaProdotto'],
    
    data:function(){
        return {
            apiStartup:'travel/preventivi',
            filters:global.filters,
			anno:new Date().getFullYear(),
            arrayPreventivi:new Array(),
            arrayPromotori : new Array(),
            intestatarioSelezionato:{},
            preventivoSelezionato:{storico:new Array()},
            periodoSelezionato:{},
            dataCreazioneRange:{},
            prodottoSelezionato:{FK_ID_MODULO:0},
            arrayCategorie:new Array(),
            search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                ANNO_ORDINE:{TYPE:'COMBO',VALUE: new Date().getFullYear()},
	            ID_PREVENTIVO:{TYPE:'TEXT',VALUE:''},
                PNR_FORMATTATO:{TYPE:'TEXT',VALUE:''},
                CODICE_ORDINE:{TYPE:'TEXT',VALUE:''},
                FK_ID_STATO:{TYPE:'COMBO',VALUE: 0},
                // VISIBILE_DEFAULT:{TYPE:'COMBO',VALUE: 1},
	            INTESTATARIO:{TYPE:'TEXT',VALUE:''}, 
	            TITOLO_PRATICA:{TYPE:'TEXT',VALUE:''}, 
                FK_ID_TIPO:{TYPE:'COMBO',VALUE:''},
                FK_ID_ENTE:{TYPE:'COMBO',VALUE:''},
                FK_ID_AZIENDA_FORNITORE:{TYPE:'COMBO',VALUE:''},               
	            FLG_ATTIVO:{TYPE:'COMBO',VALUE:'1'}, 
                FLG_GRUPPI:{TYPE:'COMBO',VALUE:''}, 
                STATO_PAGAMENTO:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_PROMOTORE:{TYPE:'COMBO',VALUE:''}, 
                FK_ID_UTENTE:{TYPE:'COMBO',VALUE:''},   
                DATA_INIZIO_SOGGIORNO:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},              
                DATA_CREAZIONE:{TYPE:'DATE_RANGE',VALUE_FROM:'',VALUE_TO:''},
	        },
        }
    },

    methods:{
        getPreventivi:function(){
            utils.ajax(this.apiStartup,{ANNO_ORDINE:this.search.ANNO_ORDINE.VALUE}, (response) => {
                if (response.esito == 0){
                    this.arrayPreventivi = response.data;
                    for (var i = 0 ; i < this.arrayPreventivi.length ; i++){
                        this.arrayPreventivi[i].PNR_FORMATTATO = this.filters.getPnr(this.arrayPreventivi[i]);
                        this.arrayPreventivi[i].SELEZIONATO = 0;
                    }
                }
            });  
        },

        openPreventivo:function(preventivo){
            this.$root.ordineSelezionato = {ID_ORDINE:preventivo.ID_ORDINE};
            global.router.push("Quota");
        },

        nuovoPreventivo : function(){ 
			this.preventivoSelezionato = {ID_CATEGORIA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
            //this.$refs.schedaContenuto.reset();
            this.filters.openPopUp("popUpPreventivo");
        },

        modificaPreventivo : function(preventivo){            
            utils.ajax('travel/preventivo',preventivo, (response) => {
                this.preventivoSelezionato = utils.clone(response.data);
                this.filters.openPopUp("popUpPreventivo");
            });             
        },

        salvaPreventivo : function(){
            var api = this.preventivoSelezionato.ID_PREVENTIVO > 0 ? "travel/preventivo/update" : "travel/ordine/genera";
			this.preventivoSelezionato.FLG_PREVENTIVO = 1;
            utils.ajax(api,this.preventivoSelezionato, (response) => {
                if (response.esito == 0){
					this.filters.closePopUp("popUpPreventivo");
                    utils.alert.success(response.messaggio);
					var ordine = {ID_ORDINE:response.id_inserito};
					//this.preventivoSelezionato.ID_PREVENTIVO = response.id_inserito;
					this.$root.ordineSelezionato = ordine;
                    global.router.push("Quota");
                }
            });
        },

		popUpPreventivo : function(){
			this.preventivoSelezionato = {ID_CATEGORIA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
			this.filters.openPopUp("popUpSearchPreventivo");
		},

        openStatoPreventivo : function(){
			this.preventivoSelezionato = {ID_PREVENTIVO:0};
            this.filters.openPopUp("popUpStatoPreventivo");
        },

        openSchedaPreventivo : function(preventivo){
            if (preventivoSelezionato.FK_ID_AZIENDA_AGENZIA > 0){
                this.$refs.schedaAzienda.openSchedaAzienda({ID_AZIENDA:preventivoSelezionato.FK_ID_AZIENDA_AGENZIA});
            }
            else{
                if(preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_FISICA > 0){
                    this.$refs.schedaUtente.openSchedaUtente({ID_UTENTE:preventivoSelezionato.FK_ID_UTENTE_INTESTATARIO});
                }
                else{
                    utils.ajax("travel/personaGiuridica",{ID_PERSONA_GIURIDICA:preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_GIURIDICA}, (response) => {
                        if (response.esito == 0){
                            this.intestatarioSelezionato = response.data;
                        }
                    }); 
                }
                /*
				this.filters.openPopUp('#popUpIntestatario');
                var modal = $('#popUpIntestatario');
                modal.modal('show'); */
            }
        },

        modificaAgenzia : function(){
            this.$refs.ricercaPg.openRicerca();
        },

        modificaIntestatario : function(tipo){
            if (tipo == 1){
                this.$refs.ricercaPfIntestario.openRicerca();
            }
            else{
                this.$refs.ricercaPgIntestatario.openRicerca();
            }
        },

		doModificaAgenzia : function(pg){
            this.preventivoSelezionato.FK_ID_AZIENDA_AGENZIA = pg.ID_AZIENDA;
            this.preventivoSelezionato.agency = {ID_AZIENDA:pg.ID_AZIENDA,personaGiuridica:utils.clone(pg)};           
        },
        doModificaIntestatario : function(p,tipo){
            if (tipo == 1){
                this.preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_FISICA = p.ID_PERSONA_FISICA;
                this.preventivoSelezionato.intestatarioPersonaFisica = utils.clone(p);
                this.preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_GIURIDICA = 0;
                this.preventivoSelezionato.intestatarioPersonaGiuridica = {};
            }
            else{
                this.preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_GIURIDICA = p.ID_PERSONA_GIURIDICA;
                this.preventivoSelezionato.intestatarioPersonaGiuridica = utils.clone(p);
                this.preventivoSelezionato.INTESTATARIO_FK_ID_PERSONA_FISICA = 0;
                this.preventivoSelezionato.intestatarioPersonaFisica = {};
            }            
        },
		
        getBtnStatusClass : function(preventivo){
            switch (preventivo.FK_ID_STATO){
                case 1:
                    return 'bg-success'
                case 2:
                    return 'bg-primary'
                case 3:
                    return 'bg-info'
				case 4:
                    return 'bg-warning'
                case 5:
                    return 'bg-yellow'
                case 6:                
                    return "bg-secondary";
                case 7:
                case 8:
                    return 'bg-danger'
                default :
                    return 'bg-light'
            }
        },

        getTipoBookClass : function(preventivo){
            switch (preventivo.FK_ID_TIPO){
                case 1:
                    return "bg-danger";
                case 2:
                    return "bg-info";                   
                case 3:
                    return "bg-warning";
                case 4:
                    return "bg-primary";                   
            }
            return "bg-dark bg-opacity-20 text-reset";
        },

        getTipoBook : function(preventivo){
            switch (preventivo.FK_ID_TIPO){
                case 1:
                    return "BA";
                case 2:
                    return "BC";
                case 3:
                    return "A";
                case 4:
                    return "C";                   
            }
            return "";
        },

        getDateRange : function(preventivo){
            $('.daterange-predefined').daterangepicker(
                {
                    startDate: moment().subtract(29, 'days'),
                    endDate: moment(),
                    minDate: '01/01/2014',
                    maxDate: '12/12/2019',
                    dateLimit: { days: 60 },
                    timePicker: true,
                    parentEl: '.content-inner',
                    showDropdowns: true,
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                },
                function(start, end) {
                    $('.daterange-predefined span').html(start.format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + end.format('MMMM D, YYYY'));
                    $.jGrowl('Date range has been changed', { header: 'Update', theme: 'bg-primary text-white', position: 'center', life: 1500 });
                }
            );

            // Display date format
            $('.daterange-predefined span').html(moment().subtract(29, 'days').format('MMMM D, YYYY') + ' &nbsp; - &nbsp; ' + moment().format('MMMM D, YYYY'));
        },

		openRoute : function(route){
			global.router.push(route);
		},

		changeModuloHandler : function(idModulo){
			this.FK_ID_MODULO = idModulo;
			this.arrayRisultatiRicerca = new Array();
		},

    },

    computed: {
        paginatedList(){
	        var arr = utils.inGrid(this.arrayPreventivi,this.search);            
		    return arr;         
        },

    }, 

    created : function(){
        this.arrayPromotori = this.$root.arrayPromotori;
        this.arrayAgenti = this.$root.arrayAgenti;
		this.filters.wait(300,()=>{
			var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
			var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl)
			})
		});
        this.getPreventivi();
    }
}
</script>