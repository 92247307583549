<template>

	<!-- Page header -->
	<div class="page-header page-header-light shadow">
		<div class="page-header-content d-lg-flex">
			<div class="d-flex">
				<h4 class="page-title mb-0">
					<router-link href="#" class="text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                    <a href="#" class="text-dark fw-semibold" v-on:click="openRoute('/Ordini')"> Nome Modulo </a> \ 
					<span class="text-muted">Configurazione  </span>
				</h4>
				<a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
					<i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
				</a>
			</div>
			<div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
				<div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
					
					<a href="#" class="d-flex align-items-center text-body lh-1 py-sm-2" style="text-align: left;" v-on:click="nuovaTipologica()">
						<i class="ph-plus fw-bold me-2 btn btn-outline-success w-32px h-32px rounded-pill"></i>
						<div class="me-auto me-lg-1">
							<div class="fs-sm text-muted mb-1"><Translate :path="'dizionario.base.BTN_NUOVO'"></Translate> Tipologia</div>
							<div class="fw-semibold">Aggiungi</div>
						</div>
					</a>

					<div class="vr d-none d-sm-block flex-shrink-0 my-2 mx-3"></div>

					<div class="d-inline-flex mt-3 mt-sm-0">
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.confTravelLingue(this.modulo)" title="Definizioni Lingua" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="ph-flag"></i>
						</a>
						<a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" data-trigger="#my_offcanvas1" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
							<i class="icon-question7" style="color: orange;"></i>
						</a>
	                </div>
				</div>
			</div>
		</div> 

        <!-- Profile navigation -->
		<div class="navbar navbar-expand-lg border-top py-1">
			<div class="container-fluid">
				<ul class="nav navbar-nav flex-row flex-fill" role="tablist">
					<li class="nav-item me-1" role="presentation">
						<a href="#config-a" class="navbar-nav-link navbar-nav-link-icon navbar-sm active rounded" data-bs-toggle="tab" aria-selected="true" role="tab">
							<div class="d-flex align-items-center mx-lg-1">
								<i class="icon-menu7 me-2"></i>
								<span class="d-none d-lg-inline-block ms-2"><Translate :path="'dizionario.base.ELENCO'"></Translate>  Tipologie</span>
							</div>
						</a>
					</li>
				</ul>
				<div class="navbar-collapse collapse" id="profile_nav">
					<ul class="navbar-nav ms-lg-auto mt-2 mt-lg-0">
						<li class="nav-item dropdown ms-lg-1">

						</li>
						<li class="nav-item ms-3">
							<SelectLingue class="form-select form-select-sm" @change="getTravelTipologiche()" v-model="currentLang">
							</SelectLingue>
						</li>
					</ul>
			   </div>
			</div>
		</div>
		<!-- /profile navigation -->	

	</div>
	<!-- /page header -->
	
	<!-- Content area -->
	<div class="content content-1">
        <div class="tab-content flex-1 order-2 order-lg-1">

            <div class="tab-pane fade active show" id="config-a">
                <div class="card shadow mb-0">
                    <div class="card-body mt-0 mb-0">
                        <nav class="tabbable">
                            <ul class="nav nav-tabs nav-tabs-underline mt-0 mb-1" id="tabs" role="tablist" style="margin-top:14px">
                                <li class="nav-item" v-bind:class="{'active':tipoU.ID_TIPOLOGICA == 1}" v-for="tipoU in paginatedListTipologiche.arr" v-bind:key="tipoU.ID_TIPOLOGICA" v-on:click="selezionaCurrentTipologica(tipoU)">
                                    <a class="nav-link isprint-tab" data-bs-toggle="tab" :data-tab="'opzioni_'+tipoU.ID_TIPOLOGICA" :href="'#opzioni_'+tipoU.ID_TIPOLOGICA" role="tab" :aria-controls="'opzioni_'+tipoU.ID_TIPOLOGICA" aria-selected="true">
                                        {{tipoU.DESCRIZIONE}}&nbsp;&nbsp;&nbsp;<small class="text-warning" v-on:click="modificaTipologica(tipoU)">[{{ tipoU.ID_TIPOLOGICA }}]</small>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div class="tab-content">

                            <div class="tab-pane active" role="tabpanel" style="padding: 0;">
                                <div class="alert alert-2 alert-secondary alert2 border-0">
                                    <form class="">
                                        <div class="row">
                                            <div class="col-12 col-md-2 form-group-filter">
                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="searchOpzioni.DESCRIZIONE.VALUE">
                                            </div>           
                                            <div class="col-12 col-md-2 form-group-filter">
                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Icona" type="text" v-model="searchOpzioni.ICONA.VALUE">
                                            </div>          
                                            <div class="col-12 col-md-2 form-group-filter">
                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Custom 1" type="text" v-model="searchOpzioni.CUSTOM_1.VALUE">		
                                            </div>         
                                            <div class="col-12 col-md-2 form-group-filter">
                                                <input autocomplete="off" class="form-control form-control-sm" placeholder="Custom 2" type="text" v-model="searchOpzioni.CUSTOM_2.VALUE">		
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="row">
                                                    <div class="d-none d-sm-block col-md-6 form-group-filter">
                                                    </div>
                                                    <div class="col-6 col-md-3 form-group-filter">
                                                        <select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="13" v-model="searchOpzioni.FK_ID_LINGUA.VALUE" @change="selezionaCurrentTipologica(currentTipologica)">
                                                            <option v-for="lingua in $root.arrayLingue" v-bind:value="lingua.ID_GEO_STATO" v-bind:key="lingua.ID_GEO_STATO">{{lingua.NOME}}</option>														
                                                        </select>
                                                    </div>
                                                    <div class="col-6 col-md-3 form-group-filter">
                                                        <select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="13" v-model="searchOpzioni.FLG_ATTIVO.VALUE">
                                                            <option value="">Tutti </option>   
                                                            <option value="1"> &nbsp; Attivati</option>
                                                            <option value="0"> &nbsp; Disattivati</option>
                                                        </select>
                                                    </div> 
                                                </div>
                                            </div>  
                                        </div>
                                    </form>
                                </div> 

                                <div class="box-tipologoca mt-2">                                
                                    <table class="table table-bordered table-hover table-striped table-togglable">
					                    <thead class="sticky-top">
					                        <tr class="bg-secondary text-white">
                                                <th class="table-head-sm" style="width: 6%;">#</th>
                                                <th class="table-head-sm" style="">DESCRIZIONE LANG</th> 
                                                <th class="table-head-sm" style="width: 20%;">Icona</th>  
                                                <th class="table-head-sm" style="width: 20%;">Custom 1</th>  
                                                <th class="table-head-sm" style="width: 20%;">Custom 2</th>  
                                                <th class="table-head-sm text-center" style="width: 5.4%;">Attivo</th> 
                                                <th class="table-head-sm text-center" style="width: 10%;">
													<div class="btn btn-light btn-sm" v-on:click="nuovaOpzione()"><i class="fa fa-plus fa-fw"></i></div>
												</th> 
                                            </tr>
                                        </thead> 
                                        <tbody>
                                            <tr v-for="opzione in paginatedListOpzioni.arr" v-bind:key="opzione.ID_OPZIONE_TIPOLOGICA">
                                                <td class="td-class fs-12" style="height:2.72rem;">                                                
                                                    {{opzione.ID_OPZIONE_TIPOLOGICA}}
                                                    <span v-show="opzione.ID_OPZIONE_TIPOLOGICA_LANG == 0 && opzione.ID_OPZIONE_TIPOLOGICA > 0" class="badge badge-danger ms-4">NON SALVATO</span>
                                                </td>
                                                <td class="td-class"><input autocomplete="off" class="form-control form-control-sm" placeholder="Descrizione" type="text" v-model="opzione.DESCRIZIONE"></td> 
                                                <td class="td-class"><input autocomplete="off" class="form-control form-control-sm" placeholder="Icona" type="text" v-model="opzione.ICONA"></td> 
                                                <td class="td-class"><input autocomplete="off" class="form-control form-control-sm" placeholder="Custom 1" type="text" v-model="opzione.CUSTOM_1"></td> 
                                                <td class="td-class"><input autocomplete="off" class="form-control form-control-sm" placeholder="Custom 2" type="text" v-model="opzione.CUSTOM_2"></td>  
                                                <td class="td-class text-center">
                                                    <ISwitch @toggle="updateStatoOpzione(opzione)" :size="'sm'" v-model="opzione.FLG_ATTIVO"></ISwitch>                                           
                                                </td>                                              
                                                <td class="td-class text-center">                                                
                                                    <button class="badge bg-success border-0 me-2" v-on:click="salvaOpzione(opzione)"><i class="fal fa-save fa-fw"></i></button>
                                                    <button v-show="currentTipologica.ID_TIPOLOGICA == 45" class="badge bg-red border-0 me-2" v-on:click="rtsType(opzione)"><i class="fal fa-cogs fa-fw"></i></button>
                                                    <button class="badge bg-danger border-0 me-2" v-on:click="deleteOpzione(opzione)"><i class="fal fa-trash fa-fw"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                
		                		<div class="row page-bottom">
                                    <div class="col-8 col-md-11" :tabindex="27">
                                        <div class="float-end">
                                            <Pagine v-model="searchOpzioni.PAGINATION.PAGE_NUMBER" :numrighe="paginatedListOpzioni.count" :numpagine="searchOpzioni.PAGINATION.LENGTH"></Pagine>
                                        </div>
                                    </div>
                                    <div class="col-4 col-md-1" :tabindex="28">
                                        <select class="form-select form-select-sm wmin-100 mt-2 float-end" style="position: relative; top: 1px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="75">75</option>
                                            <option value="100">100</option>
                                            <option value="250">250</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>


                <!-- START MADALI -->
                <div class="modal fade" id="popUpTipologica" tabindex="-1"  role="dialog"  aria-modal="true" >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header modal-header-2 bg-light text-dark">
                                <h6 class="modal-title">Tipologica</h6>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                            </div>
                            <div class="modal-body">

                                <div class="form-group">
                                    <label>Descrizione</label>
                                    <input type="text" class="form-control"  placeholder="Descrizione" v-model="tipologicaSelezionata.DESCRIZIONE">
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Stato</label>
                                            <select class="form-control" v-model="tipologicaSelezionata.STATO">
                                                <option v-bind:value="0">NON ATTIVO</option>
                                                <option v-bind:value="1">ATTIVO</option>
                                            </select>
                                        </div>                               
                                    </div>
                                </div>

                            </div>	
                            <div class="modal-footer modal-footer-1 bg-light">
                                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                                <button class="btn btn-success float-end btn-sm" v-on:click="salvaTipologica()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                            </div>
                        </div>
                    </div>
                </div>
                        
                
                <div class="modal fade" id="popUpOpzioniTipologica" tabindex="-1"  role="dialog"  aria-modal="true" >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header modal-header-2 bg-light text-dark">
                                <h6 class="modal-title">Opzioni tipologica {{ tipologicaSelezionata.DESCRIZIONE }}</h6>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                            </div>
                            <div class="modal-body">

                            </div>	
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="popUpRtsType" tabindex="-1"  role="dialog"  aria-modal="true" >
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header modal-header-2 bg-light text-dark">
                                <h6 class="modal-title">Opzioni tipologica {{ tipologicaSelezionata.DESCRIZIONE }}</h6>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">X</button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>Min pax</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MIN_PAX">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Max pax</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MAX_PAX">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Max pax ADU</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MAX_ADU">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Max pax CHD</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MAX_CHD">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Max pax INF</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MAX_INF">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Min pax ADU</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MIN_ADU">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Min pax CHD</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MIN_CHD">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Min pax INF</label>
                                    <select class="form-control" v-model="rtsTypeSelezionato.MIN_INF">
                                        <option v-for="(i,index) in 10" v-bind:value="index" v-bind:key="index">{{ index }}</option>
                                    </select>
                                </div>
                            </div>	
                            <div class="modal-footer modal-footer-1 bg-light">
                                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i class="fal fa-sign-out-alt fa-fw"></i> CHIUDI</button>
                                <button class="btn btn-success float-end btn-sm" v-on:click="salvaRtsType()"><i class="fal fa-save fa-fw"></i> SALVA</button>                                   
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
	</div>				

</template>



<script>

import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'
import SelectLingue  from '@/components/utils/SelectLingue.vue'

export default {
    name:"ConfTravelTipologiche",
    components:{
        Pagine,
        ISwitch,
		SelectLingue 
    
    },
    data: function () {
        return {   
			filters:global.filters,
            arrayLivelli: new Array(),
            arrayTipologiche : new Array(),
			tipologicaSelezionata : {opzioni:new Array()},
            FK_ID_MODULO_TIPOLOGICHE:0,
            currentTipologica:0,
            rtsTypeSelezionato:{},
            currentLang:0,
            search:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
                STATO:{TYPE:'COMBO',VALUE:""}, 
            },
			searchOpzioni:{
                PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
                DESCRIZIONE:{TYPE:'TEXT',VALUE:''},
                ICONA:{TYPE:'TEXT',VALUE:''},
                CUSTOM_1:{TYPE:'TEXT',VALUE:''},
                CUSTOM_2:{TYPE:'TEXT',VALUE:''},
                FLG_ATTIVO:{TYPE:'COMBO',VALUE:1}, 
                FK_ID_LINGUA:{TYPE:'COMBO',VALUE:""}, 
            },
        }
    },

    methods: {
        getTravelTipologiche: function () {
            utils.ajax("travel/tipologiche",{FK_ID_MODULO:this.FK_ID_MODULO_TIPOLOGICHE}, (response) => {
                this.arrayTipologiche = response.data;
            });
        },
        nuovaTipologica:function(){
            this.tipologicaSelezionata = utils.nuovoElemento(this.tipologicaSelezionata,{ID_TIPOLOGICA:0,opzioni:new Array(),STATO:1,FK_ID_MODULO:this.FK_ID_MODULO_TIPOLOGICHE,FK_ID_LIVELLO:0,FK_ID_ENTE:1},"popUpTipologica");
        },
        modificaTipologica:function(tipologica){
			utils.ajax("travel/tipologica",{ID_TIPOLOGICA:tipologica.ID_TIPOLOGICA}, (response) => {
				this.tipologicaSelezionata = utils.clone(response.data);
				this.tipologicaSelezionata = utils.modificaElemento(this.tipologicaSelezionata,this.tipologicaSelezionata,"popUpTipologica");
            });           
        },
        selezionaCurrentTipologica:function(tipologica){
            this.currentTipologica = utils.clone(tipologica);
			utils.ajax("travel/tipologica",{ID_TIPOLOGICA:tipologica.ID_TIPOLOGICA,LANG:this.searchOpzioni.FK_ID_LINGUA.VALUE}, (response) => {
				this.tipologicaSelezionata = utils.clone(response.data);
            });           
        },
        salvaTipologica:function(){
            utils.salvaElemento("travel/tipologica/",this.tipologicaSelezionata,"ID_TIPOLOGICA","popUpTipologica",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.getTravelTipologiche();
                }
            });
        },
		nuovaOpzione : function(){
			this.tipologicaSelezionata.opzioni.push({ID_OPZIONE_TIPOLOGICA:0,FK_ID_TIPOLOGICA:this.tipologicaSelezionata.ID_TIPOLOGICA,FLG_ATTIVO:1});
		},
		salvaOpzione : function(opzioneSelezionata){
            console.log(opzioneSelezionata);
			var api = opzioneSelezionata.ID_OPZIONE_TIPOLOGICA == 0  ? "travel/tipologica/opzione/insert" : "travel/tipologica/opzione/update";
			utils.ajax(api,opzioneSelezionata, (response) => {
				if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    utils.ajax("travel/tipologica",{ID_TIPOLOGICA:this.tipologicaSelezionata.ID_TIPOLOGICA,LANG:this.searchOpzioni.FK_ID_LINGUA.VALUE}, (response) => {
						this.tipologicaSelezionata = utils.clone(response.data);
					});
                }
            });
		},
		deleteOpzione : function (opzioneSelezionata){
			utils.alert.confirm("Sei sicuro di voler eliminare questo incasso?",()=>{
				utils.ajax("travel/tipologica/opzione/delete",opzioneSelezionata, (response) => {
					if (response.esito == 0){
						utils.alert.success(response.messaggio);
						utils.ajax("travel/tipologica",{ID_TIPOLOGICA:this.tipologicaSelezionata.ID_TIPOLOGICA}, (response) => {
							this.tipologicaSelezionata = utils.clone(response.data);
						});
					}
				});
            },this.$root);
		},
		opzioniTipologica:function(tipologica){
			utils.ajax("travel/tipologica/",{ID_TIPOLOGICA:tipologica.ID_TIPOLOGICA}, (response) => {
				this.tipologicaSelezionata = utils.clone(response.data);
				this.tipologicaSelezionata = utils.modificaElemento(this.tipologicaSelezionata,this.tipologicaSelezionata,"popUpOpzioniTipologica");
            });           
        },
		updateStatoTipologica : function(tipologica){
			setTimeout(() => {
				var t = utils.clone(tipologica);
				t.opzioni = new Array();
				this.tipologicaSelezionata = t;
				this.salvaTipologica(tipologica);
			}, 300);
		},
        updateStatoOpzione : function(opzione){
            setTimeout(() => {
				var t = utils.clone(opzione);
				this.opzioneSelezionata = t;
				this.salvaOpzione(opzione);
			}, 300);
        },
        getModuleName : function(){
            return filters.moduleName(this.FK_ID_MODULO_TIPOLOGICHE);
        },
        getModuleRoute : function(){
            return filters.moduleRoute(this.FK_ID_MODULO_TIPOLOGICHE);
        },
        rtsType : function(opzione){
            utils.ajax("travel/rtstype",{ID_OPZIONE_TIPOLOGICA:opzione.ID_OPZIONE_TIPOLOGICA}, (response) => {
                this.rtsTypeSelezionato = utils.clone(response.data);
                this.filters.openPopUp("popUpRtsType");     
            });   
        },
        salvaRtsType:function(){
            utils.salvaElemento("travel/rtstype/",this.rtsTypeSelezionato,"ID_RTSTYPE","popUpRtsType",(response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                }
            });
        },
    },

    computed: {
        paginatedListTipologiche(){
            var arr = utils.inGrid(this.arrayTipologiche,this.search);
            return arr;
        },
		paginatedListOpzioni(){
            var arr = utils.inGrid(this.tipologicaSelezionata.opzioni,this.searchOpzioni);
            return arr;
        },
    },

    created: function () {
        this.FK_ID_MODULO_TIPOLOGICHE = this.$root.FK_ID_MODULO_TIPOLOGICHE;
        this.$root.FK_ID_MODULO_TIPOLOGICHE = 0;
        this.searchOpzioni.FK_ID_LINGUA.VALUE = this.$root.arrayLingue[0].ID_GEO_STATO;
        this.currentLang = this.$root.currentLang;
        this.getTravelTipologiche();
    },

}
</script>