<template>
    <div>
        
        <!-- Page header -->
        <div class="page-header page-header-light shadow">
            <div class="page-header-content d-lg-flex">
                <div class="d-flex">
                    <h4 class="page-title mb-0">
                        <router-link href="#" class="d-inline-block text-dark cursor-pointer" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
                        <span class="text-muted">Dashboar {{ $root.VERSIONE }}</span>
                    </h4>
                    <a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
                        <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
                    </a>
                </div>
                <div class="collapse d-lg-block my-lg-auto ms-lg-auto text-center" id="page_header">
                    <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
                        
                        <a href="#" class="d-inline-flex align-items-center text-body lh-1 py-sm-2 me-2 position-10" style="text-align: left;">
                        
                        </a>

                        <div class="vr flex-shrink-0 my-2 mx-3"></div>

                        <div class="d-inline-flex mb-xs-1 mt-sm-0">
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="filters.openRoute('/Statistiche')" title="Statistiche" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="ph-lightning"></i>
                            </a>
                            <a href="#" class="btn btn-outline-light btn-icon w-32px h-32px squared-pill ms-2" v-on:click="$root.help(57)" title="Help OnLIne" data-bs-toggle="tooltip" data-bs-placement="left">
                                <i class="icon-question7" style="color: orange;"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <!-- Content area -->
        <div class="content content-1">

            <div class="row">
                <div class="col-lg-9">

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="card card bg-light shadow">
                                <div class="card-header bg-indigo text-white mb-0 pt-1 pb-1">
                                    <h2 class="card-title mb-0"><Translate :path="'dizionario.base.BENVENUTO'"></Translate> {{utente.USERNAME}}</h2>
                                </div>
                                <div class="card-body">
                                    <p><Translate :path="'dizionario.base.SYS_PRONTO'"></Translate></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-12 col-lg-4">
                                    <div class="card card-body">
                                        <div class="d-flex align-items-center mb-3">
                                            <div class="flex-fill">
                                                <h6 class="mb-0">Nome Server</h6>
                                                <span class="text-muted">Until 1st of June</span>
                                            </div>

                                            <i class="ph-gear ph-2x text-indigo opacity-75 ms-3"></i>
                                        </div>
                                        <div class="progress mb-2" style="height: 0.125rem;">
                                            <div class="progress-bar bg-indigo" style="width: 67%"></div>
                                        </div>
                                        <div>
                                            <span class="float-end">67%</span>
                                            Re-indexing
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="card card-body">
                                        <div class="d-flex align-items-center mb-3">
                                            <div class="flex-fill">
                                                <h6 class="mb-0">Services status</h6>
                                                <span class="text-muted">April, 19th</span>
                                            </div>
                                            <i class="ph-activity ph-2x text-danger opacity-75 ms-3"></i>
                                        </div>
                                        <div class="progress mb-2" style="height: 0.125rem;">
                                            <div class="progress-bar bg-danger" style="width: 80%"></div>
                                        </div>
                                        <div>
                                            <span class="float-end">80%</span>
                                            Partly operational
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="card card-body">
                                        <div class="d-flex align-items-center mb-3">
                                            <i class="ph-gear ph-2x text-primary opacity-75 me-3"></i>
                                            <div class="flex-fill">
                                                <h6 class="mb-0">Server maintenance</h6>
                                                <span class="text-muted">Until 1st of June</span>
                                            </div>
                                        </div>
                                        <div class="progress mb-2" style="height: 0.125rem;">
                                            <div class="progress-bar bg-primary" style="width: 67%"></div>
                                        </div>
                                        <div>
                                            <span class="float-end">67%</span>
                                            Re-indexing
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div class="row">
                        <div class="col-lg-12">

                            <div class="card mb-3">
                                <div class="card-header d-sm-flex align-items-sm-center py-sm-0">
                                    <h5 class="py-sm-2 my-sm-1"><span class="me-2">Gestione Tickets:</span><span v-if="$root.enteCorrente != undefined">{{ $root.enteCorrente.LABEL }}</span></h5>
                                    <div class="mt-2 mt-sm-0 ms-sm-auto">
                                        <select class="form-select" id="select_date">
                                            <option value="val1">June, 29 - July, 5</option>
                                            <option value="val2">June, 22 - June 28</option>
                                            <option value="val3" selected="">June, 15 - June, 21</option>
                                            <option value="val4">June, 8 - June, 14</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <ul class="nav nav-tabs nav-tabs-underline nav-justified mb-0" role="tablist">
                                        <li class="nav-item" role="presentation"><a href="#mind-1" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">NUOVI</a></li>
                                        <li class="nav-item" role="presentation"><a href="#mind-2" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">IN LAVORAZIONE</a></li>
                                        <li class="nav-item" role="presentation"><a href="#mind-3" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">EVASI</a></li>
                                        <li class="nav-item" role="presentation"><a href="#mind-4" class="nav-link" data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1">ANNULLATI</a></li>
                                    </ul>
                                    <div class="tab-content card-body">

                                        <div class="tab-pane fade active show" id="mind-1" role="tabpanel"> 
                                            <div class="bg-light mt-1">
                                                <p class="fs-lg fw-bold p-1">
                                                    <i class="icon-calendar me-2"></i>Elenco Ordini di Oggi
                                                    <span class="ms-1">(93)</span>
                                                </p> 
                                            </div>                                        
                                            <div class="table-responsive">
                                                <div class="box-memo">
                                                    <table class="table text-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 50px">Due</th>
                                                                <th style="width: 300px;">User</th>
                                                                <th>Description</th>
                                                                <th class="text-center" style="width: 20px;">
                                                                    <i class="ph-dots-three"></i>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td class="text-center">
                                                                    <h6 class="mb-0">12</h6>
                                                                    <div class="fs-sm text-muted lh-1">hours</div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                            <span class="letter-icon">AD</span>
                                                                        </a>
                                                                        <div>
                                                                            <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                            <div class="d-flex align-items-center text-muted fs-sm">
                                                                                <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                                Blocker
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="text-body">
                                                                        <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                        <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                            <i class="ph-list"></i>
                                                                        </a>
                                                                        <div class="dropdown-menu dropdown-menu-end">
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-arrow-bend-up-left me-2"></i>
                                                                                Quick reply
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-clock-counter-clockwise me-2"></i>
                                                                                Full history
                                                                            </a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-checks text-success me-2"></i>
                                                                                Resolve issue
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-x text-danger me-2"></i>
                                                                                Close issue
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>   
                                        </div>

                                        <div class="tab-pane fade" id="mind-2" role="tabpanel">  
                                            <div class="bg-light mt-1">
                                                <p class="fs-lg fw-bold p-1">
                                                    <i class="icon-calendar me-2"></i>Elenco Ordini di Oggi
                                                    <span class="ms-1">(93)</span>
                                                </p> 
                                            </div>                                        
                                            <div class="table-responsive">
                                                <div class="box-memo">
                                                    <table class="table text-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 50px">Due</th>
                                                                <th style="width: 300px;">User</th>
                                                                <th>Description</th>
                                                                <th class="text-center" style="width: 20px;">
                                                                    <i class="ph-dots-three"></i>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td class="text-center">
                                                                    <h6 class="mb-0">12</h6>
                                                                    <div class="fs-sm text-muted lh-1">hours</div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                            <span class="letter-icon">AD</span>
                                                                        </a>
                                                                        <div>
                                                                            <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                            <div class="d-flex align-items-center text-muted fs-sm">
                                                                                <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                                Blocker
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="text-body">
                                                                        <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                        <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                            <i class="ph-list"></i>
                                                                        </a>
                                                                        <div class="dropdown-menu dropdown-menu-end">
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-arrow-bend-up-left me-2"></i>
                                                                                Quick reply
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-clock-counter-clockwise me-2"></i>
                                                                                Full history
                                                                            </a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-checks text-success me-2"></i>
                                                                                Resolve issue
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-x text-danger me-2"></i>
                                                                                Close issue
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>                                            
                                        </div>

                                        <div class="tab-pane fade" id="mind-3" role="tabpanel">
                                            <div class="bg-light mt-1">
                                                <p class="fs-lg fw-bold p-1">
                                                    <i class="icon-calendar me-2"></i>Elenco Ordini di Oggi
                                                    <span class="ms-1">(93)</span>
                                                </p> 
                                            </div>                                        
                                            <div class="table-responsive">
                                                <div class="box-memo">
                                                    <table class="table text-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 50px">Due</th>
                                                                <th style="width: 300px;">User</th>
                                                                <th>Description</th>
                                                                <th class="text-center" style="width: 20px;">
                                                                    <i class="ph-dots-three"></i>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td class="text-center">
                                                                    <h6 class="mb-0">12</h6>
                                                                    <div class="fs-sm text-muted lh-1">hours</div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                            <span class="letter-icon">AD</span>
                                                                        </a>
                                                                        <div>
                                                                            <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                            <div class="d-flex align-items-center text-muted fs-sm">
                                                                                <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                                Blocker
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="text-body">
                                                                        <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                        <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                            <i class="ph-list"></i>
                                                                        </a>
                                                                        <div class="dropdown-menu dropdown-menu-end">
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-arrow-bend-up-left me-2"></i>
                                                                                Quick reply
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-clock-counter-clockwise me-2"></i>
                                                                                Full history
                                                                            </a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-checks text-success me-2"></i>
                                                                                Resolve issue
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-x text-danger me-2"></i>
                                                                                Close issue
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>   
                                        </div>

                                        <div class="tab-pane fade" id="mind-4" role="tabpanel">
                                            <div class="bg-light mt-1">
                                                <p class="fs-lg fw-bold p-1">
                                                    <i class="icon-calendar me-2"></i>Elenco Ordini di Oggi
                                                    <span class="ms-1">(93)</span>
                                                </p> 
                                            </div>                                        
                                            <div class="table-responsive">
                                                <div class="box-memo">
                                                    <table class="table text-nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 50px">Due</th>
                                                                <th style="width: 300px;">User</th>
                                                                <th>Description</th>
                                                                <th class="text-center" style="width: 20px;">
                                                                    <i class="ph-dots-three"></i>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <td class="text-center">
                                                                    <h6 class="mb-0">12</h6>
                                                                    <div class="fs-sm text-muted lh-1">hours</div>
                                                                </td>
                                                                <td>
                                                                    <div class="d-flex align-items-center">
                                                                        <a href="#" class="d-inline-flex align-items-center justify-content-center bg-teal text-white lh-1 rounded-pill w-40px h-40px me-3">
                                                                            <span class="letter-icon">AD</span>
                                                                        </a>
                                                                        <div>
                                                                            <a href="#" class="text-body fw-semibold letter-icon-title">Annabelle Doney</a>
                                                                            <div class="d-flex align-items-center text-muted fs-sm">
                                                                                <span class="bg-danger rounded-pill p-1 me-2"></span>
                                                                                Blocker
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a href="#" class="text-body">
                                                                        <div class="fw-semibold">[#1183] Workaround for OS X selects printing bug</div>
                                                                        <span class="text-muted">Chrome fixed the bug several versions ago, thus rendering this...</span>
                                                                    </a>
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="dropdown">
                                                                        <a href="#" class="text-body" data-bs-toggle="dropdown">
                                                                            <i class="ph-list"></i>
                                                                        </a>
                                                                        <div class="dropdown-menu dropdown-menu-end">
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-arrow-bend-up-left me-2"></i>
                                                                                Quick reply
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-clock-counter-clockwise me-2"></i>
                                                                                Full history
                                                                            </a>
                                                                            <div class="dropdown-divider"></div>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-checks text-success me-2"></i>
                                                                                Resolve issue
                                                                            </a>
                                                                            <a href="#" class="dropdown-item">
                                                                                <i class="ph-x text-danger me-2"></i>
                                                                                Close issue
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>   
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
                <div class="col-sm-6 col-xl-3">
                    
                    <div class="card">
                        <ul class="nav nav-tabs nav-tabs-underline nav-justified mb-0" role="tablist">
                            <li class="nav-item" role="presentation"><a href="#tab-desc" class="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">Overview</a></li>
                            <li class="nav-item" role="presentation"><a href="#tab-spec" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">Specs</a></li>
                            <li class="nav-item" role="presentation"><a href="#tab-shipping" class="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">Shipping</a></li>
                        </ul>

                        <div class="tab-content card-body">
                            <div class="tab-pane fade show active" id="tab-desc" role="tabpanel">
                                Aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthet.
                            </div>

                            <div class="tab-pane fade" id="tab-spec" role="tabpanel">
                                Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid laeggin.
                            </div>

                            <div class="tab-pane fade" id="tab-shipping" role="tabpanel">
                                DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred pitchfork. Williamsburg whatever.
                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </div>

    </div>        
</template>



<script>
//import DatePicker from '@/components/utils/DatePicker.vue'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default {
    name:'Dashboard1',	

    components:{
        //ckeditor: ClassicEditor.component,
    },					

    props: ['todo'],		

        data:function(){
        return{
            filters:global.filters,
            t:global.t,
            utente:{},
            today: new Date().toISOString().split('T')[0],
            startInterval:{},
            arrayRisultatiRicerca:new Array(),
        }
    },

    methods:{
        goToRoute: function(route){
            global.router.push(route);
        }, 

        varianti : function(){
            this.$root.appoggio.prodotto = {ID_PRODOTTO:1};
            this.$root.changeRoute('varianti');
        },

        ricercaResultHandler : function(data){
            this.arrayRisultatiRicerca = new Array();
            this.arrayRisultatiRicerca = data.RISULTATI;
        },

        testAlert : function(){   
            utils.alert.success("ciaone");
            utils.alert.warning("ciaone");
            utils.alert.info("ciaone");
            utils.alert.primary("ciaone");
            utils.alert.danger("ciaone");
        },

        openRoute : function(route){
            global.router.push(route);
        },
      
    },

    created: function () {
        this.utente = this.$root.utente;

    },


}
</script>