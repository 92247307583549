<template>
    <div>

        <!-- Page header -->
        <div class="page-header page-header-light">
            <div class="page-header-content header-elements-lg-inline">
                <div class="page-title d-flex">
                    <h4 class="d-none d-sm-block">
                        <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link>
						<a href="#" class="d-inline-block text-dark"><span class="font-weight-semibold"> Tessere </span></a> \ 
						<router-link href="#" class="d-inline-block text-dark" :to="'ListCards'"><span class="font-weight-semibold"> Elenco Stagioni </span></router-link> \ 
                        <span class="text-muted">Elenco Range</span>
						<code> - Lingua italiano</code> 
                    </h4>
                    <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Range </h4>
                    <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
                </div>
                <div class="header-elements d-none">
                    <div class="d-flex justify-content-center">
                        <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovoRange()"><i class="icon-googleplus5 text-success"></i><span>NUOVO</span></a>
                        <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovaAssicurazione()"><i class="icon-plus-circle2"></i><span>ASSICURAZIONI</span></a>
                        <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovaStagione()"><i class="icon-bars-alt"></i><span>STAGIONI</span></a>
	                    <div class="btn-group ms-1 position-static">
							<button type="button" class="btn btn-link btn-float text-body dropdown-toggle dropdown-toggle-2" data-bs-toggle="dropdown" aria-expanded="false">
								<i class="icon-power2"></i> <span>OPZIONI</span>
							</button>
							<div class="dropdown-menu dropdown-menu-right" style="">
								<a href="#" class="dropdown-item"><i class="icon-cube4"></i> Configurazione Modulo</a>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item" v-on:click="$root.confTravelTipologiche()"><i class="icon-grid"></i> Configurazione Plug-In</a>
							</div>
						</div>	                    
                        <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /page header -->

        <!-- Content area -->
		<div class="content content-2">

			<div class="card shadow mb-0">
                <div class="card-header card-header-2 bg-transparent header-elements-sm-inline py-sm-0">
                    <h6 class="card-title py-sm-2 d-none d-sm-block">
						<div class="btn-group position-static">
							<button type="button" class="btn btn-light btn-labeled btn-labeled-left dropdown-toggle btn-sm me-4" data-bs-toggle="dropdown" aria-expanded="false" :tabindex="1"><b><i class="icon-cog5 "></i></b> Azioni Multiple</button>
							<div class="dropdown-menu dropdown-menu-left dropdown-menu-sm" style="">
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Stato</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-thumbs-up2"></i> Attiva tutti</a>
										<a href="#" class="dropdown-item"><i class="icon-thumbs-down2"></i> Disattiva tutti</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item"><i class="icon-cog5"></i> Modifiche varie</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<div class="dropdown-submenu">
									<a href="#" class="dropdown-item"><i class="icon-file-excel"></i> Esporta dati</a>
									<div class="dropdown-menu">
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Pagina corrente</a>
										<a href="#" class="dropdown-item"><i class="icon-menu7"></i> Campi selezionati</a>
										<a href="#" class="dropdown-item"><i class="icon-screen-full"></i> Tutti i campi</a>
									</div>
								</div>
								<div class="dropdown-divider"></div>
								<a href="#" class="dropdown-item"><i class="icon-trash"></i> Elimina</a>
							</div>
						</div>
                    </h6>
					<div class="header-elements">
						<form action="#" class="me-3 cell-150" :tabindex="1">
							<select class="form-control form-control-sm" data-placeholder="Seleziona Anno">
								<option>Anno</option>
							</select>
						</form>
						<div class="list-icons">
							<a class="list-icons-item" data-action="collapse"></a>
						</div>
					</div>
				</div>
				<div class="collapse show">
					<div class="card-body card-body-2">
						<div class="alert alert-2 alert-secondary alert2 border-0">
							<form action="#" class="">
								<div class="row ">
									<div class="col-4 col-md-1 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id stagione" :tabindex="1" />
									</div>	
									<div class="col-8 col-md-2 form-group-filter">
										<input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Descrizione" :tabindex="1" />
									</div>               
									<div class="d-none d-sm-block col-md-2 form-group-filter">

									</div>              
									<div class="d-none d-sm-block col-md-3 form-group-filter">

									</div>	
									<div class="col-12 col-md-4">
										<div class="row">
											<div class="d-none d-sm-block col-md-5 form-group-filter">
											</div> 
											<div class="col-6 col-md-4 form-group-filter">
												<select class="form-control form-control-sm" data-placeholder="Stato" :tabindex="5">
													<option value="">Stato </option>   
													<option value="1"> &nbsp; Attivati</option>
													<option value="0"> &nbsp; Disattivati</option>
												</select>
											</div>
											<div class="col-6 col-md-3 form-group-filter">
												<a href="#collapse-icon" class="btn btn-secondary btn-sm btn-block collapsed" data-bs-toggle="collapse" aria-expanded="false" :tabindex="14">
													<span style="font-weight: 500;">Altri Filtri</span>
												</a>
											</div>  
										</div>
									</div>  
								</div>
								<div class="collapse" id="collapse-icon" style="">
									<div class="row">	
										<div class="col-6 col-md-2 form-group-filter">
										</div>  
										<div class="col-6 col-md-2 form-group-filter">
										</div>
										<div class="col-6 col-md-2 form-group-filter">
										</div>
										<div class="col-6 col-md-2 form-group-filter">
										</div>
										<div class="col-6 col-md-2 form-group-filter">
										</div>
										<div class="col-6 col-md-2 form-group-filter">
										</div>
									</div>
								</div>	
							</form>
						</div> 
					</div>
				</div>
				<div class="card-body card-body-2 card-body-nobottom card-body-notop">
					<div class="tableFixHead">
						<table class="table table-bordered table-hover table-striped table-togglable"> 
							<thead>
								<tr>
									<th class="table-head-sm" rowspan="2" style="width:2%;"> <input id="chkAllCategorie" v-on:click="filters.selectAll('chkAllCategorie',paginatedList.arr)" type="checkbox" class=""> </th>
									<th class="table-head-sm" rowspan="2" style="width:6%;"> # </th>
									<th class="table-head-sm" rowspan="2" style="">Ente / Ragione Sociale</th>
									<th class="table-head-sm text-center" rowspan="2" style="width:7%;">Range start</th>
									<th class="table-head-sm text-center" rowspan="2" style="width:7%;">Range end</th>
									<th class="table-head-sm text-center" colspan="3" style="">RASPA</th>
									<th class="table-head-sm text-center" colspan="4" style="">TESSERE GENERICHE</th>
									<th class="table-head-sm text-center" rowspan="2" style="width:5%;">Stato</th>
									<th class="table-head-sm" rowspan="2" style="width:5.4%;">Azioni</th>
								</tr>
								<tr>
									<th class="table-head-sm text-center" style="width:6%;">Emesse<br /><span class="badge bg-light rounded-0">5698</span></th>
									<th class="table-head-sm text-center" style="width:6%;">Usate<br /><span class="badge bg-light rounded-0">5698</span></th>
									<th class="table-head-sm text-center" style="width:6%;">Tipo</th>
									<th class="table-head-sm text-center" style="width:6%;">Wtp<br /><span class="badge bg-light rounded-0">5698</span></th>
									<th class="table-head-sm text-center" style="width:6%;">Wtp Attive<br /><span class="badge bg-light rounded-0">5698</span></th>
									<th class="table-head-sm text-center" style="width:6%;">GoSki<br /><span class="badge bg-light rounded-0">5698</span></th>
									<th class="table-head-sm text-center" style="width:7%;">GoSki Attive<br /><span class="badge bg-light rounded-0">5698</span></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="categoria in paginatedList.arr" v-bind:key="categoria.ID_CATEGORIA">
									<td class="text-center"><span class="footable-toggle"></span><input type="checkbox" v-bind:true-value="1" v-bind:false-value="0" v-model="categoria.SELEZIONATO" class=""> </td>
									<td class="">{{categoria.ID_CATEGORIA}}</td>
									<td class="">
										<span class="text-primary fw-bold cursor-pointer" v-on:click="modificaCategoria(categoria)">{{categoria.DESCRIZIONE}}</span><br />	                                   
										<span class="text-muted">Metti in Evidenza</span> 
									</td>
									<td class="text-center"><span class="badge bg-light">230000000</span></td>
									<td class="text-center"><span class="badge bg-light">230000399</span></td>
									<td class="text-center"><span class="badge badge-info">4528</span></td>
									<td class="text-center"><span class="badge badge-flat border-secondary text-secondary">1</span></td>
									<td class="text-center">Cartaceo</td>
									<td class="text-center"><span class="badge badge-info">1</span></td>
									<td class="text-center"><span class="badge badge-flat border-secondary text-secondary">1</span></td>
									<td class="text-center"><span class="badge badge-info">1</span></td>
									<td class="text-center"><span class="badge badge-flat border-secondary text-secondary">1</span></td>
									<td class="text-center">
										<ISwitch @toggle="updateStatoCategoria(categoria)" :size="'sm'" v-model="categoria.FLG_ATTIVO" style="margin-top:6px"></ISwitch>
									</td>
									<td class="text-center" >
										<div class="list-icons">
											<div class="dropdown">
												<a href="#" class="list-icons-item" data-bs-toggle="dropdown"><i class="icon-menu9"></i></a>
												<div class="dropdown-menu dropdown-menu-right">
													<a href="#" class="dropdown-item" v-on:click="modificaCategoria(categoria)"><i class="fal fa-pencil fa-fw"></i> Modifica Stagione</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" v-on:click="openPageCards(categoria)"><i class="far fa-credit-card fa-fw"></i> Gestione Tessere</a>
													<div class="dropdown-divider"></div>
													<a href="#" class="dropdown-item" ><i class="far fa-trash-alt fa-fw text-danger"></i> Elimina Stagione</a>
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="row">
						<div class="col-md-11" :tabindex="27">
							<div class="float-end">
								<Pagine v-model="search.PAGINATION.PAGE_NUMBER"  :numrighe="paginatedList.count"  :numpagine="search.PAGINATION.LENGTH"></Pagine>
							</div>
						</div>
						<div class="col-md-1" :tabindex="28">
							<select class="form-control wmin-100 mt-2 float-end" style="position: relative; top: 0px;" data-placeholder="Limite" :tabindex="20" v-model="search.PAGINATION.PAGE_SIZE">
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="75">75</option>
								<option value="100">100</option>
								<option value="250">250</option>
								<option value="500">500</option>
							</select>
						</div>
					</div>	

				</div>	
			</div>

		</div>
	
		<!-- Modal -->

        
    </div>
</template>



<script>
import Pagine from '@/components/utils/Pagine.vue'
import ISwitch from '@/components/utils/ISwitch.vue'

export default {
    name:"Cards",
    components:{
        Pagine,
        ISwitch,
    },
    data: function (){
      	return{
            apiStartup:'travel/categorie',
	        filters:global.filters,	
	        arrayCategorie:new Array(),
            arrayCampiPopUpCategorie : new Array(),
            categoriaSelezionata:{},
            categoriaCorrente:{},
            GLOBAL_IMAGES_PATH:'',
	        search:{
	            PAGINATION:{LENGTH:0,PAGE_NUMBER:1,PAGE_SIZE:25},
 
			},
      	}
    },

    methods: {
        getCategorie: function () {
            utils.ajax(this.apiStartup,{}, (response) => {
                this.arrayCategorie = response.data;
            }); 
        },

        nuovaCategoria : function(){ 
			this.categoriaSelezionata = {ID_CATEGORIA:0,FK_ID_MODULO:0,FLG_ATTIVO:1};
            this.filters.openPopUp("popUpCategoria"); 
        },

        modificaCategoria : function(categoria){
            this.categoriaSelezionata = utils.clone(categoria);
            this.filters.openPopUp("popUpCategoria");
        },

        salvaCategoria : function(){
            var api = this.categoriaSelezionata.ID_CATEGORIA > 0 ? "travel/categoria/update" : "travel/categoria/insert";
            utils.ajax(api,this.categoriaSelezionata, (response) => {
                if (response.esito == 0){
                    utils.alert.success(response.messaggio);
                    this.filters.closePopUp("popUpCategoria");  
                    utils.ajax(this.apiStartup,{}, (response) => {
                        this.arrayCategorie = response.data;
                    });
                }
            });
        },

		openPageCards:function(categoria){
			this.$root.categoriaSelezionata = utils.clone(categoria);
			global.router.push("Cards");
		},  

        updateStatoCategoria : function(categoria){
            setTimeout(() => {
                utils.ajax('travel/categoria/stato/update',categoria, (response) => {
                    if (response.esito == 0){
                        utils.alert.success(response.messaggio);
                        this.filters.closePopUp("popUpCategoria"); 
                    }  
                }); 
            },250);
        }
        
    },

    computed: {
        paginatedList(){
            var arr = utils.inGrid(this.arrayCategorie,this.search);
            return arr;
        },
	},

    created: function () {
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
        this.categoriaCorrente = utils.clone(this.$root.categoria);
        utils.ajax(this.apiStartup,{}, (response) => {
           this.arrayCategorie = response.data;
        });
    },

}
</script>