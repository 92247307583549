<template>

	<b class="screen-overlay"></b>

	<!-- Page content -->
	<div class="page-content">

		<!-- Main sidebar -->
		<div class="sidebar sidebar-dark sidebar-main sidebar-expand-lg" v-bind:class="{'sidebar-expand-lg':utente.ID_UTENTE > 0}" v-show="utente.ID_UTENTE > 0">

			<!-- Sidebar header -->
			<div class="sidebar-section bg-black bg-opacity-10 border-bottom border-bottom-white border-opacity-10">
				<div class="sidebar-logo d-flex justify-content-center align-items-center">
					<router-link  class="d-inline-flex align-items-center py-2" href="#" :to="'Dashboard'">
						<img src="./assets/images/logo_icon_light.png" class="sidebar-logo-icon" alt="">
                        <img src="./assets/images/logo_light.png" class="sidebar-resize-hide ms-3" height="22" alt="">
					</router-link>

					<div class="sidebar-resize-hide ms-auto">
						<button id="btnCollapseMain" type="button" class="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-control sidebar-main-resize d-none d-lg-inline-flex">
							<i class="ph-arrows-left-right"></i>
						</button>

						<button type="button" class="btn btn-flat-white btn-icon btn-sm rounded-pill border-transparent sidebar-mobile-main-toggle d-lg-none">
							<i class="ph-x"></i>
						</button>
					</div>
				</div>
			</div>
			<!-- /sidebar header -->

			<!-- Sidebar content -->
			<div class="sidebar-content" v-show="utente.ID_UTENTE > 0">

				<div class="sidebar-section">
					<div class="sidebar-section-body d-flex justify-content-center">
					</div>
				</div>

				<!-- Main navigation -->
				<div class="sidebar-section">
					<ul class="nav nav-sidebar" data-nav-type="accordion">

						<!-- Main -->
						<li class="nav-item-header pt-0">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Main</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item">
							<a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Dashboard') == true}" v-on:click="openRoute('/Dashboard')">
								<i class="ph-house"></i>
								<span>Cruscotto</span>
							</a>
						</li>

						<!-- UTENTI -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Utenti</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('UtentiClienti') == true}" v-on:click="openRoute('/UtentiClienti')"><i class="ph-users"></i><span>Gestione Clienti</span></a></li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Agenzie') == true}" v-on:click="openRoute('/Agenzie')"><i class="ph-layout"></i><span>Gestione Agenzie</span></a></li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Fornitori') == true}" v-on:click="openRoute('/Fornitori')"><i class="ph-address-book"></i><span>Gestione Fornitori</span></a></li>
						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Airlines') == true}" v-on:click="openRoute('/Airlines')"><i class="ph-star-four"></i><span>Gestione Compagnie</span></a></li>

						<!-- PRODOTTO TURISTICO -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Prodotto</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-buildings"></i>
								<span>Elenco Prodotti</span>
							</a>
							<ul class="nav-group-sub collapse">
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Activities') == true}" v-on:click="openRoute('/Activities')"><i class="fal fa-bullhorn fa-fw fa-lg"></i><span>Gestione Attivit&agrave;</span></a></li>			
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Hotels') == true}" v-on:click="openRoute('/Hotels')"><i class="fal fa-concierge-bell fa-fw fa-lg"></i><span>Gestione Hotels</span></a></li>	
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Packages') == true}" v-on:click="openRoute('/Packages')"><i class="fal fa-suitcase-rolling fa-fw fa-lg"></i><span>Gestione Pacchetti</span></a></li>			
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Flights') == true}" v-on:click="openRoute('/Flights')"><i class="fal fa-paper-plane fa-fw fa-lg"></i><span>Gestione Trasporti</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Extra') == true}" v-on:click="$root.prodotto={ID_PRODOTTO:0},openRoute('/Extra')"><i class="fal fa-paper-plane fa-fw fa-lg"></i><span>Gestione Extra</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Varianti') == true}" v-on:click="openRoute('/Varianti')"><i class="fal fa-paper-plane fa-fw fa-lg"></i><span>Gestione Varianti</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Shops') == true}" v-on:click="openRoute('/Shops')"><i class="fal fa-shopping-cart fa-fw fa-lg"></i><span>Gestione Shops</span></a></li>
							</ul>
						</li>

						<!-- GESTIONE GESTIONE -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Gestione</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-globe-hemisphere-west"></i>
								<span>Gestione Destinazioni</span>
							</a>
							<ul class="nav-group-sub collapse" style="">
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoStati') == true}" v-on:click="openRoute('/GeoStati')"><i class="fal fa-map-marker-alt fa-fw fa-lg"></i><span>Elenco Nazioni</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoRegioni') == true}" v-on:click="openRoute('/GeoRegioni')"><i class="fal fa-cloud fa-fw fa-lg"></i><span>Elenco Regioni</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoProvince') == true}" v-on:click="openRoute('/GeoProvince')"><i class="fal fa-asterisk fa-fw fa-lg"></i><span>Elenco Province</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoComuni') == true}" v-on:click="openRoute('/GeoComuni')"><i class="fal fa-home fa-fw fa-lg"></i><span>Elenco Comuni</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoAree') == true}" v-on:click="openRoute('/GeoAree')"><i class="fal fa-tag fa-fw fa-lg"></i><span>Elenco Aree Nielsen</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('GeoAeroporti') == true}" v-on:click="openRoute('/GeoAeroporti')"><i class="fal fa-location-arrow fa-fw fa-lg"></i><span>Porti / Aeroporti</span></a></li>
							</ul>
						</li> 

						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Categorie') == true}" v-on:click="openRoute('/Categorie')"><i class="ph-folder-notch-open"></i><span>Gestione Categorie</span></a></li>
						
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-notepad"></i>
								<span>Elenco Contenuti</span>
							</a>
							<ul class="nav-group-sub collapse" style="">
								<li class="nav-item" v-show="filters.hasFunzionalita(45)"><a href="#" v-on:click="openRoute('/Contenuti')" class="nav-link"><i class="fal fa-file-alt fa-fw fa-lg"></i><span>Gestione Contenuti</span></a></li>	
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Pages') == true}" v-on:click="openRoute('/Pages')"><i class="fal fa-file fa-fw fa-lg"></i><span>Gestione Pagine</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Blogs') == true}" v-on:click="openRoute('/Blogs')"><i class="fal fa-blog fa-fw fa-lg"></i><span>Gestione Blog</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('News') == true}" v-on:click="openRoute('/News')"><i class="fal fa-newspaper fa-fw fa-lg"></i><span>Gestione News</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Manuals') == true}" v-on:click="openRoute('/Manuals')"><i class="fal fa-comment-alt fa-fw fa-lg"></i><span>Gestione Manuali</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Faqs') == true}" v-on:click="openRoute('/Faqs')"><i class="fal fa-question fa-fw fa-lg"></i><span>Gestione Faqs</span></a></li>
							</ul>
						</li>

						<!-- GESTIONE MODULI -->
						<li class="nav-item-header">
							<div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">Moduli</div>
							<i class="ph-dots-three sidebar-resize-show"></i>
						</li>
						<li class="nav-item nav-item-submenu">
							<a href="#" class="nav-link">
								<i class="ph-squares-four"></i>
								<span>Gestione Moduli</span>
							</a>
							<ul class="nav-group-sub collapse" style="">
								<li class="nav-item"><a href="#" v-on:click="openRoute('/Banners')" class="nav-link"><i class="fal fa-ad fa-fw fa-lg"></i><span>Gestione Pubblicit&agrave;</span></a></li>
								<li class="nav-item"><a href="#" v-on:click="openRoute('/Flyers')" class="nav-link"><i class="fal fa-book-open fa-fw fa-lg"></i><span>Gestione Locandine</span></a></li>
								<li class="nav-item"><a href="#" v-on:click="openRoute('/Coupons')" class="nav-link"><i class="fal fa-recycle fa-fw fa-lg"></i><span>Gestione Coupon</span></a></li>
								<li class="nav-item"><a href="#" v-on:click="openRoute('/Gustbook')" class="nav-link"><i class="fal fa-book fa-fw fa-lg"></i><span>Gestione Guestbook</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Newsletters') == true}" v-on:click="openRoute('/Newsletters')"><i class="fal fa-mail-bulk fa-fw fa-lg"></i><span>Gestione Newsletter</span></a></li>
								<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('Messages') == true}" v-on:click="openRoute('/Messages')"><i class="fal fa-comment-alt fa-fw fa-lg"></i><span>Gestione Messaggi</span></a></li>
							</ul>
						</li> 

						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('CardSeason') == true}" v-on:click="openRoute('/CardSeason')"><i class="fal fa-credit-card fa-fw fa-lg"></i><span>Gestione Tessere</span></a></li>

						<li class="nav-item"><a href="#" class="nav-link" v-bind:class="{'active':isCurrent('UtentiClienti') == true}" v-on:click="openRoute('/UtentiClienti')"><i class="ph-arrow-elbow-down-right"></i><span>Gestione Routers</span></a></li>

					</ul>
				</div>
				<!-- /main navigation -->

			</div>
			<!-- /sidebar content -->

		</div>
		<!-- /main sidebar -->	

		<!-- Main content -->
		<div class="content-wrapper">	

			<!-- Navigation -->
			<div class="navbar navbar-dark navbar-expand-lg navbar-static shadow" v-show="utente.ID_UTENTE > 0">
				<div class="container-fluid">
					
					<div class="d-flex d-lg-none me-2">
						<button type="button" class="navbar-toggler sidebar-mobile-main-toggle bg-light bg-opacity-20 text-reset rounded-pill me-2">
							<i class="ph-arrows-left-right"></i>
						</button>
						<div class="d-xl-none">
							<button type="button" class="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbar-navigation" aria-expanded="false">
								<i class="ph-list"></i>
							</button>
						</div>
						<router-link class="d-inline-flex align-items-center" href="#" :to="'Dashboard'" style="margin-left: 4rem !important; margin-right: 50%;">
							<img src="./assets/images/logo_icon_light.png" class="sidebar-logo-icon" alt="">
						</router-link>
						<div class="d-xl-none float-end">
							<button type="button" class="navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#navbar-mixed" aria-expanded="false">
								<i class="ph-squares-four"></i>
							</button>
						</div>
					</div>

					<div class="navbar-collapse collapse" id="navbar-navigation" style="">
						<ul class="navbar-nav mt-2 mt-xl-0">
							<li class="nav-item dropdown ms-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown"><i class="faico icon-user-plus fa-fw me-2"></i>Accessi</a>
								<div class="dropdown-menu">
									<a href="#" v-on:click="openRoute('/UtentiCms')" class="dropdown-item"><i class="fad fa-users fa-fw me-2"></i>| Gestione Utenti</a>
									<a href="#" v-on:click="openRoute('/ConfAccessi')" class="dropdown-item"><i class="fad fa-sitemap fa-fw me-2"></i>| Gestione Permessi</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/Accessi')" class="dropdown-item"><i class="fad fa-magic fa-fw me-2"></i>| Log Accessi</a>
									<a href="#" v-on:click="openRoute('/Errori')" class="dropdown-item"><i class="fad fa-exclamation-triangle fa-fw me-2"></i>| Log Errori</a>
								</div>
							</li>

							<li class="nav-item dropdown ms-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown"><i class="faico icon-cart5 fa-fw me-2 "></i>Pratiche</a>
								<div class="dropdown-menu">
									<a href="#" v-on:click="openRoute('/Ordini')" class="dropdown-item"><i class="fad fa-euro-sign fa-fw me-2"></i>| Gestione Pratiche</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="preventivo()" class="dropdown-item"><i class="fad fa-globe fa-fw me-2"></i>| Disponibilit&agrave; Prodotto</a>
									<a href="#" v-on:click="openRoute('/Preventivi')" class="dropdown-item"><i class="fad fa-balance-scale fa-fw me-2"></i>| Gestione Preventivi</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/FullBoard')" class="dropdown-item"><i class="fad fa-users-class fa-fw me-2"></i>| Piano Occupazione</a>
								</div>
							</li>

							<li class="nav-item dropdown ms-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown"><i class="faico icon-barcode2 fa-fw me-2"></i>Contabilit&agrave;</a>
								<div class="dropdown-menu">
									<a href="#" v-on:click="openRoute('/Incassi')" class="dropdown-item"><i class="fad fa-sack-dollar fa-fw me-2"></i>| Gestione Incassi</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/Payments')" class="dropdown-item"><i class="fad fa-money-check-edit-alt fa-fw me-2"></i>| Gestione Pagamenti</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/Invoice')" class="dropdown-item"><i class="fad fa-calculator fa-fw me-2"></i>| Fatturazione</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/Statistiche')" class="dropdown-item"><i class="fad fa-calculator fa-fw me-2"></i>| Statistiche</a>
								</div>
							</li>
							
							<li class="nav-item dropdown ms-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown"><i class="faico icon-images2 fa-fw me-2"></i>Template</a>
								<div class="dropdown-menu">
									<a href="#" v-on:click="openRoute('/ConfNavs')" class="dropdown-item"><i class="fad fa-list fa-fw me-2"></i>| Gestione Men&ugrave;</a>
									<a href="#" v-on:click="openRoute('/ConfSliders')" class="dropdown-item"><i class="fad fa-camera-retro fa-fw me-2"></i>| Gestione Sliders</a>
									<a href="#" v-on:click="confTravelLingue(57)" class="dropdown-item"><i class="fad fa-flag fa-fw me-2"></i>| Gestione Lingue</a>
									<a href="#" v-on:click="openRoute('/ConfEmail')" class="dropdown-item"><i class="fad fa-envelope fa-fw me-2"></i>| Gestione Email</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/ToDO')" class="dropdown-item"><i class="fad fa-list-alt fa-fw me-2"></i>| Gestione To Do</a>
									<a href="#" v-on:click="openRoute('/ConfSocial')" class="dropdown-item"><i class="fad fa-share-square fa-fw me-2"></i>| Gestione Social</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/Accounting')" class="dropdown-item"><i class="fad fa-sack-dollar fa-fw me-2"></i>| Gestione Valute</a>
								</div>
							</li>

							<li class="nav-item dropdown ms-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown"><i class="faico icon-gear fa-fw me-2"></i>Preferenze</a>
								<div class="dropdown-menu">
									<a href="#" v-on:click="openRoute('/ConfOwner')" class="dropdown-item"><i class="fad fa-key fa-fw me-2"></i>| Configurazione Aziende</a>
									<a href="#" v-on:click="openRoute('/ConfWebsite')" class="dropdown-item"><i class="fad fa-globe fa-fw me-2"></i>| Configurazione Siti</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="openRoute('/ConfEmail')" class="dropdown-item"><i class="fad fa-envelope-open fa-fw me-2"></i>| Configurazione Dati Mail</a>
									<a href="#" v-on:click="openRoute('/ConfGoogle')" class="dropdown-item"><i class="fab fa-google fa-fw me-2"></i>| Configurazione Dati Google</a>
									<a href="#" v-on:click="openRoute('/ConfSuite')" class="dropdown-item"><i class="fad fa-code fa-fw me-2"></i>| Configurazione Dati Gestionale</a>		
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="$root.confTravelTipologiche(0)" class="dropdown-item"><i class="fad fa-cogs fa-fw me-2"></i>| Configurazione Generali</a>
									<a href="#" v-on:click="openRoute('/Configurazione')" class="dropdown-item"><i class="fad fa-cogs fa-fw me-2"></i>| Conf Originale</a>
									<li class="divider"></li>
								<a href="#" v-on:click="openRoute('/ConfFooter')" class="dropdown-item"><i class="fad fa-align-left fa-fw me-2"></i>| Configurazione Dati Footer</a>
								</div>
							</li>

						</ul>
					</div>	

					<div class="navbar-collapse collapse" id="navbar-mixed">
						<ul class="nav flex-row ms-auto">
							
							<li class="nav-item dropdown me-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<img :src="filters.getImgPath('LOGOS',idEnteCorrente+'.png')" class="rounded-circle me-2" height="24" alt="" /> 
									<span v-if="enteCorrente != undefined">{{enteCorrente.LABEL}}</span>
								</a>
								<div class="dropdown-menu">
									<div v-for="ente in $root.arrayEnti" v-bind:key="ente.ID_ENTE">
										<a href="#" v-on:click="switchEnte(ente)" class="dropdown-item">
											<img :src="filters.getImgPath('LOGOS',ente.ID_ENTE+'.png')" width="24" height="24" class="rounded-circle me-2" alt="" />
											<span class="fw-bold">{{ ente.LABEL }}</span>
										</a>
									</div>
								</div>
							</li>

							<li class="nav-item dropdown me-xl-1">
								<a href="#" class="navbar-nav-link rounded dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<Flag :country="currentLang" class="me-1"></Flag>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<div v-for="lang in arrayLingue" v-bind:key="lang.ID_GEO_STATO">
										<a href="#" v-on:click="currentLang = lang.ID_GEO_STATO" class="dropdown-item h-25px" >
											<Flag :country="lang.ID_GEO_STATO" class="me-1"></Flag> {{ lang.NOME }}
										</a>
									</div>
								</div>
							</li>
							
							<li class="nav-item nav-item-dropdown-lg dropdown dropdown-leftms-lg-2">
								<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill" data-bs-toggle="dropdown" data-bs-auto-close="outside">
									<i class="ph-chats"></i>
									<span class="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1">8</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end wmin-lg-400 p-0">
									<div class="d-flex align-items-center p-3">
										<h6 class="mb-0">Chat Amministratori</h6>
									</div>
									<div class="box-prodotto">
										<div class="dropdown-menu-scrollable pb-0" v-for="adm in $root.arrayUtentiAdmin" v-bind:value="adm.ID_PERSONA_FISICA" v-bind:key="adm.ID_PERSONA_FISICA">
											<a href="#" class="dropdown-item align-items-start text-wrap py-2">
												<div class="status-indicator-container me-3">
													<img v-if="adm.ID_PERSONA_FISICA > 0 && adm.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',adm.URL_AVATAR)" class="w-40px h-40px rounded-pill" alt="" />
													<img v-if="adm.URL_AVATAR == 'placeholder.jpg'" src="./assets/images/face11.jpg" class="w-40px h-40px rounded-pill" alt="">
													<span class="status-indicator bg-warning"></span>
												</div>
												<div class="flex-1">
													<span class="fw-semibold">{{ adm.NOME }} {{ adm.COGNOME }}</span>
													<span class="text-muted float-end fs-sm">04:58</span>
													<div class="text-muted">who knows, maybe that would be the best thing for me...</div>
												</div>
											</a>
										</div>
									</div>
									<div class="d-flex border-top py-2 px-3">
										<a href="#" class="text-body">
										</a>
										<a href="#" class="text-body ms-auto" v-on:click="openRoute('/Chat')">
											Val alla Chat
											<i class="ph-arrow-circle-right ms-1"></i>
										</a>
									</div>
								</div>
							</li>

							<li class="nav-item">
								<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill" data-bs-toggle="offcanvas" data-bs-target="#notifications">
									<i class="ph-bell"></i>
									<span class="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1">2</span>
								</a>
							</li>

						</ul>

						<ul class="nav flex-row justify-content-end order-1 order-lg-2">	
							<li class="nav-item nav-item-dropdown-lg dropdown ms-lg-2">
								<a href="#" class="navbar-nav-link align-items-center rounded-pill p-1" data-bs-toggle="dropdown">
									<div class="status-indicator-container">
										<img v-if="utente.ID_UTENTE > 0 && utente.URL_AVATAR != 'placeholder.jpg'" :src="filters.getImgPath('USERS',utente.URL_AVATAR)" class="w-32px h-32px rounded-pill" alt="" />
										<div v-if="utente.URL_AVATAR == 'placeholder.jpg' || utente.ID_UTENTE == 0" class="btn-nophoto w-32px h-32px rounded-pill">No<br />Photo</div>
										<span class="status-indicator bg-success"></span>
									</div>
									<span class="d-none d-lg-inline-block mx-lg-2">{{ utente.USERNAME }}</span>
								</a>
			
								<div class="dropdown-menu dropdown-menu-end">
									<a href="#" class="dropdown-item">
										<i class="ph-envelope-open me-2"></i>Messaggi
										<span class="badge bg-primary rounded-pill ms-auto">26</span>
									</a>
									<a href="#" class="dropdown-item" v-if="livello == 5">
										<i class="ph-currency-circle-dollar me-2"></i>Sottoscrizioni
									</a>
									<a href="#" class="dropdown-item" v-if="livello == 5">
										<i class="ph-shopping-cart me-2"></i>Ordini
									</a>
									<a href="#" v-on:click="openSchedaUtente(utente)" class="dropdown-item">
										<i class="ph-user-circle me-2"></i>Mostra Profilo
									</a>
									<div class="dropdown-divider"></div>
									<a href="#" v-on:click="logout()" class="dropdown-item">
										<i class="ph-sign-out me-2 text-danger"></i>Logout
									</a>
								</div>
							</li>
						</ul>

					</div>
					
				</div>
			</div>
			<!-- /navigation -->
			
			<!-- Inner content -->
			<div class="content-inner">

				<!--<router-view/> -->
				<router-view v-slot="{ Component }">
					<component ref="view" :is="Component" />
				</router-view>

			</div>
			<!-- /inner content -->

			<!-- Footer -->
			<div class="navbar navbar-sm border-top" v-show="utente.ID_UTENTE > 0">
				<div class="container-fluid">
					<span>&copy; 2024 <a href="https://www.webfortravel.it" target="_blank">Web For Travel</a></span>

					<ul class="nav flex-row">
						<li class="nav-item ms-md-1">
							<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded" v-on:click="openRoute('/Manuals')">
								<div class="d-flex align-items-center mx-md-1">
									<i class="icon-book me-1"></i>
									<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.OPEN_MANUALE'"></Translate></span>
								</div>
							</a>
						</li>
						<li class="nav-item ms-md-1">
							<a href="#" class="navbar-nav-link navbar-nav-link-icon rounded" v-on:click="openRoute('/Tickets')">
								<div class="d-flex align-items-center mx-md-1">
									<i class="icon-lifebuoy me-1"></i>
									<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.TICKET'"></Translate></span>
								</div>
							</a>
						</li>
						<li class="nav-item nav-item-dropdown-lg dropup ms-md-1">
							<a href="#" class="navbar-nav-link navbar-nav-link-icon text-success bg-success bg-opacity-10 fw-semibold rounded" v-on:click="openRoute('/Chat')">
								<i class="ph-chat-circle-text me-1"></i>
								<span class="d-none d-md-inline-block ms-1"><Translate :path="'dizionario.base.OPEN_CHAT'"></Translate></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- /footer -->

		</div>
        <!-- /main content -->

	</div>
	<!-- /page content -->	


	<div class="modal" id="popUpConfirm"  >
		<div class="modal-dialog"  >
			<div class="modal-content" >
				<div class="modal-header modal-header-info">
					<h5 class="modal-title">{{alertConfirm.TITLE}}</h5>
					<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
					</button>
				</div>
				<div class="modal-body">
				<div class="row">
					<div class="col-md-2" style="margin-top:10px;font-size:40px">
						<i class="fas " v-bind:class="alertConfirm.ICON"></i>
					</div>
					<div class="col-md-10 align-items-center" style="margin-top:10px">
						<div v-html="alertConfirm.MESSAGE"></div>
					</div>
				</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" v-on:click="alertConfirm.KOHandler()"><i class="fa fa-times fa-fw"></i>{{alertConfirm.KO}}</button>
					<button type="button" class="btn btn-success" v-on:click="alertConfirm.OKHandler()"><i class="fa fa-check fa-fw"></i>{{alertConfirm.OK}}</button>
				</div>
			</div>
		</div>
	</div>

	<SchedaUtente :ref="'schedaUtente'" @updateGriglia="getUtenti()"></SchedaUtente>


	<div class="offcanvas offcanvas-end offcanvas-size-xlg" id="panel_right_help" data-bs-backdrop="false" role="dialog" aria-hidden="true" tabindex="-1">
		<div class="offcanvas-header bg-warning text-white" style="height: 4rem;">
			<h5 class="offcanvas-title fw-semibold">Help</h5>
			<button type="button" class="btn-close text-white" data-bs-dismiss="offcanvas">X</button>
		</div>

		<div class="offcanvas-body p-2">

			<SchedaVisualizzazioneHelp :ref="'schedaVisualizzazioneHelp'"></SchedaVisualizzazioneHelp>

		</div>
        <div class="border-top p-1 bg-light" style="position: relative; top: -1px;">
            <span class="btn btn-link float-end" data-bs-dismiss="offcanvas"><i class="fal fa-sign-out-alt fa-fw me-1"></i><Translate class="text-uppercase" :path="'dizionario.base.BTN_CLOSE'"></Translate></span>     
        </div>  
	</div>


</template>


<script>
import Img from '@/components/utils/Img.vue'
import Flag from '@/components/utils/Flag.vue'
import googlePlaces from '@/composables/googlePlaces';
import SchedaUtente from '@/components/SchedaUtente.vue'
import SchedaVisualizzazioneHelp from '@/components/SchedaVisualizzazioneHelp.vue'


export default {
    name: 'App',   
	components:{
		Img,
		Flag,
		SchedaUtente,
		SchedaVisualizzazioneHelp
    },
	props:["livello"],

    data:function(){
        return {
			alertConfirm:{
				MESSAGE:"prova"
			},
			filters:global.filters,
			zindex:1100,
			title:'Dashboard',
			icon:'fa-home',
			ordineSelezionato:{},
			preventivoSelezionato:{},
			sidebarHasBeenResized:false,
			passwordUtente:{},
			isAdmin : false,
			utente:{funzionalita: new Array()},
			arrayMenu: new Array(),
			idStagioneCorrente:0,
			isMobile:false,
			previousRoute:"/",
			currentLang:118,
			GLOBAL_IMAGES_PATH:'',
			GLOBAL_THEME_IMAGES_PATH:'',
			savedSearch:{},
			idSetting:0,
			prodotto:{},
			stagione:{},
			percorsoLang:'',
			sottoProdotto:{},
			geoLangSelezionato:{},
			idEnteCorrente:0,
			FK_ID_MODULO_TIPOLOGICHE:0,
			arrayModuli : new Array(
					{ID:1,DESCRIZIONE:"ATTIVITA'"},
					{ID:2,DESCRIZIONE:"HOTEL"},
					{ID:3,DESCRIZIONE:"PACCHETTI"},
					{ID:4,DESCRIZIONE:"VOLI"},
					{ID:5,DESCRIZIONE:"CAMERE"},
					),
			dizionario:{},
			//////////////////////////// TIPOLOGICHE 					
			arrayTipologieOpzioni : new Array(),
			arrayUtentiAdmin : new Array(),
			arrayEnti : new Array(),
			arrayCodiciIva : new Array(),
			arrayTipoFiscale : new Array(),
			arrayTipoModuli : new Array(),
			arrayTipoCategoria : new Array(),
			arrayTipoContenuto : new Array(),
			arrayModalitaCalcolo : new Array(),
			arrayPromotori : new Array(),
			arrayAgenti : new Array(),
			arrayConti : new Array(),
			arrayGeoStati : new Array(),
			arrayGeoComuni : new Array(),
			arrayAeroporti : new Array(),
			arrayLivelli : new Array(),
			arrayGruppi : new Array(),
			arrayFunzionalita : new Array(),
			arrayTipoAgenzia : new Array(),
			arrayTipoFornitore : new Array(),
			arrayLingue : new Array(),
			arrayRuoliUtente : new Array(),
			arrayStagioni : new Array(),
			arrayRtsType : new Array(),
			arrayPraticaStato : new Array(),
			arrayPraticaBook : new Array(),
			arrayPraticaTipo : new Array(),
			arrayPraticaPagamento : new Array(),
			arrayPraticaDocumento : new Array(),
			arrayAllOpzioni : new Array(),
			arrayNetwork : new Array(),
			statistiche: {},
			currentModulo:0,
			COSTANTI:{},
			VERSIONE:'',
			CHAT_PATH:''
        } 
    },

    methods : {
		logout : function(){
			global.utils.ajax('core/logout',{}, (response) => {
				//$('.sidebar').removeClass("sidebar-main-resized");
				this.$root.utente = {};
				global.utils.deleteCookie("session_token");
				global.router.push("Login");
				this.arrayMenu = new Array();
			});
   		},

      	isCurrent : function(menu){
			if ("/"+menu == this.currentRouteName){
				return true;
			}
      	},
		
		confirm : function(alertConfirm){
			this.alertConfirm = alertConfirm;
			this.filters.openPopUp("popUpConfirm");
		},

		setHeader : function(_title,_icon){
			this.title = _title;
			this.icon = _icon;
		},

		back : function(){
			global.router.go(-1);
		},

		modificaPassword : function(){
			this.filters.openPopUp("popUpModificaPassword");
		},

		switchEnte : function(ente){
			global.utils.ajax('travel/switchtEnte',{FK_ID_ENTE:ente.ID_ENTE}, (response,status) => {
				if (response.esito == 0){
					global.utils.alert.success("Operazione correttamente eseguita");
					this.idEnteCorrente = ente.ID_ENTE;
					//$('#listaEnti').removeClass("show");
					//$('#divListaEnti').removeClass("show");
					utils.removeClass("listaEnti","show");
					utils.removeClass("divListaEnti","show");

					//console.log(this.$refs.view);
					if (typeof this.$refs.view.refreshEnte == 'function'){
						this.$refs.view.refreshEnte();
					}					
				}
			});
		}, 

		openSchedaUtente: function(){
			this.$refs.schedaUtente.openSchedaUtente(this.utente);
		},
		
		modificaProfilo : function(){
			global.router.push("Profilo");
		},

		openRoute : function(route){
			global.router.push(route);
		},

		doModificaPassword : function(){
			//)7.HRK=L3M!xiyRq,vZ
			this.filters.closePopUp("popUpModificaPassword");
			global.utils.ajax('core/utente/password/update',this.passwordUtente, (response,status) => {
				if (response.esito == 0){
					global.utils.alert.success("Operazione correttamente eseguita");
				}
			});
		},

		getDataColor : function(route){
			return route.DESCRIZIONE;
		},

		openPopUpLogin : function(){
			this.filters.openPopUp("popUpRecoveryLogin");
		},

		checkMobile : function(route){
			if (this.isMobile == false){
			return true;
			}
			else{
			if (route.ROUTE == '/Soci' || route.ROUTE == '/attivita' || route.ROUTE == '/abbonamenti'){
				return true;
			}
			}
			return false;
		},

		startHandler : function(){
			this.arrayMenu = new Array();
			global.router.push("Start");
			//1.VERIFICO ESISTENZA TOKEN
			//console.log("*** AVVIO APPLICAZIONE ***");
			var token = global.utils.getCookie("session_token");
			//SE E' PRESENTE IL TOKEN, PROVIAMO A RECUPERARE L'Utente
			if (token != "" && token != undefined && token != "undefined"){
				global.utils.SESSION_TOKEN = token;
				global.utils.ajax('core/logged',{}, (response) => {
				if (response.esito == 0 && response.data.UTENTE.ID_UTENTE > 0){
					this.utente = response.data.UTENTE;
					this.arrayMenu = response.data.UTENTE.menuItems;
					/*
					for (var i = 0 ; i < this.utente.funzionalita.length ; i++){
					if (this.utente.funzionalita[i].FK_ID_ITEM > 0){
						this.arrayMenu.push(this.utente.funzionalita[i].menuItem);
					}
					}*/
					global.router.push("Dashboard");
				}
				else{
					global.router.push("Login");
					this.arrayMenu = new Array();
				}
				});
			}
			else{
				//this.$forceUpdate();
				this.arrayMenu = new Array();
				utils.wait(()=>{
					global.router.push("Login");
				})
			}
		},

		getLang : function(){
			return this.currentlang;
		},

		refreshUtentiAdmin : function(){
			global.utils.ajax('travel/utentiAdmin',{}, (response,status) => {
				if (response.esito == 0){
					this.arrayUtentiAdmin = response.data;
				}
			});
		},

		confTravelTipologiche : function(idModulo){
			this.FK_ID_MODULO_TIPOLOGICHE = idModulo;
            global.router.push("ConfTravelTipologiche");
		},

		getLangModulo : function(idModulo){
			var modulo = utils.array.select(this.arrayTipoModuli,"ICONA",idModulo,1)[0];
			return modulo;
		},

		confTravelLingue : function(idModulo){
			this.currentModulo = idModulo;
			this.percorsoLang = this.getLangModulo(idModulo).CUSTOM_2;
			global.router.push("Lingua");
		},

		preventivo : function(){
			this.ordineSelezionato = {ID_ORDINE:0,INTESTATARIO_FK_ID_PERSONA_FISICA:0,INTESTATARIO_FK_ID_PERSONA_GIURIDICA:0};
			this.openRoute('/Quota');
		},

		////////////////////////////////////////////////////////////////// GESTIONE DELLE LINGUE ///////////////////////////////////////////////////////////
		translate : function(percorso){
			if (this.dizionario != null){
				var percorsi = percorso.split(".");
				var parziale = this.dizionario;
				for (var i = 0 ; i < percorsi.length ; i++){
					parziale = parziale[percorsi[i]];
					if (parziale == undefined){
						return "";
					}
				}
				return parziale[this.currentLang];
			}
			else{
				return "";
			}
			/*
			else{
				utils.wait(()=>{
					this.translate(lingua,percorso);
				});
			}*/
		},

		getDizionarioPercorso : function(percorso){
			var percorsi = percorso.split(".");
			var parziale = this.dizionario.dizionario;
			for (var i = 0 ; i < percorsi.length ; i++){
				parziale = parziale[percorsi[i]];
				if (parziale == undefined){
					return null;
				}
			}
			var arrayDefinizioni = new Array();
			Object.keys(parziale).forEach(key => {
				for (var i = 0 ; i < filters.root.arrayLingue.length ; i++){
					var FK_ID_LINGUA = filters.root.arrayLingue[i].ID_GEO_STATO;
					var val = parziale[key][FK_ID_LINGUA];
					arrayDefinizioni.push({DEFINIZIONE:key,VALORE:val,FK_ID_LINGUA:FK_ID_LINGUA,FK_ID_DEFINIZIONE:key,ID_DEFINIZIONE:i.toString()+"_"+FK_ID_LINGUA});
				}
			});
			return arrayDefinizioni;
		},

		getDizionario : function(handler = null){
			global.utils.ajax('travel/dizionario',{}, (response,status) => {
				this.dizionario = response.data; 
				if (handler != null)          {
					handler(this.dizionario);
				}
			});
		},

		svuotaCache : function(){
			global.utils.ajax('core/cache', (response,status) => {
				if (response.esito == 0){
					global.utils.alert.success("Operazione correttamente eseguita");
				}
			});
		}	,


		help : function(_modulo){
			var myOffcanvas = document.getElementById('panel_right_help')
			var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
			bsOffcanvas.show();
			this.$refs.schedaVisualizzazioneHelp.openSchedaVisualizzazioneHelp(_modulo);
		}
    },

    computed : {
		currentRouteName() {
			return this.$route.path;
		},

		mainMenuActive(){
			for (var i = 0 ; i < this.arrayMenu.length ; i++){
			if (this.arrayMenu[i].ROUTE == this.currentRouteName){
				return 1;
			}
			}
			return 0;
		},

		enteCorrente(){
			var ente = utils.array.select(this.arrayEnti,"ID_ENTE",this.idEnteCorrente,1)[0];
			return ente;
		}

    },

    created : function(){

		
		var apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
		this.GLOBAL_THEME_IMAGES_PATH = process.env.VUE_APP_THEME_IMAGES_PATH;
		this.VERSIONE =  process.env.VUE_APP_VERSION_NUMBER;
		this.CHAT_PATH =  process.env.VUE_APP_CHAT_PATH;
		const link = "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&libraries=places&callback=Function.prototype"; //&callback=indirizzoSelezionato
		googlePlaces(link);
        var ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
            this.isMobile = true;
        else
            this.isMobile = false;


		
        //global.utils.BASE_API = "http://localhost/dryland_2/CORE/SERVER/API/"; //process.env.VUE_APP_API_URL;
        global.utils.BASE_API = process.env.VUE_APP_API_URL;
        this.GLOBAL_IMAGES_PATH = process.env.VUE_APP_GLOBAL_IMAGES_PATH;
		this.getDizionario();
        this.startHandler();
        setTimeout(() => {
			/*
            $('.modal').on('show.bs.modal',  (e) => {
                setTimeout(() => {
                    this.zindex++;
                    $('.modal-backdrop').last().css("z-index",this.zindex);
                    this.zindex++;
                    $('.modal.fade.show').last().css("z-index",this.zindex); 
                }, 200);
            }); */                   
        }, 400);
    },
    
    watch:{
		currentlang(valore,valoreOld){
			if (valore != valoreOld){
			/*
			setTimeout(() => {
				global.t.currentlang = valore;
			},500)*/
			}
		},

        $route (valore,valoreOld){			
			//console.log(valore.path,valoreOld.path);
			this.previousRoute = valoreOld.path;
			if (valore.path == "/Pagamento"){
				this.previousRoute = "/pagamenti";
			}
			var list = document.getElementsByClassName("tooltip")
			for (var i = 0 ; i < list.length ; i++) {
				list[i].remove();
			}

			var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
			var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
			return new bootstrap.Popover(popoverTriggerEl)
			})

			var arr = document.getElementsByClassName("offcanvas")
			for (var i = 0 ; i < arr.length ; i++){
				arr[i].classList.remove("show")
			}
			global.utils.wait(()=>{
				global.utils.resizeContent();
			});
			
			return;
			$('#kt_vue_content_parent').hide().fadeIn(600);

          	setTimeout(() => {
				this.$forceUpdate();
				AppTemplate.initCore();
				AppTemplate.initAfterLoad();
				global.$('form').submit(function(event){
					event.preventDefault();
					event.stopPropagation();
					return false;
				});
				$('.modal').on('show.bs.modal',  (e) => {
					setTimeout(() => {
						this.zindex++;
						$('.modal-backdrop').last().css("z-index",this.zindex);
						this.zindex++;
						$('.modal.fade.show').last().css("z-index",this.zindex); 

						global.$('form').submit(function(event){
							event.preventDefault();
							event.stopPropagation();
							return false;
						});
					}, 200);
				});      
				
			}, 300);
        }
    }
}
</script>
