<template>
    <Prodotti :modulo="4"></Prodotti>
</template>
<script>

import Prodotti from '@/components/Prodotti.vue'

export default {
    name:"Flights",
    components:{
        Prodotti,
    },
}
</script>