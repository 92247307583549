<template>

    <!-- Page header -->
    <div class="page-header page-header-light">
        <div class="page-header-content header-elements-lg-inline">
            <div class="page-title d-flex">
                <h4 class="d-none d-sm-block">
                    <router-link href="#" class="d-inline-block text-dark" :to="'Dashboard'"><i class="icon-arrow-left52 me-2"></i></router-link> 
                    <a href="#" class="d-inline-block text-dark"><span class="font-weight-semibold"> Statistiche </span></a> \
                    <span class="text-muted">Elenco Statistiche</span>
					<code> - Lingua italiano</code>
                </h4>
                <h4 class="d-block d-xl-none"><i class="icon-arrow-left52 me-2"></i> Elenco Statistiche</h4>
                <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>
            <div class="header-elements d-none">
                <div class="d-flex justify-content-center">
                    <a href="#" class="btn btn-link btn-float text-body" v-on:click="nuovaQuota(1)"><i class="icon-earth text-primary"></i> <span>DISPONIBILITA'</span></a>
                    <a href="#" class="btn btn-link btn-float text-body" data-trigger="#my_offcanvas1"><i class="icon-question7"></i> <span>HELP</span></a>
                </div>
            </div>            
        </div>
            
        <!-- Profile navigation -->
        <div class="navbar navbar-expand-lg navbar-light border-top">
            <div class="text-center d-lg-none w-100">
                <button type="button" class="navbar-toggler dropdown-toggle" data-bs-toggle="collapse" data-target="#navbar-second">
                    <i class="icon-menu7 me-2"></i>
                    Profile navigation
                </button>
            </div>

            <div class="navbar-collapse collapse" id="navbar-second">
                <ul class="nav navbar-nav">
                    <li class="nav-item">
                        <a href="#occupancy" class="navbar-nav-link active" data-bs-toggle="tab">
                            <i class="icon-menu7 me-2"></i> Occupazione
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#product" class="navbar-nav-link" data-bs-toggle="tab">
                            <i class="icon-calendar3 me-2"></i> Vendite
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#agency" class="navbar-nav-link" data-bs-toggle="tab">
                            <i class="icon-cog3 me-2"></i> Agenzia
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#settings" class="navbar-nav-link" data-bs-toggle="tab">
                            <i class="icon-cog3 me-2"></i> Agenzia
                        </a>
                    </li>
                </ul>

                <ul class="navbar-nav ms-lg-auto">
                    <li class="nav-item">
                        <a href="#" class="navbar-nav-link">
                            <i class="icon-stack-text me-2"></i> Notes
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="navbar-nav-link">
                            <i class="icon-collaboration me-2"></i> Friends
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="navbar-nav-link">
                            <i class="icon-images3 me-2"></i> Photos
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="navbar-nav-link dropdown-toggle" data-bs-toggle="dropdown">
                            <i class="icon-gear"></i>
                            <span class="d-lg-none ms-2">Settings</span>
                        </a>

                        <div class="dropdown-menu dropdown-menu-right">
                            <a href="#" class="dropdown-item"><i class="icon-image2"></i> Update cover</a>
                            <a href="#" class="dropdown-item"><i class="icon-clippy"></i> Update info</a>
                            <a href="#" class="dropdown-item"><i class="icon-make-group"></i> Manage sections</a>
                            <div class="dropdown-divider"></div>
                            <a href="#" class="dropdown-item"><i class="icon-three-bars"></i> Activity log</a>
                            <a href="#" class="dropdown-item"><i class="icon-cog5"></i> Profile settings</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /profile navigation --> 
        
    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content content-2">
        <div class="tab-content flex-1 order-2 order-lg-1">

            <div class="tab-pane fade active show" id="occupancy">
                <div class="card shadow mb-1">
                    <div class="card-header header-elements-sm-inline">
                        <h6 class="card-title">Weekly statistics</h6>
                        <div class="header-elements">
                            <span><i class="icon-history me-2 text-success"></i> Updated 3 hours ago</span>
                            <div class="list-icons ms-3">
                                <a class="list-icons-item" data-action="reload"></a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="chart-container">
                            <div class="chart has-fixed-height" id="tornado_negative_stack"></div>
                        </div>
                    </div>
                </div>
                <div class="card shadow mb-0">
                    <div class="collapse show">
                        <div class="card-body card-body-2">
                            <div class="alert alert-2 alert-secondary alert2 border-0">
                                <form action="#" class="">
                                    <div class="row">
                                        <div class="col-6 col-md-1 form-group-1">
                                            <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Id pratica" :tabindex="6" />
                                        </div> 
                                        <div class="col-6 col-md-1 form-group-1">
                                            <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Pnr- pratica" :tabindex="7" />
                                        </div>  
                                        <div class="col-12 col-md-2 form-group-1">
                                            <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Intestatario Pratica" :tabindex="8" />
                                        </div>	 
                                        <div class="col-12 col-md-2 form-group-1">
                                            <input autocomplete="off" class="form-control form-control-sm" type="text" placeholder="Nome Pratica" :tabindex="9" />
                                        </div> 
                                        <div class="col-12 col-md-6">
                                            <div class="row">
                                                <div class="col-6 col-md-2 form-group-1">
                                                    <select class="form-control form-control-sm" data-placeholder="Sito Web" :tabindex="10">
                                                        <option>Sito</option>
                                                    </select>
                                                </div>
                                                <div class="col-6 col-md-2 form-group-1">
                                                    <select class="form-control form-control-sm" data-placeholder="Tipologia booking" tabindex="11">
                                                        <option v-bind:value="''">Booking</option> 
                                                        <option v-bind:value="1"> - Booking x Agenzia</option> 
                                                        <option v-bind:value="2"> - Booking x Cliente</option> 
                                                        <option v-bind:value="3"> - Agenzia Diretta</option> 
                                                        <option v-bind:value="4"> - Cliente Diretto</option>
                                                    </select>
                                                </div>
                                                <div class="col-6 col-md-2 form-group-1">
                                                    <select class="form-control form-control-sm" data-placeholder="Tipologia pratica" tabindex="12"> 
                                                        <option v-bind:value="''">Pratica</option> 
                                                        <option v-bind:value="0"> - Individuale</option> 
                                                        <option v-bind:value="1"> - Gruppi</option>
                                                    </select>
                                                </div>
                                                <div class="col-6 col-md-2 form-group-1">
                                                    <select class="form-control form-control-sm" data-placeholder="Stato Saldo" :tabindex="13">
                                                        <option v-bind:value="''">Saldo</option>
                                                        <option v-bind:value="1"> - Saldato</option> 
                                                        <option v-bind:value="2"> - Da saldare</option> 
                                                        <option v-bind:value="3"> - Parzialmente saldato</option> 
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-2 form-group-1">
                                                </div>
                                                <div class="col-md-2 form-group-1">
                                                    
                                                </div>  
                                            </div>
                                        </div>  
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="card-body card-body-2 card-body-nobottom card-body-notop">
                        <div class="tableFixHead" style="max-height: 32.9rem !important;">
                            <table class="table table-bordered table-hover table-striped table-togglable"> 
                                <thead>
                                    <tr>
                                        <th class="table-head-sm text-center" style="width: 6%;"> # </th>
                                        <th class="table-head-sm text-center" style="width: 7%;">Data creazione</th>
                                        <th class="table-head-sm text-center" style="width: 8%;">Stato Pratica</th>
                                        <th class="table-head-sm" style="width: 30.6%;"> Titolo pratica<br />Durata e Passeggeri</th>
                                        <th class="table-head-sm" style="width: 19%;"> Intestatario </th>
                                        <th class="table-head-sm text-center" style="width: 9%;">Totale </th>
                                        <th class="table-head-sm text-center" style="width: 7%;">Contabilità</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center f-08"> </td>
                                        <td class="text-center f-08"> </td>
                                        <td class="text-center f-08"> </td>
                                        <td class=""> </td>
                                        <td class=""> </td>
                                        <td class="text-center"> </td>
                                        <td class="text-center"> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-11">
                                <div class="float-end">
                                    <Pagine style="margin-top:6px;" :tabindex="24"></Pagine>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <select class="form-control wmin-100 mt-1 float-end" style="position: relative; top: 1px;" data-placeholder="Limite" :tabindex="25">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="tab-pane fade" id="product">
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title">Available hours</h6>
                    </div>
                    <div class="card-body">
                        <div class="chart-container">
                            <div class="chart has-fixed-height" id="available_hours"></div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title">My schedule</h6>
                    </div>

                    <div class="card-body">
                        <div class="my-schedule"></div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="agency">

                <!-- Profile info -->
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title">Profile information</h6>
                    </div>

                    <div class="card-body">
                        <form action="#">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Username</label>
                                        <input type="text" value="Eugene" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Full name</label>
                                        <input type="text" value="Kopyov" class="form-control">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Address line 1</label>
                                        <input type="text" value="Ring street 12" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Address line 2</label>
                                        <input type="text" value="building D, flat #67" class="form-control">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <label>City</label>
                                        <input type="text" value="Munich" class="form-control">
                                    </div>
                                    <div class="col-lg-4">
                                        <label>State/Province</label>
                                        <input type="text" value="Bayern" class="form-control">
                                    </div>
                                    <div class="col-lg-4">
                                        <label>ZIP code</label>
                                        <input type="text" value="1031" class="form-control">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Email</label>
                                        <input type="text" readonly="readonly" value="eugene@kopyov.com" class="form-control">
                                    </div>
                                    <div class="col-lg-6">
                                        <label>Your country</label>
                                        <select class="custom-select">
                                            <option value="germany" selected>Germany</option> 
                                            <option value="france">France</option> 
                                            <option value="spain">Spain</option> 
                                            <option value="netherlands">Netherlands</option> 
                                            <option value="other">...</option> 
                                            <option value="uk">United Kingdom</option> 
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Phone #</label>
                                        <input type="text" value="+99-99-9999-9999" class="form-control">
                                        <span class="form-text text-muted">+99-99-9999-9999</span>
                                    </div>

                                    <div class="col-lg-6">
                                        <label>Upload profile image</label>
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="customFile">
                                            <label class="custom-file-label" for="customFile">Choose file</label>
                                        </div>
                                        <span class="form-text text-muted">Accepted formats: gif, png, jpg. Max file size 2Mb</span>
                                    </div>
                                </div>
                            </div>

                            <div class="text-right">
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- /profile info -->


                <!-- Account settings -->
                <div class="card">
                    <div class="card-header">
                        <h6 class="card-title">Account settings</h6>
                    </div>

                    <div class="card-body">
                        <form action="#">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Username</label>
                                        <input type="text" value="Kopyov" readonly="readonly" class="form-control">
                                    </div>

                                    <div class="col-lg-6">
                                        <label>Current password</label>
                                        <input type="password" value="password" readonly="readonly" class="form-control">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>New password</label>
                                        <input type="password" placeholder="Enter new password" class="form-control">
                                    </div>

                                    <div class="col-lg-6">
                                        <label>Repeat password</label>
                                        <input type="password" placeholder="Repeat new password" class="form-control">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <label>Profile visibility</label>

                                        <label class="custom-control custom-radio mb-2">
                                            <input type="radio" name="visibility" class="custom-control-input" checked>
                                            <span class="custom-control-label">Visible to everyone</span>
                                        </label>

                                        <label class="custom-control custom-radio mb-2">
                                            <input type="radio" name="visibility" class="custom-control-input">
                                            <span class="custom-control-label">Visible to friends only</span>
                                        </label>

                                        <label class="custom-control custom-radio mb-2">
                                            <input type="radio" name="visibility" class="custom-control-input">
                                            <span class="custom-control-label">Visible to my connections only</span>
                                        </label>

                                        <label class="custom-control custom-radio">
                                            <input type="radio" name="visibility" class="custom-control-input">
                                            <span class="custom-control-label">Visible to my colleagues only</span>
                                        </label>
                                    </div>

                                    <div class="col-lg-6">
                                        <label>Notifications</label>

                                        <label class="custom-control custom-checkbox mb-2">
                                            <input type="checkbox" class="custom-control-input" checked>
                                            <span class="custom-control-label">Password expiration notification</span>
                                        </label>

                                        <label class="custom-control custom-checkbox mb-2">
                                            <input type="checkbox" class="custom-control-input" checked>
                                            <span class="custom-control-label">New message notification</span>
                                        </label>

                                        <label class="custom-control custom-checkbox mb-2">
                                            <input type="checkbox" class="custom-control-input" checked>
                                            <span class="custom-control-label">New task notification</span>
                                        </label>

                                        <label class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input">
                                            <span class="custom-control-label">New contact request notification</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="text-right">
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- /account settings -->

            </div>
            
        </div>
	</div>

	<!-- Modal -->



</template>



<script>

//import Pagine from '@/components/utils/Pagine.vue'	


export default {
    name:"Statistiche",

    components:{
        //Pagine,
    
    },
    data:function(){
        return {
            filters:global.filters,
            
            search:{
	            
	        },
        }
    },

    methods:{
        


    },

    computed: {
       
    }, 

    created : function(){
        
    }
}
</script>