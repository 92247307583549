<template>
    <div class="rounded shadows" id="map-canvas" style="width:100%;height:240px"></div>    
</template>



<script>
import googlePlaces from '../../composables/googlePlaces';

export default {
    name:'GoogleMaps',
    methods:{
        startup:function(ex_latitude,ex_longitude){
            var map = new google.maps.Map(document.getElementById('map-canvas'), {
                center: {
                    lat: parseFloat(ex_latitude),
                    lng: parseFloat(ex_longitude)
                },
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            var marker = new google.maps.Marker({
                map: map,
                draggable: true,
                //icon: "images/markers/marker.png",
                animation: google.maps.Animation.DROP,
                position: new google.maps.LatLng(ex_latitude, ex_longitude)
            });
       

        }
    },
    created : async () => {
        const link = "https://maps.googleapis.com/maps/api/js?v=weekly&key=AIzaSyA1SmGHsBxpgDpmEMjov9fBzq0MXJ7rMv0&libraries=places&language=it&callback=Function.prototype"; //&callback=startup
        //await googlePlaces(link);
        //autocomplete = new google.maps.places.Autocomplete(document.getElementById("ricercaGoogleMaps"));


        

        /*
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            emit('update:modelValue', place);
            emit('selezionato', place);
            //address = place;
        });*/
    },

}
</script>        
