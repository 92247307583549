const { utils } = require("./utils");

const filters = {
    root:{},
    init: (root) =>{
        filters.root = root;
    },
    getOpzioneUtente: (_label) =>{
        if (filters.root.utente != undefined && filters.root.utente.opzioni != undefined){
            for (var  i = 0 ; i < filters.root.utente.opzioni.length ; i++) {
                if (_label == filters.root.utente.opzioni[i].LABEL ){
                    return filters.root.utente.opzioni[i].VALORE;
                }
            }            
        }

        return null;
    },
    upperCase: (val) => {
        return val.toString().toUpperCase();
    },
    lowerCase: (val) => {
        return val.toString().toLowerCase();
    },
    ucfirst: (val) => {
        return val.toString().toUpperCase() + val.slice(1);
    },
    formattaImporto:(value) =>{
        if (value) {
            return global.utils.formatNumber(value);
        }
        else{
            return "0,00";
        }
    },
    formattaImportoInt:(value)=>{
        if (value) {
            return global.utils.formatNumberInt(value);
        }
        else{
            return "0";
        }
    },
    accorciaTesto : (value,length = 300, mostraPuntini = 1) =>{
        if (value) {
            if (value.toString().length > length){
                return value.toString().substring(0,length) + (mostraPuntini == 1 ? "..." : "");
            }
            else{
                return value.toString();
            }
        }
    },
    formatDate : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY');
        }
    },
    formatDateMonth : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM');
        }
    },
    formatDateReverse : (value) =>{
        if (value) {
            return value.toString().substr(6,4) + "-" + value.toString().substr(3,2) + "-" + value.toString().substr(0,2);
        }
    },
    formatDateSmart : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YY');
        }
    },
    formatDateTime : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY - HH:mm');
        }
    },
    formatDateTimeDp : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY HH:mm');
        }
    },
    formatDateTimeSeconds : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY - HH:mm:ss');
        }
    },
    formatDateTimeSecondsDatePicker : (value) =>{
        if (value) {
            return global.moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
        }
    },
    formatTime: (value) => {
        if (value) {
            return moment(String('0000-01-01 '+ value)).format('HH:mm')
        }
    },
    formatTimeOnly: (value) => {
        if (value) {
            return moment(String(value)).format('HH:mm')
        }
    },
    formatDateYear: (value) => {
        if (value) {
            return moment(String(value)).format('YYYY')
        }
    },
    formatMonth: (value) => {
        if (value) {
            var mese = global.utils.array.select(global.utils.arrayMesi,"ID_MESE",value,1)[0];
            return mese.DESCRIZIONE;
        }
    },
    formatDayWithDate: (value) => {
        if(value){
            const today = global.moment().startOf('day');
            const dataInput = global.moment(String(value)).startOf('day');
            if(dataInput.isSame(today, 'day')){
                return 'Oggi';
            }
            if(dataInput.isSame(today.subtract(1,'day'), 'day')){
                return 'Ieri'
            }
            const dayOfWeek = dataInput.format('dddd');
            const formattedDate = dataInput.format('DD/MM/YYYY');
            return `${formattedDate}`;

        }
        return '';
    },
    addToDate : (value,quantita,tipo) => {
        var new_date = moment(value, "DD/MM/YYYY").add(quantita, tipo);
        return filters.formatDate(new_date);
    },
    removeFromDate : (value,quantita,tipo) => {
        var new_date = moment(value, "DD/MM/YYYY").remove(quantita, tipo);
        return filters.formatDate(new_date);
    },
    dateDiff : function(from,to){
        var diff = Math.abs(
            moment(from)
            .startOf('day')
            .diff(moment(to).startOf('day'), 'days')
        );
        return diff;        
    },
    selectAll : (idChk,_array) => {
        var selezionato = document.getElementById(idChk).checked == true ? 1 : 0;
        for (var i = 0 ; i < _array.length ; i++){
            _array[i].SELEZIONATO = selezionato;
        }
    },
    getGiornoSettimana :  (id,label) => {
        return global.utils.getGiornoSettimana(id)[label];
    },
    getMese : (id,label) =>{
        return global.utils.getMese(id)[label];
    },
    openPopUp : function(_id){
        console.trace();
        var myModal = new bootstrap.Modal(document.getElementById(_id), {
            //keyboard: false
        });
        myModal.show();
    },
    closePopUp : function(_id){
        //var myModal = new bootstrap.Modal(document.getElementById(_id));
        var myModalEl = document.getElementById(_id)
        var myModal =  bootstrap.Modal.getInstance(myModalEl);    
        myModal.hide();
    },
    toSelect2(_array,id,descrizione){
        return utils.array.toSelect2(_array,id,descrizione);
    },
    select2AddAll(model,array){
        model = new Array();
        for (var i = 0 ; i < array.length ; i++) {
            model.push(array[i].id);
        }
        return model;
    },

    arrayModuli(){
        return new Array(           
            {ID_MODULO:1,DESCRIZIONE: 'Activities'},
            {ID_MODULO:2,DESCRIZIONE: 'Hotel'},
            {ID_MODULO:3,DESCRIZIONE: 'Pacchetti'},
            {ID_MODULO:4,DESCRIZIONE: 'Voli'},
            {ID_MODULO:5,DESCRIZIONE: 'Camere'},
            {ID_MODULO:6,DESCRIZIONE: 'Generic'},
            {ID_MODULO:10,DESCRIZIONE: 'Ordini'},

            //DA 21 A 30 SI PARLA DI PERSONE FISICHE E GIURIDICHE (ADMIN, UTENTI, AGENZIE E FORNITORI)
            {ID_MODULO:21,DESCRIZIONE: 'Utenti Amministratori'},    // LIV 2
            {ID_MODULO:22,DESCRIZIONE: 'Utenti Clienti'},           // LIV 5
            {ID_MODULO:23,DESCRIZIONE: 'Opzioni Utenti'}, 
            {ID_MODULO:24,DESCRIZIONE: 'Aziende Agenzie'},          // LIV 3
            {ID_MODULO:25,DESCRIZIONE: 'Aziende Fornitori'},        // LIV 4

            //DA 31 IN POI SI PARLA DI AREE DEL PROGRAMMA (BLOG, CATEGORIE, NEWS, CONTENT, ECC...)
            {ID_MODULO:30,DESCRIZIONE: 'Manuali'},
            {ID_MODULO:31,DESCRIZIONE: 'Assicurazioni'},
            {ID_MODULO:32,DESCRIZIONE: 'Banners'},
            {ID_MODULO:33,DESCRIZIONE: 'Blogs'},
            {ID_MODULO:34,DESCRIZIONE: 'Categorie'},
            {ID_MODULO:35,DESCRIZIONE: 'Content'},
            {ID_MODULO:36,DESCRIZIONE: 'Coupons'},
            {ID_MODULO:37,DESCRIZIONE: 'Destinazioni'},
            {ID_MODULO:38,DESCRIZIONE: 'Faqs'},
            {ID_MODULO:39,DESCRIZIONE: 'Guest Book'},
            {ID_MODULO:40,DESCRIZIONE: 'Home Page'},
            {ID_MODULO:41,DESCRIZIONE: 'Liste'},
            {ID_MODULO:42,DESCRIZIONE: 'Locandine'},
            {ID_MODULO:43,DESCRIZIONE: 'Messaggi'},
            {ID_MODULO:44,DESCRIZIONE: 'News'},
            {ID_MODULO:45,DESCRIZIONE: 'Newsletters'},
            {ID_MODULO:46,DESCRIZIONE: 'Routers'},
            {ID_MODULO:47,DESCRIZIONE: 'Shops'},
            {ID_MODULO:48,DESCRIZIONE: 'Tessere'},

            //DA 50 IN POI DESTINAZIONI
            {ID_MODULO:51,DESCRIZIONE: 'Stati'},
            {ID_MODULO:52,DESCRIZIONE: 'Regioni'},
            {ID_MODULO:53,DESCRIZIONE: 'Province'},
            {ID_MODULO:54,DESCRIZIONE: 'Comuni'},
            {ID_MODULO:55,DESCRIZIONE: 'Aree'},

        );
    },

    moduleRoute(modulo){
        switch (modulo){
            case 1:     return 'Activities';
            case 2:     return 'Hotels';
            case 3:     return 'Packages';
            case 4:     return 'Flight';
            case 5:     return 'Camere';
            case 6:     return 'Generic';
            case 10:    return 'Ordini';

            //DA 21 A 30 SI PARLA DI PERSONE FISICHE E GIURIDICHE (ADMIN, UTENTI, AGENZIE E FORNITORI)
            case 21:    return 'UtentiCms';             // LIV 2
            case 22:    return 'UtentiClienti';         // LIV 5
            case 23:    return 'Opzioni Utenti'; 
            case 24:    return 'Agenzie';               // LIV 3
            case 25:    return 'Fornitori';             // LIV 4

            //DA 31 IN POI SI PARLA DI AREE DEL PROGRAMMA (BLOG, CATEGORIE, NEWS, CONTENT, ECC...)
            case 30:    return 'Manuali';
            case 31:    return 'Assicurazioni';
            case 32:    return 'Banners';
            case 33:    return 'Blogs';
            case 34:    return 'Categorie';
            case 35:    return 'Content';
            case 36:    return 'Coupons';
            case 37:    return 'Destinazioni';
            case 38:    return 'Faqs';
            case 39:    return 'Guest Book';
            case 40:    return 'Home Page';
            case 41:    return 'Liste';
            case 42:    return 'Locandine';
            case 43:    return 'Messaggi';
            case 44:    return 'News';
            case 45:    return 'Newsletters';
            case 46:    return 'Routers';
            case 47:    return 'Shops';
            case 48:    return 'Tessere';
            case 49:    return 'Varianti';

            default:    return 'Start';
        }
    },

    moduleName(modulo){
        switch (modulo){
            case 1:     return 'Attivita`';
            case 2:     return 'Hotel';
            case 3:     return 'Pacchetti';
            case 4:     return 'Voli';
            case 5:     return 'Camere';
            case 6:     return 'Generic';
            case 10:    return 'Ordini';

            //DA 21 A 30 SI PARLA DI PERSONE FISICHE E GIURIDICHE (ADMIN, UTENTI, AGENZIE E FORNITORI)
            case 21:    return 'Utenti Amministratori'; // LIV 2
            case 22:    return 'Utenti Clienti';        // LIV 5
            case 23:    return 'Opzioni Utenti'; 
            case 24:    return 'Aziende Agenzie';       // LIV 3
            case 25:    return 'Aziende Fornitori';     // LIV 4

            //DA 31 IN POI SI PARLA DI AREE DEL PROGRAMMA (BLOG, CATEGORIE, NEWS, CONTENT, ECC...)
            case 30:    return 'Manuali';
            case 31:    return 'Assicurazioni';
            case 32:    return 'Banners';
            case 33:    return 'Blogs';
            case 34:    return 'Categorie';
            case 35:    return 'Content';
            case 36:    return 'Coupons';
            case 37:    return 'Destinazioni';
            case 38:    return 'Faqs';
            case 39:    return 'Guest Book';
            case 40:    return 'Home Page';
            case 41:    return 'Liste';
            case 42:    return 'Locandine';
            case 43:    return 'Messaggi';
            case 44:    return 'News';
            case 45:    return 'Newsletters';
            case 46:    return 'Routers';
            case 47:    return 'Shops';
            case 48:    return 'Tessere';
            case 49:    return 'Varianti';

            default:    return 'Start';
        }
    },

    iconaProdotto(modulo){
        switch (modulo){
            case 1:     return 'fa-bullhorn';
            case 2:     return 'fa-hotel';
            case 3:     return 'fa-suitcase';
            case 4:     return 'fa-plane';
            case 5:     return 'fal fa-bed-alt';
            default:    return 'fal fa-box';
        }
    },
    
    getLabel(_array,_chiave,_valore,_descrizione){
        var item = utils.array.select(_array,_chiave,_valore,1)[0];
        if (item != undefined){
            return item[_descrizione];
        }
        return "";
    },

    getPnr(ordine){
        if (ordine.ID_ORDINE > 0 && ordine.hasOwnProperty("ANNO_ORDINE")){
            var str = "";
            var anno = ordine.ANNO_ORDINE.toString().substring(2);
            str = anno + "/";
            for (var i = 0 ; i < (5-ordine.PNR.toString().length) ;i++){
                str += "0";
            }
            str += ordine.PNR;
            return str;
        }
        return "";
    },

    getStatiOrdine(){
       return filters.root.arrayPraticaStato;
    },

    getCurrentLangDesc(){
        console.log("getCurrentLangDesc");
        if (filters == undefined || filters.root == undefined || filters.root.arrayLingue == undefined){
            return "";
        }
        
        for (var i = 0 ; i < filters.root.arrayLingue.length ; i++){
            if (filters.root.arrayLingue[i]['ID_GEO_STATO'] == filters.root.currentLang){
                return filters.root.arrayLingue[i]['NOME'];
            }
        }
        return "";
    },

    getLblStatoOrdine(id){
        var arr = utils.array.select(filters.getStatiOrdine(),"ID_OPZIONE_TIPOLOGICA",id,1);
        if (arr.length > 0){
            return arr[0]['DESCRIZIONE'];
        }
        return "";
    },

    getAnniOrdine(){
        var arr = new  Array(            
            {ID:2018,DESCRIZIONE:"2018"},
            {ID:2019,DESCRIZIONE:"2019"},
            {ID:2020,DESCRIZIONE:"2020"},
            {ID:2021,DESCRIZIONE:"2021"},
            {ID:2022,DESCRIZIONE:"2022"},
            {ID:2023,DESCRIZIONE:"2023"},
            {ID:2024,DESCRIZIONE:"2024"},
            {ID:2025,DESCRIZIONE:"2025"},
            {ID:2026,DESCRIZIONE:"2026"},
        );
        return arr;
    },

    getStato(id){
        var n = utils.array.select(filters.root.arrayGeoStati,"ID_STATO",id,1)[0];
        if (n != undefined){
            return n;
        }
        return {NOME:''};
    },

    getImgPathProdotto(url){
        return filters.root.GLOBAL_IMAGES_PATH + url;
    },

    //lasciare
    getImgPathProdottoOld(root,idProdotto,idStagione){
        return root.GLOBAL_IMAGES_PATH+'PRODOTTI/'+idProdotto+'/'+idStagione+'/'+idProdotto+'.jpg'
    },
    
    getImgPath(folder,id){
        //let fileName = filters.root.GLOBAL_IMAGES_PATH + folder +'/'+id+'.jpg';
        return filters.root.GLOBAL_IMAGES_PATH + folder +'/'+id;
        //return filters.root.GLOBAL_IMAGES_PATH +'/NOPHOTO/placeholder.jpg';        
    },
    
    getImgExist(folder,id){
        var fileName = filters.root.GLOBAL_IMAGES_PATH + folder +'/' + id;
        var dir = filters.root.GLOBAL_IMAGES_PATH;
        utils.ajax('travel/images',{ROOT:this.dir,URL:this.fileName}, (response) => {
            this.Image = response.data;
        });

        //
        /*
        if(fileName != undefined){
            return filters.root.GLOBAL_IMAGES_PATH + folder +'/' + id;
        } else { 
            return filters.root.GLOBAL_IMAGES_PATH +'/NOPHOTO/placeholder.jpg'; 
        }
        //return filters.root.GLOBAL_IMAGES_PATH + folder +'/'+id;
        */
               
    },

    openRoute: function(route){
        global.router.push(route);
    },

    popover : function(){
        setTimeout(() => {
            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl)
            });
        }, 500);   
    },

    wait : function(time,_handler){
        setTimeout(() => {
           _handler() ;
        }, time);   
    },

    hasFunzionalita : function(idFunzionalita){
        if (filters.root != undefined && filters.root.utente != undefined && filters.root.utente.funzionalita != undefined){
            for (var i = 0 ; i < filters.root.utente.funzionalita.length ; i++) {
                if (filters.root.utente.funzionalita[i].FK_ID_FUNZIONALITA == idFunzionalita){
                    return true;
                }
            }
        }
        return false;
    },

    draggableChangeHandler : function(event,_array,_search,chiave){
        var newIndex = event.newIndex;
        var oldIndex = event.oldIndex;
        var arr = utils.inGrid(_array,_search).arr;
        var item = utils.clone(arr[oldIndex]);				
        var itemSostituito = utils.clone(arr[newIndex]);
        //console.log(item,itemSostituito);				
        //STEP 1: ELIMINO L'ELEMENTRO TRASCINATO
        for (var i = 0 ; i < _array.length; i++){
            if (_array[i][chiave] == item[chiave]){
                _array.splice(i,1);
                break;
            }
        }
        //STEP 2: RIAGGIUNGO L'ELEMENTO TRASCINATO ALLA NUOVA POSIZIONE
        for (var i = 0 ; i < _array.length; i++){
            if (_array[i][chiave] == itemSostituito[chiave]){
                var nuovoIndice = newIndex > oldIndex ? (i+1) : (i);
                _array.splice(nuovoIndice,0,item);
                break;
            }
        }
        for (var i = 0 ; i < _array.length; i++){
            _array[i].ORDINE = (i+1);
        }
        return _array;
    },
    
    limitcounter : function(){
        let inputTextArea = document.getElementById("input-textarea");
        let characCount = document.getElementById("charac-count");
        let wordCount = document.getElementById("word-count");

        inputTextArea.addEventListener("input", () => {
        characCount.textContent = inputTextArea.value.length;
        let txt = inputTextArea.value.trim();
        wordCount.textContent = txt.split(/\s+/).filter((item) => item).length;
        });
    },
    resizeGrid : function(collapse,tableFixHead,boxCard){
        utils.wait(()=>{
            if (document.getElementById(collapse).classList.contains("show") ){
                //devo ridurre					
                document.getElementById(tableFixHead).style['min-height'] = "30rem";
                document.getElementById(tableFixHead).style['max-height'] = "30rem";
                
                document.getElementById(boxCard).style['min-height'] = "34.5rem";
                document.getElementById(boxCard).style['max-height'] = "34.5rem";
                
            }
            else{
                //devo ingrandire					
                document.getElementById(tableFixHead).style['min-height'] = "33.3rem";
                document.getElementById(tableFixHead).style['max-height'] = "33.3rem";
            
                document.getElementById(boxCard).style['min-height'] = "37.5rem";
                document.getElementById(boxCard).style['max-height'] = "37.5rem";
                
            }				
        },350);
        
    }
     
};

exports.filters = filters;



