<template>
    <Contenuti :modulo="35"></Contenuti>
</template>
<script>

import Contenuti from '@/views/content/Contenuti.vue'

export default {
    name:"Pages",
    components:{
        Contenuti,
    },
}
</script>